import React, {useRef, useMemo, useCallback, useState, forwardRef, ChangeEvent, useEffect} from 'react';
import {AgGridReact} from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {dataTypeDefinitions} from "./agGridCustomProps";
import {Button} from "flowbite-react";
import {PiMicrosoftExcelLogoDuotone} from "react-icons/pi";
import {toast, ToastContainer} from "react-toastify";
import {IoMdClose} from "react-icons/io";
import {LuListPlus, LuListRestart} from "react-icons/lu";

type TableProps = {
    editable?: boolean;
}

export interface AgDataGridProps {
    columnDefs: any[];
    rowData: any[];
    editedRowIndex?: number | null;
    tableProps?: TableProps;
    onCellClicked?: (event: any) => void;
    isNewData?: boolean;
    onCellValueChanged?: (params: any) => void;
    rowModelType?: string;
    perPage?: number;
    masterDetail?: boolean;
    detailCellRendererParams?: any;
    defaultCsvExportParams?: any;
    defaultExcelExportParams?: any;
    isExternalExport?: boolean;
    groupIncludeFooter?: boolean;
    groupIncludeTotalFooter?: boolean;
    isCustomHeader?: boolean;
    customChildHeaderComponent?: React.FC;
    tableHeight?: number | string;
    defaultPageSize?: number;
    supportSaveTableState?: boolean;
    tableStateName?: string;
    tableDimension?: {
        width: number | string;
        height: number | string;
    };
    defaultColDef?: {
        sortable: boolean;
        resizable: boolean;
        flex?: number;
        minWidth: number;
        wrapHeaderText: boolean;
        wrapText: boolean;
        autoHeight: boolean;
    };
}

type AgDataGridType = React.FC<AgDataGridProps & any>;

const AgDataGrid: AgDataGridType = forwardRef((props, ref) => {
    const {
        columnDefs = [],
        rowData = [],
        editedRowIndex = null,
        isNewData = false,
        masterDetail = false,
        detailCellRendererParams = {},
        defaultCsvExportParams = {},
        defaultExcelExportParams = {},
        isExternalExport = false,
        groupIncludeFooter = false,
        groupIncludeTotalFooter = false,
        isCustomHeader = false,
        defaultPageSize = 10,
        supportSaveTableState = false,
        tableStateName = undefined,
        tableDimension = {
            width: "100%",
            height: "80vh",
        },
        defaultColDef = {
            sortable: true,
            resizable: true,
            minWidth: 50,
            wrapHeaderText: true,
            wrapText: true,
            autoHeight: true
        }
    } = props;

    const gridApi = useRef<any>(null);
    const columnApi = useRef<any>(null);
    const [perPage, setPerPage] = useState<number>(defaultPageSize);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const startRestoreState = () => {
            timeoutId = setTimeout(() => {
                restoreState();
            }, 1000);
        };

        if (columnApi.current !== null) {
            startRestoreState();
        }

        return () => clearTimeout(timeoutId);
    }, [perPage, columnApi]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const startRestoreState = () => {
            timeoutId = setTimeout(() => {
                restoreState();
            }, 1000);
        };

        if (columnApi.current !== undefined) {
            startRestoreState();
        }

        return () => clearTimeout(timeoutId);

    }, [columnApi]);
    // Example of consuming Grid Event
    const cellClickedListener = useCallback((event: any) => {
        props.onCellClicked?.(event)
    }, [editedRowIndex]);

    const onCellValueChanged = useCallback((params: any) => {
        props.onCellValueChanged?.(params);
    }, []);

    const handleGridChanged = () => {
        // Handle grid changes here
        console.log('Grid changed');
    };

    const onRowDataUpdated = useCallback((params: any) => {
        if (isNewData) {
            params.api.startEditingCell({
                rowIndex: 0,
                // gets the first columnKey
                colKey: params.columnApi.getAllDisplayedColumns()[0].getColId(),
            });
        }
    }, [isNewData]);

    const onGridReady = useCallback((params: any) => {
        gridApi.current = params.api;
        columnApi.current = params.columnApi;

        // Register the grid change event listener
        gridApi.current.addEventListener('gridChanged', handleGridChanged);
    }, []);

    const onPageSizeChanged = (e: ChangeEvent<HTMLSelectElement>) => {
        setPerPage(parseInt(e.target.value));
        gridApi.current.paginationSetPageSize(parseInt(e.target.value));
    }

    const excelStyles = useMemo(() => {
        return [
            {
                id: "header",
                interior: {
                    color: "#aaaaaa",
                    pattern: "Solid"
                }
            },
            {
                id: "body",
                interior: {
                    color: "#dddddd",
                    pattern: "Solid"
                }
            }
        ];
    }, []);

    const onBtExport = useCallback(() => {
        gridApi.current.exportDataAsExcel();
    }, []);


    const saveState = useCallback(() => {
        const tableSetting = columnApi.current.getColumnState();
        const savedTableSetting = localStorage.getItem('table-setting');

        if (tableStateName === undefined) {
            displayToast('no column state saved, please provide tableStateName');
            return;
        }

        if (savedTableSetting === null) {
            localStorage.setItem('table-setting', JSON.stringify([
                {
                    name: tableStateName,
                    setting: tableSetting,
                },
            ]));
            displayToast('Column state saved');
        } else {
            const parsedSavedTableSetting = JSON.parse(savedTableSetting);
            const updatedTableSetting = parsedSavedTableSetting.filter((item: any) => item.name !== tableStateName);
            updatedTableSetting.push({
                name: tableStateName,
                setting: tableSetting,
            });
            localStorage.setItem('table-setting', JSON.stringify(updatedTableSetting));
            displayToast('Column state saved');
        }

    }, [tableStateName]);

    const resetState = useCallback(() => {
        const savedTableSetting = localStorage.getItem('table-setting');
        const savedTableFilter = localStorage.getItem('table-filter');

        if (tableStateName === undefined) {
            displayToast('no column state saved, please provide tableStateName');
            return;
        }

        if (savedTableSetting === null && savedTableFilter === null) {
            displayToast('no column state / filter table saved, please provide tableStateName');
            return;
        }

        if (savedTableSetting !== null) {
            const parsedSavedTableSetting = JSON.parse(savedTableSetting);
            const updatedTableSetting = parsedSavedTableSetting.filter((item: any) => item.name !== tableStateName);
            localStorage.setItem('table-setting', JSON.stringify(updatedTableSetting));
            columnApi.current.resetColumnState();
        }

        if (savedTableFilter !== null) {
            const parsedSavedTableFilter = JSON.parse(savedTableFilter);
            const updatedTableFilter = parsedSavedTableFilter.filter((item: any) => item.name !== tableStateName);
            localStorage.setItem('table-filter', JSON.stringify(updatedTableFilter));
            gridApi.current.setFilterModel([]);
        }
        displayToast('Column state reset');
    }, [tableStateName]);

    const restoreState = useCallback(() => {
        const savedTableSetting = localStorage.getItem('table-setting');
        if (savedTableSetting === null || tableStateName === undefined) {
            console.log('no column state saved');
        } else {
            const parsedSavedTableSetting = JSON.parse(savedTableSetting);
            const savedTableSettingForThisTable = parsedSavedTableSetting.filter((item: any) => item.name === tableStateName);
            if (savedTableSettingForThisTable.length > 0) {
                columnApi.current.applyColumnState({
                    state: savedTableSettingForThisTable[0].setting,
                    applyOrder: true,
                });
                console.log('column state restored');
            } else {
                console.log('no column state saved');
            }
        }
    }, []);

    const displayToast = (info: string) => {
        toast.info(<ShowAttachmentToast info={info} />);
    };

    const CloseButton = ({ closeToast }: any) => (
        <IoMdClose
            size={20}
            onClick={() => {
                closeToast();
            }}
        />
    );

    const ShowAttachmentToast = ({info = ""}) => {
        return (
            <div>
                <span>{info}</span>
            </div>
        );
    };

    const onFirstDataRendered = useCallback(() => {
        // autoSizeAllColumns();
        restoreState();
        loadFilterStateFromLocalStorage();
    }, []);

    const saveFilterStateToLocalStorage = () => {
        const savedTableFilter = localStorage.getItem('table-filter');

        if (gridApi) {
            const filterModel = gridApi.current.getFilterModel();
            if (savedTableFilter === null) {
                localStorage.setItem('table-filter', JSON.stringify([
                    {
                        name: tableStateName,
                        filter: filterModel,
                    },
                ]));
            } else {
                const parsedSavedTableSetting = JSON.parse(savedTableFilter);
                const updatedTableSetting = parsedSavedTableSetting.filter((item: any) => item.name !== tableStateName);
                updatedTableSetting.push({
                    name: tableStateName,
                    filter: filterModel,
                });
                localStorage.setItem('table-filter', JSON.stringify(updatedTableSetting));
            }
        }
    };

    const loadFilterStateFromLocalStorage = () => {
        if (gridApi) {
            const savedFilter = localStorage.getItem('table-filter');
            if (savedFilter) {
                const filterModel = JSON.parse(savedFilter);
                const savedFilterModel = filterModel.filter((item: any) => item.name === tableStateName);
                if (savedFilterModel[0]?.filter) {
                    gridApi.current.setFilterModel(savedFilterModel[0].filter);
                }
            }
        }
    };

    return (
        <div>
            <div className="py-2 pl-4 pr-2 border-[1px] border-t-[#babfc7] border-x-[#babfc7] bg-[#f8f8f8]">
                <div className="flex">
                    <div className="flex items-center">
                        <label className={"text-xs font-bold pr-2"}>Page Size:</label>
                        <select className={"text-sm py-0 px-[10px] border-[1px] border-[#babfc7] rounded text-gray-500"}
                                onChange={onPageSizeChanged} id="page-size" value={perPage.toString()}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="1000000">All</option>
                        </select>
                    </div>
                    <div className="flex grow justify-end">
                        {
                            isCustomHeader &&
                            <div className="flex items-center">
                                {props.customChildHeaderComponent}
                            </div>
                        }
                        {
                            isExternalExport &&
                            <div className="flex items-center">
                                <Button
                                    onClick={onBtExport}
                                    size="xs"
                                    color="success"
                                >
                                    <PiMicrosoftExcelLogoDuotone className="mr-2 h-5 w-5"/>
                                    Export to Excel
                                </Button>
                            </div>
                        }
                        {
                            supportSaveTableState &&
                            <>
                                <Button size="xs"
                                        color="success" onClick={resetState} className="ml-2">
                                    <LuListRestart className="mr-2 h-5 w-5"/>
                                    Reset Column</Button>
                                <Button size="xs"
                                        color="success" onClick={saveState} className="ml-2">
                                    <LuListPlus className="mr-2 h-5 w-5"/>
                                    Save Column</Button>
                            </>
                        }
                    </div>
                </div>
            </div>

            <div
                className="ag-theme-alpine"
                style={{width: tableDimension.width, height: tableDimension.height}}
            >
                <AgGridReact
                    ref={ref as any}
                    // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple" // Options - allows click selection of rows
                    onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                    editType={'fullRow'}
                    suppressClickEdit={true}
                    enableCellChangeFlash={true}
                    onCellValueChanged={onCellValueChanged}
                    onRowDataUpdated={onRowDataUpdated}
                    // onRowEditingStopped={onRowEditingStopped}
                    enableRangeSelection={true}
                    masterDetail={masterDetail}
                    detailCellRendererParams={detailCellRendererParams}
                    defaultCsvExportParams={defaultCsvExportParams}
                    defaultExcelExportParams={defaultExcelExportParams}
                    excelStyles={excelStyles as any}
                    groupIncludeFooter={groupIncludeFooter}
                    groupIncludeTotalFooter={groupIncludeTotalFooter}
                    onFirstDataRendered={onFirstDataRendered}
                    onFilterChanged={saveFilterStateToLocalStorage}
                    //@ts-ignore
                    dataTypeDefinitions={dataTypeDefinitions}
                    pagination={true}
                    paginationPageSize={perPage}
                    cacheBlockSize={perPage}
                />
                <ToastContainer
                    autoClose={5000}
                    draggable={false}
                    closeButton={CloseButton}
                />
            </div>
        </div>
    );
});

export default AgDataGrid;
