import {FeatureEnum, MenuEnum, RoleAccessModel, ActionEnum} from "./models";

export const roleAccessList: RoleAccessModel[] = [
    {
        roleName: "Admin",
        featureAccess: [FeatureEnum.All],
        menuAccess: [
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.History,
                actionAccess: []
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Attendance,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetQC,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetMRE,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetKatapult,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetCheckJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetHoldJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetCancelJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetFielding,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetSupportingDocument,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetMapReq,
                actionAccess: []
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetRequestDocument,
                actionAccess: []
            },
            {
                menuName: MenuEnum.BillJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.ReportBilling,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillingStatistic,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.GDev,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "Administrator",
        featureAccess: [FeatureEnum.All],
        menuAccess: [
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetAllJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetQC,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMRE,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetKatapult,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCheckJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetHoldJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCancelJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetFielding,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetSupportingDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMapReq,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetRequestDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.History,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Attendance,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.SupportingDocumentReport,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.EmployeeWorkApproval,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.AttendanceApproval,
                actionAccess: [ActionEnum.All],
            },
            {
                menuName: MenuEnum.OvertimeApproval,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.HolidayApproval,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Employee,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.JobType,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.JobCategory,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.JobSubjectNotes,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.JobSource,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.PoleType,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Project,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Department,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.DepartmenOwner,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.District,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.EmailTitle,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.SupportingDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Holiday,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.ReportBilling,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillingStatistic,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.GDev,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "Superadmin",
        featureAccess: [FeatureEnum.All],
        menuAccess: [{
            menuName: MenuEnum.All,
            actionAccess: []
        }]
    },
    {
        roleName: "HRD",
        featureAccess: [FeatureEnum.Master, FeatureEnum.Approval, FeatureEnum.Report, FeatureEnum.Dashboard],
        menuAccess: [
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.Employee,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.Position,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.Team,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.Holiday,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.OvertimeApproval,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.AttendanceApproval,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.HolidayApproval,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.Attendance,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: [],
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            }
        ]
    },
    {
        roleName: "Head",
        featureAccess: [FeatureEnum.Approval, FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.WorksheetJobData, FeatureEnum.Dashboard, FeatureEnum.BillJob],
        menuAccess: [
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.EmployeeWorkApproval,
                actionAccess: []
            },
            {
                menuName: MenuEnum.OvertimeApproval,
                actionAccess: []
            },
            {
                menuName: MenuEnum.HolidayApproval,
                actionAccess: []
            },
            {
                menuName: MenuEnum.History,
                actionAccess: [ActionEnum.ActionHold, ActionEnum.Edit, ActionEnum.ActionActivate, ActionEnum.ChangeCompleteDate]
            },
            {
                menuName: MenuEnum.WorksheetAllJob,
                actionAccess: [ActionEnum.ActionHold]
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.Attendance,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.SupportingDocumentReport,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetQC,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMRE,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetKatapult,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCheckJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetHoldJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCancelJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetFielding,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.SupportingDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetSupportingDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMapReq,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetRequestDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.ReportBilling,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillingStatistic,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "SPV",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.WorksheetJobData, FeatureEnum.Dashboard,FeatureEnum.BillJob],
        menuAccess: [{
            menuName: MenuEnum.History,
            actionAccess: []
        },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMRE,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetKatapult,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCheckJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetFielding,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetSupportingDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMapReq,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.ReportBilling,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillingStatistic,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "QC",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.Dashboard, FeatureEnum.BillJob],
        menuAccess: [
            {
                menuName: MenuEnum.History,
                actionAccess: []
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetQC,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMRE,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetKatapult,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetRequestDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.ReportBilling,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillingStatistic,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "CSQ",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.Dashboard],
        menuAccess: [
            {
                menuName: MenuEnum.History,
                actionAccess: []
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: [ActionEnum.Attachment, ActionEnum.ReadyToQC, ActionEnum.ChangePoleStatus]
            },
            {
                menuName: MenuEnum.WorksheetRequestDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "Job Prep",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.Dashboard],
        menuAccess: [
            {
                menuName: MenuEnum.History,
                actionAccess: []
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "Data Center",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.WorksheetJobData, FeatureEnum.Dashboard, FeatureEnum.BillJob],
        menuAccess: [
            {
                menuName: MenuEnum.History,
                actionAccess: [ActionEnum.ActionHold, ActionEnum.ActionCancel, ActionEnum.ActionWaiting]
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.SupportingDocumentReport,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCheckJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetHoldJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCancelJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetFielding,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.SupportingDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetSupportingDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMapReq,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetAllJob,
                actionAccess: [ActionEnum.ActionHold, ActionEnum.ActionWaiting, ActionEnum.ActionCancel, ActionEnum.Edit]
            },
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.GDev,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "MRE",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.Dashboard],
        menuAccess: [
            {
                menuName: MenuEnum.History,
                actionAccess: []
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMRE,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            }
        ]
    },
    {
        roleName: "QC MRE",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.Dashboard, FeatureEnum.BillJob],
        menuAccess: [
            {
                menuName: MenuEnum.History,
                actionAccess: []
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetQC,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetMRE,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetQCMRE,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetKatapult,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.BillJob,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.ReportBilling,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillingStatistic,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
    {
        roleName: "Katapult",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.Dashboard],
        menuAccess: [
            {
                menuName: MenuEnum.History,
                actionAccess: []
            },
            {
                menuName: MenuEnum.StaffReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.JobReport,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJob,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPrep,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.WorksheetKatapult,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Dashboard,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            }
        ]
    },
    {
        roleName: "Freelance",
        featureAccess: [FeatureEnum.Report, FeatureEnum.Worksheet, FeatureEnum.WorksheetProcess, FeatureEnum.BillJob],
        menuAccess: [
            {
                menuName: MenuEnum.History,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetCalcSpec,
                actionAccess: [ActionEnum.Attachment, ActionEnum.ReadyToQC, ActionEnum.ChangePoleStatus]
            },
            {
                menuName: MenuEnum.WorksheetRequestDocument,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.Profile,
                actionAccess: []
            },
            {
                menuName: MenuEnum.WorksheetJobPanel,
                actionAccess: [ActionEnum.All]
            },
            {
                menuName: MenuEnum.BillJob,
                actionAccess: [ActionEnum.All]
            },
        ]
    },
];