import React from 'react';
import { Alert, Progress } from 'flowbite-react';
import { RequestStatus } from 'http-client';
import { isArrayEmpty } from 'helpers/checkEmptiness';
import { IoCheckmarkCircle, IoWarning } from 'react-icons/io5';
import Button from 'components/Button/Button';
import { reset } from 'features/worksheet/process/job/job.reducer';
import { UploadProgress, UploadStatus } from 'features/basic/fileAttachment/models';

const FileProgress = ({
    uploadStatus = [],
    uploadProgress = [],
    isLoading = false,
    showSavedData = false,
    savedSuccessText = 'job saved successfully.',
    ...props
}: FileProgressProps) => {
    const calc = uploadProgress.reduce((acc, item) => acc + item.progress, 0);
    const percentage = calc / uploadProgress.length;
    return (
        <div className="p-5">
            {showSavedData && (
                <div className="my-2">
                    <Alert color="success">
                        <span>
                            <span className="font-medium">Success!</span>{' '}
                            {savedSuccessText}
                        </span>
                    </Alert>
                </div>
            )}
            <>
                {uploadStatus.some(
                    (item) => item.status === RequestStatus.pending
                ) && (
                    <div className="my-2">
                        <div
                            className="gap-2 p-4 text-sm text-blue-700 bg-blue-100 border-blue-500 dark:bg-blue-200 dark:text-blue-800 rounded-lg"
                            role="alert"
                        >
                            <div>
                                <div className="flex">
                                    <span>
                                        <span className="font-medium ">
                                            Upload File!
                                        </span>{' '}
                                        Uploading file, please wait...
                                    </span>
                                </div>
                                <div className="mt-2">
                                    <Progress
                                        progress={percentage}
                                        color="blue"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!isArrayEmpty(uploadStatus) &&
                    uploadStatus.every(
                        (item) => item.status !== RequestStatus.pending
                    ) && (
                        <div className="my-2">
                            <div
                                className="gap-2 p-4 text-sm text-blue-700 bg-blue-100 border-blue-500 dark:bg-blue-200 dark:text-blue-800 rounded-lg"
                                role="alert"
                            >
                                <div>
                                    <div className="flex">
                                        <span>
                                            <span className="font-medium ">
                                                File List
                                            </span>
                                        </span>
                                    </div>
                                    <div className="mt-2">
                                        {uploadStatus.map((item, index) => (
                                            <span
                                                key={`file-${index}`}
                                                className={`${
                                                    item.status ===
                                                    RequestStatus.success
                                                        ? 'text-primary-color'
                                                        : 'text-danger-color'
                                                } block`}
                                            >
                                                {item.status ===
                                                RequestStatus.success ? (
                                                    <IoCheckmarkCircle className="inline mr-2" />
                                                ) : (
                                                    <IoWarning className="inline mr-2" />
                                                )}
                                                {item.filename}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="mt-2">
                                        {uploadStatus.some(
                                            (item) =>
                                                item.status ===
                                                RequestStatus.failed
                                        ) && (
                                            <span className="text-danger-color font-bold">
                                                Some files failed to upload,
                                                please try again later
                                            </span>
                                        )}
                                        {uploadStatus.every(
                                            (item) =>
                                                item.status ===
                                                RequestStatus.success
                                        ) && (
                                            <span className="text-primary-color font-bold">
                                                File uploaded successfully
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </>

            <div className="my-2">
                <Button
                    color="success"
                    title="Close"
                    onClick={() => {
                        reset();
                        props.setShowModal(false);
                    }}
                    disabled={uploadStatus.some(
                        (item) => item.status === RequestStatus.pending
                    )}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export interface FileProgressProps {
    uploadStatus: UploadStatus[];
    uploadProgress: UploadProgress[];
    isLoading?: boolean;
    setShowModal: (showModal: boolean) => void;
    showSavedData?: boolean;
    savedSuccessText?: string;
}

export default FileProgress;
