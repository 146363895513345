import { Timeline } from 'flowbite-react';
import React from 'react';
import {changelog} from "../constants";
const ChangeLogPage = () => {
    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Changelog</h1>
                </div>
                <div className="mt-3">
                    <Timeline>
                        {
                            changelog.map((item, index) => (
                                <Timeline.Item key={`changelog-${index}`}>
                                    <Timeline.Point />
                                    <Timeline.Content>
                                        <Timeline.Time>
                                            {item.date}
                                        </Timeline.Time>
                                        <Timeline.Title>
                                            {item.title} ({item.version})
                                        </Timeline.Title>
                                        <Timeline.Body>
                                            <p>
                                                {item.changes}
                                            </p>
                                        </Timeline.Body>
                                    </Timeline.Content>
                                </Timeline.Item>
                            ))
                        }
                    </Timeline>
                </div>
            </div>
        </div>
    );
};

export default ChangeLogPage;
