import requireAuth from '../../requireAuth/requireAuth';
import React, {Fragment, useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Menus} from '../components/MenuList';
import SidebarBrand from '../components/SidebarBrand';
import SidebarItem from '../components/SidebarItem';
import {HiChevronDoubleLeft} from "react-icons/hi";
import {darkGreen, IS_WARNING_SHOWN, LAST_ATTENDANCE, LSK_USER_SLICE} from "../../../constants";
import Swal, {SweetAlertIcon} from "sweetalert2";
import {updateAttachment} from "../../basic/attachment/attachment.reducer";
import {SupportingDocumentEnum} from "../../worksheet/process/job/models";
import withReactContent from "sweetalert2-react-content";
import Button from "../../../components/Button/Button";
import NavbarHeader from "../components/NavbarHeader";
import {useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";
import {UserCredential} from "../../login/models";
import AttendanceAlert from "../components/AttendanceAlert";
import DefaultModal from "../../../components/Modal/DefaultModal";
import DepartmentDetailPage from "../../master/department/containers/DepartmentDetailPage";
import {checkRoleFeatureAccess} from "../../../routes/checkRoleAccess";
import {FeatureEnum} from "../../../routes/models";
// import {DarkModeToggle} from "../../../components/DarkMode/DarkModeToggle";

const SidebarPanel = () => {
    const MySwal = withReactContent(Swal);

    const [open, setOpen] = useState<boolean>(true);
    const [currentMenu, setCurrentMenu] = useState<Array<string>>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    const isAttendanceToday = useAppSelector((state: RootState) => state.user.isAttendanceToday ?? {} as UserCredential);
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const isWarningBoxShown = localStorage.getItem(IS_WARNING_SHOWN);
    const last_attendance = localStorage.getItem(LAST_ATTENDANCE);

    useEffect(() => {
        if (!isAttendanceToday && isWarningBoxShown === "1") {
            setShowModal(true);
        }
    }, [isWarningBoxShown, isAttendanceToday, last_attendance]);

    useEffect(() => {
    if (last_attendance !== null) {
            const newDate = new Date().toLocaleDateString();
            const lastAttendanceDate = new Date(last_attendance).toLocaleDateString();
            if (newDate !== lastAttendanceDate) {
                setShowModal(true);
            }
        }
    }, [last_attendance]);


    const onAttendanceClose = () => {
        setShowModal(false);
        localStorage.setItem(IS_WARNING_SHOWN, "0");
    }

    const toggleOpen = () => {
        setOpen((prevState) => !prevState);
    };

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const toggleSubmenuOpen = (name: string) => {
        const _currentMenu = currentMenu.some((current) => current === name)
            ? currentMenu.filter((current) => current !== name)
            : [...currentMenu, name];
        setCurrentMenu(_currentMenu);
    };

    return (
        <div className="flex">
            <div
                className={`bg-sidebar-bg dark:bg-primary-color-dark h-screen ${
                    open ? 'w-[245px]' : 'w-18'
                } z-[999] duration-300 fixed min-h-[500px] overflow-x-hidden overflow-y-auto custom-scrollbar dark:custom-scrollbar-dark`}
            >

                <SidebarBrand open={open} toggleOpen={toggleOpen}/>

                <ul className=" p-3 pt-20 mt-2">
                    {Menus.map((menu, index) => (
                        <Fragment key={'sidebar-item-' + index}>
                            {checkRoleFeatureAccess(user.roleName, menu.id ?? FeatureEnum.All) &&
                                <div>
                                    <SidebarItem
                                        menu={menu}
                                        open={open}
                                        currentMenu={currentMenu}
                                        toggleSubmenuOpen={toggleSubmenuOpen}
                                    />
                                </div>
                            }
                        </Fragment>
                    ))}
                </ul>

                <div className="relative mt-3">
                    <hr className="border-gray-300"/>
                    {/*<DarkModeToggle />*/}
                    <div className={"flex"}>
                        {
                            open && (
                                <small className={"flex flex-grow items-center mt-3 text-gray-300 ml-3"}>
                                    version: {process.env.REACT_APP_VERSION}
                                </small>
                            )
                        }
                        <span
                            className={`mt-3 text-gray-300 duration-300 text-sm flex-row-reverse flex items-center ${!open && "justify-center"} cursor-pointer p-2 hover:bg-light-white rounded-md `}
                            onClick={toggleOpen}
                        >

                        <HiChevronDoubleLeft
                            className={`text-2xl duration-300 text-gray-300 hover:text-sidebar-text cursor-pointer ${
                                !open && 'rotate-180'
                            }`}
                        />
                    </span>
                    </div>

                </div>
            </div>

            <div
                className={`dark:bg-main-dark-bg  bg-main-bg min-h-screen duration-300 w-[100%]`}
            >
                <div
                    className={`fixed w-[100%] z-[998] bg-primary-color dark:bg-primary-color-dark duration-300 ${open ? 'pl-[280px]' : 'pl-[120px]'}`}>
                    <NavbarHeader/>
                </div>
                <div className={`${open ? 'pl-[280px]' : 'pl-[100px]'} px-8 z-10 pt-20 pb-8`}>
                    <Outlet/>
                </div>
            </div>
            <DefaultModal
                title="Information"
                show={showModal}
                onClose={toggleModal}
                body={
                    <AttendanceAlert
                        onClose={onAttendanceClose}
                        userId={user.user_id}
                    />
                }
            />
        </div>
    );
};

export default requireAuth(SidebarPanel);