import { BaseServiceClass } from 'base-service-class';
import httpClient, {ApiResponseWrapper} from 'http-client';
import {
    FullJobAttachment,
    GetJobAttachmentRequest, GetJobLinkAttachmentRequest,
    JobAttachment,
    JobAttachmentRequest,
    JobAttachmentType, JobLinkAttachment
} from "./models";

export default class AttachmentApi extends BaseServiceClass {
    static getAttachment(args: GetJobAttachmentRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobAttachment[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    skip: args.skip,
                    take: args.take,
                    job_id: args.job_id,
                },
            })
            .then((res) => res.data);
    }
    static getAttachmentSingleList(jobId: string, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobAttachment[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_single_list}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    job_id: jobId,
                },
            })
            .then((res) => res.data);
    }
    static getAttachmentType(args: GetJobAttachmentRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobAttachmentType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_type}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    skip: args.skip,
                    take: args.take,
                    job_id: args.job_id,
                },
            })
            .then((res) => res.data);
    }
    static getJobLinkAttachment(args: GetJobLinkAttachmentRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobLinkAttachment[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_job_link}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    skip: args.skip,
                    take: args.take,
                    job_id: args.job_id,
                },
            })
            .then((res) => res.data);
    }
    static createAttachment(args: JobAttachmentRequest, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }

    static updateAttachment(args: JobAttachmentRequest, token: string) {
        return httpClient
            .put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }

    static updateFullAttachment(args: FullJobAttachment, token: string) {
        return httpClient
            .put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_full}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }
}
