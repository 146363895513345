import React, { useState } from 'react';
import HolidayCalendarTable from '../components/HolidayCalendarTable';
import HolidayCalendarDetailPage from './HolidayCalendarDetailPage';
import DefaultModal from 'components/Modal/DefaultModal';
import Button from 'components/Button/Button';
import EmployeeHolidayTable from "../components/EmployeeHolidayTable";
import EmployeeHolidayDetailPage from "./EmployeeHolidayDetailPage";

const HolidayPage = () => {
    const [holidayCalendarShowModal, setHolidayCalendarShowModal] = useState<boolean>(false);
    const [employeeHolidayShowModal, setEmployeeHolidayShowModal] = useState<boolean>(false);
    const [isHolidayCalendarEdit, setIsHolidayCalendarEdit] = useState<boolean>(false);
    const [isEmployeeHolidayEdit, setIsEmployeeHolidayEdit] = useState<boolean>(false);

    const toggleHolidayCalendarModal = () => {
        setHolidayCalendarShowModal((prevState) => !prevState);
    };

    const toggleEmployeeHolidayModal = () => {
        setEmployeeHolidayShowModal((prevState) => !prevState);
    };

    const openHolidayCalendarModal = (isEdit: boolean) => {
        setIsHolidayCalendarEdit(isEdit);
        setHolidayCalendarShowModal(true);
    };

    const openEmployeeHolidayModal = (isEdit: boolean) => {
        setEmployeeHolidayShowModal(true);
        setIsEmployeeHolidayEdit(isEdit);
    };

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                    Master Holiday Calendar
                </h1>
                <div className="">
                    <Button
                        title="New Holiday Calendar"
                        onClick={() => openHolidayCalendarModal(false)}
                    />
                </div>
            </div>
            <div className="mt-3">
                <HolidayCalendarTable openModal={openHolidayCalendarModal} />

                <DefaultModal
                    title="Holiday Calendar"
                    show={holidayCalendarShowModal}
                    onClose={toggleHolidayCalendarModal}
                    body={
                        <HolidayCalendarDetailPage
                            isEdit={isHolidayCalendarEdit}
                            toggleModal={toggleHolidayCalendarModal}
                        />
                    }
                />
            </div>
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                    Master Employee Holiday
                </h1>
                <div className="">
                    <Button
                        title="New Employee Holiday"
                        onClick={() => openEmployeeHolidayModal(false)}
                    />
                </div>
            </div>
            <div className="mt-3">
                <EmployeeHolidayTable openModal={openEmployeeHolidayModal} closeModal={() => setEmployeeHolidayShowModal(false)} />

                <DefaultModal
                    title="Employee Holiday"
                    show={employeeHolidayShowModal}
                    onClose={toggleEmployeeHolidayModal}
                    body={
                        <EmployeeHolidayDetailPage
                            isEdit={isEmployeeHolidayEdit}
                            toggleModal={toggleEmployeeHolidayModal}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default HolidayPage;
