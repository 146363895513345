import {CustomFlowbiteTheme} from "flowbite-react";
import {GlobalApprovalEnum} from "./types";

export const SECRET_KEY = "qms-kpa_indonesia"

export const dummyAuth = false;
export const LSK_USER_SLICE = "__qmuser";
export const IS_WARNING_SHOWN = "__isWarningBoxShown";
export const LAST_ATTENDANCE = "__last_attendance";

export const darkGreen = "rgb(106, 131, 144)";
export const darkRed = "#d33";

export const MAX_PAGING_OPTIONS = [10, 50, 100, 200, 500, 1000];

export const JOB_STATUS_WAITING = "Waiting";
export const JOB_STATUS_HOLD = "Hold";
export const JOB_STATUS_NEW = "New";
export const JOB_STATUS_PREP = "Job Prep";
export const JOB_STATUS_READY_TO_ASSIGN = "Ready To Assign";
export const JOB_STATUS_CALC_SPEC = "Calc Spec";
export const JOB_STATUS_READY_TO_QC = "Ready To QC";
export const JOB_STATUS_QC = "QC";
export const JOB_STATUS_CANCEL = "Cancel";
export const JOB_STATUS_NEW_MRE = "New MRE";
export const JOB_STATUS_MRE = "MRE";
export const JOB_STATUS_WAITING_MRE = "Waiting MRE";
export const JOB_STATUS_READY_TO_QC_MRE = "Ready To QC MRE";
export const JOB_STATUS_QC_MRE = "QC MRE";
export const JOB_STATUS_CANCEL_MRE = "Cancel MRE";
export const JOB_STATUS_NEW_KATAPULT = "New Katapult";
export const JOB_STATUS_KATAPULT = "Katapult";
export const JOB_STATUS_WAITING_KATAPULT = "Waiting Katapult";

export const JOB_STATUS_CANCEL_KATAPULT = "Cancel Katapult";
export const JOB_STATUS_COMPLETE = "Complete";

export const JOB_STATUS_COMPLETE_MRE = "Complete MRE";
export const JOB_STATUS_COMPLETE_KATAPULT = "Complete Katapult";

export const globalApprovalStatus = [
    {
        label: "Requested",
        text: "Requested",
        value: GlobalApprovalEnum.Requested.toString()
    },
    {
        label: "Approved",
        text: "Approved",
        value: GlobalApprovalEnum.Approved.toString()
    },
    {
        label: "Rejected",
        text: "Rejected",
        value: GlobalApprovalEnum.Rejected.toString()
    }
]
export const theme: CustomFlowbiteTheme = {
    navbar: {
        link: {
            base: "block py-2 pr-4 pl-3 md:p-0 duration-300",
            active: {
                on: "bg-cyan-700 text-white dark:text-white md:bg-transparent md:text-white-700",
                off: "border-b border-gray-100 text-gray-300 hover:bg-white-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-white md:dark:hover:bg-transparent md:dark:hover:text-white"
            },
            disabled: {
                on: "text-gray-400 hover:cursor-not-allowed dark:text-gray-600",
                off: ""
            },

        }
    }
}