import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {isArrayEmpty, isObjectEmpty} from 'helpers/checkEmptiness';
import {EmployeeWorkInput, StaffReportModel} from "../../models";
import BasicSelectInput, {Option, Options} from "../../../../../components/SelectInput/BasicSelectInput";
import {StaffReportStatusEnum, typeOptions} from "../../constants";
import BasicDatePicker from "../../../../../components/DatePicker/BasicDatePicker";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import {Job} from "../../../../worksheet/process/job/models";
import {RootState} from "../../../../../app/store";
import {MultiValue} from "react-select";
import {dateSetTimeToNow} from "../../../../../helpers/convert";
import {createStaffReport, updateStaffReport} from "../../staffReport.reducer";
import AsyncSelect from "react-select/async";
import {getJobDropdown} from "../../../../worksheet/process/job/job.reducer";
import {unwrapResult} from "@reduxjs/toolkit";
const ApproveEmployeeWorkForm = ({
                                     singleEmployeeWork = {} as StaffReportModel,
                                     userId = '',
                                     positionId = '',
    isEdit = false,
                                 }: ApproveEmployeeWorkFormProps) => {
    const dispatch = useAppDispatch();
    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: {errors},
    } = useForm<EmployeeWorkInput>({
        defaultValues: {
            job_number: [],
            requestDate: dateSetTimeToNow(new Date().toISOString()) ?? new Date().toISOString(),
        }
    });

    const watchJobNumber = watch('job_number');

    const [jobNumberOptions, setJobNumberOptions] = useState<Array<Option>>([]);
    const [hide, setHide] = useState<string | undefined>(undefined);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const jobListState = useAppSelector(
        (state: RootState) => state.job.list ?? []
    );

    useEffect(() => {
        if (!isObjectEmpty(singleEmployeeWork)) {
            const selectedJobNumber = singleEmployeeWork?.number_job?.split(',').map((jobNumber: string) => {
                return {
                    text: jobNumber,
                    label: jobNumber,
                    value: jobNumber,
                };
            });
            reset({
                requestDate: singleEmployeeWork.requestDate === "0001-01-01T00:00:00" ? new Date().toISOString() : singleEmployeeWork.requestDate,
                type: singleEmployeeWork.type.toString(),
                reqMinute: singleEmployeeWork.reqMinute.toString(),
                reqPole: singleEmployeeWork.reqPole.toString(),
                note: singleEmployeeWork.note,
                job_number: selectedJobNumber,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleEmployeeWork]);

    useEffect(() => {
        if (!isArrayEmpty(jobListState)) {
            let _options = jobListState.map((job: Job) => {
                return {
                    text: job.number_job,
                    label: job.number_job,
                    value: job.number_job,
                };
            });
            setJobNumberOptions([
                ..._options,
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobListState]);

    const onSubmit = (data: EmployeeWorkInput) => {
        let _input = {
            number_job: data.job_number.map((job: Option) => job.value).join(','),
            role_id: positionId,
            note: data.note,
            reqMinute: parseInt(data.reqMinute),
            reqPole: parseInt(data.reqPole),
            approvalMinute: 0,
            approvalPole: 0,
            rejectionNote: "",
            status: StaffReportStatusEnum.Requested,
            type: parseInt(data.type),
            requestDate: data.requestDate,
            userId: userId,
        };
        if (isEdit) {
            let update = {..._input, id: singleEmployeeWork?.id}
            dispatch(updateStaffReport(update));
        } else {
            dispatch(createStaffReport(_input));
        }
    };

    const onJobNumberChange = (val: MultiValue<Option>) => {
        setValue('job_number', val);
    };

    const getDropdownJob = async(inputValue: string) => {
        const result = await dispatch(
            getJobDropdown({
                skip: 0,
                take: 100,
                job_number: inputValue.toLowerCase() === "" ? "a" : inputValue.toLowerCase(),
            })
        )

        const originalPromiseResult = unwrapResult(result);

        let _option = originalPromiseResult?.data?.map((item: any) => {
            return {
                label: `${item.number_job} | ${item.number_id}`,
                text: `${item.number_job} | ${item.number_id}`,
                value: item.number_job,
            }
        });
        return _option as Options[];
    }

    const promiseOptions = (inputValue: string) =>
        new Promise<Options[]>((resolve) => {
            clearTimeout(timer);
            setTimer(
                setTimeout(() => {
                    resolve(getDropdownJob(inputValue));
                }, 1000)
            );
        });

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <BasicDatePicker
                    label="Request Date"
                    name="requestDate"
                    placeholderText="Select Request Date"
                    control={control}
                    withPreciseTime
                />
                <BasicSelectInput
                    label="Type Add Poles"
                    message={errors.type?.message}
                    options={typeOptions}
                    {...register('type')}
                />
                {/*<SelectInput*/}
                {/*    options={jobNumberOptions}*/}
                {/*    isMulti*/}
                {/*    label="Job Number"*/}
                {/*    onChange={onJobNumberChange}*/}
                {/*    value={watchJobNumber}*/}
                {/*/>*/}
                <div className="my-2">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Job Number</label>
                    </div>
                    <div>
                        {/*<SelectInput*/}
                        {/*    key={`numberJobId`}*/}
                        {/*    options={jobOptions}*/}
                        {/*    isLoading={isJobLoading}*/}
                        {/*    onChange={(val) => onOptionChange(val, 'numberJobId')}*/}
                        {/*    value={selectedJobNumber}*/}
                        {/*/>*/}
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        display: hide && "none",*/}
                        {/*        position: "absolute",*/}
                        {/*        marginLeft: "10px",*/}
                        {/*        marginTop: "10px",*/}
                        {/*        zIndex: 10*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {selectedJobNumber && selectedJobNumber?.label}*/}
                        {/*</div>*/}
                        <AsyncSelect cacheOptions defaultOptions isMulti loadOptions={promiseOptions}
                                     value={watchJobNumber}
                                     onChange={onJobNumberChange}
                                     // onInputChange={setHide}
                                     // components={{
                                     //     MultiValue: () => {
                                     //         return null;
                                     //     }
                                     // }}
                                     styles={{
                                         menu: provided => ({...provided, zIndex: 9999})
                                     }}
                        />
                    </div>
                </div>
                <InputText
                    {...register('reqMinute')}
                    label="Request Minute"
                    placeholder="Input request minute"
                    type="number"
                    message={errors.reqMinute?.message}
                />
                <InputText
                    {...register('reqPole')}
                    label="Request Pole"
                    placeholder="Input request pole"
                    type="number"
                    message={errors.reqPole?.message}
                />
                <InputText
                    {...register('note')}
                    label="Notes"
                    placeholder="Input notes"
                    message={errors.note?.message}
                />

                <div className="pt-2 pb-2">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface ApproveEmployeeWorkFormProps {
    singleEmployeeWork?: StaffReportModel;
    userId?: string;
    positionId?: string;
    isEdit?: boolean;
}

export default ApproveEmployeeWorkForm;
