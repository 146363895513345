import {HttpClientError, RequestStatus} from "../../../http-client";
import {GetJobStatistic, InputFilter, JobIn, JobStatistic, LineChartModel} from "./models";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import JobReportApi from "./jobReport.api";
import FileSaver from "file-saver";


export interface JobReportSlice {
    jobStatisticList?: Array<JobStatistic>;
    jobStatisticChartList?: LineChartModel;
    jobInList?: Array<JobIn>;
    jobInChartList?: LineChartModel;
    isLoading?: boolean;
    isGetJobStatisticLoading?: boolean;
    isExportStatisticLoading?: boolean;
    isGetJobInLoading?: boolean;
    isExportInLoading?: boolean;
    status?: RequestStatus;
    exportStatisticStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getJobStatistic = createAsyncThunk(
    'jobReport/getJobStatistic',
    async (args: GetJobStatistic, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobReportApi.getJobStatistic(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getJobIn = createAsyncThunk(
    'jobReport/getJobIn',
    async (args: GetJobStatistic, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobReportApi.getJobIn(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const exportToCsv = createAsyncThunk(
    'jobReport/exportToCsv',
    async (args: InputFilter, { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await JobReportApi.exportToCsv(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const exportJobInToCsv = createAsyncThunk(
    'jobIn/exportJobInToCsv',
    async (args: InputFilter, { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await JobReportApi.exportJobInToCsv(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const jobReportSlice = createSlice({
    name: 'jobReport',
    initialState: {} as JobReportSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.exportStatisticStatus = RequestStatus.pending;
        },
    },
    extraReducers: builder => {
        builder.addCase(getJobStatistic.pending, (state) => {
            state.isGetJobStatisticLoading = true;
        });
        builder.addCase(getJobStatistic.fulfilled, (state, action) => {
            state.isGetJobStatisticLoading = false;
            state.jobStatisticList = action.payload?.data;
            const _label = action.payload?.data?.map((item) => new Date(item.ts_created).toLocaleDateString('en-US'));
            const _total = action.payload?.data?.map((item) => item.completePole);
            state.jobStatisticChartList = {
                labels: _label,
                datasets: [
                    {
                        label: '# of Complete Poles',
                        data: _total,
                    }
                ]
            };
        });
        builder.addCase(getJobStatistic.rejected, (state, action) => {
            state.isGetJobStatisticLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getJobIn.pending, (state) => {
            state.isGetJobInLoading = true;
        });
        builder.addCase(getJobIn.fulfilled, (state, action) => {
            state.isGetJobInLoading = false;
            state.jobInList = action.payload?.data;
            const _label = action.payload?.data?.map((item) => new Date(item.ts_created).toLocaleDateString('en-US'));
            const _total = action.payload?.data?.map((item) => item.reqPole);
            state.jobInChartList = {
                labels: _label,
                datasets: [
                    {
                        label: '# of Req Poles',
                        data: _total,
                    }
                ]
            };
        });
        builder.addCase(getJobIn.rejected, (state, action) => {
            state.isGetJobInLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // export to csv download reducer
        builder.addCase(exportToCsv.pending, (state) => {
            state.isExportStatisticLoading = true;
            state.exportStatisticStatus = RequestStatus.pending;
        })
        builder.addCase(exportToCsv.fulfilled, (state, { payload }) => {
                FileSaver.saveAs(
                    payload,
                    'job_statistic' + new Date().toISOString() + '.xlsx'
                );
                state.isExportStatisticLoading = false;
                state.exportStatisticStatus = RequestStatus.success;
            })
        builder.addCase(exportToCsv.rejected, (state, { payload }) => {
                state.error = payload as HttpClientError;
                state.isExportStatisticLoading = false;
                state.exportStatisticStatus = RequestStatus.failed;
            });
        // export to csv download reducer
        builder.addCase(exportJobInToCsv.pending, (state) => {
            state.isExportInLoading = true;
        })
        builder.addCase(exportJobInToCsv.fulfilled, (state, { payload }) => {
            FileSaver.saveAs(
                payload,
                'job_in' + new Date().toISOString() + '.xlsx'
            );
            state.isExportInLoading = false;
        })
        builder.addCase(exportJobInToCsv.rejected, (state, { payload }) => {
            state.error = payload as HttpClientError;
            state.isExportInLoading = false;
        });
    }
});

export const { reset } = jobReportSlice.actions;
export default jobReportSlice.reducer;