import React, {forwardRef, useState} from 'react';
import DataTable, {TableColumn} from "react-data-table-component";
import {tableStyles} from "./table";
import {MAX_PAGING_OPTIONS} from "../../constants";
import {TableProps} from "react-data-table-component/dist/src/DataTable/types";
import styled from "styled-components";

interface Props {
    isSticky?: boolean;
    stickyColumn?: number;
}

const Wrapper = styled.div<Props>`
  ${({isSticky, stickyColumn}) => isSticky && stickyColumn &&
          `.rdt_TableCol:nth-child(${stickyColumn}),
            .rdt_TableCell:nth-child(${stickyColumn}) {
                position: sticky;
                right: 0;
                z-index: 1;
                background: inherit;
            }
            .rdt_TableCol:nth-child(${stickyColumn}),
            .rdt_TableCell:nth-child(${stickyColumn}) {
                left: 48px;
            }
          `}
`;

export interface BasicDataTableProps {
    columns: TableColumn<any>[];
    data: unknown[];
    rows?: number | undefined;
    handlePagingChange?: (rowsPerPage: number, page: number) => void;
    conditionalRowStyles?: TableProps<any>['conditionalRowStyles'];
    isSticky?: boolean;
    stickyColumn?: number;
    keyField?: string;
}

type BasicDataTableType = React.ForwardRefExoticComponent<BasicDataTableProps & TableProps<any> & React.RefAttributes<TableProps<any>>>;

const DataTableServerPaging: BasicDataTableType = forwardRef((props, ref ) => {
    const {
        columns = [],
        data = [],
        rows = 0,
        isSticky = false,
        stickyColumn = 0,
        conditionalRowStyles = [],
        ...rest
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        props.handlePagingChange?.(newPerPage, page);
    };

    const handlePageChange = (page: number) => {
        props.handlePagingChange?.(rowsPerPage, page);
    };

    return <Wrapper isSticky={isSticky} stickyColumn={stickyColumn}>
            <DataTable
                columns={columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={rows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noHeader
                highlightOnHover
                pointerOnHover
                customStyles={tableStyles}
                theme={'solarized'}
                striped
                paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                persistTableHead
                conditionalRowStyles={conditionalRowStyles}
                keyField={props.keyField}
                ref={ref}
                {...rest}
            />
        </Wrapper>
});


export default DataTableServerPaging;
