import { AiOutlineLogout } from 'react-icons/ai';
import {
    RiBillLine,
    RiDashboardLine,
    RiFileChartLine,
    RiFolderChartLine,
    RiTableLine,
} from 'react-icons/ri';
import { CgDatabase } from 'react-icons/cg';
import {MdFactCheck} from "react-icons/md";
import {TbChecklist} from "react-icons/tb";
import {checkRoleFeatureAccess} from "../../../routes/checkRoleAccess";
import {FeatureEnum, MenuEnum} from "../../../routes/models";
import {MenuModel} from "../models";

export const Menus: MenuModel[]  = [
    { title: 'Dashboard', icon: <RiDashboardLine />, url: 'dashboard', id: FeatureEnum.Dashboard },
    {
        title: 'Worksheet',
        icon: <RiTableLine />,
        submenu: true,
        id: FeatureEnum.Worksheet,
        submenuItems: [
            {
                title: 'Process',
                child: true,
                id: FeatureEnum.WorksheetProcess,
                childItems: [
                    { id: MenuEnum.WorksheetJob, title: 'Jobs', url: 'worksheet/job' },
                    { id: MenuEnum.WorksheetJobPrep ,title: 'Job Prep', url: 'worksheet/process/job-prep' },
                    { id: MenuEnum.WorksheetCalcSpec ,title: 'Calc Spec', url: 'worksheet/process/calc-spec' },
                    { id: MenuEnum.WorksheetQC ,title: 'QC', url: 'worksheet/process/qc' },
                    { id: MenuEnum.WorksheetMRE ,title: 'MRE', url: 'worksheet/process/mre' },
                    { id: MenuEnum.WorksheetKatapult ,title: 'KATAPULT', url: 'worksheet/process/katapult' },
                ],
            },
            {
                title: 'Job Data',
                id: FeatureEnum.WorksheetJobData,
                child: true,
                childItems: [
                    { id: MenuEnum.WorksheetCheckJob ,title: 'Check Jobs', url: 'worksheet/check-job' },
                    { id: MenuEnum.WorksheetFielding ,title: 'Fielding', url: 'worksheet/fielding' },
                    {
                        id: MenuEnum.WorksheetSupportingDocument ,
                        title: 'Supporting Document',
                        url: 'worksheet/supporting-document',
                    },
                    { id: MenuEnum.WorksheetMapReq ,title: 'Map Req', url: 'worksheet/map-req' },
                ],
            },
        ],
    },
    {
        title: 'Bill Job',
        icon: <RiBillLine />,
        submenu: true,
        id: FeatureEnum.BillJob,
        submenuItems: [
            { id: MenuEnum.BillJob ,title: 'Bill Job', url: 'bill-job/create-bill-job' },
            { id: MenuEnum.ReportBilling ,title: 'Report Billing', url: 'bill-job/report-billing' },
            { id: MenuEnum.BillingStatistic ,title: 'Statistic', url: 'bill-job/billing-statistic' },
            { id: MenuEnum.GDev ,title: 'GDev', url: 'bill-job/gdev' },
        ],
    },
    {
        title: 'Report',
        icon: <RiFolderChartLine />,
        id: FeatureEnum.Report,
        submenu: true,
        submenuItems: [
            { id: MenuEnum.History ,title: 'History', url: 'report/history' },
            { id: MenuEnum.StaffReport ,title: 'Staff Report', url: 'report/staff-report' },
            { id: MenuEnum.Attendance ,title: 'Attendance', url: 'report/attendance' },
            { id: MenuEnum.JobReport ,title: 'Job Report', url: 'report/job-report' },
            { id: MenuEnum.SupportingDocumentReport ,title: 'Supporting Document', url: 'report/supporting-document' },
        ],
    },
    {
        title: 'Approval',
        icon: <TbChecklist />,
        submenu: true,
        id: FeatureEnum.Approval,
        submenuItems: [
            { id: MenuEnum.EmployeeWorkApproval ,title: 'Employee Work', url: 'approval/employee-work' },
            { id: MenuEnum.OvertimeApproval ,title: 'Overtime', url: 'approval/overtime' },
            { id: MenuEnum.AttendanceApproval ,title: 'Attendance', url: 'approval/attendance' },
            { id: MenuEnum.HolidayApproval ,title: 'Holiday', url: 'approval/holiday' },
        ],
    },
    {
        title: 'Master Data',
        icon: <CgDatabase />,
        submenu: true,
        id: FeatureEnum.Master,
        submenuItems: [
            { id: MenuEnum.Project ,title: 'Project', url: 'project' },
            { id: MenuEnum.District ,title: 'District', url: 'district' },
            { id: MenuEnum.Department ,title: 'Department', url: 'department' },
            { id: MenuEnum.DepartmenOwner ,title: 'Department Owner', url: 'department-owner' },
            { id: MenuEnum.Team ,title: 'Team', url: 'team' },
            { id: MenuEnum.JobType ,title: 'Type', url: 'job-type' },
            { id: MenuEnum.JobCategory ,title: 'Category', url: 'job-category' },
            { id: MenuEnum.JobSubjectNotes ,title: 'Subject Notes', url: 'job-subject-notes' },
            { id: MenuEnum.JobStatus ,title: 'Status', url: 'job-status' },
            { id: MenuEnum.JobKind ,title: 'KoJ', url: 'job-kind' },
            { id: MenuEnum.PoleType ,title: 'Pole Type', url: 'pole-type' },
            { id: MenuEnum.PoleStatus ,title: 'Pole Status', url: 'pole-status' },
            { id: MenuEnum.JobSource ,title: 'Source', url: 'job-source' },
            { id: MenuEnum.Employee ,title: 'Employee', url: 'employee' },
            { id: MenuEnum.Position ,title: 'Position', url: 'position' },
            // {title: "Email Title", url: 'email-title'},
            { id: MenuEnum.SupportingDocument ,title: 'Supporting Document', url: 'master/supporting-document' },
            { id: MenuEnum.Holiday ,title: 'Holiday', url: 'master/holiday' },
        ],
    },
];