import {Calendar, CalendarProps, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import "./styles.css"

const localizer = momentLocalizer(moment);

export type CalendarEvent = {
    title: string;
    start: Date;
    end: Date;
    allDay?: boolean;
}

const MyCalendar = (props: CalendarProps) => (
    <div>
        <Calendar
            startAccessor="start"
            endAccessor="end"
            style={{ height: 600 }}
            {...props}
        />
    </div>
);

export default MyCalendar;