import React, {useCallback, useEffect, useRef, useState} from 'react';
import {QCType} from '../models';
import {MdPauseCircleOutline} from 'react-icons/md';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import DefaultModal from '../../../../../components/Modal/DefaultModal';
import {customStatusOption, customWarnOption} from '../../../../../helpers/alertLabel';
import {useAppDispatch, useAppSelector} from '../../../../../helpers/redux';
import AssignQC from './AssignQC';
import {RootState} from '../../../../../app/store';
import {Link} from 'react-router-dom';
import {InputStatusModel} from 'features/worksheet/process/job/models';
import {TbHandGrab} from 'react-icons/tb';
import {getQCWithFilterList} from "../qc.reducer";
import {reset, waitingJob} from '../../job/job.reducer';
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import JobChangeStatus from "../../job/components/JobChangeStatus";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";
import {QCSortOption} from "../constants";

const QCTable = () => {
    const gridRef = useRef<any>(null);
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedJobId, setSelectedJobId] = useState<string | undefined>(undefined);

    const [showPauseModal, setShowPauseModal] = useState<boolean>(false);
    const [jobId, setJobId] = useState<string>('');

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const status = useAppSelector((state: RootState) => state.qc.status);
    const statusPole = useAppSelector((state: RootState) => state.pole.status);

    const actionStatus = useAppSelector((state: RootState) => state.job.actionStatus);
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (status === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (statusPole === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusPole]);

    useEffect(() => {
        if (actionStatus !== undefined && actionStatus !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(actionStatus === RequestStatus.success, error.data?.message, 'status has been changed!', 'failed to change status')).then(() => {
                if (actionStatus === RequestStatus.success) {
                    toggleModal();
                    refreshCache(undefined);
                }
                dispatch(reset());
                setShowModal(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatus, error]);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setSelectedJobId(undefined);
    };

    const openModal = (data: QCType) => {
        setShowModal(true);
        setSelectedJobId(data?.id);
    };

    const togglePauseModal = () => {
        setShowPauseModal((prevState) => !prevState);
        setJobId('');
    };

    const openPauseModal = (id: string) => {
        setShowPauseModal(true);
        setJobId(id);
    };

    const onStatusClicked = (id: string, _action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(waitingJob({
                    job_id: id,
                    waiting_notes: data?.notes ?? '',
                    waiting_subject_id: data?.pause_subject_id ?? '',
                    waiting_by_user: user.user_id
                }));
            }
        });
    };

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-ocalc/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex align-middle h-[100%]">
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetQC, ActionEnum.ActionAssign) &&
                    <button onClick={() => openModal(props.data)}>
                        <TbHandGrab className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetQC, ActionEnum.ActionWaiting) &&
                    <button onClick={() => openPauseModal(props.data?.id)}>
                        <MdPauseCircleOutline className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {
            field: 'project_name', headerName: 'Project',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'department_name', headerName: 'Department',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'department_owner_name', headerName: 'Department Owner', filter: false, sortable: false},
        {field: 'district_name', headerName: 'District', filter: false, sortable: false},
        {
            field: 'po_number', headerName: 'PO Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'type_name', headerName: 'Type', filter: false, sortable: false},
        {
            field: 'kind_name', headerName: 'KoJ',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'ts_created',
            headerName: 'Request Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'number_id', headerName: 'ID Number',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Job Number',
            field: 'number_job',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'other_number', headerName: 'Other Number',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'status_name', headerName: 'Status',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'date_due',
            headerName: 'Due Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Get Percentage',
            field: 'percent_qc',
            suppressCellFlash: true,
            cellRenderer: (props: any) => <span>{props.data?.percent_qc.toFixed(0) + '%'}</span>,
            filter: false,
            sortable: false
        },
        {
            field: 'date_assigned_qc',
            headerName: 'Date Assigned',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'pole_total', headerName: 'Request Pole',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(QCSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {dateFrom: startDueDate, dateTo: endDueDate} = params.request.filterModel?.date_due || {};
                const {
                    dateFrom: startDateAssign,
                    dateTo: endDateAssign
                } = params.request.filterModel?.date_assigned_qc || {};

                dispatch(getQCWithFilterList(
                    {
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        project_name: params.request.filterModel?.project_name?.filter,
                        department_name: params.request.filterModel?.department_name?.filter,
                        kind_job: params.request.filterModel?.kind_name?.filter,
                        startRequestDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endRequestDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        number_id: params.request.filterModel?.number_id?.filter,
                        number_job: params.request.filterModel?.number_job?.filter,
                        other_number: params.request.filterModel?.other_number?.filter,
                        po_number: params.request.filterModel?.po_number?.filter,
                        status: params.request.filterModel?.status_name?.filter,
                        startDueDate: startDueDate !== undefined ? dateSetTimeToNow(startDueDate) : undefined,
                        endDueDate: endDueDate !== undefined ? dateSetTimeToNow(endDueDate) : undefined,
                        startDateAssign: startDateAssign !== undefined ? dateSetTimeToNow(startDateAssign) : undefined,
                        endDateAssign: endDateAssign !== undefined ? dateSetTimeToNow(endDateAssign) : undefined,
                        req_pole: params.request.filterModel?.pole_total?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    }
                )).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            }
        }
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid mt-2">
                <div>
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'worksheet-qc'}
                                            defaultPageSize={20}
                    />
                </div>
                <DefaultModal
                    title="Get QC"
                    show={showModal}
                    onClose={toggleModal}
                    size={'7xl'}
                    maxHeight="full"
                    body={<AssignQC id={selectedJobId} onHide={toggleModal}/>}
                />
                <DefaultModal
                    title="Change Status"
                    show={showPauseModal}
                    onClose={togglePauseModal}
                    body={
                        <JobChangeStatus action={"waiting"} jobId={jobId} onSubmit={onStatusClicked}/>
                    }
                />
            </div>
        </div>
    );
};

export default QCTable;
