import Select, {GroupBase, Props} from 'react-select';
import React, {useState} from "react";

interface CustomSelectProps {
    label?: string;
    message?: string;
    supportCopyInputVal?: boolean;
    inputValue?: string;
}

function SelectInput<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group> & CustomSelectProps) {
    const {
        label = "",
        message = '',
        supportCopyInputVal = false,
        inputValue,
        ...rest
    } = props;

    const [hide, setHide] = useState<string | undefined>(undefined);

    return (
        <div className="col-span-6 sm:col-span-3">
            {
                label &&
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{label}</label>
            }
            {
                supportCopyInputVal ?
                    <>
                        <div
                            style={{
                                display: hide && "none",
                                position: "absolute",
                                marginLeft: "10px",
                                marginTop: "10px",
                                zIndex: 1
                            }}
                        >
                            {inputValue}
                        </div>
                        <Select onInputChange={setHide}
                                components={{
                                    SingleValue: () => {
                                        return null;
                                    }
                                }} styles={{
                            menu: provided => ({...provided, zIndex: 9999})
                        }} {...rest} />
                    </>
                    :
                    <Select {...rest}/>
            }
            {message !== '' && (
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {message}
                </p>
            )}
        </div>
    );
}


export default SelectInput;