import React, {useEffect, useRef, useState} from 'react';
import {TableColumn} from 'react-data-table-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';

import {DepartmentOwner} from '../models';
import {HttpClientError, RequestStatus} from 'http-client';
import {BiEditAlt} from 'react-icons/bi';
import {MdOutlineDeleteForever} from 'react-icons/md';
import {
    deleteDepartmentOwner,
    getList,
    reset,
    setSingle,
} from '../departmentOwner.reducer';
import {
    deletedStatusOption,
    deletedWarnOption, savedStatusOption,
} from '../../../../helpers/alertLabel';
import DataTableServerPaging from "../../../../components/Table/DataTableServerPaging";
import AgDataGridStatic from "../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";

const DepartmentOwnerTable = ({...props}: DepartmentOwnerTableProps) => {
    const gridApiRef = useRef<any>(null);
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const [rowIndex, setRowIndex] = useState<number>(0)

    const rows = useAppSelector(
        (state: RootState) => state.departmentOwner.rows ?? 0
    );
    const departmentOwnerList = useAppSelector(
        (state: RootState) => state.departmentOwner.list ?? []
    );
    const deleteStatus = useAppSelector(
        (state: RootState) => state.departmentOwner.deleteStatus
    );
    const error = useAppSelector(
        (state: RootState) =>
            state.departmentOwner.error ?? ({} as HttpClientError)
    );
    const status = useAppSelector((state: RootState) => state.departmentOwner.status);

    useEffect(() => {
        dispatch(getList({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (deleteStatus !== undefined && deleteStatus !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    deleteStatus === RequestStatus.success,
                    error.data?.message
                )
            ).then((res) => {
                dispatch(reset());
                dispatch(getList({})).then((res: any) => {
                    handleAutoSelectRow(res.payload?.data.length -1 ?? 0);
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus, error]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                dispatch(getList({})).then((res: any) => {
                    console.log(res.payload?.data.length);
                    handleAutoSelectRow(res.payload?.data.length -1 ?? 0);
                });
                props.openModal(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteDepartmentOwner(id));
            }
        });
    };

    const openFormModal = (single: DepartmentOwner, _rowIndex: number) => {
        props.openModal(true);
        dispatch(setSingle(single));
        setRowIndex(_rowIndex);
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex align-middle h-[100%]">
                <>
                    <button onClick={() => openFormModal(props.data, props.rowIndex)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                    <button onClick={() => onDeleteClicked(props.data?.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                    </button>
                </>
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'department_name', headerName: 'Department', filter: 'agTextColumnFilter'},
        {field: 'districts_name', headerName: 'District', filter: 'agTextColumnFilter'},
        {field: 'jobtypes_name', headerName: 'Type', filter: 'agTextColumnFilter'},
        {field: 'name', headerName: 'Department Owner', filter: 'agTextColumnFilter'},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const handleAutoSelectRow = (lastLength: number) => {
        // Get the first row node (you can adjust this logic as needed)
        const firstRowNode = gridApiRef.current.api.getRowNode(rowIndex ?? 0);

        console.log(rowIndex);

        // Select the row programmatically
        if (firstRowNode) {
            gridApiRef.current.api.ensureIndexVisible(firstRowNode.rowIndex < lastLength ? firstRowNode.rowIndex +1 : firstRowNode.rowIndex);
        }
    };

    return (
        <div className="main-data-grid mt-5">
            <AgDataGridStatic ref={gridApiRef}  columnDefs={columnDefs} rowData={departmentOwnerList}/>
            {/*<DataTableServerPaging columns={columns} data={departmentOwnerList} rows={rows} handlePagingChange={handlePagingChange} />*/}
        </div>
    );
};

export interface DepartmentOwnerTableProps {
    openModal: (isEdit: boolean) => void;
}

export default DepartmentOwnerTable;
