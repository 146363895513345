export enum HolidayCalendarTypeEnum
{
    US,Indonesia
}

export type HolidayCalendar = {
    id: string;
    date: string;
    name: string;
    type: HolidayCalendarTypeEnum;
}

export type EmployeeHoliday = {
    id: string;
    userId: string;
    name: string;
    holidayAvailable: number;
    totalHolidaysMax: number;
    totalHolidaysTaken: number;
    totalHolidaysSoon: number;
    totalHolidaysRemaining: number;
}

export type GetListRequest = {
    id?: string;
    skip?: string | number;
    take?: string | number;
}

export type HolidayCalendarReq = {
    id?: string;
    date: string;
    name: string;
    type: string | HolidayCalendarTypeEnum;
}

export type EmployeeHolidayReq = {
    id?: string;
    userId: string;
    name?: string;
    holidayAvailable: string | number;
    totalHolidaysMax: string | number;
    totalHolidaysTaken: string | number;
    totalHolidaysSoon: string | number;
    totalHolidaysRemaining: string | number;
}