import {BaseServiceClass} from "../../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../../http-client";
import {JobID} from "./models";

export default class JobDetailApi extends BaseServiceClass {
    static getByJobNumber(job_number: string, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobID>>(this.PATH_WORKSHEET.job_get_by_job_number, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    jobnumber: job_number,
                }
            })
            .then((res) => res.data);
    }
}