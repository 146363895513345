import axios from 'axios';

const httpClient = axios.create({
    timeout: 4000000,
    timeoutErrorMessage: 'Request timeout.'
});

export type ApiResponseWrapper<T> = {
    insertId: string;
    status: boolean;
    message: string;
    rows?: number;
    id_list?: Array<string>;
    isAttendanceToday?: boolean;
    data: T;
}

export type HttpClientError = {
    status: boolean;
    code: string;
    message: string;
    data?: any
}

export enum RequestStatus {pending, success, failed};
httpClient.interceptors.response.use((resp) => {
    return resp;
}, (err) => {
    if (err.response) {
        return Promise.reject({
            status: false,
            code: err.response.status,
            message: `Error occurred. ${err.message}`,
            data: err.response.data
        } as HttpClientError);
    }else if (err.request) {
        return Promise.reject({
            status: false,
            code: '08',
            message: 'No response.',
            data: err.request
        } as HttpClientError);
    } else {
        return Promise.reject({
            status: false,
            code: '09',
            message: 'Invalid request.',
            data: err.message
        } as HttpClientError);
    }
});

export default httpClient;
