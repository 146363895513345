import {Navigate, useLocation} from "react-router-dom";
import {useAppSelector} from "helpers/redux";
import {RootState} from "app/store";

const requireAuth = (Component: any) => {
    return () => {
        let location = useLocation();
        const userState = useAppSelector((state: RootState) => state.user.userAuth);

        if (userState?.id) {
            return <Component/>;
        }

        return <Navigate to="/login" state={{from: location}} replace/>;
    };
};

export default requireAuth;