import React, { useEffect } from 'react';
import { TableColumn } from 'react-data-table-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { JobSource } from '../models';
import { HttpClientError, RequestStatus } from 'http-client';
import { BiEditAlt } from 'react-icons/bi';
import { MdOutlineDeleteForever } from 'react-icons/md';
import {
    deleteJobSource,
    getList,
    reset,
    setSingle,
} from '../jobSource.reducer';
import {
    deletedStatusOption,
    deletedWarnOption,
} from '../../../../helpers/alertLabel';
import DataTableServerPaging from "../../../../components/Table/DataTableServerPaging";

const JobSourceTable = ({ ...props }: JobSourceTableProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const rows = useAppSelector(
        (state: RootState) => state.jobSource.rows ?? 0
    );
    const jobSourceList = useAppSelector(
        (state: RootState) => state.jobSource.list ?? []
    );
    const status = useAppSelector((state: RootState) => state.jobSource.status);
    const error = useAppSelector(
        (state: RootState) => state.jobSource.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        dispatch(getList({ skip: 0, take: 10 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getList({ skip: 0, take: 10 }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const openFormModal = (single: JobSource) => {
        props.openModal(true);
        dispatch(setSingle(single));
    };

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJobSource(id));
            }
        });
    };

    const columns: TableColumn<JobSource>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Action',
            width: '220px',
            cell: (row) => (
                <>
                    <button onClick={() => openFormModal(row)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color" />
                    </button>
                    <button onClick={() => onDeleteClicked(row.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color" />
                    </button>
                </>
            ),
        },
    ];

    const handlePagingChange = (rowsPerPage: number, page: number) => {
        dispatch(
            getList({
                skip: rowsPerPage * (page - 1),
                take: rowsPerPage,
            })
        );
    }

    return (
        <div className="main-data-grid mt-5">
            <DataTableServerPaging columns={columns} data={jobSourceList} rows={rows} handlePagingChange={handlePagingChange} />
        </div>
    );
};

export interface JobSourceTableProps {
    openModal: (isEdit: boolean) => void;
}

export default JobSourceTable;
