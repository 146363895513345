import React, {Fragment, useEffect, useState} from 'react';
import { useTable, usePagination, useBlockLayout } from 'react-table';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';
import {useSticky} from "react-table-sticky";
import styled from "styled-components";
import {MAX_PAGING_OPTIONS} from "../../constants";

const Styles = styled.div`
  .table {
    border: 1px solid #ddd;
 
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
 
    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;
 
      :last-child {
        border-right: 0;
      }
    }
 
    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }
 
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }
 
      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }
 
      .body {
        position: relative;
        z-index: 0;
      }
 
      [data-sticky-td] {
        position: sticky;
      }
 
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }
 
      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

const TableWithServerPaging = ({ columns, data, updateMyData, rowEdit, serverRows, handlePageChange, handleRowChange, pageCount, changePageCount }: any) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            updateMyData,
            rowEdit,
            serverRows,
            handlePageChange: (page: number) => {},
            initialState: { pageIndex: 0 },
        } as any,
        usePagination,
        useBlockLayout,
        useSticky,
    );
    // const [pageCount, setPageCount] = useState<number>(1);
    const [rowSize, setRowSize] = useState<number>(10);
    const { pageIndex } = state;

    const nextPage = (page: number) => {
        handlePageChange(page);
        changePageCount(page);
    }

    const previousPage = (page: number) => {
        handlePageChange(page);
        changePageCount(page);
    }

    const onGotoPageClicked = (page: number) => {
        handlePageChange(page);
        changePageCount(page);
    }

    const handleRowPerPageChange = (totalRow: number) => {
        setPageSize(totalRow);
        setRowSize(totalRow);
        changePageCount(1);
        handleRowChange(totalRow);
    }

    return (
        <Fragment>
            <Styles>
            <div className="relative overflow-x-auto">
                <table
                    className="w-full text-left text-sm "
                    {...getTableProps()}
                >
                    <thead className="header bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        className={`px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider dark:bg-gray-700 dark:text-gray-400`}
                                        style={{ minWidth: column.minWidth }}
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody
                        className="body bg-white dark:bg-main-dark-bg divide-y divide-gray-200"
                        {...getTableBodyProps()}
                    >
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                className="px-2 bg-white dark:bg-main-dark-bg py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900"
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="footer flex justify-end my-3">
                <div className="mr-3">
                    <span className="mr-3">Rows per page:</span>
                    <select
                        className="py-0"
                        value={rowSize}
                        onChange={(e) => handleRowPerPageChange(Number(e.target.value))}
                    >
                        {MAX_PAGING_OPTIONS.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center mr-3">
                    <span>
                        Page{' '}
                        <strong className="ml-2">
                            {pageCount} of {Math.ceil(serverRows / rowSize)}
                        </strong>{' '}
                    </span>
                </div>
                <button
                    className={`px-1 ${pageCount === 1 && 'opacity-50'}`}
                    onClick={() => onGotoPageClicked(1)}
                    disabled={pageCount === 1}
                >
                    <RxDoubleArrowLeft size={18} />
                </button>{' '}
                <button
                    className={`px-1 ${pageCount === 1 && 'opacity-50'}`}
                    onClick={() => previousPage(pageCount - 1)}
                    disabled={pageCount === 1}
                >
                    <MdKeyboardArrowLeft size={18} />
                </button>{' '}
                <button
                    className={`px-1 ${pageCount === Math.ceil(serverRows / rowSize) && 'opacity-50'}`}
                    onClick={() => nextPage(pageCount + 1)}
                    disabled={pageCount === Math.ceil(serverRows / rowSize)}
                >
                    <MdKeyboardArrowRight size={18} />
                </button>{' '}
                <button
                    className={`px-1 ${pageCount === Math.ceil(serverRows / rowSize) && 'opacity-50'}`}
                    onClick={() => onGotoPageClicked(Math.ceil(serverRows / rowSize))}
                    disabled={pageCount === Math.ceil(serverRows / rowSize)}
                >
                    <RxDoubleArrowRight size={18} />
                </button>{' '}
            </div>
            </Styles>
        </Fragment>
    );
};
export default TableWithServerPaging;
