import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AramisType, InputAramisFilter, InputWorkingDrawingFilter} from '../models';
import '../styles.css';
import RadioButton from 'components/RadioButton/RadioButton';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {getAramisList} from '../supportingDocumentAttachment.reducer';
import {RootState} from 'app/store';
import {SupportingDocumentEnum} from 'features/worksheet/process/job/models';
import {HttpClientError, RequestStatus} from 'http-client';
import {reset as resetAttachment, updateAttachment} from "../../../../basic/attachment/attachment.reducer";
import {toast, ToastContainer} from "react-toastify";
import {IoMdClose} from "react-icons/io";
import {Link} from "react-router-dom";
import {DETAIL_TYPE_JOB_INPUT} from "../../../process/job/constants";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import Divider from "../../../../../components/Layout/Divider";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import InputText from "../../../../../components/TextInput/InputText";
import Button from "../../../../../components/Button/Button";
import {useForm} from "react-hook-form";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";

const AramisTable = () => {
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);

    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        reset: resetForm
    } = useForm<InputAramisFilter>({defaultValues: {startDate: undefined, endDate: undefined}});

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const [isReFetch, setIsReFetch] = useState<boolean>(false);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const statusAttachment = useAppSelector(
        (state: RootState) => state.attachment.status
    );

    useEffect(() => {
        let localStart = startDate !== undefined ? new Date(startDate).toISOString() : undefined;
        let localEnd = endDate !== undefined ? new Date(endDate).toISOString() : undefined;
        if (isReFetch && statusAttachment !== undefined && statusAttachment !== RequestStatus.pending) {
            setIsReFetch(false);
            refreshCache(undefined);
            gridRef.current.api.setFilterModel({
                filter: {
                    filterType: "text",
                    type: "contains",
                    filter: getValues("filter")
                },
                dateStartFilter: {
                    filterType: "text",
                    type: "contains",
                    filter: localStart,
                },
                dateEndFilter: {
                    filterType: "text",
                    type: "contains",
                    filter: localEnd
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusAttachment, isReFetch]);

    const displayToast = () => {
        toast(<ShowAttachmentToast/>);
    };

    const CloseButton = ({closeToast}: any) => (
        <IoMdClose
            size={20}
            onClick={() => {
                dispatch(resetAttachment());
                closeToast();
            }}
        />
    );

    const onSupportingDocumentChange = (
        data: AramisType,
        type: SupportingDocumentEnum,
    ) => {
        if (!checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetSupportingDocument, ActionEnum.ActionAssign)) {
            return null;
        }

        setIsReFetch(true);
        displayToast();
        dispatch(
            updateAttachment({
                id: data.id,
                job_id: data.job_id,
                type_id: data.type_id,
                value: type,
                option: data.option,
                employee_id: data.employee_id,
                date_request: data.date_request,
                link: data.link,
                note: data.note,
            })
        );
    };

    const ShowAttachmentToast = () => {
        const isAttachmentLoading = useAppSelector(
            (state: RootState) => state.attachment.isLoading
        );
        const statusAttachment = useAppSelector(
            (state: RootState) => state.attachment.status
        );
        const errorAttachment = useAppSelector(
            (state: RootState) =>
                state.attachment.error ?? ({} as HttpClientError)
        );
        return (
            <div>
                {isAttachmentLoading &&
                    statusAttachment === RequestStatus.pending && (
                        <small>Saving Supporting Document...</small>
                    )}
                {!isAttachmentLoading &&
                    statusAttachment === RequestStatus.success && (
                        <small>Supporting Document Saved Successfully...</small>
                    )}
                {!isAttachmentLoading &&
                    statusAttachment === RequestStatus.failed && (
                        <small>
                            Failed when saving supporting document:{' '}
                            {errorAttachment.message}
                        </small>
                    )}
            </div>
        );
    };

    const onSubmit = (data: InputWorkingDrawingFilter) => {
        let localStart = data.startDate !== undefined ? new Date(data.startDate).toISOString() : undefined;
        let localEnd = data.endDate !== undefined ? new Date(data.endDate).toISOString() : undefined;
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            filter: {
                filterType: "text",
                type: "contains",
                filter: data.filter
            },
            dateStartFilter: {
                filterType: "text",
                type: "contains",
                filter: localStart,
            },
            dateEndFilter: {
                filterType: "text",
                type: "contains",
                filter: localEnd
            },
        });
    }

    const onResetClicked = () => {
        resetForm();
        refreshCache(undefined);
        gridRef.current.api.setFilterModel(null);
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-detail/${props.data?.job_id}`}
            className="underline text-blue-700"
        >
            {props.data?.job_number}
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex flex-col items-center h-[100%]">
                <div className="text-right">
                    <RadioButton
                        name={`aramis-${props.data?.id}`}
                        label="AVAILABLE"
                        id={`aramis-${props.data?.id}-available`}
                        checked={props.data?.value === SupportingDocumentEnum.AVAILABLE}
                        onChange={() =>
                            onSupportingDocumentChange(
                                props.data,
                                SupportingDocumentEnum.AVAILABLE,
                            )
                        }
                    />
                    <RadioButton
                        name={`aramis-${props.data?.id}`}
                        label="NOT AVAILABLE"
                        id={`aramis-${props.data?.id}-not-available`}
                        checked={props.data?.value === SupportingDocumentEnum.NOT_AVAILABLE}
                        onChange={() =>
                            onSupportingDocumentChange(
                                props.data,
                                SupportingDocumentEnum.NOT_AVAILABLE,
                            )
                        }
                    />
                    <RadioButton
                        name={`aramis-${props.data?.id}`}
                        label="NOT REQUIRED"
                        id={`aramis-${props.data?.id}-not-required`}
                        checked={props.data?.value === SupportingDocumentEnum.NOT_REQUIRED}
                        onChange={() =>
                            onSupportingDocumentChange(
                                props.data,
                                SupportingDocumentEnum.NOT_REQUIRED,
                            )
                        }
                    />
                </div>
            </div>
        );
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
            sortable: false
        },
        {field: 'job_sales_order', headerName: 'Sales Order', sortable: false},
        {
            headerName: 'Job Number',
            field: 'job_number',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            sortable: false
        },
        {field: 'project', headerName: 'Project', sortable: false},
        {field: 'department_owner_name', headerName: 'Department Owner', sortable: false},
        {field: 'teamName', headerName: 'Team', sortable: false},
        {field: 'koj', headerName: 'KoJ', sortable: false},
        {
            field: 'date_request',
            headerName: 'Request Date',
            cellDataType: 'date',
            sortable: false
        },
        {field: 'job_status', headerName: 'Status', sortable: false},
        {field: 'type_name', headerName: 'Type', sortable: false},
        {field: 'district', headerName: 'District', sortable: false},
        {field: 'po_number', headerName: 'PO Number', filter: false, sortable: false},
        {
            field: 'due_date',
            headerName: 'Due Date',
            cellDataType: 'date',
            sortable: false
        },
        {field: 'total_poles', headerName: 'Req Pole', sortable: false},
        {
            field: 'ts_modified',
            headerName: 'Last Checked',
            cellDataType: 'date',
            sortable: false
        },
        {
            headerName: 'Filter',
            field: 'filter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date Start Filter',
            field: 'dateStartFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date End Filter',
            field: 'dateEndFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }),
            sortable: false
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                let _filter = params.request.filterModel?.filter?.filter ?? undefined;
                dispatch(
                    getAramisList({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        filter: _filter,
                        startDate: params.request.filterModel?.dateStartFilter?.filter ?? undefined,
                        endDate: params.request.filterModel?.dateEndFilter?.filter ?? undefined,
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid supporting-document mt-5">
                <div className="flex">
                    <div className="flex flex-grow self-center">
                        <h1 className="dark:text-gray-300">ARAMIS</h1>
                    </div>
                </div>

                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Divider>
                            <Divider.LeftContent>
                                <div>
                                    <RangeDatePicker label={"Filter by Date"} startName='startDate' endName='endDate'
                                                     startValue={new Date(startDate)} endValue={new Date(endDate)}
                                                     control={control}/>
                                </div>
                            </Divider.LeftContent>
                            <Divider.RightContent>
                                <div>
                                    <InputText
                                        {...register('filter')}
                                        placeholder="Search by id number | job number | project | district | status"
                                        label="Filter by ID Number | Job Number | Project | District | Status"
                                    />
                                    <div className="flex justify-end">
                                        <div className={"mx-2"}>
                                            <Button
                                                color="success"
                                                type="button"
                                                title="Reset"
                                                onClick={onResetClicked}
                                            />
                                        </div>
                                        <Button
                                            color="success"
                                            type="submit"
                                            title="Filter"
                                        />
                                    </div>
                                </div>
                            </Divider.RightContent>
                        </Divider>
                    </form>
                </div>

                <div className="main-data-grid fielding mt-5">
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'job-data-aramis'} defaultPageSize={20}/>
                </div>
            </div>
            <ToastContainer
                autoClose={5000}
                draggable={false}
                closeButton={CloseButton}
            />
        </div>
    );
};

export default AramisTable;
