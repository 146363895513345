import Dropzone from 'react-dropzone';
import { convertFileSize } from '../../helpers/convert';
import { HiTrash } from 'react-icons/hi';
import {BiCloudUpload} from "react-icons/bi";
import {Progress} from "flowbite-react";
import {UploadProgress} from "types";
import React from "react";
import {IoDocumentAttach} from "react-icons/io5";
import {HttpClientError} from "../../http-client";

interface CustomDropzoneProps {
    label?: string;
    message?: string;
    onChange: (file: Array<File>) => void;
    files: Array<File>;
    uploadProgress?: UploadProgress[];
    uploadError?: HttpClientError;
    disabled?: boolean;
}

function FileDropzone(props: CustomDropzoneProps) {
    const { label = '', uploadProgress = [], files = [], ...rest } = props;

    const handleAcceptedProfileFiles = (localFile: Array<File>) => {
        rest.onChange([...files, ...localFile]);
    };

    const checkProgress = (index: number) => {
        return uploadProgress[index] ? uploadProgress[index].progress : 0;
    }

    const onRemoveFile = (index: number) => {
        let _files = files;
        _files.splice(index, 1);
        rest.onChange(_files);
    }

    return (
        <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
            {label && (
                <label
                    htmlFor={label}
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                >
                    {label}
                </label>
            )}
            <Dropzone
                onDrop={(acceptedFiles) =>
                    handleAcceptedProfileFiles(acceptedFiles)
                }
                disabled={props.disabled}
            >
                {({ getRootProps, getInputProps }) => (
                    <>
                        <section className="p-3 border-[1px] rounded-lg border-gray-400 border-dashed">
                            <div
                                className="flex justify-center"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} id={label} />
                                <div>
                                    <div className='flex justify-center'>
                                        <BiCloudUpload size={64} className='text-gray-400' />
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">
                                            Drag & drop some files here, or
                                            click to select files
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {files.length > 0 && (
                            <section className="mt-3 border-[1px] rounded-lg border-gray-300 border-solid">
                                {files.map((file: File, index) => (
                                    <div key={`local-file-${index}`}>
                                        <div className="flex p-3">
                                            <div className='flex items-center'>
                                                <IoDocumentAttach size={24} className='text-primary-color' />
                                            </div>
                                            {/*<img*/}
                                            {/*    src={URL.createObjectURL(file)}*/}
                                            {/*    alt="local"*/}
                                            {/*    width={50}*/}
                                            {/*/>*/}
                                            <div className="ml-3 flex-grow">
                                                <div className="truncate max-w-[250px]">
                                                    <span className="text-sm">
                                                        {file.name}
                                                    </span>
                                                </div>
                                                <div className='leading-none'>
                                                    <span className="text-xs text-gray-500">
                                                        {convertFileSize(
                                                            file.size
                                                        )}{' '}
                                                        MB
                                                    </span>
                                                    {
                                                        props.uploadError && <small className="text-red-500 font-bold ml-2">{props.uploadError?.message}</small>
                                                    }
                                                </div>
                                                <div className='mt-1'>
                                                    <Progress
                                                        progress={checkProgress(index)}
                                                        size="sm"
                                                        color="dark"
                                                    />
                                                </div>
                                            </div>
                                            <div onClick={() => onRemoveFile(index)} className='flex justify-center items-center cursor-pointer px-2'>
                                                <HiTrash color="#ff6961" size={18}/>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                ))}
                            </section>
                        )}
                    </>
                )}
            </Dropzone>
        </div>
    );
}

export default FileDropzone;