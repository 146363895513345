import React, {Fragment} from 'react';
import {Navbar} from "flowbite-react";
import {useAppDispatch, useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";
import {setDynamicNavId} from "../../sidebarPanel/sidebar.reducer";
import DynamicNavLink from "../../../components/Tabs/DynamicNavLink";

const JobReportDynamicNav = () => {

    const links = [
        {
            id: "ocalc",
            label: "OCALC",
        },
        {
            id: "mre",
            label: "MRE"
        },
        {
            id: "katapult",
            label: "KATAPULT"
        }
    ];

    return (
        <DynamicNavLink links={links}/>
    );
};

export default JobReportDynamicNav;