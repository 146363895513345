import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";
import SeparateTabs from "../../../components/Tabs/SeparateTabs";
import JobPrepPage from "./jobPrep/containers/JobPrepPage";
import JobOCalcPage from "./jobOCalc/containers/JobOCalcPage";
import RequestDocumentPage from "./requestDocument/containers/RequestDocumentPage";
import DetailMREPage from "./mre/containers/DetailMREPage";
import DetailKatapultPage from "./katapult/containers/DetailKatapultPage";
import {getByJobNumber} from "./jobDetail/jobDetail.reducer";
import {useParams} from "react-router-dom";
import {setDynamicNavId} from "../../sidebarPanel/sidebar.reducer";
import JobDetailPage from "./jobDetail/containers/JobDetailPage";
import JobKatapultDetailPage from "./jobDetail/containers/JobKatapultDetailPage";
import JobMREDetailPage from "./jobDetail/containers/JobMREDetailPage";

const ProcessDetailMainPage = () => {
    let { id, type } = useParams();
    const dispatch = useAppDispatch();

    console.log(type);

    //this id must match with dynamic nav id
    const tabs = [
        { id: 'job-detail', name: "Job Detail", link: `/worksheet/ocalc/job-detail/${id}`, content: <JobDetailPage /> },
        { id: 'job-prep', name: "Job Prep", link: `/worksheet/ocalc/job-prep/${id}`, content: <JobPrepPage /> },
        { id: 'job-ocalc', name: "OCALC", link: `/worksheet/ocalc/job-ocalc/${id}`, content: <JobOCalcPage /> },
        { id: 'request-document', name: "Request Document", link: `/worksheet/ocalc/request-document/${id}`, content: <RequestDocumentPage /> },

        { id: 'mre-detail', name: "Job Details", link: `/worksheet/mre/mre-detail/${id}`, content: <JobMREDetailPage /> },
        { id: 'mre-poles', name: "MRE", link: `/worksheet/mre/mre-poles/${id}`, content: <DetailMREPage /> },

        { id: 'katapult-detail', name: "Job Details", link: `/worksheet/katapult/katapult-detail/${id}`, content: <JobKatapultDetailPage /> },
        { id: 'katapult-poles', name: "Katapult", link: `/worksheet/katapult/katapult-poles/${id}`, content: <DetailKatapultPage /> },

    ];

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getByJobNumber(id));
        }
    }, [id]);

    useEffect(() => {
        if (type !== undefined) {
            dispatch(setDynamicNavId(type));
        }
    }, [type]);

    const dynamicNavId = useAppSelector((state: RootState) => state.sidebar.dynamicNavId ?? "");

    return (
        <div>
            <SeparateTabs tabs={tabs} openTab={dynamicNavId} />
        </div>
    );
};

export default ProcessDetailMainPage;
