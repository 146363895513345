import {BillJobColumnSortEnum, GDevColumnSortEnum, StatusBillEnum, TypeBillEnum} from "./models";
import * as yup from "yup";

export const schema = yup.object({
    numberJobId: yup.object({
        value: yup.string().required("Job Number is required"),
    }).required(),
    numberId: yup.object({
        value: yup.string().required("ID Number is required"),
    }).required(),
    otherNumberJobId: yup.object({
        value: yup.string().required("Other Number is required"),
    }).required(),
    salesOrderJobId: yup.object({
        value: yup.string().required("Sales Order is required"),
    }).required(),
}).required();

export const BillTypeOptions = [
    { value: TypeBillEnum.Original.toString(), label: 'Original', text: 'Original' },
    { value: TypeBillEnum.AddPoles.toString(), label: 'Add Poles', text: 'Add Poles' },
    { value: TypeBillEnum.SubstractPoles.toString(), label: 'Substract Poles', text: 'Substract Poles' },
    { value: TypeBillEnum.UpdatePoles.toString(), label: 'Update Poles', text: 'Update Poles' },
    { value: TypeBillEnum.MREP.toString(), label: 'MREP', text: 'MREP' },
    { value: TypeBillEnum.MREE.toString(), label: 'MREE', text: 'MREE' },
    { value: TypeBillEnum.Katapult.toString(), label: 'Katapult', text: 'Katapult' },
];

export const typeFilterOptions = [
    {
        displayKey: 'all',
        displayName: 'All',
        predicate: 'all',
        numberOfInputs: 0,
    },
    {
        displayKey: TypeBillEnum.Original.toString(),
        displayName: 'Original',
        predicate: TypeBillEnum.Original.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: TypeBillEnum.AddPoles.toString(),
        displayName: 'Add Poles',
        predicate: TypeBillEnum.AddPoles.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: TypeBillEnum.SubstractPoles.toString(),
        displayName: 'Substract Poles',
        predicate: TypeBillEnum.SubstractPoles.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: TypeBillEnum.UpdatePoles.toString(),
        displayName: 'Update Poles',
        predicate: TypeBillEnum.UpdatePoles.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: TypeBillEnum.MREP.toString(),
        displayName: 'MREP',
        predicate: TypeBillEnum.MREP.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: TypeBillEnum.MREE.toString(),
        displayName: 'MREE',
        predicate: TypeBillEnum.MREE.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: TypeBillEnum.Katapult.toString(),
        displayName: 'Katapult',
        predicate: TypeBillEnum.Katapult.toString(),
        numberOfInputs: 0,
    },
]

export const BillStatusOptions = [
    { value: StatusBillEnum.NewJob, label: 'New Job', text: 'New Job' },
    { value: StatusBillEnum.AddPoles, label: 'Add Poles', text: 'Add Poles' },
    { value: StatusBillEnum.NewProjectRequest, label: 'New Project Request', text: 'New Project Request' },
    { value: StatusBillEnum.RevisionAddPoles, label: 'Revision Add Poles', text: 'Revision Add Poles' },
    { value: StatusBillEnum.RevisionWrongPolesOcalcAddPole, label: 'Revision Wrong Poles Ocalc Add Pole', text: 'Revision Wrong Poles Ocalc Add Pole' },
    { value: StatusBillEnum.RevisionDWG, label: 'Revision DWG', text: 'Revision DWG' },
    { value: StatusBillEnum.RevisionWrongFielding, label: 'Revision Wrong Fielding', text: 'Revision Wrong Fielding' },
    { value: StatusBillEnum.ModeExcessPolesInError, label: 'Mode Excess Poles In Error', text: 'Mode Excess Poles In Error' },
    { value: StatusBillEnum.RevisionWrongPolesOcalcReducePoles, label: 'Revision Wrong Poles Ocalc Reduce Poles', text: 'Revision Wrong Poles Ocalc Reduce Poles' },
    { value: StatusBillEnum.UpdatePowerMap, label: 'Update Power Map', text: 'Update Power Map' },
    { value: StatusBillEnum.UpdateFielding, label: 'Update Fielding', text: 'Update Fielding' },
    { value: StatusBillEnum.UpdatePowerMapDataSheet, label: 'Update Power Map Data Sheet', text: 'Update Power Map Data Sheet' },
    { value: StatusBillEnum.UpdateDataSheet, label: 'Update Data Sheet', text: 'Update Data Sheet' },
    { value: StatusBillEnum.UpdateWorkingDrawing, label: 'Update Working Drawing', text: 'Update Working Drawing' },
    { value: StatusBillEnum.UpdatePoleNumber, label: 'Update Pole Number', text: 'Update Pole Number' },
    { value: StatusBillEnum.UpdateEmail, label: 'Update Email', text: 'Update Email' },
    { value: StatusBillEnum.UpdateAttachment, label: 'Update Attachment', text: 'Update Attachment' },
    { value: StatusBillEnum.UpdateAramis, label: 'Update Aramis', text: 'Update Aramis' },
    { value: StatusBillEnum.UpdateDenial, label: 'Update Denial', text: 'Update Denial' },
    { value: StatusBillEnum.UpdateAddPoles, label: 'Update Add Poles', text: 'Update Add Poles' },
];

export const billStatusFilterOptions = [
    {
        displayKey: 'all',
        displayName: 'All',
        predicate: 'all',
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.NewJob.toString(),
        displayName: 'New Job',
        predicate: StatusBillEnum.NewJob.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.AddPoles.toString(),
        displayName: 'Add Poles',
        predicate: StatusBillEnum.AddPoles.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.NewProjectRequest.toString(),
        displayName: 'New Project Request',
        predicate: StatusBillEnum.NewProjectRequest.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.RevisionAddPoles.toString(),
        displayName: 'Revision Add Poles',
        predicate: StatusBillEnum.RevisionAddPoles.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.RevisionWrongPolesOcalcAddPole.toString(),
        displayName: 'Revision Wrong Poles Ocalc Add Pole',
        predicate: StatusBillEnum.RevisionWrongPolesOcalcAddPole.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.RevisionDWG.toString(),
        displayName: 'Revision DWG',
        predicate: StatusBillEnum.RevisionDWG.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.RevisionWrongFielding.toString(),
        displayName: 'Revision Wrong Fielding',
        predicate: StatusBillEnum.RevisionWrongFielding.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.ModeExcessPolesInError.toString(),
        displayName: 'Mode Excess Poles In Error',
        predicate: StatusBillEnum.ModeExcessPolesInError.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.RevisionWrongPolesOcalcReducePoles.toString(),
        displayName: 'Revision Wrong Poles Ocalc Reduce Poles',
        predicate: StatusBillEnum.RevisionWrongPolesOcalcReducePoles.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdatePowerMap.toString(),
        displayName: 'Update Power Map',
        predicate: StatusBillEnum.UpdatePowerMap.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdateFielding.toString(),
        displayName: 'Update Fielding',
        predicate: StatusBillEnum.UpdateFielding.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdatePowerMapDataSheet.toString(),
        displayName: 'Update Power Map Data Sheet',
        predicate: StatusBillEnum.UpdatePowerMapDataSheet.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdateDataSheet.toString(),
        displayName: 'Update Data Sheet',
        predicate: StatusBillEnum.UpdateDataSheet.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdateWorkingDrawing.toString(),
        displayName: 'Update Working Drawing',
        predicate: StatusBillEnum.UpdateWorkingDrawing.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdatePoleNumber.toString(),
        displayName: 'Update Pole Number',
        predicate: StatusBillEnum.UpdatePoleNumber.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdateEmail.toString(),
        displayName: 'Update Email',
        predicate: StatusBillEnum.UpdateEmail.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdateAttachment.toString(),
        displayName: 'Update Attachment',
        predicate: StatusBillEnum.UpdateAttachment.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdateAramis.toString(),
        displayName: 'Update Aramis',
        predicate: StatusBillEnum.UpdateAramis.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StatusBillEnum.UpdateDenial.toString(),
        displayName: 'Update Denial',
        predicate: StatusBillEnum.UpdateDenial.toString(),
        numberOfInputs: 0,
    },
]

// export enum BillJobColumnSortEnum
// {
//     entry_date,
//     upload_date,
//     project,
//     koj,
//     number_id,
//     number_job,
//     type,
//     status,
//     total_pole,
//     complete_date,
//     department,
//     note
// }

export const billJobReportSortOptions = [
    {
        label: "ts_created",
        text: "ts_created",
        value: BillJobColumnSortEnum.entry_date.toString()
    },
    {
        label: "upload_date",
        text: "upload_date",
        value: BillJobColumnSortEnum.upload_date.toString()
    },
    {
        label: "project",
        text: "project",
        value: BillJobColumnSortEnum.project.toString()
    },
    {
        label: "koj",
        text: "koj",
        value: BillJobColumnSortEnum.koj.toString()
    },
    {
        label: "number",
        text: "number",
        value: BillJobColumnSortEnum.number_id.toString()
    },
    {
        label: "numberJob",
        text: "numberJob",
        value: BillJobColumnSortEnum.number_job.toString()
    },
    {
        label: "typeName",
        text: "typeName",
        value: BillJobColumnSortEnum.type.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: BillJobColumnSortEnum.status.toString()
    },
    {
        label: "totalPole",
        text: "totalPole",
        value: BillJobColumnSortEnum.total_pole.toString()
    },
    {
        label: "job_complete_date",
        text: "job_complete_date",
        value: BillJobColumnSortEnum.complete_date.toString()
    },
    {
        label: "department",
        text: "department",
        value: BillJobColumnSortEnum.department.toString()
    },
    {
        label: "notes",
        text: "notes",
        value: BillJobColumnSortEnum.note.toString()
    },
    {
        label: "district",
        text: "district",
        value: BillJobColumnSortEnum.District.toString()
    },
    {
        label: "typeJob",
        text: "typeJob",
        value: BillJobColumnSortEnum.TypeJob.toString()
    },
    {
        label: "qc_employee",
        text: "qc_employee",
        value: BillJobColumnSortEnum.User.toString()
    },
    {
        label: "otherNumberJob",
        text: "otherNumberJob",
        value: BillJobColumnSortEnum.other_number.toString()
    },
    {
        label: "salesOrderJob",
        text: "salesOrderJob",
        value: BillJobColumnSortEnum.sales_order.toString()
    },
]

export const gDevSortOptions = [
    {
        label: "project",
        text: "project",
        value: GDevColumnSortEnum.project_name.toString()
    },
    {
        label: "koj",
        text: "koj",
        value: GDevColumnSortEnum.kind_job.toString()
    },
    {
        label: "complete_date",
        text: "complete_date",
        value: GDevColumnSortEnum.complete_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: GDevColumnSortEnum.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: GDevColumnSortEnum.number_job.toString()
    },
    {
        label: "other_number",
        text: "other_number",
        value: GDevColumnSortEnum.other_number.toString()
    },
    {
        label: "type",
        text: "type",
        value: GDevColumnSortEnum.type.toString()
    },
    {
        label: "district",
        text: "district",
        value: GDevColumnSortEnum.district.toString()
    },
    {
        label: "sales_order",
        text: "sales_order",
        value: GDevColumnSortEnum.sales_order.toString()
    },
    {
        label: "gdev_approve_pole",
        text: "gdev_approve_pole",
        value: GDevColumnSortEnum.approve_pole.toString()
    },
    {
        label: "total_complete_pole",
        text: "total_complete_pole",
        value: GDevColumnSortEnum.pole_complete.toString()
    },
    {
        label: "total_pole",
        text: "total_pole",
        value: GDevColumnSortEnum.total_pole.toString()
    },
    {
        label: "sT_date",
        text: "sT_date",
        value: GDevColumnSortEnum.ST_date.toString()
    },
    {
        label: "mic_date",
        text: "mic_date",
        value: GDevColumnSortEnum.Mic_date.toString()
    },
    {
        label: "user_gdev",
        text: "user_gdev",
        value: GDevColumnSortEnum.user_update.toString()
    },
]