import { FC } from "react";
import DatePicker from 'react-datepicker';
import { Controller } from "react-hook-form";
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/styles/custom-datepicker.css'
import {BsCalendar3} from "react-icons/bs";
import {HiMinus} from "react-icons/hi";

type RangeDatePickerProps = {
    label?: string;
    startName: string;
    endName: string;
    startValue: Date | undefined;
    endValue: Date | undefined;
    control: any;
    placeholderText?: string;
    doSubmitOnLastChange?: boolean;
    doSubmit?: () => void;
    centerStyle?: boolean;
};

type RangeDatePickerType = FC<RangeDatePickerProps>;

const RangeDatePicker: RangeDatePickerType = ((props, ref) => {
    const {
        label = "",
        startName = "",
        endName = "",
        control = undefined,
        startValue = undefined,
        endValue = undefined,
        doSubmitOnLastChange = false,
        centerStyle = false,
        ...rest
    } = props;

    const checkDate = (date: string) => {
        if (date === undefined || date === null) {
            return new Date();
        } else {
            return new Date(date);
        }
    }

    return (
        <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
            {label && (
                <label
                    htmlFor={label}
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                >
                    {label}
                </label>
            )}
            <div className={`flex ${centerStyle ? 'justify-center' : ''}`}>
                <div className="w-[100%]">
                    <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 z-10">
                            <BsCalendar3 className="text-primary-color text-lg" />
                        </div>
                        <Controller
                            name={startName}
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className="w-[100%] pr-10 border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-color focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
                                    {...rest}
                                    id={label}
                                    onChange={(date) => field.onChange(date)}
                                    selected={checkDate(field.value)}
                                    selectsStart
                                    startDate={startValue}
                                    endDate={endValue}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="mx-2 flex items-center">
                    <HiMinus className="text-gray-400"/>
                </div>
                <div className="w-[100%]">
                <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 z-10">
                        <BsCalendar3 className="text-primary-color text-lg" />
                    </div>
                <Controller
                    name={endName}
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            className="w-[100%] border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-color focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
                            {...rest}
                            onChange={(date) => {
                                field.onChange(date);
                                if (doSubmitOnLastChange) props.doSubmit?.();
                            }}
                            selected={checkDate(field.value)}
                            selectsEnd
                            startDate={startValue}
                            endDate={endValue}
                            minDate={startValue}
                        />
                    )}
                />
                </div>
                </div>
            </div>
        </div>
    );
});

export default RangeDatePicker;