import React, {useCallback, useEffect, useRef, useState} from 'react';
import {InputDatasheetFilter} from "../models";
import {TbHandGrab} from "react-icons/tb";
import {customWarnOption} from "helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import {getDatasheetWithFilter, getMapReq, reset} from "../mapReq.reducer";
import {UserCredential} from "../../../../login/models";
import {Link} from "react-router-dom";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import Divider from "../../../../../components/Layout/Divider";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import InputText from "../../../../../components/TextInput/InputText";
import Button from "../../../../../components/Button/Button";
import {useForm} from "react-hook-form";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {dataSheetSortOption} from "../constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";

const DatasheetTable = ({reFetch = false, ...props}: DatasheetTableProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);

    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        reset: resetForm
    } = useForm<InputDatasheetFilter>({defaultValues: {startDate: undefined, endDate: undefined}});

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const userState = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );

    useEffect(() => {
        if (reFetch) {
            reFetchDatasheet();
            props.resetFetch?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reFetch]);

    const reFetchDatasheet = () => {
        let localStart = startDate !== undefined ? new Date(startDate).toISOString() : undefined;
        let localEnd = endDate !== undefined ? new Date(endDate).toISOString() : undefined;
        dispatch(reset());
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            filter: {
                filterType: "text",
                type: "contains",
                filter: getValues("filter")
            },
            dateStartFilter: {
                filterType: "text",
                type: "contains",
                filter: localStart,
            },
            dateEndFilter: {
                filterType: "text",
                type: "contains",
                filter: localEnd
            },
        });
    }

    const onGrabClicked = (id: string) => {
        MySwal.fire(
            customWarnOption(
                'Get Job',
                'Are you sure to get this job? You will not be able to recover this data!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(getMapReq({
                    id: id,
                    employee_id: userState.user_id,
                }));
            }
        });
    };

    const onSubmit = (data: InputDatasheetFilter) => {
        let localStart = data.startDate !== undefined ? new Date(data.startDate).toISOString() : undefined;
        let localEnd = data.endDate !== undefined ? new Date(data.endDate).toISOString() : undefined;
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            filter: {
                filterType: "text",
                type: "contains",
                filter: data.filter
            },
            dateStartFilter: {
                filterType: "text",
                type: "contains",
                filter: localStart,
            },
            dateEndFilter: {
                filterType: "text",
                type: "contains",
                filter: localEnd
            },
        });
    }

    const onResetClicked = () => {
        resetForm();
        refreshCache(undefined);
        gridRef.current.api.setFilterModel(null);
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-detail/${props.data?.job_id}`}
            className="underline text-blue-700"
        >
            {props.data?.job_number}
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex flex-col items-center h-[100%]">
                {
                    checkRoleActionAccess(userState?.roleName, MenuEnum.WorksheetMapReq, ActionEnum.ActionAssign) &&
                    <button onClick={() => onGrabClicked(props.data?.id)}>
                        <TbHandGrab
                            className="mr-2 text-2xl text-primary-color"
                        />
                    </button>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
            sortable: false
        },
        {
            field: 'date_request',
            headerName: 'Request Date',
            cellDataType: 'date',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'request_by', headerName: 'Req By', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Job Number',
            field: 'job_number',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'job_number_id', headerName: 'ID Number', sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'department_owner_name', headerName: 'Department Owner', sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'option', headerName: 'Area', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'status_name', headerName: 'Job Status', sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'total_poles', headerName: 'Total Poles', sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'note', headerName: 'Note', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'po_number', headerName: 'PO Number', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Filter',
            field: 'filter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date Start Filter',
            field: 'dateStartFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date End Filter',
            field: 'dateEndFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }),
            sortable: false
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(dataSheetSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.date_request || {};

                dispatch(
                    getDatasheetWithFilter({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        startRequestDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endRequestDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        req_by: params.request.filterModel?.request_by?.filter,
                        po_number: params.request.filterModel?.po_number?.filter,
                        number_job: params.request.filterModel?.job_number?.filter,
                        number_id: params.request.filterModel?.job_number_id?.filter,
                        department_owner: params.request.filterModel?.department_owner_name?.filter,
                        area: params.request.filterModel?.option?.filter,
                        status: params.request.filterModel?.status_name?.filter,
                        note: params.request.filterModel?.note?.filter,
                        total_pole: params.request.filterModel?.total_poles?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid mt-8">
                <div className='flex'>
                    <div className='flex flex-grow self-center'>
                        <h1 className="dark:text-gray-300">Datasheet</h1>
                    </div>
                </div>

                {/*<div>*/}
                {/*    <form onSubmit={handleSubmit(onSubmit)}>*/}
                {/*        <Divider>*/}
                {/*            <Divider.LeftContent>*/}
                {/*                <div>*/}
                {/*                    <RangeDatePicker label={"Filter by Date"} startName='startDate' endName='endDate'*/}
                {/*                                     startValue={new Date(startDate)} endValue={new Date(endDate)}*/}
                {/*                                     control={control}/>*/}
                {/*                </div>*/}
                {/*            </Divider.LeftContent>*/}
                {/*            <Divider.RightContent>*/}
                {/*                <div>*/}
                {/*                    <InputText*/}
                {/*                        {...register('filter')}*/}
                {/*                        placeholder="Search by id number | job number | area | status"*/}
                {/*                        label="Filter by ID Number | Job Number | Area | Status"*/}
                {/*                    />*/}
                {/*                    <div className="flex justify-end">*/}
                {/*                        <div className={"mx-2"}>*/}
                {/*                            <Button*/}
                {/*                                color="success"*/}
                {/*                                type="button"*/}
                {/*                                title="Reset"*/}
                {/*                                onClick={onResetClicked}*/}
                {/*                            />*/}
                {/*                        </div>*/}
                {/*                        <Button*/}
                {/*                            color="success"*/}
                {/*                            type="submit"*/}
                {/*                            title="Filter"*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Divider.RightContent>*/}
                {/*        </Divider>*/}
                {/*    </form>*/}
                {/*</div>*/}

                <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                        getServerSideDataSource={getServerSideDataSource} supportSaveTableState
                                        tableStateName={'job-data-datasheet'} defaultPageSize={20}/>
            </div>
        </div>
    );
};

export interface DatasheetTableProps {
    reFetch: boolean;
    resetFetch?: () => void;
}

export default DatasheetTable;
