import React, {useEffect, useRef, useState} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';
import {HttpClientError, RequestStatus} from 'http-client';
import {BiEditAlt} from 'react-icons/bi';
import {MdOutlineDeleteForever} from 'react-icons/md';
import {
    deletedWarnOption, savedStatusOption,
} from '../../../../helpers/alertLabel';
import {
    deleteEmployeeHoliday,
    getEmployeeHolidayList,
    reset,
    setSingleEmployeeHoliday,
} from "../holiday.reducer";
import {EmployeeHoliday} from "../models";
import AgDataGridStatic from "../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";

const EmployeeHolidayTable = ({...props}: EmployeeHolidayTableProps) => {
    const gridApiRef = useRef<any>(null);
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const [rowIndex, setRowIndex] = useState<number>(0)

    const employeeHoliday = useAppSelector(
        (state: RootState) => state.holiday.employeeHolidayList ?? []
    );
    const status = useAppSelector(
        (state: RootState) => state.holiday.employeeHolidayStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.holiday.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        dispatch(getEmployeeHolidayList({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteEmployeeHoliday(id));
            }
        });
    };

    // useEffect(() => {
    //     if (status !== undefined && status !== RequestStatus.pending) {
    //         MySwal.fire(
    //             deletedStatusOption(
    //                 status === RequestStatus.success,
    //                 error.data?.message
    //             )
    //         ).then(() => {
    //             dispatch(reset());
    //             dispatch(getEmployeeHolidayList({})).then(() => {
    //                 handleAutoSelectRow();
    //             });
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [status, error]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                props.closeModal();
                dispatch(getEmployeeHolidayList({})).then((res: any) => {
                    handleAutoSelectRow(res.payload?.data.length -1 ?? 0);
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const openFormModal = (single: EmployeeHoliday, _rowIndex: number) => {
        props.openModal(true);
        dispatch(setSingleEmployeeHoliday(single));
        setRowIndex(_rowIndex);
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex align-middle h-[100%]">
                <>
                    <button onClick={() => openFormModal(props.data, props.rowIndex)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                    <button onClick={() => onDeleteClicked(props.data?.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                    </button>
                </>
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'name', headerName: 'Name', filter: 'agTextColumnFilter'},
        {field: 'nip', headerName: 'NIP', filter: 'agTextColumnFilter'},
        {field: 'holidayAvailable', headerName: 'Holiday Available', filter: 'agTextColumnFilter'},
        {field: 'totalHolidaysMax', headerName: 'Total Holiday Max', filter: 'agTextColumnFilter'},
        {field: 'totalHolidaysTaken', headerName: 'Holiday Taken', filter: 'agTextColumnFilter'},
        {field: 'totalHolidaysSoon', headerName: 'Holiday to be taken', filter: 'agTextColumnFilter'},
        {field: 'totalHolidaysRemaining', headerName: 'Remaining Holidays', filter: 'agTextColumnFilter'},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const handleAutoSelectRow = (lastLength: number) => {
        // Get the first row node (you can adjust this logic as needed)
        const firstRowNode = gridApiRef.current.api.getRowNode(rowIndex ?? 0);

        // Select the row programmatically
        if (firstRowNode) {
            gridApiRef.current.api.ensureIndexVisible(firstRowNode.rowIndex < lastLength ? firstRowNode.rowIndex +1 : firstRowNode.rowIndex);
        }
    };

    return (
        <div className="main-data-grid mt-5">
            {/*<DataTableServerPaging columns={columns} data={departmentList} rows={rows} handlePagingChange={handlePagingChange} />*/}
            <AgDataGridStatic ref={gridApiRef} columnDefs={columnDefs} rowData={employeeHoliday}/>
        </div>
    );
};

export interface EmployeeHolidayTableProps {
    openModal: (isEdit: boolean) => void;
    closeModal: () => void;
}

export default EmployeeHolidayTable;
