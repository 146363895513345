import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';

import {createEmployeeHoliday, reset, updateEmployeeHoliday} from "../holiday.reducer";
import {EmployeeHoliday, EmployeeHolidayReq} from "../models";
import EmployeeHolidayForm from "../components/EmployeeHolidayForm";
import {getList} from "../../employee/employee.reducer";

const EmployeeHolidayDetailPage = ({
                                       isEdit = false,
                                   }: EmployeeHolidayDetailPageProps) => {
    const dispatch = useAppDispatch();
    // const MySwal = withReactContent(Swal);

    const isLoading = useAppSelector(
        (state: RootState) => state.holiday.isLoading ?? false
    );
    const singleState = useAppSelector(
        (state: RootState) => state.holiday.singleEmployeeHoliday ?? ({} as EmployeeHoliday)
    );

    useEffect(() => {
        dispatch(getList({skip: 0, take: 10000}));
    }, []);

    const onSubmit = (formInput: EmployeeHolidayReq) => {
        const {name, ...rest} = formInput;
        let _formInput = {
            ...rest,
            holidayAvailable: parseInt(rest.holidayAvailable as string),
            totalHolidaysMax: parseInt(rest.totalHolidaysMax as string),
            totalHolidaysTaken: parseInt(rest.totalHolidaysTaken as string) ?? 0,
            totalHolidaysSoon: parseInt(rest.totalHolidaysSoon as string) ?? 0,
            totalHolidaysRemaining: parseInt(rest.totalHolidaysRemaining as string) ?? 0,
        };
        if (isEdit) {
            let _formUpdate = {..._formInput, id: singleState.id};
            dispatch(updateEmployeeHoliday(_formUpdate));
        } else {
            dispatch(createEmployeeHoliday(_formInput));
        }
        dispatch(reset());
    };

    // useEffect(() => {
    //     if (status !== undefined && status !== RequestStatus.pending) {
    //         MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
    //             dispatch(reset());
    //             dispatch(getEmployeeHolidayList({}));
    //             props.toggleModal();
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [status, error]);

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <EmployeeHolidayForm
                isLoading={isLoading}
                isEdit={isEdit}
                onSubmit={onSubmit}
            />
        </div>
    );
};

export interface EmployeeHolidayDetailPageProps {
    isEdit: boolean;
    toggleModal: () => void;
}

export default EmployeeHolidayDetailPage;
