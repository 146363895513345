import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {getMainMREWithFilter} from "../processMRE.reducer";
import {RootState} from "../../../../../app/store";
import {Link} from "react-router-dom";
import {DETAIL_TYPE_MRE} from "../../job/constants";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";
import {MainMRESortOption} from "../constants";
import {RequestStatus} from "../../../../../http-client";

const MainMRETable = () => {
    const gridRef = useRef<any>(null);
    const dispatch = useAppDispatch();

    const actionStatus = useAppSelector((state: RootState) => state.processMRE.actionStatus);
    const status = useAppSelector((state: RootState) => state.processMRE.status);
    const statusPole = useAppSelector((state: RootState) => state.pole.status);

    useEffect(() => {
        if (actionStatus === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatus]);

    useEffect(() => {
        if (status === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (statusPole === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusPole]);

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/mre/mre-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            minWidth: 50,
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {
            field: 'project_name', headerName: 'Project',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'department_name', headerName: 'Department',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'department_owner_name', headerName: 'Department Owner', filter: false, sortable: false},
        {field: 'district_name', headerName: 'District', filter: false, sortable: false},
        {field: 'type_name', headerName: 'Type', filter: false, sortable: false},
        {
            field: 'kind_name', headerName: 'KoJ',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'ts_created',
            headerName: 'Request Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'number_id', headerName: 'ID Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Job Number',
            field: 'number_job',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'status_name', headerName: 'Status',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'date_due',
            headerName: 'Due Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'date_assigned_mre',
            headerName: 'Date Assigned MRE',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'date_assigned_qc_mre',
            headerName: 'Date Assigned QC MRE',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'pole_total', headerName: 'Request Pole',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        // {field: 'mreType', headerName: 'Request Pole', cellRenderer: () => "No Data"},
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(MainMRESortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {dateFrom: startDueDate, dateTo: endDueDate} = params.request.filterModel?.date_due || {};
                const {
                    dateFrom: startDateAssign,
                    dateTo: endDateAssign
                } = params.request.filterModel?.date_assigned_mre || {};
                const {
                    dateFrom: startDateAssignQC,
                    dateTo: endDateAssignQC
                } = params.request.filterModel?.date_assigned_qc_mre || {};

                dispatch(getMainMREWithFilter(
                    {
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        project_name: params.request.filterModel?.project_name?.filter,
                        department_name: params.request.filterModel?.department_name?.filter,
                        kind_job: params.request.filterModel?.kind_name?.filter,
                        startRequestDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endRequestDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        number_id: params.request.filterModel?.number_id?.filter,
                        number_job: params.request.filterModel?.number_job?.filter,
                        status: params.request.filterModel?.status_name?.filter,
                        startDueDate: startDueDate !== undefined ? dateSetTimeToNow(startDueDate) : undefined,
                        endDueDate: endDueDate !== undefined ? dateSetTimeToNow(endDueDate) : undefined,
                        startDateAssign: startDateAssign !== undefined ? dateSetTimeToNow(startDateAssign) : undefined,
                        endDateAssign: endDateAssign !== undefined ? dateSetTimeToNow(endDateAssign) : undefined,
                        startDateQCMREAssign: startDateAssignQC !== undefined ? dateSetTimeToNow(startDateAssignQC) : undefined,
                        endDateQCMREAssign: endDateAssignQC !== undefined ? dateSetTimeToNow(endDateAssignQC) : undefined,
                        req_pole: params.request.filterModel?.pole_total?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    }
                )).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            }
        }
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid mt-2">
                <div className='flex'>
                    <div className='flex flex-grow self-center'>
                        <h1 className="dark:text-gray-300">Main Job</h1>
                    </div>
                </div>
                <div>
                    {/*<FormLoader isLoading={isLoading} label={"Fetching Data"}>*/}
                    {/*    <AgDataGridStatic columnDefs={columnDefs} rowData={MainMREList} supportSaveTableState tableStateName={'worksheet-main-mre'}/>*/}
                    {/*</FormLoader>*/}
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'worksheet-main-mre'} defaultPageSize={20}/>
                </div>
            </div>
        </div>
    );
};

export default MainMRETable;
