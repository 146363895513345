import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {
    CancelRequest,
    CheckRequest,
    CreateJob, JobFileNew,
    GetListRequest, Job, UpdateJob,
    WaitingRequest, ChangeStatusRequest, JobAllModel, GetDropdownListRequest, DropdownJob, ChangeCompleteDateRequest
} from "./models";
import {AxiosRequestConfig} from "axios";
import {changeStatusJob} from "./job.reducer";

export default class JobApi extends BaseServiceClass {
    static getListNew(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<Job[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_new}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<Job[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getJobDropdown(args: GetDropdownListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<DropdownJob[]>>(this.PATH_WORKSHEET.job_get_dropdown, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getAllJobList(token: string) {
        return httpClient.get<ApiResponseWrapper<JobAllModel[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_all_job}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<Job>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
                skip: 0,
                take: 10
            }
        }).then((res) => res.data);
    }

    static createJob(args: CreateJob, token: string) {
        return httpClient.post<ApiResponseWrapper<string>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateJob(args: UpdateJob, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deleteJob(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }

    static getJobFile(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<JobFileNew[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_file_new}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: id,
            }
        }).then((res) => res.data);
    }

    // job file attachment
    static createJobFile(args: JobFileNew, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_file_new}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then((res) => res.data).catch((err) => err);
    }

    static deleteJobFile(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_file_new}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
            }
        }).then((res) => res.data);
    }

    // job import
    static importCSV(form: FormData, config: AxiosRequestConfig) {
        return httpClient
            .post<any>(this.PATH_WORKSHEET.job_import, form, config)
            .then((res) => res.data);
    }

    // job action
    static checkJob(args: CheckRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_new_check}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: args.job_id,
                user_id: args.user_id
            }
        }).then((res) => res.data);
    }

    static cancelJob(args: CancelRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_new_cancel}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static waitingJob(args: WaitingRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_waiting}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: args.job_id,
                waiting_notes: args.waiting_notes,
                waiting_subject_id: args.waiting_subject_id,
                waiting_by_user: args.waiting_by_user,
            }
        }).then((res) => res.data);
    }

    static changeStatusJob(args: ChangeStatusRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_change_status, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static changeCompleteDate(args: ChangeCompleteDateRequest, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_change_complete_date, null,{
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
}