import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { HttpClientError, RequestStatus } from 'http-client';
import {useNavigate, useParams} from "react-router-dom";

import {createTeam, getSingle, reset, updateTeam} from '../team.reducer';
import TeamForm from '../components/TeamForm';
import {InputModel, UpdateTeam} from '../models';
import {savedStatusOption} from "../../../../helpers/alertLabel";

const TeamDetailPage = ({isEdit = false}: TeamDetailPageProps) => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { id } = useParams();
    const MySwal = withReactContent(Swal)

    const isLoading = useAppSelector((state: RootState) => state.team.isLoading ?? false);
    const status = useAppSelector((state: RootState) => state.team.status);
    const error = useAppSelector((state: RootState) => state.team.error ?? ({} as HttpClientError));

    useEffect(() => {
        if (isEdit && id !== undefined) {
            dispatch(getSingle(id as string));
        } else {
            dispatch(reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isEdit]);

    const onSubmit = (formInput: InputModel) => {
        if (isEdit) {
            let _input = Object.assign({} as UpdateTeam, formInput);
            _input.id = id as string;
            dispatch(updateTeam(_input));
        } else {
            dispatch(createTeam(formInput));
        }
        dispatch(reset());
    };

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                return navigate("/team", {replace: true});
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <h1 className="dark:text-gray-300">Team Form</h1>

            <TeamForm isLoading={isLoading} isEdit={isEdit} onSubmit={onSubmit} />
        </div>
    );
};

export interface TeamDetailPageProps {
    isEdit: boolean;
}

export default TeamDetailPage;
