import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {JobFileKatapult, UpdateKatapultNote} from "./models";
import {JobFilePrep} from "../jobPrep/models";

export default class KatapultApi extends BaseServiceClass {
    static getKatapultFile(id: string, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobFileKatapult[]>>(this.PATH_WORKSHEET.job_file_katapult, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    job_id: id,
                },
            })
            .then((res) => res.data);
    }

    static createKatapultFile(args: JobFilePrep, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_katapult, args, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then((res) => res.data)
            .catch((err) => err);
    }

    static deleteKatapultFile(id: string, token: string) {
        return httpClient
            .delete<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_katapult, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    id: id,
                },
            })
            .then((res) => res.data);
    }

    static updateKatapultNotes(args: UpdateKatapultNote, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_update_katapult_notes, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static completeKatapult(id: string, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_katapult_complete, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: id
            }
        }).then((res) => res.data.data);
    }
}