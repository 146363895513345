import {HttpClientError, RequestStatus} from "http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ApprovalApi from "./approval.api";
import {RootState} from "app/store";
import {
    AttendanceApprovalReq, GetAttendanceFilterReq, GetHolidayFilterReq,
    GetOvertimeFilterReq,
    HolidayApprovalReq,
    OvertimeApprovalReq,
    StaffReportApprovalReq
} from "./models";
import {AttendanceModel, HolidayModel, OvertimeModel} from "../profile/models";

export interface ApprovalSlice {
    isLoading?: boolean;
    overtimeApprovalList?: Array<OvertimeModel>;
    attendanceApprovalList?: Array<AttendanceModel>;
    holidayApprovalList?: Array<HolidayModel>;
    employeeWorkApprovalStatus?: RequestStatus;
    overtimeApprovalStatus?: RequestStatus;
    attendanceApprovalStatus?: RequestStatus;
    holidayApprovalStatus?: RequestStatus;
    error?: HttpClientError;
}

export const changeStaffReportStatus = createAsyncThunk(
    'approval/employeeWorkApproval',
    async (args: StaffReportApprovalReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ApprovalApi.changeStaffReportStatus(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const changeOvertimeStatus = createAsyncThunk(
    'approval/overtimeApproval',
    async (args: OvertimeApprovalReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ApprovalApi.changeOvertimeStatus(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const changeAttendanceStatus = createAsyncThunk(
    'approval/changeAttendanceStatus',
    async (args: AttendanceApprovalReq[], { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ApprovalApi.changeAttendanceStatus(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const changeHolidayStatus = createAsyncThunk(
    'approval/holidayApproval',
    async (args: HolidayApprovalReq[], { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ApprovalApi.changeHolidayStatus(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getOvertimeWithFilter = createAsyncThunk(
    'profile/getOvertimeWithFilter',
    async (args: GetOvertimeFilterReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ApprovalApi.getOvertimeWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getAttendanceWithFilter = createAsyncThunk(
    'profile/getAttendanceWithFilter',
    async (args: GetAttendanceFilterReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ApprovalApi.getAttendanceWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getHolidayWithFilter = createAsyncThunk(
    'profile/getHolidayWithFilter',
    async (args: GetHolidayFilterReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ApprovalApi.getHolidayWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

const approvalSlice = createSlice({
    name: 'approvalState',
    initialState: {} as ApprovalSlice,
    reducers: {
        reset: (state) => {
            state.employeeWorkApprovalStatus = RequestStatus.pending;
            state.overtimeApprovalStatus = RequestStatus.pending;
            state.attendanceApprovalStatus = RequestStatus.pending;
            state.holidayApprovalStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
        }
    },
    extraReducers: builder => {
        // change employee work approval
        builder.addCase(changeStaffReportStatus.pending, (state) => {
            state.isLoading = true;
            state.employeeWorkApprovalStatus = RequestStatus.pending;
        });
        builder.addCase(changeStaffReportStatus.fulfilled, (state) => {
            state.isLoading = false;
            state.employeeWorkApprovalStatus = RequestStatus.success;
        });
        builder.addCase(changeStaffReportStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.employeeWorkApprovalStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // change overtime approval
        builder.addCase(changeOvertimeStatus.pending, (state) => {
            state.isLoading = true;
            state.overtimeApprovalStatus = RequestStatus.pending;
        });
        builder.addCase(changeOvertimeStatus.fulfilled, (state) => {
            state.isLoading = false;
            state.overtimeApprovalStatus = RequestStatus.success;
        });
        builder.addCase(changeOvertimeStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.overtimeApprovalStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // change holiday approval
        builder.addCase(changeHolidayStatus.pending, (state) => {
            state.isLoading = true;
            state.holidayApprovalStatus = RequestStatus.pending;
        });
        builder.addCase(changeHolidayStatus.fulfilled, (state) => {
            state.isLoading = false;
            state.holidayApprovalStatus = RequestStatus.success;
        });
        builder.addCase(changeHolidayStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.holidayApprovalStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // change attendance approval
        builder.addCase(changeAttendanceStatus.pending, (state) => {
            state.isLoading = true;
            state.attendanceApprovalStatus = RequestStatus.pending;
        });
        builder.addCase(changeAttendanceStatus.fulfilled, (state) => {
            state.isLoading = false;
            state.attendanceApprovalStatus = RequestStatus.success;
        });
        builder.addCase(changeAttendanceStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.attendanceApprovalStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // get overtime
        builder.addCase(getOvertimeWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOvertimeWithFilter.fulfilled, (state, {payload}) => {
            state.overtimeApprovalList = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getOvertimeWithFilter.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // get attendance
        builder.addCase(getAttendanceWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAttendanceWithFilter.fulfilled, (state, {payload}) => {
            state.attendanceApprovalList = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getAttendanceWithFilter.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // get holiday
        builder.addCase(getHolidayWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getHolidayWithFilter.fulfilled, (state, {payload}) => {
            state.holidayApprovalList = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getHolidayWithFilter.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
    }
});
export const { reset } = approvalSlice.actions;
export default approvalSlice.reducer;