import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {
    savedStatusOption,
} from '../../../../helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {HttpClientError, RequestStatus} from '../../../../http-client';
import {RootState} from '../../../../app/store';
import {BiEditAlt} from 'react-icons/bi';
import {
    approvalStatusFilterOptions,
    getSortModel,
    getSortType,
    overtimeSortOption,
} from '../../constants';
import {UserCredential} from '../../../login/models';
import {getOvertimeWithFilter, reset} from '../../approval.reducer';
import {OvertimeModel} from "../../../profile/models";
import {Link} from "react-router-dom";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import OvertimeNotesDetail from "../../../profile/absent/components/OvertimeNotesDetail";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import OvertimeApprovalForm from "./OvertimeApprovalForm";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {dateSetTimeToNow} from "../../../../helpers/convert";

const OvertimeApprovalTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [singleOvertime, setSingleOvertime] = useState<OvertimeModel>({} as OvertimeModel);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector(
        (state: RootState) => state.approval.overtimeApprovalStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.approval.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (status === RequestStatus.success) {
                    closeModal();
                }
                refreshCache(undefined);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(overtimeSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom, dateTo} = params.request.filterModel?.ts_created || {};

                dispatch(
                    getOvertimeWithFilter({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        startDate: dateFrom !== undefined ? dateSetTimeToNow(dateFrom) : undefined,
                        endDate: dateTo !== undefined ? dateSetTimeToNow(dateTo) : undefined,
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        nip: params.request.filterModel?.nip?.filter,
                        team: params.request.filterModel?.teamName?.filter,
                        name: params.request.filterModel?.name?.filter,
                        role: params.request.filterModel?.roleName?.filter,
                        req_minute: params.request.filterModel?.reqMinute?.filter,
                        note: params.request.filterModel?.notes?.filter,
                        rejection_note: params.request.filterModel?.rejectionNotes?.filter,
                        approve_by: params.request.filterModel?.approveByName?.filter,
                        manager_approval: params.request.filterModel?.managerApproveByName?.filter,
                        approve_minute: params.request.filterModel?.approveMinute?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const openModal = (overtime: OvertimeModel) => {
        setSingleOvertime(overtime);
        setShowModal(true);
    };

    const closeModal = () => {
        setSingleOvertime({} as OvertimeModel);
        setShowModal(false);
    };

    const toggleNotesModal = () => {
        setShowNotesModal((prevState) => !prevState);
        setSingleOvertime({} as OvertimeModel);
    };

    const onDetailClicked = (overtime: OvertimeModel) => {
        setSingleOvertime(overtime);
        setShowNotesModal(true);
    }

    const NotesLinkCellRender = (props: any) => (
        <Link
            to={`#`}
            className="underline text-blue-700"
            onClick={() => onDetailClicked(props.data)}
        >
            See Notes
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    (user.roleName.some((item) => item === "Head") && props.data?.statusName === "Requested") &&
                    <button onClick={() => openModal(props.data)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    (user.roleName.some((item) => item === "Administrator" || item === "Superadmin" || item === "HRD") && props.data?.statusName !== "ManagerApproval" && props.data?.statusName !== "Rejected") &&
                    <button onClick={() => openModal(props.data)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {
            field: 'ts_created', headerName: 'Created Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'nip', headerName: 'NIP',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'teamName', headerName: 'Team',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'name', headerName: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'roleName', headerName: 'Role', filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'reqMinute', headerName: 'Req Minutes', filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'notes', headerName: 'Notes', cellRenderer: NotesLinkCellRender, cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }), filter: false, sortable: false
        },
        {
            field: 'rejectionNotes', headerName: 'Rejection Notes', filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'approveMinute', headerName: 'Approved', filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'multiplier', headerName: 'Multiple', filter: false, sortable: false},
        {
            field: 'statusName', headerName: 'status', filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: approvalStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {
            field: 'approveByName', headerName: 'Approved By', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'managerApproveByName', headerName: 'Manager Approval', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'approval-overtime'} defaultPageSize={20}/>
                    <DefaultModal
                        title={'Notes'}
                        show={showNotesModal}
                        onClose={toggleNotesModal}
                        body={<OvertimeNotesDetail data={singleOvertime}/>}
                    />
                    <DefaultModal
                        title="Overtime Approval"
                        show={showModal}
                        onClose={closeModal}
                        body={
                            <OvertimeApprovalForm singleOvertime={singleOvertime} userId={user.user_id}/>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default OvertimeApprovalTable;
