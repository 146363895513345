import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {InputFilter} from "../../../staffReport/models";
import {useAppDispatch} from "../../../../../helpers/redux";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import AbsentLogTable from "../components/AbsentLogTable";
import {
    attendanceExport,
    getAttendanceLog,
    getAttendanceLogDetail,
} from "../../attendanceReport.reducer";
import Button from "../../../../../components/Button/Button";
import AttendanceTable from "../components/AttendanceTable";

const AbsentLogPage = () => {
    const {
        control,
        handleSubmit,
        watch,
        reset
    } = useForm<InputFilter>({defaultValues: {startDate: new Date().toISOString(), endDate: new Date().toISOString()}});
    const dispatch = useAppDispatch();
    const [userId, setUserId] = useState<string|undefined>(undefined);

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    useEffect(() => {
        dispatch(
            getAttendanceLog({
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
            })
        );
    }, []);

    useEffect(() => {
        if (userId !== undefined) dispatch(getAttendanceLogDetail({userId: userId, startDate: startDate, endDate: endDate}));
    }, [startDate, endDate, userId]);

    const onSubmit = (data: InputFilter) => {
        let localStart = new Date(data.startDate).toISOString();
        let localEnd = new Date(data.endDate).toISOString();
        dispatch(
            getAttendanceLog({
                startDate: localStart,
                endDate: localEnd,
            })
        );
    }

    const onDetailClicked = (userId: string) => {
        setUserId(userId);
    }

    const onExportClicked = () => {
        let localStart = new Date(startDate).toLocaleDateString('en-CA');
        let localEnd = new Date(endDate).toLocaleDateString('en-CA');
        dispatch(attendanceExport({startDate: localStart, endDate: localEnd}));
    }

    const onResetClicked = () => {
        reset();
        dispatch(
            getAttendanceLog({})
        );
    }

    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <RangeDatePicker startName='startDate' endName='endDate' startValue={new Date(startDate)} endValue={new Date(endDate)} control={control} centerStyle/>
                        <div className="flex justify-end items-center">
                            {/*<div className={"mx-1"}>*/}
                            {/*    <Button type="button" title="Export" onClick={onExportClicked} />*/}
                            {/*</div>*/}

                            <div className={"mx-1"}>
                                <Button
                                    color="success"
                                    type="button"
                                    title="Reset"
                                    onClick={onResetClicked}
                                />
                            </div>
                            <div className={"ml-1"}>
                                <Button
                                    color="success"
                                    type="submit"
                                    title="Filter"
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="mt-3">
                    <AbsentLogTable onDetailClicked={onDetailClicked} onExportHandler={onExportClicked}/>
                </div>
                <div className="mt-3">
                    <AttendanceTable />
                </div>
            </div>
        </div>
    );
};

export default AbsentLogPage;
