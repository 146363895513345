import { HttpClientError, RequestStatus } from 'http-client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import FieldingApi from './fielding.api';
import { Fielding, GetListRequest, InputFilter } from './models';
import FileSaver from 'file-saver';

export interface FieldingSlice {
    list?: Fielding[];
    rows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    actionStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getList = createAsyncThunk(
    'job/fielding/getList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await FieldingApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const exportToCsv = createAsyncThunk(
    'job/fielding/exportToCsv',
    async (args: InputFilter, { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await FieldingApi.exportToCsv(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const fieldingSlice = createSlice({
    name: 'fieldingState',
    initialState: {} as FieldingSlice,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.list = payload.data;
                state.rows = payload.rows;
            })
            .addCase(getList.rejected, (state) => {
                state.isLoading = false;
            })
            // export to csv download reducer
            .addCase(exportToCsv.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(exportToCsv.fulfilled, (state, { payload }) => {
                FileSaver.saveAs(
                    payload,
                    'fielding' + new Date().toISOString() + '.xlsx'
                );
                state.isLoading = false;
            })
            .addCase(exportToCsv.rejected, (state, { payload }) => {
                state.error = payload as HttpClientError;
                state.isLoading = false;
            });
    },
});

export const {} = fieldingSlice.actions;
export default fieldingSlice.reducer;
