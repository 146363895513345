import moment from "moment";

export const getRowStyle = (params: any) => {
    if (params.data && params.data.is_priority) {
        return { background: "rgb(251, 229, 214)"} as any;
    }
    // Return the default row style if no custom color is provided
    return null;
};

export const rowNumberRenderer = (params: any) => {
    if (params.data === undefined) {
        return null;
    }
    return params.rowIndex + 1;
};

export const dataTypeDefinitions = {
    // override `date` to handle custom date format `dd/mm/yyyy`
    date: {
        baseDataType: 'date',
        extendsDataType: 'date',
        valueParser: (params: any) => {
            if (params.data === undefined) {
                return null;
            }
            if (params.newValue == null) {
                return null;
            }
            // convert from `dd/mm/yyyy`
            return params?.value === "0001-01-01T00:00:00" ? "-" : new Date(params?.value).toLocaleDateString('en-GB');
        },
        valueFormatter: (params: any) => {
            if (params.data === undefined) {
                return null;
            }
            // convert to `dd/mm/yyyy`
            return params?.value === "0001-01-01T00:00:00" ? "-" : new Date(params?.value).toLocaleDateString('en-US');
        },
    },
    date_time: {
        baseDataType: 'date',
        extendsDataType: 'date',
        valueParser: (params: any) => {
            if (params.newValue == null) {
                return null;
            }
            return params?.value !== null ? moment(params?.value).format('MM/DD/YYYY HH:mm:ss') : "";
        },
        valueFormatter: (params: any) => {
            return params?.value !== null ? moment(params?.value).format('MM/DD/YYYY HH:mm:ss') : "";
        },
    },
};