import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {
    AramisType,
    ChangeSupportingDocumentType,
    GetAramisListRequest,
    GetWorkingDrawingListRequest, GetWorkingDrawingNewRequest,
    WorkingDrawingType
} from "./models";
import {GetMapNewRequest, PowerMapType} from "../mapReq/models";

export default class SupportingDocumentAttachmentApi extends BaseServiceClass {
    static getAramisList(args: GetAramisListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<AramisType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_aramis_na}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take,
                startDate: args.startDate,
                endDate: args.endDate,
                filter: args.filter,
            }
        }).then((res) => res.data);
    }
    static getWorkingDrawingList(args: GetWorkingDrawingListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<WorkingDrawingType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_working_na}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take,
                startDate: args.startDate,
                endDate: args.endDate,
                filter: args.filter,
            }
        }).then((res) => res.data);
    }
    static getWorkingDrawingWithFilter(args: GetWorkingDrawingNewRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<WorkingDrawingType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_working_na_with_filter}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static editSupportingDocumentAttachment(args: Array<ChangeSupportingDocumentType>, token: string) {
        const path = "/job/attachment/value/edit"
        return httpClient.put<any>(`${this.BASE_URL}${path}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
}