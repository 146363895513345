import React, {useEffect} from 'react';
import {useAppDispatch} from "../../helpers/redux";
import {setDynamicNavId} from "../sidebarPanel/sidebar.reducer";
import DynamicNavLink from "components/Tabs/DynamicNavLink";

const ProfileDynamicNav = () => {
    const dispatch = useAppDispatch();

    const links = [
        {
            id: "my-profile",
            label: "My Profile",
        },
        {
            id: "absent",
            label: "Absent"
        }
    ];

    return (
        <DynamicNavLink links={links}/>
    );
};

export default ProfileDynamicNav;