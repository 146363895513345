import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {GetCSFileRequest, JobFileCS, JobFileCSList, ReadyToQC, UpdateCSNote} from "./jobCalcSpec.models";

export default class JobCalcSpecApi extends BaseServiceClass {
    static getJobCSFile(args: GetCSFileRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobFileCSList[]>>(this.PATH_WORKSHEET.job_file_calc_spec, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args,
            })
            .then((res) => res.data);
    }

    static createJobCSFile(args: JobFileCS, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_calc_spec, args, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then((res) => res.data)
            .catch((err) => err);
    }

    static deleteJobCSFile(id: string, token: string) {
        return httpClient
            .delete<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_calc_spec, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    id: id,
                },
            })
            .then((res) => res.data);
    }

    static updateCSNote(args: UpdateCSNote, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_update_cs_notes, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static readyToQC(args: ReadyToQC, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_qc_ready_to_assign, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
}