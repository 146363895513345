import React, {useEffect, useState} from 'react';
import BasicSelectInput, {Option} from "components/SelectInput/BasicSelectInput";
import {useForm} from "react-hook-form";
import {InputRequestDocument} from "../models";
import Text from "components/Text/Text";
import TextArea from "components/TextArea/TextArea";
import Button from "components/Button/Button";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {
    getList,
    getSingle as getSingleAttachment
} from "features/master/supportingDocument/supportingDocument.reducer";
import {RootState} from "app/store";
import InputText from "../../../../../components/TextInput/InputText";
import {
    createAttachment,
    getAttachment,
    reset as resetAttachment, updateAttachment
} from "../../../../basic/attachment/attachment.reducer";
import {getSingle} from "../../../process/job/job.reducer";
import {Job} from "../../../process/job/models";
import {isArrayEmpty, isObjectEmpty} from "../../../../../helpers/checkEmptiness";
import {SupportingDocument} from "../../../../master/supportingDocument/models";
import {customWarnOption, savedStatusOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {StatusOption} from "../constants";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {JobAttachment} from "../../../../basic/attachment/models";
import {UserCredential} from "../../../../login/models";
import {dateSetTimeToNow} from "../../../../../helpers/convert";

const RequestDocumentModal = ({jobId = undefined, isEdit = false, singleRequestDocument = {} as JobAttachment, ...props}: RequestDocumentModalProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const { register, handleSubmit, formState: { errors }, watch, setValue, reset } =
        useForm<InputRequestDocument>({
            defaultValues: {option: null}
        });

    const [options, setOptions] = useState<Array<Option>>([]);

    const userState = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const supportingDocumentOptions = useAppSelector(
        (state: RootState) => state.supportingDocument.options ?? []
    );
    const singleSupportingDocument = useAppSelector(
        (state: RootState) => state.supportingDocument.single ?? {} as SupportingDocument
    );
    const singleJob = useAppSelector(
        (state: RootState) => state.job.single ?? ({} as Job)
    );
    const isAttachmentLoading = useAppSelector(
        (state: RootState) => state.attachment.isLoading
    );
    const statusAttachment = useAppSelector(
        (state: RootState) => state.attachment.status
    );
    const errorAttachment = useAppSelector(
        (state: RootState) =>
            state.attachment.error ?? ({} as HttpClientError)
    );

    const selectedAttachment = watch('type');

    useEffect(() => {
        dispatch(getList({skip: 0, take: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (jobId && jobId !== "") {
            dispatch(getSingle(jobId as string));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);

    useEffect(() => {

        if (isEdit && !isObjectEmpty(singleRequestDocument)){
            if (!isArrayEmpty(supportingDocumentOptions)) {
                reset({type: singleRequestDocument.type_id,link: singleRequestDocument?.link ?? '', note: singleRequestDocument?.note ?? '', status: singleRequestDocument?.value?.toString() ?? ''});
            }
        } else {
            if (!isArrayEmpty(supportingDocumentOptions)) {
                reset({type: supportingDocumentOptions[0].value});
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, singleRequestDocument, supportingDocumentOptions]);

    useEffect(() => {
        if (selectedAttachment !== undefined) {
            dispatch(getSingleAttachment(selectedAttachment));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttachment]);

    useEffect(() => {
        if (!isObjectEmpty(singleSupportingDocument)) {
            if (singleSupportingDocument.options === null) {
                setValue('option', null);
                setOptions([]);
            } else {
                let _arrOptions = singleSupportingDocument.options?.split(',');
                let _optionList: Array<Option> =
                    _arrOptions?.map((item) => {
                        return {
                            text: item,
                            label: item,
                            value: item,
                        };
                    }) ?? [];
                setOptions([
                    {
                        text: 'Select Item',
                        label: 'Select Item',
                        value: '',
                    },
                    ..._optionList,
                ]);
            }

        }

    }, [singleSupportingDocument]);

    useEffect(() => {
        if (statusAttachment !== undefined && statusAttachment !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(statusAttachment === RequestStatus.success, errorAttachment.data?.message)).then(() => {
                dispatch(resetAttachment());
                dispatch(getAttachment({job_id: jobId as string, skip: 0, take: 10}));
                props.onClose();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusAttachment, errorAttachment]);

    const onSubmit = (data: InputRequestDocument) => {
        MySwal.fire(
            customWarnOption(
                `${isEdit ? 'Change' : 'Add'} Request Document`,
                'Are you sure to request?',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                if (isEdit) {
                    dispatch(updateAttachment({
                        id: singleRequestDocument.id,
                        job_id: jobId as string,
                        type_id: data.type,
                        value: parseInt(data.status),
                        option: data.option,
                        date_request: dateSetTimeToNow(new Date().toISOString()),
                        link: data.link,
                        note: data.note,
                        employee_id: singleRequestDocument?.employee_id,
                    }));
                } else {
                    dispatch(createAttachment({
                        job_id: jobId as string,
                        type_id: data.type,
                        value: parseInt(data.status),
                        option: data.option,
                        date_request: dateSetTimeToNow(new Date().toISOString()),
                        link: data.link,
                        note: data.note,
                        employee_id: userState.user_id,
                    }));
                }

            }
        });
    }

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
            <Text label="Job Number" value={singleJob.number_id} />
                <BasicSelectInput
                    label="Data"
                    options={supportingDocumentOptions}
                    message={errors.type?.message}
                    {...register('type')}
                />
                {
                    (singleSupportingDocument?.options !== null && singleSupportingDocument?.options !== "") &&
                    <BasicSelectInput
                        label="Area"
                        options={options}
                        message={errors.option?.message}
                        {...register('option')}
                    />
                }
                <BasicSelectInput
                    label="Status"
                    options={StatusOption}
                    message={errors.status?.message}
                    {...register('status')}
                />
                <InputText
                    {...register('link')}
                    label="Link"
                    placeholder="Input link"
                    message={errors.link?.message}
                />
                <TextArea
                    label="Notes"
                    placeholder="Input Notes"
                    {...register('note')}
                />
                <div>
                    <Button
                        title="Save"
                        type="submit"
                        isLoading={isAttachmentLoading}
                    />
                </div>
            </form>
        </div>
    );
};

export interface RequestDocumentModalProps {
    onClose: () => void;
    isEdit?: boolean;
    singleRequestDocument?: JobAttachment;
    jobId?: string;
}

export default RequestDocumentModal;
