import {Option} from "../../../components/SelectInput/BasicSelectInput";
import {HttpClientError, RequestStatus} from "../../../http-client";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import BillJobApi from "./billJob.api";
import {
    BillJob,
    BillJobDetail,
    BillJobRequest,
    GetBillJob, GetWeekendDate,
    RequestBillJob,
    SubmitBillJobWithFile,
    TypeBillEnum
} from "../models";
import {BillStatusOptions} from "../constants";
import {UploadProgress, UploadStatus} from "../../basic/fileAttachment/models";
import FileAttachmentApi from "../../basic/fileAttachment/fileAttachment.api";
import moment from "moment";
import {InputFilter} from "../../report/jobReport/models";
import JobReportApi from "../../report/jobReport/jobReport.api";
import FileSaver from "file-saver";
import {exportToCsv} from "../../report/jobReport/jobReport.reducer";

export interface BillJobSlice {
    list?: Array<BillJob>;
    single?: BillJob;
    billByJobList?: Array<BillJob>;
    numberIdOptions?: Array<Option>;
    otherNumberOptions?: Array<Option>;
    salesOrderOptions?: Array<Option>;
    poNumberOptions?: Array<Option>;
    billStatusOptions?: Array<Option>;
    billJobDetail?: Array<BillJobDetail>;
    weekendDate?: Array<string>;
    weekendDateOptions?: Array<Option>;
    isLoading?: boolean;
    uploadProgress?: Array<UploadProgress>;
    uploadStatus?: Array<UploadStatus>;
    isBillStatusLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getJobData = createAsyncThunk(
    'bill-job/getJobData',
    async (jobId: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getJobData(jobId, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getStatusByType = createAsyncThunk(
    'bill-job/getStatusByType',
    async (type: TypeBillEnum, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getStatusByType(type, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const exportBillJob = createAsyncThunk(
    'jobReport/exportBillJob',
    async (args: BillJobRequest, { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await BillJobApi.exportBillJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const submitBillJobWithFile = createAsyncThunk(
    'bill-job/submitBillJobWithFile',
    async (args: SubmitBillJobWithFile, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((file, index) => {
                        return {
                            index: index,
                            filename: file.name,
                            status: RequestStatus.pending,
                            createStatus: RequestStatus.pending,
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (file, index) => {
                let formData = new FormData();
                formData.append('file', file);
                const config = {
                    headers: {
                        Authorization: `Bearer ${userAuth?.id as string}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        location: 'bill-job',
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            })
                        );
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.success,
                        })
                    );
                    return {
                        name: file.name,
                        path: data.result,
                    }
                }).catch((e) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.failed,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                const {id, isEdit, local_files, ...rest}  = args;
                const submitRequest = {
                    ...rest,
                    attachment: attachment[0].path,
                }
                if (isEdit) {
                    const _submitRequest = {...submitRequest, id: id};
                    dispatch(updateBillJob(_submitRequest));
                } else {
                    dispatch(createBillJob(submitRequest));
                }
            });
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createBillJob = createAsyncThunk(
    'bill-job/createBillJob',
    async (args: RequestBillJob, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.createBillJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateBillJob = createAsyncThunk(
    'bill-job/updateBillJob',
    async (args: RequestBillJob, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.updateBillJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getBillJobWithFilter = createAsyncThunk(
    'bill-job/getBillJobWithFilter',
    async (args: BillJobRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getBillJobWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getBillJob = createAsyncThunk(
    'bill-job/getBillJob',
    async (args: GetBillJob, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getBillJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingleBillJob = createAsyncThunk(
    'bill-job/getSingleBillJob',
    async (args: GetBillJob, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getSingleBillJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getBillByJob = createAsyncThunk(
    'bill-job/getBillByJob',
    async (jobId: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getBillByJob(jobId, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getBillByJobNumber = createAsyncThunk(
    'bill-job/getBillByJobNumber',
    async (jobId: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getBillByJobNumber(jobId, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteBillJob = createAsyncThunk(
    'bill-job/deleteBillJob',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.deleteBillJob(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getBillJobDetail = createAsyncThunk(
    'bill-job/getBillJobDetail',
    async (numberJob: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getBillJobDetail(numberJob, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getWeekendDate = createAsyncThunk(
    'bill-job/getWeekendDate',
    async (args: GetWeekendDate, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await BillJobApi.getWeekendDate(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const billJobSlice = createSlice({
    name: 'billJobState',
    initialState: {} as BillJobSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.numberIdOptions = [];
            state.otherNumberOptions = [];
            state.salesOrderOptions = [];
            state.poNumberOptions = [];
        },
        resetSingle: (state) => {
            state.single = {} as BillJob;
            state.billByJobList = [];
        },
        setUploadProgress: (state, payload: PayloadAction<Array<UploadProgress>>) => {
            state.uploadProgress = payload.payload;
        },
        setUploadStatus: (state, payload: PayloadAction<Array<UploadStatus>>) => {
            state.uploadStatus = payload.payload;
        },
        updateUploadProgress: (state, payload: PayloadAction<UploadProgress>) => {
            let progress = state.uploadProgress ?? [];
            let objIndex = state.uploadProgress?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            progress[objIndex as number].progress = payload.payload.progress;
            state.uploadProgress = progress;
        },
        updateUploadStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].status = payload.payload.status;
            state.uploadStatus = status;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getJobData.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getJobData.fulfilled, (state, { payload }) => {
            const jobData = payload.data;
            state.numberIdOptions = jobData.number_id.map((item) => {
                return {
                    value: item.job_id,
                    label: `${item.number_id} | ${item.koj}`,
                    text: `${item.number_id} | ${item.koj}`,
                    flag: item.flag
                }
            });
            state.otherNumberOptions = jobData.other_number.map((item) => {
                return {
                    value: item.job_id,
                    label: `${item.other_number} | ${item.koj}`,
                    text: `${item.other_number} | ${item.koj}`,
                }
            });
            state.salesOrderOptions = jobData.sales_order.map((item) => {
                return {
                    value: item.job_id,
                    label: `${item.sales_order} | ${item.koj}`,
                    text: `${item.sales_order} | ${item.koj}`,
                }
            });
            state.poNumberOptions = jobData.po_number.map((item) => {
                return {
                    value: item.job_id,
                    label: `${item.po_number} | ${item.koj}`,
                    text: `${item.po_number} | ${item.koj}`,
                }
            });
            state.isLoading = false;
        });
        builder.addCase(getJobData.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getStatusByType.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isBillStatusLoading = true;
        });
        builder.addCase(getStatusByType.fulfilled, (state, { payload }) => {
            state.isBillStatusLoading = false;
            const data = payload.data;
            const billOptions = BillStatusOptions.filter((item) => {
                return data.includes(item.value);
            });
            state.billStatusOptions = billOptions.map((item) => {
                return {
                    value: item.value.toString(),
                    label: item.label,
                    text: item.text,
                }
            });
        });
        builder.addCase(getStatusByType.rejected, (state) => {
            state.isBillStatusLoading = false;
        });
        builder.addCase(getBillJob.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getBillJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.list = payload.data;
        });
        builder.addCase(getBillJob.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getBillJobWithFilter.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getBillJobWithFilter.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.list = payload.data;
        });
        builder.addCase(getBillJobWithFilter.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getSingleBillJob.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingleBillJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.single = payload.data;
        });
        builder.addCase(getSingleBillJob.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getBillByJob.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getBillByJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.billByJobList = payload.data;
        });
        builder.addCase(getBillByJob.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getBillByJobNumber.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getBillByJobNumber.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.billByJobList = payload.data;
        });
        builder.addCase(getBillByJobNumber.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(submitBillJobWithFile.pending, (state) => {
            state.error = {} as HttpClientError;
        });
        builder.addCase(submitBillJobWithFile.fulfilled, (state, { payload }) => {
            console.log(payload)
        });
        builder.addCase(submitBillJobWithFile.rejected, (state, {payload}) => {
            state.error = payload as HttpClientError;
        });
        builder.addCase(createBillJob.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
            state.status = RequestStatus.pending;
        });
        builder.addCase(createBillJob.fulfilled, (state, { payload }) => {
            console.log(payload);
            state.isLoading = false;
            state.status = RequestStatus.success;
        });
        builder.addCase(createBillJob.rejected, (state, {payload}) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
            state.status = RequestStatus.failed;
        });
        builder.addCase(updateBillJob.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
            state.status = RequestStatus.pending;
        });
        builder.addCase(updateBillJob.fulfilled, (state, { payload }) => {
            console.log(payload);
            state.isLoading = false;
            state.status = RequestStatus.success;
        });
        builder.addCase(updateBillJob.rejected, (state, {payload}) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
            state.status = RequestStatus.failed;
        });
        builder.addCase(deleteBillJob.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
            state.status = RequestStatus.pending;
        });
        builder.addCase(deleteBillJob.fulfilled, (state, { payload }) => {
            console.log(payload);
            state.isLoading = false;
            state.status = RequestStatus.success;
        });
        builder.addCase(deleteBillJob.rejected, (state, {payload}) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
            state.status = RequestStatus.failed;
        });
        builder.addCase(getBillJobDetail.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getBillJobDetail.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.billJobDetail = payload.data;
        });
        builder.addCase(getBillJobDetail.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getWeekendDate.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getWeekendDate.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.weekendDate = payload.data;
            const _options = payload.data.map((item) => {
                return {
                    value: item,
                    label: moment(item).format('MM/DD/YYYY'),
                    text: moment(item).format('MM/DD/YYYY'),
                }
            });
            state.weekendDateOptions = [ {
                value: 'all',
                label: 'All',
                text: 'All',
            }, ..._options]
        });
        builder.addCase(getWeekendDate.rejected, (state) => {
            state.isLoading = false;
        });
        // export to csv download reducer
        builder.addCase(exportBillJob.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(exportBillJob.fulfilled, (state, { payload }) => {
            FileSaver.saveAs(
                payload,
                'billjob' + new Date().toISOString() + '.xlsx'
            );
            state.isLoading = false;
        })
        builder.addCase(exportBillJob.rejected, (state, { payload }) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});

export const { reset, resetSingle, setUploadProgress,
    updateUploadProgress,
    setUploadStatus,
    updateUploadStatus } = billJobSlice.actions;
export default billJobSlice.reducer;