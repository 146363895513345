import React from 'react';
import StaffReportTable from "../components/StaffReportTable";
import AddStaffReportTable from "../components/AddStaffReportTable";

const StaffReportPage = () => {
    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Staff Report</h1>
                </div>
                {/*<div className="mx-5">*/}
                {/*    <h1>Table Test</h1>*/}
                {/*    <AddStaffReportTableTest />*/}
                {/*</div>*/}
                <div className="mt-3">
                    <AddStaffReportTable />
                    <StaffReportTable />
                </div>
            </div>
        </div>
    );
};

export default StaffReportPage;
