import React, { useEffect } from 'react';
import { TableColumn } from 'react-data-table-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { Team } from '../models';
import { deleteTeam, getList, reset } from '../team.reducer';
import { HttpClientError, RequestStatus } from 'http-client';
import {BiEditAlt} from "react-icons/bi";
import {MdOutlineDeleteForever} from "react-icons/md";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertLabel";
import DataTableServerPaging from "../../../../components/Table/DataTableServerPaging";

const TeamTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const rows = useAppSelector((state: RootState) => state.team.rows ?? 0);
    const teamList = useAppSelector(
        (state: RootState) => state.team.list ?? []
    );
    const status = useAppSelector((state: RootState) => state.team.status);
    const error = useAppSelector(
        (state: RootState) => state.team.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        dispatch(getList({ skip: 0, take: 10 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(deletedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                dispatch(getList({ skip: 0, take: 10 }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteTeam(id));
            }
        });
    };

    const columns: TableColumn<Team>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Member',
            selector: (row) => row.employee_total,
            sortable: true,
        },
        {
            name: 'Action',
            width: '220px',
            cell: (row) => (
                <>
                    <Link
                        to={{
                            pathname: `/team/detail/${row.id}`,
                        }}
                    >
                        <BiEditAlt className="mr-2 text-2xl text-primary-color" />
                    </Link>
                    <button onClick={() => onDeleteClicked(row.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"  />
                    </button>
                </>
            ),
        },
    ];

    const handlePagingChange = (rowsPerPage: number, page: number) => {
        dispatch(
            getList({
                skip: rowsPerPage * (page - 1),
                take: rowsPerPage,
            })
        );
    }

    return (
        <div className="main-data-grid mt-5">
            <DataTableServerPaging columns={columns} data={teamList} rows={rows} handlePagingChange={handlePagingChange} />
        </div>
    );
};

export default TeamTable;
