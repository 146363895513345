import {BaseServiceClass} from 'base-service-class';
import httpClient, {ApiResponseWrapper} from 'http-client';
import {EmailModel, EmailRequest, GetEmailByJob} from "./models";

export default class EmailApi extends BaseServiceClass {
    static createEmail(args: EmailRequest, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_EMAIL.email}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }

    static getLastSeq(jobId: string, token: string) {
        return httpClient
            .get<ApiResponseWrapper<number>>(`${this.BASE_URL}${this.PATH_EMAIL.email_get_last_seq}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    job_id: jobId,
                }
            })
            .then((res) => res.data);
    }

    static getEmailByJob(args: GetEmailByJob, token: string) {
        return httpClient
            .get<ApiResponseWrapper<EmailModel[]>>(`${this.BASE_URL}${this.PATH_EMAIL.email_get_by_job}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args
            })
            .then((res) => res.data);
    }
}
