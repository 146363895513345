import React from 'react';
import StaffReportPage from "./staffReport/containers/StaffReportPage";
import EmployeeWorkPage from "./employeeWork/containers/EmployeeWorkPage";
import {useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";
import SeparateTabs from "../../../components/Tabs/SeparateTabs";
import EmployeeWorkProcessPage from "./employeeWorkProcess/containers/EmployeeWorkProcessPage";

const StaffReportMainPage = () => {
    const tabs = [
        { id: 'staff-report', name: "Staff Report", link: `#`, content: <StaffReportPage /> },
        { id: 'employee-work', name: "Employee Work", link: `#`, content: <EmployeeWorkPage /> },
        { id: 'employee-work-process', name: "Employee Work Process", link: `#`, content: <EmployeeWorkProcessPage /> },
    ];

    const dynamicNavId = useAppSelector((state: RootState) => state.sidebar.dynamicNavId ?? "");

    return (
        <div>
            <SeparateTabs tabs={tabs} openTab={dynamicNavId} />
        </div>
    );
};

export default StaffReportMainPage;
