import {HttpClientError, RequestStatus} from "http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {Employee, GetListByPositionRequest, GetListRequest, InputEmployee} from "./models";
import EmployeeApi from "./employee.api";
import {District} from "../district/models";
import {Option} from "../../../components/SelectInput/BasicSelectInput";

export interface EmployeeSlice {
    list?: Array<Employee>;
    listByPosition?: Array<Employee>;
    single?: Employee;
    options?: Array<Option>;
    listByPositionOptions?: Array<Option>;
    rows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getList = createAsyncThunk(
    'employee/getList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmployeeApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getListByPosition = createAsyncThunk(
    'employee/getListByPosition',
    async (args: GetListByPositionRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmployeeApi.getListByPosition(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingle = createAsyncThunk(
    'employee/getSingle',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmployeeApi.getSingle(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createEmployee = createAsyncThunk(
    'employee/create',
    async (args: InputEmployee, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmployeeApi.createEmployee(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateEmployee = createAsyncThunk(
    'employee/update',
    async (args: InputEmployee, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmployeeApi.updateEmployee(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteEmployee = createAsyncThunk(
    'employee/delete',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmployeeApi.deleteEmployee(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const employeeSlice = createSlice({
    name: 'employeeState',
    initialState: {} as EmployeeSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            // state.single = {} as Employee;
        },
    },
    extraReducers: (builder) => {
        // get list data reducer
        builder.addCase(getList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, { payload }) => {
            state.rows = payload.rows;
            state.list = payload.data;
            state.options = payload.data.map((employee: Employee) => {
                return {
                    text: employee.name,
                    label: employee.name,
                    value: employee.id
                }
            })
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state) => {
            state.isLoading = false;
        });
        // get list data reducer
        builder.addCase(getListByPosition.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getListByPosition.fulfilled, (state, { payload }) => {
            state.rows = payload.rows;
            state.listByPosition = payload.data;
            state.listByPositionOptions = payload.data.map((employee: Employee) => {
                return {
                    text: employee.name,
                    label: employee.name,
                    value: employee.id
                }
            })
            state.isLoading = false;
        });
        builder.addCase(getListByPosition.rejected, (state) => {
            state.isLoading = false;
        });
        // get single data reducer
        builder.addCase(getSingle.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingle.fulfilled, (state, { payload }) => {
            state.single = payload?.data;
            state.isLoading = false;
        });
        builder.addCase(getSingle.rejected, (state) => {
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createEmployee.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createEmployee.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createEmployee.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updateEmployee.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateEmployee.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateEmployee.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deleteEmployee.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteEmployee.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteEmployee.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const { reset } = employeeSlice.actions;
export default employeeSlice.reducer;