import React, {useState} from 'react';
import JobTable from '../components/JobTable';
import {Link} from "react-router-dom";
import Button from "components/Button/Button";
import DefaultModal from "components/Modal/DefaultModal";
import JobImport from "../components/JobImport";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {UserCredential} from "../../../../login/models";
import AllJobTable from "../components/AllJobTable";

const JobPage = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Jobs</h1>
                <div className="flex">
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.Import) &&
                        <div className="mr-2">
                            <Button title="Import From CSV" onClick={toggleModal}/>
                        </div>
                    }
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.Input) &&
                        <Link
                            to={{
                                pathname: '/worksheet/job/detail',
                            }}
                        >
                            <Button title="Input Job"/>
                        </Link>
                    }
                </div>
            </div>
            <div className="mt-3">
                <JobTable/>
                <AllJobTable />
                <DefaultModal
                    title="Import From CSV"
                    show={showModal}
                    onClose={toggleModal}
                    body={
                        <JobImport toggleModal={toggleModal}/>
                    }
                />
            </div>
        </div>
    );
};

export default JobPage;
