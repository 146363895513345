import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {useAppDispatch} from 'helpers/redux';
import {isObjectEmpty} from 'helpers/checkEmptiness';
import {HolidayModel, HolidayOvertimeApprovalEnum, InputHoliday} from "../../models";
import BasicDatePicker from "../../../../components/DatePicker/BasicDatePicker";
import {dateSetTimeToNow} from "../../../../helpers/convert";
import {createHoliday, updateHoliday} from "../../profile.reducer";

const AddHolidayForm = ({
                                    singleHoliday = {} as HolidayModel,
                                    userId = '',
                                    isEdit = false,
                                }: AddHolidayFormProps) => {
    const dispatch = useAppDispatch();
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<InputHoliday>({
        defaultValues: {
            notes: '',
            reqDate: dateSetTimeToNow(new Date().toISOString()) ?? new Date().toISOString(),
        }
    });

    useEffect(() => {
        if (!isObjectEmpty(singleHoliday)) {
            reset({
                reqDate: singleHoliday.reqDate,
                notes: singleHoliday.notes,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleHoliday]);

    const onSubmit = (data: InputHoliday) => {
        let _data = {
            reqDate: data.reqDate,
            notes: data.notes,
            status: HolidayOvertimeApprovalEnum.Requested,
            rejectionNotes: '',
            managerApproved: '',
            approvedBy: '',
            userId: userId,
        };
        if (isEdit) {
            let update = {..._data, id: singleHoliday?.id}
            dispatch(updateHoliday(update));
        } else {
            dispatch(createHoliday(_data));
        }
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <BasicDatePicker
                    label="Request Date"
                    name="reqDate"
                    placeholderText="Select Request Date"
                    control={control}
                    withPreciseTime
                />
                <InputText
                    {...register('notes')}
                    label="Notes"
                    placeholder="Input notes"
                    message={errors.notes?.message}
                />

                <div className="pt-2 pb-2">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface AddHolidayFormProps {
    singleHoliday?: HolidayModel;
    userId?: string;
    isEdit?: boolean;
}

export default AddHolidayForm;
