import * as yup from "yup";
import {KatapultColumnSort, MainKatapultColumnSort} from "./models";

export const schema = yup.object({
    range_start: yup.number().required(),
    range_end: yup.number().required(),
}).required();

export const katapultSortOption = [
    {
        label: "project_name",
        text: "project_name",
        value: KatapultColumnSort.project_name.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: KatapultColumnSort.department_name.toString()
    },
    {
        label: "kind_name",
        text: "kind_name",
        value: KatapultColumnSort.kind_job.toString()
    },
    {
        label: "ts_created",
        text: "ts_created",
        value: KatapultColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: KatapultColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: KatapultColumnSort.number_job.toString()
    },
    {
        label: "other_number",
        text: "other_number",
        value: KatapultColumnSort.other_number.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: KatapultColumnSort.status.toString()
    },
    {
        label: "date_due",
        text: "date_due",
        value: KatapultColumnSort.due_date.toString()
    },
    {
        label: "date_assigned_katapult",
        text: "date_assigned_katapult",
        value: KatapultColumnSort.date_assign.toString()
    },
    {
        label: "pole_total",
        text: "pole_total",
        value: KatapultColumnSort.req_pole.toString()
    },
]

export const MainKatapultSortOption = [
    {
        label: "project_name",
        text: "project_name",
        value: MainKatapultColumnSort.project_name.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: MainKatapultColumnSort.department_name.toString()
    },
    {
        label: "kind_name",
        text: "kind_name",
        value: MainKatapultColumnSort.kind_job.toString()
    },
    {
        label: "ts_created",
        text: "ts_created",
        value: MainKatapultColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: MainKatapultColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: MainKatapultColumnSort.number_job.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: MainKatapultColumnSort.status.toString()
    },
    {
        label: "date_due",
        text: "date_due",
        value: MainKatapultColumnSort.due_date.toString()
    },
    {
        label: "date_assigned_katapult",
        text: "date_assigned_katapult",
        value: MainKatapultColumnSort.date_assign.toString()
    },
    {
        label: "pole_total",
        text: "pole_total",
        value: MainKatapultColumnSort.req_pole.toString()
    },
]