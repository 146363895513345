import {HttpClientError, RequestStatus} from 'http-client';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import {
    CancelRequest,
    CheckRequest,
    CreateJobFile, JobFileNew,
    GetListRequest,
    InputImportCSV,
    InputJob,
    Job,
    WaitingRequest, JobFile, ChangeStatusRequest, JobAllModel, GetDropdownListRequest, ChangeCompleteDateRequest
} from './models';
import JobApi from './job.api';
import {UploadProgress, UploadStatus} from 'features/basic/fileAttachment/models';
import {Option} from 'components/SelectInput/BasicSelectInput';
import {isArrayEmpty} from '../../../../helpers/checkEmptiness';
import FileAttachmentApi from "../../../basic/fileAttachment/fileAttachment.api";

export interface JobSlice {
    list?: Array<Job>;
    listNew?: Array<Job>;
    listAllJob?: Array<JobAllModel>;
    single?: Job;
    jobFile?: Array<JobFileNew>;
    rows?: number;
    newRows?: number;
    options?: Array<Option>;
    isLoading?: boolean;
    uploadProgress?: Array<UploadProgress>;
    uploadStatus?: Array<UploadStatus>;
    importProgress?: number;
    rowsAffected?: number;
    status?: RequestStatus;
    deleteFileStatus?: RequestStatus;
    actionStatus?: RequestStatus;
    changeDateStatus?: RequestStatus;
    getStatus?: RequestStatus;
    error?: HttpClientError;
    errorUpload?: HttpClientError;
    uploadError?: HttpClientError;
}

export const getListNew = createAsyncThunk(
    'job/getListNew',
    async (args: GetListRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.getListNew(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getList = createAsyncThunk(
    'job/getList',
    async (args: GetListRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getJobDropdown = createAsyncThunk(
    'job/getJobDropdown',
    async (args: GetDropdownListRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.getJobDropdown(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getAllJobList = createAsyncThunk(
    'job/getAllJobList',
    async (args: undefined, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.getAllJobList(userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingle = createAsyncThunk(
    'job/getSingle',
    async (id: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.getSingle(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createJob = createAsyncThunk(
    'job/create',
    async (args: InputJob, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            let {local_files, ...rest} = args;
            let _job = {
                ...rest,
                date_due: rest.date_due,
                date_due_fielding: rest.date_due_fielding,
                pole_total: rest.pole_total ?? 0,
                status_id: "",
                waiting_subject_id: "",
                waiting_notes: "",
                waiting_ts: new Date().toISOString(),
                cancel_notes: "",
                prep_notes: "",
                prep_reference_job_id: "",
                qc_notes: "",
                calspec_notes: "",
                project_id: rest.project_id ?? '',
            };
            const response = await JobApi.createJob(
                _job,
                userAuth?.id as string
            );

            if (response.status) {
                if (!isArrayEmpty(args.local_files ?? [])) {
                    dispatch(
                        uploadJobFile({
                            job_id: response.data,
                            local_files: args.local_files,
                        })
                    );
                } else {
                    return response;
                }
            } else {
                return rejectWithValue({
                    message: 'failed when create job: ' + response.message,
                } as HttpClientError);
            }
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateJob = createAsyncThunk(
    'job/update',
    async (args: InputJob, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            let {local_files, pole_total, isEdit, ...rest} = args;
            let _job = {
                ...rest,
                date_due: rest.date_due,
                date_due_fielding: rest.date_due_fielding,
                project_id: rest.project_id ?? '',
            };
            const response = await JobApi.updateJob(
                _job,
                userAuth?.id as string
            );

            if (response.status) {
                if (!isArrayEmpty(args.local_files ?? [])) {
                    dispatch(
                        uploadJobFile({
                            job_id: args.id ?? '',
                            local_files: args.local_files,
                        })
                    );
                } else {
                    return response;
                }
            } else {
                return rejectWithValue({
                    message: 'failed when create job: ' + response.message,
                } as HttpClientError);
            }
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteJob = createAsyncThunk(
    'job/delete',
    async (args: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.deleteJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const uploadJobFile = createAsyncThunk(
    'job/uploadJobFile',
    async (args: CreateJobFile, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((file, index) => {
                        return {
                            index: index,
                            filename: file.name,
                            status: RequestStatus.pending,
                            createStatus: RequestStatus.pending,
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (file, index) => {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('job_id', args.job_id);
                const config = {
                    headers: {
                        Authorization: `Bearer ${userAuth?.id as string}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        location: 'job-new',
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            })
                        );
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.success,
                        })
                    );
                    return {
                        name: file.name,
                        path: data.result,
                        job_id: args.job_id
                    }
                }).catch((e) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.failed,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                        job_id: args.job_id
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                dispatch(createJobFile(attachment as Array<JobFileNew>));
            });
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createJobFile = createAsyncThunk(
    'job/createJobFile',
    async (args: Array<JobFileNew>, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            const createResponse = args?.map(async (arg, index) => {
                if (arg.path !== "") {
                    return await JobApi.createJobFile(arg, userAuth?.id as string).then((data) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.success,
                            })
                        );
                        return data;
                    }).catch((e) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.failed,
                            })
                        );
                        return e;
                    });
                } else {
                    return {};
                }
            });

            Promise.all(createResponse).then(async (attachment) => {
                console.log('saved successfully', attachment);
            });
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getJobFile = createAsyncThunk(
    'job/getJobFile',
    async (id: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.getJobFile(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteJobFile = createAsyncThunk(
    'job/deleteJobFile',
    async (args: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.deleteJobFile(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const checkJob = createAsyncThunk(
    'job/checkJob',
    async (args: CheckRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.checkJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const waitingJob = createAsyncThunk(
    'job/waitingJob',
    async (args: WaitingRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.waitingJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const changeStatusJob = createAsyncThunk(
    'job/holdJob',
    async (args: ChangeStatusRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.changeStatusJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const changeCompleteDate = createAsyncThunk(
    'job/changeCompleteDate',
    async (args: ChangeCompleteDateRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.changeCompleteDate(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const cancelJob = createAsyncThunk(
    'job/cancelJob',
    async (args: CancelRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobApi.cancelJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const importCSV = createAsyncThunk(
    'job/import',
    async (args: InputImportCSV, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            let formData = new FormData();
            formData.append('file', args.local_files);
            const config = {
                headers: {
                    Authorization: `Bearer ${userAuth?.id as string}`,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    dispatch(setImportProgress(percentCompleted));
                },
            };
            return JobApi.importCSV(formData, config).then((data) => {
                return data;
            }).catch((e) => {
                return rejectWithValue(e as HttpClientError);
            });
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const jobSlice = createSlice({
    name: 'jobState',
    initialState: {} as JobSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.single = {} as Job;
            state.jobFile = [] as Array<JobFile>;
            state.actionStatus = RequestStatus.pending;
            state.rowsAffected = 0;
            state.importProgress = 0;
            state.deleteFileStatus = RequestStatus.pending;
            state.uploadStatus = [];
            state.uploadProgress = [];
        },
        setUploadProgress: (state, payload: PayloadAction<Array<UploadProgress>>) => {
            state.uploadProgress = payload.payload;
        },
        setUploadStatus: (state, payload: PayloadAction<Array<UploadStatus>>) => {
            state.uploadStatus = payload.payload;
        },
        setImportProgress: (state, payload: PayloadAction<number>) => {
            state.importProgress = payload.payload;
        },
        updateUploadProgress: (state, payload: PayloadAction<UploadProgress>) => {
            let progress = state.uploadProgress ?? [];
            let objIndex = state.uploadProgress?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            progress[objIndex as number].progress = payload.payload.progress;
            state.uploadProgress = progress;
        },
        updateUploadStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].status = payload.payload.status;
            state.uploadStatus = status;
        },
        updateCreateStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].createStatus = payload.payload.createStatus;
            state.uploadStatus = status;
        },
        setUploadError: (state, payload: any) => {
            state.uploadError = payload.payload;
        },
        resetErrorUpload: (state) => {
            state.uploadError = {} as HttpClientError;
        },
        resetChangeCompleteDate: (state) => {
            state.error = {} as HttpClientError;
            state.changeDateStatus = RequestStatus.pending;
        }
    },
    extraReducers: (builder) => {
        // get list data reducer
        builder.addCase(getListNew.pending, (state) => {
            state.error = {} as HttpClientError;
            state.getStatus = RequestStatus.pending;
            state.isLoading = true;
        });
        builder.addCase(getListNew.fulfilled, (state, {payload}) => {
            state.newRows = payload.rows;
            state.listNew = payload.data;
            state.getStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(getListNew.rejected, (state) => {
            state.isLoading = false;
            state.getStatus = RequestStatus.failed;
        });
        // get list data reducer
        builder.addCase(getList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, {payload}) => {
            state.rows = payload.rows;
            state.list = payload.data;
            state.options = payload.data.map((item) => {
                return {value: item.id, label: item.number_job, text: item.number_job};
            })
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state) => {
            state.isLoading = false;
        });
        // get list data reducer
        builder.addCase(getJobDropdown.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getJobDropdown.fulfilled, (state, {payload}) => {
            state.rows = payload.rows;
            state.options = payload.data.map((item) => {
                return {value: item.id, label: item.number_job, text: item.number_job};
            })
            state.isLoading = false;
        });
        builder.addCase(getJobDropdown.rejected, (state) => {
            state.isLoading = false;
        });
        // get list data reducer
        builder.addCase(getAllJobList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getAllJobList.fulfilled, (state, {payload}) => {
            state.listAllJob = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getAllJobList.rejected, (state) => {
            state.isLoading = false;
        });
        // get single data reducer
        builder.addCase(getSingle.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingle.fulfilled, (state, {payload}) => {
            state.single = payload?.data;
            state.isLoading = false;
        });
        builder.addCase(getSingle.rejected, (state) => {
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createJob.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createJob.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createJob.rejected, (state, {payload}) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(uploadJobFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(uploadJobFile.fulfilled, (state, payload) => {
            state.isLoading = false;
        });
        builder.addCase(uploadJobFile.rejected, (state, {payload}) => {
            state.errorUpload = payload as HttpClientError;
            state.isLoading = false;
        });

        builder.addCase(getJobFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getJobFile.fulfilled, (state, {payload}) => {
            state.jobFile = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getJobFile.rejected, (state) => {
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updateJob.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateJob.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateJob.rejected, (state, {payload}) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deleteJob.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteJob.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteJob.rejected, (state, {payload}) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        builder.addCase(deleteJobFile.pending, (state) => {
            state.deleteFileStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteJobFile.fulfilled, (state) => {
            state.deleteFileStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteJobFile.rejected, (state, {payload}) => {
            state.deleteFileStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // change status reducer
        builder.addCase(checkJob.pending, (state) => {
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(checkJob.fulfilled, (state) => {
            state.actionStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(checkJob.rejected, (state, {payload}) => {
            state.actionStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // change status reducer
        builder.addCase(waitingJob.pending, (state) => {
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(waitingJob.fulfilled, (state) => {
            state.actionStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(waitingJob.rejected, (state, {payload}) => {
            state.actionStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // change status reducer
        builder.addCase(changeStatusJob.pending, (state) => {
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(changeStatusJob.fulfilled, (state) => {
            state.actionStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(changeStatusJob.rejected, (state, {payload}) => {
            state.actionStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // change status reducer
        builder.addCase(changeCompleteDate.pending, (state) => {
            state.changeDateStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(changeCompleteDate.fulfilled, (state) => {
            state.changeDateStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(changeCompleteDate.rejected, (state, {payload}) => {
            state.changeDateStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // change status reducer
        builder.addCase(cancelJob.pending, (state) => {
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(cancelJob.fulfilled, (state) => {
            state.actionStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(cancelJob.rejected, (state, {payload}) => {
            state.actionStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // import csv reducer
        builder.addCase(importCSV.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(importCSV.fulfilled, (state, {payload}) => {
            state.status = RequestStatus.success;
            state.rowsAffected = payload.data?.rowsAffected[0];
            state.isLoading = false;
        });
        builder.addCase(importCSV.rejected, (state, {payload}) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const {
    reset,
    setUploadProgress,
    updateUploadProgress,
    setUploadStatus,
    updateUploadStatus,
    updateCreateStatus,
    setImportProgress,
    setUploadError,
    resetErrorUpload,
    resetChangeCompleteDate
} = jobSlice.actions;
export default jobSlice.reducer;
