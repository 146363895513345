import {JobHistoryColumnSort} from "./models";

export const historySortOption = [
    {
        label: "project",
        text: "project",
        value: JobHistoryColumnSort.project_name.toString()
    },
    {
        label: "team",
        text: "team",
        value: JobHistoryColumnSort.team.toString()
    },
    {
        label: "koj",
        text: "koj",
        value: JobHistoryColumnSort.kind_job.toString()
    },
    {
        label: "request_date",
        text: "request_date",
        value: JobHistoryColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: JobHistoryColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: JobHistoryColumnSort.number_job.toString()
    },
    {
        label: "status",
        text: "status",
        value: JobHistoryColumnSort.status.toString()
    },
    {
        label: "type",
        text: "type",
        value: JobHistoryColumnSort.type.toString()
    },
    {
        label: "district",
        text: "district",
        value: JobHistoryColumnSort.district.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: JobHistoryColumnSort.department.toString()
    },
    {
        label: "sales_order",
        text: "sales_order",
        value: JobHistoryColumnSort.sales_order.toString()
    },
    {
        label: "source_name",
        text: "source_name",
        value: JobHistoryColumnSort.source.toString()
    },
    {
        label: "due_date",
        text: "due_date",
        value: JobHistoryColumnSort.due_date.toString()
    },
    {
        label: "complete_date",
        text: "complete_date",
        value: JobHistoryColumnSort.complete_date.toString()
    },
    {
        label: "request_pole",
        text: "request_pole",
        value: JobHistoryColumnSort.req_pole.toString()
    }
]