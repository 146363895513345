import {HttpClientError, RequestStatus} from "../../../../http-client";
import {
    AssignCalcSpecRequest,
    CalcSpecSummary,
    CalcSpecType,
    GetCalcSpecFilterRequest,
    GetCalcSpecRequest, GetMainCalcSpecFilterRequest,
    MainCalcSpecType
} from "./models";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../../app/store";
import CalcSpecApi from "./calcSpec.api";

export interface CalcSpecSlice {
    calcSpecList: Array<CalcSpecType>;
    singleCalcSpec: CalcSpecType;
    mainCalcSpecList: Array<MainCalcSpecType>;
    calcSpecSummaryList: Array<CalcSpecSummary>;
    rows?: number;
    mainRows?: number;
    isLoading?: boolean;
    isAssignLoading?: boolean;
    isSingleLoading?: boolean;
    status?: RequestStatus;
    calcSpecFetchStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getCalcSpec = createAsyncThunk(
    'process/calcSpec/getCalcSpec',
    async (args: GetCalcSpecRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await CalcSpecApi.getCalcSpec(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getCalcSpecWithFilter = createAsyncThunk(
    'process/calcSpec/getCalcSpecWithFilter',
    async (args: GetCalcSpecFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await CalcSpecApi.getCalcSpecWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getCalcSpecSummary = createAsyncThunk(
    'process/calcSpec/getCalcSpecSummary',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await CalcSpecApi.getCalcSpecSummary(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingleCalcSpec = createAsyncThunk(
    'process/calcSpec/getSingleCalcSpec',
    async (args: GetCalcSpecRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await CalcSpecApi.getSingleCalcSpec(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainCalcSpec = createAsyncThunk(
    'process/calcSpec/getMainCalcSpec',
    async (args: GetCalcSpecRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await CalcSpecApi.getMainCalcSpec(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainCalcSpecWithFilter = createAsyncThunk(
    'process/calcSpec/getMainCalcSpecWithFilter',
    async (args: GetMainCalcSpecFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await CalcSpecApi.getMainCalcSpecWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const assignCalcSpec = createAsyncThunk(
    'process/calcSpec/assignCalcSpec',
    async (args: AssignCalcSpecRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await CalcSpecApi.assignCalcSpec(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const calcSpecSlice = createSlice({
    name: 'calcSpecState',
    initialState: {} as CalcSpecSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.calcSpecFetchStatus = RequestStatus.pending;
        },
    },
    extraReducers: (builder) => {
        // fetch data reducer
        builder.addCase(getCalcSpec.pending, (state) => {
            state.isLoading = true;
            state.calcSpecFetchStatus = RequestStatus.pending;
        });
        builder.addCase(getCalcSpec.fulfilled, (state, action) => {
            state.isLoading = false;
            state.calcSpecList = action.payload.data;
            state.rows = action.payload.rows;
            state.calcSpecFetchStatus = RequestStatus.success;
        });
        builder.addCase(getCalcSpec.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
            state.calcSpecFetchStatus = RequestStatus.failed;
        });
        // fetch data reducer
        builder.addCase(getCalcSpecWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCalcSpecWithFilter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.calcSpecList = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getCalcSpecWithFilter.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getSingleCalcSpec.pending, (state) => {
            state.isSingleLoading = true;
            state.calcSpecFetchStatus = RequestStatus.pending;
        });
        builder.addCase(getSingleCalcSpec.fulfilled, (state, action) => {
            state.isSingleLoading = false;
            state.singleCalcSpec = action.payload.data;
            state.calcSpecFetchStatus = RequestStatus.success;
        });
        builder.addCase(getSingleCalcSpec.rejected, (state, action) => {
            state.isSingleLoading = false;
            state.error = action.payload as HttpClientError;
            state.calcSpecFetchStatus = RequestStatus.failed;
        });
        // fetch data reducer
        builder.addCase(getCalcSpecSummary.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCalcSpecSummary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.calcSpecSummaryList = action.payload.data;
        });
        builder.addCase(getCalcSpecSummary.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // fetch data reducer
        builder.addCase(getMainCalcSpec.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainCalcSpec.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mainCalcSpecList = action.payload.data;
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainCalcSpec.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // fetch data reducer
        builder.addCase(getMainCalcSpecWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainCalcSpecWithFilter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mainCalcSpecList = action.payload.data;
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainCalcSpecWithFilter.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // create data reducer
        builder.addCase(assignCalcSpec.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isAssignLoading = true;
        });
        builder.addCase(assignCalcSpec.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isAssignLoading = false;
        });
        builder.addCase(assignCalcSpec.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isAssignLoading = false;
        });
    }
});

export const { reset } = calcSpecSlice.actions;
export default calcSpecSlice.reducer;