import { useLocation, Navigate, Outlet } from "react-router-dom";

import React from "react";

const AccessAuth = ({ isAllowAccess }: AccessAuthProps) => {
    const location = useLocation();

    return isAllowAccess ? (
        <Outlet />
    ) : <Navigate to="/unauthorized" state={{ from: location }} replace />
};

export interface AccessAuthProps {
    isAllowAccess?: boolean;
}

export default AccessAuth;