import React, {useEffect, useState} from 'react';
import TextArea from 'components/TextArea/TextArea';
import DefaultModal from 'components/Modal/DefaultModal';
import Button from 'components/Button/Button';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {useForm} from 'react-hook-form';
import {FileAttachment} from "types";
import {RootState} from 'app/store';
import {
    isObjectEmpty,
} from 'helpers/checkEmptiness';
import {HttpClientError, RequestStatus} from 'http-client';
import {
    customWarnOption, deletedStatusOption, savedStatusOption,
} from 'helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import FileDropzone from 'components/FileInput/Dropzone';
import JobFile from 'features/worksheet/process/job/components/JobFile';
import {Alert} from 'flowbite-react';
import {HiInformationCircle} from 'react-icons/hi';
import {darkGreen, darkRed} from "../../../../../constants";
import {
    completeKatapult,
    deleteKatapultFile,
    getKatapultFile,
    reset,
    setUploadProgress,
    updateKatapultNotes,
    uploadKatapultFile
} from "../katapult.reducer";
import FileProgress from "../../../../basic/fileAttachment/components/FileProgress";
import {InputKatapult} from "../models";
import {getSingleKatapult} from "../../../process/katapult/processKatapult.reducer";
import {KatapultModel} from "../../../process/katapult/models";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";

const DetailKatapultForm = ({jobId = undefined}: DetailKatapultFormProps) => {
    const {register, handleSubmit, reset: resetForm} =
        useForm<InputKatapult>();

    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const [showAttachmentModal, setShowAttachmentModal] =
        useState<boolean>(false);
    const [currentFiles, setCurrentFiles] = useState<Array<FileAttachment>>([]);
    const [totalFileRemoved, setTotalFileRemoved] = useState<number>(0);
    const [localFiles, setLocalFiles] = useState<Array<File>>([]);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const singleKatapult = useAppSelector(
        (state: RootState) => state.processKatapult.singleKatapult ?? {} as KatapultModel
    );
    const katapultFile = useAppSelector(
        (state: RootState) => state.katapult.jobKatapultFile ?? []
    );
    const uploadStatus = useAppSelector((state: RootState) => state.katapult.uploadStatus ?? []);
    const uploadProgresses = useAppSelector(
        (state: RootState) => state.katapult.uploadProgress ?? []
    );
    const deleteFileStatus = useAppSelector(
        (state: RootState) => state.katapult.deleteFileStatus
    );
    const status = useAppSelector((state: RootState) => state.katapult.status);
    const error = useAppSelector(
        (state: RootState) => state.katapult.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (jobId && jobId !== "") {
            dispatch(getKatapultFile(jobId as string));
            dispatch(getSingleKatapult(jobId as string));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);

    useEffect(() => {
        if (!isObjectEmpty(singleKatapult)) {
            resetForm({notes: singleKatapult.katapult_notes});
        }
    }, [singleKatapult]);


    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(setUploadProgress([]));
                // return navigate('/worksheet/process/job-prep', {replace: true});
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    useEffect(() => {
        if (deleteFileStatus !== undefined && deleteFileStatus !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    deleteFileStatus === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getKatapultFile(jobId as string));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteFileStatus, error]);

    const onModalAttachmentClose = () => {
        setLocalFiles([]);
        dispatch(reset());
        dispatch(getKatapultFile(jobId as string));
        setShowAttachmentModal(false);
    };

    const onFileChange = (files: Array<File>) => {
        setLocalFiles(files);
    };

    const onFileRemove = (fileId: string) => {
        let newFiles = currentFiles.filter((f) => f.id !== fileId);
        setCurrentFiles(newFiles);
        setTotalFileRemoved((prevState) => prevState + 1);
    };

    const onSubmit = (data: InputKatapult) => {
        dispatch(updateKatapultNotes({
            job_id: jobId as string,
            notes: data.notes,
        }));
    };

    const onCompleteClicked = () => {
        MySwal.fire(customWarnOption('Complete Katapult', 'Are you sure?')).then(
            (result) => {
                if (result.isConfirmed) {
                    dispatch(completeKatapult(jobId as string));
                }
            }
        );
    };

    const onFileDelete = (fileId: string) => {
        MySwal.fire({
            text: `Are you sure you want to delete this file?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: darkRed,
            cancelButtonColor: darkGreen,
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteKatapultFile(fileId));
            }
        });
    };

    const onUploadModalClose = () => {
        setShowUploadModal(false);
        onModalAttachmentClose();
    }

    const toggleUploadModal = (args: boolean) => {
        setShowUploadModal(args);
        onModalAttachmentClose();
    };

    const KatapultFileAttachment = () => {
        const onUpload = () => {
            if (localFiles.length === 0) {
                MySwal.fire(
                    {
                        title: "File Empty!",
                        text: "You haven't selected any file",
                        icon: 'warning' as SweetAlertIcon,
                    }
                )
            } else {
                setShowUploadModal(true);
                dispatch(uploadKatapultFile({
                    job_id: jobId as string,
                    local_files: localFiles,
                }));
            }
        };

        return (
            <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <FileDropzone
                    label="Attachment"
                    onChange={onFileChange}
                    uploadProgress={uploadProgresses}
                    files={localFiles}
                />

                {totalFileRemoved > 0 && (
                    <div className="mt-3">
                        <Alert color="warning" icon={HiInformationCircle}>
                            <span>
                                Changes made in this file list will not be saved
                                until the form is submitted
                            </span>
                        </Alert>
                    </div>
                )}

                <div className="flex justify-center my-2">
                    <div className="mx-1">
                        <Button
                            title="Cancel"
                            type="button"
                            onClick={onModalAttachmentClose}
                        />
                    </div>
                    <div className="mx-1">
                        <Button title="Save" type="button" onClick={onUpload}/>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <TextArea
                    label="Katapult Notes"
                    placeholder="Input Katapult Notes"
                    {...register('notes')}
                />
                <div className="my-3">
                    <div className="mr-2 flex-grow">
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetKatapult, ActionEnum.Attachment) &&
                            <Button
                                title="Add/Remove Attachment"
                                type="button"
                                onClick={() => setShowAttachmentModal(true)}
                            />
                        }
                    </div>
                </div>
                <div>
                    <JobFile files={currentFiles} uploadedFile={katapultFile} onFileRemove={onFileRemove} onFileDelete={onFileDelete}/>
                </div>
            </div>
            <div className="mt-3 grid md:grid-cols-1 gap-4">
                <div className="flex flex-grow justify-end">
                    <div className="mr-2">
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetKatapult, ActionEnum.Edit) &&
                            <Button
                                title="Save"
                                type="submit"
                            />
                        }
                    </div>
                    <div className="inline">
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetKatapult, ActionEnum.CompleteKatapult) &&
                            <Button
                                title="COMPLETE KATAPULT"
                                type="button"
                                onClick={onCompleteClicked}
                            />
                        }
                    </div>
                </div>
            </div>
            <DefaultModal
                title="Katapult Attachment"
                show={showAttachmentModal}
                onClose={onModalAttachmentClose}
                body={<KatapultFileAttachment />}
            />
            <DefaultModal
                title="Uploading File"
                show={showUploadModal}
                onClose={() => onUploadModalClose()}
                body={
                    <FileProgress uploadStatus={uploadStatus} uploadProgress={uploadProgresses}
                                  setShowModal={toggleUploadModal}/>
                }
            />
        </form>
    );
};

export interface DetailKatapultFormProps {
    jobId?: string;
}

export default DetailKatapultForm;
