import {BaseServiceClass} from "../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../http-client";
import {GetJobIn, GetJobStatistic, InputFilter, JobIn, JobStatistic} from "./models";


export default class JobReportApi extends BaseServiceClass {

    static getJobStatistic(args: GetJobStatistic, token: string) {
        return httpClient.get<ApiResponseWrapper<JobStatistic[]>>(this.PATH_REPORT.get_job_statistic, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getJobIn(args: GetJobIn, token: string) {
        return httpClient.get<ApiResponseWrapper<JobIn[]>>(this.PATH_REPORT.get_job_in, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static exportToCsv(args: InputFilter, token: string) {
        const path = "/api/Job/ExportJobStatistikDataToExcel"
        return httpClient
            .get<any>(`${this.BASE_URL}${path}`,  {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    startDate: args.startDate,
                    endDate: args.endDate,
                }
            })
            .then((res) => res.data);
    }

    static exportJobInToCsv(args: InputFilter, token: string) {
        const path = "/api/Job/ExportJobInDataToExcel"
        return httpClient
            .get<any>(`${this.BASE_URL}${path}`,  {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    startDate: args.startDate,
                    endDate: args.endDate,
                }
            })
            .then((res) => res.data);
    }
}