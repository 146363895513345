import {BaseServiceClass} from "../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../http-client";
import {
    AbsentLogDetailModel,
    AbsentLogModel, AttendanceExportReq,
    GetAttendanceDetailLogReq,
    GetAttendanceLogReq,
    GetHolidayReportReq,
    GetOvertimeReportReq,
    HolidayModel,
    OvertimeModel
} from "./models";

export default class AttendanceReportApi extends BaseServiceClass {
    static getOvertimeReport(args: GetOvertimeReportReq, token: string) {
        return httpClient.get<ApiResponseWrapper<OvertimeModel[]>>(this.PATH_REPORT.get_overtime_report, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getHolidayReport(args: GetHolidayReportReq, token: string) {
        return httpClient.get<ApiResponseWrapper<HolidayModel[]>>(this.PATH_REPORT.get_holiday_report, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getAttendanceLog(args: GetAttendanceLogReq, token: string) {
        return httpClient.get<ApiResponseWrapper<AbsentLogModel[]>>(this.PATH_REPORT.get_attendance_report, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static changeHolidayToRequest(id: string, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(this.PATH_REPORT.change_holiday_request, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {id}
        }).then((res) => res.data);
    }

    static getAttendanceLogDetail(args: GetAttendanceDetailLogReq, token: string) {
        return httpClient.get<ApiResponseWrapper<AbsentLogDetailModel>>(this.PATH_REPORT.get_attendance_detail_report, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static attendanceExport(args: AttendanceExportReq, token: string) {
        return httpClient
            .get<any>(this.PATH_REPORT.attendance_export, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args
            })
            .then((res) => res.data);
    }
}