import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../helpers/redux";
import {getHistory} from "../history.reducer";
import InputText from "../../../../components/TextInput/InputText";
import Button from "../../../../components/Button/Button";
import {Link} from "react-router-dom";
import {customStatusOption, customWarnOption} from "../../../../helpers/alertLabel";
import {
    cancelJob,
    changeCompleteDate,
    changeStatusJob,
    reset, resetChangeCompleteDate,
    waitingJob
} from "../../../worksheet/process/job/job.reducer";
import {
    JOB_STATUS_COMPLETE, JOB_STATUS_COMPLETE_KATAPULT, JOB_STATUS_COMPLETE_MRE,
    JOB_STATUS_HOLD, JOB_STATUS_KATAPULT,
    JOB_STATUS_PREP, JOB_STATUS_QC_MRE,
    JOB_STATUS_WAITING, JOB_STATUS_WAITING_KATAPULT,
    JOB_STATUS_WAITING_MRE
} from "../../../../constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {HttpClientError, RequestStatus} from "../../../../http-client";
import {RootState} from "../../../../app/store";
import {MdMotionPhotosPaused, MdOutlineCancel, MdPauseCircleOutline} from "react-icons/md";
import {TbRefreshAlert} from "react-icons/tb";
import {RiEditCircleFill} from "react-icons/ri";
import {
    checkHoldStatus,
    checkKatapultStatus,
    checkMreStatus,
    checkNotesJobStatus,
} from "../../../worksheet/process/job/constants";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import JobChangeStatus from "../../../worksheet/process/job/components/JobChangeStatus";
import {InputCompleteDateModel, InputStatusModel} from "../../../worksheet/process/job/models";
import {checkRoleActionAccess} from "../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../routes/models";
import {UserCredential} from "../../../login/models";
import {Tooltip} from 'flowbite-react';
import MREChangeStatus from "../../../worksheet/process/mre/components/MREChangeStatus";
import {cancelMRE, reset as resetMre, waitingMRE} from "../../../worksheet/process/mre/processMRE.reducer";
import {
    cancelKatapult,
    reset as resetKatapult,
    waitingKatapult
} from "../../../worksheet/process/katapult/processKatapult.reducer";
import KatapultChangeStatus from "../../../worksheet/process/katapult/components/KatapultChangeStatus";
import {IoInformationCircleOutline} from "react-icons/io5";
import {HistoryModel} from "../models";
import {dateSetTimeToNow, dateWithTime} from "../../../../helpers/convert";
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../approval/constants";
import {historySortOption} from "../constants";
import JobChangeCompleteDate from "../../../worksheet/process/job/components/JobChangeCompleteDate";
import {BsCalendar2Date} from "react-icons/bs";
import moment from "moment";

const HistoryTable = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );

    const actionStatus = useAppSelector(
        (state: RootState) => state.job.actionStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );
    const actionStatusMre = useAppSelector((state: RootState) => state.processMRE.actionStatus);
    const errorMre = useAppSelector(
        (state: RootState) => state.processMRE.error ?? ({} as HttpClientError)
    );
    const actionStatusKatapult = useAppSelector((state: RootState) => state.processKatapult.actionStatus);
    const changeDateStatus = useAppSelector((state: RootState) => state.job.changeDateStatus);
    const errorJob = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );

    const totalRows = useAppSelector(
        (state: RootState) => state.history.historyRows ?? 0
    );

    const [inputSearch, setInputSearch] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const [jobId, setJobId] = useState<string>('');

    const [mreAction, setMreAction] = useState<string>('');
    const [showChangeMreStatusModal, setShowChangeMreStatusModal] = useState<boolean>(false);

    const [katapultAction, setKatapultAction] = useState<string>('');
    const [showChangeKatapultStatusModal, setShowChangeKatapultStatusModal] = useState<boolean>(false);

    const [showChangeCompleteDateStatusModal, setShowChangeCompleteDateStatusModal] = useState<boolean>(false);
    const [selectedCompleteDate, setSelectedCompleteDate] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (
            actionStatus !== undefined &&
            actionStatus !== RequestStatus.pending
        ) {
            MySwal.fire(
                customStatusOption(
                    actionStatus === RequestStatus.success,
                    error.data?.message,
                    'status has been changed!',
                    'failed to change status'
                )
            ).then(() => {
                dispatch(reset());
                refreshCache(undefined);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatus, error]);

    useEffect(() => {
        if (actionStatusMre !== undefined && actionStatusMre !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(actionStatusMre === RequestStatus.success, errorMre.data?.message, 'status has been changed!', 'failed to change status')).then(() => {
                dispatch(resetMre());
                refreshCache(undefined);
                setShowChangeMreStatusModal(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatusMre, errorMre]);

    useEffect(() => {
        if (actionStatusKatapult !== undefined && actionStatusKatapult !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(actionStatusKatapult === RequestStatus.success, errorJob.data?.message, 'status has been changed!', 'failed to change status')).then(() => {
                dispatch(resetKatapult());
                refreshCache(undefined);
                setShowChangeKatapultStatusModal(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatusKatapult, errorJob]);

    useEffect(() => {
        if (changeDateStatus !== undefined && changeDateStatus !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(changeDateStatus === RequestStatus.success, errorJob.data?.message, 'complete date has been changed!', 'failed to change complete date ' + errorJob.message)).then(() => {
                dispatch(resetChangeCompleteDate());
                refreshCache(undefined);
                setShowChangeCompleteDateStatusModal(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeDateStatus, errorJob]);

    const onHoldClicked = (job_id: string) => {
        MySwal.fire(
            customWarnOption(
                'Hold Job',
                'Are you sure to hold this job? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(changeStatusJob({
                    job_id: job_id,
                    status: JOB_STATUS_HOLD,
                    userId: user.user_id
                }))
            }
        });
    };

    const onActionClicked = (job_id: string) => {
        MySwal.fire(
            customWarnOption(
                'Active Job',
                'Are you sure to active this job? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(changeStatusJob({
                    job_id: job_id,
                    status: JOB_STATUS_PREP,
                    userId: user.user_id
                }))
            }
        });
    };

    const onActionMREClicked = (job_id: string) => {
        MySwal.fire(
            customWarnOption(
                'Active Job',
                'Are you sure to active this MRE job? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(changeStatusJob({
                    job_id: job_id,
                    status: JOB_STATUS_QC_MRE,
                    userId: user.user_id
                }))
            }
        });
    };

    const onActionKatapultClicked = (job_id: string) => {
        MySwal.fire(
            customWarnOption(
                'Active Job',
                'Are you sure to active this Katapult job? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(changeStatusJob({
                    job_id: job_id,
                    status: JOB_STATUS_KATAPULT,
                    userId: user.user_id
                }))
            }
        });
    };

    const onMreStatusClicked = (id: string, action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                switch (action) {
                    case 'waiting':
                        dispatch(waitingMRE({
                            job_id: id,
                            waiting_notes: data?.notes ?? '',
                            waiting_subject_id: null,
                            waiting_by_user: user.user_id
                        }));
                        break;
                    case 'cancel':
                        dispatch(cancelMRE({
                            job_id: id,
                            cancel_notes: data?.notes ?? '',
                            waiting_by_user: user.user_id
                        }));
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const onKatapultStatusClicked = (id: string, action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                switch (action) {
                    case 'waiting':
                        dispatch(waitingKatapult({
                            job_id: id,
                            waiting_notes: data?.notes ?? '',
                            waiting_subject_id: null,
                            waiting_by_user: user.user_id
                        }));
                        break;
                    case 'cancel':
                        dispatch(cancelKatapult({
                            job_id: id,
                            cancel_notes: data?.notes ?? '',
                            waiting_by_user: user.user_id
                        }));
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const onChangeCompleteDateClicked = (jobId: string, data?: InputCompleteDateModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Complete Date',
                `Are you sure you want to change complete date to ${moment(data?.completeDate).format('MM-DD-YYYY')}`
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(changeCompleteDate({
                    id: jobId,
                    completeDate: dateSetTimeToNow(data?.completeDate),
                }))
            }
        });
    };

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setJobId('');
        setAction('');
    };

    const openChangeMreStatusModal = (action: string, id: string) => {
        setMreAction(action);
        setShowChangeMreStatusModal(true);
        setJobId(id);
    };

    const openChangeCompleteDateStatusModal = (id: string, completeDate: string) => {
        setShowChangeCompleteDateStatusModal(true);
        setJobId(id);
        setSelectedCompleteDate(completeDate);
    };

    const toggleChangeMreStatusModal = () => {
        setShowChangeMreStatusModal((prevState) => !prevState);
        setMreAction('');
        setJobId('');
    };

    const openChangeKatapultStatusModal = (action: string, id: string) => {
        setKatapultAction(action);
        setShowChangeKatapultStatusModal(true);
        setJobId(id);
    };

    const toggleChangeKatapultStatusModal = () => {
        setShowChangeKatapultStatusModal((prevState) => !prevState);
        setKatapultAction('');
        setJobId('');
    };

    const toggleChangeCompleteDateStatusModal = () => {
        setShowChangeCompleteDateStatusModal((prevState) => !prevState);
        setJobId('');
        setSelectedCompleteDate(undefined);
    };

    const openModal = (action: string, id: string) => {
        setAction(action);
        setShowModal(true);
        setJobId(id);
    };

    const onStatusClicked = (
        id: string,
        action: string,
        data?: InputStatusModel
    ) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                switch (action) {
                    case 'waiting':
                        dispatch(
                            waitingJob({
                                job_id: id,
                                waiting_notes: data?.notes ?? '',
                                waiting_subject_id:
                                    data?.pause_subject_id ?? '',
                                waiting_by_user: user.user_id,
                            })
                        );
                        break;
                    case 'cancel':
                        dispatch(
                            cancelJob({
                                job_id: id,
                                cancel_notes: data?.notes ?? '',
                                waiting_by_user: user.user_id,
                            })
                        );
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex h-[100%] items-center">
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.Edit) &&
                    <button>
                        <Link
                            to={{
                                pathname: `/worksheet/job/detail/${props.data?.id}`,
                                search: '?page=history',
                            }}
                        >
                            <RiEditCircleFill className="mx-1 text-2xl text-primary-color"/>
                        </Link>
                    </button>
                }
                {
                    ((checkNotesJobStatus(props.data?.status)) && checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionInfo)) &&
                    <>
                        <button onClick={() => showNotes(props.data)}>
                            <IoInformationCircleOutline className="mx-1 text-2xl text-primary-color"/>
                        </button>
                    </>
                }
                {
                    (checkHoldStatus(props.data?.status)) &&
                    <>
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionWaiting) &&

                            <Tooltip content="Waiting Job" trigger="hover" placement="right" style="light">
                                <div className="flex">
                                    <button onClick={() => openModal('waiting', props.data?.id)}>
                                        <MdPauseCircleOutline className="mx-1 text-2xl text-primary-color"/>
                                    </button>
                                </div>
                            </Tooltip>

                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionHold) &&
                            <Tooltip content="Hold Job" trigger="hover" placement="right" style="light">
                                <div className="flex">
                                    <button onClick={() => onHoldClicked(props.data?.id)}>
                                        <MdMotionPhotosPaused className="mx-1 text-2xl text-primary-color"/>
                                    </button>
                                </div>
                            </Tooltip>
                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionCancel) &&
                            <button onClick={() => openModal('cancel', props.data?.id)}>
                                <MdOutlineCancel className="mx-1 text-2xl text-primary-color"/>
                            </button>
                        }
                    </>
                }
                {
                    (checkMreStatus(props.data?.status)) &&
                    <>
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionWaiting) &&

                            <Tooltip content="Waiting Job" trigger="hover" placement="right" style="light">
                                <div className="flex">
                                    <button onClick={() => openChangeMreStatusModal('waiting', props.data?.id)}>
                                        <MdPauseCircleOutline className="mx-1 text-2xl text-primary-color"/>
                                    </button>
                                </div>
                            </Tooltip>

                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionCancel) &&
                            <button onClick={() => openChangeMreStatusModal('cancel', props.data?.id)}>
                                <MdOutlineCancel className="mx-1 text-2xl text-primary-color"/>
                            </button>
                        }
                    </>
                }
                {
                    (checkKatapultStatus(props.data?.status)) &&
                    <>
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionWaiting) &&

                            <Tooltip content="Waiting Job" trigger="hover" placement="right" style="light">
                                <div className="flex">
                                    <button onClick={() => openChangeKatapultStatusModal('waiting', props.data?.id)}>
                                        <MdPauseCircleOutline className="mx-1 text-2xl text-primary-color"/>
                                    </button>
                                </div>
                            </Tooltip>

                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionCancel) &&
                            <button onClick={() => openChangeKatapultStatusModal('cancel', props.data?.id)}>
                                <MdOutlineCancel className="mx-1 text-2xl text-primary-color"/>
                            </button>
                        }
                    </>
                }
                {
                    (props.data?.status === JOB_STATUS_COMPLETE && checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionActivate)) &&
                    <button onClick={() => onActionClicked(props.data?.id)}>
                        <TbRefreshAlert className="mx-1 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    (props.data?.status === JOB_STATUS_COMPLETE_MRE && checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionActivate)) &&
                    <button onClick={() => onActionMREClicked(props.data?.id)}>
                        <TbRefreshAlert className="mx-1 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    (props.data?.status === JOB_STATUS_COMPLETE_KATAPULT && checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ActionActivate)) &&
                    <button onClick={() => onActionKatapultClicked(props.data?.id)}>
                        <TbRefreshAlert className="mx-1 text-2xl text-primary-color"/>
                    </button>
                }

                {
                    ((props.data?.status === JOB_STATUS_COMPLETE || props.data?.status === JOB_STATUS_COMPLETE_KATAPULT || props.data?.status === JOB_STATUS_COMPLETE_MRE) && checkRoleActionAccess(user?.roleName, MenuEnum.History, ActionEnum.ChangeCompleteDate)) &&
                    <button
                        onClick={() => openChangeCompleteDateStatusModal(props.data?.id, props.data?.complete_date)}>
                        <BsCalendar2Date className="mx-1 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    const showNotes = (data: HistoryModel) => {
        const lastCheckedDate = data.status === JOB_STATUS_WAITING ? data.last_check_date : data.status === JOB_STATUS_WAITING_MRE || data.status === JOB_STATUS_WAITING_KATAPULT ? data.waiting_ts : data.status === JOB_STATUS_HOLD ? data.hold_ts : data.cancel_ts;

        const lastCheckedBy = data.status === JOB_STATUS_WAITING ? data.last_check_user_name : data.status === JOB_STATUS_WAITING_MRE || data.status === JOB_STATUS_WAITING_KATAPULT ? data.waiting_by_user_name : data.status === JOB_STATUS_HOLD ? data.hold_by_user_name : data.cancel_by_user_name;

        const lastCheckedNotes = data.status === JOB_STATUS_WAITING ? data.waiting_notes : data.status === JOB_STATUS_WAITING_MRE || data.status === JOB_STATUS_WAITING_KATAPULT ? data.waiting_notes : data.status === JOB_STATUS_HOLD ? "-" : data.cancel_notes;

        MySwal.fire({
            html: <div className="flex">
                <div className="text-left">
                    <div className="font-bold text-sm">Last Checked By : <span
                        className="font-normal text-sm">{lastCheckedBy}</span></div>
                    <div className="font-bold text-sm">Last Checked Date : <span
                        className="font-normal text-sm">{dateWithTime(lastCheckedDate ?? "0001-01-01T00:00:00")}</span>
                    </div>
                    <div className="font-bold text-sm">Notes : <span
                        className="font-normal text-sm">{lastCheckedNotes}</span></div>
                </div>
            </div>
        });
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={props.data?.koj === "MRE" ? `/worksheet/mre/mre-detail/${props.data?.id}`
                : props.data?.koj === "Katapult" ? `/worksheet/katapult/katapult-detail/${props.data?.id}`
                    : `/worksheet/ocalc/job-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([
        {field: 'project', headerName: 'Project',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'team', headerName: 'Team',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'koj', headerName: 'KoJ',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'request_date',
            headerName: 'Request Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {field: 'number_id', headerName: 'ID Number',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Job Number',
            field: 'number_job',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'status', headerName: 'Status',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'type', headerName: 'Type',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'district', headerName: 'District',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'po_number', headerName: 'PO Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'department_name', headerName: 'Department',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'department_owner_name', headerName: 'Department Owner', filter: false, sortable: false},
        {field: 'sales_order', headerName: 'Sales Order',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'source_name', headerName: 'Sources',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }},
        {
            field: 'other_number', headerName: 'Other Number',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            },
            sortable: false
        },
        {
            field: 'due_date',
            headerName: 'Due Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {field: 'request_pole', headerName: 'Req Pole',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'employee_prep_name', headerName: 'Prep User', filter: false, sortable: false},
        {field: 'employee_cs_name', headerName: 'CS User', filter: false, sortable: false},
        {field: 'employee_qc_name', headerName: 'QC User', filter: false, sortable: false},
        {field: 'employee_mre_name', headerName: 'MRE User', filter: false, sortable: false},
        {field: 'employee_qc_mre_name', headerName: 'QC MRE User', filter: false, sortable: false},
        {field: 'mre_e', headerName: 'MRE E', filter: false, sortable: false},
        {field: 'mre_p', headerName: 'MRE P', filter: false, sortable: false},
        {field: 'employee_katapult_name', headerName: 'Katapult User', filter: false, sortable: false},
        {
            field: 'complete_date',
            headerName: 'Complete Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
            }),
        },
    ]);

    const onInputSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputSearch(e.target.value);
    }

    const onResetClicked = () => {
        setInputSearch("");
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            number_job: null,
        })
    }

    const onSearchClicked = () => {
        if (inputSearch !== "") {
            refreshCache(undefined);
            gridRef.current.api.setFilterModel({
                number_job: {
                    filterType: "text",
                    type: "contains",
                    filter: inputSearch
                },
            })
        }
    }

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(historySortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom, dateTo} = params.request.filterModel?.request_date || {};
                const {dateFrom: startDueDate, dateTo: endDueDate} = params.request.filterModel?.due_date || {};
                const {dateFrom: startCompleteDate, dateTo: endCompleteDate} = params.request.filterModel?.complete_date || {};

                dispatch(
                    getHistory({
                        skip: params.request.startRow,
                        take: params.request.endRow - params.request.startRow,
                        project_name: params.request.filterModel?.project?.filter,
                        team_name: params.request.filterModel?.team?.filter,
                        kind_job: params.request.filterModel?.koj?.filter,
                        startRequestDate: dateFrom !== undefined ? dateSetTimeToNow(dateFrom) : undefined,
                        endRequestDate: dateTo !== undefined ? dateSetTimeToNow(dateTo) : undefined,
                        number_id: params.request.filterModel?.number_id?.filter,
                        number_job: params.request.filterModel?.number_job?.filter,
                        status: params.request.filterModel?.status?.filter,
                        type: params.request.filterModel?.type?.filter,
                        district: params.request.filterModel?.district?.filter,
                        department: params.request.filterModel?.department_name?.filter,
                        sales_order: params.request.filterModel?.sales_order?.filter,
                        source: params.request.filterModel?.source_name?.filter,
                        other_number: params.request.filterModel?.other_number?.filter,
                        po_number: params.request.filterModel?.po_number?.filter,
                        startDueDate: startDueDate !== undefined ? dateSetTimeToNow(startDueDate) : undefined,
                        endDueDate: endDueDate !== undefined ? dateSetTimeToNow(endDueDate) : undefined,
                        startCompleteDate: startCompleteDate !== undefined ? dateSetTimeToNow(startCompleteDate) : undefined,
                        endCompleteDate: endCompleteDate !== undefined ? dateSetTimeToNow(endCompleteDate) : undefined,
                        req_pole: params.request.filterModel?.request_pole?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    console.log(res.payload?.data);
                    params.success({
                        rowData: res.payload?.data.list,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <div className="flex">
                        <div className="w-[300px]">
                            <InputText
                                placeholder="Search By Job Number"
                                onChange={onInputSearchChanged}
                                value={inputSearch}
                            />
                        </div>

                        <div className="flex content-center items-center mx-2">
                            <div className={"mr-1"}>
                                <Button
                                    color="success"
                                    type="button"
                                    title="Reset"
                                    onClick={onResetClicked}
                                />
                            </div>
                            <div className={"mx-1"}>
                                <Button
                                    color="success"
                                    type="button"
                                    title="Search"
                                    onClick={onSearchClicked}
                                />
                            </div>
                        </div>
                    </div>

                    {/*<AgDataGrid rowData={historyJob} columnDefs={columnDefs} tableHeight={'100vh'} defaultPageSize={50} supportSaveTableState tableStateName={'report-history'}/>*/}

                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                            getServerSideDataSource={getServerSideDataSource} supportSaveTableState
                                            tableStateName={'report-history'} defaultPageSize={20} supportAllPageOptions totalData={totalRows}/>
                </div>
            </div>
            <DefaultModal
                title="Change Status"
                show={showModal}
                onClose={toggleModal}
                body={
                    <JobChangeStatus
                        action={action}
                        jobId={jobId}
                        onSubmit={onStatusClicked}
                    />
                }
            />
            <DefaultModal
                title="Change MRE Status"
                show={showChangeMreStatusModal}
                onClose={toggleChangeMreStatusModal}
                body={<MREChangeStatus action={mreAction} jobId={jobId} onSubmit={onMreStatusClicked}/>}
            />
            <DefaultModal
                title="Change Katapult Status"
                show={showChangeKatapultStatusModal}
                onClose={toggleChangeKatapultStatusModal}
                body={<KatapultChangeStatus action={katapultAction} jobId={jobId} onSubmit={onKatapultStatusClicked}/>}
            />
            <DefaultModal
                title="Change Complete Date"
                show={showChangeCompleteDateStatusModal}
                onClose={toggleChangeCompleteDateStatusModal}
                body={<JobChangeCompleteDate completeDate={selectedCompleteDate} jobId={jobId} onSubmit={onChangeCompleteDateClicked}/>}
            />
        </div>
    );
};

export default HistoryTable;
