import React, {useEffect} from 'react';
import {useAppDispatch} from "../../../helpers/redux";
import {setDynamicNavId} from "../../sidebarPanel/sidebar.reducer";
import DynamicNavLink from "../../../components/Tabs/DynamicNavLink";

const AttendanceReportDynamicNav = () => {
    const dispatch = useAppDispatch();

    const links = [
        {
            id: "overtime-report",
            label: "Overtime",
        },
        {
            id: "holiday-report",
            label: "Holiday"
        },
        {
            id: "absent-log",
            label: "Absent Log"
        }
    ];

    useEffect(() => {
        dispatch(setDynamicNavId('overtime-report'));
    }, []);

    return (
        <DynamicNavLink links={links}/>
    );
};
export default AttendanceReportDynamicNav;