import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors} from 'chart.js';
import {Line} from "react-chartjs-2";
import { faker } from '@faker-js/faker';
import {useAppSelector} from "../../../../../helpers/redux";
import {LineChartModel} from "../../models";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors
);
const JobStatisticChart = () => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Job In Chart',
            },
        },
    };

    const jobInChart = useAppSelector(
        (state) => state.jobReport.jobInChartList ?? {
            labels: [],
            datasets: [],
        } as LineChartModel
    );

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <Line options={options} data={jobInChart} />
                </div>
            </div>
        </div>
    );
};

export default JobStatisticChart;
