import React, {useEffect} from 'react';
import {TableColumn} from "react-data-table-component";
import DataTableServerPaging from "../../../../../components/Table/DataTableServerPaging";
import {JobLinkAttachment} from "../../../../basic/attachment/models";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {getJobLinkAttachment} from "../../../../basic/attachment/attachment.reducer";

const JobLinkHistory = ({jobId = undefined}: JobLinkHistoryProps) => {
    const dispatch = useAppDispatch();

    const linkList = useAppSelector(
        (state: RootState) => state.attachment.linkList ?? []
    );
    const rows = useAppSelector(
        (state: RootState) => state.attachment.linkRows ?? 0
    );

    useEffect(() => {
        if (jobId !== undefined) {
            dispatch(getJobLinkAttachment({
                job_id: jobId,
                skip: 0,
                take: 10,
            }));
        }
    }, [jobId]);

    const columns: TableColumn<JobLinkAttachment>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Job Number',
            selector: (row) => row.job_number_job,
            sortable: true,
        },
        {
            name: 'ID Number',
            selector: (row) => row.job_number_id,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => row.ts_created,
            sortable: true,
            format: (row) => row.ts_created !== null ? new Date(row.ts_created).toLocaleDateString('en-US') : "-",
        },
        {
            name: 'By',
            selector: (row) => row.employee_name,
            sortable: true,
        },
        {
            name: 'Link',
            cell: (row) => (
                <a
                    href={`${row.link}`}
                    className="underline text-blue-700"
                    target={"_blank"}
                >
                    {row.link}
                </a>
            ),
        },
    ]

    const handlePagingChange = (rowsPerPage: number, page: number) => {
        dispatch(
            getJobLinkAttachment({
                job_id: jobId,
                skip: rowsPerPage * (page - 1),
                take: rowsPerPage,
            })
        );
    }

    return (
        <div className="p-5">
            <DataTableServerPaging columns={columns} data={linkList} rows={rows}
                                   handlePagingChange={handlePagingChange}
                                   // conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

export interface JobLinkHistoryProps {
    jobId?: string;
}

export default JobLinkHistory;
