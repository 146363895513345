import React, {useEffect, useState} from 'react';
import MyCalendar from '../../../../components/Calendar/MyCalendar';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ProfileList from "../components/ProfileList";
import {getHolidayCalendarEventList, getHolidayCalendarList} from "../../../master/holiday/holiday.reducer";
import {useAppDispatch, useAppSelector} from "../../../../helpers/redux";
import {RootState} from "../../../../app/store";
import {HolidayCalendarTypeEnum} from "../../../master/holiday/models";
import AgDataGrid from "../../../../components/DataGrid/AgDataGrid";
import {filterParams} from "../../../../helpers/agTable";

const localizer = momentLocalizer(moment);

const MyProfilePage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getHolidayCalendarList({ skip: 0, take: 200 }));
        dispatch(getHolidayCalendarEventList({ skip: 0, take: 200 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const rows = useAppSelector(
    //     (state: RootState) => state.holiday.holidayCalendarRows ?? 0
    // );
    const holidayList = useAppSelector(
        (state: RootState) => state.holiday.holidayCalendarList ?? []
    );
    const holidayEventList = useAppSelector(
        (state: RootState) => state.holiday.holidayCalendarEventList ?? []
    );

    // const columns: TableColumn<CalendarModel>[] = [
    //     {
    //         name: 'Date',
    //         selector: (row) =>
    //             new Date(row.date).toLocaleDateString('en-US'),
    //         sortable: true,
    //     },
    //     {
    //         name: 'Name',
    //         selector: (row) => row.name,
    //         sortable: true,
    //     },
    //     {
    //         name: 'Type',
    //         selector: (row) => row.type,
    //         format: (row) => {
    //             if (row.type === HolidayCalendarTypeEnum.US) return "US Holiday (🇺🇸)";
    //             else if (row.type === HolidayCalendarTypeEnum.Indonesia) return "Indonesia Holiday (🇮🇩)";
    //             else return "Other";
    //         },
    //         sortable: true,
    //     },
    // ];

    const TypeCellRender = (props: any) => (
        <div>
            {props.data?.type === HolidayCalendarTypeEnum.US && "US Holiday"}
            {props.data?.type === HolidayCalendarTypeEnum.Indonesia && "Indonesia Holiday"}
        </div>
    );

    // const handlePagingChange = (rowsPerPage: number, page: number) => {
    //     dispatch(
    //         getHolidayCalendarList({
    //             skip: rowsPerPage * (page - 1),
    //             take: rowsPerPage,
    //         })
    //     );
    // };

    const customSlotPropGetter = (date: Date) => {
        if (date.getDate() === 7 || date.getDate() === 15)
            return {
                className: "holiday-date",
            }
        else return {}
    }

    const customDayPropGetter = (date: Date) => {
        let isHolidayDate = holidayList.some((item) => date.toLocaleDateString() === new Date(item.date).toLocaleDateString());
        if (isHolidayDate)
            return {
                className: "holiday-date",
            }
        else return {}
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            field: 'date',
            headerName: 'Date',
            cellDataType: 'date', filter: 'agDateColumnFilter', filterParams: filterParams
        },
        {field: 'name', headerName: 'Name'},
        {field: 'type', headerName: 'Type',
            cellRenderer: TypeCellRender,
        },
    ]);

    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                        My Profile
                    </h1>
                </div>
                <div className="mt-3">
                     <MyCalendar events={holidayEventList} localizer={localizer} slotPropGetter={customSlotPropGetter} dayPropGetter={customDayPropGetter} />
                </div>
                <div className="mt-3">
                    {/*<DataTableServerPaging*/}
                    {/*    columns={columns}*/}
                    {/*    data={holidayList}*/}
                    {/*    rows={rows}*/}
                    {/*    handlePagingChange={handlePagingChange}*/}
                    {/*/>*/}
                    <AgDataGrid
                        rowData={holidayList}
                        columnDefs={columnDefs}
                        tableHeight={400}
                        defaultColDef={
                            {
                                sortable: true,
                                resizable: true,
                                minWidth: 50,
                                wrapHeaderText: true,
                                wrapText: true,
                                autoHeight: true,
                                flex: 1,
                            }
                        }
                        defaultPageSize={20}
                    />
                </div>
                <div className="mt-3">
                    <ProfileList />
                </div>
            </div>
        </div>
    );
};

export default MyProfilePage;