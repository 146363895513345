import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {
    DatasheetType,
    GetDatasheetListRequest, GetDatasheetNewRequest,
    GetMainMapReq, GetMapNewRequest, GetMapReq,
    GetMapReqListRequest,
    MainMapReq,
    PowerMapType
} from "./models";
import {GetPrepRequest, JobPrep} from "../../process/jobPrep/models";

export default class MapReqApi extends BaseServiceClass {
    static getMapReqList(args: GetMapReqListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<PowerMapType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_power_map_na}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take,
                startDate: args.startDate,
                endDate: args.endDate,
                filter: args.filter
            }
        }).then((res) => res.data);
    }
    static getDatasheetList(args: GetDatasheetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<DatasheetType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_datasheet_na}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take,
                startDate: args.startDate,
                endDate: args.endDate,
                filter: args.filter
            }
        }).then((res) => res.data);
    }
    static getMapReqWithFilter(args: GetMapNewRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<PowerMapType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_power_map_na_with_filter}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static getDatasheetWithFilter(args: GetDatasheetNewRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<DatasheetType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_datasheet_na_with_filter}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static getMainList(args: GetMainMapReq, token: string) {
        return httpClient.get<ApiResponseWrapper<MainMapReq[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_main_map_req}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                employee_id: args.employee_id,
                skip: args.skip,
                take: args.take,
                startDate: args.startDate,
                endDate: args.endDate,
                filter: args.filter
            }
        }).then((res) => res.data);
    }
    static getMapReq(args: GetMapReq, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_attachment_map_req_get, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
}