import React, {useRef, useState} from 'react';
import FormLoader from "../../../../components/Loader/FormLoader";
import AgDataGridStatic from "../../../../components/DataGrid/AgDataGridStatic";
import {useAppSelector} from "../../../../helpers/redux";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import {RootState} from "../../../../app/store";

const ReportBillingOverviewJobTable = () => {
    const gridRef = useRef<any>(null);

    const billJobDetail = useAppSelector((state: RootState) => state.billJob.billJobDetail ?? []);

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'source_name', headerName: 'Source Name', filter: 'agTextColumnFilter'},
        {field: 'number_id', headerName: 'ID Number', filter: 'agTextColumnFilter'},
        {field: 'number_job', headerName: 'Job number', filter: 'agTextColumnFilter'},
        {field: 'district_name', headerName: 'District', filter: 'agTextColumnFilter'},
        {field: 'po_number', headerName: 'PO Number', filter: 'agTextColumnFilter'},
        {field: 'department_name', headerName: 'GEOCEO', filter: 'agTextColumnFilter'},
        {field: 'job_type', headerName: 'Type', filter: 'agTextColumnFilter'},
        {field: 'sales_order', headerName: 'Sales Order', filter: 'agTextColumnFilter'},
        {field: 'other_number', headerName: 'Other Number', filter: 'agTextColumnFilter'},
        {field: 'complete_date', headerName: 'Completed Date', cellDataType: 'date'},
        {field: 'poles', headerName: 'Poles', filter: 'agTextColumnFilter'},
        {field: 'created_pole', headerName: 'Already Created Poles', filter: 'agTextColumnFilter'},
        {field: 'not_created_pole', headerName: 'Not Created Poles', filter: 'agTextColumnFilter'},
        {field: 'total_poles', headerName: 'Total Poles', filter: 'agTextColumnFilter'},
        {field: 'notes', headerName: 'Notes', filter: 'agTextColumnFilter'},
    ]);

    return (
        <div className="main-data-grid mt-5">
            <div>
                <FormLoader isLoading={false} label={"Fetching Data"}>
                    <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={billJobDetail}
                                      supportSaveTableState
                                      tableStateName={'bill-job-overview'} tableDimension={{
                        width: "100%",
                        height: "30vh",

                    }} defaultColDef={{
                        sortable: true,
                        resizable: true,
                        minWidth: 10,
                        wrapHeaderText: true,
                        wrapText: true,
                        autoHeight: true,
                        flex: 1,
                    }}/>
                </FormLoader>
            </div>
        </div>
    );
};

export default ReportBillingOverviewJobTable;
