import {HttpClientError, RequestStatus} from "../../../http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import GDevApi from "./gdev.api";
import {BilledGDevRequest, GDevApproveRequest, GetGDevRequest} from "../models";
import FileSaver from "file-saver";
import {exportJobInToCsv} from "../../report/jobReport/jobReport.reducer";

export interface GDevSlice {
    data: any;
    total: number;
    isLoading: boolean;
    isExportLoading: boolean;
    status?: RequestStatus;
    billedStatus?: RequestStatus;
    approvedStatus?: RequestStatus;
    exportStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getGDevData = createAsyncThunk(
    'GDev/getGDevData',
    async (args: GetGDevRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await GDevApi.get(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const billedGDev = createAsyncThunk(
    'GDev/billedGDev',
    async (args: BilledGDevRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await GDevApi.billedGDev(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const approveGDev = createAsyncThunk(
    'GDev/approveGDev',
    async (args: GDevApproveRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await GDevApi.approveGDev(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const exportGDev = createAsyncThunk(
    'GDev/exportGDev',
    async (args: GetGDevRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await GDevApi.exportGDev(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const gDevSlice = createSlice({
    name: 'gDevState',
    initialState: {} as GDevSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.billedStatus = RequestStatus.pending;
            state.approvedStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getGDevData.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getGDevData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = payload.data.list;
            state.total = payload.data.total;
        });
        builder.addCase(getGDevData.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(billedGDev.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
            state.billedStatus = RequestStatus.pending;
        });
        builder.addCase(billedGDev.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.billedStatus = RequestStatus.success;
        });
        builder.addCase(billedGDev.rejected, (state) => {
            state.isLoading = false;
            state.billedStatus = RequestStatus.failed
        });
        builder.addCase(approveGDev.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
            state.approvedStatus = RequestStatus.pending;
        });
        builder.addCase(approveGDev.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.approvedStatus = RequestStatus.success;
        });
        builder.addCase(approveGDev.rejected, (state) => {
            state.isLoading = false;
            state.approvedStatus = RequestStatus.failed
        });
        // export to csv download reducer
        builder.addCase(exportGDev.pending, (state) => {
            state.isExportLoading = true;
            state.exportStatus = RequestStatus.pending;
        })
        builder.addCase(exportGDev.fulfilled, (state, { payload }) => {
            FileSaver.saveAs(
                payload,
                'gdev' + new Date().toISOString() + '.xlsx'
            );
            state.isExportLoading = false;
            state.exportStatus = RequestStatus.success;
        })
        builder.addCase(exportGDev.rejected, (state, { payload }) => {
            state.error = payload as HttpClientError;
            state.isExportLoading = false;
            state.exportStatus = RequestStatus.failed;
        });
    }
});

export const { reset } = gDevSlice.actions;
export default gDevSlice.reducer;