import React, {useEffect, useState} from 'react';
import InputText from "../../../../../components/TextInput/InputText";
import {Button} from "flowbite-react";
import {FaMapMarkerAlt} from "react-icons/fa";
import InputCheckbox from "../../../../../components/Checkbox/InputCheckbox";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import {SingleValue} from "react-select";
import {Option} from "../../../../../components/SelectInput/BasicSelectInput";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {getList} from "../../../../master/poleType/poleType.reducer";
import {getList as getPoleStatusList} from "../../../../master/poleStatus/poleStatus.reducer";
import TextArea from "../../../../../components/TextArea/TextArea";
import {useForm} from "react-hook-form";
import {KatapultPoleInput} from "../models";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import Places from "../../jobPrep/components/Places";
import {LocationCoordinate, Pole} from "../../../../basic/pole/models";
import {createPole, updatePole} from "../../../../basic/pole/pole.reducer";
import {isArrayEmpty, isObjectEmpty} from "../../../../../helpers/checkEmptiness";
import {useParams} from "react-router-dom";

const AddKatapultPoleForm = ({
                            isEdit = false,
                            singlePole = {} as Pole,
                        }: AddKatapultPoleFormProps) => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch
    } = useForm<KatapultPoleInput>({
        defaultValues: {
            index: 0,
            is_pip: false,
            is_fielding: true,
        }
    });

    const watchIsPip = watch('is_pip');
    const watchIsFielding = watch('is_fielding');

    const [showMapModal, setShowMapModal] = useState<boolean>(false);
    const [defaultCoordinate, setDefaultCoordinate] =
        useState<LocationCoordinate>({lat: 43.45, lng: -80.49});

    const poleTypeOptions = useAppSelector(
        (state: RootState) => state.poleType.options ?? []
    );

    const poleKatapultByJob = useAppSelector((state: RootState) => state.pole.poleKatapultByJob ?? []);

    useEffect(() => {
        dispatch(getList({skip: 0, take: 500}));
        dispatch(getPoleStatusList({skip: 0, take: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEdit && !isObjectEmpty(singlePole)) {
            reset({
                seq: singlePole?.seq,
                no: singlePole?.no,
                address: singlePole?.address,
                is_pip: singlePole?.is_pip,
                latitude: singlePole?.latitude,
                longitude: singlePole?.longitude,
                pole_type: {
                    value: singlePole?.type_id,
                    label: singlePole?.type_name,
                },
                is_fielding: singlePole?.is_fielding,
                notes: singlePole?.notes,
                index: singlePole?.index,
            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, singlePole]);

    useEffect(() => {
        if (!isArrayEmpty(poleKatapultByJob)) {
            const filteredPole = poleKatapultByJob.filter(item => item.latitude !== 0 && item.longitude !== 0);
            const currentPole = poleKatapultByJob.find(item => item.id === singlePole.id) ?? {} as Pole;
            if (isEdit) {
                if (currentPole.latitude === 0 && currentPole.longitude === 0 && !isArrayEmpty(filteredPole)) {
                    setDefaultCoordinate({
                        lat: filteredPole[filteredPole.length - 1].latitude ?? 43.45,
                        lng: filteredPole[filteredPole.length - 1].longitude ?? -80.49,
                    })
                } else {
                    setDefaultCoordinate({
                        lat: singlePole.latitude ?? 43.45,
                        lng: singlePole.longitude ?? -80.49,
                    });
                }
            } else {
                if (!isArrayEmpty(filteredPole)) {
                    setDefaultCoordinate({
                        lat: filteredPole[filteredPole.length - 1].latitude ?? 43.45,
                        lng: filteredPole[filteredPole.length - 1].longitude ?? -80.49,
                    })
                }
            }
        }
    }, [poleKatapultByJob, singlePole, isEdit]);

    const onSubmit = (data: KatapultPoleInput) => {
        let _input = {
            job_id: id as string,
            seq: data.seq ?? '',
            no: data.no,
            address: data.address,
            is_pip: data.is_pip ?? 0,
            latitude: data.latitude,
            longitude: data.longitude,
            type_id: data.pole_type.value,
            is_fielding: data.is_fielding,
            is_mre: false,
            is_katapult: true,
            notes: data.notes,
            index: isEdit ? data.index ?? 0 : 0,
        };
        if (isEdit) {
            let senUpdatePole = {
                ..._input, id: singlePole.id,
                employee_prep_id: singlePole.employee_prep_id ?? "",
                employee_calcspec_id: singlePole.employee_calcspec_id ?? "",
                employee_qc_id: singlePole.employee_qc_id ?? "",
                employee_katapult_id: singlePole.employee_katapult_id ?? "",
            };
            dispatch(updatePole(senUpdatePole));
        } else {
            dispatch(createPole(_input));
        }
    };

    const toggleMapModal = () => {
        setShowMapModal((prevState) => !prevState);
    };

    const onCoordinateChange = (coordinate: LocationCoordinate | undefined, address: string) => {
        setValue('latitude', coordinate !== undefined ? coordinate.lat : 0);
        setValue('longitude', coordinate !== undefined ? coordinate.lng : 0);
        setValue('address', address);
        setShowMapModal(false);
    }

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputText
                    label="No"
                    placeholder="Input No"
                    type="number"
                    {...register('index')}
                    min={0}
                />
                <InputText
                    label="Pole Seq"
                    placeholder="Input Pole Seq"
                    {...register('seq')}
                />
                <InputText
                    label="Pole No"
                    placeholder="Input Pole No"
                    {...register('no')}
                />
                <div className={"flex"}>
                    <div className="grow">
                        <InputText
                            label="Pole Address"
                            placeholder="Input Pole Address"
                            {...register('address')}
                        />
                    </div>
                    <div className="flex items-end mb-2 ml-2">
                        <Button
                            size="xs"
                            color="success"
                            className="h-[42.5px]"
                            onClick={toggleMapModal}
                        >
                            <FaMapMarkerAlt className="mr-2 h-5 w-5"/>
                            Pin Address Map
                        </Button>
                    </div>
                </div>
                <div className={"flex"}>
                    <div className="w-1/2 mr-1.5">
                        <InputText
                            label="Latitude"
                            placeholder="Input Latitude"
                            {...register('latitude')}
                        />
                    </div>
                    <div className="w-1/2 ml-1.5">
                        <InputText
                            label="Longitude"
                            placeholder="Input Longitude"
                            {...register('longitude')}
                        />
                    </div>
                </div>
                <div className="mt-2">
                    <SelectInput
                        key={`my_unique_select_key__pole_type`}
                        options={poleTypeOptions}
                        // isLoading={isProjectLoading}
                        label="PoleType"
                        onChange={(val: SingleValue<Option>) =>
                            setValue('pole_type', val ?? {} as Option)
                        }
                        // message={errors.project_id?.message}
                        // defaultInputValue={initialValue}
                        value={watch('pole_type')}
                        menuPosition="fixed"
                        menuPlacement="auto"
                    />
                </div>
                <div className="mt-2">
                    <TextArea
                        label="Notes Fielding"
                        placeholder="Input address"
                        {...register('notes')}
                    />
                </div>
                <div className="flex">
                    <InputCheckbox
                        label="PIP"
                        id="isPip"
                        checked={watchIsPip}
                        onChange={(e) => {
                            setValue('is_pip', e.target.checked)
                        }}
                    />
                    <div className={"ml-3"}>
                        <InputCheckbox
                            label="Fielding"
                            id="isFielding"
                            checked={watchIsFielding}
                            onChange={(e) => {
                                setValue('is_fielding', e.target.checked)
                            }}
                        />
                    </div>
                </div>
                <div className="flex pt-2 pb-2 mt-3 justify-end">
                    <Button
                        color="success"
                        type="submit"
                    >Save Pole</Button>
                </div>
            </form>

            <DefaultModal
                title="Maps"
                show={showMapModal}
                onClose={toggleMapModal}
                size="7xl"
                body={
                    <div style={{height: '80vh', width: '100%'}}>
                        <Places
                            onCoordinateChange={onCoordinateChange}
                            defaultCoordinate={defaultCoordinate}
                            isMapEditable={true}
                        />
                    </div>
                }
            />
        </div>
    );
};

export interface AddKatapultPoleFormProps {
    isEdit: boolean;
    singlePole?: Pole;
}

export default AddKatapultPoleForm;
