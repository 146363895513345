import React, {useMemo, useRef, useState} from 'react';
import AgDataGrid from "../../../../../components/DataGrid/AgDataGrid";
import {DetailRole} from "../../models";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../../../../helpers/redux";

const DetailEmployeeWork = ({data = []}: DetailEmployeeWorkProps) => {
    const subGridRef = useRef<any>(null);

    const employeeWork = useAppSelector(
        (state) => state.staffReport.employeeWorkList ?? []
    );

    const [subColumnDefs, _setSubColumnDefs] = useState([
        // { field: 'name', headerName: 'Name', filter: true, cellRenderer: 'agGroupCellRenderer' },
        // { field: 'teamName', headerName: 'Team', filter: true},
        {field: 'positionName', headerName: 'Position', filter: true, cellRenderer: 'agGroupCellRenderer'},
        {
            field: 'totalJobComplete',
            headerName: 'Total Job Completed',
            aggFunc: 'sum'
        },
        {field: 'processJob', headerName: 'Processes Pole', aggFunc: 'sum'},
        {field: 'totalPole', headerName: 'Total Poles', aggFunc: 'sum'},
        {field: 'addPole', headerName: 'Add Poles', aggFunc: 'sum'},
        {field: 'revisionPole', headerName: 'Revision Poles', aggFunc: 'sum'},
        // {field: 'sumPole', headerName: 'Sum Poles', aggFunc: 'sum'},
        {field: 'mre', headerName: 'MRE', aggFunc: 'sum'},
        {field: 'katapult', headerName: 'Katapult', aggFunc: 'sum'},
    ]);

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={props.data?.koj === "MRE" ? `/worksheet/mre/mre-detail/${props.data?.id}`
                : props.data?.koj === "Katapult" ? `/worksheet/katapult/katapult-detail/${props.data?.id}`
                    : `/worksheet/ocalc/job-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                rowSelection: "multiple",
                enableRangeSelection: true,
                dataTypeDefinitions: {
                    date: {
                        baseDataType: 'date',
                        extendsDataType: 'date',
                        valueParser: (params: any) => {
                            if (params.data === undefined) {
                                return null;
                            }
                            if (params.newValue == null) {
                                return null;
                            }
                            // convert from `dd/mm/yyyy`
                            return params?.value === "0001-01-01T00:00:00" ? "-" : new Date(params?.value).toLocaleDateString('en-GB');
                        },
                        valueFormatter: (params: any) => {
                            if (params.data === undefined) {
                                return null;
                            }
                            // convert to `dd/mm/yyyy`
                            return params?.value === "0001-01-01T00:00:00" ? "-" : new Date(params?.value).toLocaleDateString('en-US');
                        },
                    }
                },
                groupIncludeFooter: true,
                groupIncludeTotalFooter: true,
                columnDefs: [
                    {field: "project", headerName: "Project (Job)"},
                    {field: "koj", headerName: "KoJ"},
                    {field: "number_id", headerName: "ID Number"},
                    {
                        headerName: 'Job Number',
                        field: 'number_job',
                        suppressCellFlash: true,
                        cellRenderer: JobNumberLinkCellRender,
                    },
                    {field: "job_type_name", headerName: "Type"},
                    {field: "district", headerName: "District"},
                    {
                        field: 'complete_date',
                        headerName: 'Complete Date',
                        cellDataType: 'date',
                    },
                    {field: "poles", headerName: "Poles", aggFunc: 'sum'},
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params: any) => {
                params.successCallback(params.data.details);
            }
        };
    }, []);

    const defaultCsvExportParams = useMemo(() => {
        return {
            getCustomContentBelowRow: (params: any) => {
                const rows = getRows(params);
                return rows.map((row) => row.cells);
            },
        };
    }, [employeeWork]);

    const defaultExcelExportParams = useMemo(() => {
        return {
            getCustomContentBelowRow: (params: any) => getRows(params),
            columnWidth: 120,
            fileName: `employee_work_${new Date().toLocaleDateString('en-US')}.xlsx`,
        };
    }, [employeeWork]);

    const getRows = (params: any) => {
        if (params.node?.footer) {
            return [];
        } else {
            if (!params.node.data.details || params.node.data.details?.length === 0) return [];
            return [
                {
                    outlineLevel: 1,
                    cells: [
                        cell(''),
                        cell('Project (Job)', 'header'),
                        cell('KoJ', 'header'),
                        cell('ID Number', 'header'),
                        cell('Job Number', 'header'),
                        cell('Type', 'header'),
                        cell('District', 'header'),
                        cell('Complete Date', 'header'),
                        cell('Poles', 'header'),
                    ],
                },
            ].concat(
                ...params.node.data.details?.map((record: any) => [
                    {
                        outlineLevel: 1,
                        cells: [
                            cell(''),
                            cell(record?.project ?? "-", 'body'),
                            cell(record?.koj ?? "-", 'body'),
                            cell(record?.number_id ?? "-", 'body'),
                            cell(record?.number_job ?? "-", 'body'),
                            cell(record?.job_type_name ?? "-", 'body'),
                            cell(record?.district ?? "-", 'body'),
                            cell(record?.complete_date === "0001-01-01T00:00:00" ? "-" : new Date(record.complete_date).toLocaleDateString('en-US'), 'body'),
                            cell(record?.poles ?? "-", 'body'),
                        ],
                    },
                ])
            );
        }
    };

    const cell = (text: string, styleId?: string | undefined) => {
        return {
            styleId: styleId,
            data: {
                type: /^\d+$/.test(text) ? 'Number' : 'String',
                value: String(text),
            },
        };
    };

    return (
        <div>
            <AgDataGrid
                rowData={data}
                columnDefs={subColumnDefs}
                masterDetail={true}
                detailCellRendererParams={detailCellRendererParams}
                defaultCsvExportParams={defaultCsvExportParams}
                defaultExcelExportParams={defaultExcelExportParams}
                // isExternalExport
                groupIncludeFooter={true}
                groupIncludeTotalFooter={true}
                ref={subGridRef}
                tableHeight={400}
                defaultPageSize={20}
            />
        </div>
    );
};

export interface DetailEmployeeWorkProps {
    data: DetailRole[];
}

export default DetailEmployeeWork;
