export type Employee = {
    id: string;
    name: string;
    username: string;
    password: string;
    phone: string;
    number_employee: string;
    number_ktp: string;
    number_npwp: string;
    bank_account_number: string;
    bank_account_name: string;
    bank_account_address: string;
    dob: string;
    religion: string;
    contact: string;
    contact_relation: string;
    position: string;
    position_id: string;
    team_id: string;
    email: string;
    date_entry: string;
    status_marital: string;
    total_children: number;
    position_name?: string;
    team_name?: string;
    date_birth?: string;
    place_of_birth: string;
    bpjS_ketenagakerjaan: string;
    bpjS_kesehatan: string;
    education: EducationTypeEnum | string;
    relation_status: RelationTypeEnum | string;
    education_name?: string;
    relation_status_name?: string;
    address: string;
    bank_name: string;
};

export type GetListRequest = {
    skip?: string | number;
    take?: string | number;
};

export type GetListByPositionRequest = {
    skip?: string | number;
    take?: string | number;
    position?: string;
};

export type GetSingleRequest = {
    skip: string | number;
    take: string | number;
    id: string;
};

export type InputModel = {
    name: string;
    username: string;
    password: string;
    phone: string;
    number_employee: string;
    number_ktp: string;
    number_npwp: string;
    bank_account_number: string;
    bank_account_name: string;
    bank_account_address: string;
    dob: string;
    religion: string;
    contact: string;
    contact_relation: string;
    position: string;
    position_id: string;
    team_id: string;
    email: string;
    date_entry: string;
    status_marital: string;
    total_children: string | number;
    nip: string;
    address: string;
    place_of_birth: string;
    bpjS_ketenagakerjaan: string;
    bpjS_kesehatan: string;
    education: EducationTypeEnum | string;
    relation_status: RelationTypeEnum | string;
    date_birth: string;
    bank_name: string;
};

export enum EducationTypeEnum
{none, SD, SekolahMenengah, Diploma, S1, S2, S3}

export enum RelationTypeEnum
{
    IbuKandung,
    BapaKandung,
    Paman,
    Bibi,
    Kakak,
    Adik,
    Sepupu,
    Teman,
    Suami,
    Istri
}

export type InputEmployee = {
    id?: string;
    name: string;
    username: string;
    password: string;
    phone: string;
    number_employee: string;
    number_ktp: string;
    number_npwp: string;
    bank_account_number: string;
    bank_account_name: string;
    bank_account_address: string;
    dob: string;
    religion: string;
    contact: string;
    contact_relation: string;
    position_id: string;
    team_id: string;
};