import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  .date:after {
    content: "";
    position: absolute;
    border-top: 1px solid #e2e8f0;
    top: 12px;
    width: 100%;
  }

  .date:after {
    margin-left: 15px;
  }
`;

export type GridData = {
    label?: string;
    data: Array<{
        text: string;
        value: string;
    }>
}

const GridList = ({
                      data = [],
                      multiColumn = false,
                      column = 0,
                      showLineLabel = false,
                      noLabel = false
                  }: GridListProps) => {
    return (
        <Wrapper className={`${multiColumn && `md:grid md:grid-cols-${column} md:gap-4`}`}>
            {
                data?.map(item => (
                    <div>
                        {
                            !noLabel && (
                                <span
                                    className={`text-gray-900 relative inline-block ${showLineLabel && 'date'} uppercase font-medium tracking-widest`}>{item.label}</span>
                            )
                        }
                        {
                            item.data?.map(dataItem => (
                                <div className="flex my-2">
                                    <div className="w-6/12">
                                        <span className="text-sm block">{dataItem.text}</span>
                                    </div>
                                    <div className="w-6/12">
                                        <span className="text-sm text-gray-600 block">{dataItem.value}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </Wrapper>
    );
};

export interface GridListProps {
    data: Array<GridData>;
    multiColumn?: boolean;
    column?: number;
    showLineLabel?: boolean;
    noLabel?: boolean;
}

export default GridList;
