import React, {useEffect} from 'react';
import TextArea from 'components/TextArea/TextArea';
import {useForm} from "react-hook-form";
import {InputStatusModel} from "../models";
import Button from "components/Button/Button";
import BasicSelectInput from "components/SelectInput/BasicSelectInput";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import {getList} from "features/master/jobSubjectNotes/jobSubjectNotes.reducer";
import {isArrayEmpty} from "helpers/checkEmptiness";

const JobChangeStatus = ({
    action = '',
    jobId = '',
    ...props
}: JobChangeStatusProps) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<InputStatusModel>();

    const dispatch = useAppDispatch();

    const subjectNotesOptions = useAppSelector(
        (state: RootState) => state.jobSubjectNotes.options ?? []
    );

    useEffect(() => {
        if (action === 'waiting') {
            dispatch(getList({skip: 0, take: 500}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    useEffect(() => {
        if (action === 'waiting' && !isArrayEmpty(subjectNotesOptions)) {
            reset({
                pause_subject_id: subjectNotesOptions[0]?.value,
            } as InputStatusModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, subjectNotesOptions]);

    const onSubmit = (data: InputStatusModel) => props.onSubmit(jobId, action, data);

    return (
        <div className='p-5'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextArea
                    {...register( 'notes')}
                    label="Notes"
                    placeholder="Input notes"
                    message={action === 'waiting' ? errors.notes?.message : errors.notes?.message}
                />
                {
                    action === 'waiting' &&
                    <BasicSelectInput
                        label="Subject Notes"
                        message={errors.pause_subject_id?.message}
                        options={subjectNotesOptions}
                        {...register('pause_subject_id')}
                    />
                }
                <div className="pt-2 pb-2 flex content-end">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface JobChangeStatusProps {
    action: string;
    jobId: string;
    onSubmit: (id: string, action: string, data: InputStatusModel) => void;
}

export default JobChangeStatus;
