import {ActionEnum, FeatureEnum, MenuEnum} from "./models";
import {roleAccessList} from "./roleAccessList";

export const checkRoleFeatureAccess = (roleNames: string[], featureName: FeatureEnum) => {
    if (roleNames === undefined || roleNames === null) {
        return false
    }

    for (const roleName of roleNames) {
        const roleAccess = roleAccessList.find((roleAccess) => roleAccess.roleName === roleName);

        if (roleAccess) {
            if (roleAccess.featureAccess.some((featureAccess) => featureAccess === featureName || featureAccess === FeatureEnum.All)) {
                return true;
            }
        }
    }

    return false;
};

export const checkRoleMenuAccess = (roleNames: string[], menuName: MenuEnum) => {
    if (roleNames === undefined || roleNames === null) {
        return false
    }

    for (const roleName of roleNames) {
        const roleAccess = roleAccessList.find((roleAccess) => roleAccess.roleName === roleName);

        if (roleAccess) {
            if (roleAccess.menuAccess.some((menuAccess) => menuAccess.menuName === menuName || menuAccess.menuName === MenuEnum.All)) {
                return true;
            }
        }
    }

    return false;
};

export const checkRoleActionAccess = (
    roleNames: string[],
    menuName: MenuEnum,
    actionName: ActionEnum
) => {
    if (roleNames === undefined || roleNames === null) {
        return false
    }
    for (const roleName of roleNames) {
        const roleAccess = roleAccessList.find((roleAccess) => roleAccess.roleName === roleName);

        if (roleAccess) {
            if (roleAccess.menuAccess.some((menuAccess) => menuAccess.menuName === MenuEnum.All)) {
                return true;
            }

            const menuAccess = roleAccess.menuAccess.find((menuAccess) => menuAccess.menuName === menuName || menuAccess.menuName === MenuEnum.All);

            if (menuAccess && menuAccess.actionAccess.includes(ActionEnum.All)) {
                return true;
            }

            if (menuAccess && menuAccess.actionAccess.includes(actionName)) {
                return true;
            }
        }
    }

    return false;
};