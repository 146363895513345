import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {CreateJobStatus, GetListRequest, JobStatus, UpdateJobStatus} from "./models";

export default class JobStatusApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<JobStatus[]>>(`${this.BASE_URL}${this.PATH_MASTER.jobStatus}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<JobStatus>>(`${this.BASE_URL}${this.PATH_MASTER.jobStatus}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
            }
        }).then((res) => res.data);
    }

    static createJobStatus(args: CreateJobStatus, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobStatus}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateJobStatus(args: UpdateJobStatus, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobStatus}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deleteJobStatus(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobStatus}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
}