import React, { useEffect } from 'react';
import { TableColumn } from 'react-data-table-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { HttpClientError, RequestStatus } from 'http-client';
import { BiEditAlt } from 'react-icons/bi';
import { MdOutlineDeleteForever } from 'react-icons/md';
import {
    deletedStatusOption,
    deletedWarnOption,
} from '../../../../helpers/alertLabel';
import DataTableServerPaging from "../../../../components/Table/DataTableServerPaging";
import {deleteHolidayCalendar, getHolidayCalendarList, reset, setSingleHolidayCalendar} from "../holiday.reducer";
import {HolidayCalendar, HolidayCalendarTypeEnum} from "../models";
import moment from "moment-timezone";

const HolidayCalendarTable = ({ ...props }: HolidayCalendarTableProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const rows = useAppSelector(
        (state: RootState) => state.holiday.holidayCalendarRows ?? 0
    );
    const holidayList = useAppSelector(
        (state: RootState) => state.holiday.holidayCalendarList ?? []
    );
    const status = useAppSelector(
        (state: RootState) => state.holiday.holidayCalendarStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.holiday.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        dispatch(getHolidayCalendarList({ skip: 0, take: 10 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteHolidayCalendar(id));
            }
        });
    };

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getHolidayCalendarList({ skip: 0, take: 10 }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const openFormModal = (single: HolidayCalendar) => {
        props.openModal(true);
        dispatch(setSingleHolidayCalendar(single));
    };

    const columns: TableColumn<HolidayCalendar>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            format: (row) => moment(new Date(row.date), '').format('MM/DD/YYYY'),
            sortable: true,
        },
        {
            name: 'Type',
            selector: (row) => row.type,
            format: (row) => (
                <>
                    {row.type === HolidayCalendarTypeEnum.Indonesia ? 'Indonesia' : 'US'}
                </>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            width: '220px',
            cell: (row) => (
                <>
                    <button onClick={() => openFormModal(row)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color" />
                    </button>
                    <button onClick={() => onDeleteClicked(row.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color" />
                    </button>
                </>
            ),
        },
    ];

    const handlePagingChange = (rowsPerPage: number, page: number) => {
        dispatch(
            getHolidayCalendarList({
                skip: rowsPerPage * (page - 1),
                take: rowsPerPage,
            })
        );
    }

    return (
        <div className="main-data-grid mt-5">
            <DataTableServerPaging columns={columns} data={holidayList} rows={rows} handlePagingChange={handlePagingChange} />
        </div>
    );
};

export interface HolidayCalendarTableProps {
    openModal: (isEdit: boolean) => void;
}

export default HolidayCalendarTable;
