import { darkGreen, darkRed } from '../constants';
import { SweetAlertIcon } from 'sweetalert2';
import React from 'react';

export const savedSuccessText = 'Your data was saved!';
export const savedErrorText = 'Failed to save data: ';

export const deletedWarnTitle = 'Are you sure?';
export const deletedWarnText = "You won't be able to revert this!";
export const deletedSuccessTitle = 'Deleted!';
export const deletedErrorTitle = 'Action was not changed!';
export const deletedSuccessText = 'Data has been deleted.';
export const deletedErrorText = 'Error when deleting data: ';

export const deletedWarnOption = {
    title: deletedWarnTitle,
    text: deletedWarnText,
    icon: 'warning' as SweetAlertIcon,
    showCancelButton: true,
    confirmButtonColor: darkRed,
    cancelButtonColor: darkGreen,
    confirmButtonText: 'Yes, delete it!',
};

export const customWarnOption = (title: string, description: string) => {
    return {
        title: title,
        text: description,
        icon: 'warning' as SweetAlertIcon,
        showCancelButton: true,
        cancelButtonColor: darkGreen,
        confirmButtonText: 'Yes',
    };
};

export const deletedStatusOption = (status: boolean, error: string) => {
    return {
        title: status ? deletedSuccessTitle : deletedErrorTitle,
        text: status ? deletedSuccessText : deletedErrorText + error,
        icon: status
            ? ('success' as SweetAlertIcon)
            : ('error' as SweetAlertIcon),
        confirmButtonColor: darkGreen,
        timer: 2000,
        timerProgressBar: true,
    };
};

export const savedStatusOption = (status: boolean, error: string, holdAutoClose?: boolean) => {
    return {
        html: (
            <span>
                {status
                    ? savedSuccessText
                    : savedErrorText + error}
            </span>
        ),
        icon: status
            ? ('success' as SweetAlertIcon)
            : ('error' as SweetAlertIcon),
        timer: holdAutoClose ? undefined : 2000,
        timerProgressBar: true,
    };
};

export const customStatusOption = ( status: boolean, error: string, successText: string, failedText: string) => {
    return {
        html: (
            <span>
                {status
                    ? successText
                    : failedText + error}
            </span>
        ),
        icon: status
            ? ('success' as SweetAlertIcon)
            : ('error' as SweetAlertIcon),
    };
};