import * as yup from 'yup';
import {EducationTypeEnum, RelationTypeEnum} from "./models";

export const schema = yup
    .object({
        name: yup.string().required(),
        username: yup.string().required(),
        password: yup.string().nullable().required("password is required"),
        // phone: yup.string().required(),
        // number_employee: yup.string().required("employee number is required"),
        // number_ktp: yup.string().required("ktp number is required"),
        // number_npwp: yup.string().required("npwp number is required"),
        // bank_account_number: yup.string().required("bank account number is required"),
        // bank_account_name: yup.string().required("bank account name is required"),
        // bank_account_address: yup.string().required("bank account address is required"),
        // dob: yup.string().required(),
        // religion: yup.string().required(),
        // contact: yup.string().required(),
        // contact_relation: yup.string().required("contact relation is required"),
        position_id: yup.string().nullable().required("position is required"),
        // team_id: yup.string().required(),
    })
    .required();


export const educationOptions = [
    {value: EducationTypeEnum.none.toString(), label: 'Tidak Ada Pendidikan Formal', text: 'Tidak Ada Pendidikan Formal'},
    {value: EducationTypeEnum.SD.toString(), label: 'Sekolah Dasar', text: 'Sekolah Dasar'},
    {value: EducationTypeEnum.SekolahMenengah.toString(), label: 'Sekolah Menengah/Teknik', text: 'Sekolah Menengah/Teknik'},
    {value: EducationTypeEnum.Diploma.toString(), label: 'Diploma Associate (D2/D3)', text: 'Diploma Associate (D2/D3)'},
    {value: EducationTypeEnum.S1.toString(), label: 'Sarjana (S1)', text: 'Sarjana (S1)'},
    {value: EducationTypeEnum.S2.toString(), label: 'Magister (S2)', text: 'Magister (S2)'},
    {value: EducationTypeEnum.S3.toString(), label: 'Doktor (S3)', text: 'Doktor (S3)'}]

export const relationOptions = [
    {value: RelationTypeEnum.IbuKandung.toString(), label: 'Ibu Kandung', text: 'Ibu Kandung'},
    {value: RelationTypeEnum.BapaKandung.toString(), label: 'Ayah Kandung', text: 'Ayah Kandung'},
    {value: RelationTypeEnum.Paman.toString(), label: 'Paman', text: 'Paman'},
    {value: RelationTypeEnum.Bibi.toString(), label: 'Bibi', text: 'Bibi'},
    {value: RelationTypeEnum.Kakak.toString(), label: 'Kakak', text: 'Kakak'},
    {value: RelationTypeEnum.Adik.toString(), label: 'Adik', text: 'Adik'},
    {value: RelationTypeEnum.Sepupu.toString(), label: 'Sepupu', text: 'Sepupu'},
    {value: RelationTypeEnum.Teman.toString(), label: 'Teman', text: 'Teman'},
    {value: RelationTypeEnum.Suami.toString(), label: 'Suami', text: 'Suami'},
    {value: RelationTypeEnum.Istri.toString(), label: 'Istri', text: 'Istri'},
]
