import { ComponentPropsWithRef, FC, forwardRef } from 'react';

type RadioButtonProps = {
    label?: string;
    message?: string;
};

type RadioButtonType = FC<RadioButtonProps & ComponentPropsWithRef<'input'>>;

const RadioButton: RadioButtonType = forwardRef((props, ref) => {
    const { label = '', message = '', ...rest } = props;

    return (
        <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
            <div className="flex items-center gap-2">
                <input
                    id={rest.id}
                    className="h-4 w-4 border border-gray-300 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600 cursor-pointer"
                    type="radio"
                    ref={ref}
                    {...rest}
                />

                {label && (
                    <label
                        className="text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                        htmlFor={rest.id}
                    >
                        {label}
                    </label>
                )}
            </div>

            {message !== '' && (
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {message}
                </p>
            )}
        </div>
    );
});

export default RadioButton;