export type HistoryModel = {
    id: string;
    project: string;
    team: string | null;
    koj: string;
    request_date: string;
    number_id: string;
    number_job: string;
    status: string;
    type: string;
    district: string;
    sales_order: string;
    other_number: string | null;
    due_date: string;
    request_pole: number;
    employee_id_list: string | null;
    team_list: string | null;
    department_owner_name: string;
    employee_cs: null | string;
    employee_cs_name: null | string;
    employee_katapult: null | string;
    employee_katapult_name: null | string;
    employee_mre: null | string;
    employee_mre_name: null | string;
    employee_prep: null | string;
    employee_prep_name: null | string;
    employee_qc: null | string;
    employee_qc_mre: null | string;
    employee_qc_mre_name: null | string;
    employee_qc_name: null | string;
    complete_date: string | null;
    mre_e: number;
    mre_p: number;
    last_check_user_name: string;
    last_check_date: string;
    waiting_notes: string | null;
    hold_by_user_name: string | null;
    waiting_ts: string | null;
    hold_ts: string | null;
    cancel_ts: string | null;
    waiting_by_user_name: string | null;
    cancel_by_user_name: string | null;
    cancel_notes: string | null;
};

export enum JobHistoryColumnSort
{
    project_name,
    team,
    kind_job,
    request_date,
    number_id,
    number_job,
    status,
    type,
    district,
    department,
    sales_order,
    source,
    due_date,
    //prep_user,
    //cs_user,
    //qc_user,
    //mre_user,
    //katapult_user,
    //qc_mre_user,
    complete_date,
    req_pole
}

export enum SortingEnum {
    Ascending,
    Descending
}

export type GetHistoryWithFilterRequest = {
    project_name?: string;
    team_name?: string;
    kind_job?: string;
    startRequestDate?: string;
    endRequestDate?: string;
    number_id?: string;
    number_job?: string;
    status?: string;
    type?: string;
    district?: string;
    department?: string;
    sales_order?: string;
    source?: string;
    other_number?: string;
    po_number?: string;
    startDueDate?: string;
    endDueDate?: string;
    startCompleteDate?: string;
    endCompleteDate?: string;
    req_pole?: number;
    column?: JobHistoryColumnSort;
    sort?: SortingEnum;
    skip: number;
    take: number;
}