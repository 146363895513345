import Dropzone from 'react-dropzone';
import { BiCloudUpload } from 'react-icons/bi';
import { Progress } from 'flowbite-react';
import React from 'react';
import { isObjectEmpty } from '../../helpers/checkEmptiness';
import { IoDocumentAttach } from 'react-icons/io5';
import {convertFileSize} from "../../helpers/convert";

interface CustomDropzoneProps {
    label?: string;
    message?: string;
    onChange: (file: File) => void;
    file: File;
    uploadProgress?: number;
}

function SingleFileDropzone(props: CustomDropzoneProps) {
    const {
        label = '',
        uploadProgress = 0,
        file = {} as File,
        message = '',
        ...rest
    } = props;

    const handleAcceptedProfileFiles = (localFile: Array<File>) => {
        rest.onChange(localFile[0]);
    };

    return (
        <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
            {label && (
                <label
                    htmlFor={label}
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                >
                    {label}
                </label>
            )}
            <Dropzone
                onDrop={(acceptedFiles) =>
                    handleAcceptedProfileFiles(acceptedFiles)
                }
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <>
                        <section className="p-3 border-[1px] rounded-lg border-gray-400 border-dashed">
                            <div
                                className="flex justify-center"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} id={label} />
                                <div>
                                    <div className="flex justify-center">
                                        {!isObjectEmpty(file) ? (
                                            <div className="mb-3">
                                                <div className="flex justify-center">
                                                    <IoDocumentAttach
                                                        size={64}
                                                        className="text-primary-color"
                                                    />
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-500">
                                                        {file.name + ` (${convertFileSize(
                                                            file.size
                                                        )} MB)`}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <BiCloudUpload
                                                size={64}
                                                className="text-gray-400"
                                            />
                                        )}
                                    </div>
                                    <div className="mt-1">
                                        {
                                            uploadProgress > 0 &&
                                            <Progress
                                                progress={uploadProgress}
                                                size="sm"
                                                color="dark"
                                            />
                                        }

                                    </div>
                                    <div>
                                        {message !== '' && (
                                            <p className="mt-2 text-center text-sm text-danger-color dark:text-gray-400">
                                                {message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">
                                            {uploadProgress > 0 ? "Importing, please wait ..." : "Drag & drop some files here, or click to select files" }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )}
            </Dropzone>
        </div>
    );
}

export default SingleFileDropzone;
