import {BooleanNumber, SupportingDocumentEnum} from "types";

export type DatasheetType = {
    id: string;
    job_id: string;
    job_number: string;
    job_number_id: string;
    type_id: string;
    type_name: string;
    value: SupportingDocumentEnum;
    date_request: string;
    link: string;
    note: string;
    option: string;
    request_by: string;
    total_poles: number;
    department_owner_name: string;
    status_name: string;
};

export type PowerMapType = {
    id: string;
    job_id: string;
    job_number: string;
    job_number_id: string;
    type_id: string;
    type_name: string;
    value: SupportingDocumentEnum;
    date_request: string;
    link: string;
    note: string;
    option: string;
    request_by: string;
    total_poles: number;
    department_owner_name: string;
    status_name: string;
};

export type MainMapReq = {
    job_id: string;
    job: string | null;
    type_id: string;
    jobAttachmentType: string | null;
    value: SupportingDocumentEnum;
    date_request: string;
    link: string;
    note: string;
    option: string | null;
    employee_id: string;
    applicationUser: string | null;
    date_follow_up: string;
    missing_fielding: string | null;
    employee_request_id: string;
    ts_last_check: string;
    id: string;
    createby_id: string | null;
    createdby: string | null;
    ts_created: string;
    modifiedby_id: string | null;
    modifiedby: string | null;
    ts_modified: string;
    isactive: boolean;
    createdby_name: string;
    employee_request_name: string;
    job_number_job: string;
    job_number_id: string;
    type_name: string;
    job_pole_total: string;
    employee_name: string;
    department_owner_name: string;
    job_status_name: string;
}

export type GetMapReqListRequest = {
    skip: string | number;
    take: string | number;
    startDate?: string;
    endDate?: string;
    filter?: string;
}

export type GetDatasheetListRequest = {
    skip: string | number;
    take: string | number;
    startDate?: string;
    endDate?: string;
    filter?: string;
}

export type GetMainMapReq = {
    employee_id: string;
    skip: string | number;
    take: string | number;
    startDate?: string;
    endDate?: string;
    filter?: string;
}

export enum PowerMapColumnShort {
    requestDate,
    req_by,
    number_job,
    number_id,
    department_owner,
    po_number,
    area,
    status,
    note,
    total_pole,
}

export enum DataSheetColumnShort {
    requestDate,
    req_by,
    po_number,
    number_job,
    number_id,
    department_owner,
    area,
    status,
    note,
    total_pole,
}

export type GetMapNewRequest = {
    startRequestDate?: string;
    endRequestDate?: string;
    req_by?: string;
    number_job?: string;
    number_id?: string;
    department_owner?: string;
    po_number?: string;
    area?: string;
    status?: string;
    note?: string;
    total_pole?: number;
    column?: PowerMapColumnShort;
    sort?: number;
    skip: number;
    take: number;
}

export enum DatasheetColumnShort {
    startRequestDate,
    endRequestDate,
    req_by,
    po_number,
    number_job,
    number_id,
    department_owner,
    area,
    status,
    note,
    total_pole,
}

export type GetDatasheetNewRequest = {
    startRequestDate?: string;
    endRequestDate?: string;
    req_by?: string;
    po_number?: string;
    number_job?: string;
    number_id?: string;
    department_owner?: string;
    area?: string;
    status?: string;
    note?: string;
    total_pole?: number;
    column?: DatasheetColumnShort;
    sort?: number;
    skip: number;
    take: number;
}

export type GetMapReq = {
    id: string;
    employee_id: string;
}

export type InputMapReqFilter = {
    startDate: string;
    endDate: string;
    filter: string;
}

export type InputDatasheetFilter = {
    startDate: string;
    endDate: string;
    filter: string;
}

export type InputMainJobFilter = {
    startDate: string;
    endDate: string;
    filter: string;
}