import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {useAppDispatch} from 'helpers/redux';
import {isObjectEmpty} from 'helpers/checkEmptiness';
import {EmployeeWorkApprovalInput} from "../../models";
import BasicSelectInput from "../../../../components/SelectInput/BasicSelectInput";
import {approvalStatusOption} from "../../constants";
import {GlobalApprovalEnum} from "../../../../types";
import { changeStaffReportStatus} from "../../approval.reducer";
import {StaffReportModel} from "../../../report/staffReport/models";
import Text from "../../../../components/Text/Text";

const ApproveEmployeeWorkForm = ({
                                    singleEmployeeWork = {} as StaffReportModel,
                                    userId = '',
                                }: ApproveEmployeeWorkFormProps) => {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<EmployeeWorkApprovalInput>({
        defaultValues: {
            status: GlobalApprovalEnum.Approved.toString(),
        }
    });

    useEffect(() => {
        if (!isObjectEmpty(singleEmployeeWork)) {
            reset({
                status: GlobalApprovalEnum.Approved.toString(),
                approvalPole: singleEmployeeWork?.statusName === "ApprovedByHead" ? singleEmployeeWork?.approvalPole.toString() : singleEmployeeWork?.reqPole.toString(),
                rejectionNote: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleEmployeeWork]);

    const onSubmit = (data: EmployeeWorkApprovalInput) => {
        let _input = {
            id: singleEmployeeWork?.id ?? '',
            approvalPole: parseInt(data.approvalPole ?? '0'),
            rejectionNote: data.rejectionNote,
            userId: userId,
            status: parseInt(data.status) as GlobalApprovalEnum,
        };
        dispatch(changeStaffReportStatus(_input));
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text
                    label="User"
                    value={singleEmployeeWork?.name}
                />
                <Text
                    label="Notes"
                    value={singleEmployeeWork?.note}
                />
                <BasicSelectInput
                    label="Status"
                    message={errors.status?.message}
                    options={approvalStatusOption}
                    {...register('status')}
                />
                <InputText
                    {...register('approvalPole')}
                    label="Approve Pole"
                    placeholder="Input approve pole"
                    type="number"
                    message={errors.approvalPole?.message}
                />
                <InputText
                    {...register('rejectionNote')}
                    label="Rejection Notes"
                    placeholder="Input rejection notes"
                    message={errors.rejectionNote?.message}
                />
                <div className="pt-2 pb-2">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface ApproveEmployeeWorkFormProps {
    singleEmployeeWork?: StaffReportModel;
    userId?: string;
}

export default ApproveEmployeeWorkForm;
