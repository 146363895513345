import React from 'react';
import {Route, Routes} from "react-router-dom";
import LoginPage from "features/login/containers/LoginPage";
import SidebarPanel from "features/sidebarPanel/containers/SidebarPanel";
import Logout from "features/logout/containers/Logout";
import PositionPage from "../features/master/position/containers/PositionPage";
import PositionDetailPage from "../features/master/position/containers/PositionDetailPage";
import TeamPage from "../features/master/team/containers/TeamPage";
import TeamDetailPage from "../features/master/team/containers/TeamDetailPage";
import EmployeePage from "../features/master/employee/containers/EmployeePage";
import EmployeeDetailPage from "../features/master/employee/containers/EmployeeDetailPage";
import JobTypePage from "../features/master/jobType/containers/JobTypePage";
import JobCategoryPage from "../features/master/jobCategory/containers/JobCategoryPage";
import JobSubjectNotePage from "../features/master/jobSubjectNotes/containers/JobSubjectNotePage";
import JobStatusPage from "../features/master/jobStatus/containers/JobStatusPage";
import JobKindPage from "../features/master/jobKind/containers/JobKindPage";
import ProjectPage from "../features/master/project/containers/ProjectPage";
import DistrictPage from "../features/master/district/containers/DistrictPage";
import DepartmentPage from "../features/master/department/containers/DepartmentPage";
import DepartmentOwnerPage from "../features/master/departmentOwner/containers/DepartmentOwnerPage";
import JobSourcePage from "../features/master/jobSource/containers/JobSourcePage";
import InputJobPage from "../features/worksheet/process/job/containers/JobPage";
import InputJobDetailPage from "../features/worksheet/process/job/containers/JobDetailPage";
import JobCheckPage from "../features/worksheet/jobData/jobCheck/containers/JobCheckPage";
import EmailTitlePage from "../features/master/emailTitle/containers/EmailTitlePage";
import FieldingPage from "../features/worksheet/jobData/fielding/containers/FieldingPage";
import PoleTypePage from "../features/master/poleType/containers/PoleTypePage";
import PoleStatusPage from "../features/master/poleStatus/containers/PoleStatusPage";
import SupportingDocumentPage from "../features/worksheet/jobData/supportingDocument/containers/SupportingDocumentPage";
import MapReqPage from "../features/worksheet/jobData/mapReq/containers/MapReqPage";
import MasterSupportingDocumentPage
    from "../features/master/supportingDocument/containers/MasterSupportingDocumentPage";
import JobPrepPage from "../features/worksheet/process/jobPrep/containers/JobPrepPage";
import CalcSpecPage from "../features/worksheet/process/calcSpec/containers/CalcSpecPage";
import MREPage from "../features/worksheet/process/mre/containers/MREPage";
import QCPage from "../features/worksheet/process/qc/containers/QCPage";
import KatapultPage from "../features/worksheet/process/katapult/containers/KatapultPage";
import DashboardPage from "../features/dashboard/containers/DashboardPage";
import ChangeLogPage from "../features/changelog/containers/ChangeLogPage";
import HistoryPage from "../features/report/history/containers/HistoryPage";
import StaffReportMainPage from "../features/report/staffReport/StaffReportMainPage";
import JobReportMainPage from "../features/report/jobReport/JobReportMainPage";
import ProfileMainPage from "../features/profile/ProfileMainPage";
import ProcessDetailMainPage from "../features/worksheet/processDetail/ProcessDetailMainPage";
import AttendanceReportMainPage from "../features/report/attendance/AttendanceReportMainPage";
import EmployeeWorkPage from "../features/approval/employeeWork/containers/EmployeeWorkPage";
import OvertimeApprovalPage from "../features/approval/overtimeApproval/containers/OvertimeApprovalPage";
import AttendanceApprovalPage from "../features/approval/attendanceApproval/containers/AttendanceApprovalPage";
import HolidayApprovalPage from "../features/approval/holidayApproval/containers/HolidayApprovalPage";
import AccessAuth from "../features/requireAuth/AccessAuth";
import {checkRoleActionAccess, checkRoleMenuAccess} from "./checkRoleAccess";
import {MenuEnum, ActionEnum} from "./models";
import {useAppSelector} from "../helpers/redux";
import {RootState} from "../app/store";
import {UserCredential} from "../features/login/models";
import UnauthorizedPage from "../components/Layout/Unauthorized";
import HolidayPage from 'features/master/holiday/containers/HolidayPage';
import SupportingDocumentReportPage
    from "../features/report/supportingDocument/containers/SupportingDocumentReportPage";
import BillJobPage from "../features/billJob/billJob/containers/BillJobPage";
import ReportBillingPage from "../features/billJob/reportBilling/containers/ReportBillingPage";
import BillingStatisticPage from "../features/billJob/statistic/containers/BillingStatisticPage";
import CreateBillJobPage from "../features/billJob/billJob/containers/CreateBillJobPage";
import GDevPage from "../features/billJob/gdev/containers/GDevPage";

const RouteList = () => {
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );

    console.log(user?.roleName);

    return (
        <Routes>
            <Route path="login" element={<LoginPage/>}/>
            <Route path="about" element={<div>about</div>}/>
            <Route path="*" element={<SidebarPanel/>}>
                <Route path="protected" element={<div>protected</div>}/>
                <Route path="admin" element={<div>admin</div>}/>


                <Route path="changelog" element={<ChangeLogPage/>}/>

                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Dashboard)}/>}>
                    <Route path="dashboard" element={<DashboardPage/>}/>
                </Route>

                {/* Master */}
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Position)}/>}>
                    <Route path="position" element={<PositionPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Position)}/>}>
                    <Route path="position/detail" element={<PositionDetailPage isEdit={false}/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Position)}/>}>
                    <Route path="position/detail/:id" element={<PositionDetailPage isEdit={true}/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Team)}/>}>
                    <Route path="team" element={<TeamPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Team)}/>}>
                    <Route path="team/detail" element={<TeamDetailPage isEdit={false}/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Team)}/>}>
                    <Route path="team/detail/:id" element={<TeamDetailPage isEdit={true}/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Employee)}/>}>
                    <Route path="employee" element={<EmployeePage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Employee)}/>}>
                    <Route path="employee/detail" element={<EmployeeDetailPage isEdit={false}/>}/>
                    <Route path="employee/detail/:id" element={<EmployeeDetailPage isEdit={true}/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.JobType)}/>}>
                    <Route path="job-type" element={<JobTypePage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.JobCategory)}/>}>
                    <Route path="job-category" element={<JobCategoryPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.JobSubjectNotes)}/>}>
                    <Route path="job-subject-notes" element={<JobSubjectNotePage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.JobStatus)}/>}>
                    <Route path="job-status" element={<JobStatusPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.JobKind)}/>}>
                    <Route path="job-kind" element={<JobKindPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.JobSource)}/>}>
                    <Route path="job-source" element={<JobSourcePage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.PoleType)}/>}>
                    <Route path="pole-type" element={<PoleTypePage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.PoleStatus)}/>}>
                    <Route path="pole-status" element={<PoleStatusPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Project)}/>}>
                    <Route path="project" element={<ProjectPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.District)}/>}>
                    <Route path="district" element={<DistrictPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Department)}/>}>
                    <Route path="department" element={<DepartmentPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.DepartmenOwner)}/>}>
                    <Route path="department-owner" element={<DepartmentOwnerPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.EmailTitle)}/>}>
                    <Route path="email-title" element={<EmailTitlePage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetSupportingDocument)}/>}>
                    <Route path="master/supporting-document" element={<MasterSupportingDocumentPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Holiday)}/>}>
                    <Route path="master/holiday" element={<HolidayPage />}/>
                </Route>
                {/*end of master*/}

                {/*worksheet*/}
                <Route
                    element={<AccessAuth isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetJob)}/>}>
                    <Route path="worksheet/job" element={<InputJobPage/>}/>
                </Route>

                {/*create job url*/}
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob,  ActionEnum.Input)}/>}>
                    <Route path="worksheet/job/detail" element={<InputJobDetailPage isEdit={false}/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.Edit)}/>}>
                    <Route path="worksheet/job/detail/:id" element={<InputJobDetailPage isEdit={true}/>}/>
                </Route>
                {/*end of create job*/}

                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetJobPanel)}/>}>
                    <Route path="worksheet/:koj/:type/:id" element={<ProcessDetailMainPage/>}/>
                </Route>


                {/*job data*/}
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetCheckJob)}/>}>
                    <Route path="worksheet/check-job" element={<JobCheckPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetFielding)}/>}>
                    <Route path="worksheet/fielding" element={<FieldingPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetSupportingDocument)}/>}>
                    <Route path="worksheet/supporting-document" element={<SupportingDocumentPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetMapReq)}/>}>
                    <Route path="worksheet/map-req" element={<MapReqPage/>}/>
                </Route>
                {/*end of job data*/}

                <Route element={<AccessAuth
                    isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetJobPrep)}/>}>
                    <Route path="worksheet/process/job-prep" element={<JobPrepPage/>}/>
                </Route>
                <Route element={<AccessAuth
                    isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetCalcSpec)}/>}>
                    <Route path="worksheet/process/calc-spec" element={<CalcSpecPage/>}/>
                </Route>
                <Route element={<AccessAuth
                    isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetQC)}/>}>
                    <Route path="worksheet/process/qc" element={<QCPage/>}/>
                </Route>
                <Route element={<AccessAuth
                    isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetMRE)}/>}>
                    <Route path="worksheet/process/mre" element={<MREPage/>}/>
                </Route>
                <Route element={<AccessAuth
                    isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.WorksheetKatapult)}/>}>
                    <Route path="worksheet/process/katapult" element={<KatapultPage/>}/>
                </Route>
                {/*end of worksheet*/}

                {/*Report*/}
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.History)}/>}>
                    <Route path="report/history" element={<HistoryPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.StaffReport)}/>}>
                    <Route path="report/staff-report" element={<StaffReportMainPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.JobReport)}/>}>
                    <Route path="report/job-report" element={<JobReportMainPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Attendance)}/>}>
                    <Route path="report/attendance" element={<AttendanceReportMainPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.SupportingDocumentReport)}/>}>
                    <Route path="report/supporting-document" element={<SupportingDocumentReportPage />}/>
                </Route>
                {/*end of report*/}

                {/*Approval*/}
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.EmployeeWorkApproval)}/>}>
                    <Route path="approval/employee-work" element={<EmployeeWorkPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.OvertimeApproval)}/>}>
                    <Route path="approval/overtime" element={<OvertimeApprovalPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.AttendanceApproval)}/>}>
                    <Route path="approval/attendance" element={<AttendanceApprovalPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.HolidayApproval)}/>}>
                    <Route path="approval/holiday" element={<HolidayApprovalPage/>}/>
                </Route>
                {/*end of approval*/}

                {/*Profile*/}
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.Profile)}/>}>
                    <Route path="profile" element={<ProfileMainPage/>}/>
                </Route>
                {/*end of profile*/}

                {/*Bill Job*/}
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.BillJob)}/>}>
                    <Route path="bill-job/create-bill-job" element={<CreateBillJobPage />}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.BillJob)}/>}>
                    <Route path="bill-job/bill-job/:id" element={<BillJobPage isEdit={true}/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.BillJob)}/>}>
                    <Route path="bill-job/report-billing" element={<ReportBillingPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.BillJob)}/>}>
                    <Route path="bill-job/billing-statistic" element={<BillingStatisticPage/>}/>
                </Route>
                <Route
                    element={<AccessAuth
                        isAllowAccess={checkRoleMenuAccess(user?.roleName, MenuEnum.GDev)}/>}>
                    <Route path="bill-job/gdev" element={<GDevPage/>}/>
                </Route>
                {/*end of bill job*/}

                {/*Logout*/}
                <Route path="logout" element={<Logout/>}/>
                <Route path="unauthorized" element={<UnauthorizedPage/>}/>
            </Route>
        </Routes>
    );
};

export default RouteList;
