import {
    CreateEmailTitle,
    GetListRequest, EmailTitle, UpdateEmailTitle,
} from './models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpClientError, RequestStatus } from 'http-client';
import { RootState } from 'app/store';
import EmailTitleApi from './emailTitle.api';
import {Option} from "components/SelectInput/BasicSelectInput";

export interface EmailTitleSlice {
    list?: Array<EmailTitle>;
    options?: Array<Option>;
    single?: EmailTitle;
    rows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getList = createAsyncThunk(
    'email-title/getList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmailTitleApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingle = createAsyncThunk(
    'email-title/getSingle',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmailTitleApi.getSingle(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createEmailTitle = createAsyncThunk(
    'email-title/create',
    async (args: CreateEmailTitle, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmailTitleApi.createEmailTitle(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateEmailTitle = createAsyncThunk(
    'email-title/update',
    async (args: UpdateEmailTitle, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmailTitleApi.updateEmailTitle(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteEmailTitle = createAsyncThunk(
    'email-title/delete',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmailTitleApi.deleteEmailTitle(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const emailTitleSlice = createSlice({
    name: 'emailTitleState',
    initialState: {} as EmailTitleSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.single = {} as EmailTitle;
        },
        setSingle: (state, action) => {
            state.single = action.payload;
        }
    },
    extraReducers: (builder) => {
        // get list data reducer
        builder.addCase(getList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, { payload }) => {
            state.rows = payload.rows;
            state.list = payload.data;
            state.options = payload.data.map((position: EmailTitle) => {
                return {
                    text: position.name,
                    label: position.name,
                    value: position.id
                }
            })
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state) => {
            state.isLoading = false;
        });

        // get single data reducer
        builder.addCase(getSingle.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingle.fulfilled, (state, { payload }) => {
            state.single = payload?.data[0];
            state.isLoading = false;
        });
        builder.addCase(getSingle.rejected, (state) => {
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createEmailTitle.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createEmailTitle.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createEmailTitle.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updateEmailTitle.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateEmailTitle.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateEmailTitle.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deleteEmailTitle.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteEmailTitle.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteEmailTitle.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const { reset, setSingle } = emailTitleSlice.actions;
export default emailTitleSlice.reducer;
