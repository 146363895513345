import {FC} from "react";
import DatePicker from 'react-datepicker';
import {Controller} from "react-hook-form";
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from "react-datepicker";
import {dateSetTimeToNow} from "../../helpers/convert";

type BasicDatePickerProps = {
    label?: string;
    name: string;
    control: any;
    placeholderText: string;
    asISOString?: boolean;
    withPreciseTime?: boolean;
};

type BasicDatePickerType = FC<BasicDatePickerProps>;

const BasicDatePicker: BasicDatePickerType = ((props, ref) => {
    const {
        label = "",
        name = "",
        control = undefined,
        asISOString = false,
        withPreciseTime = false,
        ...rest
    } = props;

    return <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
        {
            label && <label htmlFor={label}
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{label}</label>
        }
        <Controller
            name={name}
            control={control}
            render={({field}) => (
                <DatePicker
                    className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-color focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
                    {...rest}
                    id={label}
                    onChange={(date) => {
                        withPreciseTime ?
                            field.onChange(dateSetTimeToNow(date?.toISOString()) ?? new Date().toISOString())
                            :
                            field.onChange(asISOString ? date?.toISOString() : date)
                    }}
                    selected={field.value === undefined ? new Date() : new Date(field.value)}
                    showYearDropdown
                    dropdownMode="select"
                    // ref={ref}
                />
            )}
        />

    </div>

});

export default BasicDatePicker;