import React, {useEffect, useState} from 'react';
import Divider from 'components/Layout/Divider';
import TextArea from 'components/TextArea/TextArea';
import {Link, useParams} from 'react-router-dom';
import DefaultModal from 'components/Modal/DefaultModal';
import ViewMaps from './ViewMaps';
import {Option,} from 'components/SelectInput/BasicSelectInput';
import Button from 'components/Button/Button';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {useForm} from 'react-hook-form';
import {GetListRequest, Job, PrepReference,} from 'features/worksheet/process/job/models';
import {FileAttachment} from 'types';
import {RootState} from 'app/store';
import {
    getList as getJobList,
    getSingle,
    setUploadProgress,
    updateJob,
} from 'features/worksheet/process/job/job.reducer';
import {isArrayEmpty, isObjectEmpty} from 'helpers/checkEmptiness';
import {HttpClientError, RequestStatus} from 'http-client';
import {customWarnOption, deletedStatusOption, savedStatusOption,} from 'helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import SelectInput from 'components/SelectInput/SelectInput';
import {MultiValue} from 'react-select';
import FileDropzone from 'components/FileInput/Dropzone';
import JobFile from 'features/worksheet/process/job/components/JobFile';
import {Alert, Dropdown} from 'flowbite-react';
import {HiInformationCircle} from 'react-icons/hi';
import {exportPoleToCsv} from '../../../../basic/pole/pole.reducer';
import {deleteJobPrepFile, getJobPrepFile, readyToAssign, reset, uploadJobPrepFile,} from '../jobPrep.reducer';
import FileProgress from '../../../../basic/fileAttachment/components/FileProgress';
import {darkGreen, darkRed} from '../../../../../constants';
import {UpdateDetailJobPrep} from '../models';
import {
    getAttachmentSingleList,
    getAttachmentType,
} from '../../../../basic/attachment/attachment.reducer';
import JobPrepAttachment from "./JobPrepAttachment";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import {dateSetTimeToNow} from "../../../../../helpers/convert";
import moment from "moment";
import {getJobPrepSummary} from "../../../process/jobPrep/processJobPrep.reducer";

const JobPrepForm = () => {
    const {register, handleSubmit, setValue, getValues, reset: resetForm, watch} =
        useForm<UpdateDetailJobPrep>({
            defaultValues: {
                prep_reference_job_id: 'none',
            }
        });

    const {id} = useParams();

    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const selectedJobReference = watch('selected_prep_reference');

    const [showViewMapModal, setShowViewMapModal] = useState<boolean>(false);
    const [showAttachmentModal, setShowAttachmentModal] =
        useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [jobNumberOptions, setJobNumberOptions] = useState<Array<Option>>([]);
    const [currentFiles, setCurrentFiles] = useState<Array<FileAttachment>>([]);
    const [totalFileRemoved, setTotalFileRemoved] = useState<number>(0);
    const [localFiles, setLocalFiles] = useState<Array<File>>([]);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const jobListState = useAppSelector(
        (state: RootState) => state.job.list ?? []
    );
    const isJobLoading = useAppSelector(
        (state: RootState) => state.job.isLoading
    );
    const singleJobState = useAppSelector(
        (state: RootState) => state.job.single ?? ({} as Job)
    );
    const status = useAppSelector((state: RootState) => state.job.status);
    const statusJobPrep = useAppSelector((state: RootState) => state.jobPrep.status);
    const uploadProgresses = useAppSelector(
        (state: RootState) => state.jobPrep.uploadProgress ?? []
    );
    const uploadStatus = useAppSelector((state: RootState) => state.jobPrep.uploadStatus ?? []);
    const deleteFileStatus = useAppSelector(
        (state: RootState) => state.jobPrep.deleteFileStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );
    const errorJobPrep = useAppSelector(
        (state: RootState) => state.jobPrep.error ?? ({} as HttpClientError)
    );
    const jobPrepFile = useAppSelector(
        (state: RootState) => state.jobPrep.jobPrepFile ?? []
    );
    const jobPrepSummaryList = useAppSelector((state: RootState) => state.processJobPrep.jobPrepSummaryList ?? []);

    useEffect(() => {
        dispatch(getAttachmentType({skip: 0, take: 100}));
        dispatch(getJobList({skip: 0, take: 100000} as GetListRequest));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id && id !== "") {
            dispatch(getJobPrepFile(id as string));
            dispatch(getSingle(id as string));
            dispatch(getAttachmentSingleList(id));
            dispatch(getJobPrepSummary(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (!isArrayEmpty(jobListState)) {
            let _options = jobListState.map((job: Job) => {
                return {
                    text: `${job.number_job} (${job.number_id})`,
                    label: `${job.number_job} (${job.number_id})`,
                    value: job.id,
                };
            });
            setJobNumberOptions([
                {
                    text: '-- No Job Reference --',
                    label: '-- No Job Reference --',
                    value: 'none',
                },
                ..._options,
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobListState]);

    useEffect(() => {
        if (!isObjectEmpty(singleJobState)) {
            resetForm({
                id: singleJobState.id,
                prep_notes: singleJobState.prep_notes,
                prep_reference_job_id: singleJobState.prep_reference_job_id ?? 'none',
                prep_reference_job_number_job: singleJobState.prep_reference_job_number_job === "" ? '-- No Job Reference --' : singleJobState.prep_reference_job_number_job,
                selected_prep_reference: singleJobState.prep_reference_list === null ? [] : singleJobState.prep_reference_list?.map((ref: PrepReference) => {
                    return {
                        text: ref.prep_reference_job_number_job,
                        label: ref.prep_reference_job_number_job,
                        value: ref.prep_reference_job_id,
                    };
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleJobState]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(setUploadProgress([]));
                // return navigate('/worksheet/process/job-prep', {replace: true});
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    useEffect(() => {
        if (statusJobPrep !== undefined && statusJobPrep !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    statusJobPrep === RequestStatus.success,
                    errorJobPrep.data?.message
                )
            ).then(() => {
                dispatch(reset());
                // return navigate('/worksheet/process/calc-spec', {replace: true});
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusJobPrep, errorJobPrep]);

    useEffect(() => {
        if (deleteFileStatus !== undefined && deleteFileStatus !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    deleteFileStatus === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getJobPrepFile(id as string));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteFileStatus, error]);

    const toggleUploadModal = (args: boolean) => {
        onModalAttachmentClose();
        setShowUploadModal(args);
    };

    const onModalAttachmentClose = () => {
        setLocalFiles([]);
        dispatch(reset());
        dispatch(getJobPrepFile(id as string));
        setShowAttachmentModal(false);
    };

    const toggleViewMapModal = () => {
        setShowViewMapModal((prevState) => !prevState);
    };

    const onExportClicked = () => {
        dispatch(exportPoleToCsv(id as string));
    };

    const onFileChange = (files: Array<File>) => {
        setLocalFiles(files);
    };

    const onFileRemove = (fileId: string) => {
        let newFiles = currentFiles.filter((f) => f.id !== fileId);
        setCurrentFiles(newFiles);
        setTotalFileRemoved((prevState) => prevState + 1);
    };

    const onFileDelete = (fileId: string) => {
        MySwal.fire({
            text: `Are you sure you want to delete this file?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: darkRed,
            cancelButtonColor: darkGreen,
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJobPrepFile(fileId));
            }
        });
    };

    const onUploadModalClose = () => {
        onModalAttachmentClose();
        setShowUploadModal(false);

    }

    const JobPrepFileAttachment = () => {
        const onUpload = () => {
            if (localFiles.length === 0) {
                MySwal.fire(
                    {
                        title: "File Empty!",
                        text: "You haven't selected any file",
                        icon: 'warning' as SweetAlertIcon,
                    }
                )
            } else {
                setShowUploadModal(true);
                dispatch(uploadJobPrepFile({
                    job_id: id as string,
                    local_files: localFiles,
                }));
            }
        };

        return (
            <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <FileDropzone
                    label="Attachment"
                    onChange={onFileChange}
                    uploadProgress={uploadProgresses}
                    files={localFiles}
                />
                {/*<JobFile files={currentFiles} onFileRemove={onFileRemove} />*/}

                {totalFileRemoved > 0 && (
                    <div className="mt-3">
                        <Alert color="warning" icon={HiInformationCircle}>
                            <span>
                                Changes made in this file list will not be saved
                                until the form is submitted
                            </span>
                        </Alert>
                    </div>
                )}

                <div className="flex justify-center my-2">
                    <div className="mx-1">
                        <Button
                            title="Cancel"
                            type="button"
                            onClick={onModalAttachmentClose}
                        />
                    </div>
                    <div className="mx-1">
                        <Button title="Save" type="button" onClick={onUpload}/>
                    </div>
                </div>
            </div>
        );
    };

    const onReferenceChange = (val: MultiValue<Option>) => {
        let _val = [...val]
        setValue('selected_prep_reference', _val);
    };

    const onSubmit = (data: UpdateDetailJobPrep) => {
        const _selectedRef = data.selected_prep_reference?.map((item) => item.value);
        const {prep_reference_list, ...rest} = singleJobState
        const _data = {
            ...rest,
            date_due: dateSetTimeToNow(new Date(singleJobState.date_due).toISOString()),
            date_due_fielding: dateSetTimeToNow(new Date(singleJobState.date_due_fielding).toISOString()),
            prep_notes: data.prep_notes,
            prep_reference_job_id: _selectedRef?.toString() ?? "",
            isEdit: true,
            local_files: [],
            po_number: singleJobState.po_number as string
        };
        dispatch(updateJob(_data));
    };

    const onReadyClicked = () => {
        MySwal.fire(customWarnOption('Ready to Assign', 'Are you sure?')).then(
            (result) => {
                if (result.isConfirmed) {
                    dispatch(readyToAssign({id: id as string}));
                }
            }
        );
    };

    return (
        <form>
            <Divider>
                <Divider.LeftContent>
                    <div>
                        <div className={"flex"}>
                            <div className="h-[36vh] my-5 border border-[#dfdfdf] rounded-lg w-[100%]">
                                <div className="h-[40px] bg-[#e7ebe6] rounded-t-lg flex items-center">
                                    <label className="block text-sm font-bold text-gray-700 dark:text-gray-300 px-3">
                                        Summary
                                    </label>
                                </div>
                                <div className="h-[calc(36vh-40px)] overflow-auto">
                                    <div className="overflow-auto h-[calc(36vh-40px)]">
                                        {
                                            jobPrepSummaryList.map((item) => (
                                                <div
                                                    className="flex h-[40px] p-4 items-center text-xs border border-[#dfdfdf] hover:bg-[#fcebe0]">
                                                    <div className="w-1/3 truncate">
                                                        <b>{item.employee_name}</b>
                                                    </div>
                                                    <div className="w-1/3">
                                                        <span className="text-[#919191]">Total Poles </span>
                                                        <b>{item.total}</b>
                                                    </div>
                                                    <div className="w-1/3">
                                                    <span
                                                        className="text-[#919191]">Date </span><b>{moment(item.date_due).format("D MMM YYYY")}</b>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TextArea
                            label="Prep Notes"
                            placeholder="Input Prep Notes"
                            {...register('prep_notes')}
                        />

                        <div className="my-3">
                            <span
                                onClick={toggleViewMapModal}
                                className="underline text-blue-700 mr-2 cursor-pointer"
                            >
                                View Maps
                            </span>
                            {
                                checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.Export) &&
                                <span
                                    onClick={onExportClicked}
                                    className="underline text-blue-700 cursor-pointer"
                                >
                                Export to CSV
                            </span>
                            }
                        </div>

                        <DefaultModal
                            title="Maps"
                            show={showViewMapModal}
                            onClose={toggleViewMapModal}
                            size="7xl"
                            body={
                                <div
                                    style={{
                                        height: '500px',
                                        width: '100%',
                                    }}
                                >
                                    <ViewMaps/>
                                </div>
                            }
                        />

                        <div className="my-3 flex">
                            <div className="flex grow">
                                <div className="w-[100%]">
                                    <SelectInput
                                        isMulti
                                        key={`my_unique_select_key__${getValues(
                                            'prep_reference_job_id'
                                        )}`}
                                        options={jobNumberOptions}
                                        isLoading={isJobLoading}
                                        label="Job Reference"
                                        onChange={onReferenceChange}
                                        value={selectedJobReference}
                                    />
                                </div>
                            </div>
                            <div className="flex items-end mx-2">
                                <Dropdown
                                    dismissOnClick={false}
                                    label="View"
                                    color="grey"
                                >
                                    {
                                        singleJobState.prep_reference_list?.map((ref: PrepReference) => (
                                            <Dropdown.Item>
                                                <Link
                                                    // to={`/worksheet/ocalc/job-prep/${ref.prep_reference_job_id}`}
                                                    to={ref?.koj === "MRE" ? `/worksheet/mre/mre-detail/${ref.prep_reference_job_id}`
                                                        : ref?.koj === "Katapult" ? `/worksheet/katapult/katapult-detail/${ref.prep_reference_job_id}`
                                                            : `/worksheet/ocalc/job-detail/${ref.prep_reference_job_id}`}
                                                    target={'_blank'}
                                                    rel="noopener noreferrer"
                                                >
                                                    {ref.prep_reference_job_number_job}
                                                </Link>
                                            </Dropdown.Item>
                                        ))
                                    }
                                </Dropdown>
                            </div>
                        </div>
                        <div className="my-3">
                            <div className="mr-2 flex-grow">
                                {
                                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.Input) &&
                                    <div className="mr-2 inline">
                                        <Button
                                            title="Save"
                                            type="submit"
                                            onClick={handleSubmit((d) => onSubmit(d))}
                                        />
                                    </div>
                                }
                                {
                                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.Attachment) &&
                                    <Button
                                        title="Add/Remove Attachment"
                                        type="button"
                                        onClick={() => setShowAttachmentModal(true)}
                                    />
                                }
                            </div>
                        </div>
                        <div>
                            <JobFile files={currentFiles} uploadedFile={jobPrepFile} onFileRemove={onFileRemove}
                                     onFileDelete={onFileDelete}
                                     isReadOnly={!checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.Attachment)}/>
                        </div>
                    </div>
                </Divider.LeftContent>
                <Divider.CenterLine/>
                <Divider.RightContent>
                    <JobPrepAttachment jobId={id as string}/>
                </Divider.RightContent>
            </Divider>
            <div className="mt-3 grid md:grid-cols-1 gap-4">
                <div className="flex flex-grow justify-end">
                    <div className="inline">
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.ReadyToAssign) &&
                            <Button
                                title="READY TO ASSIGN"
                                type="button"
                                onClick={onReadyClicked}
                            />
                        }
                    </div>
                </div>
            </div>
            <DefaultModal
                title="Job Prep Attachment"
                show={showAttachmentModal}
                onClose={onModalAttachmentClose}
                body={<JobPrepFileAttachment/>}
            />
            <DefaultModal
                title="Uploading File"
                show={showUploadModal}
                onClose={() => onUploadModalClose()}
                body={
                    <FileProgress uploadStatus={uploadStatus} uploadProgress={uploadProgresses}
                                  setShowModal={toggleUploadModal}/>
                }
            />
        </form>
    );
};

export default JobPrepForm;
