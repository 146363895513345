import {
    EmployeeHoliday, EmployeeHolidayReq,
    GetListRequest, HolidayCalendar, HolidayCalendarReq, HolidayCalendarTypeEnum,
} from './models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpClientError, RequestStatus } from 'http-client';
import { RootState } from 'app/store';
import HolidayApi from './holiday.api';
import {EventCalendarModel} from "../../../components/Calendar/models";

export interface HolidaySlice {
    holidayCalendarList?: Array<HolidayCalendar>;
    holidayCalendarEventList?: Array<EventCalendarModel>;
    employeeHolidayList?: Array<EmployeeHoliday>;
    singleHolidayCalendar?: HolidayCalendar;
    singleEmployeeHoliday?: EmployeeHoliday;
    holidayCalendarRows?: number;
    employeeHolidayRows?: number;
    isLoading?: boolean;
    holidayCalendarStatus?: RequestStatus;
    employeeHolidayStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getHolidayCalendarList = createAsyncThunk(
    'holiday/getHolidayCalendarList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.getHolidayCalendarList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getHolidayCalendarEventList = createAsyncThunk(
    'holiday/getHolidayCalendarEventList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.getHolidayCalendarList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getEmployeeHolidayList = createAsyncThunk(
    'holiday/getEmployeeHolidayList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.getEmployeeHolidayList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingleHolidayCalendar = createAsyncThunk(
    'holiday/getSingleHolidayCalendar',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.getHolidayCalendarList({
                id: id
            }, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingleEmployeeHoliday = createAsyncThunk(
    'holiday/getSingleEmployeeHoliday',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.getEmployeeHolidayList({
                id: id
            }, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);


export const createHolidayCalendar = createAsyncThunk(
    'holiday/createHolidayCalendar',
    async (args: HolidayCalendarReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.createHolidayCalendar(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createEmployeeHoliday = createAsyncThunk(
    'holiday/createEmployeeHoliday',
    async (args: EmployeeHolidayReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.createEmployeeHoliday(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);


export const updateHolidayCalendar = createAsyncThunk(
    'holiday/updateHolidayCalendar',
    async (args: HolidayCalendarReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.updateHolidayCalendar(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateEmployeeHoliday = createAsyncThunk(
    'holiday/updateEmployeeHoliday',
    async (args: EmployeeHolidayReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.updateEmployeeHoliday(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);


export const deleteHolidayCalendar = createAsyncThunk(
    'holiday/deleteHolidayCalendar',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.deleteHolidayCalendar(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteEmployeeHoliday = createAsyncThunk(
    'holiday/deleteEmployeeHoliday',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HolidayApi.deleteEmployeeHoliday(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const holidaySlice = createSlice({
    name: 'holidayState',
    initialState: {} as HolidaySlice,
    reducers: {
        reset: (state) => {
            state.holidayCalendarStatus = RequestStatus.pending;
            state.employeeHolidayStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.singleHolidayCalendar = {} as HolidayCalendar;
            state.singleEmployeeHoliday = {} as EmployeeHoliday;
        },
        setSingleHolidayCalendar: (state, action) => {
            state.singleHolidayCalendar = action.payload;
        },
        setSingleEmployeeHoliday: (state, action) => {
            state.singleEmployeeHoliday = action.payload;
        }
    },
    extraReducers: (builder) => {
        // get list data reducer
        builder.addCase(getHolidayCalendarList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getHolidayCalendarList.fulfilled, (state, { payload }) => {
            state.holidayCalendarRows = payload.rows;
            state.holidayCalendarList = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getHolidayCalendarList.rejected, (state) => {
            state.isLoading = false;
        });
        // get list data reducer
        builder.addCase(getHolidayCalendarEventList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getHolidayCalendarEventList.fulfilled, (state, { payload }) => {
            state.holidayCalendarEventList = payload.data?.map((item: HolidayCalendar) => {
                return {
                    id: item.id,
                    title: `${item.type === HolidayCalendarTypeEnum.US ? "🇺🇸" : item.type === HolidayCalendarTypeEnum.Indonesia ? "🇮🇩" :""} ${item.name}`,
                    allDay: true,
                    start: new Date(item.date),
                    end: new Date(item.date)
                }
            })
            state.isLoading = false;
        });
        builder.addCase(getHolidayCalendarEventList.rejected, (state) => {
            state.isLoading = false;
        });
        // get list data reducer
        builder.addCase(getEmployeeHolidayList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getEmployeeHolidayList.fulfilled, (state, { payload }) => {
            state.employeeHolidayRows = payload.rows;
            state.employeeHolidayList = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getEmployeeHolidayList.rejected, (state) => {
            state.isLoading = false;
        });

        // get single data reducer
        builder.addCase(getSingleHolidayCalendar.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingleHolidayCalendar.fulfilled, (state, { payload }) => {
            state.singleHolidayCalendar = payload?.data[0];
            state.isLoading = false;
        });
        builder.addCase(getSingleHolidayCalendar.rejected, (state) => {
            state.isLoading = false;
        });

        // get single data reducer
        builder.addCase(getSingleEmployeeHoliday.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingleEmployeeHoliday.fulfilled, (state, { payload }) => {
            state.singleEmployeeHoliday = payload?.data[0];
            state.isLoading = false;
        });
        builder.addCase(getSingleEmployeeHoliday.rejected, (state) => {
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createHolidayCalendar.pending, (state) => {
            state.holidayCalendarStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createHolidayCalendar.fulfilled, (state) => {
            state.holidayCalendarStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createHolidayCalendar.rejected, (state, { payload }) => {
            state.holidayCalendarStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createEmployeeHoliday.pending, (state) => {
            state.employeeHolidayStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createEmployeeHoliday.fulfilled, (state) => {
            state.employeeHolidayStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createEmployeeHoliday.rejected, (state, { payload }) => {
            state.employeeHolidayStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updateHolidayCalendar.pending, (state) => {
            state.holidayCalendarStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateHolidayCalendar.fulfilled, (state) => {
            state.holidayCalendarStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateHolidayCalendar.rejected, (state, { payload }) => {
            state.holidayCalendarStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updateEmployeeHoliday.pending, (state) => {
            state.employeeHolidayStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateEmployeeHoliday.fulfilled, (state) => {
            state.employeeHolidayStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateEmployeeHoliday.rejected, (state, { payload }) => {
            state.employeeHolidayStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deleteHolidayCalendar.pending, (state) => {
            state.holidayCalendarStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteHolidayCalendar.fulfilled, (state) => {
            state.holidayCalendarStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteHolidayCalendar.rejected, (state, { payload }) => {
            state.holidayCalendarStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deleteEmployeeHoliday.pending, (state) => {
            state.employeeHolidayStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteEmployeeHoliday.fulfilled, (state) => {
            state.employeeHolidayStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteEmployeeHoliday.rejected, (state, { payload }) => {
            state.employeeHolidayStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const { reset, setSingleHolidayCalendar, setSingleEmployeeHoliday } = holidaySlice.actions;
export default holidaySlice.reducer;
