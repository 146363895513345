import {GlobalApprovalEnum} from "../../types";

export type StaffReportApprovalReq = {
    id: string;
    approvalPole: number;
    rejectionNote: string;
    userId: string;
    status: GlobalApprovalEnum;
}

export type OvertimeApprovalReq = {
    id: string;
    approveMinute: number;
    multiplier: number;
    rejectionNote: string;
    userId: string;
    status: GlobalApprovalEnum;
}

export type AttendanceApprovalReq = {
    id: string;
    approveMinute: number;
    rejectionNote: string;
    isDeducted: boolean;
    isRefNote: boolean;
    userId: string;
    status: GlobalApprovalEnum;
}

// export type InputEmployeeWorkFilter = {
//     startDate: string;
//     endDate: string;
//     filterByName: string;
//     filterByNIP: string;
//     filterByTeam: string;
//     filterByNotes: string;
//     filterByJobNumber: string;
//     filterByPosition: string;
//     status: string;
// }

export type InputAttendanceFilter = {
    startDate: string;
    endDate: string;
    filter: string;
    status: string;
}

// export type InputHolidayFilter = {
//     startDate: string;
//     endDate: string;
//     startCreatedDate: string;
//     endCreatedDate: string;
//     filter: string;
//     status?: string;
// }
//
// export type InputOvertimeFilter = {
//     startDate: string;
//     endDate: string;
//     filter: string;
//     status: string;
// }

export type HolidayApprovalReq = {
    id: string;
    rejectionNotes: string;
    userId: string;
    status: GlobalApprovalEnum;
}

export type AttendanceApprovalInput = {
    status: string;
    approveMinute: string;
    leaveDuration: string;
    rejectionNotes: string;
    isDeducted: boolean;
    isRefNote: boolean;
}

export type EmployeeWorkApprovalInput = {
    status: string;
    approvalPole: string;
    rejectionNote: string;
}

export type HolidayApprovalInput = {
    status: string;
    rejectionNotes: string;
}

export type OvertimeApprovalInput = {
    status: string;
    rejectionNote: string;
    approveMinute: string;
    multiplier: string;
}

export enum SortingEnum {
    Ascending,
    Descending
}

export enum EmployeeWorkSortEnum {
    requestDate,
    typeName,
    number_job,
    name,
    nip,
    teamName,
    role_name,
    statusName,
}

export enum OvertimeSortEnum {
    ts_created,
    nip,
    teamName,
    name,
    roleName,
    statusName,
}

// export enum AttendanceSortEnum {
//     ts_created,
//     absentDate,
//     nip,
//     teamName,
//     name,
//     statusName,
// }
//
// export enum HolidaySortEnum {
//     ts_created,
//     reqDate,
//     nip,
//     teamName,
//     name,
//     statusName,
// }

export enum OvertimeApprovalColumnSort
{
    ts_created,
    nip,
    team,
    name,
    role,
    req_minute,
    notes,
    rejection_note,
    approved_time,
    status,
    approve_by,
    manager_approval
}

export enum AttendanceApprovalWithFilterColumnSort
{
    ts_created,
    absent_date,
    nip,
    team,
    name,
    req_minute,
    leave_duration,
    notes,
    status,
    approval_minute,
    approval_date,
    rejection_note
}

export enum HolidayApprovalWithFilterColumnSort
{
    ts_created,
    req_date,
    nip,
    team,
    name,
    note,
    status,
    rejection_note,
    approve_by,
    manager_approval
}

export type GetOvertimeFilterReq = {
    skip: number;
    take: number;
    startDate?: string;
    endDate?: string;
    status?: number;
    nip?: string;
    team?: string;
    name?: string;
    role?: string;
    req_minute?: number;
    note?: string;
    rejection_note?: string;
    approve_by?: string;
    manager_approval?: string;
    approve_minute?: number;
    column?: OvertimeApprovalColumnSort;
    sortType?: SortingEnum;
}

export type GetAttendanceFilterReq = {
    skip: number;
    take: number;
    startDate?: string;
    endDate?: string;
    startAbsentDate?: string;
    endAbsentDate?: string;
    nip?: string;
    team?: string;
    name?: string;
    req_minute?: number;
    leave_duration?: number;
    note?: string;
    status?: number;
    approve_minute?: number;
    startApprovalDate?: string;
    endApprovalDate?: string;
    rejection_note?: string;
    column?: AttendanceApprovalWithFilterColumnSort;
    sortType?: SortingEnum;
}

export type GetHolidayFilterReq = {
    skip: number;
    take: number;
    startDate?: string;
    endDate?: string;
    startReqDate?: string;
    endReqDate?: string;
    nip?: string;
    team?: string;
    name?: string;
    note?: string;
    status?: number;
    rejection_note?: string;
    approve_by?: string;
    manager_approval?: string;
    column?: HolidayApprovalWithFilterColumnSort;
    sortType?: SortingEnum;
}