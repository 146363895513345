import React, {ComponentPropsWithRef, FC, forwardRef} from 'react';
import Spinner from "../Spinner/Spinner";

type ButtonProps = {
    title?: string;
    isLoading?: boolean;
    disabled?: boolean;
}

type ButtonType = FC<ButtonProps & ComponentPropsWithRef<'button'>>;

const Button: ButtonType = forwardRef((props, ref) => {
    const {
        title = "",
        isLoading = false,
        disabled = false,
        ...rest
    } = props;

    return <button
        className="inline text-white bg-secondary-color border border-transparent hover:bg-secondary-color-hover focus:ring-4 focus:ring-secondary-color-hover disabled:hover:bg-primary-color-hover dark:bg-primary-color-dark dark:hover:bg-primary-color-dark-hover dark:focus:ring-primary-color-hover dark:disabled:hover:bg-primary-color-dark-hover group h-[100%] w-fit items-center justify-center py-1 px-5 text-center font-normal focus:z-10 rounded-lg" ref={ref} {...rest} disabled={isLoading || disabled}>
        <span className="flex items-center rounded-md text-sm px-2 py-1">
            {
                isLoading ? <Spinner /> : title
            }
        </span></button>
});

export default Button;
