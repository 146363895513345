export const filterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
        var dateAsString = new Date(cellValue).toLocaleDateString('en-GB');
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split('/');
        var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
    minValidYear: 2000,
    maxValidYear: new Date().getFullYear(),
    inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};