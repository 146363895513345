import {Option} from "../../components/SelectInput/BasicSelectInput";

export type BillJobInputModel = {
    numberJobId: Option;
    numberId: Option;
    otherNumberJobId: Option;
    salesOrderJobId: Option;
    poNumberJobId: Option;
    type: Option;
    status: Option;
    totalPole: string;
    vDriveLocation: string;
    attachment: string;
    notes: string;
    local_files: Array<File>;
}

export type SubmitBillJobWithFile = {
    id?: string;
    numberJobId: string;
    otherNumberJobId: string;
    numberId: string;
    salesOrderJobId: string;
    poNumberJobId: string;
    type: number;
    status: number;
    totalPole: number;
    vDriveLocation: string;
    attachment: string;
    notes: string;
    local_files: Array<File>;
    isEdit: boolean;
}

export interface SelectedJobData {
    number_id: NumberId[];
    other_number: OtherNumber[];
    sales_order: SalesOrder[];
    po_number: PONumber[];
}

export interface NumberId {
    job_id: string;
    number_id: string;
    koj: string;
    flag: boolean;
}

export interface OtherNumber {
    job_id: string;
    koj: string;
    other_number: string;
}

export interface SalesOrder {
    job_id: string;
    koj: string;
    sales_order: string;
}

export interface PONumber {
    job_id: string;
    koj: string;
    po_number: string;
}

export enum TypeBillEnum {
    Original,
    AddPoles,
    SubstractPoles,
    UpdatePoles,
    MREP,
    MREE,
    Katapult
}

export enum StatusBillEnum {
    NewJob,
    AddPoles,
    NewProjectRequest,
    RevisionAddPoles,
    RevisionWrongPolesOcalcAddPole,
    RevisionDWG,
    RevisionWrongFielding,
    ModeExcessPolesInError,
    RevisionWrongPolesOcalcReducePoles,
    UpdatePowerMap,
    UpdateFielding,
    UpdatePowerMapDataSheet,
    UpdateDataSheet,
    UpdateWorkingDrawing,
    UpdatePoleNumber,
    UpdateEmail,
    UpdateAttachment,
    UpdateAramis,
    UpdateDenial,
    UpdateAddPoles
}

export type RequestBillJob = {
    id?: string;
    numberJobId: string;
    otherNumberJobId: string;
    numberId: string;
    salesOrderJobId: string;
    poNumberJobId: string;
    type: TypeBillEnum;
    status: StatusBillEnum;
    totalPole: number;
    vDriveLocation: string;
    attachment: string;
    notes: string;
}

export type GetBillJob = {
    skip: number;
    take: number;
    id?: string;
}

export type BillJob = {
    ts_created: string;
    upload_date: string;
    job_complete_date: string;
    department: string;
    departmentOwner: string;
    project: string;
    koj: string;
    id: string;
    numberJobId: string;
    numberJob: string;
    numberId: string;
    number: string;
    otherNumberJobId: string;
    otherNumberJob: string;
    salesOrderJobId: string;
    salesOrderJob: string;
    poNumberJob: string;
    poNumberJobId: string;
    type: TypeBillEnum;
    typeName: string;
    status: StatusBillEnum;
    statusName: string;
    totalPole: number;
    vDriveLocation: string;
    attachment: string;
    notes: string;
    reqNumber: string;
    isMoreThan24Hours: boolean;
}

export type BillJobDetail = {
    id: string;
    source_name: string;
    number_id: string;
    number_job: string;
    other_number: string;
    sales_order: string;
    complete_date: string;
    district_name: string;
    department_name: string;
    total_poles: number;
    notes: string | null;
}

export enum BillJobColumnSortEnum {
    entry_date,
    upload_date,
    project,
    koj,
    number_id,
    number_job,
    type,
    status,
    total_pole,
    complete_date,
    department,
    note,
    District,
    TypeJob,
    User,
    other_number,
    sales_order
}

export enum SortingEnum {
    Ascending,
    Descending
}

export interface BillJobRequest {
    startEntryDate?: string;
    endEntryDate?: string;
    startUploadDate?: string;
    endUploadDate?: string;
    project?: string;
    koj?: string;
    numberJobId?: string;
    numberId?: string;
    type?: number;
    status?: number;
    totalPole?: number;
    startCompleteDate?: string;
    endCompleteDate?: string;
    department?: string;
    notes?: string;
    district?: string;
    typeJob?: string;
    user?: string;
    other_number?: string;
    sales_order?: string;
    weekendDate?: string,
    month?: number,
    year?: number,
    column?: BillJobColumnSortEnum;
    sort?: SortingEnum;
    skip: number;
    take: number;
}

export type GetWeekendDate = {
    month: number;
    year: number;
}

export type GetGDevRequest = {
    project_name?: string;
    kind_job?: string;
    startCompleteDate?: string;
    endCompleteDate?: string;
    startMICDate?: string;
    endMICDate?: string;
    startSTDate?: string;
    endSTDate?: string;
    number_id?: string;
    number_job?: string;
    other_number?: string;
    type?: string;
    district?: string;
    sales_order?: string;
    pole_approve?: number;
    pole_complete?: number;
    total_pole?: number;
    column?: GDevColumnSortEnum;
    sort?: number;
    weekendDate?: string;
    month?: number;
    year?: number;
    isBilled?: boolean;
    is_gdev_approve_pole_null?: boolean | null;
    is_user_gdev_null?: boolean | null;
    skip?: number;
    take?: number;
}

export enum GDevColumnSortEnum {
    project_name,
    kind_job,
    complete_date,
    number_id,
    number_job,
    other_number,
    type,
    district,
    sales_order,
    approve_pole,
    pole_complete,
    total_pole,
    ST_date,
    Mic_date,
    user_update
}

export type GDevDataModel = {
    id: string;
    project: string;
    koj: string;
    complete_date: string;
    number_id: string;
    number_job: string;
    other_number: string;
    type: string;
    sales_order: string;
    district: string;
    sT_date: string | null;
    mic_date: string | null;
    user_gdev: string | null;
    gdev_approve_pole: number | null;
    total_complete_pole: number;
    total_pole: number;
    isBilled: boolean | null;
}

export type BilledGDevRequest = {
    job_id: string;
    sT_date: string | null;
    mic_date: string | null;
    user_gdev: string;
    gdev_approve_pole: number;
}

export type GDevApproveRequest = {
    job_id: string;
    isBilled: boolean;
}