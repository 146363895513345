import React, {useEffect, useState} from 'react';
import AgDataGrid from "../../../../../components/DataGrid/AgDataGrid";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {filterParams} from "../../../../../helpers/agTable";
import {Link} from "react-router-dom";
import {
    checkHoldStatus,
    checkKatapultStatus,
    checkMreStatus,
    checkNotesJobStatus,
} from "../constants";
import {cancelJob, changeStatusJob, getAllJobList, reset, waitingJob} from "../job.reducer";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {Tooltip} from "flowbite-react";
import {MdMotionPhotosPaused, MdOutlineCancel, MdPauseCircleOutline} from "react-icons/md";
import {RootState} from "../../../../../app/store";
import {UserCredential} from "../../../../login/models";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import JobChangeStatus from "./JobChangeStatus";
import {InputStatusModel} from "../models";
import {customStatusOption, customWarnOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
    JOB_STATUS_HOLD,
    JOB_STATUS_WAITING,
    JOB_STATUS_WAITING_KATAPULT,
    JOB_STATUS_WAITING_MRE
} from "../../../../../constants";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import FormLoader from "../../../../../components/Loader/FormLoader";
import MREChangeStatus from "../../mre/components/MREChangeStatus";
import {cancelMRE, reset as resetMre, waitingMRE} from "../../mre/processMRE.reducer";
import KatapultChangeStatus from "../../katapult/components/KatapultChangeStatus";
import {cancelKatapult, reset as resetKatapult, waitingKatapult} from "../../katapult/processKatapult.reducer";
import {IoInformationCircleOutline} from "react-icons/io5";
import {HistoryModel} from "../../../../report/history/models";
import {dateWithTime} from "../../../../../helpers/convert";
import {RiEditCircleFill} from "react-icons/ri";

const AllJobTable = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();

    const allJobState = useAppSelector(state => state.job.listAllJob ?? []);
    const isLoading = useAppSelector(state => state.job.isLoading);
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const actionStatus = useAppSelector(
        (state: RootState) => state.job.actionStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );
    const actionStatusMre = useAppSelector((state: RootState) => state.processMRE.actionStatus);
    const errorMre = useAppSelector(
        (state: RootState) => state.processMRE.error ?? ({} as HttpClientError)
    );
    const actionStatusKatapult = useAppSelector((state: RootState) => state.processKatapult.actionStatus);
    const errorKatapult = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const [jobId, setJobId] = useState<string>('');
    const [isAllJobAction, setIsAllJobAction] = useState<boolean>(false);

    const [mreAction, setMreAction] = useState<string>('');
    const [showChangeMreStatusModal, setShowChangeMreStatusModal] = useState<boolean>(false);
    const [katapultAction, setKatapultAction] = useState<string>('');
    const [showChangeKatapultStatusModal, setShowChangeKatapultStatusModal] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getAllJobList());
    }, []);

    useEffect(() => {
        if (
            isAllJobAction && (actionStatus !== undefined &&
                actionStatus !== RequestStatus.pending)
        ) {
            MySwal.fire(
                customStatusOption(
                    actionStatus === RequestStatus.success,
                    error.data?.message,
                    'status has been changed!',
                    'failed to change status'
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getAllJobList());
                setJobId('');
                setAction('');
                setShowModal(false);
                setIsAllJobAction(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllJobAction, actionStatus, error]);

    useEffect(() => {
        if (actionStatusMre !== undefined && actionStatusMre !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(actionStatusMre === RequestStatus.success, errorMre.data?.message, 'status has been changed!', 'failed to change status')).then(() => {
                dispatch(resetMre());
                dispatch(getAllJobList());
                setJobId('');
                setAction('');
                setShowChangeMreStatusModal(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatusMre, errorMre]);

    useEffect(() => {
        if (actionStatusKatapult !== undefined && actionStatusKatapult !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(actionStatusKatapult === RequestStatus.success, errorKatapult.data?.message, 'status has been changed!', 'failed to change status')).then(() => {
                dispatch(resetKatapult());
                dispatch(getAllJobList());
                setJobId('');
                setAction('');
                setShowChangeKatapultStatusModal(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatusKatapult, errorKatapult]);

    const openModal = (action: string, id: string) => {
        setAction(action);
        setShowModal(true);
        setJobId(id);
    };

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setJobId('');
        setAction('');
    };

    const openChangeMreStatusModal = (action: string, id: string) => {
        setMreAction(action);
        setShowChangeMreStatusModal(true);
        setJobId(id);
    };

    const toggleChangeMreStatusModal = () => {
        setShowChangeMreStatusModal((prevState) => !prevState);
        setMreAction('');
        setJobId('');
    };

    const openChangeKatapultStatusModal = (action: string, id: string) => {
        setKatapultAction(action);
        setShowChangeKatapultStatusModal(true);
        setJobId(id);
    };

    const toggleChangeKatapultStatusModal = () => {
        setShowChangeKatapultStatusModal((prevState) => !prevState);
        setKatapultAction('');
        setJobId('');
    };

    const onHoldClicked = (job_id: string) => {
        MySwal.fire(
            customWarnOption(
                'Hold Job',
                'Are you sure to hold this job? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                setIsAllJobAction(true);
                dispatch(changeStatusJob({
                    job_id: job_id,
                    status: JOB_STATUS_HOLD,
                    userId: user.user_id
                }))
            }
        });
    };

    const onStatusClicked = (
        id: string,
        action: string,
        data?: InputStatusModel
    ) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            setIsAllJobAction(true);
            if (result.isConfirmed) {
                switch (action) {
                    case 'waiting':
                        dispatch(
                            waitingJob({
                                job_id: id,
                                waiting_notes: data?.notes ?? '',
                                waiting_subject_id:
                                    data?.pause_subject_id ?? '',
                                waiting_by_user: user.user_id
                            })
                        );
                        break;
                    case 'cancel':
                        dispatch(
                            cancelJob({
                                job_id: id,
                                cancel_notes: data?.notes ?? '',
                                waiting_by_user: user.user_id
                            })
                        );
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const onMreStatusClicked = (id: string, action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                switch (action) {
                    case 'waiting':
                        dispatch(waitingMRE({
                            job_id: id,
                            waiting_notes: data?.notes ?? '',
                            waiting_subject_id: null,
                            waiting_by_user: user.user_id
                        }));
                        break;
                    case 'cancel':
                        dispatch(cancelMRE({
                            job_id: id,
                            cancel_notes: data?.notes ?? '',
                            waiting_by_user: user.user_id
                        }));
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const onKatapultStatusClicked = (id: string, action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                switch (action) {
                    case 'waiting':
                        dispatch(waitingKatapult({
                            job_id: id,
                            waiting_notes: data?.notes ?? '',
                            waiting_subject_id: null,
                            waiting_by_user: user.user_id
                        }));
                        break;
                    case 'cancel':
                        dispatch(cancelKatapult({
                            job_id: id,
                            cancel_notes: data?.notes ?? '',
                            waiting_by_user: user.user_id
                        }));
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex h-[100%] items-center">
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetAllJob, ActionEnum.Edit) &&
                    <button>
                        <Link
                            to={{
                                pathname: `/worksheet/job/detail/${props.data?.id}`,
                            }}
                        >
                            <RiEditCircleFill className="mx-1 text-2xl text-primary-color"/>
                        </Link>
                    </button>
                }
                {
                    (checkHoldStatus(props.data?.status)) &&
                    <>
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetAllJob, ActionEnum.ActionWaiting) &&

                            <Tooltip content="Waiting Job" trigger="hover" placement="right" style="light">
                                <div className="flex">
                                    <button onClick={() => openModal('waiting', props.data?.id)}>
                                        <MdPauseCircleOutline className="mx-1 text-2xl text-primary-color"/>
                                    </button>
                                </div>
                            </Tooltip>

                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetAllJob, ActionEnum.ActionHold) &&
                            <Tooltip content="Hold Job" trigger="hover" placement="right" style="light">
                                <div className="flex">
                                    <button onClick={() => onHoldClicked(props.data?.id)}>
                                        <MdMotionPhotosPaused className="mx-1 text-2xl text-primary-color"/>
                                    </button>
                                </div>
                            </Tooltip>
                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetAllJob, ActionEnum.ActionCancel) &&
                            <button onClick={() => openModal('cancel', props.data?.id)}>
                                <MdOutlineCancel className="mx-1 text-2xl text-primary-color"/>
                            </button>
                        }
                    </>
                }
                {
                    (checkNotesJobStatus(props.data?.status)) &&
                    <>
                        <button onClick={() => showNotes(props.data)}>
                            <IoInformationCircleOutline className="mx-1 text-2xl text-primary-color"/>
                        </button>
                    </>
                }
                {
                    (checkMreStatus(props.data?.status)) &&
                    <>
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetAllJob, ActionEnum.ActionWaiting) &&

                            <Tooltip content="Waiting Job" trigger="hover" placement="right" style="light">
                                <div className="flex">
                                    <button onClick={() => openChangeMreStatusModal('waiting', props.data?.id)}>
                                        <MdPauseCircleOutline className="mx-1 text-2xl text-primary-color"/>
                                    </button>
                                </div>
                            </Tooltip>

                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetAllJob, ActionEnum.ActionCancel) &&
                            <button onClick={() => openChangeMreStatusModal('cancel', props.data?.id)}>
                                <MdOutlineCancel className="mx-1 text-2xl text-primary-color"/>
                            </button>
                        }
                    </>
                }
                {
                    (checkKatapultStatus(props.data?.status)) &&
                    <>
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetAllJob, ActionEnum.ActionWaiting) &&

                            <Tooltip content="Waiting Job" trigger="hover" placement="right" style="light">
                                <div className="flex">
                                    <button onClick={() => openChangeKatapultStatusModal('waiting', props.data?.id)}>
                                        <MdPauseCircleOutline className="mx-1 text-2xl text-primary-color"/>
                                    </button>
                                </div>
                            </Tooltip>

                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetAllJob, ActionEnum.ActionCancel) &&
                            <button onClick={() => openChangeKatapultStatusModal('cancel', props.data?.id)}>
                                <MdOutlineCancel className="mx-1 text-2xl text-primary-color"/>
                            </button>
                        }
                    </>
                }
            </div>
        )
    }

    const showNotes = (data: HistoryModel) => {
        const lastCheckedDate = data.status === JOB_STATUS_WAITING ? data.last_check_date : data.status === JOB_STATUS_WAITING_MRE || data.status === JOB_STATUS_WAITING_KATAPULT ? data.waiting_ts : data.status === JOB_STATUS_HOLD ? data.hold_ts : data.cancel_ts;

        const lastCheckedBy = data.status === JOB_STATUS_WAITING ? data.last_check_user_name : data.status === JOB_STATUS_WAITING_MRE || data.status === JOB_STATUS_WAITING_KATAPULT ? data.waiting_by_user_name : data.status === JOB_STATUS_HOLD ? data.hold_by_user_name : data.cancel_by_user_name;

        const lastCheckedNotes = data.status === JOB_STATUS_WAITING ? data.waiting_notes : data.status === JOB_STATUS_WAITING_MRE || data.status === JOB_STATUS_WAITING_KATAPULT ? data.waiting_notes : data.status === JOB_STATUS_HOLD ? "-" : data.cancel_notes;

        MySwal.fire({
            html: <div className="flex">
                <div className="text-left">
                    <div className="font-bold text-sm">Last Checked By : <span
                        className="font-normal text-sm">{lastCheckedBy}</span></div>
                    <div className="font-bold text-sm">Last Checked Date : <span
                        className="font-normal text-sm">{dateWithTime(lastCheckedDate ?? "0001-01-01T00:00:00")}</span>
                    </div>
                    <div className="font-bold text-sm">Notes : <span
                        className="font-normal text-sm">{lastCheckedNotes}</span></div>
                </div>
            </div>
        });
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={props.data?.koj === "MRE" ? `/worksheet/mre/mre-detail/${props.data?.id}`
                : props.data?.koj === "Katapult" ? `/worksheet/katapult/katapult-detail/${props.data?.id}`
                    : `/worksheet/ocalc/job-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([
        {field: 'project', headerName: 'Project', filter: true},
        {field: 'team', headerName: 'Team', filter: true},
        {field: 'koj', headerName: 'KoJ', filter: true},
        {
            field: 'request_date',
            headerName: 'Request Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date'
        },
        {field: 'number_id', headerName: 'ID Number', filter: 'agTextColumnFilter'},
        {
            headerName: 'Job Number',
            field: 'number_job',
            filter: 'agTextColumnFilter',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
        },
        {field: 'status', headerName: 'Status', filter: true},
        {field: 'type', headerName: 'Type', filter: true},
        {field: 'district', headerName: 'District'},
        {field: 'po_number', headerName: 'PO Number', filter: 'agTextColumnFilter'},
        {field: 'department_name', headerName: 'Department', filter: 'agTextColumnFilter'},
        {field: 'department_owner_name', headerName: 'Department Owner', filter: 'agTextColumnFilter'},
        {field: 'sales_order', headerName: 'Sales Order'},
        {field: 'source_name', headerName: 'Sources', filter: 'agTextColumnFilter'},
        {field: 'other_number', headerName: 'Other Number', filter: 'agTextColumnFilter'},
        {
            field: 'due_date',
            headerName: 'Due Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date'
        },
        {field: 'request_pole', headerName: 'Req Pole'},
        {field: 'employee_prep_name', headerName: 'Prep User'},
        {field: 'employee_cs_name', headerName: 'CS User'},
        {field: 'employee_qc_name', headerName: 'QC User'},
        {field: 'employee_mre_name', headerName: 'MRE User'},
        {field: 'employee_qc_mre_name', headerName: 'QC MRE User'},
        {field: 'mre_e', headerName: 'MRE E'},
        {field: 'mre_p', headerName: 'MRE P'},
        {field: 'employee_katapult_name', headerName: 'Katapult User'},
        {
            field: 'complete_date',
            headerName: 'Complete Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date'
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
            }),
        },
    ]);

    return (
        <div>
            <div className="mt-5">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">All Job</h1>
                </div>
                <div className="main-data-grid">
                    <FormLoader isLoading={isLoading} label={"Fetching Data"}>
                        <AgDataGrid rowData={allJobState} columnDefs={columnDefs} defaultPageSize={20}/>
                    </FormLoader>
                </div>
                <DefaultModal
                    title="Change Status"
                    show={showModal}
                    onClose={toggleModal}
                    body={
                        <JobChangeStatus
                            action={action}
                            jobId={jobId}
                            onSubmit={onStatusClicked}
                        />
                    }
                />
                <DefaultModal
                    title="Change MRE Status"
                    show={showChangeMreStatusModal}
                    onClose={toggleChangeMreStatusModal}
                    body={<MREChangeStatus action={mreAction} jobId={jobId} onSubmit={onMreStatusClicked}/>}
                />
                <DefaultModal
                    title="Change Katapult Status"
                    show={showChangeKatapultStatusModal}
                    onClose={toggleChangeKatapultStatusModal}
                    body={<KatapultChangeStatus action={katapultAction} jobId={jobId}
                                                onSubmit={onKatapultStatusClicked}/>}
                />
            </div>
        </div>
    );
};

export default AllJobTable;
