import React from 'react';
import JobPrepTable from '../components/JobPrepTable';
import JobPrepForm from "../components/JobPrepForm";

const JobPrepPage = () => {

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="mt-3">
                <JobPrepTable />
                <div>
                    <JobPrepForm />
                </div>
            </div>
        </div>
    );
};

export default JobPrepPage;
