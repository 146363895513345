import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {MdOutlineCheckCircleOutline, MdOutlineEmail} from 'react-icons/md';
import DefaultModal from 'components/Modal/DefaultModal';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';
import withReactContent from 'sweetalert2-react-content';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import {exportToCsv, getList} from '../fielding.reducer';
import {
    customStatusOption, savedStatusOption,
} from 'helpers/alertLabel';
import {reset} from 'features/worksheet/process/job/job.reducer';
import {HttpClientError, RequestStatus} from 'http-client';
import {Fielding, InputFieldingFilter} from '../models';
import FieldingUpdate from "./FieldingUpdate";
import {AiOutlineHistory} from "react-icons/ai";
import {darkGreen} from "../../../../../constants";
import {updateAttachment, reset as resetAttachment} from "../../../../basic/attachment/attachment.reducer";
import {SupportingDocumentEnum} from "../../../process/job/models";
import CreateEmail from "../../../../basic/email/containers/CreateEmail";
import {DETAIL_TYPE_JOB_INPUT} from "../../../process/job/constants";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import Divider from "../../../../../components/Layout/Divider";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import InputText from "../../../../../components/TextInput/InputText";
import Button from "../../../../../components/Button/Button";
import {useForm} from "react-hook-form";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {parseDate} from "../../../../../helpers/convert";

const FieldingTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        reset: resetForm
    } = useForm<InputFieldingFilter>({defaultValues: {startRequestDate: undefined, endRequestDate: undefined, startDueDate: undefined, endDueDate: undefined}});

    const startRequestDate = watch('startRequestDate');
    const endRequestDate = watch('endRequestDate');
    const startDueDate = watch('startDueDate');
    const endDueDate = watch('endDueDate');

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const status = useAppSelector((state: RootState) => state.fielding.status);
    const actionStatus = useAppSelector(
        (state: RootState) => state.job.actionStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );
    const emailError = useAppSelector(
        (state: RootState) => state.fielding.error ?? ({} as HttpClientError)
    );
    const statusAttachment = useAppSelector(
        (state: RootState) => state.attachment.status
    );
    const errorAttachment = useAppSelector(
        (state: RootState) => state.attachment.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [jobId, setJobId] = useState<string>('');
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [selectedFielding, setSelectedFielding] = useState<Fielding>({} as Fielding);

    useEffect(() => {
        if (statusAttachment !== undefined && statusAttachment !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(statusAttachment === RequestStatus.success, errorAttachment.data?.message)).then(() => {
                if (statusAttachment === RequestStatus.success) {
                    refreshCache(undefined);
                    gridRef.current.api.setFilterModel({
                        filter: {
                            filterType: "text",
                            type: "contains",
                            filter: getValues("filter")
                        },
                        startRequestDateFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: parseDate(startRequestDate),
                        },
                        endRequestDateFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: parseDate(endRequestDate),
                        },
                        startDueDateFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: parseDate(startDueDate),
                        },
                        endDueDateFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: parseDate(endDueDate),
                        },
                    });
                    closeUpdateModal();
                }
                dispatch(resetAttachment());
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusAttachment, errorAttachment]);

    useEffect(() => {
        if (
            actionStatus !== undefined &&
            actionStatus !== RequestStatus.pending
        ) {
            MySwal.fire(
                customStatusOption(
                    actionStatus === RequestStatus.success,
                    error.data?.message,
                    'status has been changed!',
                    'failed to change status'
                )
            ).then(() => {
                dispatch(reset());
                refreshCache(undefined);
                gridRef.current.api.setFilterModel({
                    filter: {
                        filterType: "text",
                        type: "contains",
                        filter: getValues("filter")
                    },
                    dateStartFilter: {
                        filterType: "text",
                        type: "contains",
                        filter: parseDate(startRequestDate),
                    },
                    dateEndFilter: {
                        filterType: "text",
                        type: "contains",
                        filter: parseDate(endRequestDate),
                    },
                    startDueDateFilter: {
                        filterType: "text",
                        type: "contains",
                        filter: parseDate(startDueDate),
                    },
                    endDueDateFilter: {
                        filterType: "text",
                        type: "contains",
                        filter: parseDate(endDueDate),
                    },
                });
                setShowModal(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatus, error]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                customStatusOption(
                    status === RequestStatus.success,
                    error.data?.message,
                    'email setting has been save!',
                    'failed to save email setting'
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getList({skip: 0, take: 10}));
                setShowModal(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, emailError]);

    const toggleModal = () => {
        dispatch(getList({skip: 0, take: 10}));
        setShowModal((prevState) => !prevState);
        setJobId('');
    };

    const openModal = (_action: string, id: string) => {
        setShowModal(true);
        setJobId(id);
    };

    const closeUpdateModal = () => {
        setShowUpdateModal(false);
        setSelectedFielding({} as Fielding);
    };

    const openUpdateModal = (data: Fielding) => {
        setShowUpdateModal(true);
        setSelectedFielding(data);
    };

    const onCheckClicked = (data: Fielding) => {
        MySwal.fire({
            title: 'Available',
            text: 'Are you sure? You will not be able to recover this data!',
            input: 'text',
            inputLabel: 'Link',
            inputPlaceholder: 'Enter the Link',
            icon: 'warning' as SweetAlertIcon,
            showCancelButton: true,
            cancelButtonColor: darkGreen,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    updateAttachment({
                        id: data.id,
                        value: SupportingDocumentEnum.AVAILABLE,
                        link: result.value,
                        job_id: data.job_id,
                        type_id: data.type_id,
                        option: data.option,
                        employee_id: data.employee_id,
                        date_request: data.date_request,
                        note: data.note,
                    })
                )
            }
        });
    }

    const onResetClicked = () => {
        resetForm();
        refreshCache(undefined);
        gridRef.current.api.setFilterModel(null);
    }

    const onSubmit = (data: InputFieldingFilter) => {
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            filter: {
                filterType: "text",
                type: "contains",
                filter: data.filter
            },
            startRequestDateFilter: {
                filterType: "text",
                type: "contains",
                filter: parseDate(startRequestDate),
            },
            endRequestDateFilter: {
                filterType: "text",
                type: "contains",
                filter: parseDate(endRequestDate),
            },
            startDueDateFilter: {
                filterType: "text",
                type: "contains",
                filter: parseDate(startDueDate),
            },
            endDueDateFilter: {
                filterType: "text",
                type: "contains",
                filter: parseDate(endDueDate),
            },
        });
    }

    const onExportClicked = () => {
        dispatch(exportToCsv({
            startDate: parseDate(startRequestDate),
            endDate: parseDate(endRequestDate),
        }));
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-detail/${props.data?.job_id}`}
            className="underline text-blue-700"
        >
            {props.data?.job_number}
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetFielding, ActionEnum.ActionCheck) &&
                        <button
                            onClick={() =>
                                onCheckClicked(props.data)
                            }
                        >
                            <MdOutlineCheckCircleOutline className="mr-2 text-2xl text-primary-color"/>
                        </button>
                    }
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetFielding, ActionEnum.ActionLastCheck) &&
                        <button
                            onClick={() =>
                                openUpdateModal(props.data)
                            }
                        >
                            <AiOutlineHistory
                                className="mr-2 text-2xl text-primary-color"
                            />
                        </button>
                    }
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetFielding, ActionEnum.ActionCancel) &&
                        <button
                            onClick={() =>
                                openModal('cancel', props.data?.job_id)
                            }
                        >
                            <MdOutlineEmail className=" text-2xl text-primary-color"/>
                        </button>
                    }
            </div>
        )

    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
            sortable: false
        },
        {field: 'job_number_id', headerName: 'ID Number', sortable: false},
        {
            headerName: 'Job Number',
            field: 'job_number',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            sortable: false
        },
        {field: 'project', headerName: 'Project', sortable: false},
        {field: 'department_owner_name', headerName: 'Department Owner', sortable: false},
        {field: 'koj', headerName: 'KoJ', sortable: false},
        {
            field: 'date_request',
            headerName: 'Request Date',
            cellDataType: 'date',
            sortable: false
        },
        {field: 'job_status', headerName: 'Status', sortable: false},
        {field: 'type_name', headerName: 'Type', sortable: false},
        {field: 'district', headerName: 'District', sortable: false},
        {field: 'po_number', headerName: 'PO Number', sortable: false},
        {
            field: 'date_due',
            headerName: 'Due Date',
            cellDataType: 'date',
            sortable: false
        },
        {field: 'req_pole', headerName: 'Req Pole', sortable: false},
        {
            field: 'date_due_fielding',
            headerName: 'Fielding Due Date',
            cellDataType: 'date',
            sortable: false
        },
        {field: 'fielder', headerName: 'Fielder name', sortable: false},
        {field: 'note', headerName: 'Subject Notes', sortable: false},
        {
            field: 'date_follow_up',
            headerName: 'Follow up date',
            cellDataType: 'date_time',
            sortable: false
        },
        {field: 'last_check_user_name', headerName: 'Follow up user', sortable: false},
        {field: 'missing_fielding', headerName: 'Total Missing Fielding', sortable: false},
        {
            headerName: 'Filter',
            field: 'filter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date Start Filter',
            field: 'startRequestDateFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date End Filter',
            field: 'endRequestDateFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Due Date Start Filter',
            field: 'startDueDateFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Due Date End Filter',
            field: 'endDueDateFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            sortable: false
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                let _filter = params.request.filterModel?.filter?.filter ?? undefined;
                dispatch(
                    getList({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        filter: _filter,
                        startRequestDate: params.request.filterModel?.startRequestDateFilter?.filter ?? undefined,
                        endRequestDate: params.request.filterModel?.endRequestDateFilter?.filter ?? undefined,
                        startDueDate: params.request.filterModel?.startDueDateFilter?.filter ?? undefined,
                        endDueDate: params.request.filterModel?.endDueDateFilter?.filter ?? undefined,
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Divider>
                        <Divider.LeftContent>
                            <div>
                                <RangeDatePicker label={"Filter by Request Date"} startName='startRequestDate' endName='endRequestDate'
                                                 startValue={new Date(startRequestDate)} endValue={new Date(endRequestDate)}
                                                 control={control}/>
                                <RangeDatePicker label={"Filter by Due Date Fielding"} startName='startDueDate' endName='endDueDate'
                                                 startValue={new Date(startDueDate)} endValue={new Date(endDueDate)}
                                                 control={control}/>
                            </div>
                        </Divider.LeftContent>
                        <Divider.RightContent>
                            <div>
                                <InputText
                                    {...register('filter')}
                                    placeholder="Search by id number | job number | project | district | status | notes"
                                    label="Filter by ID Number | Job Number | Project | District | Status |  Notes"
                                />
                            </div>
                            <div className="flex items-center justify-end">
                                <div className="flex items-center justify-end">
                                    <div className={"ml-2"}>
                                        <Button title="Export" onClick={onExportClicked}/>
                                    </div>
                                    <div className={"mx-2"}>
                                        <Button
                                            color="success"
                                            type="button"
                                            title="Reset"
                                            onClick={onResetClicked}
                                        />
                                    </div>
                                    <Button
                                        color="success"
                                        type="submit"
                                        title="Filter"
                                    />
                                </div>
                            </div>
                        </Divider.RightContent>
                    </Divider>
                </form>
            </div>
            <div className="main-data-grid fielding mt-5">
                <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'job-data-fielding'} defaultPageSize={20} />
                <DefaultModal
                    title="E-Mail"
                    show={showModal}
                    onClose={toggleModal}
                    body={
                        <CreateEmail
                            jobId={jobId}
                            closeModal={toggleModal}
                        />
                    }
                />
                <DefaultModal
                    title="Update Fielding"
                    show={showUpdateModal}
                    onClose={closeUpdateModal}
                    body={
                        <FieldingUpdate isLoading={false} data={selectedFielding}/>
                    }
                />
            </div>
        </div>
    );
};

export default FieldingTable;
