import {
    CreatePosition,
    GetListRequest,
    Position,
    UpdatePosition,
} from './models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpClientError, RequestStatus } from 'http-client';
import { RootState } from 'app/store';
import PositionApi from './position.api';
import {Option} from "../../../components/SelectInput/BasicSelectInput";

export interface PositionSlice {
    list?: Array<Position>;
    options?: Array<Option>;
    optionByName?: Array<Option>;
    single?: Position;
    rows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getList = createAsyncThunk(
    'master/position/getList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PositionApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingle = createAsyncThunk(
    'master/position/getSingle',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PositionApi.getSingle(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createPosition = createAsyncThunk(
    'master/position/create',
    async (args: CreatePosition, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PositionApi.createPosition(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updatePosition = createAsyncThunk(
    'master/position/update',
    async (args: UpdatePosition, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PositionApi.updatePosition(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deletePosition = createAsyncThunk(
    'master/position/delete',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PositionApi.deletePosition(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const positionSlice = createSlice({
    name: 'positionState',
    initialState: {} as PositionSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.single = {} as Position;
        },
    },
    extraReducers: (builder) => {
        // get list data reducer
        builder.addCase(getList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, { payload }) => {
            state.rows = payload.rows;
            state.list = payload.data;
            state.options = payload.data.map((position: Position) => {
                return {
                    label: position.name,
                    text: position.name,
                    value: position.id
                }
            });
            state.optionByName = payload.data.map((position: Position) => {
                return {
                    label: position.name,
                    text: position.name,
                    value: position.name
                }
            });
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state) => {
            state.isLoading = false;
        });

        // get single data reducer
        builder.addCase(getSingle.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingle.fulfilled, (state, { payload }) => {
            state.single = payload?.data[0];
            state.isLoading = false;
        });
        builder.addCase(getSingle.rejected, (state) => {
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createPosition.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createPosition.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createPosition.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updatePosition.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updatePosition.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updatePosition.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deletePosition.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deletePosition.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deletePosition.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const { reset } = positionSlice.actions;
export default positionSlice.reducer;
