import {HttpClientError, RequestStatus} from "../../../../http-client";
import {
    AssignMRERequest,
    GetMREListRequest, GetMREMainWithFilterRequest,
    GetMREWithFilterRequest,
    MainMREModel,
    MREModel,
    MRESummary
} from "./models";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../../app/store";
import ProcessMREApi from "./processMRE.api";
import {KatapultCancelReq, KatapultWaitingReq} from "../katapult/models";

export interface ProcessMRESlice {
    MREList: Array<MREModel>;
    singleMRE: MREModel;
    MREMainList: Array<MainMREModel>;
    mreSummaryList: Array<MRESummary>;
    qcMRESummaryList: Array<MRESummary>;
    rows?: number;
    mainRows?: number;
    isLoading?: boolean;
    isSingleLoading?: boolean;
    isAssignLoading?: boolean;
    status?: RequestStatus;
    actionStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getMRE = createAsyncThunk(
    'process-mre/getMRE',
    async (args: GetMREListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.getMRE(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMREWithFilter = createAsyncThunk(
    'process-mre/getMREWithFilter',
    async (args: GetMREWithFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.getMREWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);


export const getSingleMRE = createAsyncThunk(
    'process-mre/getSingleMRE',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.getSingleMRE(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMRESummary = createAsyncThunk(
    'process-mre/getMRESummary',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.getMRESummary(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getQCMRESummary = createAsyncThunk(
    'process-mre/getQCMRESummary',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.getQCMRESummary(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainMRE = createAsyncThunk(
    'process-mre/getMainMRE',
    async (args: GetMREListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.getMainMRE(args, userAuth?.id as string, userAuth?.user_id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainMREWithFilter = createAsyncThunk(
    'process-mre/getMainMREWithFilter',
    async (args: GetMREMainWithFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.getMainMREWithFilter(args, userAuth?.id as string, userAuth?.user_id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const assignMRE = createAsyncThunk(
    'process-mre/assignMRE',
    async (args: AssignMRERequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.assignMRE(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const assignQCMRE = createAsyncThunk(
    'process-mre/assignQCMRE',
    async (args: AssignMRERequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.assignQCMRE(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const waitingMRE = createAsyncThunk(
    'process-mre/waitingMRE',
    async (args: KatapultWaitingReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.waitingMRE(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const cancelMRE = createAsyncThunk(
    'process-mre/cancelMRE',
    async (args: KatapultCancelReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessMREApi.cancelMRE(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const processMRESlice = createSlice({
    name: 'processMREState',
    initialState: {} as ProcessMRESlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
        },
    },
    extraReducers: (builder) => {
        // get mre reducer
        builder.addCase(getMRE.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMRE.fulfilled, (state, action) => {
            state.isLoading = false;
            state.MREList = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getMRE.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get mre reducer
        builder.addCase(getMREWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMREWithFilter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.MREList = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getMREWithFilter.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get single mre
        builder.addCase(getSingleMRE.pending, (state) => {
            state.isSingleLoading = true;
        });
        builder.addCase(getSingleMRE.fulfilled, (state, action) => {
            state.isSingleLoading = false;
            state.singleMRE = action.payload.data;
        });
        builder.addCase(getSingleMRE.rejected, (state, action) => {
            state.isSingleLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get mre reducer
        builder.addCase(getMRESummary.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMRESummary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mreSummaryList = action.payload.data;
        });
        builder.addCase(getMRESummary.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get mre reducer
        builder.addCase(getQCMRESummary.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQCMRESummary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.qcMRESummaryList = action.payload.data;
        });
        builder.addCase(getQCMRESummary.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get main mre reducer
        builder.addCase(getMainMRE.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainMRE.fulfilled, (state, action) => {
            state.isLoading = false;
            state.MREMainList = action.payload.data;
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainMRE.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get main mre reducer
        builder.addCase(getMainMREWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainMREWithFilter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.MREMainList = action.payload.data;
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainMREWithFilter.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // assign mre reducer
        builder.addCase(assignMRE.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isAssignLoading = true;
        });
        builder.addCase(assignMRE.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isAssignLoading = false;
        });
        builder.addCase(assignMRE.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isAssignLoading = false;
        });
        // assign qc mre reducer
        builder.addCase(assignQCMRE.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(assignQCMRE.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(assignQCMRE.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // waiting mre reducer
        builder.addCase(waitingMRE.pending, (state) => {
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(waitingMRE.fulfilled, (state) => {
            state.actionStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(waitingMRE.rejected, (state, { payload }) => {
            state.actionStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // cancel mre reducer
        builder.addCase(cancelMRE.pending, (state) => {
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(cancelMRE.fulfilled, (state) => {
            state.actionStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(cancelMRE.rejected, (state, { payload }) => {
            state.actionStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});

export const { reset } = processMRESlice.actions;
export default processMRESlice.reducer;