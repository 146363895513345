import React, {useCallback, useEffect, useRef, useState} from 'react';
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {deletePole, getPoleKatapultByJob, reset} from "../../../../basic/pole/pole.reducer";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import {Button} from "flowbite-react";
import {IoMdAdd} from "react-icons/io";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {deletedWarnOption, savedStatusOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import InputCheckbox from "../../../../../components/Checkbox/InputCheckbox";
import {BiEditAlt} from "react-icons/bi";
import {Pole} from "../../../../basic/pole/models";
import {MdOutlineDeleteForever} from "react-icons/md";
import AddKatapultPoleForm from "./AddKatapultPoleForm";
import {useParams} from "react-router-dom";

const AddKatapultPoleTable = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);
    const { id } = useParams();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [singlePole, setSinglePole] = useState<Pole>({} as Pole);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const isJobIdLoading = useAppSelector((state: RootState) => state.jobDetail.isLoading);
    const status = useAppSelector((state: RootState) => state.pole.status);
    const error = useAppSelector(
        (state: RootState) => state.pole.error ?? ({} as HttpClientError)
    );

    const IsPipCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <InputCheckbox
                    checked={props.data?.is_pip}
                    readOnly
                />
            </div>
        )
    }

    const IsFieldingCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <InputCheckbox
                    checked={props.data?.is_fielding}
                    readOnly
                />
            </div>
        )
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    <>
                        <button onClick={() => onEditClick(props.data)}>
                            <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                        </button>
                        <button
                            onClick={() =>
                                onDeleteClicked(props.data?.id)
                            }
                        >
                            <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                        </button>
                    </>
                }
            </div>
        )
    }

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletePole(id));
            }
        });
    };

    const onEditClick = (data: Pole) => {
        setSinglePole(data);
        setIsEdit(true);
        setShowModal(true);
    }

    const [columnDefs, _setColumnDefs] = useState([
        // {
        //     headerName: '#',
        //     field: 'index',
        //     cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        //     suppressSizeToFit: true,
        //     // checkboxSelection: true,
        //     // showDisabledCheckboxes: true,
        //     sortable: false,
        // },
        {field: 'index', headerName: '#', sortable: false},
        {field: 'seq', headerName: 'Pole Seq', sortable: false},
        {field: 'no', headerName: 'Pole No', sortable: false},
        {field: 'address', headerName: 'Pole Address', sortable: false},
        {field: 'is_pip', headerName: 'PIP', cellRenderer: IsPipCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }), sortable: false},
        {field: 'latitude', headerName: 'Latitude', sortable: false},
        {field: 'longitude', headerName: 'Longitude', sortable: false},
        {field: 'type_name', headerName: 'Pole Type', sortable: false},
        {field: 'is_fielding', headerName: 'Fielding', cellRenderer: IsFieldingCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }), sortable: false},
        {field: 'notes', headerName: 'Notes Fielding', sortable: false},
        {field: 'status_name', headerName: 'Pole Status', sortable: false},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            sortable: false
        },
    ]);

    const onSelectionChanged = useCallback(() => {
        let selectedRows = gridRef.current.api.getSelectedNodes();
        let _selectedRows = selectedRows.map((item: any) => item.data);
        // setSelection(_selectedRows);
        console.log(_selectedRows);
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                refreshCache(undefined);
                dispatch(reset());
                closeModal();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const closeModal = () => {
        setShowModal(false);
        setIsEdit(false);
        setSinglePole({} as Pole);
    };

    const getServerSideDataSource = (katapultId: string) => {
        return {
            getRows: (params: any) => {
                dispatch(
                    getPoleKatapultByJob({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        job_id: katapultId as string,
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                    Katapult Pole
                </h1>
            </div>
            <div className="main-data-grid mt-5">
                {
                    !isJobIdLoading && (
                        <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={() => getServerSideDataSource(id as string)}
                                                isRowSelectable={() => true} onSelectionChanged={onSelectionChanged}
                                                isCustomHeader={true}
                                                customChildHeaderComponent={
                                                    <>
                                                        <Button
                                                            onClick={() => {
                                                                setShowModal(true);
                                                                setIsEdit(false);
                                                            }}
                                                            size="xs"
                                                            color="success"
                                                        >
                                                            <IoMdAdd className="mr-2 h-5 w-5"/>
                                                            Add New Pole
                                                        </Button>
                                                    </>
                                                }
                        />
                    )
                }
            </div>
            <DefaultModal
                title={`${isEdit ? 'Update' : 'Add'} Katapult Pole`}
                show={showModal}
                onClose={closeModal}
                body={
                    <AddKatapultPoleForm isEdit={isEdit} singlePole={singlePole}/>
                }
            />
        </div>
    );
};

export default AddKatapultPoleTable;
