import React, {useCallback, useEffect, useRef, useState} from 'react';
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {
    changeStatusMultiplePole,
    deletePole,
    getPoleMREByJob,
    removeMultiplePoleAssign,
    reset
} from "../../../../basic/pole/pole.reducer";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {JobID} from "../../jobDetail/models";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import AddMREPoleForm from "./AddMREPoleForm";
import {Button, Dropdown} from "flowbite-react";
import {IoMdAdd} from "react-icons/io";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {customWarnOption, deletedWarnOption, savedStatusOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import InputCheckbox from "../../../../../components/Checkbox/InputCheckbox";
import {BiEditAlt} from "react-icons/bi";
import {Pole, PoleAssignmentTypeEnum} from "../../../../basic/pole/models";
import {MdOutlineDeleteForever} from "react-icons/md";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";
import {AiOutlineMenu} from "react-icons/ai";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import {getList as getPoleStatusList} from "../../../../master/poleStatus/poleStatus.reducer";
import {useParams} from "react-router-dom";

const AddMREPoleTable = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);
    const { id } = useParams();

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const poleStatusList = useAppSelector(
        (state: RootState) => state.poleStatus.list ?? []
    );

    const [selection, setSelection] = useState<Array<string>>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [singlePole, setSinglePole] = useState<Pole>({} as Pole);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const isJobIdLoading = useAppSelector((state: RootState) => state.jobDetail.isLoading);
    const status = useAppSelector((state: RootState) => state.pole.status);
    const error = useAppSelector(
        (state: RootState) => state.pole.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        dispatch(getPoleStatusList({skip: 0, take: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const IsPipCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <InputCheckbox
                    checked={props.data?.is_pip}
                    readOnly
                />
            </div>
        )
    }

    const IsFieldingCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <InputCheckbox
                    checked={props.data?.is_fielding}
                    readOnly
                />
            </div>
        )
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    <>
                        <button onClick={() => onEditClick(props.data)}>
                            <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                        </button>
                        <button
                            onClick={() =>
                                onDeleteClicked(props.data?.id)
                            }
                        >
                            <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                        </button>
                    </>
                }
            </div>
        )
    }

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletePole(id));
            }
        });
    };

    const onEditClick = (data: Pole) => {
        setSinglePole(data);
        setIsEdit(true);
        setShowModal(true);
    }


    const [columnDefs, _setColumnDefs] = useState([
        // {
        //     headerName: '#',
        //     field: 'index',
        //     cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        //     suppressSizeToFit: true,
        //     checkboxSelection: true,
        //     showDisabledCheckboxes: true,
        //     sortable: false,
        // },
        {field: 'index', headerName: '#', sortable: false},
        {field: 'seq', headerName: 'Pole Seq', sortable: false},
        {field: 'no', headerName: 'Pole No', sortable: false},
        {field: 'address', headerName: 'Pole Address', sortable: false},
        {
            field: 'is_pip', headerName: 'PIP', cellRenderer: IsPipCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }), sortable: false
        },
        {field: 'latitude', headerName: 'Latitude', sortable: false},
        {field: 'longitude', headerName: 'Longitude', sortable: false},
        {field: 'type_name', headerName: 'Pole Type', sortable: false},
        {
            field: 'is_fielding', headerName: 'Fielding', cellRenderer: IsFieldingCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }), sortable: false
        },
        {field: 'notes', headerName: 'Notes Fielding', sortable: false},
        {field: 'status_name', headerName: 'Pole Status', sortable: false},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            sortable: false
        },
    ]);

    const onSelectionChanged = useCallback(() => {
        let selectedRows = gridRef.current.api.getSelectedNodes();
        let _selectedRows = selectedRows.map((item: any) => item.data.id);
        setSelection(_selectedRows);
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                refreshCache(undefined);
                dispatch(reset());
                closeModal();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const closeModal = () => {
        setShowModal(false);
        setIsEdit(false);
        setSinglePole({} as Pole);
    };

    const getServerSideDataSource = (mreId: string) => {
        return {
            getRows: (params: any) => {
                dispatch(
                    getPoleMREByJob({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        job_id: mreId as string,
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    const onMultipleChangeStatus = (status: string) => {
        if (isArrayEmpty(selection)) return null;
        let poleIds = selection.toString();
        MySwal.fire(
            customWarnOption(
                'Change Status Multiple Data',
                `Are you sure you want to change status of ${selection.length} items?`
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    changeStatusMultiplePole({
                        pole_ids: poleIds,
                        status_id: status,
                    })
                );
            }
        });
    }

    return (
        <div>
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                    MRE Pole
                </h1>
            </div>
            <div className="main-data-grid mt-5">
                {
                    !isJobIdLoading && (
                        <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                                getServerSideDataSource={() => getServerSideDataSource(id as string)}
                                                isRowSelectable={() => true} onSelectionChanged={onSelectionChanged}
                                                isCustomHeader={true}
                                                customChildHeaderComponent={
                                                    <>

                                                        {
                                                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetMRE, ActionEnum.Input) &&
                                                            <Button
                                                                onClick={() => {
                                                                    setShowModal(true);
                                                                    setIsEdit(false);
                                                                }}
                                                                size="xs"
                                                                color="success"
                                                            >
                                                                <IoMdAdd className="mr-2 h-5 w-5"/>
                                                                Add New Pole
                                                            </Button>
                                                        }
                                                        {
                                                            <>
                                                                <div className={"ml-2"}>
                                                                    <Dropdown
                                                                        color="light"
                                                                        label={<AiOutlineMenu
                                                                            className="text-xl text-light"/>}
                                                                        arrowIcon={false}
                                                                    >
                                                                        <Dropdown.Header><span className="block text-sm font-medium truncate">Multiple Action</span>
                                                                        </Dropdown.Header>
                                                                        {
                                                                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.ChangePoleStatus) &&
                                                                            <>
                                                                                {poleStatusList.map((status) => {
                                                                                        // if (status.name === 'Already Created' || status.name === 'Not Created' || status.name === 'Data Base Pole')
                                                                                            return (
                                                                                                <Dropdown.Item
                                                                                                    key={status.id}
                                                                                                    onClick={() =>
                                                                                                        onMultipleChangeStatus(status.id)
                                                                                                    }
                                                                                                ><span className={`${
                                                                                                    isArrayEmpty(selection) &&
                                                                                                    'text-gray-400'
                                                                                                }`}>{status.name}</span></Dropdown.Item>
                                                                                            );
                                                                                    }
                                                                                )}
                                                                            </>
                                                                        }

                                                                        {/*{*/}
                                                                        {/*    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.Delete) &&*/}
                                                                        {/*    <>*/}
                                                                        {/*        <Dropdown.Divider/>*/}
                                                                        {/*        <Dropdown.Item onClick={onMultipleDelete}>*/}
                                                                        {/*            <span*/}
                                                                        {/*                className={`${isArrayEmpty(selection) && 'text-gray-400'}`}>Delete</span>*/}
                                                                        {/*        </Dropdown.Item>*/}
                                                                        {/*    </>*/}
                                                                        {/*}*/}

                                                                    </Dropdown>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
                        />
                    )
                }
            </div>
            <DefaultModal
                title={`${isEdit ? 'Update' : 'Add'} MRE Pole`}
                show={showModal}
                onClose={closeModal}
                body={
                    <AddMREPoleForm isEdit={isEdit} singlePole={singlePole}/>
                }
            />
        </div>
    );
};

export default AddMREPoleTable;
