import React from 'react';
import JobCheckTable from "../components/JobCheckTable";
import JobHoldTable from "../components/JobHoldTable";
import JobCancelTable from "../components/JobCancelTable";

const JobCheckPage = () => {
    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="mb-5">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Check Jobs</h1>
                </div>
                <div className="mt-3">
                    <JobCheckTable/>
                </div>
            </div>
            <div className="mb-5">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Hold Jobs</h1>
                </div>
                <div className="mt-3">
                    <JobHoldTable/>
                </div>
            </div>
            <div className="mb-5">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Cancel
                        Jobs</h1>
                </div>
                <div className="mt-3">
                    <JobCancelTable />
                </div>
            </div>
        </div>
    );
};

export default JobCheckPage;
