import React, {useEffect, useState} from 'react';
import { TableColumn } from 'react-data-table-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';

import { District } from '../models';
import { HttpClientError, RequestStatus } from 'http-client';
import { BiEditAlt } from 'react-icons/bi';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { deleteDistrict, getList, reset, setSingle } from '../district.reducer';
import {
    deletedStatusOption,
    deletedWarnOption,
} from '../../../../helpers/alertLabel';
import DataTableServerPaging from "../../../../components/Table/DataTableServerPaging";
import AgDataGridStatic from "../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";

const DistrictTable = ({ ...props }: DistrictTableProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const rows = useAppSelector((state: RootState) => state.district.rows ?? 0);
    const districtList = useAppSelector(
        (state: RootState) => state.district.list ?? []
    );
    const status = useAppSelector((state: RootState) => state.district.status);
    const error = useAppSelector(
        (state: RootState) => state.district.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        dispatch(getList({ skip: 0, take: 1000 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getList({ skip: 0, take: 1000 }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteDistrict(id));
            }
        });
    };

    const openFormModal = (single: District) => {
        props.openModal(true);
        dispatch(setSingle(single));
    };

    const columns: TableColumn<District>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'District Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Project Name',
            selector: (row) => row.project_names,
            sortable: true,
        },
        {
            name: 'Action',
            width: '220px',
            cell: (row) => (
                <>
                    <button onClick={() => openFormModal(row)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color" />
                    </button>
                    <button onClick={() => onDeleteClicked(row.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color" />
                    </button>
                </>
            ),
        },
    ];

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex align-middle h-[100%]">
                <>
                    <button onClick={() => openFormModal(props.data)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color" />
                    </button>
                    <button onClick={() => onDeleteClicked(props.data?.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color" />
                    </button>
                </>
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'name', headerName: 'District Name', filter: 'agTextColumnFilter'},
        {field: 'project_names', headerName: 'Project Name', filter: 'agTextColumnFilter'},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const handlePagingChange = (rowsPerPage: number, page: number) => {
        dispatch(
            getList({
                skip: rowsPerPage * (page - 1),
                take: rowsPerPage,
            })
        );
    }

    return (
        <div className="main-data-grid mt-5">
            <AgDataGridStatic columnDefs={columnDefs} rowData={districtList} defaultColDef={{
                sortable: true,
                resizable: true,
                minWidth: 10,
                wrapHeaderText: true,
                wrapText: true,
                autoHeight: true,
                flex: 1,
            }}/>
        </div>
    );
};

export interface DistrictTableProps {
    openModal: (isEdit: boolean) => void;
}

export default DistrictTable;
