import React from 'react';
import ReactTable from "../../../../components/Table/ReactTable";
import EditableCell from "../../../../components/Table/EditableCell";
import {OvertimeModel} from "../../models";

const OvertimeNotesDetail = ({data = {} as OvertimeModel}: OvertimeNotesDetailProps) => {

    const columns = [
        {
            Header: 'NO.',
            width: 50,
            Cell: ({row: {index}}: any) => {
                return <div>{index + 1}</div>;
            },
        },
        {
            Header: 'Pole',
            minWidth: 150,
            accessor: 'pole',
            Cell: EditableCell,
        },
        {
            Header: 'Work',
            minWidth: 150,
            accessor: 'work',
            Cell: EditableCell,
        },
        {
            Header: 'Note',
            minWidth: 150,
            accessor: 'note',
            Cell: EditableCell,
        },
    ];

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <ReactTable
                columns={columns}
                data={data?.details ?? []}
            />
        </div>
    );
};

export interface OvertimeNotesDetailProps {
    data?: OvertimeModel;
}

export default OvertimeNotesDetail;
