import React, {useEffect, useState} from 'react';
// import Button from "../../../components/Button/Button";
import AgDataGrid from "../../../components/DataGrid/AgDataGrid";
import {filterParams} from "../../../helpers/agTable";
import {useAppDispatch, useAppSelector} from "../../../helpers/redux";
import {getDashboardOcalc} from "../dashboard.reducer";

const DashboardJob = () => {
    const dispatch = useAppDispatch();

    const dashboardJob = useAppSelector(state => state.dashboard.dashboardJobList ?? []);

    useEffect(() => {
        dispatch(getDashboardOcalc());
    }, []);


    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([
        {field: 'project', headerName: 'Project', filter: true},
        {field: 'team', headerName: 'Team', filter: true},
        {field: 'koj', headerName: 'KoJ', filter: true},
        {field: 'request_date', headerName: 'Request Date', filter: 'agDateColumnFilter', filterParams: filterParams, cellDataType: 'date'},
        {field: 'number_id', headerName: 'ID Number', filter: 'agTextColumnFilter'},
        {field: 'number_job', headerName: 'Job Number', filter: 'agTextColumnFilter'},
        {field: 'status', headerName: 'Status', filter: true},
        {field: 'type', headerName: 'Type', filter: true},
        {field: 'district', headerName: 'District'},
        {field: 'department_name', headerName: 'Department', filter: 'agTextColumnFilter'},
        {field: 'department_owner_name', headerName: 'Department Owner', filter: 'agTextColumnFilter'},
        {field: 'sales_order', headerName: 'Sales Order'},
        {field: 'other_number', headerName: 'Other Number'},
        {field: 'po_number', headerName: 'PO Number'},
        {field: 'due_date', headerName: 'Due Date', filter: 'agDateColumnFilter', filterParams: filterParams, cellDataType: 'date'},
        {field: 'request_pole', headerName: 'Req Pole'},
    ]);

    // const onExportClicked = () => {
    //
    // }

    return (
        <div>
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Jobs</h1>
                <div>
                    <div className='inline'>
                        {/*<Button title="Export" onClick={onExportClicked} />*/}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <AgDataGrid rowData={dashboardJob} columnDefs={columnDefs} supportSaveTableState tableStateName={'dashboard-job'} defaultPageSize={20}/>
                </div>
            </div>
        </div>
    );
};

export default DashboardJob;
