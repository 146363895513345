import {ComponentPropsWithRef, FC, forwardRef} from 'react';

type InputCheckboxProps = {
    label?: string;
    id?: string;
    message?: string;
    disabled?: boolean;
    picked?: boolean;
};

type InputCheckboxType = FC<
    InputCheckboxProps & ComponentPropsWithRef<'input'>
>;

const InputCheckbox: InputCheckboxType = forwardRef((props, ref) => {
    const {label = '', id = '', disabled = false, picked = false, ...rest} = props;

    return (
        <div className="flex items-center gap-2">
            <input
                type="checkbox"
                id={id}
                ref={ref}
                {...rest}
                disabled={disabled}
                className={`h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:border-primary-color 
                focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-color ${disabled && "checked:bg-gray-400 focus:bg-gray-400"} ${picked ? "checked:bg-gray-400 focus:bg-gray-400" : "checked:bg-primary-color focus:bg-primary-color"}`}
            />
            <label
                className={`text-sm font-medium text-gray-900 dark:text-gray-300 ${disabled && "text-gray-400 dark:text-gray-500"}`}
                htmlFor={id}
            >
                {label}
            </label>
        </div>
    );
});

export default InputCheckbox;