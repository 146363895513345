import React, {useState, useMemo, useEffect} from 'react';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    Circle,
} from '@react-google-maps/api';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import '../styles.css';
import {Libraries} from '@react-google-maps/api/dist/utils/make-load-script-url';
import {Button as FlowbiteButton} from 'flowbite-react';
import {
    isArrayEmpty,
    isObjectEmpty,
} from 'helpers/checkEmptiness';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';
import {Spinner} from 'flowbite-react';
import InputText from 'components/TextInput/InputText';
import {getPoleByRadius, resetPoleByRadius} from "../../../../basic/pole/pole.reducer";
import {RiRefreshLine} from "react-icons/ri";
import {FiSave} from "react-icons/fi";

type Location = {
    lat: number;
    lng: number;
};

export default function Places({
                                   defaultCoordinate = {} as Location,
                                   isMapEditable = false,
                                   ...props
                               }: PlacesModel) {
    const libraries: Libraries = ['places'];
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs',
        libraries: libraries,
    });
    console.log(defaultCoordinate);
    if (!isLoaded) return <div>Loading...</div>;
    return (
        <Map
            onCoordinateChange={props.onCoordinateChange}
            defaultCoordinate={defaultCoordinate}
            isMapEditable={isMapEditable}
        />
    );
}

function Map({defaultCoordinate = {} as Location, isMapEditable = false, ...props}: MapModel) {
    const [isCoordinateSearch, setIsCoordinateSearch] =
        useState<boolean>(true);
    const [isCoordinateError, setIsCoordinateError] = useState<boolean>(false);
    const [isWaitingCoordinate, setIsWaitingCoordinate] = useState<boolean>(false);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));
    const [selected, setSelected] = useState<Location | undefined>(undefined);
    const [address, setAddress] = useState<string>("");
    const center = useMemo(
        () => selected ?? undefined,
        [selected]
    );
    const dispatch = useAppDispatch();
    const poleByRadius = useAppSelector(
        (state: RootState) => state.pole.poleByRadius ?? []
    );
    const isLoading = useAppSelector(
        (state: RootState) => state.jobPrep.isLoading
    );
    const isPoleLoading = useAppSelector(
        (state: RootState) => state.pole.isPoleRadiusLoading
    );
    const toggleCoordinateSearch = () => {
        setIsCoordinateSearch((prevState) => !prevState);
    };

    useEffect(() => {
        if (!isObjectEmpty(defaultCoordinate)) {
            console.log('defaultCoordinat', defaultCoordinate);
            setSelected({
                lat: isNaN(defaultCoordinate.lat) ? 36.179588607678525 : defaultCoordinate.lat === 0 ? 36.179588607678525 : defaultCoordinate.lat,
                lng: isNaN(defaultCoordinate.lng) ? -115.1816481995731 : defaultCoordinate.lng === 0 ? -115.1816481995731 : defaultCoordinate.lng
            });
            if (defaultCoordinate.lat !== 0 && defaultCoordinate.lng !== 0) {
                dispatch(
                    getPoleByRadius({
                        latitude: isNaN(defaultCoordinate.lat) ? 36.179588607678525 : defaultCoordinate.lat,
                        longitude: isNaN(defaultCoordinate.lng) ? -115.1816481995731 : defaultCoordinate.lng,
                        radius: 0.5,
                    })
                );
            }

        }
    }, [defaultCoordinate]);

    const getAddresses = async (selected: Location) => {
        let addresses = await getGeocode({location: {lat: selected.lat, lng: selected.lng}});
        return addresses[0].formatted_address;
    }

    useEffect(() => {
        if (selected !== undefined) {
            getAddresses(selected).then((_address) => {
                setAddress(_address)
            });
        }

    }, [selected]);

    const onMarkerClicked = (lat: number, lng: number) => {
        dispatch(resetPoleByRadius());
        dispatch(
            getPoleByRadius({
                latitude: lat,
                longitude: lng,
                radius: 0.5,
            })
        );
        setSelected({
            lat,
            lng,
        });
    };

    const onCoordinateSearchChange = (e: any) => {
        let inputValue = e.target.value;
        clearTimeout(timer);
        setIsWaitingCoordinate(true);
        setTimer(
            setTimeout(() => {
                let splitted = inputValue.split(',');
                if (splitted.length === 2) {
                    if (splitted.some((item: string) => isNaN(parseFloat(item)))) {
                        setIsCoordinateError(true);
                        setIsWaitingCoordinate(false);
                    } else {
                        setIsCoordinateError(false);
                        setIsWaitingCoordinate(false);
                        setSelected({
                            lat: parseFloat(splitted[0]),
                            lng: parseFloat(splitted[1]),
                        });
                        dispatch(resetPoleByRadius());
                        dispatch(
                            getPoleByRadius({
                                latitude: parseFloat(splitted[0]),
                                longitude: parseFloat(splitted[1]),
                                radius: 0.5,
                            })
                        );
                    }
                } else {
                    setIsCoordinateError(true);
                    setIsWaitingCoordinate(false);
                }

            }, 1000)
        );
    }

    return (
        <>
            <div className="flex justify-center my-2 absolute z-[99] left-[14%] top-[11.5%]">
                <div className="inline py-2">
                    <FlowbiteButton color="gray" onClick={toggleCoordinateSearch}>
                        <RiRefreshLine className={"mr-2"}/>
                        {isCoordinateSearch
                            ? 'Search By Place'
                            : 'Search By Coordinate'}
                    </FlowbiteButton>
                </div>

                {isCoordinateSearch ? (
                    <>
                        <div className={'mx-2 w-[300px]'}>
                            <InputText onChange={onCoordinateSearchChange}
                                       placeholder="Input latitude, longitude (exp: xxxx, xxxx)"
                                       message={!isWaitingCoordinate && isCoordinateError ? "Wrong coordinate format" : ""}
                                       customMessageClass={"text-white"}/>
                            {
                                isWaitingCoordinate ? <>
                                    <Spinner
                                        color="success"
                                        aria-label="Success spinner example"
                                        size={'xs'}
                                    />
                                    <small className="ml-2 text-white">
                                        Waiting for coordinate format ...
                                    </small></> : <></>
                            }
                        </div>

                    </>
                ) : (
                    <div className='mx-2 py-2 '>
                        <div className="places-container">
                            <PlacesAutocomplete setSelected={setSelected}/>
                        </div>
                    </div>
                )}

                <div className="inline py-2">
                    {
                        isMapEditable &&
                        <FlowbiteButton color="gray" onClick={() => props.onCoordinateChange(selected, address)}>
                            <FiSave className={"mr-2"}/>
                            Save Coordinate
                        </FlowbiteButton>
                    }
                </div>
            </div>
            {isPoleLoading && (
                <div className={`absolute z-[9999] top-[18%] right-[10%]`}>
                    <Spinner
                        color="success"
                        aria-label="Success spinner example"
                        size={'xs'}
                    />
                    <small className="ml-2 font-medium italic text-white">
                        Getting Nearest Poles
                    </small>
                </div>
            )}
            {(!isPoleLoading && isArrayEmpty(poleByRadius)) && (
                <div className={`absolute z-[999] top-[18%] right-[10%]`}>
                    <small className="ml-2 font-medium italic text-white">
                        No Nearest Poles Available
                    </small>
                </div>
            )}

            <div className="h-[100vh]">
                <GoogleMap
                    zoom={25}
                    center={center}
                    mapContainerClassName="map-container"
                    mapTypeId={'satellite'}
                    onClick={(e) =>
                        onMarkerClicked(
                            e.latLng?.lat() ?? 43.45,
                            e.latLng?.lng() ?? -80.49
                        )
                    }
                >
                    {selected && <Marker position={selected}/>}
                    {selected && (
                        <Circle
                            center={selected}
                            radius={500}
                            onClick={(e) =>
                                onMarkerClicked(
                                    e.latLng?.lat() ?? 43.45,
                                    e.latLng?.lng() ?? -80.49
                                )
                            }
                        />
                    )}
                    {!isArrayEmpty(poleByRadius) &&
                        poleByRadius.map((item, index) => (
                            <Marker
                                position={{
                                    lat: item.latitude ?? 0,
                                    lng: item.longitude ?? 0,
                                }}
                                label={{
                                    text: `Pole - ${item.no} (Seq: ${item.seq})`,
                                    className: 'marker-label',
                                }}
                                // onClick={(e) => window.open(`/worksheet/ocalc/job-prep/${item.job_id}`, '_blank')}
                                onClick={(e) => window.open(item?.koj === "MRE" ? `/worksheet/mre/mre-detail/${item.job_id}`
                                    : item?.koj === "Katapult" ? `/worksheet/katapult/katapult-detail/${item.job_id}`
                                        : `/worksheet/ocalc/job-detail/${item.job_id}`, '_blank')}
                            />
                        ))}
                </GoogleMap>
            </div>
        </>
    );
}

const PlacesAutocomplete = ({setSelected}: any) => {
    const {
        ready,
        value,
        setValue,
        suggestions: {status, data},
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address: any) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({address});
        const {lat, lng} = await getLatLng(results[0]);
        setSelected({lat, lng});
    };

    return (
        <Combobox onSelect={handleSelect}>
            <ComboboxInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                className="combobox-input"
                placeholder="Search an address"
            />
            <ComboboxPopover style={{zIndex: 3000}}>
                <ComboboxList>
                    {status === 'OK' &&
                        data.map(({place_id, description}) => (
                            <ComboboxOption
                                key={place_id}
                                value={description}
                            />
                        ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    );
};

interface MapModel {
    defaultCoordinate?: Location;
    onCoordinateChange: (coordinate: Location | undefined, address: string) => void;
    isMapEditable?: boolean;
}

export interface PlacesModel {
    defaultCoordinate?: Location;
    onCoordinateChange: (coordinate: Location | undefined, address: string) => void;
    isMapEditable?: boolean;
}
