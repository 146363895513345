import {createSlice} from "@reduxjs/toolkit";


export interface SidebarSlice {
    dynamicNavId?: string;
}

const sidebarSlice = createSlice({
    name: 'sidebarState',
    initialState: {} as SidebarSlice,
    reducers: {
        setDynamicNavId: (state, payload) => {
            state.dynamicNavId = payload.payload;
        }
    }
});

export const {setDynamicNavId} = sidebarSlice.actions;
export default sidebarSlice.reducer;