import React from 'react';
import QCTable from "../components/QCTable";
import MainQCTable from "../components/MainQCTable";

const QCPage = () => {
    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">QC
                    </h1>
            </div>
            <div className="mt-3">
                <QCTable />
                <MainQCTable />
            </div>
        </div>
    );
};

export default QCPage;
