import React, { useEffect, useState } from 'react';
import Text from 'components/Text/Text';
import Button from 'components/Button/Button';
import {
    getJobFile,
    getSingle
} from 'features/worksheet/process/job/job.reducer';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { Job } from 'features/worksheet/process/job/models';
import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import Divider from 'components/Layout/Divider';
import DefaultModal from '../../../../../components/Modal/DefaultModal';
import JobLinkHistory from './JobLinkHistory';
import JobEmailHistory from "./JobEmail";
import JobFile from "../../../process/job/components/JobFile";
import {useParams} from "react-router-dom";

const JobDetail = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
    const [showEmailModal, setShowEmailModal] = useState<boolean>(false);

    const singleState = useAppSelector(
        (state: RootState) => state.job.single ?? ({} as Job)
    );
    const jobFile = useAppSelector(
        (state: RootState) => state.job.jobFile ?? []
    );

    useEffect(() => {
        if (id && id !== "") {
            dispatch(getSingle(id as string));
            dispatch(getJobFile(id as string));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const toggleLinkModal = () => {
        setShowLinkModal((prevState) => !prevState);
    };

    const toggleEmailModal = () => {
        setShowEmailModal((prevState) => !prevState);
    };

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <h1 className="font-medium text-gray-700 dark:text-gray-300">
                JOB - {singleState.number_job}
            </h1>
            <Divider>
                <Divider.LeftContent>
                    <div>
                        <Text
                            label="Request Date"
                            value={new Date(
                                singleState.ts_created
                            ).toLocaleDateString('en-GB')}
                        />
                        <Text label="ID Number" value={singleState.number_id} />
                        <Text
                            label="Job Number"
                            value={singleState.number_job}
                        />
                        <Text
                            label="Other Number"
                            value={singleState.other_number === "" ? "-" : singleState.other_number ?? "-"}
                        />
                        <Text
                            label="Sales Order"
                            value={singleState.sales_order}
                        />
                        <Text
                            label="Fielding Due Date"
                            value={new Date(
                                singleState.date_due_fielding
                            ).toLocaleDateString('en-GB')}
                        />
                        <Text label="Fielder" value={singleState.fielder} />
                        <Text label="Category" value={'category'} />
                        <Text label="Type" value={singleState.type_name} />
                        <Text
                            label="District"
                            value={singleState.district_name}
                        />
                        <Text
                            label="PO Number"
                            value={singleState.po_number === "" ? "-" : singleState.po_number ?? "-"}
                        />
                        <Text label="Requestor" value={singleState.requestor} />
                        <Text
                            label="Req Pole"
                            value={singleState.pole_total?.toString()}
                        />
                        <Text label="KoJ" value={singleState.kind_name} />
                        <Text label="Subject" value={'No Data'} />
                        {/*{singleState.pause_subject_name !== null && (*/}
                        {/*    <div className="my-3 mr-5">*/}
                        {/*        <Alert*/}
                        {/*            color="warning"*/}
                        {/*            additionalContent={*/}
                        {/*                <React.Fragment>*/}
                        {/*                    <div>*/}
                        {/*                        <div className="text-sm text-yellow-700 dark:text-yellow-800">*/}
                        {/*                            Date:{' '}*/}
                        {/*                            {new Date(*/}
                        {/*                                singleState.pause_ts*/}
                        {/*                            ).toLocaleDateString('en-GB')}*/}
                        {/*                        </div>*/}
                        {/*                        <div className="text-sm text-yellow-700 dark:text-yellow-800">*/}
                        {/*                            Notes: {singleState.pause_note}*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </React.Fragment>*/}
                        {/*            }*/}
                        {/*            icon={HiInformationCircle}*/}
                        {/*        >*/}
                        {/*            <h3 className="text-sm font-medium text-yellow-700 dark:text-yellow-800">*/}
                        {/*                Waiting Notes*/}
                        {/*            </h3>*/}
                        {/*        </Alert>*/}
                        {/*    </div>*/}
                        {/*)}*/}

                        <div className="my-3 mr-5">
                            <Alert
                                color="warning"
                                additionalContent={
                                    <React.Fragment>
                                        <div>
                                            <div className="text-sm text-yellow-700 dark:text-yellow-800">
                                                Date:{' '}
                                                {new Date().toLocaleDateString(
                                                    'en-GB'
                                                )}
                                            </div>
                                            <div className="text-sm text-yellow-700 dark:text-yellow-800">
                                                Notes: No Data
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                icon={HiInformationCircle}
                            >
                                <h3 className="text-sm font-medium text-yellow-700 dark:text-yellow-800">
                                    Waiting Notes
                                </h3>
                            </Alert>
                        </div>

                        <Text label="Notes" value={'No Data'} />
                    </div>
                </Divider.LeftContent>
                <Divider.CenterLine />
                <Divider.RightContent>
                    <div>
                        <Text
                            label="Due Date"
                            value={new Date(
                                singleState.date_due
                            ).toLocaleDateString('en-GB')}
                        />
                        <Text
                            label="Project"
                            value={singleState.project_name}
                        />
                        <Text label="Department" value={singleState.department_name} />
                        <Text label="Department Owner" value={singleState.department_owner_name} />
                        <Text label="Sources" value={singleState.source_name} />
                        <Text label="Job Prep" value={singleState.employee_prep_user ?? "-"} />
                        <Text label="CSQ" value={singleState.employee_cs_user ?? "-"} />
                        <Text label="QC" value={singleState.employee_qc_user ?? "-"} />
                        <Text label="MRE" value={singleState.employee_mre_user ?? "-"} />
                        <Text label="QC MRE" value={singleState.employee_qc_mre_user ?? "-"} />
                        <Text label="Status" value={singleState.status_name} />
                        <Text
                            label="Completed Date"
                            value={(singleState.complete_date !== undefined && singleState.complete_date !== "0001-01-01T00:00:00") ? new Date(
                                singleState.complete_date
                            ).toLocaleDateString('en-GB') : '-'}
                        />
                        <JobFile uploadedFile={jobFile} isReadOnly/>
                        <div className="my-3">
                            <Button title="Email History" onClick={toggleEmailModal} />
                        </div>
                        <div className="my-3">
                            <Button title="Link" onClick={toggleLinkModal} />
                        </div>
                    </div>
                </Divider.RightContent>
            </Divider>

            <DefaultModal
                title="Link"
                show={showLinkModal}
                onClose={toggleLinkModal}
                body={<JobLinkHistory jobId={id} />}
            />
            <DefaultModal
                title="Email History"
                show={showEmailModal}
                onClose={toggleEmailModal}
                body={<JobEmailHistory jobId={id} />}
            />
        </div>
    );
};

export default JobDetail;
