import React, {useEffect, useState} from 'react';
import {InputStatusModel} from "features/worksheet/process/job/models";
import {Link} from "react-router-dom";
import {
    MdOutlineCancel,
    MdOutlineCheckCircleOutline,
} from "react-icons/md";
import {BiEditAlt} from "react-icons/bi";
import DefaultModal from "components/Modal/DefaultModal";
import JobChangeStatus from "features/worksheet/process/job/components/JobChangeStatus";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {getList, pendingJob, reset} from "../jobCheck.reducer";
import {customStatusOption, customWarnOption} from "helpers/alertLabel";
import {cancelJob, checkJob, reset as resetJob} from "features/worksheet/process/job/job.reducer";
import {AiOutlineHistory} from "react-icons/ai";
import {HttpClientError, RequestStatus} from "http-client";
import {DETAIL_TYPE_JOB_INPUT} from "../../../process/job/constants";
import AgDataGrid from "../../../../../components/DataGrid/AgDataGrid";
import {filterParams} from "../../../../../helpers/agTable";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {UserCredential} from "../../../../login/models";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";

const JobCheckTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const jobCheckList = useAppSelector((state: RootState) => state.jobCheck.list ?? []);
    const actionStatus = useAppSelector((state: RootState) => state.job.actionStatus);
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );
    const actionPendingStatus = useAppSelector((state: RootState) => state.jobCheck.actionStatus);
    const errorPending = useAppSelector(
        (state: RootState) => state.jobCheck.error ?? ({} as HttpClientError)
    );
    const userState = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const [jobId, setJobId] = useState<string>('');
    const [tableName, setTableName] = useState('');

    useEffect(() => {
        dispatch(getList({skip: 0, take: 10}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ((tableName === 'waiting' && actionStatus !== undefined && actionStatus !== RequestStatus.pending)) {
            MySwal.fire(customStatusOption(actionStatus === RequestStatus.success, error.data?.message, 'status has been changed!', 'failed to change status')).then(() => {
                dispatch(resetJob());
                dispatch(getList({skip: 0, take: 10}));
                setShowModal(false);
                setTableName('');
            })
        } else if ((tableName === 'waiting' && actionPendingStatus !== undefined && actionPendingStatus !== RequestStatus.pending)) {
            MySwal.fire(customStatusOption(actionPendingStatus === RequestStatus.success, errorPending.data?.message, 'status has been changed!', 'failed to change status')).then(() => {
                dispatch(reset());
                dispatch(getList({skip: 0, take: 10}));
                setShowModal(false);
                setTableName('');
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableName, actionStatus, actionPendingStatus, error, errorPending]);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setAction('');
        setJobId('');
    };

    const openModal = (action: string, id: string) => {
        setAction(action);
        setShowModal(true);
        setJobId(id);
    };

    const onStatusClicked = (id: string, action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                switch (action) {
                    case 'checked':
                        dispatch(checkJob({job_id: id, user_id: user.user_id}));
                        setTableName('waiting');
                        break;
                    case 'pending':
                        dispatch(pendingJob({id, userId: userState.user_id}));
                        setTableName('waiting');
                        break;
                    case 'cancel':
                        dispatch(cancelJob({
                            job_id: id,
                            cancel_notes: data?.notes ?? '',
                            waiting_by_user: user.user_id
                        }));
                        setTableName('waiting');
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const ActionCellRender = (props: any) => {
        return (
            <div className="flex align-middle h-[100%]">
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCheckJob, ActionEnum.ActionCheck) &&
                    <button onClick={() => onStatusClicked(props.data?.id, 'checked')}>
                        <MdOutlineCheckCircleOutline
                            className="mr-2 text-2xl text-primary-color"
                        />
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCheckJob, ActionEnum.ActionLastCheck) &&
                    <button onClick={() => onStatusClicked(props.data?.id, 'pending')}>
                        <AiOutlineHistory
                            className="mr-2 text-2xl text-primary-color"
                        />
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCheckJob, ActionEnum.ActionCancel) &&
                    <button onClick={() => openModal('cancel', props.data?.id)}>
                        <MdOutlineCancel className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {/*<span className="mx-3">|</span>*/}
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.Edit) &&
                    <button>
                        <Link
                            to={{
                                pathname: `/worksheet/job/detail/${props.data?.id}`,
                                search: `?from=check-job`
                            }}
                        >
                            <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                        </Link>
                    </button>
                }
            </div>
        )
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            maxWidth: 50,
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'sales_order', headerName: 'Sales Order', filter: true},
        {field: 'number_id', headerName: 'ID Number', filter: true},
        {
            headerName: 'Job Number',
            field: 'number_job',
            filter: 'agTextColumnFilter',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
        },
        {field: 'project_name', headerName: 'Project', filter: 'agTextColumnFilter'},
        {field: 'department_owner_name', headerName: 'Department Owner', filter: 'agTextColumnFilter'},
        {field: 'kind_name', headerName: 'KoJ', filter: 'agTextColumnFilter'},
        {field: 'waiting_by_user_name', headerName: 'Waiting By', filter: 'agTextColumnFilter'},
        {
            field: 'waiting_ts',
            headerName: 'Waiting Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date_time'
        },
        {field: 'waiting_subject_name', headerName: 'Waiting Subject', filter: 'agTextColumnFilter'},
        {field: 'waiting_notes', headerName: 'Waiting Notes'},
        {
            field: 'ts_created',
            headerName: 'Request Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date'
        },
        {field: 'status_name', headerName: 'Status', filter: true},
        {field: 'type_name', headerName: 'Type', filter: true},
        {field: 'district_name', headerName: 'District'},
        {field: 'po_number', headerName: 'PO Number', filter: false, sortable: false},
        {
            field: 'date_due',
            headerName: 'Due Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date'
        },
        {field: 'pole_total', headerName: 'Req Pole'},
        {
            field: 'last_check_date',
            headerName: 'Last Checked',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date_time'
        },
        {
            field: 'last_check_user_name',
            headerName: 'Last Checked By',
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
        },
    ]);

    return (
        <div>
            <div className="main-data-grid mt-5">
                <AgDataGrid rowData={jobCheckList} columnDefs={columnDefs} supportSaveTableState tableStateName={'job-data-check-job'} defaultPageSize={20}/>
                <DefaultModal
                    title="Change Status"
                    show={showModal}
                    onClose={toggleModal}
                    body={<JobChangeStatus action={action} jobId={jobId} onSubmit={onStatusClicked}/>}
                />
            </div>
        </div>
    );
};

export default JobCheckTable;
