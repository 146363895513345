import {
    ComponentPropsWithRef,
    FC,
    forwardRef,
    useCallback,
    useState,
} from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

type InputPasswordProps = {
    label?: string;
    message?: string;
};

type InputPasswordType = FC<
    InputPasswordProps & ComponentPropsWithRef<'input'>
>;

const InputPassword: InputPasswordType = forwardRef((props, ref) => {
    const { label = '', message = '', ...rest } = props;

    const [isShown, setIsShown] = useState<boolean>(false);

    const onToggle = useCallback(() => {
        setIsShown((prevState) => !prevState);
    }, []);

    return (
        <div className="col-span-6 sm:col-span-3 pt-2 pb-2 relative">
            {label && (
                <label
                    htmlFor={label}
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                >
                    {label}
                </label>
            )}

            <span
                onClick={onToggle}
                className={`absolute right-[12px] z-[99] top-[53px] translate-y-[-50%] w-[22px] text-primary-color text-[20px] cursor-pointer`}
            >
                {isShown ? <FaEyeSlash /> : <FaEye />}
            </span>

            <input
                id={label}
                type={`${isShown ? 'text' : 'password'}`}
                ref={ref}
                className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-color focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-color dark:focus:ring-primary-color rounded-lg p-2.5 text-sm"
                {...rest}
            />

            {message !== '' && (
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {message}
                </p>
            )}
        </div>
    );
});

export default InputPassword;
