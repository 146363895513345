import React, {useEffect} from 'react';
import DetailMREPoleTable from "../components/DetailMREPoleTable";
import DetailMREForm from "../components/DetailMREForm";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {JobID} from "../../jobDetail/models";
import {isObjectEmpty} from "../../../../../helpers/checkEmptiness";
import {getDetailPoleMRE} from "../../../../basic/pole/pole.reducer";
import DetailQCMREForm from "../components/DetailQCMREForm";
import AddMREPoleTable from "../components/AddMREPoleTable";
import {useParams} from "react-router-dom";

const DetailMREPage = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    useEffect(() => {
       if (id && id !== "") {
           dispatch(getDetailPoleMRE({job_id: id as string, skip: 0, take: 10}));
       }
    }, [id]);

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="mt-3">
                <div>
                    <AddMREPoleTable />
                    <div className="my-3">
                        <hr/>
                    </div>
                    <DetailMREPoleTable jobId={id as string} />
                </div>
                <div>
                    <DetailMREForm jobId={id as string} />
                    <div className="my-3">
                        <hr/>
                    </div>
                    <DetailQCMREForm jobId={id as string} />
                </div>
            </div>
        </div>
    );
};

export default DetailMREPage;
