
export type SupportingDocumentReportModel = {
    date_request: string;
    employee_name_req_by: string | null;
    area?: string | null;
    job_id: string;
    job_number: string;
    other_number: string;
    sales_order: string;
    type_name: string;
    status_name: string;
    total_pole: number;
    note: string;
    complete_by: string | null;
    get_by: string | null;
    isactive: boolean;
}

export enum SortingEnum {
    Ascending,
    Descending
}

export enum SupportingDocumentSortEnum {
    requestDate,
    area,
    req_by,
    job_id,
    job_number,
    note,
    other_number,
    sales_order,
    total_pole,
    status_name,
    type_name
}

export type GetSupportingDocumentReportReq = {
    skip: number;
    take: number;
    startDate?: string;
    endDate?: string;
    filterByEmployeeName?: string;
    filterByJobId?: string;
    filterByJobNumber?: string;
    filterByNote?: string;
    filterBySalesOrder?: string;
    filterByType?: string;
    filterByStatus?: string;
    filterByOtherNumber?: string;
    filterByArea?: string;
    column?: number;
    sortType?: SortingEnum;
}