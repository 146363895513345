import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {CompleteJobRequest, GetQCFileRequest, JobFileQC, JobFileQCList, UpdateQCNote} from "./jobQC.models";

export default class JobQCApi extends BaseServiceClass {
    static getJobQCFile(args: GetQCFileRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobFileQCList[]>>(this.PATH_WORKSHEET.job_file_qc, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args,
            })
            .then((res) => res.data);
    }

    static createJobQCFile(args: JobFileQC, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_qc, args, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => res.data)
            .catch((err) => err);
    }

    static deleteJobQCFile(id: string, token: string) {
        return httpClient
            .delete<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_qc, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    id: id,
                },
            })
            .then((res) => res.data);
    }

    static updateQCNote(args: UpdateQCNote, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_update_qc_notes, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static completeJob(args: CompleteJobRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_complete, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
}