import React, {useCallback, useEffect, useRef, useState} from 'react';
import {TbHandGrab} from "react-icons/tb";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {Link} from "react-router-dom";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import GetJobPrep from "./GetJobPrep";
import {DETAIL_TYPE_JOB_INPUT} from "features/worksheet/process/job/constants";
import {JobPrep} from "../models";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {savedStatusOption} from "../../../../../helpers/alertLabel";
import {getJobPrepWithFilter, reset} from "../processJobPrep.reducer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {UserCredential} from "../../../../login/models";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";
import {jobPrepSortOption} from "../constants";

const JobPrepTable = () => {
        const gridRef = useRef<any>(null);
        const dispatch = useAppDispatch();
        const MySwal = withReactContent(Swal);

        const [showModal, setShowModal] = useState<boolean>(false);
        const [selectedJobId, setSelectedJobId] = useState<string | undefined>(undefined);

        const user = useAppSelector(
            (state: RootState) => state.user.userAuth ?? {} as UserCredential
        );

        const statusPole = useAppSelector((state: RootState) => state.pole.status);
        const status = useAppSelector((state: RootState) => state.processJobPrep.status);
        const error = useAppSelector((state: RootState) => state.processJobPrep.error ?? ({} as HttpClientError));

        useEffect(() => {
            if (status !== undefined && status !== RequestStatus.pending) {
                MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                    if (status === RequestStatus.success) {
                        toggleModal();
                        refreshCache(undefined);
                    }
                    dispatch(reset());
                })
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [status, error]);

        useEffect(() => {
            if (statusPole === RequestStatus.success) {
                refreshCache(undefined);
                dispatch(reset());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [statusPole]);

        const toggleModal = () => {
            setShowModal((prevState) => !prevState);
            setSelectedJobId(undefined);
        };

        const onGrabClicked = (job: JobPrep) => {
            setSelectedJobId(job.id);
            setShowModal(true);
        };

        const ActionCellRender = (props: any) => {

            if (props.data === undefined) {
                return null;
            }

            return (
                <div className="flex align-middle h-[100%]">
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.ActionAssign) &&
                        <button onClick={() => {
                            onGrabClicked(props.data)
                        }}>
                            <TbHandGrab
                                className="mr-2 text-2xl text-primary-color"
                            />
                        </button>
                    }
                </div>
            )
        }

        const JobNumberLinkCellRender = (props: any) => (
            <Link
                to={`/worksheet/ocalc/job-prep/${props.data?.id}`}
                className="underline text-blue-700"
            >
                {props.data?.number_job}
            </Link>
        );

        const [columnDefs, _setColumnDefs] = useState([
            {
                headerName: '#',
                field: 'rowNumber',
                cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            },
            {
                field: 'project_name', headerName: 'Project',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {
                field: 'department_name', headerName: 'Department',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {field: 'department_owner_name', headerName: 'Department Owner', filter: false, sortable: false},
            {field: 'district_name', headerName: 'District', filter: false, sortable: false},
            {
                field: 'po_number', headerName: 'PO Number',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {field: 'type_name', headerName: 'Type', filter: false, sortable: false},
            {
                field: 'kind_name', headerName: 'KoJ',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {
                field: 'ts_created',
                headerName: 'Request Date',
                cellDataType: 'date',
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    maxNumConditions: 1,
                },
            },
            {
                field: 'number_id', headerName: 'ID Number',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {
                headerName: 'Job Number',
                field: 'number_job',
                suppressCellFlash: true,
                cellRenderer: JobNumberLinkCellRender,
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {
                field: 'other_number', headerName: 'Other Number',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {
                field: 'status_name', headerName: 'Status',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {
                field: 'date_due',
                headerName: 'Due Date',
                cellDataType: 'date',
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    maxNumConditions: 1,
                },
            },
            {
                headerName: 'Get Percentage',
                field: 'percent_prep',
                suppressCellFlash: true,
                cellRenderer: (props: any) => <>{props.data?.percent_prep.toFixed(0) + '%'}</>,
            },
            {
                field: 'date_assigned_prep',
                headerName: 'Date Assigned',
                cellDataType: 'date',
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    maxNumConditions: 1,
                },
            },
            {
                field: 'pole_total', headerName: 'Req Pole',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {
                field: 'check_job_user_name', headerName: 'Ready In',
                filter: 'agTextColumnFilter', filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            {
                field: 'check_job_date',
                headerName: 'Date Ready',
                cellDataType: 'date',
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    maxNumConditions: 1,
                },
            },
            {
                headerName: 'Actions',
                field: 'actions',
                pinned: 'right',
                suppressCellFlash: true,
                minWidth: 100,
                cellRenderer: ActionCellRender,
                cellStyle: () => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }),
            },
        ]);

        const getServerSideDataSource = () => {
            return {
                getRows: (params: any) => {
                    const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(jobPrepSortOption, params.request.sortModel[0].colId) ?? undefined;
                    const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                    const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                    const {dateFrom: startDueDate, dateTo: endDueDate} = params.request.filterModel?.date_due || {};
                    const {
                        dateFrom: startDateAssign,
                        dateTo: endDateAssign
                    } = params.request.filterModel?.date_assigned_prep || {};
                    const {
                        dateFrom: startDateReady,
                        dateTo: endDateReady
                    } = params.request.filterModel?.check_job_date || {};

                    dispatch(getJobPrepWithFilter(
                        {
                            skip: params.request.startRow,
                            take: params.api.paginationGetPageSize(),
                            project_name: params.request.filterModel?.project_name?.filter,
                            department_name: params.request.filterModel?.department_name?.filter,
                            kind_job: params.request.filterModel?.kind_job?.filter,
                            startRequestDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                            endRequestDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                            number_id: params.request.filterModel?.number_id?.filter,
                            number_job: params.request.filterModel?.number_job?.filter,
                            other_number: params.request.filterModel?.other_number?.filter,
                            po_number: params.request.filterModel?.po_number?.filter,
                            status: params.request.filterModel?.status_name?.filter,
                            startDueDate: startDueDate !== undefined ? dateSetTimeToNow(startDueDate) : undefined,
                            endDueDate: endDueDate !== undefined ? dateSetTimeToNow(endDueDate) : undefined,
                            startDateAssign: startDateAssign !== undefined ? dateSetTimeToNow(startDateAssign) : undefined,
                            endDateAssign: endDateAssign !== undefined ? dateSetTimeToNow(endDateAssign) : undefined,
                            req_pole: params.request.filterModel?.pole_total?.filter,
                            ready_in: params.request.filterModel?.check_job_user_name?.filter,
                            startDateReady: startDateReady !== undefined ? dateSetTimeToNow(startDateReady) : undefined,
                            endDateReady: endDateReady !== undefined ? dateSetTimeToNow(endDateReady) : undefined,
                            column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                            sort: sortModel !== undefined ? sortType : undefined
                        }
                    )).then((res: any) => {
                        params.success({
                            rowData: res.payload?.data,
                            rowCount: res.payload.rows,
                        });
                    }).catch((_err: any) => {
                        params.fail();
                    })
                }
            }
        }

        const refreshCache = useCallback((route: any) => {
            const purge = !!document?.querySelector("#purge" as any)?.checked;
            gridRef.current.api.refreshServerSide({route: route, purge: purge});
        }, []);

        return (
            <div>
                <div className="main-data-grid mt-2">
                    <div className='flex'>
                        <div className='flex flex-grow self-center'>
                            <h1 className="dark:text-gray-300 font-bold my-3">Job Prep</h1>
                        </div>
                    </div>
                    <div>
                        {/*<FormLoader isLoading={isLoading} label={"Fetching Data"}>*/}
                        {/*    <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={prepList} supportSaveTableState*/}
                        {/*                      tableStateName={'worksheet-job-prep'}/>*/}

                        {/*</FormLoader>*/}

                        <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                                getServerSideDataSource={getServerSideDataSource}
                                                supportSaveTableState
                                                tableStateName={'worksheet-job-prep'}
                                                defaultPageSize={20}
                        />
                    </div>
                    <DefaultModal
                        title="Get"
                        show={showModal}
                        onClose={toggleModal}
                        size={'7xl'}
                        maxHeight="full"
                        body={<GetJobPrep id={selectedJobId} onHide={toggleModal}/>}
                    />
                </div>
            </div>
        );
    }
;

export default JobPrepTable;
