import {SupportingDocumentEnum} from "../../../basic/attachment/models";

export const StatusOption = [
    {
        text: 'AVAILABLE',
        label: 'AVAILABLE',
        value: SupportingDocumentEnum.AVAILABLE.toString(),
    },
    {
        text: 'NOT AVAILABLE',
        label: 'NOT AVAILABLE',
        value: SupportingDocumentEnum.NOT_AVAILABLE.toString(),
    },
    {
        text: 'NOT REQUIRED',
        label: 'NOT REQUIRED',
        value: SupportingDocumentEnum.NOT_REQUIRED.toString(),
    },
]