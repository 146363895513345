import React, {
    useEffect, useRef,
    useState,
} from 'react';
import {Link} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
    cancelJob,
    checkJob,
    deleteJob, getListNew,
    reset,
    waitingJob,
} from '../job.reducer';
import {InputStatusModel} from '../models';
import {HttpClientError, RequestStatus} from 'http-client';
import {BiEditAlt} from 'react-icons/bi';
import {
    MdOutlineCancel,
    MdOutlineCheckCircleOutline,
    MdOutlineDeleteForever,
    MdPauseCircleOutline,
} from 'react-icons/md';
import {
    customStatusOption,
    customWarnOption,
    deletedStatusOption,
    deletedWarnOption,
} from 'helpers/alertLabel';
import DefaultModal from 'components/Modal/DefaultModal';
import JobChangeStatus from './JobChangeStatus';
import {DETAIL_TYPE_JOB_INPUT} from '../constants';
import 'ag-grid-enterprise';
import {
    rowNumberRenderer
} from "../../../../../components/DataGrid/agGridCustomProps";
import AgDataGridStatic from "../../../../../components/DataGrid/AgDataGridStatic";
import {filterParams} from "../../../../../helpers/agTable";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import FormLoader from "../../../../../components/Loader/FormLoader";

const JobTable = () => {
    const gridRef = useRef<any>(null);
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const jobList = useAppSelector((state: RootState) => state.job.listNew ?? []);
    const isLoading = useAppSelector((state: RootState) => state.job.isLoading);
    const status = useAppSelector((state: RootState) => state.job.status);
    const actionStatus = useAppSelector(
        (state: RootState) => state.job.actionStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const [jobId, setJobId] = useState<string>('');
    const [isJobAction, setJobAction] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getListNew({}));
    }, []);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setAction('');
        setJobId('');
    };

    const openModal = (action: string, id: string) => {
        setAction(action);
        setShowModal(true);
        setJobId(id);
    };

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                setJobAction(true);
                dispatch(deleteJob(id));
            }
        });
    };

    const onStatusClicked = (
        id: string,
        action: string,
        data?: InputStatusModel
    ) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            setJobAction(true);
            if (result.isConfirmed) {
                switch (action) {
                    case 'check':
                        dispatch(checkJob({job_id: id, user_id: user.user_id}));
                        break;
                    case 'waiting':
                        dispatch(
                            waitingJob({
                                job_id: id,
                                waiting_notes: data?.notes ?? '',
                                waiting_subject_id:
                                    data?.pause_subject_id ?? '',
                                waiting_by_user: user.user_id
                            })
                        );
                        break;
                    case 'cancel':
                        dispatch(
                            cancelJob({
                                job_id: id,
                                cancel_notes: data?.notes ?? '',
                                waiting_by_user: user.user_id
                            })
                        );
                        break;
                    default:
                        setJobAction(false);
                        break;
                }
            }
        });
    };

    useEffect(() => {
        if (isJobAction && (status !== undefined && status !== RequestStatus.pending)) {
            MySwal.fire(
                deletedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                setJobAction(false);
                dispatch(reset());
                dispatch(getListNew({}));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isJobAction, status, error]);

    useEffect(() => {
        if (
            isJobAction &&
            (actionStatus !== undefined &&
                actionStatus !== RequestStatus.pending)
        ) {
            MySwal.fire(
                customStatusOption(
                    actionStatus === RequestStatus.success,
                    error.data?.message,
                    'status has been changed!',
                    'failed to change status'
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getListNew({}));
                setShowModal(false);
                setJobAction(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isJobAction, actionStatus, error]);

    // ag-grid props

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.ActionCheck) &&
                    <button onClick={() => onStatusClicked(props.data?.id, 'check')}>
                        <MdOutlineCheckCircleOutline className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.ActionWaiting) &&
                    <button onClick={() => openModal('waiting', props.data?.id)}>
                        <MdPauseCircleOutline className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.ActionCancel) &&
                    <button onClick={() => openModal('cancel', props.data?.id)}>
                        <MdOutlineCancel className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.Edit) &&
                    <button>
                        <Link
                            to={{
                                pathname: `/worksheet/job/detail/${props.data?.id}`,
                            }}
                        >
                            <BiEditAlt className=" text-2xl text-primary-color"/>
                        </Link>
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJob, ActionEnum.Delete) &&
                    <button onClick={() => onDeleteClicked(props.data?.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                    </button>
                }
            </div>
        )
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'sales_order', headerName: 'Sales Order', filter: 'agTextColumnFilter'},
        {field: 'number_id', headerName: 'ID Number', filter: 'agTextColumnFilter'},
        {
            headerName: 'Job Number',
            field: 'number_job',
            filter: 'agTextColumnFilter',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
        },
        {field: 'other_number', headerName: 'Other Number', filter: 'agTextColumnFilter'},
        {field: 'project_name', headerName: 'Project', filter: 'agTextColumnFilter'},
        {field: 'department_name', headerName: 'Department', filter: 'agTextColumnFilter'},
        {field: 'department_owner_name', headerName: 'Department Owner', filter: 'agTextColumnFilter'},
        {field: 'kind_name', headerName: 'KoJ', filter: true},
        {
            field: 'ts_created',
            headerName: 'Request Date',
            cellDataType: 'date', filter: 'agDateColumnFilter', filterParams: filterParams
        },
        {field: 'status_name', headerName: 'Status', filter: true},
        {field: 'type_name', headerName: 'Type', filter: true},
        {field: 'category_name', headerName: 'Category', filter: true},
        {field: 'source_name', headerName: 'Source', filter: true},
        {field: 'district_name', headerName: 'District', filter: true},
        {field: 'po_number', headerName: 'PO Number', filter: true},
        {
            field: 'date_due',
            headerName: 'Due Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams
        },
        {field: 'pole_total', headerName: 'Req Pole'},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    return (
        <div className="main-data-grid mt-5">
            <div>
                <FormLoader isLoading={isLoading} label={"Fetching Data"}>
                    <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={jobList} supportSaveTableState
                                      tableStateName={'worksheet-job'} defaultPageSize={20}/>
                </FormLoader>
            </div>
            <DefaultModal
                title="Change Status"
                show={showModal}
                onClose={toggleModal}
                body={
                    <JobChangeStatus
                        action={action}
                        jobId={jobId}
                        onSubmit={onStatusClicked}
                    />
                }
            />
        </div>
    );
};

export default JobTable;
