export enum SupportingDocumentEnum {
    NOT_REQUIRED, NOT_AVAILABLE, AVAILABLE
}

export type JobAttachmentRequest = {
    id?: string;
    job_id: string;
    type_id: string;
    value: SupportingDocumentEnum,
    date_request: string;
    link: string;
    note: string;
    employee_id: string;
    option: string | null;
}

export type GetJobAttachmentRequest = {
    skip?: number;
    take?: number;
    job_id?: string;
}

export type GetJobLinkAttachmentRequest = {
    skip?: number;
    take?: number;
    job_id?: string;
}

export type JobAttachment = {
    id: string;
    job_id: string;
    type_id: string;
    type_name: string;
    value: SupportingDocumentEnum,
    date_request: string;
    link: string;
    note: string;
    employee_id: string;
    employee_name: string;
    option: string | null;
    ts_created: string;
    ts_last_check: string;
    job_pole_total: number;
    job_date_due_fielding: string;
    job_project_district_name_list: string;
    job_status_name: string;
    job_kind_name: string;
    job_project_name: string;
    job_number_job: string;
    job_sales_order: string;
    position: string;
}

export type JobAttachmentType = {
    id: string;
    name: string;
    options: string;
}

export type FullJobAttachment = {
    id: string;
    job_id: string;
    type_id: string;
    value: SupportingDocumentEnum;
    date_request: string;
    link: string;
    note: string;
    option: string | null;
    employee_id:string;
    date_follow_up: string;
    missing_fielding: string;
    ts_last_check: string;
};

export type JobLinkAttachment = {
    id: string;
    job_id: string;
    job_number_id: string;
    job_number_job: string;
    ts_created: string;
    link: string;
    employee_name: string;
    employee_id: string;
};