import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {InputFilter} from "../../../staffReport/models";
import {useAppDispatch} from "../../../../../helpers/redux";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import OvertimeReportTable from "../components/OvertimeReportTable";
import {getOvertimeReport} from "../../attendanceReport.reducer";
import Button from "../../../../../components/Button/Button";

const OvertimeReportPage = () => {
    const {
        control,
        handleSubmit,
        watch,
        reset,
    } = useForm<InputFilter>({defaultValues: {startDate: new Date().toISOString(), endDate: new Date().toISOString()}});
    const dispatch = useAppDispatch();

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    useEffect(() => {
        dispatch(
            getOvertimeReport({})
        );
    }, []);

    const onResetClicked = () => {
        reset();
        dispatch(
            getOvertimeReport({})
        );
    }

    const onSubmit = (data: InputFilter) => {
        let localStart = new Date(data.startDate).toISOString();
        let localEnd = new Date(data.endDate).toISOString();
        dispatch(
            getOvertimeReport({
                startDate: localStart,
                endDate: localEnd,
            })
        );
    }
    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Overtime</h1>
                </div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <RangeDatePicker startName='startDate' endName='endDate' startValue={new Date(startDate)} endValue={new Date(endDate)} control={control} centerStyle/>
                        <div className="flex justify-end items-center">
                            <div className={"mx-1"}>
                                <Button
                                    color="success"
                                    type="button"
                                    title="Reset"
                                    onClick={onResetClicked}
                                />
                            </div>
                            <div className={"ml-1"}>
                                <Button
                                    color="success"
                                    type="submit"
                                    title="Filter"
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="mt-3">
                    <OvertimeReportTable />
                </div>
            </div>
        </div>
    );
};

export default OvertimeReportPage;
