import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import './App.css';
import RouteList from "./routes/RouteList";

function App() {
    return (
        <Router>
            <div className="App">
                <RouteList/>
            </div>
        </Router>
    );
}

export default App;
