import {defaultThemes, TableStyles} from 'react-data-table-component';

export const tableStyles: TableStyles = {
    header: {
        style: {
            minHeight: '56px',
        },
    },
    table: {
        style: {
            // borderRightStyle: 'solid',
            // borderRightWidth: '1px',
            // borderRightColor: defaultThemes.default.divider.default,
            // borderLeftStyle: 'solid',
            // borderLeftWidth: '1px',
            // borderLeftColor: defaultThemes.default.divider.default,
        },
    },
    headRow: {
        style: {
            borderBottomStyle: 'none',
            borderBottomWidth: '0',
            borderBottomColor: defaultThemes.default.divider.default,
            backgroundColor: '#f5f8ff'
        },
    },
    rows: {
        style: {
            backgroundColor: '#f9fbfc',
            '&:not(:last-of-type)': {
                borderBottomStyle: 'none',
                borderBottomWidth: '0',
                borderBottomColor: defaultThemes.default.divider.default,
            },
        },
        stripedStyle: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#ffffff',
            },
        },
    },
    headCells: {
        style: {
            // '&:not(:last-of-type)': {
            //     borderRightStyle: 'solid',
            //     borderRightWidth: '1px',
            //     borderRightColor: defaultThemes.default.divider.default,
            // },
        },
    },
    cells: {
        style: {

        },
    },
    pagination: {
        style: {
            div: {
                select: {
                    backgroundImage: 'none',
                },
            },
        },
    },

};


