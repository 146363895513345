import React, {useEffect, useState} from 'react';
import {Card} from "flowbite-react";
import Divider from "../../../../components/Layout/Divider";
import Text from "../../../../components/Text/Text";
import Button from "../../../../components/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../../helpers/redux";
import {RootState} from "../../../../app/store";
import {UserCredential} from "../../../login/models";
import {isObjectEmpty} from "../../../../helpers/checkEmptiness";
import {getSingle, reset} from "../../../master/employee/employee.reducer";
import {Employee} from "../../../master/employee/models";
import EmployeeDetailPage from "../../../master/employee/containers/EmployeeDetailPage";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import {RequestStatus} from "../../../../http-client";
import {savedStatusOption} from "../../../../helpers/alertLabel";

const ProfileList = () => {
    const dispatch = useAppDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);

    const userAuth = useAppSelector((state: RootState) => state.user.userAuth ?? {} as UserCredential);
    const singleEmployee = useAppSelector((state: RootState) => state.employee.single ?? {} as Employee);
    const status = useAppSelector((state: RootState) => state.employee.status);

    useEffect(() => {
        if (!isObjectEmpty(userAuth)) {
            dispatch(getSingle(userAuth.user_id as string));
        }
    }, [userAuth]);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    useEffect(() => {
        if (status !== undefined && status === RequestStatus.success) {
            dispatch(getSingle(userAuth.user_id as string));
            setShowModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <Card>
            <div className="flex">
                <div className="grow">
                    <h5 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                        Profile
                    </h5>
                </div>
                <div>
                    <Button
                        title="Edit"
                        onClick={toggleModal}
                    />
                </div>
            </div>

            <div className="mb-5">
                <Divider>
                    <Divider.LeftContent>
                        <div>
                            <Text label="NIP" value={singleEmployee.number_employee}/>
                            <Text label="Name" value={singleEmployee.name}/>
                            <Text label="Username" value={singleEmployee.username}/>
                            <Text label="Password" value={"xxxxxx"}/>
                            <Text label="Phone Number" value={singleEmployee.phone}/>
                            <Text label="NIK" value={singleEmployee.number_ktp}/>
                            <Text label="NPWP Number" value={singleEmployee.number_npwp}/>
                            <Text label="Address" value={singleEmployee.address}/>
                            <Text label="Bank Account Number" value={singleEmployee.bank_account_number}/>
                            <Text label="Bank Account Name" value={singleEmployee.bank_account_name}/>
                            <Text label="Bank Account Address" value={singleEmployee.bank_account_address}/>
                            <Text label="Name of Bank" value={singleEmployee.bank_name}/>
                        </div>
                    </Divider.LeftContent>
                    <Divider.CenterLine/>
                    <Divider.RightContent>
                        <div>
                            <div>
                                <Text
                                    label="Date of Birth"
                                    value={singleEmployee.date_birth !== undefined ? new Date(singleEmployee.date_birth).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB')}
                                />
                                <Text label="Place of Birth" value={singleEmployee.place_of_birth}/>
                                <Text label="Religion" value={singleEmployee.religion}/>
                                <Text label="Contact Relation" value={singleEmployee.contact_relation}/>
                                <Text label="Name of Relation" value={singleEmployee.contact}/>
                                <Text label="Relationship Status" value={singleEmployee.relation_status_name}/>
                                <Text label="Position" value={singleEmployee.position_name}/>
                                <Text label="Team" value={singleEmployee.team_name}/>
                                <Text label="E-mail" value={singleEmployee.email}/>
                                <Text label="Highest Education" value={singleEmployee.education_name}/>
                                <Text label="No. BPJS Kesehatan" value={singleEmployee.bpjS_kesehatan}/>
                                <Text label="No. BPJS Ketenagakerjaan" value={singleEmployee.bpjS_ketenagakerjaan}/>
                                <Text
                                    label="Entry Date"
                                    value={new Date(singleEmployee.date_entry).toLocaleDateString('en-GB')}
                                />
                                <Text label="Marital Status" value={singleEmployee.status_marital}/>
                                <Text label="Total Number of Children" value={singleEmployee.status_marital}/>
                            </div>
                        </div>
                    </Divider.RightContent>
                </Divider>
            </div>
            <DefaultModal
                title="Edit Profile"
                show={showModal}
                onClose={toggleModal}
                body={<EmployeeDetailPage isEdit={true} isMyProfile={true}/>}
            />
        </Card>
    );
};

export default ProfileList;
