import React, {ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {dataTypeDefinitions, getRowStyle} from "./agGridCustomProps";
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {toast, ToastContainer} from "react-toastify";
import {IoMdClose} from "react-icons/io";
import {Button} from "flowbite-react";
import {LuListPlus, LuListRestart} from "react-icons/lu";

// ag-grid server side pagination (SSP)
export interface AgDataGridProps {
    columnDefs: any[];
    rowData: any[];
    totalRows: number;
    getServerSideDataSource: () => any;
    onFilterChanged: (e: any) => void;
    isRowSelectable: (e: any) => void;
    onSelectionChanged: (e: any) => void;
    isCustomHeader?: boolean;
    customChildHeaderComponent?: React.FC;
    defaultPageSize?: number;
    tableStateName?: string;
    tableDimension?: {
        width: number | string;
        height: number | string;
    };
    defaultColDef?: {
        sortable: boolean;
        resizable: boolean;
        flex?: number;
        minWidth: number;
        wrapHeaderText: boolean;
        wrapText: boolean;
        autoHeight: boolean;
    };
    detailCellRenderer?: any;
    detailRowHeight?: number;
    defaultFilter?: any;
    defaultSortModel?: any;
    supportAllPageOptions?: boolean;
    totalData?: number;
    customRowStyle?: any | undefined;
}

type AgDataGridType = React.FC<AgDataGridProps & any>;
export const AgDataGridServerPaging: AgDataGridType = forwardRef((props, ref) => {
    const {
        columnDefs = [],
        totalRows = 0,
        isCustomHeader = false,
        defaultPageSize = 10,
        supportSaveTableState = false,
        tableStateName = undefined,
        tableDimension = {
            width: "100%",
            height: "80vh",
        },
        defaultColDef = {
            sortable: true,
            resizable: true,
            minWidth: 50,
            wrapHeaderText: true,
            wrapText: true,
            autoHeight: true
        },
        detailRowHeight = 500,
        defaultFilter = {},
        defaultSortModel = [],
        supportAllPageOptions = false,
        totalData = 0,
        customRowStyle = undefined
    } = props;

    const gridApi = useRef<any>(null);
    const columnApi = useRef<any>(null);
    // const gridRef = useRef<any>(null);
    const [perPage, setPerPage] = useState<number>(0);
    const [isPageAll, setIsPageAll] = useState<boolean>(false);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const startRestoreState = () => {
            timeoutId = setTimeout(() => {
                restoreState();
            }, 1000);
        };

        if (columnApi.current !== null) {
            startRestoreState();
        }

        return () => clearTimeout(timeoutId);
    }, [perPage, columnApi]);

    const onFirstDataRendered = useCallback((_params: any) => {
        // autoSizeAllColumns();
        restoreState();
        loadFilterStateFromLocalStorage();
    }, []);

    useEffect(() => {
        if (gridApi.current && supportAllPageOptions && isPageAll) {
            const currentPage = gridApi.current.paginationGetCurrentPage();
            if (totalData !== currentPage) {
                setPerPage(parseInt(totalData));
                gridApi.current.paginationSetPageSize(totalData);
            }
        }
    }, [gridApi, supportAllPageOptions, totalData, isPageAll]);

    const onPageSizeChanged = (e: ChangeEvent<HTMLSelectElement>) => {
        setPerPage(e.target.value === "all" ? totalData : parseInt(e.target.value));
        gridApi.current.paginationSetPageSize(e.target.value === "all" ? totalData : parseInt(e.target.value));
        setIsPageAll(e.target.value === "all");
    }

    const onGridReady = useCallback((params: any) => {
        gridApi.current = params.api;
        columnApi.current = params.columnApi;
        let datasource = props.getServerSideDataSource();
        params.api.setServerSideDatasource(datasource);
        setPerPage(defaultPageSize);
        params.api.paginationSetPageSize(defaultPageSize);
        if (defaultFilter !== undefined) params.api.setFilterModel(defaultFilter);
        if (defaultSortModel !== undefined) params.columnApi.applyColumnState(defaultSortModel);
    }, [defaultPageSize, defaultFilter, defaultSortModel]);

    const saveState = useCallback(() => {
        const tableSetting = columnApi.current.getColumnState();
        const savedTableSetting = localStorage.getItem('table-setting');

        if (tableStateName === undefined) {
            displayToast('no column state saved, please provide tableStateName');
            return;
        }

        if (savedTableSetting === null) {
            localStorage.setItem('table-setting', JSON.stringify([
                {
                    name: tableStateName,
                    setting: tableSetting,
                },
            ]));
            displayToast('Column state saved');
        } else {
            const parsedSavedTableSetting = JSON.parse(savedTableSetting);
            const updatedTableSetting = parsedSavedTableSetting.filter((item: any) => item.name !== tableStateName);
            updatedTableSetting.push({
                name: tableStateName,
                setting: tableSetting,
            });
            localStorage.setItem('table-setting', JSON.stringify(updatedTableSetting));
            displayToast('Column state saved');
        }

    }, [tableStateName]);

    const resetState = useCallback(() => {
        const savedTableSetting = localStorage.getItem('table-setting');
        const savedTableFilter = localStorage.getItem('table-filter');

        if (tableStateName === undefined) {
            displayToast('no column state saved, please provide tableStateName');
            return;
        }

        if (savedTableSetting === null && savedTableFilter === null) {
            displayToast('no column state / filter table saved, please provide tableStateName');
            return;
        }

        if (savedTableSetting !== null) {
            const parsedSavedTableSetting = JSON.parse(savedTableSetting);
            const updatedTableSetting = parsedSavedTableSetting.filter((item: any) => item.name !== tableStateName);
            localStorage.setItem('table-setting', JSON.stringify(updatedTableSetting));
            columnApi.current.resetColumnState();
        }

        if (savedTableFilter !== null) {
            const parsedSavedTableFilter = JSON.parse(savedTableFilter);
            const updatedTableFilter = parsedSavedTableFilter.filter((item: any) => item.name !== tableStateName);
            localStorage.setItem('table-filter', JSON.stringify(updatedTableFilter));
            gridApi.current.setFilterModel([]);
        }
        displayToast('Column state reset');
    }, [tableStateName]);

    const restoreState = useCallback(() => {
        const savedTableSetting = localStorage.getItem('table-setting');
        if (savedTableSetting === null || tableStateName === undefined) {
            console.log('no column state saved');
        } else {
            const parsedSavedTableSetting = JSON.parse(savedTableSetting);
            const savedTableSettingForThisTable = parsedSavedTableSetting.filter((item: any) => item.name === tableStateName);
            if (savedTableSettingForThisTable.length > 0) {
                columnApi.current.applyColumnState({
                    state: savedTableSettingForThisTable[0].setting,
                    applyOrder: true,
                });
                console.log('column state restored');
            } else {
                console.log('no column state saved');
            }
        }
    }, []);

    const displayToast = (info: string) => {
        toast.info(<ShowAttachmentToast info={info} />);
    };

    const CloseButton = ({ closeToast }: any) => (
        <IoMdClose
            size={20}
            onClick={() => {
                closeToast();
            }}
        />
    );

    const ShowAttachmentToast = ({info = ""}) => {
        return (
            <div>
                <span>{info}</span>
            </div>
        );
    };

    const saveFilterStateToLocalStorage = () => {
        const savedTableFilter = localStorage.getItem('table-filter');

        if (gridApi) {
            const filterModel = gridApi.current.getFilterModel();
            if (savedTableFilter === null) {
                localStorage.setItem('table-filter', JSON.stringify([
                    {
                        name: tableStateName,
                        filter: filterModel,
                    },
                ]));
            } else {
                const parsedSavedTableSetting = JSON.parse(savedTableFilter);
                const updatedTableSetting = parsedSavedTableSetting.filter((item: any) => item.name !== tableStateName);
                updatedTableSetting.push({
                    name: tableStateName,
                    filter: filterModel,
                });
                localStorage.setItem('table-filter', JSON.stringify(updatedTableSetting));
            }
        }
    };

    const loadFilterStateFromLocalStorage = () => {
        if (gridApi) {
            const savedFilter = localStorage.getItem('table-filter');
            if (savedFilter) {
                const filterModel = JSON.parse(savedFilter);
                const savedFilterModel = filterModel.filter((item: any) => item.name === tableStateName);
                if (savedFilterModel[0]?.filter) {
                    gridApi.current.setFilterModel(savedFilterModel[0].filter);
                }
            }
        }
    };

    return (
        <div>
            <div className="py-2 px-4 border-[1px] border-t-[#babfc7] border-x-[#babfc7] bg-[#f8f8f8]">
                <div className="flex">
                    <div className="flex items-center">
                        <label className={"text-xs font-bold pr-2"}>Page Size:</label>
                        <select className={"text-sm py-0 px-[10px] border-[1px] border-[#babfc7] rounded text-gray-500"}
                                onChange={onPageSizeChanged} id="page-size" value={totalData === perPage ? 'all' : perPage.toString()}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            {supportAllPageOptions && <option value={"all"}>All</option>}
                        </select>
                    </div>
                    <div className="flex grow justify-end">
                        {
                            isCustomHeader &&
                            <div className="flex items-center">
                                {props.customChildHeaderComponent}
                            </div>
                        }
                        {
                            supportSaveTableState &&
                            <>
                                <Button size="xs"
                                        color="success" onClick={resetState} className="ml-2">
                                    <LuListRestart className="mr-2 h-5 w-5"/>
                                    Reset Column</Button>
                                <Button size="xs"
                                        color="success" onClick={saveState} className="ml-2">
                                    <LuListPlus className="mr-2 h-5 w-5"/>
                                    Save Column</Button>
                            </>
                        }
                    </div>
                </div>

            </div>
            <div
                className="ag-theme-alpine"
                style={{width: tableDimension.width, height: tableDimension.height}}
            >
                <AgGridReact
                    ref={ref as any}
                    pagination={true}
                    columnDefs={columnDefs}
                    paginationPageSize={perPage}
                    cacheBlockSize={perPage}
                    animateRows={true}
                    defaultColDef={defaultColDef}
                    dataTypeDefinitions={dataTypeDefinitions as any}
                    getRowStyle={customRowStyle ?? getRowStyle}
                    onGridReady={onGridReady}
                    rowModelType={'serverSide'}
                    infiniteInitialRowCount={totalRows}
                    // rowData={rowData}
                    enableRangeSelection={true}
                    onFirstDataRendered={onFirstDataRendered}
                    isRowSelectable={props.isRowSelectable}
                    rowSelection={'multiple'}
                    maintainColumnOrder={true}
                    onSelectionChanged={props.onSelectionChanged}
                    onFilterChanged={saveFilterStateToLocalStorage}
                    masterDetail={true}
                    detailCellRenderer={props.detailCellRenderer}
                    detailRowHeight={detailRowHeight}
                />
                <ToastContainer
                    autoClose={5000}
                    draggable={false}
                    closeButton={CloseButton}
                />
            </div>
        </div>
    );
});

export default AgDataGridServerPaging;
