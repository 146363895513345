import React, {Fragment, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { RiDashboardFill } from 'react-icons/ri';
import {BsChevronDown, BsChevronRight, BsDot} from 'react-icons/bs';
import { MenuModel } from '../models';
import {checkRoleFeatureAccess, checkRoleMenuAccess} from "../../../routes/checkRoleAccess";
import {FeatureEnum, MenuEnum} from "../../../routes/models";
import {useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";
import {UserCredential} from "../../login/models";

const SidebarItem = ({
    menu = {} as MenuModel,
    open = false,
    currentMenu = [],
    ...props
}: SidebarItemProps) => {
    let location = useLocation();
    const [currentChild, setCurrentChild] = useState<Array<string>>([]);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );

    const toggleChildOpen = (name: string) => {
        const _currentChild = currentChild.some((current) => current === name)
            ? currentChild.filter((current) => current !== name)
            : [...currentChild, name];
        setCurrentChild(_currentChild);
    };
    return (
        <>
            <Link to={menu.url ?? '#'} onClick={() => {
                menu.submenu && open && props.toggleSubmenuOpen(menu.title);
            }}>
                <li
                    className={`text-t-primary-color dark:text-gray-300 text-sm flex items-center gap-x-2 cursor-pointer p-2 hover:bg-sidebar-menu hover:text-sidebar-text rounded-sm ${
                        menu.spacing ? 'mt-9' : 'mt-2'
                    } ${(currentMenu.some((current) => current === menu.title) || location?.pathname === "/" + menu.url) && 'bg-sidebar-menu border-l-4 border-sidebar-text text-sidebar-text'} duration-300 mt-2`}
                >
                    <span className="text-xl block float-left">
                        {menu.icon ? menu.icon : <RiDashboardFill />}
                    </span>
                    <span
                        className={`font-medium flex-1 ${
                            !open && 'hidden'
                        }`}
                    >
                        {menu.title}
                    </span>
                    {menu.submenu && open && (
                        <BsChevronDown
                            className={`${
                                currentMenu.some(
                                    (current) => current === menu.title
                                ) && 'rotate-180'
                            }`}
                        />
                    )}
                </li>
            </Link>
            {menu.submenu &&
                currentMenu.some((current) => current === menu.title) &&
                open && (
                    <ul className='duration-300 bg-sidebar-submenu pb-3'>
                        {menu.submenuItems?.map((submenuItem, index) => {
                            if (submenuItem.child ? checkRoleFeatureAccess(user.roleName, submenuItem.id ?? FeatureEnum.All) : checkRoleMenuAccess(user?.roleName, submenuItem.id ?? MenuEnum.All)) {
                                return (
                                    <Fragment key={'submenu' + index}>
                                        <Link to={submenuItem.url ?? '#'} onClick={() => {
                                            submenuItem.child && toggleChildOpen(submenuItem.title);
                                        }}>
                                            <li
                                                key={'submenu' + index}
                                                className={`dark:text-gray-400 text-xs hover:text-[13px] hover:text-primary-color font-medium duration-300 flex items-center gap-x-2 cursor-pointer p-2 pl-6 pr-5 hover:bg-light-white rounded-md ${location?.pathname === "/"+submenuItem.url ? 'text-sidebar-text' : 'text-t-secondary-color'}`}
                                            >
                                                {submenuItem.childItems && (
                                                    <BsChevronRight
                                                        className={`${
                                                            currentChild.some(
                                                                (current) => current === submenuItem.title
                                                            ) && 'rotate-90'
                                                        }`}
                                                    />
                                                )}
                                                {submenuItem.title}
                                            </li>
                                        </Link>
                                        {submenuItem.child &&
                                            currentChild.some((current) => current === submenuItem.title) &&
                                            <ul className='duration-300' >
                                                {submenuItem.childItems?.map((child, index) => {
                                                    if (checkRoleMenuAccess(user?.roleName, child.id ?? MenuEnum.All)) {
                                                        return (
                                                            <Link to={child.url ?? '#'} key={child.title + index}>
                                                                <li
                                                                    key={child.title + index}
                                                                    className={`${location?.pathname === "/" + child.url ? 'text-sidebar-text font-medium' : 'text-t-secondary-color'} dark:text-gray-400 text-xs flex items-center p-1 pl-10 pr-5 hover:text-[13px] hover:text-primary-color duration-300 rounded-md`}
                                                                >
                                                                    <BsDot/>
                                                                    {child.title}
                                                                </li>
                                                            </Link>
                                                        )
                                                    }
                                                })}
                                            </ul>
                                        }
                                    </Fragment>
                                )
                            }
                        })}
                    </ul>
                )}
        </>
    );
};

export interface SidebarItemProps {
    menu: MenuModel;
    open: boolean;
    currentMenu: Array<string>;
    toggleSubmenuOpen: (name: string) => void;
}

export default SidebarItem;
