import React, {useCallback, useEffect, useRef, useState} from 'react';
import {MREModel, PoleMREEnum} from '../models';
import {MdOutlineCancel, MdOutlineSupervisedUserCircle, MdPauseCircleOutline,} from 'react-icons/md';
import DefaultModal from '../../../../../components/Modal/DefaultModal';
import {useAppDispatch, useAppSelector} from '../../../../../helpers/redux';
import AssignMRE from './AssignMRE';
import {RootState} from '../../../../../app/store';
import {Link} from 'react-router-dom';
import {cancelMRE, getMREWithFilter, reset, waitingMRE} from '../processMRE.reducer';
import AssignQCMRE from "./AssignQCMRE";
import MREChangeStatus from "./MREChangeStatus";
import {InputStatusModel} from "../../job/models";
import {customStatusOption, customWarnOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";
import {MRESortOption} from "../constants";

const MRETable = () => {
    const gridRef = useRef<any>(null);
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedJobId, setSelectedJobId] = useState<string | undefined>(undefined)
    const [mreAssignStatus, setAssignStatus] = useState<PoleMREEnum>(PoleMREEnum.MRE);
    const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const [jobId, setJobId] = useState<string>('');

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const status = useAppSelector((state: RootState) => state.processMRE.status);
    const statusPole = useAppSelector((state: RootState) => state.pole.status);
    const actionStatus = useAppSelector((state: RootState) => state.processMRE.actionStatus);
    const error = useAppSelector(
        (state: RootState) => state.processMRE.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (actionStatus !== undefined && actionStatus !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(actionStatus === RequestStatus.success, error.data?.message, 'status has been changed!', 'failed to change status')).then(() => {
                dispatch(reset());
                refreshCache(undefined);
                setShowChangeStatusModal(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatus, error]);

    useEffect(() => {
        if (status === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (statusPole === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusPole]);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setSelectedJobId(undefined);
    };

    const openModal = (data: MREModel) => {
        if (data.status_name === 'New MRE' || data.status_name === 'MRE') {
            setAssignStatus(PoleMREEnum.MRE);
        } else if (checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetMRE, ActionEnum.ActionAssign) && data.status_name === 'Ready To QC MRE' || data.status_name === 'QC MRE') {
            setAssignStatus(PoleMREEnum.QC);
        }
        setShowModal(true);
        setSelectedJobId(data.id);
    };

    const openChangeStatusModal = (action: string, id: string) => {
        setAction(action);
        setShowChangeStatusModal(true);
        setJobId(id);
    };

    const toggleChangeStatusModal = () => {
        setShowChangeStatusModal((prevState) => !prevState);
        setAction('');
        setJobId('');
    };

    const onStatusClicked = (id: string, action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                switch (action) {
                    case 'waiting':
                        dispatch(waitingMRE({
                            job_id: id,
                            waiting_notes: data?.notes ?? '',
                            waiting_subject_id: null,
                            waiting_by_user: user.user_id
                        }));
                        break;
                    case 'cancel':
                        dispatch(cancelMRE({
                            job_id: id,
                            cancel_notes: data?.notes ?? '',
                            waiting_by_user: user.user_id
                        }));
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex align-middle h-[100%]">
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetMRE, ActionEnum.ActionAssign) &&
                    <button onClick={() => openModal(props.data)}>
                        <MdOutlineSupervisedUserCircle className="text-2xl text-primary-color"/>
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetMRE, ActionEnum.ActionWaiting) &&
                    <button onClick={() => openChangeStatusModal('waiting', props.data?.id)}>
                        <MdPauseCircleOutline
                            className="text-2xl text-primary-color"
                        />
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetMRE, ActionEnum.ActionCancel) &&
                    <button onClick={() => openChangeStatusModal('cancel', props.data?.id)}>
                        <MdOutlineCancel
                            className="text-2xl text-primary-color"
                        />
                    </button>
                }
            </div>
        )
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/mre/mre-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {
            field: 'project_name', headerName: 'Project',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'department_name', headerName: 'Department',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'department_owner_name', headerName: 'Department Owner', filter: false, sortable: false},
        {field: 'district_name', headerName: 'District', filter: false, sortable: false},
        {
            field: 'po_number', headerName: 'PO Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'type_name', headerName: 'Type', filter: false, sortable: false},
        {
            field: 'kind_name', headerName: 'KoJ',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'ts_created',
            headerName: 'Request Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'number_id', headerName: 'ID Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Job Number',
            field: 'number_job',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'other_number', headerName: 'Other Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'status_name', headerName: 'Status',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'date_due',
            headerName: 'Due Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Assign Percentage',
            field: 'percent_cs',
            suppressCellFlash: true,
            cellRenderer: (props: any) => <>{props.data?.percent_cs.toFixed(0) + '%'}</>,
            filter: false, sortable: false
        },
        {
            field: 'date_assigned_mre',
            headerName: 'Date Assigned MRE',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'date_assigned_qc_mre',
            headerName: 'Date Assigned QC MRE',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'pole_total', headerName: 'Req Pole',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 100,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(MRESortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {dateFrom: startDueDate, dateTo: endDueDate} = params.request.filterModel?.date_due || {};
                const {
                    dateFrom: startDateAssign,
                    dateTo: endDateAssign
                } = params.request.filterModel?.date_assigned_mre || {};
                const {
                    dateFrom: startDateAssignQC,
                    dateTo: endDateAssignQC
                } = params.request.filterModel?.date_assigned_qc_mre || {};

                dispatch(getMREWithFilter(
                    {
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        project_name: params.request.filterModel?.project_name?.filter,
                        department_name: params.request.filterModel?.department_name?.filter,
                        kind_job: params.request.filterModel?.kind_name?.filter,
                        startRequestDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endRequestDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        number_id: params.request.filterModel?.number_id?.filter,
                        number_job: params.request.filterModel?.number_job?.filter,
                        other_number: params.request.filterModel?.other_number?.filter,
                        po_number: params.request.filterModel?.po_number?.filter,
                        status: params.request.filterModel?.status_name?.filter,
                        startDueDate: startDueDate !== undefined ? dateSetTimeToNow(startDueDate) : undefined,
                        endDueDate: endDueDate !== undefined ? dateSetTimeToNow(endDueDate) : undefined,
                        startDateAssign: startDateAssign !== undefined ? dateSetTimeToNow(startDateAssign) : undefined,
                        endDateAssign: endDateAssign !== undefined ? dateSetTimeToNow(endDateAssign) : undefined,
                        startDateQCMREAssign: startDateAssignQC !== undefined ? dateSetTimeToNow(startDateAssignQC) : undefined,
                        endDateQCMREAssign: endDateAssignQC !== undefined ? dateSetTimeToNow(endDateAssignQC) : undefined,
                        req_pole: params.request.filterModel?.pole_total?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    }
                )).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            }
        }
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid mt-2">
                <div>
                    {/*<FormLoader isLoading={isLoading} label={"Fetching Data"}>*/}
                    {/*    <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={MREList} supportSaveTableState*/}
                    {/*                      tableStateName={'worksheet-mre'}/>*/}
                    {/*</FormLoader>*/}
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'worksheet-mre'}
                                            defaultPageSize={20}
                    />
                </div>
                <DefaultModal
                    title={mreAssignStatus === PoleMREEnum.MRE ? 'Assign MRE' : 'Assign QC MRE'}
                    show={showModal}
                    onClose={toggleModal}
                    size={'7xl'}
                    maxHeight="full"
                    body={
                        mreAssignStatus === PoleMREEnum.MRE ? (
                            <AssignMRE id={selectedJobId} onHide={toggleModal}/>
                        ) : (
                            <AssignQCMRE
                                id={selectedJobId}
                                onHide={toggleModal}
                            />
                        )
                    }
                />
                <DefaultModal
                    title="Change Status"
                    show={showChangeStatusModal}
                    onClose={toggleChangeStatusModal}
                    body={<MREChangeStatus action={action} jobId={jobId} onSubmit={onStatusClicked}/>}
                />
            </div>
        </div>
    );
};

export default MRETable;
