import React, {useEffect, useState} from 'react';
import TextArea from "../../../../../components/TextArea/TextArea";
import Button from "../../../../../components/Button/Button";
import DataTable, {TableColumn} from "react-data-table-component";
import {tableStyles} from "../../../../../components/Table/table";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import FileProgress from "../../../../basic/fileAttachment/components/FileProgress";
import {MdClose, MdDownload} from "react-icons/md";
import {darkGreen, darkRed} from "../../../../../constants";
import withReactContent from "sweetalert2-react-content";
import Swal, {SweetAlertIcon} from "sweetalert2";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {useForm} from "react-hook-form";
import {Job} from "../../../process/job/models";
import FileDropzone from "../../../../../components/FileInput/Dropzone";
import {
    completeJob,
    deleteJobQCFile,
    getJobQCFile,
    reset,
    updateQCNote,
    uploadJobQCFile
} from "../jobQC.reducer";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {deletedStatusOption, savedStatusOption} from "../../../../../helpers/alertLabel";
import {setUploadProgress} from "../../../process/job/job.reducer";
import {InputQCJob, JobFileQCList} from "../jobQC.models";
import {isObjectEmpty} from "../../../../../helpers/checkEmptiness";
import {UserCredential} from "../../../../login/models";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import FileAttachmentApi from "../../../../basic/fileAttachment/fileAttachment.api";

const JobQCForm = ({jobId = undefined}:JobQCFormProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const { register, handleSubmit, reset: resetForm, formState: { errors } } =
        useForm<InputQCJob>();

    const [showAttachmentModal, setShowAttachmentModal] =
        useState<boolean>(false);
    const [localFiles, setLocalFiles] = useState<Array<File>>([]);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const singleJobState = useAppSelector(
        (state: RootState) => state.job.single ?? ({} as Job)
    );
    const jobQCFile = useAppSelector(
        (state: RootState) => state.jobQC.jobQCFile ?? []
    );
    const userId = useAppSelector(
        (state: RootState) => state.user.userAuth?.user_id
    );
    const uploadStatus = useAppSelector((state: RootState) => state.jobQC.uploadStatus ?? []);
    const uploadProgresses = useAppSelector(
        (state: RootState) => state.jobQC.uploadProgress ?? []
    );
    const deleteFileStatus = useAppSelector(
        (state: RootState) => state.jobQC.deleteFileStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.jobQC.error ?? ({} as HttpClientError)
    );
    const status = useAppSelector((state: RootState) => state.jobQC.status);

    useEffect(() => {
        if (jobId && jobId !== "" && userId !== undefined) {
            dispatch(getJobQCFile({job_id: jobId as string, employee_id: userId as string}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId, userId]);

    useEffect(() => {
        if (!isObjectEmpty(singleJobState)) {
            resetForm({
                qc_notes: singleJobState.qc_notes,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleJobState]);

    useEffect(() => {
        if (deleteFileStatus !== undefined && deleteFileStatus !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    deleteFileStatus === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getJobQCFile({job_id: jobId as string, employee_id: userId as string}));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteFileStatus, error]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(setUploadProgress([]));
                // return navigate('/worksheet/process/qc', { replace: true });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onFileDelete = (fileId: string) => {
        MySwal.fire({
            text: `Are you sure you want to delete this file?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: darkRed,
            cancelButtonColor: darkGreen,
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJobQCFile(fileId));
            }
        });
    };

    const onSubmit = (data: InputQCJob) => {
        dispatch(updateQCNote({
            job_id: jobId as string,
            qc_notes: data.qc_notes,
        }))
    };

    const onCompleteClicked = () => {
        MySwal.fire(
            {
                title: <span>Complete job!</span>,
                html: <div><span>Are you sure? You will not be able to recover this data!</span><br/><br/><span>Form Report</span><br/><a href="https://report.kpaindonesia.com" target="_blank" rel="noreferrer" className="text-blue-700">https://report.kpaindonesia.com</a></div>,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: darkGreen,
                confirmButtonText: 'Yes',
            }
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(completeJob({job_id: jobId as string}));
            }
        });
    }

    const onModalAttachmentClose = () => {
        setLocalFiles([]);
        dispatch(reset());
        dispatch(getJobQCFile({job_id: jobId as string, employee_id: userId as string}));
        setShowAttachmentModal(false);
    };

    const onUploadModalClose = () => {
        setShowUploadModal(false);
        onModalAttachmentClose();
    }

    const toggleUploadModal = (args: boolean) => {
        setShowUploadModal(args);
        onModalAttachmentClose();
    };

    const onFileChange = (files: Array<File>) => {
        setLocalFiles(files);
    };

    const QCAttachment = () => {
        const onUpload = () => {
            if (localFiles.length === 0) {
                MySwal.fire(
                    {
                        title: "File Empty!",
                        text: "You haven't selected any file",
                        icon: 'warning' as SweetAlertIcon,
                    }
                )
            } else {
                setShowUploadModal(true);
                dispatch(uploadJobQCFile({
                    job_id: jobId as string,
                    local_files: localFiles,
                }))
            }
        };

        return (
            <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <FileDropzone
                    label="Attachment"
                    onChange={onFileChange}
                    uploadProgress={uploadProgresses}
                    files={localFiles}
                />

                <div className="flex justify-center my-2">
                    <div className="mx-1">
                        <Button
                            title="Cancel"
                            type="button"
                            onClick={onModalAttachmentClose}
                        />
                    </div>
                    <div className="mx-1">
                        <Button title="Save" type="button" onClick={onUpload} />
                    </div>
                </div>
            </div>
        );
    };

    const columnsAttachment: TableColumn<JobFileQCList>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Date',
            selector: (row) => row.ts_created,
            format: (row) => row.ts_created !== null ? new Date(row.ts_created).toLocaleDateString('en-US') : "-",
            sortable: true,
        },
        {
            name: 'QC',
            selector: (row) => row.employee_name ?? '-',
            sortable: true,
        },
        {
            name: 'File Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Action',
            width: '150px',
            cell: (row) => (
                <>
                    <a
                        href={
                            FileAttachmentApi.getMediaEndpoint(row.path ?? "")
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MdDownload className="mr-2 text-2xl text-primary-color" />
                    </a>
                    <button type={'button'} onClick={() => onFileDelete(row.id)}>
                        <MdClose className="mr-2 text-2xl text-secondary-color" />
                    </button>
                </>
            ),
        },
    ];

    return (
        <form>
            <div className="mb-2 mt-3 flex">
                <div className="flex items-center grow">
                    <label
                        className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                    >
                        QC
                    </label>
                </div>
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetQC, ActionEnum.Attachment) &&
                    <Button
                        title="Add/Remove Attachment"
                        type="button"
                        onClick={() => setShowAttachmentModal(true)}
                    />
                }

            </div>
            <div className={"mb-3"}>
                <div>
                    <DataTable
                        columns={columnsAttachment}
                        data={jobQCFile}
                        pagination
                        noHeader
                        highlightOnHover
                        pointerOnHover
                        customStyles={tableStyles}
                        theme={'solarized'}
                        striped
                        persistTableHead
                    />
                </div>
            </div>
            <div>
                <TextArea
                    {...register('qc_notes')}
                    label="QC Notes"
                    placeholder="Input QC Notes"
                    message={errors.qc_notes?.message}
                />
            </div>
            <div className="mt-3 grid md:grid-cols-1 gap-4">
                <div className="flex flex-grow justify-end">
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetQC, ActionEnum.Edit) &&
                        <div className="mr-2">
                            <Button
                                title="Save"
                                type="submit"
                                onClick={handleSubmit((d) => onSubmit(d))}
                            />
                        </div>
                    }
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetQC, ActionEnum.CompleteJob) &&
                        <div className="inline">
                            <Button
                                title="COMPLETE JOB"
                                type="button"
                                onClick={onCompleteClicked}
                            />
                        </div>
                    }
                </div>
            </div>
            <DefaultModal
                title="QC Attachment"
                show={showAttachmentModal}
                onClose={onModalAttachmentClose}
                body={<QCAttachment />}
            />
            <DefaultModal
                title="Uploading File"
                show={showUploadModal}
                onClose={()=>onUploadModalClose()}
                body={
                    <FileProgress uploadStatus={uploadStatus} uploadProgress={uploadProgresses}  setShowModal={toggleUploadModal} />
                }
            />
        </form>
    );
};

export interface JobQCFormProps {
    jobId?: string;
}

export default JobQCForm;
