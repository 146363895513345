import React from "react"
import {TextInput} from "flowbite-react";
const EditableCell = ({
                          value: initialValue,
                          row: { index },
                          column: { id },
                          updateMyData, // This is a custom function that we supplied to our table
                          rowEdit,
                          textType,
                          defaultValue,
                      }: any) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const onChange = (e: { target: { value: any } }) => {
        setValue(e.target.value);
    };
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value)
    }
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return (
        <>
            {rowEdit === index.toString() ? (
                <TextInput
                    id="small"
                    type={textType !== undefined ? textType : "text"}
                    sizing="sm"
                    value={value} onChange={onChange} onBlur={onBlur} />
            ) : (
                <span className='text-sm font-normal'>{initialValue}</span>
            )}
        </>
    )
}
export default EditableCell