import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {deleteEmployee, getList, reset} from "../employee.reducer";
import {HttpClientError, RequestStatus} from "http-client";
import {BiEditAlt} from "react-icons/bi";
import {MdOutlineDeleteForever} from "react-icons/md";
import {
    deletedStatusOption,
    deletedWarnOption,
} from "../../../../helpers/alertLabel";
import AgDataGridStatic from "../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";

const EmployeeTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const employeeList = useAppSelector((state: RootState) => state.employee.list ?? []);
    const status = useAppSelector((state: RootState) => state.employee.status);
    const error = useAppSelector((state: RootState) => state.employee.error ?? ({} as HttpClientError));

    useEffect(() => {
        dispatch(getList({ skip: 0, take: 1000 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(deletedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                dispatch(getList({ skip: 0, take: 1000 }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteEmployee(id));
            }
        });
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex align-middle h-[100%]">
                <Link
                    to={{
                        pathname: `/employee/detail/${props.data?.id}`,
                    }}
                >
                    <BiEditAlt className="mr-2 text-2xl text-primary-color" />
                </Link>
                <button onClick={() => onDeleteClicked(props.data?.id)}>
                    <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"  />
                </button>
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'nip', headerName: 'NIP', filter: 'agTextColumnFilter'},
        {field: 'name', headerName: 'Name', filter: 'agTextColumnFilter'},
        {field: 'phone', headerName: 'Phone Number', filter: 'agTextColumnFilter'},
        {field: 'team_name', headerName: 'Team Name', filter: 'agTextColumnFilter'},
        {field: 'position_name', headerName: 'Position Name', filter: 'agTextColumnFilter'},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    return (
        <div className="main-data-grid mt-5">
            <AgDataGridStatic columnDefs={columnDefs} rowData={employeeList} defaultPageSize={20}/>
        </div>
    );
};

export default EmployeeTable;
