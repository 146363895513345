import React, { useState } from 'react';
import AgDataGrid from 'components/DataGrid/AgDataGrid';
import { useAppSelector } from 'helpers/redux';
import {filterParams} from "../../../../../helpers/agTable";

const OvertimeReportTable = () => {
    const overtimeReportList = useAppSelector(
        (state) => state.attendanceReport.overtimeReportList ?? []
    );

    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([

        { field: 'nip', headerName: 'NIP', filter: true },
        { field: 'team', headerName: 'Team', filter: true },
        { field: 'name', headerName: 'Name', filter: true },
        {field: 'ts_created', headerName: 'Created Date', filter: 'agDateColumnFilter', filterParams: filterParams, cellDataType: 'date'},
        { field: 'roleName', headerName: 'Role', filter: true },
        { field: 'reqMinute', headerName: 'Req Minutes', filter: true, aggFunc: 'sum' },
        { field: 'notes', headerName: 'Notes' },
        { field: 'status', headerName: 'Status', filter: true },
        { field: 'rejectionNote', headerName: 'Rejection Notes' },
        { field: 'approveMinute', headerName: 'Approved', aggFunc: 'sum' },
        { field: 'totalApproveMinute', headerName: 'Total Approved', aggFunc: 'sum' },
        { field: 'multiplier', headerName: 'Multiple' },
        { field: 'managerApproveByName', headerName: 'Manager Approved' },
        { field: 'approveByName', headerName: 'Approved By' },
    ]);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <AgDataGrid
                        rowData={overtimeReportList}
                        columnDefs={columnDefs}
                        groupIncludeFooter={true}
                        groupIncludeTotalFooter={true}
                        supportSaveTableState
                        tableStateName={'report-overtime'}
                        defaultPageSize={20}
                    />
                </div>
            </div>
        </div>
    );
};

export default OvertimeReportTable;
