import React, { FC } from 'react';

type DefaultModalProps = {
    title?: string;
    message?: string;
    show: boolean;
    onClose: () => void;
    body: React.ReactElement;
    size?: 'sm' | 'md' | '2xl' | '3xl' | '4xl' | '5xl' | '7xl' | 'full' | 'screen-md' | 'screen-xl';
    maxHeight?: 'sm' | 'md' | '2xl' | '3xl' | '4xl' | '5xl' | '7xl' | 'full' | 'screen-md' | 'screen-xl';
};

type DefaultModalType = FC<DefaultModalProps>;

const DefaultModal: DefaultModalType = ((props) => {
    const { title = '', show = false, size = '2xl', body = <></>, maxHeight = 'fit', ...rest } = props;

    return (
        <>
            {show ? (
                <div>
                    <div
                        aria-hidden="false"
                        className="fixed top-0 right-0 left-0 z-[999] h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
                        data-testid="modal"
                        role="dialog"
                    >
                        <div className={`relative h-full w-full p-4 max-w-${size} max-h-${maxHeight}`}>
                            <div className="relative rounded-lg bg-white shadow dark:bg-gray-800">
                                <div className="flex items-start justify-between rounded-t dark:border-gray-600 border-b p-5">
                                    <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                                        {title}
                                    </h3>
                                    <button
                                        aria-label="Close"
                                        className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                                        type="button"
                                        onClick={rest.onClose}
                                    >
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                                <div>{body}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
});

export default DefaultModal;
