import {HttpClientError, RequestStatus} from "http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {
    DatasheetType,
    GetMapReqListRequest,
    GetDatasheetListRequest,
    PowerMapType,
    MainMapReq, GetMainMapReq, GetMapReq, GetDatasheetNewRequest, GetMapNewRequest
} from "./models";
import MapReqApi from "./mapReq.api";

export interface MapReqSlice {
    mapReqList: PowerMapType[];
    datasheetList: DatasheetType[];
    mainList: MainMapReq[];
    mapReqRows?: number;
    datasheetRows?: number;
    mainRows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getMapReqList = createAsyncThunk(
    'map-request/getMapReqList',
    async (args: GetMapReqListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MapReqApi.getMapReqList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getDatasheetList = createAsyncThunk(
    'map-request/getDatasheetList',
    async (args: GetDatasheetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MapReqApi.getDatasheetList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMapReqWithFilter = createAsyncThunk(
    'map-request/getMapReqWithFilter',
    async (args: GetMapNewRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MapReqApi.getMapReqWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getDatasheetWithFilter = createAsyncThunk(
    'map-request/getDatasheetWithFilter',
    async (args: GetDatasheetNewRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MapReqApi.getDatasheetWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainList = createAsyncThunk(
    'map-request/getMainList',
    async (args: GetMainMapReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MapReqApi.getMainList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMapReq = createAsyncThunk(
    'supporting-document/getMapReq',
    async (args: GetMapReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MapReqApi.getMapReq(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const mapReqSlice = createSlice({
    name: 'mapReqState',
    initialState: {} as MapReqSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMapReqList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMapReqList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mapReqList = action.payload.data;
            state.mapReqRows = action.payload.rows;
        });
        builder.addCase(getMapReqList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getDatasheetList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDatasheetList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.datasheetList = action.payload.data;
            state.datasheetRows = action.payload.rows;
        });
        builder.addCase(getDatasheetList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getMainList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mainList = action.payload.data;
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // update data reducer
        builder.addCase(getMapReq.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getMapReq.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(getMapReq.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});

export const { reset } = mapReqSlice.actions;
export default mapReqSlice.reducer;