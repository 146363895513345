import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {
    GetCancelRequest,
    GetHoldRequest,
    GetListRequest,
    JobCancel,
    JobCheck,
    JobHold,
    JobHoldList,
    PendingRequest
} from "./models";

export default class JobCheckApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<JobCheck[]>>(this.PATH_WORKSHEET.job_get_waiting, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<JobCheck>>(this.PATH_WORKSHEET.job_get_waiting, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
            }
        }).then((res) => res.data);
    }

    static pendingJob(args: PendingRequest, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_pending, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getHoldList(args: GetHoldRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<JobHold[]>>(this.PATH_WORKSHEET.job_get_hold, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getCancelList(args: GetCancelRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<JobCancel[]>>(this.PATH_WORKSHEET.job_get_cancel, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
}