import React, {Fragment, useEffect} from 'react';
import {Navbar} from "flowbite-react";
import {useAppDispatch, useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";
import {setDynamicNavId} from "../../sidebarPanel/sidebar.reducer";
import DynamicNavLink from "../../../components/Tabs/DynamicNavLink";

const JobReportDynamicNav = () => {
    const dispatch = useAppDispatch();

    const links = [
        {
            id: "job-statistic",
            label: "Job Statistic",
        },
        {
            id: "job-in",
            label: "Job In"
        }
    ];

    useEffect(() => {
        dispatch(setDynamicNavId('job-statistic'));
    }, []);

    return (
        <DynamicNavLink links={links}/>
    );
};

export default JobReportDynamicNav;