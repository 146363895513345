import React from 'react';
import {Navbar} from "flowbite-react";
import {Route, Routes, useRoutes} from "react-router-dom";
import JobReportDynamicNav from "../../report/jobReport/JobReportDynamicNav";
import DashboardDynamicNav from "../../dashboard/containers/DashboardDynamicNav";
import ProfileDynamicNav from "../../profile/ProfileDynamicNav";
import StaffReportDynamicNav from "../../report/staffReport/StaffReportDynamicNav";
import ProcessDetailDynamicNav from "../../worksheet/processDetail/ProcessDetailDynamicNav";
import AttendanceReportDynamicNav from "../../report/attendance/AttendanceReportDynamicNav";
import ApprovalDynamicNav from "../../approval/ApprovalDynamicNav";

const DynamicNavRoute = () => {
    return (
            <Routes>
                <Route path="dashboard" element={<DashboardDynamicNav />}/>
                <Route path="worksheet/:koj/:type/:id" element={<ProcessDetailDynamicNav />}/>
                <Route path="report/job-report" element={<JobReportDynamicNav />}/>
                <Route path="report/staff-report" element={<StaffReportDynamicNav />}/>
                <Route path="report/attendance" element={<AttendanceReportDynamicNav />}/>
                {/*<Route path="approval/:type" element={<ApprovalDynamicNav />}/>*/}
                <Route path="profile" element={<ProfileDynamicNav />}/>
            </Routes>
    );
};

export default DynamicNavRoute;