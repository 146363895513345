import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {BillJobInputModel} from "../../models";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import {getJobDropdown} from "../../../worksheet/process/job/job.reducer";
import {useAppDispatch, useAppSelector} from "../../../../helpers/redux";
import {RootState} from "../../../../app/store";
import {SingleValue} from "react-select";
import {Option, Options} from "../../../../components/SelectInput/BasicSelectInput";
import Button from "../../../../components/Button/Button";
import {
    createBillJob,
    getBillByJobNumber,
    getJobData,
    getStatusByType,
    reset,
    submitBillJobWithFile,
} from "../billJob.reducer";
import {BillTypeOptions, schema} from "../../constants";
import InputText from "../../../../components/TextInput/InputText";
import TextArea from "../../../../components/TextArea/TextArea";
import FileDropzone from "../../../../components/FileInput/Dropzone";
import {HttpClientError, RequestStatus} from "../../../../http-client";
import {customWarnOption, savedStatusOption} from "../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useParams} from "react-router-dom";
import {UserCredential} from "../../../login/models";
import AsyncSelect from "react-select/async";
import {unwrapResult} from "@reduxjs/toolkit";
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

const BillJobForm = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const [hide, setHide] = useState<string | undefined>(undefined);

    let {id} = useParams();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset: resetForm,
        formState: {errors},
    } = useForm<BillJobInputModel>({
        resolver: yupResolver(schema),
    });

    const selectedJobNumber = watch('numberJobId');
    const selectedIdNumber = watch('numberId');
    const selectedOtherNumber = watch('otherNumberJobId');
    const selectedSalesOrder = watch('salesOrderJobId');
    const selectedPONumber = watch('poNumberJobId');
    const selectedType = watch('type');
    const selectedStatus = watch('status');
    const fileWatcher = watch('local_files');

    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    // const jobOptions = useAppSelector(
    //     (state: RootState) => state.job.options ?? []
    // );
    // const isJobLoading = useAppSelector(
    //     (state: RootState) => state.job.isLoading
    // );

    const numberIdOptions = useAppSelector(
        (state: RootState) => state.billJob.numberIdOptions ?? []
    );
    const otherNumberOptions = useAppSelector(
        (state: RootState) => state.billJob.otherNumberOptions ?? []
    );
    const salesOrderOptions = useAppSelector(
        (state: RootState) => state.billJob.salesOrderOptions ?? []
    );
    const poNumberOptions = useAppSelector(
        (state: RootState) => state.billJob.poNumberOptions ?? []
    );
    const isSelectedJobLoading = useAppSelector(
        (state: RootState) => state.billJob.isLoading
    );

    const billStatusOptions = useAppSelector(
        (state: RootState) => state.billJob.billStatusOptions ?? []
    );
    const isBillStatusLoading = useAppSelector(
        (state: RootState) => state.billJob.isBillStatusLoading
    );
    const uploadProgresses = useAppSelector(
        (state: RootState) => state.billJob.uploadProgress ?? []
    );
    const status = useAppSelector(
        (state: RootState) => state.billJob.status
    );
    const error = useAppSelector(
        (state: RootState) => state.billJob.error ?? {} as HttpClientError
    );
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );

    useEffect(() => {
        if (selectedJobNumber && selectedJobNumber.value !== undefined) {
            dispatch(getJobData(selectedJobNumber.value));
            dispatch(getBillByJobNumber(selectedJobNumber.value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedJobNumber]);

    useEffect(() => {
        if (selectedType && selectedType.value !== undefined) {
            dispatch(getStatusByType(parseInt(selectedType.value)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message, true)).then(() => {
                dispatch(getBillByJobNumber(selectedJobNumber.value));
                dispatch(reset());
                resetForm();
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onSubmit = (data: BillJobInputModel) => {
        MySwal.fire(
            customWarnOption(
                'Create Bill Job',
                'Are you sure you want to create bill job?',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                const _submit = {
                    ...data,
                    numberJobId: data.numberJobId?.value,
                    numberId: data.numberId?.value,
                    otherNumberJobId: data.otherNumberJobId?.value,
                    salesOrderJobId: data.salesOrderJobId?.value,
                    poNumberJobId: data.poNumberJobId?.value,
                    type: parseInt(data.type?.value),
                    status: parseInt(data.status?.value),
                    totalPole: parseInt(data.totalPole),
                    user_qc: user.user_id
                }
                if (data.local_files) {
                    const _submitBill = {..._submit, isEdit: false, id: id};
                    dispatch(submitBillJobWithFile(_submitBill));
                } else {
                    dispatch(createBillJob(_submit));
                }
            }
        });
    };

    const onOptionChange = (val: SingleValue<Option>, valName: any) => {
        setValue(valName, val ?? {} as Option);
    };

    const onFileChange = (files: Array<File>) => {
        setValue('local_files', files);
    };

    const getDropdownJob = async(inputValue: string) => {
        const result = await dispatch(
            getJobDropdown({
                skip: 0,
                take: 100,
                job_number: inputValue.toLowerCase() === "" ? "a" : inputValue.toLowerCase(),
            })
        )

        const originalPromiseResult = unwrapResult(result);

        let _option = originalPromiseResult?.data?.map((item: any) => {
            return {
                label: `${item.number_job} | ${item.koj}`,
                text: `${item.number_job} | ${item.koj}`,
                value: item.id,
            }
        });
        return _option as Options[];
    }

    const promiseOptions = (inputValue: string) =>
        new Promise<Options[]>((resolve) => {
            clearTimeout(timer);
            setTimer(
                setTimeout(() => {
                    resolve(getDropdownJob(inputValue));
                }, 1000)
            );
        });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className="flex mt-5 mb-10">
                    <div className="w-1/6 flex items-center">
                    <span>
                        Job Number
                    </span>
                    </div>
                    <div className="w-1/2">
                        {/*<SelectInput*/}
                        {/*    key={`numberJobId`}*/}
                        {/*    options={jobOptions}*/}
                        {/*    isLoading={isJobLoading}*/}
                        {/*    onChange={(val) => onOptionChange(val, 'numberJobId')}*/}
                        {/*    value={selectedJobNumber}*/}
                        {/*/>*/}
                        <div
                            style={{
                                display: hide && "none",
                                position: "absolute",
                                marginLeft: "10px",
                                marginTop: "10px",
                                zIndex: 10
                            }}
                        >
                            {selectedJobNumber && selectedJobNumber?.label}
                        </div>
                        <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                                     value={selectedJobNumber !== null ? {
                                         value: selectedJobNumber?.value,
                                         label: selectedJobNumber?.label,
                                     } : null}
                                     onChange={(data: any) => onOptionChange({
                                         value: data.value,
                                         label: data.label,
                                         text: data.label
                                     }, 'numberJobId')}
                                     onInputChange={setHide}
                                     components={{
                                         SingleValue: () => {
                                             return null;
                                         }
                                     }}
                                     styles={{
                                         menu: provided => ({...provided, zIndex: 9999})
                                     }}
                        />
                        {errors.numberJobId?.value?.message !== '' && (
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                {errors.numberJobId?.value?.message}
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            ID Number
                        </span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`numberId`}
                            options={numberIdOptions}
                            isLoading={isSelectedJobLoading}
                            onChange={(val) => onOptionChange(val, 'numberId')}
                            value={selectedIdNumber?.value !== undefined ? selectedIdNumber : null}
                            supportCopyInputVal
                            inputValue={selectedIdNumber?.label}
                            message={errors.numberId?.value?.message}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            Other Number
                        </span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`otherNumberJobId`}
                            options={otherNumberOptions}
                            isLoading={isSelectedJobLoading}
                            onChange={(val) => onOptionChange(val, 'otherNumberJobId')}
                            value={selectedOtherNumber?.value !== undefined ? selectedOtherNumber : null}
                            supportCopyInputVal
                            inputValue={selectedOtherNumber?.label}
                            message={errors.otherNumberJobId?.value?.message}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            Sales Order
                        </span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`salesOrderJobId`}
                            options={salesOrderOptions}
                            isLoading={isSelectedJobLoading}
                            onChange={(val) => onOptionChange(val, 'salesOrderJobId')}
                            value={selectedSalesOrder?.value !== undefined ? selectedSalesOrder : null}
                            supportCopyInputVal
                            inputValue={selectedSalesOrder?.label}
                            message={errors.salesOrderJobId?.value?.message}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            PO Number
                        </span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`poNumberJobId`}
                            options={poNumberOptions}
                            isLoading={isSelectedJobLoading}
                            onChange={(val) => onOptionChange(val, 'poNumberJobId')}
                            value={selectedPONumber?.value !== undefined ? selectedPONumber : null}
                            supportCopyInputVal
                            inputValue={selectedPONumber?.label}
                            message={errors.poNumberJobId?.value?.message}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            Type
                        </span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`BillType`}
                            options={BillTypeOptions}
                            onChange={(val) => onOptionChange(val, 'type')}
                            value={selectedType?.value !== undefined ? selectedType : null}
                            supportCopyInputVal
                            inputValue={selectedType?.label}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            Status
                        </span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`BillStatus`}
                            options={billStatusOptions}
                            isLoading={isBillStatusLoading}
                            onChange={(val) => onOptionChange(val, 'status')}
                            value={selectedStatus?.value !== undefined ? selectedStatus : null}
                            supportCopyInputVal
                            inputValue={selectedStatus?.label}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            Total Poles
                        </span>
                    </div>
                    <div className="w-1/2">
                        <InputText
                            {...register('totalPole')}
                            placeholder="Input total poles"
                            type={'number'}
                            message={errors.totalPole?.message}
                            onWheel={(e) => (e.target as HTMLElement).blur()}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            VDrive Location
                        </span>
                    </div>
                    <div className="w-1/2">
                        <InputText
                            {...register('vDriveLocation')}
                            placeholder="Input VDrive Location"
                            message={errors.vDriveLocation?.message}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            Attachment
                        </span>
                    </div>
                    <div className="w-1/2">
                        <FileDropzone
                            onChange={onFileChange}
                            uploadProgress={uploadProgresses}
                            files={fileWatcher}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>
                            Notes
                        </span>
                    </div>
                    <div className="w-1/2">
                        <TextArea
                            {...register('notes')}
                            placeholder="Input notes"
                            message={errors.notes?.message}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6">

                    </div>
                    <div className="w-1/2 flex justify-end">
                        <Button
                            title="Save"
                            type="submit"
                        />
                    </div>
                </div>
            </div>

        </form>
    );
};

export default BillJobForm;
