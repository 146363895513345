import React, {useEffect, useState} from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Colors} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {PieChartModel} from "../models";
import {useAppDispatch, useAppSelector} from "../../../helpers/redux";
import {
    getDashboardHeaderKatapult,
    getDashboardHeaderMRE,
    getDashboardHeaderOcalc, getKatapultChart, getMREChart,
    getOcalcChart
} from "../dashboard.reducer";
import SeparateTabs from "../../../components/Tabs/SeparateTabs";
import {RootState} from "../../../app/store";
import {setDynamicNavId} from "../../sidebarPanel/sidebar.reducer";
import AgDataGrid from "../../../components/DataGrid/AgDataGrid";

ChartJS.register(ArcElement, Tooltip, Legend, Colors);
const DashboardOverview = () => {
    const dispatch = useAppDispatch();

    const [columnDefs, _setColumnDefs] = useState([
        {field: 'projectName', headerName: 'Project', filter: false, sortable: false},
        {field: 'availablePole', headerName: 'Available Poles', filter: false, sortable: false, aggFunc: 'sum'},
        {field: 'waitingPole', headerName: 'Waiting Poles', filter: false, sortable: false, aggFunc: 'sum'},
        {field: 'holdPole', headerName: 'Hold Poles', filter: false, sortable: false, aggFunc: 'sum'},
        {field: 'totalPole', headerName: 'Total Poles', filter: false, sortable: false, aggFunc: 'sum'},
        {field: 'totalJobs', headerName: 'Total Jobs', filter: false, sortable: false, aggFunc: 'sum'},
        {field: 'latePole', headerName: 'Late Poles', filter: false, sortable: false, aggFunc: 'sum'},
    ]);

    const OcalcTable = () => {

        useEffect(() => {
            dispatch(getDashboardHeaderOcalc());
            dispatch(getOcalcChart());
        }, []);

        const ocalcHeaderList = useAppSelector(state => state.dashboard.ocalcHeaderList ?? []);

        const ocalcChartList = useAppSelector(state => state.dashboard.ocalcChartList ?? {
            labels: [],
            datasets: [],
        } as PieChartModel);

        return (
            <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3 lg:gap-4">
                <div className="xl:col-span-2">
                    {/*<DataTable columns={columns} pagination data={ocalcHeaderList} persistTableHead/>*/}
                    <AgDataGrid rowData={ocalcHeaderList} columnDefs={columnDefs} defaultPageSize={20} tableDimension={{
                        width: "100%",
                        height: "550px",
                    }} defaultColDef={{
                        sortable: true,
                        resizable: true,
                        flex: 1,
                        minWidth: 50,
                        wrapHeaderText: true,
                        wrapText: true,
                        autoHeight: true
                    }} groupIncludeFooter={true} groupIncludeTotalFooter={true}/>
                </div>
                <div className="flex justify-center">
                    <div className="sm:w-[200px] lg:w-[400px]">
                        <Pie data={ocalcChartList}/>
                    </div>
                </div>
            </div>
        )
    }

    const MRETable = () => {
        useEffect(() => {
            dispatch(getDashboardHeaderMRE());
            dispatch(getMREChart());
        }, []);

        const mreHeaderList = useAppSelector(state => state.dashboard.mreHeaderList ?? []);

        const mreChartList = useAppSelector(state => state.dashboard.mreChartList ?? {
            labels: [],
            datasets: [],
        } as PieChartModel);

        return (
            <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3 lg:gap-4">
                <div className="xl:col-span-2">
                    {/*<DataTable columns={columns} pagination data={mreHeaderList} persistTableHead/>*/}
                    <AgDataGrid rowData={mreHeaderList} columnDefs={columnDefs} defaultPageSize={20} tableDimension={{
                        width: "100%",
                        height: "550px",
                    }} defaultColDef={{
                        sortable: true,
                        resizable: true,
                        flex: 1,
                        minWidth: 50,
                        wrapHeaderText: true,
                        wrapText: true,
                        autoHeight: true
                    }} groupIncludeFooter={true} groupIncludeTotalFooter={true}/>
                </div>
                <div className="flex justify-center">
                    <div className="sm:w-[200px] lg:w-[400px]">
                        <Pie data={mreChartList}/>
                    </div>
                </div>
            </div>
        )
    }

    const KatapultTable = () => {
        useEffect(() => {
            dispatch(getDashboardHeaderKatapult());
            dispatch(getKatapultChart());
        }, []);


        const katapultHeaderList = useAppSelector(state => state.dashboard.katapultHeaderList ?? []);
        const katapultChartList = useAppSelector(state => state.dashboard.katapultChartList ?? {
            labels: [],
            datasets: [],
        } as PieChartModel);

        return (
            <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3 lg:gap-4">
                <div className="xl:col-span-2">
                    {/*<DataTable columns={columns} pagination data={katapultHeaderList} persistTableHead/>*/}
                    <AgDataGrid rowData={katapultHeaderList} columnDefs={columnDefs} defaultPageSize={20} tableDimension={{
                        width: "100%",
                        height: "550px",
                    }} defaultColDef={{
                        sortable: true,
                        resizable: true,
                        flex: 1,
                        minWidth: 50,
                        wrapHeaderText: true,
                        wrapText: true,
                        autoHeight: true
                    }} groupIncludeFooter={true} groupIncludeTotalFooter={true}/>
                </div>
                <div className="flex justify-center">
                    <div className="sm:w-[200px] lg:w-[400px]">
                        <Pie data={katapultChartList}/>
                    </div>
                </div>
            </div>
        )
    }

    const tabs = [
        {id: 'ocalc', name: "OCALC", link: `#`, content: <OcalcTable/>},
        {id: 'mre', name: "MRE", link: `#`, content: <MRETable/>},
        {id: 'katapult', name: "Katapult", link: `#`, content: <KatapultTable/>},
    ];

    const dynamicNavId = useAppSelector((state: RootState) => state.sidebar.dynamicNavId ?? "");

    useEffect(() => {
        dispatch(setDynamicNavId('ocalc'));
    }, []);


    return (
        <div>
            <SeparateTabs tabs={tabs} openTab={dynamicNavId}/>
        </div>

    );
};

export default DashboardOverview;
