import * as yup from "yup";
import ProcessJobPrepApi from "./processJobPrep.api";
import {UserCredential} from "../../../login/models";
import {JobMainPrepColumnSort, JobPrepColumnSort} from "./models";
import {Option} from "../../../../components/SelectInput/BasicSelectInput";

export const schema = yup.object({
    range_start: yup.number().required(),
    range_end: yup.number().required(),
}).required();

export const fetchData = (_gridApi: any, user: UserCredential, perPage: number) => {
    const dataSource = {
        getRows: (params: any) => {
            ProcessJobPrepApi.getJobPrep(
                {
                    skip: params.startRow,
                    take: perPage,
                },
                user.id
            ).then((res) => {
                if (res.status) {
                    // call the success callback
                    params.successCallback(res.data, res.rows);
                } else {
                    // inform the grid request failed
                    params.successCallback([], 0);
                }
            });
        },
    };
    _gridApi.setDatasource(dataSource);
    _gridApi.refreshCells();
}

export const fetchMainData = (_gridApi: any, user: UserCredential, perPage: number) => {
    const dataSource = {
        getRows: (params: any) => {
            ProcessJobPrepApi.getMainJobPrep(
                {
                    employee_id: user.user_id,
                    skip: params.startRow,
                    take: perPage,
                },
                user.id
            ).then((res) => {
                if (res.status) {
                    // call the success callback
                    params.successCallback(res.data, res.rows);
                } else {
                    // inform the grid request failed
                    params.successCallback([], 0);
                }
            });
        },
    };
    _gridApi.setDatasource(dataSource);
    _gridApi.refreshCells();
}



export const jobPrepSortOption = [
    {
        label: "project_name",
        text: "project_name",
        value: JobPrepColumnSort.project_name.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: JobPrepColumnSort.department_name.toString()
    },
    {
        label: "kind_name",
        text: "kind_name",
        value: JobPrepColumnSort.kind_job.toString()
    },
    {
        label: "ts_created",
        text: "ts_created",
        value: JobPrepColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: JobPrepColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: JobPrepColumnSort.number_job.toString()
    },
    {
        label: "other_number",
        text: "other_number",
        value: JobPrepColumnSort.other_number.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: JobPrepColumnSort.status.toString()
    },
    {
        label: "date_due",
        text: "date_due",
        value: JobPrepColumnSort.due_date.toString()
    },
    {
        label: "date_assigned_prep",
        text: "date_assigned_prep",
        value: JobPrepColumnSort.date_assign.toString()
    },
    {
        label: "pole_total",
        text: "pole_total",
        value: JobPrepColumnSort.req_pole.toString()
    },
    {
        label: "check_job_user_name",
        text: "check_job_user_name",
        value: JobPrepColumnSort.ready_in.toString()
    },
    {
        label: "check_job_date",
        text: "check_job_date",
        value: JobPrepColumnSort.date_ready.toString()
    },
]

export const mainJobPrepSortOption = [
    {
        label: "project_name",
        text: "project_name",
        value: JobMainPrepColumnSort.project_name.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: JobMainPrepColumnSort.department_name.toString()
    },
    {
        label: "kind_name",
        text: "kind_name",
        value: JobMainPrepColumnSort.kind_job.toString()
    },
    {
        label: "ts_created",
        text: "ts_created",
        value: JobMainPrepColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: JobMainPrepColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: JobMainPrepColumnSort.number_job.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: JobMainPrepColumnSort.status.toString()
    },
    {
        label: "date_due",
        text: "date_due",
        value: JobMainPrepColumnSort.due_date.toString()
    },
    {
        label: "date_assigned_prep",
        text: "date_assigned_prep",
        value: JobMainPrepColumnSort.date_assign.toString()
    },
    {
        label: "pole_total",
        text: "pole_total",
        value: JobMainPrepColumnSort.req_pole.toString()
    },
]