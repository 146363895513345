import React, {useEffect} from 'react';
import {getBillByJob, getBillByJobNumber, getBillJobDetail} from "../../billJob/billJob.reducer";
import {useAppDispatch} from "../../../../helpers/redux";
import ReportBillingOverviewBillTable from "./ReportBillingOverviewBillTable";
import ReportBillingOverviewJobTable from "./ReportBillingOverviewJobTable";

const ReportBillingOverview = ({numberJobId = undefined, numberJob = undefined}: ReportBillingDetailProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (numberJobId !== undefined) {
            // dispatch(getBillByJob(numberJobId));
            dispatch(getBillByJobNumber(numberJobId));
        }
    }, [numberJobId]);

    useEffect(() => {
        if (numberJob !== undefined) {
            dispatch(getBillJobDetail(numberJob));
        }
    }, [numberJob]);

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div>
                <div className="flex mt-5 mb-10">
                    <div className="w-1/6 flex items-center">
                    <span>
                        Job Number
                    </span>
                    </div>
                    <div className="w-1/2">
                        <span>
                            <b>{numberJob}</b>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <ReportBillingOverviewBillTable/>
            </div>
            <div>
                <ReportBillingOverviewJobTable/>
            </div>
        </div>
    );
};

export interface ReportBillingDetailProps {
    numberJobId?: string;
    numberJob?: string;
}

export default ReportBillingOverview;
