import {BaseServiceClass} from "../../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../../http-client";
import {
    GetQCListRequest, GetQCMainWithFilterRequest,
    GetQCRequest,
    GetQCWithFilterRequest,
    JobQCSummary,
    MainQCType,
    QCType
} from "./models";

export default class QcApi extends BaseServiceClass {
    static getQCList(args: GetQCListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<QCType[]>>(this.PATH_WORKSHEET.job_get_qc, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getSingleQC(args: GetQCListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<QCType>>(this.PATH_WORKSHEET.job_get_qc, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: args.id,
                skip: 0,
                take: 1
            }
        }).then((res) => res.data);
    }
    static getQCWithFilterList(args: GetQCWithFilterRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<QCType[]>>(this.PATH_WORKSHEET.job_get_qc_with_filter, args,{
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
    static getQCMainList(args: GetQCListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<MainQCType[]>>(this.PATH_WORKSHEET.job_get_qc_main, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getMainQCWithFilterList(args: GetQCMainWithFilterRequest, token: string, userId: string) {
        return httpClient.post<ApiResponseWrapper<MainQCType[]>>(this.PATH_WORKSHEET.job_get_qc_main_with_filter, {...args, employee_id: userId}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
    static getJobQCSummary(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<JobQCSummary[]>>(this.PATH_WORKSHEET.job_get_qc_summary, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }

    static getAssignQC(args: GetQCRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_qc_task, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data.data);
    }
}