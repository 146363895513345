import {BaseServiceClass} from "../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../http-client";
import {GetSupportingDocumentReportReq, SupportingDocumentReportModel} from "./models";

export default class SupportingDocumentReportApi extends BaseServiceClass {
    static getSupportingDocumentReport(args: GetSupportingDocumentReportReq, token: string) {
        return httpClient.get<ApiResponseWrapper<SupportingDocumentReportModel[]>>(this.PATH_REPORT.get_supporting_document_log, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
}