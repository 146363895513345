import React, {useState} from 'react';
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {getSupportingDocumentReport} from "../supportingDocumentReport.reducer";
import {useAppDispatch} from "../../../../helpers/redux";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import {dateSetTimeToNow} from "../../../../helpers/convert";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../approval/constants";
import {supportingDocumentSortOption} from "../constants";

const SupportingDocumentReportTable = () => {
    const dispatch = useAppDispatch();
    const gridRef = React.useRef<any>();

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {
            field: 'date_request',
            headerName: 'Req Date',
            cellDataType: 'date_time',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        // {
        //     field: 'employee_name_req_by', headerName: 'Req By', filter: 'agTextColumnFilter', filterParams: {
        //         filterOptions: ['contains'],
        //         maxNumConditions: 1,
        //     }
        // },
        {
            field: 'job_number', headerName: 'Job Number', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'job_id', headerName: 'ID Number', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'other_number', headerName: 'Other Number', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'sales_order', headerName: 'Sales Order', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'area', headerName: 'Area', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'type_name', headerName: 'Type', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'status_name', headerName: 'Job Status', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'total_pole', headerName: 'Total Poles'},
        {
            field: 'note', headerName: 'Note', filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'employee_name_req_by', headerName: 'Req By', filter: false, sortable: false},
        // {field: 'get_by', headerName: 'Get By', filter: false, sortable: false},
        {field: 'valueName', headerName: 'Supporting Document Status', filter: false, sortable: false},
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel ) ? undefined : getSortModel(supportingDocumentSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel ) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom, dateTo} = params.request.filterModel?.date_request || {};

                dispatch(getSupportingDocumentReport(
                    {
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        startDate: dateFrom !== undefined ? dateSetTimeToNow(dateFrom) : undefined,
                        endDate: dateTo !== undefined ? dateSetTimeToNow(dateTo) : undefined,
                        filterByEmployeeName: params.request.filterModel?.employee_name_req_by?.filter,
                        filterByJobId: params.request.filterModel?.job_id?.filter,
                        filterByJobNumber: params.request.filterModel?.job_number?.filter,
                        filterByNote: params.request.filterModel?.note?.filter,
                        filterBySalesOrder: params.request.filterModel?.sales_order?.filter,
                        filterByOtherNumber: params.request.filterModel?.other_number?.filter,
                        filterByArea: params.request.filterModel?.area?.filter,
                        filterByType: params.request.filterModel?.type_name?.filter,
                        filterByStatus: params.request.filterModel?.status_name?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    }
                )).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            }
        }
    }

    return (
        <div>
            <div className="mt-3">
                <div>
                </div>
            </div>
            <div className="main-data-grid fielding mt-5">
                <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                        getServerSideDataSource={getServerSideDataSource}
                                        supportSaveTableState
                                        tableStateName={'report-supporting-document'}
                                        defaultPageSize={20}
                />
            </div>
        </div>
    );
};

export default SupportingDocumentReportTable;
