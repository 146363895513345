import React, {useEffect} from 'react';
import TextArea from 'components/TextArea/TextArea';
import {useForm} from "react-hook-form";
import {InputCompleteDateModel, InputStatusModel} from "../models";
import Button from "components/Button/Button";
import BasicSelectInput from "components/SelectInput/BasicSelectInput";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import {getList} from "features/master/jobSubjectNotes/jobSubjectNotes.reducer";
import {isArrayEmpty} from "helpers/checkEmptiness";
import BasicDatePicker from "../../../../../components/DatePicker/BasicDatePicker";

const JobChangeCompleteDate = ({
    jobId = '',
    completeDate = undefined,
    ...props
}: JobChangeCompleteDateProps) => {
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<InputCompleteDateModel>();

    useEffect(() => {
        if (completeDate !== undefined) {
            reset({
                completeDate: completeDate,
            } as InputCompleteDateModel);
        }
    }, []);

    const onSubmit = (data: InputCompleteDateModel) => props.onSubmit(jobId, data);

    return (
        <div className='p-5'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BasicDatePicker
                    label="Job Complete Date"
                    name="completeDate"
                    placeholderText="Select Complete Date"
                    control={control}
                    withPreciseTime
                />
                <div className="pt-2 pb-2 flex content-end">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface JobChangeCompleteDateProps {
    jobId: string;
    completeDate?: string;
    onSubmit: (jobId: string, data: InputCompleteDateModel) => void;
}

export default JobChangeCompleteDate;
