import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {dateSetTimeToNow} from "../../../../helpers/convert";
import {useAppDispatch, useAppSelector} from "../../../../helpers/redux";
import {RootState} from "../../../../app/store";
import {UserCredential} from "../../../login/models";
import {billedGDev} from "../gdev.reducer";
import InputText from "../../../../components/TextInput/InputText";
import {GDevDataModel} from "../../models";
import Button from "../../../../components/Button/Button";
import BasicNullableDatePicker from "../../../../components/DatePicker/BasicNullableDatePicker";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";

type FormType = {
    gdev_approve_pole: string;
    sT_date: string | null;
    mic_date: string | null;
}

interface GDevFormProps {
    data: GDevDataModel | undefined;
}

const GDevForm = ({data = undefined}: GDevFormProps) => {
    const dispatch = useAppDispatch();
    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
    } = useForm<FormType>({
        defaultValues: {
            gdev_approve_pole: '0',
            sT_date: null,
            mic_date: null,
        }
    });

    useEffect(() => {
        if (data !== undefined) {
            console.log(data);
            reset({
                gdev_approve_pole: data.gdev_approve_pole?.toString() ?? '0',
                sT_date: data.sT_date ? dateSetTimeToNow(new Date(data.sT_date).toISOString()) : null,
                mic_date: data.mic_date ? dateSetTimeToNow(new Date(data.mic_date).toISOString()) : null,
            });
        }
    }, [data]);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );

    const onSubmit = (formData: FormType) => {
        let _data = {
            gdev_approve_pole: parseInt(formData.gdev_approve_pole) ?? 0,
            sT_date: formData.sT_date,
            mic_date: formData.mic_date,
        };
        if (!data) return;
        dispatch(billedGDev({
            ..._data,
            job_id: data.id,
            user_gdev: user.name
        }));
    }

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputText
                    {...register('gdev_approve_pole')}
                    label="Pole Approved"
                    placeholder="Input Pole Approved"
                    type="number"
                    message={errors.gdev_approve_pole?.message}
                />
                <div className={'flex'}>
                    <div className={'flex grow-1 w-full'}>
                        <BasicNullableDatePicker
                            label="ST Date"
                            name="sT_date"
                            placeholderText="Select ST Date"
                            control={control}
                            withPreciseTime
                        />
                    </div>
                    <div className={'flex items-end mb-2 ml-2'}>
                        <FlowbiteButton
                            onClick={() => setValue('sT_date', null)}
                            type="button"
                            color="success"
                        >Unset</FlowbiteButton>
                    </div>
                </div>

                <div className={'flex'}>
                    <div className={'flex grow-1 w-full'}>
                        <BasicNullableDatePicker
                            label="MIC Date"
                            name="mic_date"
                            placeholderText="Select MIC Date"
                            control={control}
                            withPreciseTime
                        />
                    </div>
                    <div className={'flex items-end mb-2 ml-2'}>
                        <FlowbiteButton
                            onClick={() => setValue('mic_date', null)}
                            type="button"
                            color="success"
                        >Unset</FlowbiteButton>
                    </div>
                </div>

                <div className="pt-2 pb-2">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export default GDevForm;
