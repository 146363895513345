import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {
    JobFileMRE,
    JobFileQCMRE,
    UpdateMRENote,
    UpdateQCMRENote
} from "./models";
import {JobFilePrep} from "../jobPrep/models";

export default class MreApi extends BaseServiceClass {
    static getMREFile(id: string, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobFileMRE[]>>(this.PATH_WORKSHEET.job_file_mre, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    job_id: id,
                },
            })
            .then((res) => res.data);
    }

    static createMREFile(args: JobFilePrep, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_mre, args, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then((res) => res.data)
            .catch((err) => err);
    }

    static deleteMREFile(id: string, token: string) {
        return httpClient
            .delete<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_mre, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    id: id,
                },
            })
            .then((res) => res.data);
    }

    static getQCMREFile(id: string, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobFileQCMRE[]>>(this.PATH_WORKSHEET.job_file_qc_mre_get, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    job_id: id,
                },
            })
            .then((res) => res.data);
    }

    static createQCMREFile(args: JobFilePrep, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_qc_mre_post, args, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then((res) => res.data)
            .catch((err) => err);
    }

    static deleteQCMREFile(id: string, token: string) {
        return httpClient
            .delete<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_file_qc_mre_delete, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    id: id,
                },
            })
            .then((res) => res.data);
    }

    static updateMRENotes(args: UpdateMRENote, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_update_mre_notes, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static updateQCMRENotes(args: UpdateQCMRENote, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_update_qc_mre_notes, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static readyToQCMRE(id: string, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_mre_ready_to_qc_mre, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: id
            }
        }).then((res) => res.data.data);
    }
    static completeMRE(id: string, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_mre_complete, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: id
            }
        }).then((res) => res.data.data);
    }
}