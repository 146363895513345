import React, {useCallback, useEffect, useRef, useState} from 'react';
import Text from '../../../../../components/Text/Text';
import {useAppDispatch, useAppSelector} from '../../../../../helpers/redux';
import {RootState} from '../../../../../app/store';
import {UserCredential} from '../../../../login/models';
import {useForm} from 'react-hook-form';
import Button from '../../../../../components/Button/Button';
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {customWarnOption, savedStatusOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {QCType, InputAssignQC, EmployeeQC} from "../models";
import {getAssignQC, getJobQCSummary, getSingleQC, reset} from "../qc.reducer";
import {getListByPosition} from "../../../../master/employee/employee.reducer";
import DatePickerState from "../../../../../components/DatePicker/DatePickerState";
import {isArrayEmpty, isObjectEmpty} from "../../../../../helpers/checkEmptiness";
import InputCheckbox from "../../../../../components/Checkbox/InputCheckbox";
import {useSelected} from "../../../../../hooks/checkbox/useSelected";
import {useShiftSelected} from "../../../../../hooks/checkbox/useShiftSelected";
import {
    getPoleByJob,
    removeMultiplePoleAssign,
    removePoleAssign,
    reset as resetPoles
} from "../../../../basic/pole/pole.reducer";
import {MdOutlineDeleteForever} from "react-icons/md";
import {Pole, PoleAssignmentTypeEnum, PoleGetByJobColumnSort} from "../../../../basic/pole/models";
import AgDataGridStatic from "../../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import moment from "moment";
import FormLoader from "../../../../../components/Loader/FormLoader";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";
import {SortingEnum} from "../../../../approval/models";

const AssignQC = ({id = undefined, ...props}: AssignQCProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const {
        handleSubmit,
        reset: resetForm,
    } = useForm<InputAssignQC>({});

    const [selectedRemove, setSelectedRemove] = useState<Array<string>>([]);
    const [userGetPoles, setUserGetPoles] = useState<Array<EmployeeQC>>([]);
    const [selection, setSelection] = useState<Array<string>>([]);
    const [initialPoleIds, setInitialPoleIds] = useState<Array<string>>([]);

    const [dueDate, setDueDate] = useState<Date>(new Date());

    const job = useAppSelector((state: RootState) => state.qc.singleQC ?? {} as QCType);
    const isQCLoading = useAppSelector((state: RootState) => state.qc.isSingleLoading);
    const isAssignLoading = useAppSelector((state: RootState) => state.qc.isAssignLoading);

    const userState = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const qcSummaryList = useAppSelector((state: RootState) => state.qc.qcSummaryList ?? []);
    const status = useAppSelector((state: RootState) => state.qc.status);
    const error = useAppSelector((state: RootState) => state.qc.error ?? ({} as HttpClientError));
    const pole = useAppSelector(
        (state: RootState) => state.pole.poleByJob ?? []
    );
    const isPoleLoading = useAppSelector((state: RootState) => state.pole.isLoading);
    const statusPole = useAppSelector((state: RootState) => state.pole.status);
    const errorPole = useAppSelector((state: RootState) => state.pole.error ?? ({} as HttpClientError));

    const {selected, change, clear} = useSelected([] as Array<string>);
    const onChange = useShiftSelected(initialPoleIds, change);

    useEffect(() => {
        dispatch(getListByPosition({skip: 0, take: 10, position: 'QC'}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleQC({id: id, skip: 0, take: 1}));
            dispatch(getJobQCSummary(id));
        }
    }, [id]);

    useEffect(() => {
        if (!isObjectEmpty(job)) {
            dispatch(getPoleByJob({job_id: job.id as string, skip: 0, take: 1000, column: PoleGetByJobColumnSort.index, sort: SortingEnum.Ascending}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [job]);

    useEffect(() => {
        if (!isObjectEmpty(job) && !isArrayEmpty(pole)) {
            let employeeList: Array<EmployeeQC> = job.employee_prep_list ?? [];
            let selectedPoleIds: string[] = [];
            employeeList.map((item: EmployeeQC) => {
                selectedPoleIds = [...selectedPoleIds, item.pole_ids ?? ""]
            });
            let _employeeList = pole.map((item: Pole) => {
                const singleEmployee = employeeList.find((employee: EmployeeQC) => employee.pole_ids === item.id) ?? {} as EmployeeQC;
                return {
                    employee_id: singleEmployee?.employee_id ?? "-",
                    employee_name: singleEmployee?.employee_name ?? "-",
                    pole_ids: item.id,
                    pole_sequences: singleEmployee?.pole_sequences ?? "-",
                    isStatic: isObjectEmpty(singleEmployee)
                }
            });
            setUserGetPoles(_employeeList);
            setDueDate(job.date_due === "0001-01-01T00:00:00" ? new Date() : new Date(job.date_due));
            setSelection([...selectedPoleIds]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [job, pole]);

    useEffect(() => {
        if (!isArrayEmpty(pole)) {
            const initialPoleIds = pole
                .filter(item => !item.employee_qc_id)
                .map(item => item.id);
            setInitialPoleIds(initialPoleIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pole]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                resetForm();
                props.onHide();
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    useEffect(() => {
        if (statusPole !== undefined && statusPole !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(statusPole === RequestStatus.success, errorPole.data?.message)).then(() => {
                resetForm();
                dispatch(resetPoles());
                setSelectedRemove([]);
                props.onHide();
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusPole, errorPole]);

    const onSubmit = (data: InputAssignQC) => {

        let _selectedPoleId = selected.map((item) => item.split('&')[0]);

        if (!isArrayEmpty(_selectedPoleId) && data.employee_id !== '') {
            MySwal.fire(
                customWarnOption(
                    'Assign Job',
                    'Are you sure? You will not be able to recover this data!',
                )
            ).then((result) => {
                if (result.isConfirmed) {
                    dispatch(getAssignQC({
                        id: job.id,
                        date_due: dueDate.toISOString(),
                        employee_id: userState.user_id,
                        pole_id_list: _selectedPoleId,
                    }));
                    clear();
                }
            });
        }
    };

    const onDelete = (poleId: string) => {
        MySwal.fire(
            customWarnOption(
                'Remove Get',
                'Are you sure you want to remove this GET? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(removePoleAssign({
                    poleId: poleId,
                    type: PoleAssignmentTypeEnum.qc
                }));
            }
        });
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <>
                {
                    userState.user_id === props.data?.employee_id &&
                    <div className="flex align-middle h-[100%]">
                        <button type="button" onClick={() => onDelete(props.data?.pole_ids)}>
                            <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                        </button>
                    </div>
                }
            </>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            width: 30,
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
            checkboxSelection: (props: any) => userState.user_id === props.data?.employee_id,
            showDisabledCheckboxes: false,
        },
        {field: 'employee_name', headerName: 'Name', filter: 'agTextColumnFilter'},
        {field: 'pole_sequences', headerName: 'Pole Seq', filter: 'agTextColumnFilter'},
        {
            headerName: 'Actions',
            field: 'actions',
            suppressCellFlash: true,
            cellRenderer: ActionCellRender,
        },
    ]);

    const onSelectionChanged = useCallback(() => {
        let selectedRows = gridRef.current.api.getSelectedNodes();
        let _selectedRows = selectedRows
            .filter((item: any) => item.data.employee_id === userState.user_id)
            .map((item: any) => item.data.pole_ids);
        setSelectedRemove(_selectedRows);
    }, []);

    const onUnassignHandler = () => {
        const _multipleSelection = selectedRemove.map((item) => {
            return {
                poleId: item,
                type: PoleAssignmentTypeEnum.qc
            }
        });
        MySwal.fire(
            customWarnOption(
                'Remove Get',
                'Are you sure you want to remove this GET? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeMultiplePoleAssign(_multipleSelection));
            }
        });
    }

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <FormLoader isLoading={isQCLoading || isPoleLoading} label="Fetching Data ...">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex">
                        <div className="w-1/2">
                            <Text label="Job Number" value={job.number_job} suppressBorder/>
                            <Text label="District" value={job.district_name} suppressBorder/>
                            <DatePickerState
                                label="Due Date"
                                name="date_due"
                                placeholderText="Select Due Date"
                                selected={dueDate}
                                onChange={(date) => {
                                    setDueDate(date ?? new Date());
                                }}
                            />
                        </div>
                        <div className="w-1/2">
                            <Text label="QC" value={userState.name} suppressBorder/>
                            <Text label="Type" value={job.type_name} suppressBorder/>
                        </div>
                    </div>

                    <div className="h-[36vh] my-5 border border-[#dfdfdf] rounded-lg">
                        <div className="h-[40px] bg-[#e7ebe6] rounded-t-lg flex items-center">
                            <label className="block text-sm font-bold text-gray-700 dark:text-gray-300 px-3">
                                Poles
                            </label>
                        </div>
                        <div className="h-[calc(36vh-50px)] overflow-auto">
                            <div className="grid md:grid-cols-4 gap-4 p-3">
                                {
                                    pole.map((item, index) => (
                                        <div key={item.id} className={"flex"}>
                                            <label
                                                className={`mr-2 text-sm font-bold text-gray-900 dark:text-gray-300`}
                                                htmlFor={item.id}
                                            >
                                                {`${index + 1}.`}
                                            </label>
                                            <InputCheckbox
                                                className="my-3"
                                                label={item.seq ?? ""}
                                                id={item.id}
                                                onChange={(event) => onChange(event, item.id)}
                                                checked={selection.includes(item.id) || selected.includes(item.id)}
                                                picked={selection.includes(item.id)}
                                                disabled={selection.includes(item.id)}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="h-[36vh] my-5 border border-[#dfdfdf] rounded-lg">
                        <div className="h-[40px] bg-[#e7ebe6] rounded-t-lg flex items-center">
                            <label className="block text-sm font-bold text-gray-700 dark:text-gray-300 px-3">
                                Summary
                            </label>
                        </div>
                        <div className="h-[calc(36vh-40px)] overflow-auto">
                            <div className="flex">
                                <div className="w-1/2 overflow-auto h-[276px]">
                                    {
                                        qcSummaryList.map((item) => (
                                            <div
                                                className="flex h-[40px] p-4 items-center text-xs border border-[#dfdfdf] hover:bg-[#fcebe0]">
                                                <div className="w-1/3 truncate">
                                                    <b>{item.employee_name}</b>
                                                </div>
                                                <div className="w-1/3">
                                                    <span className="text-[#919191]">Total Poles </span>
                                                    <b>{item.total}</b>
                                                </div>
                                                <div className="w-1/3">
                                                    <span
                                                        className="text-[#919191]">Date </span><b>{moment(item.date_due).format("D MMM YYYY")}</b>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="w-1/2 h-[calc(36vh-40px)]">
                                    <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={userGetPoles}
                                                      tableDimension={{
                                                          width: '100%',
                                                          height: '100%'
                                                      }} containerClass={"h-[calc(36vh-96px)]"} defaultColDef={{
                                        sortable: true,
                                        resizable: true,
                                        flex: 1,
                                        minWidth: 50,
                                        wrapHeaderText: true,
                                        wrapText: true,
                                        autoHeight: true
                                    }} isRowSelectable={() => true} onSelectionChanged={onSelectionChanged}
                                                      isCustomHeader={true}
                                                      customChildHeaderComponent={
                                                          <>
                                                              <FlowbiteButton
                                                                  onClick={onUnassignHandler}
                                                                  size="xs"
                                                                  color="success"
                                                                  disabled={selectedRemove.length === 0}
                                                              >
                                                                  <MdOutlineDeleteForever className="mr-2 h-5 w-5"/>
                                                                  Unassign Selected ({selectedRemove.length})
                                                              </FlowbiteButton>
                                                          </>
                                                      }/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <Button
                            color="success"
                            type="submit"
                            title="Submit"
                            isLoading={isAssignLoading}
                        />
                    </div>
                </form>
            </FormLoader>
        </div>
    );
};

export interface AssignQCProps {
    // job: QCType;
    id?: string;
    onHide: () => void;
}

export default AssignQC;
