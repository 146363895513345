import React from 'react';
import JobMREDetail from "../components/JobMREDetail";

const JobMREDetailPage = () => {
    return (
        <div>
            <JobMREDetail />
        </div>
    );
};

export default JobMREDetailPage;
