import React, {useMemo, useRef, useState} from 'react';
import AgDataGrid from "../../../../../components/DataGrid/AgDataGrid";
import {DetailRole} from "../../models";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../../../../helpers/redux";
import {IoInformationCircleOutline} from "react-icons/io5";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import AgDataGridStatic from "../../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";

const DetailEmployeeWorkProcess = ({data = [], name = undefined}: DetailEmployeeWorkProps) => {
    const subGridRef = useRef<any>(null);

    const employeeWork = useAppSelector(
        (state) => state.staffReport.employeeWorkList ?? []
    );

    const [showLog, setShowLog] = useState<boolean>(false);
    const [readyToAssignLog, setReadyToAssignLog] = useState<Array<{date: string}>>([]);
    const [readyToQCLog, setReadyToQCLog] = useState<Array<{date: string}>>([]);
    const [readyToQCMRELog, setReadyToQCMRELog] = useState<Array<{date: string}>>([]);

    const [subColumnDefs, _setSubColumnDefs] = useState([
        // { field: 'name', headerName: 'Name', filter: true, cellRenderer: 'agGroupCellRenderer' },
        // { field: 'teamName', headerName: 'Team', filter: true},
        {field: 'positionName', headerName: 'Position', filter: true, cellRenderer: 'agGroupCellRenderer'},
        {
            field: 'totalJobComplete',
            headerName: 'Total Job Completed',
            aggFunc: 'sum'
        },
        // {field: 'processJob', headerName: 'Processes Pole', aggFunc: 'sum'},
        {field: 'totalPole', headerName: 'Total Poles', aggFunc: 'sum'},
        {field: 'addPole', headerName: 'Add Poles', aggFunc: 'sum'},
        {field: 'revisionPole', headerName: 'Revision Poles', aggFunc: 'sum'},
        {field: 'sumPole', headerName: 'Sum Poles', aggFunc: 'sum'},
        {field: 'mre', headerName: 'MRE', aggFunc: 'sum'},
        {field: 'katapult', headerName: 'Katapult', aggFunc: 'sum'},
    ]);

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={props.data?.koj === "MRE" ? `/worksheet/mre/mre-detail/${props.data?.id}`
                : props.data?.koj === "Katapult" ? `/worksheet/katapult/katapult-detail/${props.data?.id}`
                    : `/worksheet/ocalc/job-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const showLogModal = (data: any) => {
        setShowLog(true);
        setReadyToAssignLog((data?.ready_to_assign_date_list as string)?.split(',').map((date: string) => ({date})));
        setReadyToQCLog((data?.ready_to_QC_date_list as string)?.split(',').map((date: string) => ({date})));
        setReadyToQCMRELog((data?.ready_to_QC_MRE_list as string)?.split(',').map((date: string) => ({date})));
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <>
                    <button onClick={() => showLogModal(props.data)}>
                        <IoInformationCircleOutline className="mx-1 text-2xl text-primary-color"/>
                    </button>
                </>
            </div>
        )
    }

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                rowSelection: "multiple",
                enableRangeSelection: true,
                dataTypeDefinitions: {
                    date: {
                        baseDataType: 'date',
                        extendsDataType: 'date',
                        valueParser: (params: any) => {
                            if (params.data === undefined) {
                                return null;
                            }
                            if (params.newValue == null) {
                                return null;
                            }
                            // convert from `dd/mm/yyyy`
                            return params?.value === "0001-01-01T00:00:00" ? "-" : new Date(params?.value).toLocaleDateString('en-GB');
                        },
                        valueFormatter: (params: any) => {
                            if (params.data === undefined) {
                                return null;
                            }
                            // convert to `dd/mm/yyyy`
                            return params?.value === "0001-01-01T00:00:00" ? "-" : new Date(params?.value).toLocaleDateString('en-US');
                        },
                    }
                },
                groupIncludeFooter: true,
                groupIncludeTotalFooter: true,
                columnDefs: [
                    {field: "project", headerName: "Project (Job)"},
                    {field: "koj", headerName: "KoJ"},
                    {field: "number_id", headerName: "ID Number"},
                    {
                        headerName: 'Job Number',
                        field: 'number_job',
                        suppressCellFlash: true,
                        cellRenderer: JobNumberLinkCellRender,
                    },
                    {field: "job_type_name", headerName: "Type"},
                    {field: "district", headerName: "District"},
                    {
                        field: 'waiting_ts',
                        headerName: 'Waiting Date',
                        cellDataType: 'date',
                    },
                    {
                        field: 'hold_ts',
                        headerName: 'Hold Date',
                        cellDataType: 'date',
                    },
                    {
                        field: 'complete_date',
                        headerName: 'Complete Date',
                        cellDataType: 'date',
                    },
                    {
                        field: 'cancel_ts',
                        headerName: 'Cancel Date',
                        cellDataType: 'date',
                    },
                    {
                        field: 'actions',
                        headerName: 'Status Logs',
                        cellRenderer: ActionCellRender,
                        cellStyle: () => ({
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        })
                    },
                    // {
                    //     field: 'ready_to_assign_date_list',
                    //     headerName: 'Ready To Assign Date',
                    // },
                    // {
                    //     field: 'ready_to_QC_date_list',
                    //     headerName: 'Ready To QC Date',
                    // },
                    // {
                    //     field: 'ready_to_QC_MRE_list',
                    //     headerName: 'Ready To QC MRE Date',
                    //     hide: true,
                    // },
                    {field: "poles", headerName: "Poles", aggFunc: 'sum'},
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params: any) => {
                params.successCallback(params.data.details);
                console.log(params.data);
            }
        };
    }, []);

    const defaultCsvExportParams = useMemo(() => {
        return {
            getCustomContentBelowRow: (params: any) => {
                const rows = getRows(params);
                return rows.map((row) => row.cells);
            },
        };
    }, [employeeWork]);

    const defaultExcelExportParams = useMemo(() => {
        return {
            getCustomContentBelowRow: (params: any) => getRows(params),
            columnWidth: 120,
            fileName: `employee_work_${name}_${new Date().toLocaleDateString('en-US')}.xlsx`,
        };
    }, [employeeWork]);

    const getRows = (params: any) => {
        if (params.node?.footer) {
            return [];
        } else {
            if (!params.node.data.details || params.node.data.details?.length === 0) return [];
            return [
                {
                    outlineLevel: 1,
                    cells: [
                        cell(''),
                        cell('Project (Job)', 'header'),
                        cell('KoJ', 'header'),
                        cell('ID Number', 'header'),
                        cell('Job Number', 'header'),
                        cell('Type', 'header'),
                        cell('District', 'header'),
                        cell('Waiting Date', 'header'),
                        cell('Hold Date', 'header'),
                        cell('Cancel Date', 'header'),
                        cell('Complete Date', 'header'),
                        cell('Poles', 'header'),
                        cell('Ready To Assign Log', 'header'),
                        cell('Ready To QC Log', 'header'),
                        cell('Ready To QC MRE Log', 'header'),
                    ],
                },
            ].concat(
                ...params.node.data.details?.map((record: any) => [
                    {
                        outlineLevel: 1,
                        cells: [
                            cell(''),
                            cell(record?.project ?? "-", 'body'),
                            cell(record?.koj ?? "-", 'body'),
                            cell(record?.number_id ?? "-", 'body'),
                            cell(record?.number_job ?? "-", 'body'),
                            cell(record?.job_type_name ?? "-", 'body'),
                            cell(record?.district ?? "-", 'body'),
                            cell(record?.waiting_ts === "0001-01-01T00:00:00" ? "-" : new Date(record.waiting_ts).toLocaleDateString('en-US'), 'body'),
                            cell(record?.hold_ts === "0001-01-01T00:00:00" ? "-" : new Date(record.hold_ts).toLocaleDateString('en-US'), 'body'),
                            cell(record?.cancel_ts === "0001-01-01T00:00:00" ? "-" : new Date(record.cancel_ts).toLocaleDateString('en-US'), 'body'),
                            cell(record?.complete_date === "0001-01-01T00:00:00" ? "-" : new Date(record.complete_date).toLocaleDateString('en-US'), 'body'),
                            cell(record?.poles ?? "-", 'body'),
                            cell(record?.ready_to_assign_date_list ?? "-", 'body'),
                            cell(record?.ready_to_QC_date_list ?? "-", 'body'),
                            cell(record?.ready_to_QC_MRE_list ?? "-", 'body'),
                        ],
                    },
                ])
            );
        }
    };

    const cell = (text: string, styleId?: string | undefined) => {
        return {
            styleId: styleId,
            data: {
                type: /^\d+$/.test(text) ? 'Number' : 'String',
                value: String(text),
            },
        };
    };

    const ShowLogModal = () => {

        const [columnDefs, _setColumnDefs] = useState([
            {
                headerName: '#',
                field: 'rowNumber',
                cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            },
            {field: 'date', headerName: 'Date'},
        ])

        return (
            <div className="p-5">
                <div className={"flex"}>
                    <div className={"w-1/3 mx-2"}>
                        <h1 className={"mb-3 font-bold text-sm"}>Ready To Assign Log</h1>
                        <AgDataGridStatic columnDefs={columnDefs} rowData={readyToAssignLog} tableDimension={{
                            width: "100%",
                            height: "300px",
                        }} defaultColDef={{
                            sortable: true,
                            resizable: true,
                            minWidth: 50,
                            wrapHeaderText: true,
                            wrapText: true,
                            autoHeight: true,
                            flex: 1
                        }}/>
                    </div>
                    <div className={"w-1/3 mx-2"}>
                        <h1 className={"mb-3 font-bold text-sm"}>Ready To QC Log</h1>
                        <AgDataGridStatic columnDefs={columnDefs} rowData={readyToQCLog} tableDimension={{
                            width: "100%",
                            height: "300px",
                        }} defaultColDef={{
                            sortable: true,
                            resizable: true,
                            minWidth: 50,
                            wrapHeaderText: true,
                            wrapText: true,
                            autoHeight: true,
                            flex: 1
                        }}/>
                    </div>
                    <div className={"w-1/3 mx-2"}>
                        <h1 className={"mb-3 font-bold text-sm"}>Ready To QC MRE Log</h1>
                        <AgDataGridStatic columnDefs={columnDefs} rowData={readyToQCMRELog} tableDimension={{
                            width: "100%",
                            height: "300px",
                        }} defaultColDef={{
                            sortable: true,
                            resizable: true,
                            minWidth: 50,
                            wrapHeaderText: true,
                            wrapText: true,
                            autoHeight: true,
                            flex: 1
                        }}/>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div>
            <AgDataGrid
                rowData={data}
                columnDefs={subColumnDefs}
                masterDetail={true}
                detailCellRendererParams={detailCellRendererParams}
                defaultCsvExportParams={defaultCsvExportParams}
                defaultExcelExportParams={defaultExcelExportParams}
                // isExternalExport
                groupIncludeFooter={true}
                groupIncludeTotalFooter={true}
                ref={subGridRef}
                tableHeight={400}
                defaultPageSize={20}
            />

            <DefaultModal
                title="Date Status Logs"
                size={"7xl"}
                show={showLog}
                onClose={() => setShowLog(false)}
                body={<ShowLogModal/>}
            />
        </div>
    );
};

export interface DetailEmployeeWorkProps {
    data: DetailRole[];
    name?: string;
}

export default DetailEmployeeWorkProcess;
