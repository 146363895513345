import * as yup from "yup";
import {JobPrepColumnSort} from "../jobPrep/models";
import {MainQCColumnSort, QCColumnSort} from "./models";

export const schema = yup.object({
    range_start: yup.number().required(),
    range_end: yup.number().required(),
}).required();

export const QCSortOption = [
    {
        label: "project_name",
        text: "project_name",
        value: QCColumnSort.project_name.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: QCColumnSort.department_name.toString()
    },
    {
        label: "kind_name",
        text: "kind_name",
        value: QCColumnSort.kind_job.toString()
    },
    {
        label: "ts_created",
        text: "ts_created",
        value: QCColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: QCColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: QCColumnSort.number_job.toString()
    },
    {
        label: "other_number",
        text: "other_number",
        value: QCColumnSort.other_number.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: QCColumnSort.status.toString()
    },
    {
        label: "date_due",
        text: "date_due",
        value: QCColumnSort.due_date.toString()
    },
    {
        label: "date_assigned_qc",
        text: "date_assigned_qc",
        value: QCColumnSort.date_assign.toString()
    },
    {
        label: "pole_total",
        text: "pole_total",
        value: QCColumnSort.req_pole.toString()
    },
]

export const MainQCSortOption = [
    {
        label: "project_name",
        text: "project_name",
        value: MainQCColumnSort.project_name.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: MainQCColumnSort.department_name.toString()
    },
    {
        label: "kind_name",
        text: "kind_name",
        value: MainQCColumnSort.kind_job.toString()
    },
    {
        label: "ts_created",
        text: "ts_created",
        value: MainQCColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: MainQCColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: MainQCColumnSort.number_job.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: MainQCColumnSort.status.toString()
    },
    {
        label: "date_due",
        text: "date_due",
        value: MainQCColumnSort.due_date.toString()
    },
    {
        label: "date_assigned_qc",
        text: "date_assigned_qc",
        value: MainQCColumnSort.date_assign.toString()
    },
    {
        label: "pole_total",
        text: "pole_total",
        value: MainQCColumnSort.req_pole.toString()
    },
]