import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/Button/Button';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import InputText from "components/TextInput/InputText";
import TextArea from "components/TextArea/TextArea";
import {createEmail, getLastSeq, reset} from "../email.reducer";
import {HttpClientError, RequestStatus} from "http-client";
import {savedStatusOption} from "helpers/alertLabel";
import {RootState} from "app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {InputEmailModel} from "../models";

const CreateEmail = ({ jobId = '', ...props }: CreateEmailProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<InputEmailModel>();
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();

    const statusEmail = useAppSelector(
        (state: RootState) => state.email.status
    );
    const lastSeq = useAppSelector(
        (state: RootState) => state.email.lastSeq ?? 0
    );
    const errorEmail = useAppSelector(
        (state: RootState) => state.email.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (jobId !== '') {
            dispatch(getLastSeq(jobId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);

    useEffect(() => {
        if (statusEmail !== undefined && statusEmail !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(statusEmail === RequestStatus.success, errorEmail.data?.message)).then(() => {
                if (statusEmail === RequestStatus.success) {
                    props.closeModal();
                }
                dispatch(reset());
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusEmail, errorEmail]);

    const onSubmit = (data: InputEmailModel) => {
        let _data = {...data, job_id: jobId, seq: lastSeq + 1}
        dispatch(createEmail(_data));
    }

    return (
        <div className="p-5">
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputText
                    label="Date"
                    placeholder="Date"
                    value={new Date().toLocaleDateString()}
                    readOnly
                />
                <InputText
                    label="Seq Email"
                    placeholder="Input Seq Email"
                    readOnly
                    value={lastSeq + 1}
                />
                <InputText
                    {...register('receiver')}
                    label="Send to"
                    placeholder="Input Receiver"
                    message={errors.receiver?.message}
                />
                <InputText
                    {...register('sender')}
                    label="By"
                    placeholder="Input Sender"
                    message={errors.sender?.message}
                />
                <TextArea
                    {...register('note')}
                    label="Email Notes"
                    placeholder="Input Email Notes"
                />
                {/*<SelectInput*/}
                {/*    options={emailTitleOptions}*/}
                {/*    isMulti*/}
                {/*    label="Title"*/}
                {/*    onChange={onMailTitleChange}*/}
                {/*/>*/}
                <div className="pt-2 pb-2 flex content-end">
                    <Button color="success" type="submit" title="Submit" />
                </div>
            </form>
        </div>
    );
};

export interface CreateEmailProps {
    jobId: string;
    closeModal: () => void;
}

export default CreateEmail;
