import {PowerMapColumnShort, DataSheetColumnShort} from './models';

export const powerMapSortOption = [
    {
        label: "date_request",
        text: "date_request",
        value: PowerMapColumnShort.requestDate.toString()
    },
    {
        label: "request_by",
        text: "request_by",
        value: PowerMapColumnShort.req_by.toString()
    },
    {
        label: "job_number",
        text: "job_number",
        value: PowerMapColumnShort.number_job.toString()
    },
    {
        label: "job_number_id",
        text: "job_number_id",
        value: PowerMapColumnShort.number_id.toString()
    },
    {
        label: "department_owner_name",
        text: "department_owner_name",
        value: PowerMapColumnShort.department_owner.toString()
    },
    {
        label: "po_number",
        text: "po_number",
        value: PowerMapColumnShort.po_number.toString()
    },
    {
        label: "option",
        text: "option",
        value: PowerMapColumnShort.area.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: PowerMapColumnShort.status.toString()
    },
    {
        label: "note",
        text: "note",
        value: PowerMapColumnShort.note.toString()
    },
    {
        label: "total_pole",
        text: "total_pole",
        value: PowerMapColumnShort.total_pole.toString()
    },
]

export const dataSheetSortOption = [
    {
        label: "date_request",
        text: "date_request",
        value: DataSheetColumnShort.requestDate.toString()
    },
    {
        label: "request_by",
        text: "request_by",
        value: DataSheetColumnShort.req_by.toString()
    },
    {
        label: "po_number",
        text: "po_number",
        value: DataSheetColumnShort.po_number.toString()
    },
    {
        label: "job_number",
        text: "job_number",
        value: DataSheetColumnShort.number_job.toString()
    },
    {
        label: "job_number_id",
        text: "job_number_id",
        value: DataSheetColumnShort.number_id.toString()
    },
    {
        label: "department_owner_name",
        text: "department_owner_name",
        value: DataSheetColumnShort.department_owner.toString()
    },
    {
        label: "option",
        text: "option",
        value: DataSheetColumnShort.area.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: DataSheetColumnShort.status.toString()
    },
    {
        label: "note",
        text: "note",
        value: DataSheetColumnShort.note.toString()
    },
    {
        label: "total_pole",
        text: "total_pole",
        value: DataSheetColumnShort.total_pole.toString()
    },
]