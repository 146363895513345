import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {
    savedStatusOption,
} from '../../../../helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {HttpClientError, RequestStatus} from '../../../../http-client';
import {RootState} from '../../../../app/store';
import {BiEditAlt} from 'react-icons/bi';
import {
    approvalStatusFilterOptions,
    employeeWorkSortOption,
    getSortModel,
    getSortType,
} from '../../constants';
import {UserCredential} from '../../../login/models';
import {StaffReportModel} from '../../../report/staffReport/models';
import {getStaffReportApproval} from '../../../report/staffReport/staffReport.reducer';
import {reset} from '../../approval.reducer';
import DefaultModal from "../../../../components/Modal/DefaultModal";
import ApproveEmployeeWorkForm from "./ApproveEmployeeWorkForm";
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {Button as FlowbiteButton} from "flowbite-react";
import {LuDatabaseBackup} from "react-icons/lu";
import {dateSetTimeToNow} from "../../../../helpers/convert";

const ApproveEmployeeWorkTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = React.useRef<any>();

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector(
        (state: RootState) => state.approval.employeeWorkApprovalStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.approval.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [singleEmployeeWork, setSingleEmployeeWork] = useState<StaffReportModel>({} as StaffReportModel);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (status === RequestStatus.success) {
                    closeModal();
                }
                refreshCache(undefined);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const openModal = (staff: StaffReportModel) => {
        setSingleEmployeeWork(staff);
        setShowModal(true);
    };

    const closeModal = () => {
        setSingleEmployeeWork({} as StaffReportModel);
        setShowModal(false);
    };

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    const NoteCellRender = (props: any) => {
        const [isExpand, setIsExpand] = useState<boolean>(false);

        if (props.data === undefined) {
            return null;
        }

        const toggleExpand = () => {
            setIsExpand(!isExpand);
        }

        return <div>
            {props.data.note?.length > 10 ? isExpand ? props.data.note : props.data.note?.substring(0, 10) : props.data.note}{props.data.note?.length > 10 &&
            <span className="text-cyan-600 hover:underline dark:text-cyan-500" onClick={toggleExpand}>...</span>}
        </div>
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    (user.roleName.some((item) => item === "Head") && props.data?.statusName === "Requested") &&
                    <button onClick={() => openModal(props.data)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    (user.roleName.some((item) => item === "Administrator" || item === "Superadmin" || item === "HRD") && props.data?.statusName !== "ManagerApproval" && props.data?.statusName !== "Rejected") &&
                    <button onClick={() => openModal(props.data)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {
            field: 'requestDate', headerName: 'Date Request', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'typeName', headerName: 'Type Add Poles'
        },
        {
            field: 'number_job', headerName: 'Job Number',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'name', headerName: 'Name',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'nip', headerName: 'NIP',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'teamName', headerName: 'Team',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'role_name', headerName: 'Position',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'note', headerName: 'Notes', cellRenderer: NoteCellRender,
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'reqMinute', headerName: 'Req Minutes'},
        {field: 'reqPole', headerName: 'Req Poles'},
        {field: 'rejectionNote', headerName: 'Rejection Notes'},
        {field: 'approvalPole', headerName: 'Approval Poles'},
        {
            field: 'statusName', headerName: 'Status', filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: approvalStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(employeeWorkSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom, dateTo} = params.request.filterModel?.requestDate || {};

                dispatch(
                    getStaffReportApproval({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        filterByName: params.request.filterModel?.name?.filter,
                        filterByNIP: params.request.filterModel?.nip?.filter,
                        filterByTeam: params.request.filterModel?.teamName?.filter,
                        filterByNotes: params.request.filterModel?.note?.filter,
                        filterByJobNumber: params.request.filterModel?.number_job?.filter,
                        filterByPosition: params.request.filterModel?.role_name?.filter,
                        startDate: dateFrom !== undefined ? dateSetTimeToNow(dateFrom) : undefined,
                        endDate: dateTo !== undefined ? dateSetTimeToNow(dateTo) : undefined,
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        isReport: false,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                            getServerSideDataSource={getServerSideDataSource}
                                            isCustomHeader
                                            customChildHeaderComponent={
                                                <>
                                                    <FlowbiteButton
                                                        onClick={() => refreshCache(undefined)}
                                                        size="xs"
                                                        color="success"
                                                    >
                                                        <LuDatabaseBackup className="mr-2 h-5 w-5"/>
                                                        Refresh Data
                                                    </FlowbiteButton>
                                                </>
                                            }
                                            supportSaveTableState
                                            tableStateName={'approval-employee-work'}
                                            defaultPageSize={20}
                    />
                    <DefaultModal
                        title="Employee Work Approval"
                        show={showModal}
                        onClose={closeModal}
                        body={<ApproveEmployeeWorkForm singleEmployeeWork={singleEmployeeWork} userId={user.user_id}/>}
                    />
                </div>
            </div>
        </div>
    );
};

export default ApproveEmployeeWorkTable;
