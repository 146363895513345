import React from 'react';
import AddAttendanceTable from "../components/AddAttendanceTable";
import AddHolidayTable from "../components/AddHolidayTable";
import AddOvertimeTable from "../components/AddOvertimeTable";

const AbsentPage = () => {
    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div>
                    <AddAttendanceTable />
                </div>
                <div className="mt-10">
                    <AddHolidayTable />
                </div>
                <div className="mt-10">
                    <AddOvertimeTable />
                </div>
            </div>
        </div>
    );
};

export default AbsentPage;