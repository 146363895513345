import {
    CreateTeam,
    GetListRequest, Team, UpdateTeam,
} from './models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpClientError, RequestStatus } from 'http-client';
import { RootState } from 'app/store';
import TeamApi from './team.api';
import {Option} from "../../../components/SelectInput/BasicSelectInput";

export interface TeamSlice {
    list?: Array<Team>;
    options?: Array<Option>;
    single?: Team;
    rows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getList = createAsyncThunk(
    'team/getList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await TeamApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingle = createAsyncThunk(
    'team/getSingle',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await TeamApi.getSingle(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createTeam = createAsyncThunk(
    'team/create',
    async (args: CreateTeam, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await TeamApi.createTeam(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateTeam = createAsyncThunk(
    'team/update',
    async (args: UpdateTeam, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await TeamApi.updateTeam(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteTeam = createAsyncThunk(
    'team/delete',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await TeamApi.deleteTeam(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const teamSlice = createSlice({
    name: 'teamState',
    initialState: {} as TeamSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.single = {} as Team;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, { payload }) => {
            state.rows = payload.rows;
            state.list = payload.data;
            // state.list = payload.data.map((team: Team) => {
            //     return {...team, members: JSON.parse(team.employee_total)};
            // })
            state.options = payload.data.map((team: Team) => {
                return {
                    text: team.name,
                    value: team.id
                }
            })
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state) => {
            state.isLoading = false;
        });

        // get single data reducer
        builder.addCase(getSingle.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingle.fulfilled, (state, { payload }) => {
            state.single = payload?.data;
            state.isLoading = false;
        });
        builder.addCase(getSingle.rejected, (state) => {
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createTeam.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createTeam.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createTeam.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updateTeam.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateTeam.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateTeam.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deleteTeam.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteTeam.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteTeam.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const { reset } = teamSlice.actions;
export default teamSlice.reducer;
