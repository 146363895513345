import React, { useEffect } from 'react';
import JobCalcSpecTable from '../components/JobCalcSpecTable';
import JobCalcSpecForm from '../components/JobCalcSpecForm';
import JobQCForm from '../components/JobQCForm';
import { useAppDispatch } from '../../../../../helpers/redux';
import { getSingle } from '../../../process/job/job.reducer';
import {useParams} from "react-router-dom";

const JobOCalcPage = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    // const singleState = useAppSelector(
    //     (state: RootState) => state.job.single ?? ({} as Job)
    // );

    useEffect(() => {
        if (id && id !== "") {
            dispatch(getSingle(id as string));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="mt-3">
                <JobCalcSpecTable jobId={id} />
                <div>
                    <JobCalcSpecForm jobId={id} />
                    <div className="my-3">
                        <hr />
                    </div>
                    <JobQCForm jobId={id} />
                </div>
            </div>
        </div>
    );
};

export default JobOCalcPage;
