import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {deletedStatusOption, deletedWarnOption, savedStatusOption} from 'helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {HttpClientError, RequestStatus} from 'http-client';
import {RootState} from 'app/store';
import {BiEditAlt} from 'react-icons/bi';
import {MdOutlineDeleteForever} from 'react-icons/md';
import DefaultModal from '../../../../components/Modal/DefaultModal';
import AddOvertimeForm from './AddOvertimeForm';
import {deleteOvertime, getOvertime, reset} from '../../profile.reducer';
import {HolidayOvertimeApprovalEnum, OvertimeModel} from '../../models';
import OvertimeNotesDetail from './OvertimeNotesDetail';
import {Link} from 'react-router-dom';
import {UserCredential} from "../../../login/models";
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";
import {IoMdAdd} from "react-icons/io";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {approvalStatusFilterOptions, getSortModel, getSortType} from "../../../approval/constants";
import {userOvertimeSortOption} from "../../constants";
import {dateSetTimeToNow} from "../../../../helpers/convert";

const AddOvertimeTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector((state: RootState) => state.profile.overtimeStatus);
    const deleteStatus = useAppSelector((state: RootState) => state.profile.overtimeDeleteStatus);
    const error = useAppSelector(
        (state: RootState) => state.profile.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState(false);
    const [singleData, setSingleData] = useState({} as OvertimeModel);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (status === RequestStatus.success) {
                    toggleModal();
                }
                refreshCache(undefined);
                gridRef.current.api.setFilterModel(null);
                setIsEdit(false);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    useEffect(() => {
        if (deleteStatus !== undefined && deleteStatus !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    deleteStatus === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (deleteStatus === RequestStatus.success) {
                    refreshCache(undefined);
                    gridRef.current.api.setFilterModel(null);
                }
                setIsEdit(false);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus, error]);

    const onEditClick = (data: OvertimeModel) => {
        setShowModal(true);
        setIsEdit(true);
        setSingleData(data);
    };

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteOvertime(id));
            }
        });
    };

    const onDetailClicked = (data: OvertimeModel) => {
        setSingleData(data);
        setShowNotesModal(true);
    }

    const onAddOvertimeClick = () => {
        setIsEdit(false);
        setShowModal(true);
    };

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const toggleNotesModal = () => {
        setShowNotesModal((prevState) => !prevState);
        setSingleData({} as OvertimeModel);
    };

    const NotesLinkCellRender = (props: any) => (
        <Link
            to={`#`}
            className="underline text-blue-700"
            onClick={() => onDetailClicked(props.data)}
        >
            See Notes
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                    {
                        props.data?.status === HolidayOvertimeApprovalEnum.Requested &&
                        <>
                            <button onClick={() => onEditClick(props.data)}>
                                <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                            </button>
                            <button
                                onClick={() => onDeleteClicked(props.data.id)}
                            >
                                <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                            </button>
                        </>
                    }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'ts_created', headerName: 'Created Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {field: 'roleName', headerName: 'Role',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'reqMinute', headerName: 'Req Minutes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'notes', headerName: 'Notes', cellRenderer: NotesLinkCellRender, cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'statusName', headerName: 'status', filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: approvalStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {field: 'rejectionNotes', headerName: 'Rejection Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'approveMinute', headerName: 'Approve',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'totalApproveMinute', headerName: 'Total Approve', filter: false, sortable: false},
        {field: 'multiplier', headerName: 'Multiple', filter: false, sortable: false},
        {field: 'approveByName', headerName: 'Approved By',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }},
        {field: 'managerApproveByName', headerName: 'Manager Approval',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            filter: false,
            sortable: false,
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(userOvertimeSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom, dateTo} = params.request.filterModel?.ts_created || {};

                dispatch(
                    getOvertime({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        userId: user.user_id,
                        startDate: dateFrom !== undefined ? dateSetTimeToNow(dateFrom) : undefined,
                        endDate: dateTo !== undefined ? dateSetTimeToNow(dateTo) : undefined,
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        role: params.request.filterModel?.roleName?.filter,
                        req_minute: params.request.filterModel?.reqMinute?.filter,
                        note: params.request.filterModel?.notes?.filter,
                        rejection_note: params.request.filterModel?.rejectionNotes?.filter,
                        approve_by: params.request.filterModel?.approveByName?.filter,
                        manager_approval: params.request.filterModel?.managerApproveByName?.filter,
                        approve_minute: params.request.filterModel?.approveMinute?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <div className="flex justify-items-center">
                        <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                            Overtime
                        </h1>
                        {/*<div className="flex">*/}
                        {/*    <div className="mr-2">*/}
                        {/*        <Button*/}
                        {/*            title="Add Overtime"*/}
                        {/*            onClick={onAddOvertimeClick}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="mt-3">
                        <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'profile-overtime'} isCustomHeader={true} customChildHeaderComponent={
                                                    <>
                                                        <FlowbiteButton
                                                            onClick={onAddOvertimeClick}
                                                            size="xs"
                                                            color="success"
                                                        >
                                                            <IoMdAdd className="mr-2 h-5 w-5"/>
                                                            Add Overtime
                                                        </FlowbiteButton>
                                                    </>
                                                }
                                                defaultPageSize={20}
                        />
                    </div>
                    <DefaultModal
                        title={isEdit ? 'Edit Overtime' : 'Add Overtime'}
                        show={showModal}
                        onClose={toggleModal}
                        body={
                            <AddOvertimeForm
                                isEdit={isEdit}
                                data={singleData}
                            />
                        }
                    />
                    <DefaultModal
                        title={'Notes'}
                        show={showNotesModal}
                        onClose={toggleNotesModal}
                        body={<OvertimeNotesDetail data={singleData}/>}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddOvertimeTable;
