import {
    GetMainPrepListRequest,
    GetPrepListRequest, GetPrepMainRequest, GetPrepRequest,
    JobPrep,
    JobPrepGetRequest,
    JobPrepSummary,
    MainJobPrep
} from "./models";
import {HttpClientError, RequestStatus} from "../../../../http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../../app/store";
import ProcessJobPrepApi from "./processJobPrep.api";

export interface ProcessJobPrepSlice {
    jobPrep: JobPrep[];
    singleJobPrep?: JobPrep;
    mainJobPrep: MainJobPrep[];
    jobPrepSummaryList: Array<JobPrepSummary>;
    rows?: number;
    mainRows?: number;
    isLoading?: boolean;
    isGetLoading?: boolean;
    isMainLoading?: boolean;
    isSingleLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getJobPrep = createAsyncThunk(
    'process/jobPrep/getJobPrep',
    async (args: GetPrepListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessJobPrepApi.getJobPrep(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getJobPrepWithFilter = createAsyncThunk(
    'process/jobPrep/getJobPrepWithFilter',
    async (args: GetPrepRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessJobPrepApi.getJobPrepWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingleJobPrep = createAsyncThunk(
    'process/jobPrep/getSingle',
    async (args: GetPrepListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessJobPrepApi.getSingleJobPrep(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainJobPrepWithFilter = createAsyncThunk(
    'process/jobPrep/getMainJobPrepWithFilter',
    async (args: GetPrepMainRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessJobPrepApi.getMainJobPrepWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainJobPrep = createAsyncThunk(
    'process/jobPrep/getMainJobPrep',
    async (args: GetMainPrepListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessJobPrepApi.getMainJobPrep(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const jobPrepGetAction = createAsyncThunk(
    'process/jobPrep/jobPrepGetAction',
    async (jobPole: JobPrepGetRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessJobPrepApi.jobPrepGetAction(jobPole, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getJobPrepSummary = createAsyncThunk(
    'process/jobPrep/getJobPrepSummary',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessJobPrepApi.getJobPrepSummary(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const jobPrepProcessSlice = createSlice({
    name: 'jobPrepState',
    initialState: {} as ProcessJobPrepSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getJobPrep.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getJobPrep.fulfilled, (state, action) => {
            state.isLoading = false;
            state.jobPrep = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getJobPrep.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get list data with filter reducer
        builder.addCase(getJobPrepWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getJobPrepWithFilter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.jobPrep = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getJobPrepWithFilter.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get single data reducer
        builder.addCase(getSingleJobPrep.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isSingleLoading = true;
        });
        builder.addCase(getSingleJobPrep.fulfilled, (state, { payload }) => {
            state.singleJobPrep = payload?.data;
            state.isSingleLoading = false;
        });
        builder.addCase(getSingleJobPrep.rejected, (state) => {
            state.isSingleLoading = false;
        });
        builder.addCase(getJobPrepSummary.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getJobPrepSummary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.jobPrepSummaryList = action.payload.data;
        });
        builder.addCase(getJobPrepSummary.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getMainJobPrep.pending, (state) => {
            state.isMainLoading = true;
        });
        builder.addCase(getMainJobPrep.fulfilled, (state, action) => {
            state.isMainLoading = false;
            state.mainJobPrep = action.payload.data;
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainJobPrep.rejected, (state, action) => {
            state.isMainLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get list data with filter reducer
        builder.addCase(getMainJobPrepWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainJobPrepWithFilter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mainJobPrep = action.payload.data;
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainJobPrepWithFilter.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // assign job prep reducer
        builder.addCase(jobPrepGetAction.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isGetLoading = true;
        });
        builder.addCase(jobPrepGetAction.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isGetLoading = false;
        });
        builder.addCase(jobPrepGetAction.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isGetLoading = false;
        });
    }
});
export const { reset } = jobPrepProcessSlice.actions;
export default jobPrepProcessSlice.reducer;