import React, {useCallback, useEffect, useRef, useState} from 'react';
import {customWarnOption} from "helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {CSEmployee} from "../models";
import {MdPauseCircleOutline} from "react-icons/md";
import {getMainCalcSpec, getMainCalcSpecWithFilter} from "../calcSpec.reducer";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import JobChangeStatus from "../../job/components/JobChangeStatus";
import {InputStatusModel} from "../../job/models";
import {waitingJob} from "../../job/job.reducer";
import {Link} from "react-router-dom";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {UserCredential} from "../../../../login/models";
import {isArrayEmpty, isObjectEmpty} from "../../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {mainCalcSpecSortOption} from "../constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {RequestStatus} from "../../../../../http-client";

const MainCalcSpecTable = () => {
    const gridRef = useRef<any>(null);
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector((state: RootState) => state.calcSpec.status);
    const statusPole = useAppSelector((state: RootState) => state.pole.status);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [jobId, setJobId] = useState<string>('');

    useEffect(() => {
        if (!isObjectEmpty(user)) dispatch(getMainCalcSpec({employee_id: user.user_id}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (status === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (statusPole === RequestStatus.success) {
            refreshCache(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusPole]);

    const onStatusClicked = (id: string, action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Change Status',
                'Are you sure you want to change the status'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(waitingJob({
                    job_id: id,
                    waiting_notes: data?.notes ?? '',
                    waiting_subject_id: data?.pause_subject_id ?? '',
                    waiting_by_user: user.user_id
                }));
            }
        });
    };

    const openModal = (id: string) => {
        setShowModal(true);
        setJobId(id);
    };

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setJobId('');
    };

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-ocalc/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex align-middle h-[100%]">
                <button onClick={() => openModal(props.data?.id)}>
                    <MdPauseCircleOutline
                        className="mr-2 text-2xl text-primary-color"
                    />
                </button>
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            minWidth: 50,
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {
            field: 'project_name', headerName: 'Project',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'department_name', headerName: 'Department',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'department_owner_name', headerName: 'Department Owner', filter: false, sortable: false},
        {field: 'district_name', headerName: 'District', filter: false, sortable: false},
        {field: 'type_name', headerName: 'Type', filter: false, sortable: false},
        {
            field: 'kind_name', headerName: 'KoJ',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'ts_created',
            headerName: 'Request Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'number_id', headerName: 'ID Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Job Number',
            field: 'number_job',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'status_name', headerName: 'Status',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'CSQ',
            field: 'employee_prep_list',
            suppressCellFlash: true,
            cellRenderer: (props: any) => {
                let _employee_prep = props.data?.employee_prep_list ?? [];
                let _member = _employee_prep.map((item: CSEmployee) => {
                    return item.employee_name;
                });
                return <span>{_member.toString()}</span>;
            },
            filter: false, sortable: false
        },
        {
            field: 'date_due',
            headerName: 'Due Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'date_assigned_cs',
            headerName: 'Date Assigned',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'pole_total', headerName: 'Request Pole',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(mainCalcSpecSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {dateFrom: startDueDate, dateTo: endDueDate} = params.request.filterModel?.date_due || {};
                const {
                    dateFrom: startDateAssign,
                    dateTo: endDateAssign
                } = params.request.filterModel?.date_assigned_cs || {};

                dispatch(getMainCalcSpecWithFilter(
                    {
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        employee_id: user.user_id,
                        project_name: params.request.filterModel?.project_name?.filter,
                        department_name: params.request.filterModel?.department_name?.filter,
                        kind_job: params.request.filterModel?.kind_name?.filter,
                        startRequestDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endRequestDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        number_id: params.request.filterModel?.number_id?.filter,
                        number_job: params.request.filterModel?.number_job?.filter,
                        status: params.request.filterModel?.status_name?.filter,
                        startDueDate: startDueDate !== undefined ? dateSetTimeToNow(startDueDate) : undefined,
                        endDueDate: endDueDate !== undefined ? dateSetTimeToNow(endDueDate) : undefined,
                        startDateAssign: startDateAssign !== undefined ? dateSetTimeToNow(startDateAssign) : undefined,
                        endDateAssign: endDateAssign !== undefined ? dateSetTimeToNow(endDateAssign) : undefined,
                        req_pole: params.request.filterModel?.pole_total?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    }
                )).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            }
        }
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);


    return (
        <div>
            <div className="main-data-grid mt-10">
                <div className='flex'>
                    <div className='flex flex-grow self-center'>
                        <h1 className="dark:text-gray-300">Main Job</h1>
                    </div>
                </div>
                <div>
                    {/*<FormLoader isLoading={isLoading} label={"Fetching Data"}>*/}
                    {/*    <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={mainCalcSpecList} supportSaveTableState tableStateName={'worksheet-main-calc-spec'}/>*/}
                    {/*</FormLoader>*/}

                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                            getServerSideDataSource={getServerSideDataSource}
                                            supportSaveTableState
                                            tableStateName={'worksheet-main-calc-spec'}
                                            defaultPageSize={20}
                    />
                </div>
                <DefaultModal
                    title="Change Status"
                    show={showModal}
                    onClose={toggleModal}
                    body={<JobChangeStatus action={"waiting"} jobId={jobId} onSubmit={onStatusClicked}/>}
                />
            </div>
        </div>
    );
};

export default MainCalcSpecTable;
