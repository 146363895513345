import React, {Fragment} from 'react';
import {Spinner} from "flowbite-react";

const FormLoader = ({ children, isLoading, label }: FormLoaderProps) => {
    return (
        <Fragment>
            {isLoading ? (
                <div className="loading-circle-container">
                    <div className="loading-screen-blur" style={{ height: "100%" }}>
                        <div className="text-center">
                            <Spinner className="loading-screen-blur-spinner mr-3" />
                        </div>
                        <div className="text-center">
                            <small>{label}</small>
                        </div>
                    </div>
                    <div>{children}</div>
                </div>
            ) : (
                <div>{children}</div>
            )}
        </Fragment>
    );
};

export interface FormLoaderProps {
    children: React.ReactElement;
    isLoading?: boolean;
    label?: string;
}

export default FormLoader;
