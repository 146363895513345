import React, {useRef, useState} from 'react';
import FormLoader from "../../../../components/Loader/FormLoader";
import AgDataGridStatic from "../../../../components/DataGrid/AgDataGridStatic";
import {useAppSelector} from "../../../../helpers/redux";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import {RootState} from "../../../../app/store";
import {BiCloudDownload} from "react-icons/bi";
import FileAttachmentApi from "../../../basic/fileAttachment/fileAttachment.api";
import {filterParams} from "../../../../helpers/agTable";

const BillJobTable = () => {
    const gridRef = useRef<any>(null);

    const billJob = useAppSelector((state: RootState) => state.billJob.billByJobList ?? []);

    const NotesCellRender = (props: any) => {
        const [isExpand, setIsExpand] = useState<boolean>(false);

        if (props.data === undefined) {
            return null;
        }

        const toggleExpand = () => {
            setIsExpand(!isExpand);
        }

        return <div>
            {props.data.notes?.length > 10 ? isExpand ? props.data.notes : props.data.notes?.substring(0, 10) : props.data.notes}{props.data.notes?.length > 10 &&
            <span className="text-cyan-600 hover:underline dark:text-cyan-500" onClick={toggleExpand}>...</span>}
        </div>
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'ts_created', headerName: 'Entry Date', cellDataType: 'date', filter: 'agDateColumnFilter', filterParams: filterParams},
        {field: 'upload_date', headerName: 'Upload Date', cellDataType: 'date', filter: 'agDateColumnFilter', filterParams: filterParams},
        {field: 'reqNumber', headerName: 'Req Number', filter: 'agTextColumnFilter'},
        {field: 'project', headerName: 'Project', filter: 'agTextColumnFilter'},
        {field: 'koj', headerName: 'KoJ', filter: 'agTextColumnFilter'},
        {field: 'number', headerName: 'ID Number', filter: 'agTextColumnFilter'},
        {field: 'numberJob', headerName: 'Job Number', filter: 'agTextColumnFilter'},
        {field: 'poNumberJob', headerName: 'PO Number', filter: 'agTextColumnFilter'},
        {field: 'typeName', headerName: 'Type', filter: 'agTextColumnFilter'},
        {field: 'statusName', headerName: 'Status', filter: 'agTextColumnFilter'},
        {field: 'totalPole', headerName: 'Total Pole', filter: 'agTextColumnFilter'},
        {field: 'qc_employee', headerName: 'QC', filter: 'agTextColumnFilter'},
        {field: 'job_complete_date', headerName: 'Complete Date', cellDataType: 'date', filter: 'agDateColumnFilter', filterParams: filterParams},
        {field: 'department', headerName: 'Department', filter: 'agTextColumnFilter'},
        {field: 'departmentOwner', headerName: 'Department Owner', filter: 'agTextColumnFilter'},
        {field: 'vDriveLocation', headerName: 'vDrive Location', filter: 'agTextColumnFilter'},
        {field: 'notes', headerName: 'Notes', filter: 'agTextColumnFilter', cellRenderer: NotesCellRender},
        {
            field: 'attachment', headerName: 'Attachment', filter: 'agTextColumnFilter', cellRenderer: (props: any) => (
                <>
                    {props.data?.attachment &&
                    <div><a
                        href={FileAttachmentApi.getMediaEndpoint(props.data?.attachment ?? "")}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <BiCloudDownload color="#00bdfe" size={20}/>
                    </a>
                    </div>
                    }
                </>
            ), cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    return (
        <div className="main-data-grid mt-5">
            <div>
                <FormLoader isLoading={false} label={"Fetching Data"}>
                    <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={billJob} supportSaveTableState
                                      tableStateName={'bill-job'} defaultPageSize={20}/>
                </FormLoader>
            </div>
        </div>
    );
};

export default BillJobTable;
