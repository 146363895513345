import {BaseServiceClass} from "base-service-class";
import httpClient from "http-client";
import {CreateEmailTitle, GetListRequest, UpdateEmailTitle} from "./models";

export default class EmailTitleApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        const path = "/email/title"
        return httpClient.get<any>(`${this.BASE_URL}${path}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        const path = "/email/title"
        return httpClient.get<any>(`${this.BASE_URL}${path}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
            }
        }).then((res) => res.data);
    }

    static createEmailTitle(args: CreateEmailTitle, token: string) {
        const path = "/email/title"
        return httpClient.post<any>(`${this.BASE_URL}${path}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateEmailTitle(args: UpdateEmailTitle, token: string) {
        const path = "/email/title"
        return httpClient.put<any>(`${this.BASE_URL}${path}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deleteEmailTitle(id: string, token: string) {
        const path = "/email/title"
        return httpClient.delete<any>(`${this.BASE_URL}${path}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
}