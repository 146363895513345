import React from 'react';
import JobKatapultDetail from "../components/JobKatapultDetail";

const JobKatapultDetailPage = () => {
    return (
        <div>
            <JobKatapultDetail />
        </div>
    );
};

export default JobKatapultDetailPage;
