import React, {useEffect} from 'react';
import DetailKatapultForm from "../components/DetailKatapultForm";
import {useAppDispatch} from "../../../../../helpers/redux";
import {getPoleKatapultByJob} from "../../../../basic/pole/pole.reducer";
import AddKatapultPoleTable from "../components/AddKatapultPoleTable";
import {useParams} from "react-router-dom";

const DetailKatapultPage = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    useEffect(() => {
       if (id && id !== "") {
           dispatch(getPoleKatapultByJob({job_id: id as string, skip: 0, take: 10}));
       }
    }, [id]);

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="mt-3">
                <AddKatapultPoleTable />
                <div>
                    <DetailKatapultForm jobId={id as string} />
                </div>
            </div>
        </div>
    );
};

export default DetailKatapultPage;
