import React, {useCallback, useEffect, useRef, useState} from 'react';
import {InputMapReqFilter, MainMapReq} from '../models';
import {MdOutlineCheckCircleOutline, MdOutlineEmail} from 'react-icons/md';
import withReactContent from 'sweetalert2-react-content';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import {darkGreen} from '../../../../../constants';
import DefaultModal from 'components/Modal/DefaultModal';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {getMainList} from '../mapReq.reducer';
import {RootState} from 'app/store';
import {reset as resetAttachment, updateAttachment} from "../../../../basic/attachment/attachment.reducer";
import {SupportingDocumentEnum} from "../../../process/job/models";
import CreateEmail from "../../../../basic/email/containers/CreateEmail";
import {UserCredential} from "../../../../login/models";
import {Link} from "react-router-dom";
import {DETAIL_TYPE_JOB_INPUT} from "../../../process/job/constants";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import Divider from "../../../../../components/Layout/Divider";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import InputText from "../../../../../components/TextInput/InputText";
import Button from "../../../../../components/Button/Button";
import {useForm} from "react-hook-form";

const MainJobTable = ({reFetch = false, ...props}: MainJobTableProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);

    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        reset: resetForm
    } = useForm<InputMapReqFilter>({defaultValues: {startDate: undefined, endDate: undefined}});

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const [showModal, setShowModal] = useState<boolean>(false);
    const [jobId, setJobId] = useState<string>('');

    const userState = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );

    useEffect(() => {
        if (reFetch) {
            reFetchMainJob();
            props.resetFetch?.();
        }
    }, [reFetch]);

    const reFetchMainJob = () => {
        let localStart = startDate !== undefined ? new Date(startDate).toISOString() : undefined;
        let localEnd = endDate !== undefined ? new Date(endDate).toISOString() : undefined;
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            filter: {
                filterType: "text",
                type: "contains",
                filter: getValues("filter")
            },
            dateStartFilter: {
                filterType: "text",
                type: "contains",
                filter: localStart,
            },
            dateEndFilter: {
                filterType: "text",
                type: "contains",
                filter: localEnd
            },
        });
        dispatch(resetAttachment());
    }

    const onAcceptClicked = (data: MainMapReq) => {
        MySwal.fire({
            title: 'Available',
            text: 'Are you sure? You will not be able to recover this data!',
            input: 'text',
            inputLabel: 'Link',
            inputPlaceholder: 'Enter the Link',
            icon: 'warning' as SweetAlertIcon,
            showCancelButton: true,
            cancelButtonColor: darkGreen,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    updateAttachment({
                        id: data.id,
                        job_id: data.job_id,
                        type_id: data.type_id,
                        value: SupportingDocumentEnum.AVAILABLE,
                        option: data.option,
                        employee_id: data.employee_id,
                        date_request: data.date_request,
                        link: result.value,
                        note: data.note,
                    })
                )
            }
        });
    };

    const toggleModal = () => {
        dispatch(getMainList({employee_id: userState.user_id, skip: 0, take: 10}));
        setShowModal((prevState) => !prevState);
        setJobId('');
    };

    const openModal = (id: string) => {
        setShowModal(true);
        setJobId(id);
    };

    const onSubmit = (data: InputMapReqFilter) => {
        let localStart = data.startDate !== undefined ? new Date(data.startDate).toISOString() : undefined;
        let localEnd = data.endDate !== undefined ? new Date(data.endDate).toISOString() : undefined;
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            filter: {
                filterType: "text",
                type: "contains",
                filter: data.filter
            },
            dateStartFilter: {
                filterType: "text",
                type: "contains",
                filter: localStart,
            },
            dateEndFilter: {
                filterType: "text",
                type: "contains",
                filter: localEnd
            },
        });
    }

    const onResetClicked = () => {
        resetForm();
        refreshCache(undefined);
        gridRef.current.api.setFilterModel(null);
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-detail/${props.data?.job_id}`}
            className="underline text-blue-700"
        >
            {props.data?.job_number_job}
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    checkRoleActionAccess(userState?.roleName, MenuEnum.WorksheetMapReq, ActionEnum.ActionCheck) &&
                    <button onClick={() => onAcceptClicked(props.data)}>
                        <MdOutlineCheckCircleOutline className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    checkRoleActionAccess(userState?.roleName, MenuEnum.WorksheetMapReq, ActionEnum.ActionEmail) &&
                    <button>
                        <MdOutlineEmail
                            onClick={() => openModal(props.data?.job_id)}
                            className=" text-2xl text-primary-color"
                        />
                    </button>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
            sortable: false
        },
        {
            field: 'date_request',
            headerName: 'Request Date',
            cellDataType: 'date',
            sortable: false
        },
        {field: 'employee_request_name', headerName: 'Req By', sortable: false},
        {
            headerName: 'Job Number',
            field: 'job_number_job',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            sortable: false
        },
        {field: 'job_number_id', headerName: 'ID Number', sortable: false},
        {field: 'department_owner_name', headerName: 'Department Owner', sortable: false},
        {field: 'option', headerName: 'Area', sortable: false},
        {field: 'job_status_name', headerName: 'Job Status', sortable: false},
        {field: 'type_name', headerName: 'Item', sortable: false},
        {field: 'job_pole_total', headerName: 'Total Poles', sortable: false},
        {field: 'employee_name', headerName: 'Get', sortable: false},
        {field: 'note', headerName: 'Note', sortable: false},
        {
            headerName: 'Filter',
            field: 'filter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date Start Filter',
            field: 'dateStartFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date End Filter',
            field: 'dateEndFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }),
            sortable: false
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                let _filter = params.request.filterModel?.filter?.filter ?? undefined;
                dispatch(
                    getMainList({
                        employee_id: userState.user_id,
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        filter: _filter,
                        startDate: params.request.filterModel?.dateStartFilter?.filter ?? undefined,
                        endDate: params.request.filterModel?.dateEndFilter?.filter ?? undefined,
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid mt-8">
                <div className="flex">
                    <div className="flex flex-grow self-center">
                        <h1 className="dark:text-gray-300">Main Job</h1>
                    </div>
                </div>

                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Divider>
                            <Divider.LeftContent>
                                <div>
                                    <RangeDatePicker label={"Filter by Date"} startName='startDate' endName='endDate'
                                                     startValue={new Date(startDate)} endValue={new Date(endDate)}
                                                     control={control}/>
                                </div>
                            </Divider.LeftContent>
                            <Divider.RightContent>
                                <div>
                                    <InputText
                                        {...register('filter')}
                                        placeholder="Search by id number | job number | area | item | status"
                                        label="Filter by ID Number | Job Number | Area | Item | Status"
                                    />
                                    <div className="flex justify-end">
                                        <div className={"mx-2"}>
                                            <Button
                                                color="success"
                                                type="button"
                                                title="Reset"
                                                onClick={onResetClicked}
                                            />
                                        </div>
                                        <Button
                                            color="success"
                                            type="submit"
                                            title="Filter"
                                        />
                                    </div>
                                </div>
                            </Divider.RightContent>
                        </Divider>
                    </form>
                </div>

                <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                        getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'job-data-main-map-req'} defaultPageSize={20}/>
                <DefaultModal
                    title="E-Mail"
                    show={showModal}
                    onClose={toggleModal}
                    body={
                        <CreateEmail
                            jobId={jobId}
                            closeModal={toggleModal}
                        />
                    }
                />
            </div>
        </div>
    );
};

export interface MainJobTableProps {
    reFetch: boolean;
    resetFetch?: () => void;
}

export default MainJobTable;
