import { BaseServiceClass } from 'base-service-class';
import httpClient, {ApiResponseWrapper} from '../../../../http-client';
import {JobFilePrep, ReadyToAssign, UpdateDetailJobPrep} from "./models";

export default class JobPrepApi extends BaseServiceClass {
    static getJobPrepFile(id: string, token: string) {
        return httpClient
            .get<ApiResponseWrapper<JobFilePrep[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_file_prep}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    job_id: id,
                },
            })
            .then((res) => res.data);
    }

    static createJobPrepFile(args: JobFilePrep, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_file_prep}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then((res) => res.data)
            .catch((err) => err);
    }

    static deleteJobPrepFile(id: string, token: string) {
        return httpClient
            .delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_file_prep}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    id: id,
                },
            })
            .then((res) => res.data);
    }

    static readyToAssign(args: ReadyToAssign, token: string) {
        return httpClient.post<any>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_ready_to_assign}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: args.id,
            },
        }).then((res) => res.data);
    }
}