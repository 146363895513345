import React, {useEffect, useState} from 'react';
import MapReqTable from "../components/MapReqTable";
import MainJobTable from "../components/MainJobTable";
import Button from "components/Button/Button";
import DatasheetTable from "../components/DatasheetTable";
import {useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {customStatusOption, savedStatusOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MapReqPage = () => {
    const MySwal = withReactContent(Swal);

    const [isReFetchMapReq, setIsReFetchMapReq] = useState<boolean>(false);
    const [isReFetchMainJob, setIsReFetchMainJob] = useState<boolean>(false);

    const mapReqStatus = useAppSelector(
        (state: RootState) => state.mapReq.status
    );
    const mapReqError = useAppSelector(
        (state: RootState) =>
            state.mapReq.error ?? ({} as HttpClientError)
    );
    const statusAttachment = useAppSelector(
        (state: RootState) => state.attachment.status
    );
    const errorAttachment = useAppSelector(
        (state: RootState) => state.attachment.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (mapReqStatus !== undefined && mapReqStatus !== RequestStatus.pending) {
            MySwal.fire(
                customStatusOption(
                    mapReqStatus === RequestStatus.success,
                    mapReqError.data?.message,
                    'status has been changed!',
                    'failed to change status'
                )
            ).then(() => {
                setIsReFetchMapReq(true);
                setIsReFetchMainJob(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapReqStatus, mapReqError]);

    useEffect(() => {
        if (statusAttachment !== undefined && statusAttachment !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(statusAttachment === RequestStatus.success, errorAttachment.data?.message)).then(() => {
                if (statusAttachment === RequestStatus.success) {
                    setIsReFetchMapReq(true);
                    setIsReFetchMainJob(true);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusAttachment, errorAttachment]);

    const resetFetch = () => {
        setIsReFetchMapReq(false);
        setIsReFetchMainJob(false);
    }

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Power Map & Data Sheet</h1>
                <div>
                    <div className='inline'>
                        <Button title="Export" />
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <MapReqTable reFetch={isReFetchMapReq} resetFetch={resetFetch} />
                <DatasheetTable reFetch={isReFetchMapReq} resetFetch={resetFetch} />
                <MainJobTable reFetch={isReFetchMainJob} resetFetch={resetFetch} />
            </div>
        </div>
    );
};

export default MapReqPage;
