import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {useAppDispatch} from 'helpers/redux';
import {isObjectEmpty} from 'helpers/checkEmptiness';
import {AttendanceApprovalInput} from "../../models";
import BasicSelectInput from "../../../../components/SelectInput/BasicSelectInput";
import {approvalStatusOption} from "../../constants";
import InputCheckbox from "../../../../components/Checkbox/InputCheckbox";
import {GlobalApprovalEnum} from "../../../../types";
import {AttendanceModel} from "../../../profile/models";
import {changeAttendanceStatus} from "../../approval.reducer";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import {SupportingDocumentEnum} from "../../../worksheet/process/job/models";
import Text from "../../../../components/Text/Text";

const AttendanceApprovalForm = ({
                         singleAttendance = {} as AttendanceModel,
                         userId = '',
                     }: AttendanceApprovalFormProps) => {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
        setValue,
        watch
    } = useForm<AttendanceApprovalInput>({
        defaultValues: {
            status: GlobalApprovalEnum.Approved.toString(),
        }
    });

    const watchIsDeducted = watch('isDeducted');
    const watchIsRefNote = watch('isRefNote');

    useEffect(() => {
        if (!isObjectEmpty(singleAttendance)) {
            let {reqMinute, leaveDuration } = singleAttendance;
            reset({
                status: GlobalApprovalEnum.Approved.toString(),
                approveMinute: reqMinute.toString(),
                leaveDuration: leaveDuration.toString(),
                rejectionNotes: '',
                isDeducted: false,
                isRefNote: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleAttendance]);

    const onSubmit = (data: AttendanceApprovalInput) => {
        let _input = {
            id: singleAttendance?.id ?? '',
            approveMinute: parseInt(data.approveMinute ?? '0'),
            leaveDuration: parseInt(data.leaveDuration ?? '0'),
            rejectionNote: data.rejectionNotes,
            isDeducted: data.isDeducted,
            isRefNote: data.isRefNote,
            userId: userId,
            status: parseInt(data.status) as GlobalApprovalEnum,
        };
        dispatch(changeAttendanceStatus([_input]));
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text
                    label="User"
                    value={singleAttendance?.name}
                />
                <Text
                    label="Notes"
                    value={singleAttendance?.notes}
                />
                <BasicSelectInput
                    label="Status"
                    message={errors.status?.message}
                    options={approvalStatusOption}
                    {...register('status')}
                />
                <InputText
                    {...register('approveMinute')}
                    label="Approve Minute"
                    placeholder="Input approve minute"
                    type="number"
                    message={errors.approveMinute?.message}
                />
                <InputText
                    {...register('leaveDuration')}
                    label="Leave Duration"
                    placeholder="Input leave duration"
                    type="number"
                    message={errors.leaveDuration?.message}
                />
                <InputText
                    {...register('rejectionNotes')}
                    label="Rejection Notes"
                    placeholder="Input rejection notes"
                    message={errors.rejectionNotes?.message}
                />
                <div className={"my-3 flex"}>
                    <div className={"mr-3"}>
                        <InputCheckbox
                            {...register('isDeducted')}
                            label="Deducted"
                            id="isDeducted"
                            checked={watchIsDeducted}
                            onChange={(e) => {
                                setValue('isDeducted', e.target.checked)
                                setValue('isRefNote', false)
                            }}
                        />
                    </div>
                    <div className={"mr-3"}>
                        <InputCheckbox
                            label="Ref Note"
                            id={"isRefNote"}
                            checked={watchIsRefNote}
                            onChange={(e) => {
                                setValue('isRefNote', e.target.checked)
                                setValue('isDeducted', false)
                            }}
                        />
                    </div>
                </div>

                <div className="pt-2 pb-2">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface AttendanceApprovalFormProps {
    singleAttendance?: AttendanceModel;
    userId?: string;
}

export default AttendanceApprovalForm;
