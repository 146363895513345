import {HttpClientError, RequestStatus} from "http-client";
import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {UploadProgress, UploadStatus} from "../../../basic/fileAttachment/models";
import {RootState} from "../../../../app/store";
import MreApi from "./mre.api";
import {CreateJobFile, JobFileNew} from "../../process/job/models";
import FileAttachmentApi from "../../../basic/fileAttachment/fileAttachment.api";
import {JobFileMRE, JobFileQCMRE, UpdateMRENote, UpdateQCMRENote} from "./models";

export interface MRESlice {
    jobMREFile?: Array<JobFileMRE>;
    jobQCMREFile?: Array<JobFileQCMRE>;
    uploadProgress?: Array<UploadProgress>;
    uploadStatus?: Array<UploadStatus>;
    deleteFileStatus?: RequestStatus;
    deleteQCFileStatus?: RequestStatus;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}
// --- mre file ---
export const uploadMREFile = createAsyncThunk(
    'processDetail/mre/uploadMREFile',
    async (args: CreateJobFile, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((file, index) => {
                        return {
                            index: index,
                            filename: file.name,
                            status: RequestStatus.pending,
                            createStatus: RequestStatus.pending,
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (file, index) => {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('job_id', args.job_id);
                const config = {
                    headers: {
                        Authorization: `Bearer ${userAuth?.id as string}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        location: 'job-mre',
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            })
                        );
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.success,
                        })
                    );
                    return {
                        name: file.name,
                        path: data.result,
                        job_id: args.job_id
                    }
                }).catch(() => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.failed,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                        job_id: args.job_id
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                dispatch(createMREFile(attachment as Array<JobFileNew>));
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createMREFile = createAsyncThunk(
    'processDetail/mre/createMREFile',
    async (
        args: Array<JobFileMRE>,
        {getState, rejectWithValue, dispatch}
    ) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            const createResponse = args?.map(async (arg, index) => {
                if (arg.path !== "") {
                    return await MreApi.createMREFile(arg, userAuth?.id as string).then((data) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.success,
                            })
                        );
                        return data;
                    }).catch((e) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.failed,
                            })
                        );
                        return e;
                    });
                } else {
                    return {};
                }
            });

            Promise.all(createResponse).then(async (attachment) => {
                console.log('saved successfully', attachment);
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMREFile = createAsyncThunk(
    'processDetail/mre/getMREFile',
    async (id: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await MreApi.getMREFile(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteMREFile = createAsyncThunk(
    'processDetail/mre/deleteMREFile',
    async (args: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await MreApi.deleteMREFile(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

// --- end of mre file ---
// --- qc mre file ---
export const uploadQCMREFile = createAsyncThunk(
    'processDetail/mre/uploadQCMREFile',
    async (args: CreateJobFile, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((file, index) => {
                        return {
                            index: index,
                            filename: file.name,
                            status: RequestStatus.pending,
                            createStatus: RequestStatus.pending,
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (file, index) => {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('job_id', args.job_id);
                const config = {
                    headers: {
                        Authorization: `Bearer ${userAuth?.id as string}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        location: 'job-qc-mre',
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            }));
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.success,
                        })
                    );
                    return {
                        name: file.name,
                        path: data.result,
                        job_id: args.job_id
                    }
                }).catch(() => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.failed,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                        job_id: args.job_id
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                dispatch(createQCMREFile(attachment as Array<JobFileNew>));
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createQCMREFile = createAsyncThunk(
    'processDetail/mre/createQCMREFile',
    async (
        args: Array<JobFileMRE>,
        {getState, rejectWithValue, dispatch}
    ) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            const createResponse = args?.map(async (arg, index) => {
                if (arg.path !== "") {
                    return await MreApi.createQCMREFile(arg, userAuth?.id as string).then((data) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.success,
                            })
                        );
                        return data;
                    }).catch((e) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.failed,
                            })
                        );
                        return e;
                    });
                } else {
                    return {};
                }
            });

            Promise.all(createResponse).then(async (attachment) => {
                console.log('saved successfully', attachment);
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getQCMREFile = createAsyncThunk(
    'processDetail/mre/getQCMREFile',
    async (id: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await MreApi.getQCMREFile(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteQCMREFile = createAsyncThunk(
    'processDetail/mre/deleteQCMREFile',
    async (args: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await MreApi.deleteQCMREFile(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

// --- end of qc mre file ---

export const updateMRENotes = createAsyncThunk(
    'processDetail/mre/updateMRENotes',
    async (args: UpdateMRENote, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MreApi.updateMRENotes(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateQCMRENotes = createAsyncThunk(
    'processDetail/mre/updateQCMRENotes',
    async (args: UpdateQCMRENote, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MreApi.updateQCMRENotes(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const readyToQCMRE = createAsyncThunk(
    'processDetail/mre/readyToQCMRE',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MreApi.readyToQCMRE(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);
export const completeMRE = createAsyncThunk(
    'processDetail/mre/completeMRE',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await MreApi.completeMRE(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const mreSlice = createSlice({
    name: 'mreState',
    initialState: {} as MRESlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.uploadStatus = [];
            state.uploadProgress = [];
            state.deleteFileStatus = RequestStatus.pending;
            state.deleteQCFileStatus = RequestStatus.pending;
            state.uploadStatus = [];
            state.uploadProgress = [];
        },
        setUploadProgress: (state, payload: PayloadAction<Array<UploadProgress>>) => {
            state.uploadProgress = payload.payload;
        },
        setUploadStatus: (state, payload: PayloadAction<Array<UploadStatus>>) => {
            state.uploadStatus = payload.payload;
        },
        updateUploadProgress: (state, payload: PayloadAction<UploadProgress>) => {
            let progress = state.uploadProgress ?? [];
            let objIndex = state.uploadProgress?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            progress[objIndex as number].progress = payload.payload.progress;
            state.uploadProgress = progress;
        },
        updateUploadStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].status = payload.payload.status;
            state.uploadStatus = status;
        },
        updateCreateStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].createStatus = payload.payload.createStatus;
            state.uploadStatus = status;
        },
    },
    extraReducers: (builder) => {
        // create mre file
        builder.addCase(createMREFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createMREFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createMREFile.rejected, (state) => {
            state.isLoading = false;
        });
        // get mre file
        builder.addCase(getMREFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getMREFile.fulfilled, (state, {payload}) => {
            state.jobMREFile = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getMREFile.rejected, (state) => {
            state.isLoading = false;
        });
        // delete mre file
        builder.addCase(deleteMREFile.pending, (state) => {
            state.deleteFileStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteMREFile.fulfilled, (state) => {
            state.deleteFileStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteMREFile.rejected, (state, {payload}) => {
            state.deleteFileStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // create qc mre file
        builder.addCase(createQCMREFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createQCMREFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createQCMREFile.rejected, (state) => {
            state.isLoading = false;
        });
        // get qc mre file
        builder.addCase(getQCMREFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getQCMREFile.fulfilled, (state, {payload}) => {
            state.jobQCMREFile = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getQCMREFile.rejected, (state) => {
            state.isLoading = false;
        });
        // delete qc mre file
        builder.addCase(deleteQCMREFile.pending, (state) => {
            state.deleteQCFileStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteQCMREFile.fulfilled, (state) => {
            state.deleteQCFileStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteQCMREFile.rejected, (state, {payload}) => {
            state.deleteQCFileStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // update mre note
        builder.addCase(updateMRENotes.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateMRENotes.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateMRENotes.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // update qc mre note
        builder.addCase(updateQCMRENotes.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateQCMRENotes.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateQCMRENotes.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // ready to qc mre reducer
        builder.addCase(readyToQCMRE.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(readyToQCMRE.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(readyToQCMRE.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // complete mre reducer
        builder.addCase(completeMRE.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(completeMRE.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(completeMRE.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});
export const {
    reset,
    setUploadProgress,
    setUploadStatus,
    updateUploadProgress,
    updateUploadStatus,
    updateCreateStatus,
} = mreSlice.actions;
export default mreSlice.reducer;