import React, {useEffect} from 'react';
import {Avatar, Dropdown, Navbar} from 'flowbite-react';
import {useAppDispatch, useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";
import DynamicNavRoute from "./DynamicNavRoute";
import {Navigate, useNavigate} from "react-router-dom";
import {setDynamicNavId} from "../sidebar.reducer";
import {getInitials} from "../../../helpers/convert";
import {IoNotificationsCircleOutline, IoNotificationsCircleSharp} from "react-icons/io5";
import {getNotificationUser} from "../../profile/profile.reducer";

const NavbarHeader = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const userState = useAppSelector((state: RootState) => state.user.userAuth);
    const notificationUserList = useAppSelector((state: RootState) => state.profile.notificationUserList ?? []);

    useEffect(() => {
        if (userState?.user_id !== undefined) {
            dispatch(getNotificationUser({
                skip: 0,
                take: 10,
                userId: userState?.user_id
            }));
        }
    }, [userState]);

    const onSignoutClicked = () => {
        return navigate('/logout', {replace: true});
    }

    const onProfileClicked = (path: string) => {
        dispatch(setDynamicNavId(path));
        return navigate('/profile', {replace: true});
    }

    return (
        <Navbar
            fluid
            rounded
            className={`bg-primary-color`}
        >
            <div className="flex md:order-2">
                <div className={"flex mx-5 items-center"}>
                    <Dropdown label={<Avatar
                        img="https://img.icons8.com/ios-filled/50/ffffff/appointment-reminders--v1.png"
                        rounded
                        status="busy"
                        statusPosition="top-right"
                        size="xs"
                    />}
                              arrowIcon={false}
                              inline
                    >
                        <Dropdown.Header>
            <span className="block text-sm">
              Notifications
            </span>
                        </Dropdown.Header>
                        {notificationUserList?.map((item) => (
                            <Dropdown.Item>{item.description}</Dropdown.Item>
                        ))}
                    </Dropdown>
                </div>
                <div>
                    <Dropdown
                        inline
                        label={<Avatar alt="User settings" placeholderInitials={getInitials(userState?.name ?? "")}
                                       rounded/>}
                    >
                        <Dropdown.Header>
            <span className="block text-sm">
              {userState?.name}
            </span>
                            <span className="block truncate text-sm font-medium">
              {userState?.email}
            </span>
                        </Dropdown.Header>
                        <Dropdown.Item onClick={() => onProfileClicked('my-profile')}>
                            My Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => onProfileClicked('absent')}>
                            Absent
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={onSignoutClicked}>
                            Sign out
                        </Dropdown.Item>
                    </Dropdown>
                    <Navbar.Toggle/>
                </div>
            </div>
            <div>
                <DynamicNavRoute/>
            </div>
        </Navbar>
    );
};

export default NavbarHeader;