import {EmployeeWorkSortEnum} from "../../approval/models";
import {SupportingDocumentSortEnum} from "./models";

export const supportingDocumentSortOption = [
    {
        label: "date_request",
        text: "date_request",
        value: SupportingDocumentSortEnum.requestDate.toString()
    },
    {
        label: "area",
        text: "area",
        value: SupportingDocumentSortEnum.area.toString()
    },
    {
        label: "employee_name_req_by",
        text: "employee_name_req_by",
        value: SupportingDocumentSortEnum.req_by.toString()
    },
    {
        label: "job_id",
        text: "job_id",
        value: SupportingDocumentSortEnum.job_id.toString()
    },
    {
        label: "job_number",
        text: "job_number",
        value: SupportingDocumentSortEnum.job_number.toString()
    },
    {
        label: "note",
        text: "note",
        value: SupportingDocumentSortEnum.note.toString()
    },
    {
        label: "other_number",
        text: "other_number",
        value: SupportingDocumentSortEnum.other_number.toString()
    },
    {
        label: "sales_order",
        text: "sales_order",
        value: SupportingDocumentSortEnum.sales_order.toString()
    },
    {
        label: "total_pole",
        text: "total_pole",
        value: SupportingDocumentSortEnum.total_pole.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: SupportingDocumentSortEnum.status_name.toString()
    },
    {
        label: "type_name",
        text: "type_name",
        value: SupportingDocumentSortEnum.type_name.toString()
    },
]