import {SortingEnum} from "../../approval/models";

export type Pole = {
    id: string;
    job_id: string;
    seq: string | null;
    no: string | null;
    address: string | null;
    is_pip: boolean;
    latitude: number;
    longitude: number;
    type_id: string;
    is_fielding: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    notes: string | null;
    status_id: string;
    employee_prep_id?: string | null;
    employee_calcspec_id?: string | null;
    employee_qc_id?: string | null;
    employee_katapult_id?: string | null;
    job_number_job: string;
    pole_type_name: string | null;
    pole_status_name: string;
    index?: number;
    number_id?: string;
    type_name?: string;
    koj?: string;
};

export type PoleKatapult = {
    id: string;
    job_id: string;
    seq: string;
    no: null | string;
    address: null | string;
    is_pip: boolean;
    latitude: number;
    longitude: number;
    type_id: string;
    type_name: string;
    is_fielding: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    notes: null | string;
    status_id: null | string;
    employee_prep_id: null | string;
    employee_calcspec_id: null | string;
    employee_prep_name: null | string;
    employee_calcspec_name: null | string;
    employee_qc_id: null | string;
    employee_qc_name: null | string;
    employee_katapult_id: null | string;
    employee_katapult_name: null | string;
    index: number;
    status_name: string;
};

export type PoleMap = {
    id: string;
    ts_created: string;
    job_id: string;
    latitude: number;
    longitude: number;
    no: string;
};

export type InputPole = {
    id?: string;
    job_id: string;
    seq: string;
    no: string | null;
    address: string | null;
    is_pip: boolean;
    latitude: number;
    longitude: number;
    type_id: string;
    is_fielding: boolean;
    notes: string | null;
    status_id?: string;
    employee_prep_id?: string;
    employee_calcspec_id?: string;
    employee_qc_id?: string;
};

export type LocationCoordinate = {
    lat: number;
    lng: number;
};

export type ChangeMultipleStatus = {
    pole_ids: string;
    status_id: string;
};

export type GetByRadius = {
    latitude: number;
    longitude: number;
    radius: number;
};

export enum PoleGetByJobColumnSort
{
    pole_seq,
    pole_no,
    cs,
    qc,
    status,
    index
}

export type GetPoleRequest = {
    job_id: string;
    skip?: number;
    take?: number;
    seq?: string;
    no?: string;
    cs?: string;
    qc?: string;
    status?: string;
    column?: PoleGetByJobColumnSort;
    sort?: SortingEnum;
};

export type GetMREPoleRequest = {
    skip: number;
    take: number;
    job_id: string;
};

export type DetailPoleMRE = {
    id: string;
    job_id: string;
    seq: string;
    no: string | null;
    type: string;
    status: string;
    employee_mre_id: string;
    employee_mre_name: string;
    employee_mre_qc_id: string;
    employee_mre_qc_name: string;
    index: number;
    status_name: string;
};

export type PoleMRE = {
    id: string;
    job_id: string;
    seq: string;
    no: string;
    address: string;
    is_pip: boolean;
    latitude: number;
    longitude: number;
    type_id: string;
    type_name: string;
    is_fielding: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    notes: string;
    status_id: string | null;
    employee_prep_id: string | null;
    employee_calcspec_id: string | null;
    employee_prep_name: string | null;
    employee_calcspec_name: string | null;
    employee_qc_id: string | null;
    employee_qc_name: string | null;
    employee_katapult_id: string | null;
    employee_mre_qc_id: string | null;
    employee_mre_qc_name: string | null;
    employee_mre_id: string | null;
    employee_mre_name: string | null;
    index: number;
    status_name: string;
};

export enum PoleAssignmentTypeEnum
{
    prep,calspec, qc, katapult, mre, mre_qc
}

export type RemovePoleAssignReq = {
    poleId: string;
    type: PoleAssignmentTypeEnum;
}