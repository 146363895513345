import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import InputText from 'components/TextInput/InputText';
import {Fielding, InputFieldingUpdate} from '../models';
import Button from 'components/Button/Button';
import {useAppDispatch} from 'helpers/redux';
import {isObjectEmpty} from 'helpers/checkEmptiness';
import BasicDatePicker from "../../../../../components/DatePicker/BasicDatePicker";
import TextArea from "../../../../../components/TextArea/TextArea";
import {customWarnOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {updateFullAttachment} from "../../../../basic/attachment/attachment.reducer";
import moment from "moment-timezone";
import {dateSetTimeToNow} from "../../../../../helpers/convert";

const FieldingUpdate = ({
                            isLoading = false,
                            data = {} as Fielding,
                        }: FieldingUpdateProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const {
        control,
        register,
        handleSubmit,
        reset: resetForm,
        formState: {errors},
    } = useForm<InputFieldingUpdate>();

    useEffect(() => {
        if (!isObjectEmpty(data)) {
            resetForm({
                missing_fielding: data.missing_fielding,
                date_follow_up: data.date_follow_up === '0001-01-01T00:00:00' ? new Date().toISOString() : data.date_follow_up,
                note: data.note,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onSubmit = (input: InputFieldingUpdate) => {
        MySwal.fire(
            customWarnOption(
                'Update Fielding',
                'Are you sure? You will not be able to recover this data!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    updateFullAttachment({
                        id: data.id,
                        job_id: data.job_id,
                        type_id: data.type_id,
                        value: data.value,
                        date_request: data.date_request,
                        link: data.link,
                        note: input.note,
                        option: data.option,
                        employee_id: data.employee_id,
                        date_follow_up: dateSetTimeToNow(input.date_follow_up),
                        missing_fielding: input.missing_fielding,
                        ts_last_check: new Date().toISOString(),
                    })
                );
            }
        });
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputText
                    {...register('missing_fielding')}
                    label="Missing Fielding"
                    placeholder="Missing Fielding"
                    type="number"
                    message={errors.missing_fielding?.message}
                />
                <BasicDatePicker
                    label="Follow Up Date"
                    name="date_follow_up"
                    placeholderText="Select Follow Up Date"
                    control={control}
                />
                <TextArea
                    label="Fielding Notes"
                    placeholder="Input Fielding Notes"
                    {...register('note')}
                />

                <div className="pt-2 pb-2">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                        isLoading={isLoading}
                    />
                </div>
            </form>
        </div>
    );
};

export interface FieldingUpdateProps {
    isLoading: boolean;
    data: Fielding;
}

export default FieldingUpdate;
