import React from 'react';
import {CgSpinner} from "react-icons/cg";

const Spinner = () => {

        return <span>
            <div className="mr-3 inline">
                <span role="status">
                    <CgSpinner className="inline animate-spin text-gray-200 w-5 h-5" />
                </span>
            </div>
            Loading ...
        </span>
};

export default Spinner;
