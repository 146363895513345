import React, { useState } from 'react';
import AgDataGrid from 'components/DataGrid/AgDataGrid';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {filterParams} from "../../../../../helpers/agTable";
import {TbRefreshAlert} from "react-icons/tb";
import {customWarnOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {changeHolidayToRequest} from "../../attendanceReport.reducer";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {RootState} from "../../../../../app/store";
import {UserCredential} from "../../../../login/models";

const HolidayReportTable = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const holidayReportList = useAppSelector(
        (state) => state.attendanceReport.holidayReportList ?? []
    );

    const onActionClicked = (job_id: string) => {
        MySwal.fire(
            customWarnOption(
                'Change to Request',
                'Are you sure to change this holiday to requested? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(changeHolidayToRequest(job_id));
            }
        });
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex h-[100%] items-center">
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.Attendance, ActionEnum.ChangeToRequest) &&
                    props.data?.status === 'ManagerApproval' &&
                    <button onClick={() => onActionClicked(props.data?.id)}>
                        <TbRefreshAlert className="mx-1 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([
        {field: 'nip', headerName: 'NIP', filter: true},
        { field: 'team', headerName: 'Team', filter: true },
        { field: 'name', headerName: 'Name', filter: true },
        { field: 'ts_created', headerName: 'Created Date', filter: 'agDateColumnFilter', filterParams: filterParams, cellDataType: 'date' },
        { field: 'reqDate', headerName: 'Request Date', filter: 'agDateColumnFilter', filterParams: filterParams, cellDataType: 'date' },
        { field: 'notes', headerName: 'Notes' },
        { field: 'status', headerName: 'Status', filter: true },
        { field: 'rejectionNotes', headerName: 'Rejection Notes' },
        { field: 'managerApprovedByName', headerName: 'Manager Approved' },
        { field: 'approvedByName', headerName: 'Approved By' },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
            }),
        },
    ]);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <AgDataGrid
                        rowData={holidayReportList}
                        columnDefs={columnDefs}
                        supportSaveTableState
                        tableStateName={'report-holiday'}
                        defaultPageSize={20}
                    />
                </div>
            </div>
        </div>
    );
};

export default HolidayReportTable;
