import { ComponentPropsWithRef, FC, forwardRef } from 'react';

type TextAreaProps = {
    label?: string;
    message?: string;
};

type TextAreaType = FC<TextAreaProps & ComponentPropsWithRef<'textarea'>>;

const TextArea: TextAreaType = forwardRef((props, ref) => {
    const { label = '', message = '', ...rest } = props;

    return (
        <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
            {label && (
                <label
                    htmlFor={label}
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                >
                    {label}
                </label>
            )}

            <textarea
                id={label}
                ref={ref}
                {...rest}
                className="block w-full rounded-lg border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-color focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-color dark:focus:ring-primary-color"
            />

            {message !== '' && (
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {message}
                </p>
            )}
        </div>
    );
});

export default TextArea;
