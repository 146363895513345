import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import {HttpClientError, RequestStatus} from "http-client";
import {useNavigate, useParams} from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {createEmployee, getSingle, reset, updateEmployee} from "../employee.reducer";
import {InputEmployee, InputModel} from "../models";
import EmployeeForm from "../components/EmployeeForm";
import {getList} from "../../position/position.reducer";
import {getList as getTeamList} from "../../team/team.reducer";
import {savedStatusOption} from "../../../../helpers/alertLabel";
import {UserCredential} from "../../../login/models";

const EmployeeDetailPage = ({isEdit = false, isMyProfile = false}: EmployeeDetailPageProps) => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { id } = useParams();
    const MySwal = withReactContent(Swal)

    const userAuth = useAppSelector((state: RootState) => state.user.userAuth ?? {} as UserCredential);
    const isLoading = useAppSelector((state: RootState) => state.employee.isLoading ?? false);
    const status = useAppSelector((state: RootState) => state.employee.status);
    const error = useAppSelector((state: RootState) => state.employee.error ?? ({} as HttpClientError));

    useEffect(() => {
        dispatch(getList({skip: 0, take: 500}));
        dispatch(getTeamList({skip: 0, take: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEdit && id !== undefined) {
            dispatch(getSingle(id as string));
        } else {
            dispatch(reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isEdit]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                if (isEdit && id !== undefined) {
                    return navigate("/employee", {replace: true});
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onSubmit = (formInput: InputModel) => {
        if (isEdit) {
            let _input = Object.assign({} as InputEmployee, formInput);
            _input.id = id === undefined ? userAuth.user_id : id as string;
            _input.date_entry = new Date(formInput.date_entry).toISOString();
            _input.education = parseInt(formInput.education as string);
            _input.relation_status = parseInt(formInput.relation_status as string);
            _input.total_children = parseInt(formInput.total_children as string);
            dispatch(updateEmployee(_input));
        } else {
            let _input = Object.assign({} as InputEmployee, formInput);
            _input.education = parseInt(formInput.education as string);
            _input.date_entry = new Date(formInput.date_entry).toISOString();
            _input.relation_status = parseInt(formInput.relation_status as string);
            _input.total_children = parseInt(formInput.total_children as string);
            dispatch(createEmployee(_input));
        }
        dispatch(reset());
    };

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <h1 className="dark:text-gray-300">Employee Form</h1>

            <EmployeeForm isLoading={isLoading} isEdit={isEdit} onSubmit={onSubmit} isMyProfile={isMyProfile} />
        </div>
    );
};

export interface EmployeeDetailPageProps {
    isEdit: boolean;
    isMyProfile?: boolean;
}

export default EmployeeDetailPage;
