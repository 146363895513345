import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    DetailOvertime,
    HolidayOvertimeApprovalEnum,
    InputOvertime,
    OvertimeModel,
    SendOvertimeReq
} from "../../models";
import Text from "../../../../components/Text/Text";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import BasicSelectInput, {Option} from "../../../../components/SelectInput/BasicSelectInput";
import {useAppDispatch, useAppSelector} from "../../../../helpers/redux";
import {RootState} from "../../../../app/store";
import {SingleValue} from "react-select";
import {getList} from "../../../master/position/position.reducer";
import InputText from "../../../../components/TextInput/InputText";
import {multiplierOptions} from "../../constants";
import ReactTable from "../../../../components/Table/ReactTable";
import {BiCheckCircle, BiEditAlt} from "react-icons/bi";
import {MdOutlineCancel, MdOutlineDeleteForever} from "react-icons/md";
import EditableCell from "../../../../components/Table/EditableCell";
import Button from "../../../../components/Button/Button";
import {createOvertime, updateOvertime} from "../../profile.reducer";
import {UserCredential} from "../../../login/models";

const AddOvertimeForm = ({data = {} as OvertimeModel, isEdit = false}: AddOvertimeFormProps) => {
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset: resetForm,
        formState: {errors},
    } = useForm<InputOvertime>();

    const user = useAppSelector((state: RootState) => state.user.userAuth ?? {} as UserCredential);
    const positionOptions = useAppSelector((state: RootState) => state.position.options ?? []);
    const positionLoading = useAppSelector((state: RootState) => state.position.isLoading ?? false);

    const [rowdata, setRowData] = useState<DetailOvertime[]>([]);
    const [tempData, setTempData] = useState<DetailOvertime>({} as DetailOvertime);
    const [isRowEdit, setIsRowEdit] = useState('');
    const [isRowCreate, setIsRowCreate] = useState<boolean>(false);

    const roleIdWatcher = watch('roleId');
    const roleNameWatcher = watch('roleName');

    useEffect(() => {
        dispatch(getList({skip: 0, take: 500}));
    }, []);

    useEffect(() => {
        if (isEdit) {
            const _data = {...data, multiplier: data.multiplier.toString()};
            setRowData(_data.details ?? []);
            resetForm(_data);
        }
    }, [isEdit, data]);
    const onSubmit = (data: InputOvertime) => {

        const {roleName, ...rest} = data;

        if (isEdit) {
            const _data = {
                ...rest,
                notes: data.notes ?? "",
                status: HolidayOvertimeApprovalEnum.Requested,
                rejectionNote: data.rejectionNote ?? "",
                approveMinute: data.approveMinute ?? 0,
                multiplier: parseFloat(data.multiplier),
                managerApproveById: data.managerApproveById ?? null,
                approveById: data.approveById ?? null,
                userId: user.user_id,
                detail: rowdata,
            } as SendOvertimeReq;

            dispatch(updateOvertime(_data));
        } else {
            const _data = {
                ...rest,
                notes: "",
                status: HolidayOvertimeApprovalEnum.Requested,
                rejectionNote: "",
                approveMinute: 0,
                multiplier: parseFloat(data.multiplier),
                managerApproveById: null,
                approveById: null,
                userId: user.user_id,
                detail: rowdata,
            } as SendOvertimeReq;

            dispatch(createOvertime(_data));
        }

    }

    const onRoleChange = (val: SingleValue<Option>) => {
        setValue('roleId', val?.value ?? '');
        setValue('roleName', val?.text ?? '');
    };

    const updateMyData = (rowIndex: number, columnId: any, value: any) => {
        // We also turn on the flag to not reset the page
        setRowData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    const onCancelClick = (data: DetailOvertime, index: number) => {
        setIsRowEdit('');
        if (isRowCreate) {
            const dataUpdate = [...rowdata];
            dataUpdate.splice(index, 1);
            setRowData(dataUpdate);
        } else {
            const dataUpdate = [...rowdata];
            dataUpdate[index] = tempData;
            setRowData(dataUpdate);
            setTempData({} as DetailOvertime);
        }
    };

    const onSaveClick = () => {
        setIsRowEdit('');
        setIsRowCreate(false);
    };

    const onEditClick = (data: DetailOvertime, index: number) => {
        setTempData(data);
        setIsRowEdit(index.toString());
        setIsRowCreate(false);
    };

    const onDeleteClicked = (index: number) => {
        const dataUpdate = [...rowdata];
        dataUpdate.splice(index, 1);
        setRowData(dataUpdate);
    }

    const onAddRowClick = () => {
        setIsRowEdit('0');
        setIsRowCreate(true);
        let data = [...rowdata];
        data.unshift({
            pole: 0,
            work: '',
            note: '',
        });
        setRowData(
            data
        );
    };

    const columns = [
        {
            Header: 'NO.',
            width: 50,
            Cell: ({row: {index}}: any) => {
                return <div>{index + 1}</div>;
            },
        },
        {
            Header: 'Pole',
            minWidth: 150,
            accessor: 'pole',
            Cell: EditableCell,
        },
        {
            Header: 'Work',
            minWidth: 150,
            accessor: 'work',
            Cell: EditableCell,
        },
        {
            Header: 'Note',
            minWidth: 150,
            accessor: 'note',
            Cell: EditableCell,
        },
        {
            Header: 'Action',
            minWidth: 150,
            sticky: 'right',
            Cell: ({row}: any) => {
                return (
                    <>
                        {isRowEdit === row?.index.toString() ? (
                            <>
                                <button
                                    onClick={() => onSaveClick()}
                                >
                                    <BiCheckCircle className="mr-2 text-2xl text-primary-color"/>
                                </button>
                                <button onClick={() => onCancelClick(row.original, row?.index)}>
                                    <MdOutlineCancel className="mr-2 text-2xl text-secondary-color"/>
                                </button>
                            </>
                        ) : (
                            <>
                                <button onClick={() => onEditClick(row.original,row?.index)}>
                                    <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                                </button>
                                <button
                                    onClick={() =>
                                        onDeleteClicked(row?.index)
                                    }
                                >
                                    <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                                </button>
                            </>
                        )}
                    </>
                );
            }
        }
    ];

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text label="Create Date" value={new Date().toLocaleDateString('en-US')}/>
                <SelectInput
                    key={`my_unique_select_key__role`}
                    options={positionOptions}
                    isLoading={positionLoading}
                    value={{text: roleNameWatcher, label: roleNameWatcher, value: roleIdWatcher} as Option}
                    label="Role"
                    onChange={onRoleChange}
                    message={errors.roleId?.message}
                />
                <InputText
                    {...register('reqMinute')}
                    label="Req Minutes"
                    placeholder="Req Minutes"
                    type="number"
                    message={errors.reqMinute?.message}
                />
                <BasicSelectInput
                    label="Multiple"
                    options={multiplierOptions}
                    message={errors.multiplier?.message}
                    {...register('multiplier')}
                />
                <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Notes
                    </label>
                    <div className="mr-2">
                        <Button title="Add Notes" type="button" onClick={onAddRowClick}/>
                    </div>
                </div>
                <ReactTable
                    columns={columns}
                    data={rowdata}
                    updateMyData={updateMyData}
                    rowEdit={isRowEdit}
                />
                <div className="mr-2">
                    <Button title="Save" type="submit"/>
                </div>
            </form>
        </div>
    );
};

export interface AddOvertimeFormProps {
    data: OvertimeModel;
    isEdit: boolean;
}

export default AddOvertimeForm;
