import React from 'react';
import DashboardOverview from "../components/DashboardOverview";
import DashboardJob from "../components/DashboardJob";

const DashboardPage = () => {
    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Dashboard</h1>
                </div>
                <div className="mt-3">
                    <DashboardOverview />
                </div>
                <div className="mt-3">
                    <DashboardJob />
                </div>
            </div>
        </div>

    );
};

export default DashboardPage;
