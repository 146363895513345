import { configureStore } from '@reduxjs/toolkit';
import { LSK_USER_SLICE, SECRET_KEY } from '../constants';
import CryptoJS from 'crypto-js';

import loginReducer, { UserSlice } from '../features/login/login.reducer';
import positionReducer, {
    PositionSlice,
} from '../features/master/position/position.reducer';
import teamReducer, { TeamSlice } from '../features/master/team/team.reducer';
import employeeReducer, {
    EmployeeSlice,
} from '../features/master/employee/employee.reducer';
import jobTypeReducer, {
    JobTypeSlice,
} from '../features/master/jobType/jobType.reducer';
import jobCategoryReducer, {
    JobCategorySlice,
} from '../features/master/jobCategory/jobCategory.reducer';
import jobSubjectNotesReducer, {
    JobSubjectNoteSlice,
} from '../features/master/jobSubjectNotes/jobSubjectNotes.reducer';
import jobStatusReducer, {
    JobStatusSlice,
} from '../features/master/jobStatus/jobStatus.reducer';
import jobKindReducer, {
    JobKindSlice,
} from '../features/master/jobKind/jobKind.reducer';
import projectReducer, {
    ProjectSlice,
} from '../features/master/project/project.reducer';
import districtReducer, {
    DistrictSlice,
} from '../features/master/district/district.reducer';
import departmentReducer, {
    DepartmentSlice,
} from '../features/master/department/department.reducer';
import departmentOwnerReducer, {
    DepartmentOwnerSlice,
} from '../features/master/departmentOwner/departmentOwner.reducer';
import jobSourceReducer, {
    JobSourceSlice,
} from '../features/master/jobSource/jobSource.reducer';
import jobReducer, {
    JobSlice,
} from '../features/worksheet/process/job/job.reducer';
import jobCheckReducer, {
    JobCheckSlice,
} from '../features/worksheet/jobData/jobCheck/jobCheck.reducer';
import emailTitleReducer, {
    EmailTitleSlice,
} from '../features/master/emailTitle/emailTitle.reducer';
import fieldingReducer, {
    FieldingSlice,
} from '../features/worksheet/jobData/fielding/fielding.reducer';
import poleTypeReducer, {
    PoleTypeSlice,
} from '../features/master/poleType/poleType.reducer';
import jobPrepReducer, {
    JobPrepSlice,
} from '../features/worksheet/processDetail/jobPrep/jobPrep.reducer';
import poleStatusReducer, {
    PoleStatusSlice,
} from '../features/master/poleStatus/poleStatus.reducer';
import supportingDocumentReducer, {
    SupportingDocumentSlice,
} from '../features/master/supportingDocument/supportingDocument.reducer';
import ProcessJobPrepReducer, {
    ProcessJobPrepSlice,
} from '../features/worksheet/process/jobPrep/processJobPrep.reducer';
import supportingDocumentAttachmentReducer, {
    SupportingDocumentAttachmentSlice,
} from '../features/worksheet/jobData/supportingDocument/supportingDocumentAttachment.reducer';
import mapReqReducer, {
    MapReqSlice,
} from '../features/worksheet/jobData/mapReq/mapReq.reducer';
import calcSpecReducer, {
    CalcSpecSlice,
} from '../features/worksheet/process/calcSpec/calcSpec.reducer';
import jobCalcSpecReducer, {
    JobCalcSpecSlice,
} from '../features/worksheet/processDetail/jobOCalc/jobCalcSpec.reducer';
import qcReducer, {
    QCSlice,
} from '../features/worksheet/process/qc/qc.reducer';
import poleReducer, { PoleSlice } from '../features/basic/pole/pole.reducer';
import jobQCReducer, {JobQCSlice} from "../features/worksheet/processDetail/jobOCalc/jobQC.reducer";
import attachmentReducer, {AttachmentSlice} from "../features/basic/attachment/attachment.reducer";
import emailReducer, {EmailSlice} from "../features/basic/email/email.reducer";
import jobDetailReducer, {JobDetailSlice} from "../features/worksheet/processDetail/jobDetail/jobDetail.reducer";
import processKatapultReducer, {
    ProcessKatapultSlice
} from "../features/worksheet/process/katapult/processKatapult.reducer";
import katapultReducer, {KatapultSlice} from "../features/worksheet/processDetail/katapult/katapult.reducer";
import processMREReducer, {ProcessMRESlice} from "../features/worksheet/process/mre/processMRE.reducer";
import mreReducer, {MRESlice} from "../features/worksheet/processDetail/mre/mre.reducer";
import dashboardReducer, {DashboardSlice} from "../features/dashboard/dashboard.reducer";
import historyReducer, {HistorySlice} from "../features/report/history/history.reducer";
import staffReportReducer, {StaffReportSlice} from "../features/report/staffReport/staffReport.reducer";
import jobReportReducer, {JobReportSlice} from "../features/report/jobReport/jobReport.reducer";
import sidebarReducer, {SidebarSlice} from "../features/sidebarPanel/sidebar.reducer";
import profileReducer, {ProfileSlice} from "../features/profile/profile.reducer";
import attendanceReportReducer, {AttendanceReportSlice} from "../features/report/attendance/attendanceReport.reducer";
import approvalReducer, {ApprovalSlice} from "../features/approval/approval.reducer";
import holidayReducer, {HolidaySlice} from "../features/master/holiday/holiday.reducer";
import supportingDocumentReportReducer, {
    SupportingDocumentReportSlice
} from "../features/report/supportingDocument/supportingDocumentReport.reducer";
import billJobReducer, {BillJobSlice} from "../features/billJob/billJob/billJob.reducer";
import gdevReducer, {GDevSlice} from "../features/billJob/gdev/gdev.reducer";

export const store = configureStore<RootState>({
    reducer: {
        user: loginReducer,
        position: positionReducer,
        team: teamReducer,
        employee: employeeReducer,
        jobType: jobTypeReducer,
        jobCategory: jobCategoryReducer,
        jobSubjectNotes: jobSubjectNotesReducer,
        jobStatus: jobStatusReducer,
        jobKind: jobKindReducer,
        jobSource: jobSourceReducer,
        poleType: poleTypeReducer,
        poleStatus: poleStatusReducer,
        supportingDocument: supportingDocumentReducer,
        project: projectReducer,
        district: districtReducer,
        department: departmentReducer,
        departmentOwner: departmentOwnerReducer,
        emailTitle: emailTitleReducer,
        pole: poleReducer,
        attachment: attachmentReducer,
        email: emailReducer,
        holiday: holidayReducer,
        job: jobReducer,
        jobDetail: jobDetailReducer,
        jobPrep: jobPrepReducer,
        jobCalcSpec: jobCalcSpecReducer,
        jobQC: jobQCReducer,
        jobCheck: jobCheckReducer,
        fielding: fieldingReducer,
        supportingDocumentAttachment: supportingDocumentAttachmentReducer,
        mapReq: mapReqReducer,
        processJobPrep: ProcessJobPrepReducer,
        calcSpec: calcSpecReducer,
        qc: qcReducer,
        processMRE: processMREReducer,
        processKatapult: processKatapultReducer,
        katapult: katapultReducer,
        mre: mreReducer,
        dashboard: dashboardReducer,
        history: historyReducer,
        staffReport: staffReportReducer,
        jobReport: jobReportReducer,
        attendanceReport: attendanceReportReducer,
        supportingDocumentReport: supportingDocumentReportReducer,
        profile: profileReducer,
        approval: approvalReducer,
        sidebar: sidebarReducer,
        billJob: billJobReducer,
        gdev: gdevReducer,
    },
});

export type RootState = {
    user: UserSlice;
    position: PositionSlice;
    team: TeamSlice;
    employee: EmployeeSlice;
    jobType: JobTypeSlice;
    jobCategory: JobCategorySlice;
    jobSubjectNotes: JobSubjectNoteSlice;
    jobStatus: JobStatusSlice;
    jobKind: JobKindSlice;
    jobSource: JobSourceSlice;
    poleType: PoleTypeSlice;
    poleStatus: PoleStatusSlice;
    supportingDocument: SupportingDocumentSlice;
    project: ProjectSlice;
    district: DistrictSlice;
    department: DepartmentSlice;
    departmentOwner: DepartmentOwnerSlice;
    emailTitle: EmailTitleSlice;
    pole: PoleSlice;
    attachment: AttachmentSlice;
    email: EmailSlice;
    holiday: HolidaySlice;
    job: JobSlice;
    jobDetail: JobDetailSlice;
    jobPrep: JobPrepSlice;
    jobCalcSpec: JobCalcSpecSlice;
    jobQC: JobQCSlice;
    jobCheck: JobCheckSlice;
    fielding: FieldingSlice;
    supportingDocumentAttachment: SupportingDocumentAttachmentSlice;
    mapReq: MapReqSlice;
    processJobPrep: ProcessJobPrepSlice;
    calcSpec: CalcSpecSlice;
    qc: QCSlice;
    processMRE: ProcessMRESlice;
    processKatapult: ProcessKatapultSlice;
    katapult: KatapultSlice;
    mre: MRESlice;
    dashboard: DashboardSlice;
    history: HistorySlice;
    staffReport: StaffReportSlice;
    jobReport: JobReportSlice;
    attendanceReport: AttendanceReportSlice;
    supportingDocumentReport: SupportingDocumentReportSlice;
    profile: ProfileSlice;
    approval: ApprovalSlice;
    sidebar: SidebarSlice;
    billJob: BillJobSlice;
    gdev: GDevSlice;
};

export type AppDispatch = typeof store.dispatch;

store.subscribe(() => {
    const state = store.getState();
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(state.user), SECRET_KEY).toString();
    localStorage.setItem(LSK_USER_SLICE, encrypted);
});