import {BaseServiceClass} from "../../base-service-class";
import {GetStaffReq, SendStaffReq, StaffReportModel} from "../report/staffReport/models";
import httpClient, {ApiResponseWrapper} from "../../http-client";
import {
    AttendanceModel, EmployeeHolidayUserModel,
    GetAttendanceReq, GetAttendanceWithFilterReq,
    GetHolidayReq, GetHolidayWithFilterReq, GetNotificationUserReq, GetOvertimeReq, GetOvertimeWithFilterReq,
    HolidayModel, NotificationModel, OvertimeModel,
    SendAttendanceReq,
    SendHolidayReq, SendOvertimeReq
} from "./models";


export default class ProfileApi extends BaseServiceClass {
    static getAttendanceList(args: GetAttendanceReq, token: string) {
        return httpClient.get<ApiResponseWrapper<AttendanceModel[]>>(this.PATH_PROFILE.attendance, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getAttendanceApprovalList(args: GetAttendanceReq, token: string) {
        return httpClient.get<ApiResponseWrapper<AttendanceModel[]>>(this.PATH_PROFILE.get_attendance_approval, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getAttendance(args: GetAttendanceWithFilterReq, token: string) {
        return httpClient.post<ApiResponseWrapper<AttendanceModel[]>>(this.PATH_PROFILE.get_attendance_user, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static createAttendance(args: SendAttendanceReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_PROFILE.attendance, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static updateAttendance(args: SendAttendanceReq, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(this.PATH_PROFILE.attendance, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static deleteAttendance(arg: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(this.PATH_PROFILE.attendance, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: arg
            }
        }).then((res) => res.data);
    }
    static getHolidayList(args: GetHolidayReq, token: string) {
        return httpClient.get<ApiResponseWrapper<HolidayModel[]>>(this.PATH_PROFILE.holiday, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getHolidayApprovalList(args: GetHolidayReq, token: string) {
        return httpClient.get<ApiResponseWrapper<HolidayModel[]>>(this.PATH_PROFILE.get_holiday_approval, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getHoliday(args: GetHolidayWithFilterReq, token: string) {
        return httpClient.post<ApiResponseWrapper<HolidayModel[]>>(this.PATH_PROFILE.get_holiday_user, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static getEmployeeHolidayByUser(userId: string, token: string) {
        return httpClient.get<ApiResponseWrapper<EmployeeHolidayUserModel>>(this.PATH_PROFILE.get_employee_holiday_by_user, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                userId: userId
            }
        }).then((res) => res.data);
    }
    static createHoliday(args: SendHolidayReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_PROFILE.holiday, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static updateHoliday(args: SendHolidayReq, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(this.PATH_PROFILE.holiday, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static deleteHoliday(arg: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(this.PATH_PROFILE.holiday, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: arg
            }
        }).then((res) => res.data);
    }
    static getOvertimeList(args: GetOvertimeReq, token: string) {
        return httpClient.get<ApiResponseWrapper<OvertimeModel[]>>(this.PATH_PROFILE.overtime, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getOvertimeApprovalList(args: GetOvertimeReq, token: string) {
        return httpClient.get<ApiResponseWrapper<OvertimeModel[]>>(this.PATH_PROFILE.get_overtime_approval, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getOvertime(args: GetOvertimeWithFilterReq, token: string) {
        return httpClient.post<ApiResponseWrapper<OvertimeModel[]>>(this.PATH_PROFILE.get_overtime_user, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static createOvertime(args: SendOvertimeReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_PROFILE.overtime, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static updateOvertime(args: SendOvertimeReq, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(this.PATH_PROFILE.overtime, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static deleteOvertime(arg: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(this.PATH_PROFILE.overtime, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: arg
            }
        }).then((res) => res.data);
    }
    static getNotificationUser(args: GetNotificationUserReq, token: string) {
        return httpClient.get<ApiResponseWrapper<NotificationModel[]>>(this.PATH_PROFILE.get_notification_user, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
}