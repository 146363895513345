import React from 'react';
import {useForm} from 'react-hook-form';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {useAppDispatch} from 'helpers/redux';
import {HolidayApprovalInput} from "../../models";
import BasicSelectInput from "../../../../components/SelectInput/BasicSelectInput";
import {approvalStatusOption} from "../../constants";
import {GlobalApprovalEnum} from "../../../../types";
import {HolidayModel} from "../../../profile/models";
import {changeHolidayStatus} from "../../approval.reducer";
import Text from "../../../../components/Text/Text";

const HolidayApprovalForm = ({
                         singleHoliday = {} as HolidayModel,
                         userId = '',
                     }: HolidayApprovalFormProps) => {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<HolidayApprovalInput>({
        defaultValues: {
            status: GlobalApprovalEnum.Approved.toString(),
        }
    });

    const onSubmit = (data: HolidayApprovalInput) => {
        let _input = {
            id: singleHoliday?.id ?? '',
            rejectionNotes: data.rejectionNotes,
            userId: userId,
            status: parseInt(data.status) as GlobalApprovalEnum,
        };
        dispatch(changeHolidayStatus([_input]));
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text
                    label="User"
                    value={singleHoliday?.name}
                />
                <Text
                    label="Notes"
                    value={singleHoliday?.notes}
                />
                <BasicSelectInput
                    label="Status"
                    message={errors.status?.message}
                    options={approvalStatusOption}
                    {...register('status')}
                />
                <InputText
                    {...register('rejectionNotes')}
                    label="Rejection Notes"
                    placeholder="Input rejection notes"
                    message={errors.rejectionNotes?.message}
                />
                <div className="pt-2 pb-2">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface HolidayApprovalFormProps {
    singleHoliday?: HolidayModel;
    userId?: string;
}

export default HolidayApprovalForm;
