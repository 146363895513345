import React, {useState} from 'react';
import SeparateTabs from "components/Tabs/SeparateTabs";
import {useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import AbsentPage from "./absent/containers/AbsentPage";
import MyProfilePage from "./myProfile/containers/MyProfilePage";

const ProfileMainPage = () => {
    //this id must match with job report dynamic nav id
    const tabs = [
        { id: 'my-profile', name: "Job Statistic", link: `#`, content: <MyProfilePage /> },
        { id: 'absent', name: "Job In", link: `#`, content: <AbsentPage /> },
    ];

    const dynamicNavId = useAppSelector((state: RootState) => state.sidebar.dynamicNavId ?? "");

    return (
        <div>
            <SeparateTabs tabs={tabs} openTab={dynamicNavId} />
        </div>
    );
};

export default ProfileMainPage;
