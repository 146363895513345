import {ComponentPropsWithRef, FC, forwardRef} from "react";

export type Option = {
    text: string;
    value: string;
    label?: string;
}

export type Options = {
    text: string;
    value: string;
    label: string;
}

type BasicSelectInputProps = {
    label?: string;
    message?: string;
    options?: Array<Option>;
};

type BasicSelectInputType = FC<BasicSelectInputProps & ComponentPropsWithRef<'select'>>;

const BasicSelectInput: BasicSelectInputType = forwardRef((props, ref) => {
    const {
        label = "",
        message = '',
        options = [],
        ...rest} = props;

    return <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
        {
            label && <label htmlFor={label} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{label}</label>
        }
        <select
            ref={ref}
            {...rest}
            id={label}
            className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-color focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
        >
            {
                options?.map((option) => (
                    <option key={`option-${option.value}`} value={option.value}>{option.text}</option>
                ))
            }
        </select>
        {message !== '' && (
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                {message}
            </p>
        )}
    </div>
});

export default BasicSelectInput;