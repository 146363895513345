import React from 'react';
import InputText from 'components/TextInput/InputText';
import InputPassword from 'components/TextInput/InputPassword';
import { useForm } from 'react-hook-form';
import { InputModel } from '../models';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema } from '../constants';
import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import { useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { isObjectEmpty } from 'helpers/checkEmptiness';
import { HttpClientError } from '../../../http-client';
import Button from 'components/Button/Button';

const LoginForm = ({ ...props }: LoginFormProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<InputModel>({
        resolver: yupResolver(schema),
    });

    const error = useAppSelector(
        (state: RootState) => state.user.error ?? ({} as HttpClientError)
    );
    const isLoading = useAppSelector(
        (state: RootState) => state.user.isLoading
    );

    const onSubmit = (data: InputModel) => props.onSubmit(data);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputText
                {...register('username')}
                label="Username"
                placeholder="Masukkan username"
                message={errors.username?.message}
                autoComplete="username"
            />

            <InputPassword
                {...register('password')}
                label="Password"
                message={errors.password?.message}
                placeholder="Masukkan password"
                autoComplete="current-password"
            />

            {!isObjectEmpty(error) && (
                <Alert color="failure" icon={HiInformationCircle}>
                    <span className="capitalize">{error.data?.message}</span>
                </Alert>
            )}

            <div className="pt-2 pb-2">
                <Button
                    color="success"
                    type="submit"
                    title="Login"
                    isLoading={isLoading}
                />
            </div>
        </form>
    );
};

export interface LoginFormProps {
    onSubmit: (formInput: InputModel) => void;
}

export default LoginForm;
