import {FC} from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type DatePickerStateTypeProps = {
    label?: string;
    name: string;
    placeholderText: string;
    showYearDropdown?: boolean;
};

type DatePickerStateType = FC<DatePickerStateTypeProps & ReactDatePickerProps>;

const DatePickerState: DatePickerStateType = (props) => {
    const { label = '', name = '', ...rest } = props;

    return (
        <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
            {label && (
                <label
                    htmlFor={label}
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                >
                    {label}
                </label>
            )}
            <DatePicker
                className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-color focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
                {...rest}
                id={label}
                showYearDropdown
                dropdownMode="select"
                portalId="root-portal"
            />
        </div>
    );
};

export default DatePickerState;