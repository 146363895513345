import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {CreatePosition, GetListRequest, Position, UpdatePosition} from "./models";

export default class PositionApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<Position[]>>(`${this.BASE_URL}${this.PATH_MASTER.position}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<Position[]>>(`${this.BASE_URL}${this.PATH_MASTER.position}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
                skip: 0,
                take: 10
            }
        }).then((res) => res.data);
    }

    static createPosition(args: CreatePosition, token: string) {
        return httpClient.post<ApiResponseWrapper<Position>>(`${this.BASE_URL}${this.PATH_MASTER.position}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updatePosition(args: UpdatePosition, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.position}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deletePosition(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.position}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
}