import {HttpClientError, RequestStatus} from "http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {
    AramisType,
    ChangeSupportingDocumentType,
    GetAramisListRequest,
    GetWorkingDrawingListRequest, GetWorkingDrawingNewRequest,
    WorkingDrawingType
} from "./models";
import SupportingDocumentAttachmentApi from "./supportingDocumentAttachment.api";

export interface SupportingDocumentAttachmentSlice {
    aramisList: AramisType[];
    workingDrawingList: WorkingDrawingType[];
    aramisRows?: number;
    workingDrawingRows?: number;
    rows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getAramisList = createAsyncThunk(
    'supporting-document/getAramisList',
    async (args: GetAramisListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await SupportingDocumentAttachmentApi.getAramisList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getWorkingDrawingList = createAsyncThunk(
    'supporting-document/getWorkingDrawingList',
    async (args: GetWorkingDrawingListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await SupportingDocumentAttachmentApi.getWorkingDrawingList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getWorkingDrawingWithFilter = createAsyncThunk(
    'supporting-document/getWorkingDrawingWithFilter',
    async (args: GetWorkingDrawingNewRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await SupportingDocumentAttachmentApi.getWorkingDrawingWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const editSupportingDocumentAttachment = createAsyncThunk(
    'supporting-document/editSupportingDocumentAttachment',
    async (args: Array<ChangeSupportingDocumentType>, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await SupportingDocumentAttachmentApi.editSupportingDocumentAttachment(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const supportingDocumentAttachmentSlice = createSlice({
    name: 'supportingDocumentState',
    initialState: {} as SupportingDocumentAttachmentSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAramisList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAramisList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.aramisList = action.payload.data;
            state.aramisRows = action.payload.rows;
        });
        builder.addCase(getAramisList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getWorkingDrawingList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getWorkingDrawingList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.workingDrawingList = action.payload.data;
            state.workingDrawingRows = action.payload.rows;
        });
        builder.addCase(getWorkingDrawingList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // update data reducer
        builder.addCase(editSupportingDocumentAttachment.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(editSupportingDocumentAttachment.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(editSupportingDocumentAttachment.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});

export const { reset } = supportingDocumentAttachmentSlice.actions;
export default supportingDocumentAttachmentSlice.reducer;