import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    InputWorkingDrawingFilter,
    WorkingDrawingType
} from '../models';
import '../styles.css';
import Button from 'components/Button/Button';
import RadioButton from 'components/RadioButton/RadioButton';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';
import {getWorkingDrawingWithFilter} from '../supportingDocumentAttachment.reducer';
import {SupportingDocumentEnum} from 'features/worksheet/process/job/models';
import {HttpClientError, RequestStatus} from 'http-client';
import {reset as resetAttachment, updateAttachment} from "../../../../basic/attachment/attachment.reducer";
import {toast, ToastContainer} from "react-toastify";
import {IoMdClose} from "react-icons/io";
import {Link} from "react-router-dom";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import Divider from "../../../../../components/Layout/Divider";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import InputText from "../../../../../components/TextInput/InputText";
import {useForm} from "react-hook-form";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {powerMapSortOption} from "../../mapReq/constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";

const WorkingDrawingTable = () => {
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);

    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        reset: resetForm
    } = useForm<InputWorkingDrawingFilter>({defaultValues: {startDate: undefined, endDate: undefined}});

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const [isReFetch, setIsReFetch] = useState<boolean>(false);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const statusAttachment = useAppSelector(
        (state: RootState) => state.attachment.status
    );

    useEffect(() => {
        let localStart = startDate !== undefined ? new Date(startDate).toISOString() : undefined;
        let localEnd = endDate !== undefined ? new Date(endDate).toISOString() : undefined;
        if (isReFetch && statusAttachment !== undefined && statusAttachment !== RequestStatus.pending) {
            setIsReFetch(false);
            refreshCache(undefined);
            gridRef.current.api.setFilterModel({
                filter: {
                    filterType: "text",
                    type: "contains",
                    filter: getValues("filter")
                },
                dateStartFilter: {
                    filterType: "text",
                    type: "contains",
                    filter: localStart,
                },
                dateEndFilter: {
                    filterType: "text",
                    type: "contains",
                    filter: localEnd
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusAttachment, isReFetch]);

    const displayToast = () => {
        toast(<ShowAttachmentToast/>);
    };

    const CloseButton = ({closeToast}: any) => (
        <IoMdClose
            size={20}
            onClick={() => {
                dispatch(resetAttachment());
                closeToast();
            }}
        />
    );

    const onSupportingDocumentChange = (
        data: WorkingDrawingType,
        type: SupportingDocumentEnum
    ) => {
        if (!checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetSupportingDocument, ActionEnum.ActionAssign)) {
            return null;
        }

        setIsReFetch(true);
        displayToast();
        dispatch(
            updateAttachment({
                id: data.id,
                job_id: data.job_id,
                type_id: data.type_id,
                value: type,
                option: data.option,
                employee_id: data.employee_id,
                date_request: data.date_request,
                link: data.link,
                note: data.note,
            })
        );
    };

    const ShowAttachmentToast = () => {
        const isAttachmentLoading = useAppSelector(
            (state: RootState) => state.attachment.isLoading
        );
        const statusAttachment = useAppSelector(
            (state: RootState) => state.attachment.status
        );
        const errorAttachment = useAppSelector(
            (state: RootState) =>
                state.attachment.error ?? ({} as HttpClientError)
        );
        return (
            <div>
                {isAttachmentLoading &&
                    statusAttachment === RequestStatus.pending && (
                        <small>Saving Supporting Document...</small>
                    )}
                {!isAttachmentLoading &&
                    statusAttachment === RequestStatus.success && (
                        <small>Supporting Document Saved Successfully...</small>
                    )}
                {!isAttachmentLoading &&
                    statusAttachment === RequestStatus.failed && (
                        <small>
                            Failed when saving supporting document:{' '}
                            {errorAttachment.message}
                        </small>
                    )}
            </div>
        );
    };

    const onSubmit = (data: InputWorkingDrawingFilter) => {
        let localStart = data.startDate !== undefined ? new Date(data.startDate).toISOString() : undefined;
        let localEnd = data.endDate !== undefined ? new Date(data.endDate).toISOString() : undefined;
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            filter: {
                filterType: "text",
                type: "contains",
                filter: data.filter
            },
            dateStartFilter: {
                filterType: "text",
                type: "contains",
                filter: localStart,
            },
            dateEndFilter: {
                filterType: "text",
                type: "contains",
                filter: localEnd
            },
        });
    }

    const onResetClicked = () => {
        resetForm();
        refreshCache(undefined);
        gridRef.current.api.setFilterModel(null);
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-detail/${props.data?.job_id}`}
            className="underline text-blue-700"
        >
            {props.data?.job_number}
        </Link>
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex flex-col items-center h-[100%]">
                <div className="text-right">
                    <RadioButton
                        name={`working-drawing-${props.data?.id}`}
                        label="AVAILABLE"
                        id={`working-drawing-${props.data?.id}-available`}
                        checked={props.data?.value === SupportingDocumentEnum.AVAILABLE}
                        onChange={() =>
                            onSupportingDocumentChange(
                                props.data,
                                SupportingDocumentEnum.AVAILABLE,
                            )
                        }
                    />
                    <RadioButton
                        name={`working-drawing-${props.data?.id}`}
                        label="NOT AVAILABLE"
                        id={`working-drawing-${props.data?.id}-not-available`}
                        checked={props.data?.value === SupportingDocumentEnum.NOT_AVAILABLE}
                        onChange={() =>
                            onSupportingDocumentChange(
                                props.data,
                                SupportingDocumentEnum.NOT_AVAILABLE,
                            )
                        }
                    />
                    <RadioButton
                        name={`working-drawing-${props.data?.id}`}
                        label="NOT REQUIRED"
                        id={`working-drawing-${props.data?.id}-not-required`}
                        checked={props.data?.value === SupportingDocumentEnum.NOT_REQUIRED}
                        onChange={() =>
                            onSupportingDocumentChange(
                                props.data,
                                SupportingDocumentEnum.NOT_REQUIRED,
                            )
                        }
                    />
                </div>
            </div>
        );
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
            sortable: false
        },
        {
            field: 'job_sales_order',
            headerName: 'Sales Order',
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Job Number',
            field: 'job_number',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'project', headerName: 'Project', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'department_owner_name',
            headerName: 'Department Owner',
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'teamName', headerName: 'Team', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'koj', headerName: 'KoJ', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'date_request',
            headerName: 'Request Date',
            cellDataType: 'date',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'job_status', headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'type_name', headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'district', headerName: 'District', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'po_number', headerName: 'PO Number', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'due_date',
            headerName: 'Due Date',
            cellDataType: 'date',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'total_poles', headerName: 'Req Pole', sortable: true, filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'ts_modified',
            headerName: 'Last Checked',
            cellDataType: 'date',
            sortable: true,
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Filter',
            field: 'filter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date Start Filter',
            field: 'dateStartFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Date End Filter',
            field: 'dateEndFilter',
            hide: true,
            filter: 'agTextColumnFilter',
            sortable: false
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }),
            sortable: false
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(powerMapSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.date_request || {};
                const {dateFrom: startDueDate, dateTo: endDueDate} = params.request.filterModel?.due_date || {};
                const {
                    dateFrom: startLastCheckedDate,
                    dateTo: endLastCheckedDate
                } = params.request.filterModel?.ts_modified || {};

                dispatch(
                    getWorkingDrawingWithFilter({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        startRequestDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endRequestDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        sales_order: params.request.filterModel?.request_by?.filter,
                        number_job: params.request.filterModel?.job_number?.filter,
                        project: params.request.filterModel?.project?.filter,
                        department_owner: params.request.filterModel?.department_owner_name?.filter,
                        team: params.request.filterModel?.teamName?.filter,
                        koj: params.request.filterModel?.koj?.filter,
                        status: params.request.filterModel?.job_status?.filter,
                        type: params.request.filterModel?.type_name?.filter,
                        district: params.request.filterModel?.district?.filter,
                        po_number: params.request.filterModel?.po_number?.filter,
                        startDueDate: startDueDate !== undefined ? dateSetTimeToNow(startDueDate) : undefined,
                        endDueDate: endDueDate !== undefined ? dateSetTimeToNow(endDueDate) : undefined,
                        startLastChecked: startLastCheckedDate !== undefined ? dateSetTimeToNow(startLastCheckedDate) : undefined,
                        endLastChecked: endLastCheckedDate !== undefined ? dateSetTimeToNow(endLastCheckedDate) : undefined,
                        req_pole: params.request.filterModel?.total_poles?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid supporting-document mt-5">
                <div className="flex">
                    <div className="flex flex-grow self-center">
                        <h1 className="dark:text-gray-300">Working Drawing</h1>
                    </div>
                </div>

                {/*<div>*/}
                {/*    <form onSubmit={handleSubmit(onSubmit)}>*/}
                {/*        <Divider>*/}
                {/*            <Divider.LeftContent>*/}
                {/*                <div>*/}
                {/*                    <RangeDatePicker label={"Filter by Date"} startName='startDate' endName='endDate'*/}
                {/*                                     startValue={new Date(startDate)} endValue={new Date(endDate)}*/}
                {/*                                     control={control}/>*/}
                {/*                </div>*/}
                {/*            </Divider.LeftContent>*/}
                {/*            <Divider.RightContent>*/}
                {/*                <div>*/}
                {/*                    <InputText*/}
                {/*                        {...register('filter')}*/}
                {/*                        placeholder="Search by id number | job number | project | district | status"*/}
                {/*                        label="Filter by ID Number | Job Number | Project | District | Status"*/}
                {/*                    />*/}
                {/*                    <div className="flex justify-end">*/}
                {/*                        <div className={"mx-2"}>*/}
                {/*                            <Button*/}
                {/*                                color="success"*/}
                {/*                                type="button"*/}
                {/*                                title="Reset"*/}
                {/*                                onClick={onResetClicked}*/}
                {/*                            />*/}
                {/*                        </div>*/}
                {/*                        <Button*/}
                {/*                            color="success"*/}
                {/*                            type="submit"*/}
                {/*                            title="Filter"*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Divider.RightContent>*/}
                {/*        </Divider>*/}
                {/*    </form>*/}
                {/*</div>*/}

                <div className="main-data-grid fielding mt-5">
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                            getServerSideDataSource={getServerSideDataSource} supportSaveTableState
                                            tableStateName={'job-data-working-drawing'} defaultPageSize={20}/>
                </div>

                <ToastContainer
                    autoClose={5000}
                    draggable={false}
                    closeButton={CloseButton}
                />
            </div>
        </div>
    );
};

export default WorkingDrawingTable;
