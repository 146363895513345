import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {useAppDispatch} from 'helpers/redux';
import {OvertimeApprovalInput} from "../../models";
import BasicSelectInput from "../../../../components/SelectInput/BasicSelectInput";
import {approvalStatusOption} from "../../constants";
import {GlobalApprovalEnum} from "../../../../types";
import {OvertimeModel} from "../../../profile/models";
import {changeOvertimeStatus} from "../../approval.reducer";
import {isObjectEmpty} from "../../../../helpers/checkEmptiness";
import {multiplierOptions} from "../../../profile/constants";
import Text from "../../../../components/Text/Text";
import OvertimeNotesDetail from "../../../profile/absent/components/OvertimeNotesDetail";

const OvertimeApprovalForm = ({
                         singleOvertime = {} as OvertimeModel,
                         userId = '',
                     }: OvertimeApprovalFormProps) => {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm<OvertimeApprovalInput>({
        defaultValues: {
            status: GlobalApprovalEnum.Approved.toString(),
        }
    });

    useEffect(() => {
        if (!isObjectEmpty(singleOvertime)) {
            let {reqMinute} = singleOvertime;
            reset({
                status: GlobalApprovalEnum.Approved.toString(),
                approveMinute: reqMinute.toString(),
                rejectionNote: '',
                multiplier: singleOvertime?.multiplier?.toString() ?? '1',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleOvertime]);

    const onSubmit = (data: OvertimeApprovalInput) => {
        let _input = {
            id: singleOvertime?.id ?? '',
            approveMinute: parseInt(data.approveMinute),
            multiplier: parseFloat(data.multiplier),
            rejectionNote: data.rejectionNote,
            userId: userId,
            status: parseInt(data.status) as GlobalApprovalEnum,
        };
        dispatch(changeOvertimeStatus(_input));
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text
                    label="User"
                    value={singleOvertime?.name ?? "-"}
                />
                <BasicSelectInput
                    label="Status"
                    message={errors.status?.message}
                    options={approvalStatusOption}
                    {...register('status')}
                />
                <InputText
                    {...register('approveMinute')}
                    label="Approve Minute"
                    placeholder="Input approve minute"
                    type="number"
                    message={errors.approveMinute?.message}
                />
                <BasicSelectInput
                    label="Multiplier"
                    message={errors.multiplier?.message}
                    options={multiplierOptions}
                    {...register('multiplier')}
                />
                <InputText
                    {...register('rejectionNote')}
                    label="Rejection Notes"
                    placeholder="Input rejection notes"
                    message={errors.rejectionNote?.message}
                />
                <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Notes</label>
                    <OvertimeNotesDetail data={singleOvertime}/>
                </div>
                <div className="pt-2 pb-2">
                <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface OvertimeApprovalFormProps {
    singleOvertime?: OvertimeModel;
    userId?: string;
}

export default OvertimeApprovalForm;
