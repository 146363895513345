import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {Link} from "react-router-dom";
import {DETAIL_TYPE_JOB_INPUT} from "../../job/constants";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {RequestStatus} from "../../../../../http-client";
import {getMainJobPrepWithFilter, reset} from "../processJobPrep.reducer";
import {UserCredential} from "../../../../login/models";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {mainJobPrepSortOption} from "../constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";

const MainJobTable = () => {
    const gridRef = useRef<any>(null);
    const dispatch = useAppDispatch();

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const statusPole = useAppSelector((state: RootState) => state.pole.status);
    const status = useAppSelector((state: RootState) => state.processJobPrep.status);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            if (status === RequestStatus.success) {
                refreshCache(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, user]);

    useEffect(() => {
        if (statusPole === RequestStatus.success) {
            refreshCache(undefined);
            dispatch(reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusPole]);

    // ag-grid props
    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-prep/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            maxWidth: 50,
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {
            field: 'project_name', headerName: 'Project',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'department_name', headerName: 'Department',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }

        },
        {field: 'department_owner_name', headerName: 'Department Owner', filter: false, sortable: false },
        {field: 'district_name', headerName: 'District', filter: false, sortable: false},
        {field: 'type_name', headerName: 'Type', filter: false, sortable: false},
        {
            field: 'kind_name', headerName: 'KoJ',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'ts_created',
            headerName: 'Request Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'number_id', headerName: 'ID Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Job Number',
            field: 'number_job',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'status_name', headerName: 'Status',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'date_due',
            headerName: 'Due Date',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'date_assigned_prep',
            headerName: 'Date Assigned',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'pole_total', headerName: 'Req Pole',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(mainJobPrepSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {dateFrom: startDueDate, dateTo: endDueDate} = params.request.filterModel?.date_due || {};
                const {
                    dateFrom: startDateAssign,
                    dateTo: endDateAssign
                } = params.request.filterModel?.date_assigned_prep || {};

                dispatch(getMainJobPrepWithFilter(
                    {
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        employee_id: user.user_id,
                        project_name: params.request.filterModel?.project_name?.filter,
                        department_name: params.request.filterModel?.department_name?.filter,
                        kind_job: params.request.filterModel?.kind_name?.filter,
                        startRequestDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endRequestDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        number_id: params.request.filterModel?.number_id?.filter,
                        number_job: params.request.filterModel?.number_job?.filter,
                        status: params.request.filterModel?.status_name?.filter,
                        startDueDate: startDueDate !== undefined ? dateSetTimeToNow(startDueDate) : undefined,
                        endDueDate: endDueDate !== undefined ? dateSetTimeToNow(endDueDate) : undefined,
                        startDateAssign: startDateAssign !== undefined ? dateSetTimeToNow(startDateAssign) : undefined,
                        endDateAssign: endDateAssign !== undefined ? dateSetTimeToNow(endDateAssign) : undefined,
                        req_pole: params.request.filterModel?.pole_total?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    }
                )).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            }
        }
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid mt-10">
                <div className='flex'>
                    <div className='flex flex-grow self-center'>
                        <h1 className="dark:text-gray-300 font-bold my-3">Main Job</h1>
                    </div>
                </div>
                <div>
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                            getServerSideDataSource={getServerSideDataSource}
                                            supportSaveTableState
                                            tableStateName={'worksheet-main-job-prep'}
                                            defaultPageSize={20}
                    />
                </div>
            </div>
        </div>
    );
};

export default MainJobTable;
