import {HttpClientError, RequestStatus} from 'http-client';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import JobCheckApi from './jobCheck.api';
import {GetHoldRequest, GetListRequest, JobCancel, JobCheck, JobHold, JobHoldList, PendingRequest} from './models';

export interface JobCheckSlice {
    list?: JobCheck[];
    single?: JobCheck;
    holdList?: JobHold[];
    cancelList?: JobCancel[];
    rows?: number;
    holdRows?: number;
    cancelRows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    actionStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getList = createAsyncThunk(
    'job/check/getList',
    async (args: GetListRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobCheckApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingle = createAsyncThunk(
    'job/check/getSingle',
    async (id: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobCheckApi.getSingle(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const pendingJob = createAsyncThunk(
    'job/check/pending',
    async (args: PendingRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobCheckApi.pendingJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getHoldList = createAsyncThunk(
    'job/hold/getHoldList',
    async (args: GetHoldRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobCheckApi.getHoldList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getCancelList = createAsyncThunk(
    'job/cancel/getCancelList',
    async (args: GetHoldRequest, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await JobCheckApi.getCancelList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const jobCheckSlice = createSlice({
    name: 'jobCheckState',
    initialState: {} as JobCheckSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.actionStatus = RequestStatus.pending;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getList.pending, (state) => {
                state.isLoading = true;
                state.status = RequestStatus.pending;
            })
            .addCase(getList.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.status = RequestStatus.success;
                state.list = payload.data;
                state.rows = payload.rows;
            })
            .addCase(getList.rejected, (state) => {
                state.isLoading = false;
                state.status = RequestStatus.failed;
            })
            .addCase(getSingle.pending, (state) => {
                state.isLoading = true;
                state.status = RequestStatus.pending;
            })
            .addCase(getSingle.fulfilled, (state, action) => {
                state.isLoading = false;
                state.status = RequestStatus.success;
                state.single = action.payload.data;
            })
            .addCase(getSingle.rejected, (state) => {
                state.isLoading = false;
                state.status = RequestStatus.failed;
            })
            // change status reducer
            .addCase(pendingJob.pending, (state) => {
                state.actionStatus = RequestStatus.pending;
                state.error = {} as HttpClientError;
                state.isLoading = true;
            })
            .addCase(pendingJob.fulfilled, (state) => {
                state.actionStatus = RequestStatus.success;
                state.isLoading = false;
            })
            .addCase(pendingJob.rejected, (state, {payload}) => {
                state.actionStatus = RequestStatus.failed;
                state.error = payload as HttpClientError;
                state.isLoading = false;
            })
            .addCase(getHoldList.pending, (state) => {
                state.isLoading = true;
                state.status = RequestStatus.pending;
            })
            .addCase(getHoldList.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.status = RequestStatus.success;
                state.holdList = payload.data;
                state.holdRows = payload.rows;
            })
            .addCase(getHoldList.rejected, (state) => {
                state.isLoading = false;
                state.status = RequestStatus.failed;
            })
            .addCase(getCancelList.pending, (state) => {
                state.isLoading = true;
                state.status = RequestStatus.pending;
            })
            .addCase(getCancelList.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.status = RequestStatus.success;
                state.cancelList = payload.data;
                state.cancelRows = payload.rows;
            })
            .addCase(getCancelList.rejected, (state) => {
                state.isLoading = false;
                state.status = RequestStatus.failed;
            })
    },
});

export const {reset} = jobCheckSlice.actions;
export default jobCheckSlice.reducer;