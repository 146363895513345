import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {deletedWarnOption, savedStatusOption} from 'helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {HttpClientError, RequestStatus} from 'http-client';
import {RootState} from 'app/store';
import {BiEditAlt} from 'react-icons/bi';
import {MdOutlineDeleteForever} from 'react-icons/md';
import {AttendanceModel} from '../../models';
import {UserCredential} from '../../../login/models';
import {
    deleteAttendance,
    getAttendance,
    reset,
} from '../../profile.reducer';
import {GlobalApprovalEnum} from '../../../../types';
import {LAST_ATTENDANCE} from '../../../../constants';
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import AttendanceForm from "./AttendanceForm";
import InputCheckbox from "../../../../components/Checkbox/InputCheckbox";
import {Button as FlowbiteButton} from "flowbite-react";
import {IoMdAdd} from "react-icons/io";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {
    approvalAttendanceStatusFilterOptions,
    getSortModel,
    getSortType
} from "../../../approval/constants";
import {userAttendanceSortOption} from "../../constants";
import {dateSetTimeToNow} from "../../../../helpers/convert";

const AddStaffReportTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector((state: RootState) => state.profile.attendanceStatus);
    const error = useAppSelector(
        (state: RootState) => state.profile.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [singleAttendance, setSingleAttendance] = useState<AttendanceModel>({} as AttendanceModel);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (status === RequestStatus.success) {
                    localStorage.setItem(LAST_ATTENDANCE, new Date().toISOString());
                    closeModal();
                }
                refreshCache(undefined);
                gridRef.current.api.setFilterModel(null);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAttendance(id));
            }
        });
    };

    const openModal = (attendance: AttendanceModel, isEdit: boolean) => {
        setSingleAttendance(attendance);
        setIsEdit(isEdit);
        setShowModal(true);
    };

    const closeModal = () => {
        setSingleAttendance({} as AttendanceModel);
        setShowModal(false);
    };

    const IsDeductedCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <InputCheckbox
                    id="isDeducted"
                    checked={props.data?.isDeducted}
                    readOnly
                />
            </div>
        )
    }

    const IsRefNoteCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <InputCheckbox
                    id="isDeducted"
                    checked={props.data?.isRefNote}
                    readOnly
                />
            </div>
        )
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    props.data?.status === GlobalApprovalEnum.Requested &&
                    <>
                        <button onClick={() => openModal(props.data, true)}>
                            <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                        </button>
                        <button
                            onClick={() =>
                                onDeleteClicked(props.data?.id)
                            }
                        >
                            <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                        </button>
                    </>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'ts_created', headerName: 'Date Created', cellDataType: 'date_time',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {field: 'absentDate', headerName: 'Absent Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {field: 'reqMinute', headerName: 'Req Minutes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'leaveDuration', headerName: 'Leave Duration',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'notes', headerName: 'Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'statusName', headerName: 'Status',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: approvalAttendanceStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {field: 'approveMinute', headerName: 'Approve Minutes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'rejectionNotes', headerName: 'Rejection Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'isDeducted', headerName: 'Deducted', cellRenderer: IsDeductedCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            filter: false,
            sortable: false,
        },
        {field: 'isRefNote', headerName: 'Ref Notes', cellRenderer: IsRefNoteCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            filter: false,
            sortable: false,
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            filter: false,
            sortable: false,
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(userAttendanceSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {
                    dateFrom: startAbsentDate,
                    dateTo: endAbsentDate
                } = params.request.filterModel?.absentDate || {};
                dispatch(
                    getAttendance({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        userId: user.user_id,
                        startDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        startAbsentDate: startAbsentDate !== undefined ? dateSetTimeToNow(startAbsentDate) : undefined,
                        endAbsentDate: endAbsentDate !== undefined ? dateSetTimeToNow(endAbsentDate) : undefined,
                        req_minute: params.request.filterModel?.reqMinute?.filter,
                        leave_duration: params.request.filterModel?.leaveDuration?.filter,
                        note: params.request.filterModel?.notes?.filter,
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        approve_minute: params.request.filterModel?.approveMinute?.filter,
                        rejection_note: params.request.filterModel?.rejectionNotes?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <div className="flex justify-items-center">
                        <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                            Attendance
                        </h1>
                        {/*<div className="flex">*/}
                        {/*    <div className="mr-2">*/}
                        {/*        <Button*/}
                        {/*            title="Add Attendance"*/}
                        {/*            onClick={() => openModal({} as AttendanceModel, false)}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="mt-3">
                        <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'profile-attendance'} isCustomHeader={true} customChildHeaderComponent={
                                                    <>
                                                        <FlowbiteButton
                                                            onClick={() => openModal({} as AttendanceModel, false)}
                                                            size="xs"
                                                            color="success"
                                                        >
                                                            <IoMdAdd className="mr-2 h-5 w-5"/>
                                                            Add Attendance
                                                        </FlowbiteButton>
                                                    </>
                                                }
                                                defaultPageSize={20}
                                                defaultSortModel={{
                                                    state: [
                                                        {
                                                            colId: "absentDate",
                                                            sort: "desc"
                                                        }
                                                    ]
                                                }}
                        />
                        <DefaultModal
                            title={`${isEdit ? "Edit" : "Add" } Attendance`}
                            show={showModal}
                            onClose={closeModal}
                            body={
                                <AttendanceForm singleAttendance={singleAttendance} userId={user.user_id} isEdit={isEdit} />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddStaffReportTable;
