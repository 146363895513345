import {PoleGetByJobColumnSort} from "../../../basic/pole/models";

// {
//     headerName: '#',
//         field: 'index',
//     suppressSizeToFit: true,
//     checkboxSelection: true,
//     showDisabledCheckboxes: true,
// },
// {
//     field: 'seq', headerName: 'Pole Seq',
//     filter: 'agTextColumnFilter',
//     filterParams: {
//     filterOptions: ['contains'],
//         maxNumConditions: 1,
// }
// },
// {
//     field: 'no', headerName: 'Pole No',
//     filter: 'agTextColumnFilter',
//     filterParams: {
//     filterOptions: ['contains'],
//         maxNumConditions: 1,
// }
// },
// {
//     field: 'employee_calcspec_name', headerName: 'CS',
//     filter: 'agTextColumnFilter',
//     filterParams: {
//     filterOptions: ['contains'],
//         maxNumConditions: 1,
// }
// },
// {
//     field: 'employee_qc_name', headerName: 'QC',
//     filter: 'agTextColumnFilter',
//     filterParams: {
//     filterOptions: ['contains'],
//         maxNumConditions: 1,
// }
// },
// {
//     field: 'status_name', headerName: 'Status',
//     filter: 'agTextColumnFilter',
//     filterParams: {
//     filterOptions: ['contains'],
//         maxNumConditions: 1,
// }
// },
export const poleOCalcSortOption = [
    {
        label: "seq",
        text: "seq",
        value: PoleGetByJobColumnSort.pole_seq.toString()
    },
    {
        label: "no",
        text: "no",
        value: PoleGetByJobColumnSort.pole_no.toString()
    },
    {
        label: "employee_calcspec_name",
        text: "employee_calcspec_name",
        value: PoleGetByJobColumnSort.cs.toString()
    },
    {
        label: "employee_qc_name",
        text: "employee_qc_name",
        value: PoleGetByJobColumnSort.qc.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: PoleGetByJobColumnSort.status.toString()
    },
    {
        label: "index",
        text: "index",
        value: PoleGetByJobColumnSort.index.toString()
    },
]