import BillJobPage from "../features/billJob/billJob/containers/BillJobPage";

export enum FeatureEnum {
    All,
    Dashboard,
    Master,
    Worksheet,
    WorksheetProcess,
    WorksheetJobData,
    Report,
    Approval,
    Profile,
    BillJob
}

export enum MenuEnum {
    All,
    Dashboard,
    // Master,
    Position,
    Team,
    Employee,
    JobType,
    JobCategory,
    JobSubjectNotes,
    JobStatus,
    JobKind,
    JobSource,
    PoleType,
    PoleStatus,
    Project,
    Department,
    DepartmenOwner,
    District,
    EmailTitle,
    SupportingDocument,
    Holiday,
    // Worksheet
    WorksheetJob,
    WorksheetAllJob,
    WorksheetCheckJob,
    WorksheetHoldJob,
    WorksheetCancelJob,
    WorksheetFielding,
    WorksheetSupportingDocument,
    WorksheetMapReq,
    WorksheetJobPrep,
    WorksheetCalcSpec,
    WorksheetRequestDocument,
    WorksheetMRE,
    WorksheetQCMRE,
    WorksheetQC,
    WorksheetKatapult,
    WorksheetJobPanel,
    // Report
    History,
    StaffReport,
    JobReport,
    Attendance,
    SupportingDocumentReport,
    //Approval
    EmployeeWorkApproval,
    OvertimeApproval,
    AttendanceApproval,
    HolidayApproval,
    //Profile
    Profile,
    //BillJob
    BillJob,
    ReportBilling,
    BillingStatistic,
    GDev
}

export enum ActionEnum {
    All,
    Import,
    Export,
    Input,
    Edit,
    Delete,
    ActionCheck,
    ActionWaiting,
    ActionHold,
    ActionCancel,
    ActionAssign,
    ActionLastCheck,
    ActionActivate,
    ActionInfo,
    ActionEmail,
    ChangePoleStatus,
    PinMapPole,
    Attachment,
    SupportingDocument,
    ReadyToAssign,
    ReadyToQC,
    CompleteJob,
    ReadyToQCMRE,
    CompleteMRE,
    CompleteKatapult,
    ChangeCompleteDate,
    ChangeToRequest
}


export type MenuAccessModel = {
    menuName: MenuEnum,
    actionAccess: Array<ActionEnum>
}

export type RoleAccessModel = {
    roleName: "Superadmin" | "Admin" | "Administrator" | "HRD" | "Head" | "SPV" | "QC" | "CSQ" | "Job Prep" | "Data Center" | "MRE" | "QC MRE" | "Katapult" | "Freelance" ;
    menuAccess: Array<MenuAccessModel>;
    featureAccess: Array<FeatureEnum>;
}