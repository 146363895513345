import React, {useCallback, useEffect, useState} from 'react';
import MonthYearPickerState from "../../../../components/DatePicker/MonthYearPickerState";
import BasicSelectInput, {Option} from "../../../../components/SelectInput/BasicSelectInput";
import Button from "../../../../components/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../../helpers/redux";
import {RootState} from "../../../../app/store";
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";
import {PiMicrosoftExcelLogoDuotone} from "react-icons/pi";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../approval/constants";
import {gDevSortOptions} from "../../constants";
import {getWeekendDate} from "../../billJob/billJob.reducer";
import {dateSetTimeToNow} from "../../../../helpers/convert";
import {approveGDev, exportGDev, getGDevData, reset} from "../gdev.reducer";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import {BiEditAlt} from "react-icons/bi";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import GDevForm from "./GDevForm";
import {GDevDataModel} from "../../models";
import {MdOutlineCheckCircleOutline} from "react-icons/md";
import {customWarnOption, savedStatusOption} from "../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal, {SweetAlertIcon} from "sweetalert2";
import {darkGreen} from "../../../../constants";
import './style.css';
import {HttpClientError, RequestStatus} from "../../../../http-client";
import {Link} from "react-router-dom";

const GDevTable = () => {
    const MySwal = withReactContent(Swal);

    const dispatch = useAppDispatch();
    const gridRef = React.useRef<any>();

    const weekendDateOptions = useAppSelector(
        (state: RootState) => state.billJob.weekendDateOptions ?? []
    );
    const isExportLoading = useAppSelector(
        (state: RootState) => state.gdev.isExportLoading
    );
    const billedStatus = useAppSelector(
        (state: RootState) => state.gdev.billedStatus
    );
    const approvedStatus = useAppSelector(
        (state: RootState) => state.gdev.approvedStatus
    );
    const error = useAppSelector((state: RootState) => state.gdev.error ?? ({} as HttpClientError));

    const [showModal, setShowModal] = useState<boolean>(false);
    const [monthSelected, setMonthSelected] = useState<Date>(new Date());
    const [weekendSelected, setWeekendSelected] = useState<string>("all");
    const [billedSelected, setBilledSelected] = useState<string>("all");
    const [selectedGDev, setSelectedGDev] = useState<GDevDataModel>({} as GDevDataModel);

    useEffect(() => {
        if (monthSelected !== undefined) {
            dispatch(getWeekendDate({
                month: monthSelected.getMonth() + 1,
                year: monthSelected.getFullYear()
            }))
        }

    }, [monthSelected]);

    useEffect(() => {
        if (billedStatus !== undefined && billedStatus !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(billedStatus === RequestStatus.success, error.data?.message)).then(() => {
                if (billedStatus === RequestStatus.success) {
                    refreshCache(undefined);
                    gridRef.current.api.setFilterModel({
                        monthYearFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: monthSelected.toISOString()
                        },
                        weekendDateFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: weekendSelected
                        },
                        statusFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: billedSelected
                        }
                    });
                    setShowModal(false);
                }
                dispatch(reset());
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billedStatus, error]);

    useEffect(() => {
        if (approvedStatus !== undefined && approvedStatus !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(approvedStatus === RequestStatus.success, error.data?.message)).then(() => {
                if (approvedStatus === RequestStatus.success) {
                    refreshCache(undefined);
                    gridRef.current.api.setFilterModel({
                        monthYearFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: monthSelected.toISOString()
                        },
                        weekendDateFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: weekendSelected
                        },
                        statusFilter: {
                            filterType: "text",
                            type: "contains",
                            filter: billedSelected
                        }
                    });
                    setShowModal(false);
                }
                dispatch(reset());
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedStatus, error]);

    const onSearchClicked = () => {
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            monthYearFilter: {
                filterType: "text",
                type: "contains",
                filter: monthSelected.toISOString()
            },
            weekendDateFilter: {
                filterType: "text",
                type: "contains",
                filter: weekendSelected
            },
            statusFilter: {
                filterType: "text",
                type: "contains",
                filter: billedSelected
            }
        })
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    const handleApproveClick = (data?: GDevDataModel) => {
        if (data?.sT_date === null || data?.sT_date === undefined || data?.gdev_approve_pole === null || data?.gdev_approve_pole === undefined) {
            MySwal.fire(
                {
                    title: "",
                    text: "Please fill ST Date and Pole Approved First",
                    icon: 'warning' as SweetAlertIcon,
                    showCancelButton: false,
                    cancelButtonColor: darkGreen,
                    confirmButtonText: 'OK',
                }
            )

            return null;
        }
        MySwal.fire(
            customWarnOption(
                'Billed Selected Job?',
                'Are you sure to change selected job to billed?',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(approveGDev({
                    job_id: data.id,
                    isBilled: true
                }));
            }
        });
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <button onClick={() => openModal(props.data)}>
                    <BiEditAlt className="mr-2 text-2xl text-teal-600"/>
                </button>
                <button
                    onClick={() => handleApproveClick(props.data)}
                >
                    <MdOutlineCheckCircleOutline
                        className="mr-2 text-2xl text-teal-600"
                    />
                </button>
            </div>
        )
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={props.data?.koj === "MRE" ? `/worksheet/mre/mre-detail/${props.data?.id}`
                : props.data?.koj === "Katapult" ? `/worksheet/katapult/katapult-detail/${props.data?.id}`
                    : `/worksheet/ocalc/job-detail/${props.data?.id}`}
            className="underline text-blue-700"
            target="_blank"
        >
            {props.data?.number_job}
        </Link>
    );

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {
            field: 'project', headerName: 'Project',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'koj', headerName: 'KoJ',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'complete_date', headerName: 'Complete Date', cellDataType: 'date', filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'number_id', headerName: 'ID Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'number_job', headerName: 'Job Number',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'other_number', headerName: 'Other Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'type', headerName: 'Type',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'sales_order', headerName: 'Sales Order',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'district', headerName: 'District',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'sT_date', headerName: 'ST Date', cellDataType: 'date_time', filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'mic_date', headerName: 'MIC Date', cellDataType: 'date_time', filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'user_gdev', headerName: 'User',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains', 'blank'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'gdev_approve_pole', headerName: 'Pole Approved',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains', 'blank'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'total_complete_pole', headerName: 'Pole Complete',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'total_pole', headerName: 'Total Pole',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Month Year Filter',
            field: 'monthYearFilter',
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Weekend Date Filter',
            field: 'weekendDateFilter',
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Status Filter',
            field: 'statusFilter',
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(gDevSortOptions, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {
                    dateFrom: startCompleteDate,
                    dateTo: endCompleteDate
                } = params.request.filterModel?.complete_date || {};
                const {dateFrom: startMICDate, dateTo: endMICDate} = params.request.filterModel?.mic_date || {};
                const {
                    dateFrom: startSTDate,
                    dateTo: endSTDate
                } = params.request.filterModel?.sT_date || {};

                const _monthYearSelected = params.request.filterModel?.monthYearFilter?.filter !== undefined ? new Date(params.request.filterModel?.monthYearFilter?.filter) : new Date();

                dispatch(getGDevData({
                    skip: params.request.startRow,
                    take: params.api.paginationGetPageSize(),
                    weekendDate: params.request.filterModel?.weekendDateFilter?.filter === 'all' ? undefined : params.request.filterModel?.weekendDateFilter?.filter,
                    month: params.request.filterModel?.weekendDateFilter?.filter === 'all' ? _monthYearSelected.getMonth() + 1 : undefined,
                    year: params.request.filterModel?.weekendDateFilter?.filter === 'all' ? _monthYearSelected.getFullYear() : undefined,
                    column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                    sort: sortModel !== undefined ? sortType : undefined,
                    project_name: params.request.filterModel?.project?.filter,
                    kind_job: params.request.filterModel?.koj?.filter,
                    startCompleteDate: startCompleteDate !== undefined ? dateSetTimeToNow(startCompleteDate) : undefined,
                    endCompleteDate: endCompleteDate !== undefined ? dateSetTimeToNow(endCompleteDate) : undefined,
                    startMICDate: startMICDate !== undefined ? dateSetTimeToNow(startMICDate) : undefined,
                    endMICDate: endMICDate !== undefined ? dateSetTimeToNow(endMICDate) : undefined,
                    startSTDate: startSTDate !== undefined ? dateSetTimeToNow(startSTDate) : undefined,
                    endSTDate: endSTDate !== undefined ? dateSetTimeToNow(endSTDate) : undefined,
                    number_id: params.request.filterModel?.number_id?.filter,
                    number_job: params.request.filterModel?.number_job?.filter,
                    other_number: params.request.filterModel?.other_number?.filter,
                    type: params.request.filterModel?.type?.filter,
                    district: params.request.filterModel?.district?.filter,
                    sales_order: params.request.filterModel?.sales_order?.filter,
                    pole_approve: params.request.filterModel?.gdev_approve_pole?.filter,
                    pole_complete: params.request.filterModel?.total_complete_pole?.filter,
                    total_pole: params.request.filterModel?.total_pole?.filter,
                    isBilled: params.request.filterModel?.statusFilter?.filter === 'all' ? undefined : params.request.filterModel?.statusFilter?.filter === 'billed',
                    is_gdev_approve_pole_null: params.request.filterModel?.gdev_approve_pole?.type === 'blank' ? true : null,
                    is_user_gdev_null: params.request.filterModel?.user_gdev?.type === 'blank' ? true : null,
                })).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data?.list,
                        rowCount: res.payload.data?.total,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            }
        }
    }

    const onExportHandler = () => {
        const filterModel = gridRef.current.api.getFilterModel();
        const {
            dateFrom: startCompleteDate,
            dateTo: endCompleteDate
        } = filterModel?.complete_date || {};
        const {dateFrom: startMICDate, dateTo: endMICDate} = filterModel?.mic_date || {};
        const {
            dateFrom: startSTDate,
            dateTo: endSTDate
        } = filterModel?.sT_date || {};
        const _monthYearSelected = filterModel?.monthYearFilter?.filter !== undefined ? new Date(filterModel?.monthYearFilter?.filter) : new Date();

        dispatch(exportGDev({
            // skip: gridRef.current.api.paginationGetCurrentPage() * gridRef.current.api.paginationGetPageSize(),
            // take: gridRef.current.api.paginationGetPageSize(),
            weekendDate: filterModel?.weekendDateFilter?.filter === 'all' ? undefined : filterModel.weekendDateFilter?.filter,
            month: filterModel?.weekendDateFilter?.filter === 'all' ? _monthYearSelected.getMonth() + 1 : undefined,
            year: filterModel?.weekendDateFilter?.filter === 'all' ? _monthYearSelected.getFullYear() : undefined,
            project_name: filterModel?.project?.filter,
            kind_job: filterModel?.koj?.filter,
            startCompleteDate: startCompleteDate !== undefined ? dateSetTimeToNow(startCompleteDate) : undefined,
            endCompleteDate: endCompleteDate !== undefined ? dateSetTimeToNow(endCompleteDate) : undefined,
            startMICDate: startMICDate !== undefined ? dateSetTimeToNow(startMICDate) : undefined,
            endMICDate: endMICDate !== undefined ? dateSetTimeToNow(endMICDate) : undefined,
            startSTDate: startSTDate !== undefined ? dateSetTimeToNow(startSTDate) : undefined,
            endSTDate: endSTDate !== undefined ? dateSetTimeToNow(endSTDate) : undefined,
            number_id: filterModel?.number_id?.filter,
            number_job: filterModel?.number_job?.filter,
            other_number: filterModel?.other_number?.filter,
            type: filterModel?.type?.filter,
            district: filterModel?.district?.filter,
            sales_order: filterModel?.sales_order?.filter,
            pole_approve: filterModel?.gdev_approve_pole?.filter,
            pole_complete: filterModel?.total_complete_pole?.filter,
            total_pole: filterModel?.total_pole?.filter,
            isBilled: filterModel?.statusFilter?.filter === 'all' ? undefined : filterModel?.statusFilter?.filter === 'billed',
            is_gdev_approve_pole_null: filterModel?.gdev_approve_pole?.type === 'blank',
        }))

    }

    const openModal = (gDev: GDevDataModel) => {
        setSelectedGDev(gDev);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedGDev({} as GDevDataModel);
        setShowModal(false);
    };

    const getRowStyle = (params: any) => {
        if (params.data && params.data.isBilled) {
            return { background: "rgb(192, 216, 193)" } as any;
        } else {
            return { background: "rgb(241, 192, 192)" } as any;
        }
    }

        return (
        <div>
            <div className="mt-3">
                <div>
                    <div className={"flex"}>
                        <div className="w-1/4 mr-1">
                            <MonthYearPickerState
                                name="monthFilter"
                                label="Month / Year"
                                placeholderText="Select month & year"
                                selected={monthSelected}
                                onChange={(date) => setMonthSelected(date ?? new Date())}
                            />
                        </div>
                        <div className="w-1/4 mx-1">
                            <BasicSelectInput
                                label="Week Ending"
                                options={weekendDateOptions}
                                name={"weekEndingFilter"}
                                onChange={(e) => setWeekendSelected(e.target.value)}
                            />
                        </div>

                    </div>
                    <div className={"flex"}>
                        <div className="w-1/4 mr-1">
                            <BasicSelectInput
                                label="Status"
                                onChange={(e) => setBilledSelected(e.target.value)}
                                value={billedSelected}
                                options={[
                                    {value: 'all', label: 'All', text: "All"},
                                    {value: 'billed', label: 'Billed', text: "Billed"},
                                    {value: 'not-billed', label: 'Not Billed', text: "Not Billed"},
                                ] as Option[]}
                            />
                        </div>
                        <div className="flex items-center ml-1">
                            <div className="mt-5">
                                <Button
                                    color="success"
                                    type="button"
                                    title="Search"
                                    onClick={onSearchClicked}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-data-grid fielding mt-5">
                <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                        getServerSideDataSource={getServerSideDataSource} supportSaveTableState
                                        tableStateName={'GDev'}
                                        defaultFilter={{
                                            monthYearFilter: {
                                                filterType: "text",
                                                type: "contains",
                                                filter: monthSelected.toISOString()
                                            },
                                            weekendDateFilter: {
                                                filterType: "text",
                                                type: "contains",
                                                filter: weekendSelected
                                            },
                                            statusFilter: {
                                                filterType: "text",
                                                type: "contains",
                                                filter: billedSelected
                                            }
                                        }}
                                        isCustomHeader={true}
                                        customChildHeaderComponent={
                                            <>
                                                <FlowbiteButton
                                                    onClick={onExportHandler}
                                                    size="xs"
                                                    color="success"
                                                    isProcessing={isExportLoading}
                                                    disabled={isExportLoading}
                                                >
                                                    <PiMicrosoftExcelLogoDuotone className="mr-2 h-5 w-5"/>
                                                    {isExportLoading ? "Exporting" : " Export to Excel"}
                                                </FlowbiteButton>
                                            </>
                                        }
                                        defaultPageSize={20}
                                        customRowStyle={getRowStyle}
                />
                <DefaultModal
                    title={`GDev Approval Pole`}
                    show={showModal}
                    onClose={closeModal}
                    body={
                        <GDevForm data={selectedGDev} />
                     }
                />
            </div>
        </div>
    );
};

export default GDevTable;
