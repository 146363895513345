import React, {useEffect, useState} from 'react';
import {Job, InputJob} from '../models';
import {FileAttachment} from "types";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {schema} from '../constants';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {isArrayEmpty, isObjectEmpty} from 'helpers/checkEmptiness';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';
import BasicSelectInput, {Option} from 'components/SelectInput/BasicSelectInput';
import BasicDatePicker from 'components/DatePicker/BasicDatePicker';
import InputCheckbox from 'components/Checkbox/InputCheckbox';
import FileDropzone from 'components/FileInput/Dropzone';
import JobFile from './JobFile';
import {HiInformationCircle} from 'react-icons/hi';
import {Alert} from 'flowbite-react';
import {darkGreen, darkRed} from '../../../../../constants';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import SelectInput from "components/SelectInput/SelectInput";
import {SingleValue} from "react-select";
import {getListByDistrict as getProjectByDistrict, reset as resetProject} from "features/master/project/project.reducer";
import {deleteJobFile} from "../job.reducer";
import {dateSetTimeToNow} from "../../../../../helpers/convert";

const JobForm = ({isLoading = false, isEdit = false, ...props}: JobProps) => {
    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
        formState: {errors},
    } = useForm<InputJob>({
        resolver: yupResolver(schema),
        defaultValues: {local_files: [] as File[], selectedProject: {
            value: "null",
            label: "-",
            text: "-",
            }},
    });
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const fileWatcher = watch('local_files');
    const districtWatcher = watch('district_id');
    const projectWatcher = watch('selectedProject');
    const KojWatcher = watch('kind_id');
    // const isMreWatcher = watch('is_mre');
    // const isKatapultWatcher = watch('is_katapult');

    const [currentFiles, setCurrentFiles] = useState<Array<FileAttachment>>([]);
    const [totalFileRemoved, setTotalFileRemoved] = useState<number>(0);

    const singleState = useAppSelector(
        (state: RootState) => state.job.single ?? ({} as Job)
    );
    const categoryOptions = useAppSelector(
        (state: RootState) => state.jobCategory.options ?? []
    );
    const typeOptions = useAppSelector(
        (state: RootState) => state.jobType.options ?? []
    );
    const districtOptions = useAppSelector(
        (state: RootState) => state.district.options ?? []
    );
    const projectOptions = useAppSelector(
        (state: RootState) => state.project.optionsByDistrict ?? []
    );
    const isProjectLoading = useAppSelector(
        (state: RootState) => state.project.isLoading
    );
    const jobKindOptions = useAppSelector(
        (state: RootState) => state.jobKind.options ?? []
    );
    const departmentOptions = useAppSelector(
        (state: RootState) => state.department.options ?? []
    );
    const sourceOptions = useAppSelector(
        (state: RootState) => state.jobSource.options ?? []
    );
    const uploadProgresses = useAppSelector(
        (state: RootState) => state.job.uploadProgress ?? []
    );
    const jobFile = useAppSelector(
        (state: RootState) => state.job.jobFile ?? []
    );

    useEffect(() => {
        if (!isObjectEmpty(singleState)) {
            // destructuring an object
            let {
                type_name,
                district_name,
                kind_name,
                ...rest
            } = singleState;
            let convert = {
                ...rest,
                local_files: [] as File[],
                pole_total: singleState.pole_total,
                selectedProject: {
                    value: singleState.project_id === null ? "null" : singleState.project_id,
                    label: singleState.project_name === null ? "-" : singleState.project_name,
                    text: singleState.project_name === null ? "-" : singleState.project_name,
                },
                is_mre: singleState.is_have_mre,
                is_katapult: singleState.is_have_katapult,
                category_id: singleState.category_id === null ? "null" : singleState.category_id,
                district_id: singleState.district_id === null ? "null" : singleState.district_id,
                department_id: singleState.department_id === null ? "null" : singleState.department_id,
                // date_due: singleState.date_due,
                // date_due_fielding: singleState.date_due_fielding,
                date_due: dateSetTimeToNow(rest.date_due),
                date_due_fielding: singleState.date_due_fielding === "0001-01-01T00:00:00" ? new Date().toISOString() : dateSetTimeToNow(singleState.date_due_fielding),
            };
            reset(convert);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleState]);

    useEffect(() => {
        if (districtWatcher !== undefined ) {
            if (districtWatcher !== "null" && districtWatcher !== null) {
                dispatch(getProjectByDistrict(districtWatcher));
            } else {
                dispatch(resetProject());
                setValue('selectedProject', {
                    value: "null",
                    label: "-",
                    text: "-",
                } as Option);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districtWatcher]);

    useEffect(() => {
        if (!isEdit && !isArrayEmpty(jobKindOptions)) {
            const kind = jobKindOptions.find((item) => item.text === 'Normal');
            setValue('kind_id', kind?.value ?? jobKindOptions[0]?.value);
        }
    }, [jobKindOptions, isEdit]);

    useEffect(() => {
        if (!isArrayEmpty(sourceOptions)) {
            const source = sourceOptions.find((item) => item.text === 'Sitetracker');
            setValue('source_id', source?.value ?? sourceOptions[0]?.value);
        }
    }, [sourceOptions]);

    useEffect(() => {
        if (KojWatcher !== undefined) {

            const _koj = jobKindOptions.find((item) => item.value === KojWatcher);
            if (_koj?.text === 'MRE' || _koj?.text === 'Katapult') {
                setValue('is_mre', false);
                setValue('is_katapult', false);
            }

        }
    }, [KojWatcher]);

    useEffect(() => {
        if (!isEdit) {
            reset({
                category_id: "null",
                type_id: "",
                district_id: "null",
                department_id: "null",
                local_files: [] as File[],
                date_due: dateSetTimeToNow(new Date().toISOString()) ?? new Date().toISOString(),
                date_due_fielding: dateSetTimeToNow(new Date().toISOString()) ?? new Date().toISOString(),
            } as InputJob);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isEdit,
        categoryOptions,
        typeOptions,
        districtOptions,
        jobKindOptions,
        departmentOptions,
        sourceOptions,
    ]);

    const doSubmit = (data: InputJob) => {
        let _data = {
            number_id: data.number_id,
            number_job: data.number_job,
            other_number: data.other_number,
            po_number: data.po_number,
            sales_order: data.sales_order,
            date_due: dateSetTimeToNow(new Date(data.date_due).toISOString()),
            date_due_fielding: data.date_due_fielding,
            fielder: data.fielder,
            category_id: data.category_id === "null" ? null : data.category_id,
            type_id: data.type_id,
            district_id: data.district_id === "null" ? null : data.district_id,
            project_id: data.selectedProject?.value === "null" ? null : data.selectedProject?.value ?? null,
            requestor: data.requestor,
            is_priority: data.is_priority,
            is_mre: false,
            is_katapult: false,
            kind_id: data.kind_id,
            department_id: data.department_id === "null" ? null : data.department_id,
            source_id: data.source_id,
            pole_total: data.pole_total,
            status_id: singleState.status_id,
            local_files: data.local_files,
            isEdit: isEdit,
        }
        // console.log(_data);
        props.onSubmit(_data);
    };

    const onSubmit = (data: InputJob) => {
        if (totalFileRemoved > 0) {
            MySwal.fire({
                text: `You are about to delete ${totalFileRemoved} current file, are you sure?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: darkRed,
                cancelButtonColor: darkGreen,
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    doSubmit(data);
                }
            });
        } else {
            doSubmit(data);
        }
    };

    const onFileChange = (files: Array<File>) => {
        setValue('local_files', files);
    };

    const onFileRemove = (fileId: string) => {
        let newFiles = currentFiles.filter((f) => f.id !== fileId);
        setCurrentFiles(newFiles);
        setTotalFileRemoved((prevState) => prevState + 1);
    };

    const onFileDelete = (fileId: string) => {
        MySwal.fire({
            text: `Are you sure you want to delete this file?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: darkRed,
            cancelButtonColor: darkGreen,
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJobFile(fileId));
            }
        });
    };

    const onProjectChange = (val: SingleValue<Option>) => {
        setValue('selectedProject', val ?? {} as Option);
    };

    // const disabledMREKatapultOption = () => {
    //     const _koj = jobKindOptions.find((item) => item.value === KojWatcher);
    //     return _koj?.text === 'MRE' || _koj?.text === 'Katapult';
    // }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-2 gap-4">
                <div>
                    <InputText
                        {...register('number_id')}
                        label="ID Number"
                        placeholder="Input id number"
                        message={errors.number_id?.message}
                        // disabled={isEdit}
                    />

                    <InputText
                        {...register('number_job')}
                        label="Job Number"
                        placeholder="Input job number"
                        message={errors.number_job?.message}
                    />

                    <InputText
                        {...register('po_number')}
                        label="PO Number"
                        placeholder="Input po number"
                        message={errors.po_number?.message}
                    />

                    <InputText
                        {...register('other_number')}
                        label="Other Number"
                        placeholder="Input other number"
                        message={errors.other_number?.message}
                    />

                    <InputText
                        {...register('sales_order')}
                        label="Sales Order"
                        placeholder="Input sales order"
                        message={errors.sales_order?.message}
                    />

                    <BasicDatePicker
                        label="Fielding Due Date"
                        name="date_due_fielding"
                        placeholderText="Select Due Date"
                        control={control}
                        withPreciseTime
                    />

                    <BasicDatePicker
                        label="Job Due Date"
                        name="date_due"
                        placeholderText="Select Due Date"
                        control={control}
                        withPreciseTime
                    />

                    <InputText
                        {...register('fielder')}
                        label="Fielder"
                        placeholder="Input fielder"
                        message={errors.fielder?.message}
                    />

                    <BasicSelectInput
                        label="Category"
                        message={errors.category_id?.message}
                        options={[{
                            text: "-",
                            value: "null"
                        }, ...categoryOptions]}
                        {...register('category_id')}
                    />
                    <BasicSelectInput
                        label="Type"
                        message={errors.type_id?.message}
                        options={typeOptions}
                        {...register('type_id')}
                    />
                    <BasicSelectInput
                        label="District"
                        message={errors.district_id?.message}
                        options={[{
                            text: "-",
                            value: "null"
                        },...districtOptions]}
                        {...register('district_id')}
                    />
                    <SelectInput
                        key={`my_unique_select_key__${getValues('selectedProject')?.value}`}
                        options={[{
                            text: "-",
                            value: "null",
                            label: "-",
                        },...projectOptions]}
                        isLoading={isProjectLoading}
                        label="Project"
                        onChange={onProjectChange}
                        message={errors.selectedProject?.message}
                        value={projectWatcher}
                    />
                    <InputText
                        {...register('requestor')}
                        label="Requestor"
                        placeholder="Input requestor"
                        message={errors.requestor?.message}
                    />
                </div>
                <div>
                    <div className="mt-2.5 mb-3">
                        <InputCheckbox
                            className="my-3"
                            label="Priority"
                            id="priority"
                            {...register('is_priority')}
                        />
                        {/*<InputCheckbox*/}
                        {/*    className="my-3"*/}
                        {/*    label="MRE Req"*/}
                        {/*    id="mre-req"*/}
                        {/*    {...register('is_mre')}*/}
                        {/*    disabled={isEdit && singleState.is_have_mre || disabledMREKatapultOption()}*/}
                        {/*    checked={isMreWatcher}*/}
                        {/*/>*/}
                        {/*<InputCheckbox*/}
                        {/*    className="my-3"*/}
                        {/*    label="Katapult"*/}
                        {/*    id="katapult"*/}
                        {/*    {...register('is_katapult')}*/}
                        {/*    disabled={isEdit && singleState.is_have_katapult || disabledMREKatapultOption()}*/}
                        {/*    checked={isKatapultWatcher}*/}
                        {/*/>*/}
                    </div>

                    <InputText
                        {...register('pole_total')}
                        label="Req Pole"
                        placeholder="Input req pole"
                        message={errors.pole_total?.message}
                    />

                    <BasicSelectInput
                        label="KoJ"
                        message={errors.kind_id?.message}
                        options={jobKindOptions}
                        {...register('kind_id')}
                        disabled={singleState.kind_name === 'MRE' || singleState.kind_name === 'Katapult'}
                        value={KojWatcher}
                    />
                    <BasicSelectInput
                        label="Department"
                        message={errors.department_id?.message}
                        options={[{
                            text: "-",
                            value: "null"
                        }, ...departmentOptions]}
                        {...register('department_id')}
                    />
                    {/*department owner should be here automatically*/}
                    <BasicSelectInput
                        label="Sources"
                        message={errors.source_id?.message}
                        options={sourceOptions}
                        {...register('source_id')}
                    />

                    <FileDropzone
                        label="Attachment"
                        onChange={onFileChange}
                        uploadProgress={uploadProgresses}
                        files={fileWatcher}
                    />

                    <JobFile files={currentFiles} uploadedFile={jobFile} onFileRemove={onFileRemove}
                             onFileDelete={onFileDelete}/>

                    {totalFileRemoved > 0 && (
                        <div className="mt-3">
                            <Alert color="warning" icon={HiInformationCircle}>
                                <span>
                                    Changes made in this file list will not be
                                    saved until the form is submitted
                                </span>
                            </Alert>
                        </div>
                    )}

                    <div className="pt-2 pb-2 float-right">
                        <Button
                            color="success"
                            type="submit"
                            title="Submit"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export interface JobProps {
    isLoading: boolean;
    isEdit: boolean;
    onSubmit: (formInput: InputJob) => void;
}

export default JobForm;
