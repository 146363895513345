import React, { useEffect, useState } from 'react';
import AgDataGrid from 'components/DataGrid/AgDataGrid';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { filterParams } from 'helpers/agTable';
import {getStaffReportMain} from '../../staffReport.reducer';
import {Link} from "react-router-dom";
import {DETAIL_TYPE_JOB_INPUT} from "../../../../worksheet/process/job/constants";
import Button from "../../../../../components/Button/Button";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import {useForm} from "react-hook-form";
import {InputFilter} from "../../models";
import FormLoader from "../../../../../components/Loader/FormLoader";

const StaffReportTable = () => {
    const dispatch = useAppDispatch();

    const {
        control,
        handleSubmit,
        watch,
        reset,
    } = useForm<InputFilter>({defaultValues: {startDate: new Date().toISOString(), endDate: new Date().toISOString()}});

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const mainStaffReport = useAppSelector(
        (state) => state.staffReport.staffReportMainList ?? []
    );
    const isMainLoading = useAppSelector(
        (state) => state.staffReport.isMainLoading
    );


    useEffect(() => {
        dispatch(getStaffReportMain({
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
        }));
    }, []);

    const JobNumberLinkCellRender = (props: any) => (
        // <Link
        //     to={`/worksheet/job-detail/${DETAIL_TYPE_JOB_INPUT}/${props.data?.number_id}`}
        //     className="underline text-blue-700"
        // >
        //     {props.data?.number_job}
        // </Link>
    <>
        {props.data?.number_id?.split(',').map((item: string, index: number) => (
            <Link
                to={`/worksheet/job-detail/${DETAIL_TYPE_JOB_INPUT}/${item}`}
                className="underline text-blue-700"
            >
                {`${props.data?.number_job?.split(',')[index]}${props.data?.number_id.split(',').length - 1 === index ? '' : ', '}`}
            </Link>
        ))}
    </>
    );

    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([
        {
            field: 'requestDate',
            headerName: 'Request Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date',
        },
        {
            field: 'typeName',
            headerName: 'Type',
            filter: true,
            // cellRenderer: (params: any) => {
            //     const option = typeOptions.find(
            //         (option) => parseInt(option.value) === params.value
            //     );
            //     return option ? option.label : '';
            // },
        },
        {
            headerName: 'Job Number',
            field: 'number_job',
            filter: 'agTextColumnFilter',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
        },
        { field: 'name', headerName: 'Name', filter: true },
        { field: 'nip', headerName: 'NIP', filter: true },
        { field: 'teamName', headerName: 'Team', filter: true },
        { field: 'role_name', headerName: 'Position', filter: true },
        { field: 'note', headerName: 'Notes' },
        { field: 'reqMinute', headerName: 'Req Minutes', aggFunc: 'sum' },
        { field: 'reqPole', headerName: 'Req Pole', filter: true, aggFunc: 'sum' },
        { field: 'totalPole', headerName: 'Total Pole', filter: true },
        { field: 'statusName', headerName: 'Status', filter: true },
        { field: 'approvalPole', headerName: 'Approval Poles', aggFunc: 'sum' },
        { field: 'approvalHeadName', headerName: 'Head' },
        { field: 'approvalByName', headerName: 'Manager' },
        { field: 'rejectionNote', headerName: 'Rejection Notes' },
    ]);

    const onSubmit = (data: InputFilter) => {
        let localStart = new Date(data.startDate).toISOString();
        let localEnd = new Date(data.endDate).toISOString();
        // refreshCache(undefined);
        // gridRef.current.api.setFilterModel({
        //     date_filter: {
        //         filterType: "date",
        //         type: "inRange",
        //         dateFrom: localStart,
        //         dateTo: localEnd,
        //     },
        // })
        dispatch(getStaffReportMain({
            startDate: localStart,
            endDate: localEnd,
        }));
    }

    const onResetClicked = () => {
        reset();
        dispatch(getStaffReportMain({
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
        }));
    }

    return (
        <div className={"mt-10"}>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <RangeDatePicker startName='startDate' endName='endDate' startValue={new Date(startDate)}
                                     endValue={new Date(endDate)} control={control} centerStyle/>
                    <div className="flex justify-end items-center">
                        <div className={"mx-1"}>
                            <Button
                                color="success"
                                type="button"
                                title="Reset"
                                onClick={onResetClicked}
                            />
                        </div>
                        <div className={"ml-1"}>
                            <Button
                                color="success"
                                type="submit"
                                title="Filter"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="mt-3">
                <FormLoader isLoading={isMainLoading} label={"Fetching Data"}>
                <div className="main-data-grid fielding mt-5">
                    <AgDataGrid
                        rowData={mainStaffReport}
                        columnDefs={columnDefs}
                        groupIncludeFooter={true}
                        groupIncludeTotalFooter={true}
                        supportSaveTableState
                        tableStateName={'report-main-staff-report'}
                        defaultPageSize={20}
                    />
                </div>
                </FormLoader>
            </div>
        </div>
    );
};

export default StaffReportTable;
