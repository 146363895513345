import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';
import {HttpClientError, RequestStatus} from 'http-client';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
    createJob, getJobFile,
    getSingle,
    reset,
    setUploadProgress,
    updateJob,
} from '../job.reducer';
import {InputJob} from '../models';
import JobForm from '../components/JobForm';
import {getList as getCategoryList} from 'features/master/jobCategory/jobCategory.reducer';
import {getList as getTypeList} from 'features/master/jobType/jobType.reducer';
import {getList as getDistrictList} from 'features/master/district/district.reducer';
import {getList as getJobKindList} from 'features/master/jobKind/jobKind.reducer';
import {getList as getDepartmentList} from 'features/master/department/department.reducer';
import {getList as getJobSourceList} from 'features/master/jobSource/jobSource.reducer';
import {deletedStatusOption, savedStatusOption} from 'helpers/alertLabel';
import {isArrayEmpty} from 'helpers/checkEmptiness';
import {Alert, Progress} from 'flowbite-react';
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import Button from "../../../../../components/Button/Button";
import {IoCloudDone, IoCloudOffline} from "react-icons/io5";

const JobDetailPage = ({isEdit = false}: JobDetailPageProps) => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let {id} = useParams();
    let [searchParams, _setSearchParams] = useSearchParams();
    const MySwal = withReactContent(Swal);

    const [showModal, setShowModal] = useState<boolean>(false);

    const isLoading = useAppSelector(
        (state: RootState) => state.job.isLoading ?? false
    );
    const status = useAppSelector((state: RootState) => state.job.status);
    const uploadStatus = useAppSelector((state: RootState) => state.job.uploadStatus ?? []);
    const uploadProgresses = useAppSelector(
        (state: RootState) => state.job.uploadProgress ?? []
    );
    const deleteFileStatus = useAppSelector(
        (state: RootState) => state.job.deleteFileStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        dispatch(getCategoryList({skip: 0, take: 500}));
        dispatch(getTypeList({skip: 0, take: 500}));
        dispatch(getDistrictList({skip: 0, take: 500}));
        dispatch(getJobKindList({skip: 0, take: 500}));
        dispatch(getDepartmentList({skip: 0, take: 500}));
        dispatch(getJobSourceList({skip: 0, take: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEdit && id !== undefined) {
            dispatch(getSingle(id as string));
            dispatch(getJobFile(id as string));
        } else {
            dispatch(reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isEdit]);

    useEffect(() => {
        if (deleteFileStatus !== undefined && deleteFileStatus !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    deleteFileStatus === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getJobFile(id as string));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteFileStatus, error]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            if (status === RequestStatus.success) {
                setShowModal(true);
                reset();
            } else {
                MySwal.fire(savedStatusOption(false, error.data?.message)).then(() => {
                    dispatch(reset());
                    dispatch(setUploadProgress([]));
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const SendJob = () => {
        const calc = uploadProgresses.reduce((acc, item) => acc + item.progress, 0);
        const percentage = calc / uploadProgresses.length;
        return (
            <div className="p-5">
                <div className="my-2">
                    <Alert
                        color="success"
                    >
                            <span>
                                <span className="font-medium">Success!</span>{' '}
                                job saved successfully.
                            </span>
                    </Alert>
                </div>
                <>
                    {
                        uploadStatus.some((item) => item.status === RequestStatus.pending) && (
                            <div className="my-2">
                                <div
                                    className="gap-2 p-4 text-sm text-blue-700 bg-blue-100 border-blue-500 dark:bg-blue-200 dark:text-blue-800 rounded-lg"
                                    role="alert">
                                    <div>
                                        <div className="flex">
                                    <span>
                                    <span className="font-medium ">
                                        Upload File!
                                    </span>{' '}
                                        Uploading file, please wait...
                                </span>
                                        </div>
                                        <div className="mt-2">
                                            <Progress progress={percentage} color="blue"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        !isArrayEmpty(uploadStatus) && uploadStatus.every((item) => item.status !== RequestStatus.pending) && (
                            <div className="my-2">
                                <div
                                    className="gap-2 p-4 text-sm text-blue-700 bg-blue-100 border-blue-500 dark:bg-blue-200 dark:text-blue-800 rounded-lg"
                                    role="alert">
                                    <div>
                                        <div className="flex">
                                    <span>
                                    <span className="font-medium ">
                                        File List
                                    </span>
                                </span>
                                        </div>
                                        <div className="mt-2">
                                            {
                                                uploadStatus.map((item, index) => (
                                                    <span
                                                        key={`upload-status-${index}`}
                                                        className={`${(item.status === RequestStatus.success && item.createStatus === RequestStatus.success) ? "text-primary-color" : "text-danger-color"} block`}>{(item.status === RequestStatus.success && item.createStatus === RequestStatus.success) ?
                                                        <IoCloudDone className="inline mr-2"/> :
                                                        <IoCloudOffline className="inline mr-2"/>}{item.filename}</span>
                                                ))
                                            }
                                        </div>
                                        <div className="mt-2">
                                            {
                                                uploadStatus.some((item) => item.status === RequestStatus.failed) && (
                                                    <span className="text-danger-color font-bold">Some files failed to upload, please try again later</span>
                                                )
                                            }
                                            {
                                                uploadStatus.every((item) => item.status === RequestStatus.success) && (
                                                    <span
                                                        className="text-primary-color font-bold">File uploaded successfully</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>

                <div className="my-2">
                    <Button
                        color="success"
                        title="Close"
                        onClick={() => {
                            dispatch(reset());
                            setShowModal(false);
                            if (searchParams.get('page')) {
                                navigate(`/report/history`);
                            } else {
                                navigate(searchParams.get('from') !== null ? `/worksheet/${searchParams.get('from')}` : "/worksheet/job", {replace: true});
                            }
                        }}
                        disabled={uploadStatus.some((item) => item.status === RequestStatus.pending)}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        )

    }

    const onSubmit = (formInput: InputJob) => {
        if (isEdit) {
            let input = {...formInput, id: id as string};
            dispatch(updateJob(input));
        } else {
            dispatch(createJob(formInput));
        }
    };

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <h1 className="dark:text-gray-300">Input Job Form</h1>

            <JobForm
                isLoading={isLoading}
                isEdit={isEdit}
                onSubmit={onSubmit}
            />
            <DefaultModal
                title="Saving Job"
                show={showModal}
                onClose={toggleModal}
                body={
                    <SendJob/>
                }
            />
        </div>
    );
};

export interface JobDetailPageProps {
    isEdit: boolean;
}

export default JobDetailPage;
