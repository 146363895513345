import {BaseServiceClass} from "../../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../../http-client";
import {
    AssignMRERequest,
    GetMREListRequest, GetMREMainWithFilterRequest, GetMREWithFilterRequest,
    MainMREModel,
    MRECancelReq,
    MREModel,
    MRESummary,
    MREWaitingReq
} from "./models";

export default class ProcessMREApi extends BaseServiceClass {
    static getMRE(args: GetMREListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<MREModel[]>>(this.PATH_WORKSHEET.job_get_mre, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getMREWithFilter(args: GetMREWithFilterRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<MREModel[]>>(this.PATH_WORKSHEET.job_get_mre_with_filter, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static getSingleMRE(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<MREModel>>(this.PATH_WORKSHEET.job_get_mre, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: 0,
                take: 1,
                id: id
            }
        }).then((res) => res.data);
    }
    static getMainMRE(args: GetMREListRequest, token: string, userId: string) {
        return httpClient.get<ApiResponseWrapper<MainMREModel[]>>(this.PATH_WORKSHEET.job_get_mre_main, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getMainMREWithFilter(args: GetMREMainWithFilterRequest, token: string, userId: string) {
        return httpClient.post<ApiResponseWrapper<MainMREModel[]>>(this.PATH_WORKSHEET.job_get_mre_main_with_filter, {...args, employee_id: userId}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static assignMRE(args: AssignMRERequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_mre_assign, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data.data);
    }
    static assignQCMRE(args: AssignMRERequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_qc_mre_assign, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data.data);
    }
    static waitingMRE(args: MREWaitingReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_mre_waiting, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data.data);
    }
    static getMRESummary(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<MRESummary[]>>(this.PATH_WORKSHEET.job_get_mre_summary, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
    static getQCMRESummary(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<MRESummary[]>>(this.PATH_WORKSHEET.job_get_qc_mre_summary, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
    static cancelMRE(args: MRECancelReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_mre_cancel, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data.data);
    }
}