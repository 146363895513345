import React, {useEffect, useState} from 'react';
import AgDataGrid from 'components/DataGrid/AgDataGrid';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {filterParams} from "../../../../../helpers/agTable";
import {Button} from "flowbite-react";
import {PiMicrosoftExcelLogoDuotone} from "react-icons/pi";
import {exportToCsv, reset} from "../../jobReport.reducer";
import FormLoader from "../../../../../components/Loader/FormLoader";
import {customStatusOption} from "../../../../../helpers/alertLabel";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {RootState} from "../../../../../app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const JobStatisticTable = ({
        startDate = new Date().toISOString(),
        endDate = new Date().toISOString(),
                           }:JobStatisticTableProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const jobStatisticList = useAppSelector(
        (state) => state.jobReport.jobStatisticList ?? []
    );
    const isGetJobStatisticLoading = useAppSelector(
        (state) => state.jobReport.isGetJobStatisticLoading
    );
    const isExportStatisticLoading = useAppSelector(
        (state) => state.jobReport.isExportStatisticLoading
    );
    const exportStatus = useAppSelector((state: RootState) => state.jobReport.exportStatisticStatus);
    const error = useAppSelector((state: RootState) => state.jobReport.error ?? ({} as HttpClientError));

    useEffect(() => {
        if (exportStatus !== undefined && exportStatus !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(exportStatus === RequestStatus.success, error.data?.message, 'Statistic has been exported!', 'failed to export job statistic : ')).then(() => {
                dispatch(reset());
            })
        }
    }, [exportStatus]);

    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([
        {
            field: 'ts_created',
            headerName: 'Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date'
        },
        {field: 'completeJob', headerName: 'Completed Job', filter: true, aggFunc: 'sum'},
        {field: 'completePole', headerName: 'Completed Poles', filter: true, aggFunc: 'sum'},
        {field: 'completeJobMRE', headerName: 'Completed MRE', filter: true, aggFunc: 'sum'},
        {field: 'completeJobKatapult', headerName: 'Completed Katapult', filter: true, aggFunc: 'sum'},
        {field: 'acceptedPole', headerName: 'Accepted Poles', filter: true, aggFunc: 'sum'},
        {field: 'databasePole', headerName: 'Database Poles', filter: true, aggFunc: 'sum'},
        {field: 'alreadyCreated', headerName: 'Already Created', filter: true, aggFunc: 'sum'},
        {field: 'notCreated', headerName: 'Not Created', filter: true, aggFunc: 'sum'},
    ]);

    const onExportHandler = () => {
        dispatch(exportToCsv({
            startDate: startDate,
            endDate: endDate,
        }));
    }

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <FormLoader isLoading={isGetJobStatisticLoading} label={"Fetching Data"}>
                    <AgDataGrid
                        rowData={jobStatisticList}
                        columnDefs={columnDefs}
                        isCustomHeader={true}
                        customChildHeaderComponent={
                            <>
                                <Button
                                    onClick={onExportHandler}
                                    size="xs"
                                    color="success"
                                    isProcessing={isExportStatisticLoading}
                                    disabled={isExportStatisticLoading}
                                >
                                    <PiMicrosoftExcelLogoDuotone className="mr-2 h-5 w-5"/>
                                    {isExportStatisticLoading ? "Exporting" : " Export to Excel"}
                                </Button>
                            </>
                        }
                        groupIncludeFooter={true}
                        groupIncludeTotalFooter={true}
                        supportSaveTableState
                        tableStateName={'report-job-statistic'}
                        defaultPageSize={20}
                    />
                    </FormLoader>
                </div>
            </div>
        </div>
    );
};

export interface JobStatisticTableProps {
    startDate?: string;
    endDate?: string;
}

export default JobStatisticTable;
