import {CSEmployee} from "../features/worksheet/process/calcSpec/models";
import moment from "moment";

export const convertFileSize = (bytes: number) => {
    return (bytes / (1024*1024)).toFixed(2);
}

export const getRangeTotal = (start:number, end:number):number=> {
   return Array.from({ length: end - start + 1 }, (_, i) => i).length;
}

export const groupBy = (arr: any[], property: string | number) => {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) {
            memo[x[property]] = [];
        }
        memo[x[property]].push(x);
        return memo;
    }, []);
};

export const groupByExt =  (data: any[], ...keys: any[]) =>
{
    // Ex: {values: {color: 'red'}}, ['values', 'color'] => 'red'
    // @ts-ignore
    const getGroupFromItem = (item, keys) =>
    {
        return (keys.length > 1)
            ? getGroupFromItem(item[keys[0]], keys.slice(1))
            : item[keys[0]]
    }

    return data.reduce((results, item) =>
        {
            // Get the first instance of the key by which we're grouping
            var group = getGroupFromItem(item, keys);

            // Ensure that there's an array to hold our results for this group
            results[group] = results[group] || [];

            // Add this item to the appropriate group within results
            results[group].push(item);

            // Return the updated results object to be passed into next reduce call
            return results;
        },

        // Initial value of the results object
        {}
    );
};

export const dateWithTime = (date: string) => {
    return date === "0001-01-01T00:00:00" ? "-" :moment(date).format('MM/DD/YYYY HH:mm:ss');
}

export const checkValidDate = (date: string) => {
    return date === "0001-01-01T00:00:00" ? "-" :new Date(date).toLocaleDateString('en-US');
}

export const dateSetTimeToNow = (date: string | undefined) => {
    // Membuat objek Date dari tanggal dan waktu yang diberikan
    let dateAndTime = date !== undefined ? new Date(date) : new Date();

// Mendapatkan jam, menit, detik, dan milidetik saat ini
    let timeNow = new Date();

// Mengatur jam, menit, detik, dan milidetik objek tanggalDanWaktu ke jam sekarang
    dateAndTime.setHours(timeNow.getHours(), timeNow.getMinutes(), timeNow.getSeconds(), timeNow.getMilliseconds());

    return moment(dateAndTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
}

export function getInitials(fullName: string) {
    // Split the full name into words
    const words = fullName.split(' ');

    // Extract the first character of each word and join them
    const initials = words.map(word => word[0]).join('');

    return initials.toUpperCase(); // Convert to uppercase if needed
}

export function parseDate(date: string | undefined) {
    return date !== undefined ? new Date(date).toISOString() : undefined;
}


export function getDatesHMinus7(dateStrings: string[]): Date[] {
    return dateStrings.map((dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() - 6);
        return date;
    });
}

export function checkWeekendRange(
    startWeeks: string[],
    endWeeks: string[]
): string[] {
    const currentDate = new Date().toISOString().split('T')[0];

    const isWithinRange = (
        date: string,
        startDate: string,
        endDate: string
    ): boolean => {
        const currentDateObj = new Date(date);
        const start = new Date(startDate);
        const end = new Date(endDate);
        return currentDateObj >= start && currentDateObj <= end;
    };

    for (let i = 0; i < startWeeks.length; i++) {
        const startDate = startWeeks[i];
        const endDate = endWeeks[i];
        if (isWithinRange(currentDate, startDate, endDate)) {
            return [startDate, endDate];
        }
    }

    return [];
}

export function checkCurrentWeekendRange(
    startWeeks: string,
    endWeeks: string,
    currentDate: string
): boolean {
    const isWithinRange = (
        date: string,
        startDate: string,
        endDate: string
    ): boolean => {
        const currentDateObj = new Date(date);
        const start = new Date(startDate);
        const end = new Date(endDate);
        return currentDateObj >= start && currentDateObj <= end;
    };

    const startDate = startWeeks;
    const endDate = endWeeks;
    if (isWithinRange(currentDate.split('T')[0], startDate, endDate)) {
        return true;
    }

    return false;
}