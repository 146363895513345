import { HttpClientError, RequestStatus } from 'http-client';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {UploadProgress, UploadStatus} from "../../../basic/fileAttachment/models";
import {CompleteJobRequest, GetQCFileRequest, JobFileQC, JobFileQCList, UpdateQCNote} from "./jobQC.models";
import JobQCApi from "./jobQC.api";
import {CreateJobFile, JobFileNew} from "../../process/job/models";
import FileAttachmentApi from "../../../basic/fileAttachment/fileAttachment.api";

export interface JobQCSlice {
    jobQCFile?: Array<JobFileQCList>;
    uploadProgress?: Array<UploadProgress>;
    uploadStatus?: Array<UploadStatus>;
    deleteFileStatus?: RequestStatus;
    isLoading?: boolean;
    rowsAffected?: number;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const uploadJobQCFile = createAsyncThunk(
    'processDetail/qc/uploadJobQCFile',
    async (args: CreateJobFile, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((file, index) => {
                        return {
                            index: index,
                            filename: file.name,
                            status: RequestStatus.pending,
                            createStatus: RequestStatus.pending,
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (file, index) => {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('job_id', args.job_id);
                const config = {
                    headers: {
                        Authorization: `Bearer ${userAuth?.id as string}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        location: 'job-qc',
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            })
                        );
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.success,
                        })
                    );
                    return {
                        name: file.name,
                        path: data.result,
                        job_id: args.job_id
                    }
                }).catch(() => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.failed,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                        job_id: args.job_id
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                dispatch(createJobQCFile(attachment as Array<JobFileNew>));
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createJobQCFile = createAsyncThunk(
    'processDetail/qc/createJobQCFile',
    async (
        args: Array<JobFileQC>,
        { getState, rejectWithValue, dispatch }
    ) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            const createResponse = args?.map(async (arg, index) => {
                if (arg.path !== "") {
                    return await JobQCApi.createJobQCFile({
                        ...arg, employee_id: userAuth?.user_id as string
                    }, userAuth?.id as string).then((data) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.success,
                            })
                        );
                        return data;
                    }).catch((e) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.failed,
                            })
                        );
                        return e;
                    });
                } else {
                    return {};
                }
            });

            Promise.all(createResponse).then(async (attachment) => {
                console.log('saved successfully', attachment);
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

// export const createJobQCFile = createAsyncThunk(
//     'processDetail/qc/createJobQCFile',
//     async (args: CreateJobQCFile, { getState, rejectWithValue, dispatch }) => {
//         try {
//             const { userAuth } = (getState() as RootState).user;
//
//             dispatch(
//                 setUploadProgress(
//                     args.local_files.map((file, index) => {
//                         return { index: index, progress: 0 };
//                     })
//                 )
//             );
//             dispatch(
//                 setUploadStatus(
//                     args.local_files.map((file, index) => {
//                         return {
//                             index: index,
//                             filename: file.name,
//                             status: RequestStatus.pending,
//                         };
//                     })
//                 )
//             );
//
//             args.local_files.map(async (file, index) => {
//                 let formData = new FormData();
//                 formData.append('file', file);
//                 formData.append('job_id', args.job_id);
//                 formData.append('employee_id', args.employee_id);
//                 const config = {
//                     headers: {
//                         Authorization: `Bearer ${userAuth?.id as string}`,
//                         'Content-Type': 'multipart/form-data',
//                     },
//                     onUploadProgress: (progressEvent: ProgressEvent) => {
//                         const percentCompleted = Math.round(
//                             (progressEvent.loaded * 100) / progressEvent.total
//                         );
//                         dispatch(
//                             updateUploadProgress({
//                                 index: index,
//                                 progress: percentCompleted,
//                             })
//                         );
//                     },
//                 };
//                 let response = await JobQCApi.createJobQCFile(
//                     formData,
//                     config
//                 );
//                 if (response.status) {
//                     dispatch(
//                         updateUploadStatus({
//                             index: index,
//                             status: RequestStatus.success,
//                         })
//                     );
//                     return response;
//                 } else {
//                     dispatch(
//                         updateUploadStatus({
//                             index: index,
//                             status: RequestStatus.failed,
//                         })
//                     );
//                     return response;
//                 }
//             });
//         } catch (e) {
//             console.log('caught error', e);
//             return rejectWithValue(e as HttpClientError);
//         }
//     }
// );

export const getJobQCFile = createAsyncThunk(
    'processDetail/qc/getJobQCFile',
    async (args: GetQCFileRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobQCApi.getJobQCFile(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteJobQCFile = createAsyncThunk(
    'processDetail/qc/deleteJobQCFile',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobQCApi.deleteJobQCFile(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateQCNote = createAsyncThunk(
    'processDetail/qc/updateQCNote',
    async (args: UpdateQCNote, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobQCApi.updateQCNote(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const completeJob = createAsyncThunk(
    'processDetail/qc/completeJob',
    async (args: CompleteJobRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobQCApi.completeJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const jobQCSlice = createSlice({
    name: 'jobQCState',
    initialState: {} as JobQCSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.rowsAffected = 0;
            state.uploadStatus = [];
            state.uploadProgress = [];
            state.deleteFileStatus = RequestStatus.pending;
            state.uploadStatus = [];
            state.uploadProgress = [];
        },
        setUploadProgress: (state, payload: PayloadAction<Array<UploadProgress>>) => {
            state.uploadProgress = payload.payload;
        },
        setUploadStatus: (state, payload: PayloadAction<Array<UploadStatus>>) => {
            state.uploadStatus = payload.payload;
        },
        updateUploadProgress: (state, payload: PayloadAction<UploadProgress>) => {
            let progress = state.uploadProgress ?? [];
            let objIndex = state.uploadProgress?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            progress[objIndex as number].progress = payload.payload.progress;
            state.uploadProgress = progress;
        },
        updateUploadStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].status = payload.payload.status;
            state.uploadStatus = status;
        },
        updateCreateStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].createStatus = payload.payload.createStatus;
            state.uploadStatus = status;
        },
    },
    extraReducers: (builder) => {
        // create job qc file
        builder.addCase(createJobQCFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createJobQCFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createJobQCFile.rejected, (state) => {
            state.isLoading = false;
        });
        // get job qc file
        builder.addCase(getJobQCFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getJobQCFile.fulfilled, (state, { payload }) => {
            state.jobQCFile = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getJobQCFile.rejected, (state) => {
            state.isLoading = false;
        });
        // delete job qc file
        builder.addCase(deleteJobQCFile.pending, (state) => {
            state.deleteFileStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteJobQCFile.fulfilled, (state) => {
            state.deleteFileStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteJobQCFile.rejected, (state, { payload }) => {
            state.deleteFileStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        builder.addCase(updateQCNote.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateQCNote.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateQCNote.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        builder.addCase(completeJob.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(completeJob.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(completeJob.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const {
    reset,
    setUploadProgress,
    setUploadStatus,
    updateUploadProgress,
    updateUploadStatus,
    updateCreateStatus
} = jobQCSlice.actions;
export default jobQCSlice.reducer;