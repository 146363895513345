import React from 'react';
import EmployeeTable from '../components/EmployeeTable';
import BasicHeaderLayout from 'components/Layout/BasicHeaderLayout';

const EmployeePage = () => {
    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <BasicHeaderLayout
                path={'/employee/detail'}
                pageTitle="Employee"
                buttonTitle="New Employee"
            />
            <div className="mt-3">
                <EmployeeTable />
            </div>
        </div>
    );
};

export default EmployeePage;
