import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';

import {createPosition, getSingle, reset, updatePosition} from '../position.reducer';
import PositionForm from '../components/PositionForm';
import {InputModel, UpdatePosition} from '../models';
import { HttpClientError, RequestStatus } from '../../../../http-client';
import {useNavigate, useParams} from "react-router-dom";
import {savedStatusOption} from "../../../../helpers/alertLabel";

const PositionDetailPage = ({isEdit = false}: PositionDetailPageProps) => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { id } = useParams();
    const MySwal = withReactContent(Swal)

    const isLoading = useAppSelector((state: RootState) => state.position.isLoading ?? false);
    const status = useAppSelector((state: RootState) => state.position.status);
    const error = useAppSelector((state: RootState) => state.position.error ?? ({} as HttpClientError));

    useEffect(() => {
        if (isEdit && id !== undefined) {
            dispatch(getSingle(id as string));
        } else {
            dispatch(reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isEdit]);

    const onSubmit = (formInput: InputModel) => {
        if (isEdit) {
            let _input = Object.assign({} as UpdatePosition, formInput);
            _input.id = id as string;
            dispatch(updatePosition(_input));
        } else {
            dispatch(createPosition(formInput));
        }
        dispatch(reset());
    };

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                return navigate("/position", {replace: true});
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <h1 className="text-lg leading-10 dark:text-gray-300 grow font-medium truncate mr-5">Position Form</h1>

            <PositionForm isLoading={isLoading} isEdit={isEdit} onSubmit={onSubmit} />
        </div>
    );
};

export interface PositionDetailPageProps {
    isEdit: boolean;
}

export default PositionDetailPage;
