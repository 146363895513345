import React from 'react';
import { HiTrash } from 'react-icons/hi';
import {FileAttachment} from "types";
import {BiCloudDownload} from "react-icons/bi";
import {IoDocumentAttach} from "react-icons/io5";
import FileAttachmentApi from "../../../basic/fileAttachment/fileAttachment.api";
const BillJobFile = ({files = [], isReadOnly = false, ...props}: JobFileProps) => {

    return (
        <section className={
                files?.length > 0 ? "mt-3 border-[1px] rounded-lg border-gray-300 border-solid max-h-[300px] overflow-x-auto" : ""
            }
        >
            {files?.map((file: FileAttachment, index) => (
                <div key={file.id}>
                    <div className="flex p-3">
                        <IoDocumentAttach size={24} className='text-primary-color' />
                        <div className="ml-3 flex-grow">
                            <div className="truncate max-w-[250px]">
                                <span className="text-sm">{file.name}</span>
                            </div>
                        </div>
                        {
                            file.path && (
                                <div
                                    className="flex px-2 justify-center items-center cursor-pointer"
                                >
                                    <a
                                        href={FileAttachmentApi.getMediaEndpoint(file.path ?? "")}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <BiCloudDownload color="#00bdfe" size={20}/>
                                    </a>
                                </div>
                            )
                        }
                        {
                            !isReadOnly &&
                            <div
                                onClick={() => props.onFileRemove?.(file.id)}
                                className="flex px-2 justify-center items-center cursor-pointer"
                            >
                                <HiTrash color="#ff6961" size={18}/>
                            </div>
                        }
                    </div>
                    <hr/>
                </div>
            ))}
        </section>
    );
};

interface JobFileProps {
    files?: Array<FileAttachment>;
    isReadOnly?: boolean;
    onFileRemove?: (fileId: string) => void;
    onFileDelete?: (fileId: string) => void;
}

export default BillJobFile;
