import React, {useCallback, useEffect, useRef, useState} from 'react';
import Text from '../../../../../components/Text/Text';
import {useAppDispatch, useAppSelector} from '../../../../../helpers/redux';
import {RootState} from '../../../../../app/store';
import {useForm} from 'react-hook-form';
import Button from '../../../../../components/Button/Button';
import {mreTypeOptions} from '../constants';
import {HttpClientError, RequestStatus} from '../../../../../http-client';
import {
    customWarnOption,
    savedStatusOption,
} from '../../../../../helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import SelectInput from '../../../../../components/SelectInput/SelectInput';
import {
    InputAssignMRE,
    MREEmployee,
    MREModel,
    PoleMREEnum,
} from '../models';
import {
    assignQCMRE,
    getQCMRESummary,
    getSingleMRE,
    reset
} from '../processMRE.reducer';
import {getListByPosition} from '../../../../master/employee/employee.reducer';
import {SingleValue} from 'react-select';
import BasicSelectInput, {
    Option,
} from '../../../../../components/SelectInput/BasicSelectInput';
import {MdOutlineDeleteForever} from 'react-icons/md';
import DatePickerState from '../../../../../components/DatePicker/DatePickerState';
import {
    isArrayEmpty, isBlank,
    isObjectEmpty,
} from '../../../../../helpers/checkEmptiness';
import InputCheckbox from '../../../../../components/Checkbox/InputCheckbox';
import {
    getPoleMREByJob,
    removeMultiplePoleAssign,
    removePoleAssign,
    reset as resetPole
} from '../../../../basic/pole/pole.reducer';
import {useSelected} from '../../../../../hooks/checkbox/useSelected';
import {useShiftSelected} from '../../../../../hooks/checkbox/useShiftSelected';
import {UserCredential} from "../../../../login/models";
import {PoleAssignmentTypeEnum, PoleMRE} from "../../../../basic/pole/models";
import AgDataGridStatic from "../../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import moment from "moment/moment";
import FormLoader from "../../../../../components/Loader/FormLoader";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";

const AssignQCMRE = ({id = undefined, ...props}: AssignQCMREProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const {
        register,
        setValue,
        watch,
        reset: resetForm,
        formState: {errors},
    } = useForm<InputAssignMRE>({
        defaultValues: {
            employee_id: '',
            employee_name: '',
            mre_type: '0',
        }
    });

    const [selectedRemove, setSelectedRemove] = useState<Array<string>>([]);
    const [userAssignMRE, setUserAssignMRE] = useState<Array<MREEmployee>>([]);
    const [dueDate, setDueDate] = useState<Date>(new Date());
    const [selection, setSelection] = useState<Array<string>>([]);
    const [initialPoleIds, setInitialPoleIds] = useState<Array<string>>([]);

    const employeeIdWatcher = watch('employee_id');
    const employeeNameWatcher = watch('employee_name');
    const mreTypeWatcher = watch('mre_type');

    const job = useAppSelector((state: RootState) => state.processMRE.singleMRE ?? {} as MREModel);
    const isQCMRELoading = useAppSelector((state: RootState) => state.processMRE.isSingleLoading);
    const qcMRESummaryList = useAppSelector((state: RootState) => state.processMRE.qcMRESummaryList ?? []);
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector((state: RootState) => state.processMRE.status);
    const error = useAppSelector((state: RootState) => state.processMRE.error ?? ({} as HttpClientError));
    const employeeOptions = useAppSelector((state: RootState) => state.employee.listByPositionOptions ?? []);
    const isLoading = useAppSelector((state: RootState) => state.employee.isLoading);
    const pole = useAppSelector(
        (state: RootState) => state.pole.poleMREByJob ?? []
    );
    const statusPole = useAppSelector((state: RootState) => state.pole.status);
    const errorPole = useAppSelector((state: RootState) => state.pole.error ?? ({} as HttpClientError));
    const isPoleLoading = useAppSelector((state: RootState) => state.pole.isLoading);

    const {selected, change, clear} = useSelected([] as Array<string>);
    const onChange = useShiftSelected(initialPoleIds, change);

    useEffect(() => {
        dispatch(getListByPosition({skip: 0, take: 100, position: 'QC MRE'}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleMRE(id));
            dispatch(getQCMRESummary(id));
        }
    }, [id]);

    useEffect(() => {
        if (!isObjectEmpty(job)) {
            dispatch(getPoleMREByJob({job_id: job.id as string, skip: 0, take: 1000}));
        }
    }, [job]);

    useEffect(() => {
        if (!isObjectEmpty(job)) {
            let employeeList: Array<MREEmployee> = job.employee_prep_list ?? [];
            let selectedPoleIds: string[] = [];

            const defaultEmployeeList = employeeList.filter((item) => item.type === parseInt(mreTypeWatcher) && item.status === PoleMREEnum.QC);
            defaultEmployeeList.map((item: MREEmployee) => {
                selectedPoleIds = [...selectedPoleIds, item.pole_ids ?? ""];
            });
            let _employeeList = pole.map((item: PoleMRE) => {
                const singleEmployee = defaultEmployeeList.find((employee: MREEmployee) => employee.pole_ids === item.id) ?? {} as MREEmployee;
                return {
                    employee_id: singleEmployee?.employee_id ?? undefined,
                    employee_name: singleEmployee?.employee_name ?? undefined,
                    pole_ids: item.id,
                    pole_sequences: singleEmployee?.pole_sequences ?? undefined,
                    isStatic: isObjectEmpty(singleEmployee)
                }
            });
            setUserAssignMRE(_employeeList);
            setSelection([...selectedPoleIds]);

            setDueDate(new Date(job.date_due));
        }
    }, [job, pole, mreTypeWatcher]);

    useEffect(() => {
        if (!isArrayEmpty(pole)) {
            const initialPoleIds = pole
                .filter(item => userAssignMRE.some(_item => _item.pole_ids === item.id && _item.isStatic))
                .map(item => item.id);
            setInitialPoleIds(initialPoleIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pole, userAssignMRE]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                resetForm();
                props.onHide();
                clear();
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error, user]);

    useEffect(() => {
        if (statusPole !== undefined && statusPole !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(statusPole === RequestStatus.success, errorPole.data?.message)).then(() => {
                resetForm();
                dispatch(resetPole());
                setSelectedRemove([]);
                props.onHide();
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusPole, errorPole]);

    const onSaveAssign = () => {
        if (isBlank(employeeIdWatcher) || isArrayEmpty(selected)) return null;
        MySwal.fire(
            customWarnOption(
                'Assign Job',
                'Are you sure? You will not be able to recover this data!',
            )
        ).then((result) => {
            let _ids = selected.map((item) => item);

            let _data = [{
                employee_id: employeeIdWatcher,
                pole_list: _ids?.map((item) => {
                    return {
                        pole_id: item,
                        type: parseInt(mreTypeWatcher),
                    }
                }),
            }];

            if (result.isConfirmed) {
                dispatch(assignQCMRE({
                    id: job.id,
                    date_due: dueDate.toISOString(),
                    data: _data,
                }))
            }
        });
    }

    const onEmployeeChange = (val: SingleValue<Option>) => {
        setValue('employee_id', val?.value ?? '');
        setValue('employee_name', val?.text ?? '');
    };

    const onDelete = (poleId: string) => {
        MySwal.fire(
            customWarnOption(
                'Remove Get',
                'Are you sure you want to remove this GET? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(removePoleAssign({
                    poleId: poleId,
                    type: PoleAssignmentTypeEnum.mre_qc
                }));
            }
        });
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <>
                {
                    !props.data?.isStatic &&
                    <>
                        {
                            user.user_id === props.data?.employee_id &&
                            <div className="flex align-middle h-[100%]">
                                <button type="button" onClick={() => onDelete(props.data?.pole_ids ?? "")}>
                                    <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                                </button>
                            </div>
                        }
                    </>
                }
            </>

        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            width: 30,
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'employee_name', headerName: 'Name', filter: 'agTextColumnFilter'},
        {field: 'pole_sequences', headerName: 'Pole Seq', filter: 'agTextColumnFilter'},
        {
            headerName: 'Actions',
            field: 'actions',
            suppressCellFlash: true,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
        },
    ]);

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, poleId: string) => {
        if (isBlank(employeeIdWatcher)) return null;
        onChange(event, poleId);
    }

    const onSelectionChanged = useCallback(() => {
        let selectedRows = gridRef.current.api.getSelectedNodes();

        let _selectedRows = selectedRows
            .filter((item: any) => item.data.employee_id === user.user_id)
            .map((item: any) => item.data.pole_ids);
        setSelectedRemove(_selectedRows);
    }, []);

    const onUnassignHandler = () => {
        const _multipleSelection = selectedRemove.map((item) => {
            return {
                poleId: item,
                type: PoleAssignmentTypeEnum.mre_qc
            }
        });
        MySwal.fire(
            customWarnOption(
                'Remove Assign',
                'Are you sure you want to remove this Assign? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeMultiplePoleAssign(_multipleSelection));
            }
        });
    }

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <FormLoader isLoading={isQCMRELoading || isPoleLoading} label="Fetching Data ...">
                <form>
                <div className="flex">
                    <div className="w-1/2 px-1">
                        <Text label="Job Number" value={job.number_job} suppressBorder/>
                        <Text label="District" value={job.district_name} suppressBorder/>
                    </div>
                    <div className="w-1/2 px-1">
                        <Text label="Type" value={job.type_name} suppressBorder/>
                        <BasicSelectInput
                            label="MRE Type"
                            options={mreTypeOptions}
                            message={errors.mre_type?.message}
                            {...register('mre_type')}
                        />
                    </div>
                </div>
                <div className="flex">
                    <div className="w-1/2 px-1 pt-2.5">
                        <SelectInput
                            key={`my_unique_select_key__user`}
                            options={employeeOptions}
                            isLoading={isLoading}
                            value={{
                                text: employeeNameWatcher,
                                label: employeeNameWatcher,
                                value: employeeIdWatcher
                            } as Option}
                            label="MRE"
                            onChange={onEmployeeChange}
                            message={errors.employee_id?.message}
                        />
                    </div>
                    <div className="w-1/2 px-1">
                        <DatePickerState
                            label="Due Date"
                            name="date_due"
                            placeholderText="Select Due Date"
                            selected={dueDate}
                            onChange={(date) => {
                                setDueDate(date ?? new Date());
                            }}
                        />

                    </div>
                </div>

                <div className="h-[36vh] my-5 border border-[#dfdfdf] rounded-lg">
                    <div className="h-[40px] bg-[#e7ebe6] rounded-t-lg flex items-center">
                        <label className="block text-sm font-bold text-gray-700 dark:text-gray-300 px-3">
                            Poles
                        </label>
                    </div>
                    <div className="h-[calc(36vh-50px)] overflow-auto">
                        <div className="grid md:grid-cols-4 gap-4 p-3">
                            {
                                pole.map((item, index) => (
                                    <div key={item.id} className={"flex"}>
                                        <label
                                            className={`mr-2 text-sm font-bold text-gray-900 dark:text-gray-300`}
                                            htmlFor={item.id}
                                        >
                                            {`${index + 1}.`}
                                        </label>
                                        <InputCheckbox
                                            className="my-3"
                                            label={item.seq ?? ""}
                                            id={item.id}
                                            onChange={(event) => onCheckboxChange(event, item.id)}
                                            checked={selection.includes(item.id) || selected.includes(item.id)}
                                            picked={userAssignMRE.some(_item => _item.pole_ids === item.id && !_item.isStatic)}
                                            // disabled={selection.includes(item.id) || userAssignMRE.some(_item => _item.pole_ids === item.id && _item.isStatic)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="h-[36vh] my-5 border border-[#dfdfdf] rounded-lg">
                    <div className="h-[40px] bg-[#e7ebe6] rounded-t-lg flex items-center">
                        <label className="block text-sm font-bold text-gray-700 dark:text-gray-300 px-3">
                            Summary
                        </label>
                    </div>
                    <div className="h-[calc(36vh-40px)] overflow-auto">
                        <div className="flex">
                            <div className="w-1/2 overflow-auto h-[276px]">
                                {
                                    qcMRESummaryList.map((item) => (
                                        <div
                                            className="flex h-[40px] p-4 items-center text-xs border border-[#dfdfdf] hover:bg-[#fcebe0]">
                                            <div className="w-1/3 truncate">
                                                <b>{item.employee_name}</b>
                                            </div>
                                            <div className="w-1/3">
                                                <span className="text-[#919191]">Total Poles </span>
                                                <b>{item.total}</b>
                                            </div>
                                            <div className="w-1/3">
                                                    <span
                                                        className="text-[#919191]">Date </span><b>{moment(item.date_due).format("D MMM YYYY")}</b>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="w-1/2 h-[calc(36vh-40px)]">
                                <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={userAssignMRE} tableDimension={{
                                    width: '100%',
                                    height: '100%'
                                }} containerClass={"h-[calc(36vh-96px)]"} defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    flex: 1,
                                    minWidth: 50,
                                    wrapHeaderText: true,
                                    wrapText: true,
                                    autoHeight: true
                                }}  isRowSelectable={() => true} onSelectionChanged={onSelectionChanged}
                                                  isCustomHeader={true} customChildHeaderComponent={
                                    <>
                                        <FlowbiteButton
                                            onClick={onUnassignHandler}
                                            size="xs"
                                            color="success"
                                            disabled={selectedRemove.length === 0}
                                        >
                                            <MdOutlineDeleteForever className="mr-2 h-5 w-5"/>
                                            Unassign Selected ({selectedRemove.length})
                                        </FlowbiteButton>
                                    </>
                                }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end">
                    <Button
                        color="success"
                        type="button"
                        title="Submit"
                        onClick={onSaveAssign}
                    />
                </div>
            </form>
            </FormLoader>
        </div>
    );
};

export interface AssignQCMREProps {
    // job: MREModel;
    id?: string;
    onHide: () => void;
}

export default AssignQCMRE;
