import React, {useEffect} from 'react';
import CreateBillJobForm from "../components/CreateBillJobForm";
import CreateBillJobTable from "../components/CreateBillJobTable";
import {reset, resetSingle} from "../billJob.reducer";
import {useAppDispatch} from "../../../../helpers/redux";

const CreateBillJobPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(reset());
        dispatch(resetSingle());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Bill Job</h1>
                </div>
                <div className="mt-3">
                    <CreateBillJobForm />
                    <CreateBillJobTable />
                </div>
            </div>
        </div>

    );
};

export default CreateBillJobPage;
