import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {useAppDispatch} from 'helpers/redux';
import {isObjectEmpty} from 'helpers/checkEmptiness';
import {GlobalApprovalEnum} from "../../../../types";
import {AttendanceModel, InputAttendance} from "../../models";
import BasicDatePicker from "../../../../components/DatePicker/BasicDatePicker";
import {dateSetTimeToNow} from "../../../../helpers/convert";
import {createAttendance, updateAttendance} from "../../profile.reducer";

const AttendanceApprovalForm = ({
                                    singleAttendance = {} as AttendanceModel,
                                    userId = '',
                                    isEdit = false,
                                }: AttendanceApprovalFormProps) => {
    const dispatch = useAppDispatch();
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<InputAttendance>({
        defaultValues: {
            reqMinute: '0',
            leaveDuration: '0',
            absentDate: dateSetTimeToNow(new Date().toISOString()) ?? new Date().toISOString(),
        }
    });

    useEffect(() => {
        if (!isObjectEmpty(singleAttendance)) {
            reset({
                reqMinute: singleAttendance.reqMinute.toString(),
                notes: singleAttendance.notes,
                absentDate: singleAttendance.absentDate,
                leaveDuration: singleAttendance.leaveDuration.toString(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleAttendance]);

    const onSubmit = (data: InputAttendance) => {
        let _data = {
            reqMinute: parseInt(data.reqMinute) ?? 0,
            leaveDuration: parseInt(data.leaveDuration) ?? 0,
            notes: data.notes ?? '',
            rejectionNotes: '',
            status: GlobalApprovalEnum.Requested,
            approveMinute: 0,
            isDeducted: false,
            isRefNote: false,
            userId: userId,
            absentDate: data.absentDate,
        };
        if (isEdit) {
            let update = {..._data, id: singleAttendance?.id}
            dispatch(updateAttendance(update));
        } else {
            dispatch(createAttendance(_data));
        }
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <BasicDatePicker
                    label="Absent Date"
                    name="absentDate"
                    placeholderText="Select Absent Date"
                    control={control}
                    withPreciseTime
                />
                <InputText
                    {...register('reqMinute')}
                    label="Req Minutes"
                    placeholder="Input req minutes"
                    type="number"
                    message={errors.reqMinute?.message}
                />
                <InputText
                    {...register('leaveDuration')}
                    label="Leave Duration"
                    placeholder="Input leave duration"
                    type="number"
                    message={errors.leaveDuration?.message}
                />
                <InputText
                    {...register('notes')}
                    label="Notes"
                    placeholder="Input notes"
                    message={errors.notes?.message}
                />

                <div className="pt-2 pb-2">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                    />
                </div>
            </form>
        </div>
    );
};

export interface AttendanceApprovalFormProps {
    singleAttendance?: AttendanceModel;
    userId?: string;
    isEdit?: boolean;
}

export default AttendanceApprovalForm;
