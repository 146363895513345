import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {
    AttendanceApprovalReq, GetAttendanceFilterReq, GetHolidayFilterReq,
    GetOvertimeFilterReq,
    HolidayApprovalReq,
    OvertimeApprovalReq,
    StaffReportApprovalReq
} from "./models";
import {AttendanceModel, HolidayModel, OvertimeModel} from "../profile/models";

export default class ApprovalApi extends BaseServiceClass {
    static changeStaffReportStatus(args: StaffReportApprovalReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_APPROVAL.change_staff_report_status, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static changeOvertimeStatus(args: OvertimeApprovalReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_APPROVAL.change_overtime_status, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static changeAttendanceStatus(args: AttendanceApprovalReq[], token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_APPROVAL.change_attendance_status, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static changeHolidayStatus(args: HolidayApprovalReq[], token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_APPROVAL.change_holiday_status, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static getOvertimeWithFilter(args: GetOvertimeFilterReq, token: string) {
        return httpClient.post<ApiResponseWrapper<OvertimeModel[]>>(this.PATH_APPROVAL.get_overtime_with_filter, args,{
            headers: {
                Authorization: `Bearer ${token}`
            },
            // params: args
        }).then((res) => res.data);
    }
    static getAttendanceWithFilter(args: GetAttendanceFilterReq, token: string) {
        return httpClient.post<ApiResponseWrapper<AttendanceModel[]>>(this.PATH_APPROVAL.get_attendance_with_filter, args,{
            headers: {
                Authorization: `Bearer ${token}`
            },
            // params: args
        }).then((res) => res.data);
    }
    static getHolidayWithFilter(args: GetHolidayFilterReq, token: string) {
        return httpClient.post<ApiResponseWrapper<HolidayModel[]>>(this.PATH_APPROVAL.get_holiday_with_filter, args,{
            headers: {
                Authorization: `Bearer ${token}`
            },
            // params: args
        }).then((res) => res.data);
    }
}