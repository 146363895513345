import {Option} from "../../../components/SelectInput/BasicSelectInput";
import {HolidayApprovalEnum} from "../../profile/models";
import {StaffReportApprovalColumnSort, StaffReportFilterStatusEnum} from "./models";
import {HolidayApprovalWithFilterColumnSort} from "../../approval/models";

export enum StaffReportStatusEnum {
    Requested,
    Approved,
    Rejected,
}

export enum StaffReportTypeEnum {
    Add,
    Update,
    Revision,
    Remove,
}

export const typeOptions = [
    { text: 'Add', value: StaffReportTypeEnum.Add.toString(), label: 'Add', },
    { text: 'Update', value: StaffReportTypeEnum.Update.toString(), label: 'Update', },
    { text: 'Revision', value: StaffReportTypeEnum.Revision.toString(), label: 'Revision', },
    { text: 'Remove', value: StaffReportTypeEnum.Remove.toString(), label: 'Remove', },
] as Option[];

export const staffReportFilterStatusOption = [
    {
        label: "None",
        text: "None",
        value: "none"
    },
    {
        label: "Requested",
        text: "Requested",
        value: StaffReportFilterStatusEnum.Requested.toString()
    },
    {
        label: "Approved by Head",
        text: "Approved by Head",
        value: StaffReportFilterStatusEnum.ApprovedByHead.toString()
    },
    {
        label: "Manager Approval",
        text: "Manager Approval",
        value: StaffReportFilterStatusEnum.ManagerApproval.toString()
    },
    {
        label: "Rejected",
        text: "Rejected",
        value: StaffReportFilterStatusEnum.Rejected.toString()
    }
]

export const staffReportStatusFilterOptions = [
    {
        displayKey: 'all',
        displayName: 'All',
        predicate: 'all',
        numberOfInputs: 0,
    },
    {
        displayKey: StaffReportFilterStatusEnum.Requested.toString(),
        displayName: 'Requested',
        predicate: StaffReportFilterStatusEnum.Requested.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StaffReportFilterStatusEnum.ApprovedByHead.toString(),
        displayName: 'Approved by Head',
        predicate: StaffReportFilterStatusEnum.ApprovedByHead.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StaffReportFilterStatusEnum.ManagerApproval.toString(),
        displayName: 'Manager Approval',
        predicate: StaffReportFilterStatusEnum.ManagerApproval.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StaffReportFilterStatusEnum.Rejected.toString(),
        displayName: 'Rejected',
        predicate: StaffReportFilterStatusEnum.Rejected.toString(),
        numberOfInputs: 0,
    },
]

export const staffReportFilterTypeOption = [
    {
        label: "None",
        text: "None",
        value: "none"
    },
    {
        label: "Add",
        text: "Add",
        value: StaffReportTypeEnum.Add.toString()
    },
    {
        label: "Update",
        text: "Update",
        value: StaffReportTypeEnum.Update.toString()
    },
    {
        label: "Revision",
        text: "Revision",
        value: StaffReportTypeEnum.Revision.toString()
    },
    {
        label: "Remove",
        text: "Remove",
        value: StaffReportTypeEnum.Remove.toString()
    }
]

export const staffReportTypeFilterOptions = [
    {
        displayKey: 'all',
        displayName: 'All',
        predicate: 'all',
        numberOfInputs: 0,
    },
    {
        displayKey: StaffReportTypeEnum.Add.toString(),
        displayName: 'Add',
        predicate: StaffReportTypeEnum.Add.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StaffReportTypeEnum.Update.toString(),
        displayName: 'Update',
        predicate: StaffReportTypeEnum.Update.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StaffReportTypeEnum.Revision.toString(),
        displayName: 'Revision',
        predicate: StaffReportTypeEnum.Revision.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: StaffReportTypeEnum.Remove.toString(),
        displayName: 'Remove',
        predicate: StaffReportTypeEnum.Remove.toString(),
        numberOfInputs: 0,
    },
]

export const staffReportSortOption = [
    {
        label: "requestDate",
        text: "requestDate",
        value: StaffReportApprovalColumnSort.requestDate.toString()
    },
    {
        label: "typeName",
        text: "typeName",
        value: StaffReportApprovalColumnSort.typeName.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: StaffReportApprovalColumnSort.number_job.toString()
    },
    {
        label: "role_name",
        text: "role_name",
        value: StaffReportApprovalColumnSort.role_name.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: StaffReportApprovalColumnSort.statusName.toString()
    }
]


export type InputStaffReportFilter = {
    startDate: string;
    endDate: string;
    status: string;
    type: string;
    filter: string;
}