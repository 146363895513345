import {HttpClientError, RequestStatus} from "../../../http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import {
    AbsentLogDetailModel,
    AbsentLogModel, AttendanceExportReq, GetAttendanceDetailLogReq, GetAttendanceLogReq,
    GetHolidayReportReq,
    GetOvertimeReportReq,
    HolidayModel,
    OvertimeModel
} from "./models";
import AttendanceReportApi from "./attendanceReport.api";
import {CalendarEvent} from "../../../components/Calendar/MyCalendar";
import moment from "moment/moment";
import FileSaver from "file-saver";

export interface AttendanceReportSlice {
    overtimeReportList?: Array<OvertimeModel>;
    holidayReportList?: Array<HolidayModel>;
    absentReportList?: Array<AbsentLogModel>;
    absentReportDetail?: AbsentLogDetailModel;
    absentReportEventDetail?: CalendarEvent[];
    isLoading?: boolean;
    status?: RequestStatus;
    changeStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getOvertimeReport = createAsyncThunk(
    'attendanceReport/getOvertimeReport',
    async (args: GetOvertimeReportReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await AttendanceReportApi.getOvertimeReport(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getHolidayReport = createAsyncThunk(
    'attendanceReport/getHolidayReport',
    async (args: GetHolidayReportReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await AttendanceReportApi.getHolidayReport(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getAttendanceLog = createAsyncThunk(
    'attendanceReport/getAttendanceLog',
    async (args: GetAttendanceLogReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await AttendanceReportApi.getAttendanceLog(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const changeHolidayToRequest = createAsyncThunk(
    'attendanceReport/changeHolidayToRequest',
    async (id: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await AttendanceReportApi.changeHolidayToRequest(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getAttendanceLogDetail = createAsyncThunk(
    'attendanceReport/getAttendanceLogDetail',
    async (args: GetAttendanceDetailLogReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await AttendanceReportApi.getAttendanceLogDetail(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const attendanceExport = createAsyncThunk(
    'attendanceReport/attendanceExport',
    async (args: AttendanceExportReq, {getState, rejectWithValue}) => {
        const {userAuth} = (getState() as RootState).user;
        try {
            return await AttendanceReportApi.attendanceExport(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const attendanceReportSlice = createSlice({
    name: 'attendanceReportState',
    initialState: {} as AttendanceReportSlice,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.status = undefined;
            state.changeStatus = undefined;
            state.error = undefined;
        }
    },
    extraReducers: builder => {
        builder.addCase(getOvertimeReport.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOvertimeReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.overtimeReportList = action.payload?.data;
        });
        builder.addCase(getOvertimeReport.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getHolidayReport.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getHolidayReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.holidayReportList = action.payload?.data;
        });
        builder.addCase(getHolidayReport.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getAttendanceLog.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAttendanceLog.fulfilled, (state, action) => {
            state.isLoading = false;
            state.absentReportList = action.payload?.data;
        });
        builder.addCase(getAttendanceLog.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(changeHolidayToRequest.pending, (state) => {
            state.isLoading = true;
            state.changeStatus = RequestStatus.pending;
        });
        builder.addCase(changeHolidayToRequest.fulfilled, (state) => {
            state.isLoading = false;
            state.changeStatus = RequestStatus.success;
        });
        builder.addCase(changeHolidayToRequest.rejected, (state, action) => {
            state.isLoading = false;
            state.changeStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getAttendanceLogDetail.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAttendanceLogDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.absentReportDetail = action.payload?.data;
            state.absentReportEventDetail = action.payload?.data?.details?.map((item) => {
                return {
                    title: item.time.toString(),
                    start: moment(item.date).toDate(),
                    end: moment(item.date).toDate(),
                    allDay: true,
                }
            });
        });
        builder.addCase(getAttendanceLogDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // export to csv download reducer
        builder.addCase(attendanceExport.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(attendanceExport.fulfilled, (state, {payload}) => {
            FileSaver.saveAs(
                payload,
                'attendance-report' + new Date().toISOString() + '.xlsx'
            );
            state.isLoading = false;
        });
        builder.addCase(attendanceExport.rejected, (state, {payload}) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});

export const {reset} = attendanceReportSlice.actions;
export default attendanceReportSlice.reducer;