import { Navigate, useLocation } from 'react-router-dom';
import { AiOutlineBarChart } from 'react-icons/ai';
import { InputModel } from '../models';
import LoginForm from '../components/LoginForm';
import {login, resetError} from '../login.reducer';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import {useEffect} from "react";
import {IS_WARNING_SHOWN, LSK_USER_SLICE} from "../../../constants";

const LoginPage = () => {
    let location = useLocation();
    const dispatch = useAppDispatch();
    const userState = useAppSelector((state: RootState) => state.user.userAuth);

    useEffect(() => {
        dispatch(resetError());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (formInput: InputModel) => {
        dispatch(resetError());
        dispatch(login(formInput));
        localStorage.setItem(IS_WARNING_SHOWN, "1");
    };

    if (userState?.id) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return (
        <div className="h-screen bg-background-login dark:bg-background-login-dark">
            <div className="md:w-6/12 xl:w-5/12 2xl:w-4/12  h-screen bg-white dark:border-gray-700 dark:bg-gray-800 p-12">
                <div>
                    <h1 className="text-3xl font-bold dark:text-gray-300">
                        <span className="text-primary-color">
                            <AiOutlineBarChart className="inline text-5xl" />
                            Quality
                        </span>{' '}
                        Management <small className="text-sm">v2</small>
                    </h1>
                    <h2 className="pt-2 text-sm text-gray-600 dark:text-gray-400">
                        Gunakan akun kamu untuk masuk
                    </h2>
                </div>

                <div className="pt-5">
                    <LoginForm onSubmit={onSubmit} />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
