import {BaseServiceClass} from "../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../http-client";
import {
    BillJob,
    BillJobDetail, BillJobRequest,
    GetBillJob, GetWeekendDate,
    RequestBillJob,
    SelectedJobData,
    StatusBillEnum,
    TypeBillEnum
} from "../models";
import {CreateJob} from "../../worksheet/process/job/models";
import {InputFilter} from "../../report/jobReport/models";

export default class BillJobApi extends BaseServiceClass {
    static getJobData(jobId: string, token: string) {
        return httpClient.get<ApiResponseWrapper<SelectedJobData>>(this.PATH_BILL_JOB.get_job_data, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: jobId,
            }
        }).then((res) => res.data);
    }
    static getStatusByType(type: TypeBillEnum, token: string) {
        return httpClient.get<ApiResponseWrapper<StatusBillEnum[]>>(this.PATH_BILL_JOB.get_status_by_type, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                type: type,
            }
        }).then((res) => res.data);
    }

    static createBillJob(args: RequestBillJob, token: string) {
        return httpClient.post<ApiResponseWrapper<string>>(this.PATH_BILL_JOB.bill_job, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
    static getBillJob(args: GetBillJob, token: string) {
        return httpClient.get<ApiResponseWrapper<Array<BillJob>>>(this.PATH_BILL_JOB.bill_job, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getBillJobWithFilter(args: BillJobRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<Array<BillJob>>>(this.PATH_BILL_JOB.bill_job_with_filter, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
    static getSingleBillJob(args: GetBillJob, token: string) {
        return httpClient.get<ApiResponseWrapper<BillJob>>(this.PATH_BILL_JOB.bill_job, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getBillByJob(jobId: string, token: string) {
        return httpClient.get<ApiResponseWrapper<Array<BillJob>>>(this.PATH_BILL_JOB.get_bill_by_job, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: jobId
            }
        }).then((res) => res.data);
    }
    static getBillByJobNumber(jobId: string, token: string) {
        return httpClient.get<ApiResponseWrapper<Array<BillJob>>>(this.PATH_BILL_JOB.get_bill_by_job_number, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                job_id: jobId
            }
        }).then((res) => res.data);
    }
    static updateBillJob(args: RequestBillJob, token: string) {
        return httpClient.put<ApiResponseWrapper<string>>(this.PATH_BILL_JOB.bill_job, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
    static deleteBillJob(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<string>>(this.PATH_BILL_JOB.bill_job, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
    static getBillJobDetail(numberJob: string, token: string) {
        return httpClient.get<ApiResponseWrapper<Array<BillJobDetail>>>(this.PATH_BILL_JOB.get_bill_job_detail, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                number_job: numberJob,
            }
        }).then((res) => res.data);
    }

    static getWeekendDate(args: GetWeekendDate, token: string) {
        return httpClient.get<ApiResponseWrapper<Array<string>>>(this.PATH_BILL_JOB.get_weekend_date, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static exportBillJob(args: BillJobRequest, token: string) {
        const path = "/api/BillJob/ExportBillJobDataToExcel"
        return httpClient
            .post<any>(`${this.BASE_URL}${path}`, args, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }
}