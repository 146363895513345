import React from 'react';
import RequestDocumentTable from "../components/RequestDocumentTable";
const RequestDocumentPage = () => {
    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="mt-3">
                <RequestDocumentTable />
            </div>
        </div>
    );
};

export default RequestDocumentPage;