import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import InputText from 'components/TextInput/InputText';
import {InputModel, Team} from '../models';
import Button from 'components/Button/Button';
import {schema} from "../constants";
import {useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import {isObjectEmpty} from "helpers/checkEmptiness";

const TeamForm = ({ isLoading = false, isEdit = false, ...props }: TeamFormProps) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<InputModel>({
        resolver: yupResolver(schema),
    });

    const singleState = useAppSelector((state: RootState) => state.team.single ?? {} as Team);

    useEffect(() => {
        if (!isObjectEmpty(singleState)) {
            reset(singleState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleState]);

    useEffect(() => {
        if (!isEdit) {
            reset({} as InputModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);


    const onSubmit = (data: InputModel) => props.onSubmit(data);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputText
                {...register('name')}
                label="Team Name"
                placeholder="Input team name"
                message={errors.name?.message}
            />
            <div className="pt-2 pb-2">
                <Button
                    color="success"
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                />
            </div>
        </form>
    );
};

export interface TeamFormProps {
    isLoading: boolean;
    isEdit: boolean;
    onSubmit: (formInput: InputModel) => void;
}

export default TeamForm;
