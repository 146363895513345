import {JobPrepColumnSort} from "../jobPrep/models";

export type QCType = {
    id: string;
    number_id: string;
    number_job: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string;
    category_name: string;
    type_id: string;
    type_name: string;
    district_id: string;
    district_name: string;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    kind_name: string;
    department_id: string;
    department_name: string;
    source_id: string;
    source_name: string;
    status_id: string;
    status_name: string;
    date_due_fielding: string;
    project_id: string;
    project_name: string;
    waiting_subject_id: string | null;
    waiting_subject_name: string | null;
    waiting_notes: string | null;
    waiting_ts: string;
    ts_created: string;
    cancel_notes: string | null;
    prep_notes: string | null;
    prep_reference_job_id: string | null;
    prep_reference_job_number_job: string;
    employee_prep_list: [];
    percent_prep: number;
    percent_cs: number;
    percent_qc: number;
    qc_notes: string | null;
    calcspec_notes: string | null;
    pole_total: number;
    department_owner_name: string;
    date_assigned_qc: string;
};

export type MainQCType = {
    id: string;
    table_id: string;
    number_id: string;
    number_job: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string;
    category_name: string;
    type_id: string;
    type_name: string;
    district_id: string;
    district_name: string;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    kind_name: string;
    department_id: string;
    department_name: string;
    source_id: string;
    source_name: string;
    status_id: string;
    status_name: string;
    date_due_fielding: string;
    project_id: string;
    project_name: string;
    waiting_subject_id: string | null;
    waiting_subject_name: string | null;
    waiting_notes: string | null;
    waiting_ts: string;
    ts_created: string;
    cancel_notes: string | null;
    prep_notes: string | null;
    prep_reference_job_id: string | null;
    prep_reference_job_number_job: string;
    employee_prep_list: [];
    percent_prep: number;
    percent_cs: number;
    percent_qc: number;
    qc_notes: string | null;
    calcspec_notes: string | null;
    pole_total: number;
    department_owner_name: string;
};

export type CSEmployeeGet = {
    employee_id: string,
    employee_name: string,
    seq_end: string,
    seq_start: string;
}

export type GetQCListRequest = {
    id?: string;
    skip?: string | number;
    take?: string | number;
    employee_id?: string;
};

export type InputAssignQC = {
    range_start: number;
    date_due: number;
    employee_id: string;
    employee_name: string;
}

export type AssignUserQC = {
    employee_id: string;
    range_start: number;
    range_end: number;
}

export type AssignQCRequest = {
    id: string;
    date_due: string;
    get_list: Array<AssignUserQC>;
}

export type GetQCRequest = {
    id: string;
    employee_id: string;
    date_due: string;
    pole_id_list: Array<string>;
}

export type UserAssignQC = {
    employee_id: string,
    employee_name: string,
    range_end: number,
    range_start: number;
}

export type InputStatusModel = {
    pause_subject_id?: string;
    notes?: string;
}

export type QCEmployeeGet = {
    employee_id: string,
    employee_name: string,
    seq_end: string,
    seq_start: string;
    pole_sequences: string;
    pole_ids: string;
}

export type EmployeeQC = {
    employee_id: string;
    employee_name: string;
    pole_ids: string;
    pole_sequences: string;
}

export type JobQCSummary = {
    employee_id: string;
    employee_name: string;
    total: number;
    date_due: string;
}

export enum QCColumnSort {
    project_name,
    department_name,
    kind_job,
    request_date,
    number_id,
    number_job,
    other_number,
    status,
    due_date,
    date_assign,
    req_pole,
}

export enum MainQCColumnSort
{
    project_name,
    department_name,
    kind_job,
    request_date,
    number_id,
    number_job,
    status,
    due_date,
    date_assign,
    req_pole
}


export enum SortingEnum {
    Ascending,
    Descending
}

export type GetQCWithFilterRequest = {
    project_name?: string;
    department_name?: string;
    kind_job?: string;
    startRequestDate?: string;
    endRequestDate?: string;
    number_id?: string;
    number_job?: string;
    other_number?: string;
    po_number?: string;
    status?: string;
    startDueDate?: string;
    endDueDate?: string;
    startDateAssign?: string;
    endDateAssign?: string;
    req_pole?: number;
    column?: QCColumnSort;
    sort?: SortingEnum;
    skip: number;
    take: number;
}

export type GetQCMainWithFilterRequest = {
    project_name?: string;
    department_name?: string;
    kind_job?: string;
    startRequestDate?: string;
    endRequestDate?: string;
    number_id?: string;
    number_job?: string;
    status?: string;
    startDueDate?: string;
    endDueDate?: string;
    startDateAssign?: string;
    endDateAssign?: string;
    req_pole?: number;
    column?: MainQCColumnSort;
    sort?: SortingEnum;
    employee_id?: string;
    skip: number;
    take: number;
}