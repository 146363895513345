import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {CreateJobType, GetListRequest, JobType, UpdateJobType} from "./models";

export default class JobTypeApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<JobType[]>>(`${this.BASE_URL}${this.PATH_MASTER.jobType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<JobType>>(`${this.BASE_URL}${this.PATH_MASTER.jobType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
            }
        }).then((res) => res.data);
    }

    static createJobType(args: CreateJobType, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobType}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateJobType(args: UpdateJobType, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobType}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deleteJobType(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
}