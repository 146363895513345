import React from 'react';
import MRETable from "../components/MRETable";
import MainMRETable from "../components/MainMRETable";

const MREPage = () => {
    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">MRE
                    </h1>
            </div>
            <div className="mt-3">
                <MRETable />
                <MainMRETable />
            </div>
        </div>
    );
};

export default MREPage;
