import {MRETypeEnum} from "../worksheet/process/mre/models";
import {GlobalApprovalEnum} from "../../types";
import {
    AttendanceProfileWithFilterColumnSort,
    HolidayOvertimeApprovalEnum,
    HolidayProfileWithFilterColumnSort, OvertimeProfileColumnSort
} from "./models";

export const multiplierOptions = [
    {
        text: '1',
        label: '1',
        value: '1',
    },
    {
        text: '1.5',
        label: '1.5',
        value: '1.5',
    },
    {
        text: '2',
        label: '2',
        value: '2',
    },
]

export const holidayOvertimeApprovalStatus = [
    {
        label: "Requested",
        value: HolidayOvertimeApprovalEnum.Requested
    },
    {
        label: "Manager Approved",
        value: HolidayOvertimeApprovalEnum.ManagerApproved
    },
    {
        label: "Approved",
        value: HolidayOvertimeApprovalEnum.Approved
    },
    {
        label: "Rejected",
        value: HolidayOvertimeApprovalEnum.Rejected
    }
]
// export enum OvertimeProfileColumnSort
// {
//     ts_created,
//     role,
//     req_minute,
//     notes,
//     rejection_note,
//     approved_time,
//     status,
//     approve_by,
//     manager_approval
// }

export const userAttendanceSortOption = [
    {
        label: "ts_created",
        text: "ts_created",
        value: AttendanceProfileWithFilterColumnSort.ts_created.toString()
    },
    {
        label: "absentDate",
        text: "absentDate",
        value: AttendanceProfileWithFilterColumnSort.absent_date.toString()
    },
    {
        label: "reqMinute",
        text: "reqMinute",
        value: AttendanceProfileWithFilterColumnSort.req_minute.toString()
    },
    {
        label: "leaveDuration",
        text: "leaveDuration",
        value: AttendanceProfileWithFilterColumnSort.leave_duration.toString()
    },
    {
        label: "notes",
        text: "notes",
        value: AttendanceProfileWithFilterColumnSort.notes.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: AttendanceProfileWithFilterColumnSort.status.toString()
    },
    {
        label: "approveMinute",
        text: "approveMinute",
        value: AttendanceProfileWithFilterColumnSort.approval_minute.toString()
    },
    {
        label: "rejectionNotes",
        text: "rejectionNotes",
        value: AttendanceProfileWithFilterColumnSort.rejection_note.toString()
    }
];

export const userHolidaySortOption = [
    {
        label: "ts_created",
        text: "ts_created",
        value: HolidayProfileWithFilterColumnSort.ts_created.toString()
    },
    {
        label: "reqDate",
        text: "reqDate",
        value: HolidayProfileWithFilterColumnSort.req_date.toString()
    },
    {
        label: "notes",
        text: "notes",
        value: HolidayProfileWithFilterColumnSort.note.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: HolidayProfileWithFilterColumnSort.status.toString()
    },
    {
        label: "rejectionNotes",
        text: "rejectionNotes",
        value: HolidayProfileWithFilterColumnSort.rejection_note.toString()
    },
    {
        label: "approvalByName",
        text: "approvalByName",
        value: HolidayProfileWithFilterColumnSort.approve_by.toString()
    },
    {
        label: "managerApprovedName",
        text: "managerApprovedName",
        value: HolidayProfileWithFilterColumnSort.manager_approval.toString()
    }
];

export const userOvertimeSortOption = [
    {
        label: "ts_created",
        text: "ts_created",
        value: OvertimeProfileColumnSort.ts_created.toString()
    },
    {
        label: "roleName",
        text: "roleName",
        value: OvertimeProfileColumnSort.role.toString()
    },
    {
        label: "reqMinute",
        text: "reqMinute",
        value: OvertimeProfileColumnSort.req_minute.toString()
    },
    {
        label: "notes",
        text: "notes",
        value: OvertimeProfileColumnSort.notes.toString()
    },
    {
        label: "rejectionNotes",
        text: "rejectionNotes",
        value: OvertimeProfileColumnSort.rejection_note.toString()
    },
    {
        label: "approveMinute",
        text: "approveMinute",
        value: OvertimeProfileColumnSort.approved_time.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: OvertimeProfileColumnSort.status.toString()
    },
    {
        label: "approveByName",
        text: "approveByName",
        value: OvertimeProfileColumnSort.approve_by.toString()
    },
    {
        label: "managerApproveByName",
        text: "managerApproveByName",
        value: OvertimeProfileColumnSort.manager_approval.toString()
    }
]