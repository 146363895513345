import {BooleanNumber} from "types";
export enum MRETypeEnum {
    MRE_P, MRE_E
}
export enum PoleMREEnum
{
    MRE, QC
}
export type MREModel = {
    id: string;
    number_id: string;
    number_job: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string;
    category_name: string;
    type_id: string;
    type_name: string;
    district_id: string;
    district_name: string;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    kind_name: string;
    department_id: string;
    department_name: string;
    source_id: string;
    source_name: string;
    status_id: string;
    status_name: string;
    date_due_fielding: string;
    project_id: string;
    project_name: string;
    waiting_subject_id: string;
    waiting_subject_name: null | string;
    waiting_notes: string;
    waiting_ts: string;
    ts_created: string;
    cancel_notes: string;
    prep_notes: string;
    prep_reference_job_id: string;
    prep_reference_job_number_job: string;
    employee_prep_list: Array<MREEmployee>;
    percent_prep: number;
    percent_cs: number;
    percent_qc: number;
    qc_notes: string;
    calcspec_notes: string;
    pole_total: number;
    mre_notes?: string;
    mre_qc_notes?: string;
    department_owner_name: string;
};

export type MainMREModel = {
    id: string;
    number_id: string;
    number_job: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string;
    category_name: string;
    type_id: string;
    type_name: string;
    district_id: string;
    district_name: string;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    kind_name: string;
    department_id: string;
    department_name: string;
    source_id: string;
    source_name: string;
    status_id: string;
    status_name: string;
    date_due_fielding: string;
    project_id: string;
    project_name: string;
    waiting_subject_id: string;
    waiting_subject_name: null | string;
    waiting_notes: string;
    waiting_ts: string;
    ts_created: string;
    cancel_notes: string;
    prep_notes: string;
    prep_reference_job_id: string;
    prep_reference_job_number_job: string;
    employee_prep_list: null | string;
    percent_prep: number;
    percent_cs: number;
    percent_qc: number;
    qc_notes: string;
    calcspec_notes: string;
    pole_total: number;
    department_owner_name: string;
};

export type CalcSpecType = {
    id: string;
    ts_created: string;
    number_id: string;
    number_job: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string;
    type_id: string | null;
    district_id: string;
    requestor: string;
    is_priority: BooleanNumber | null;
    is_mre_req: BooleanNumber | null;
    is_katapult: BooleanNumber | null;
    kind_id: string | null;
    department_id: string;
    source_id: string;
    status_id: string;
    date_due_fielding: string | null;
    project_id: string | null;
    notes: string | null;
    pause_note: string;
    pause_subject_id: string | null;
    pause_ts: string | null;
    pending_ts: string | null;
    notes_prep: string | null;
    reference_id: string | null;
    category_name: string;
    type_name: string | null;
    district_name: string;
    project_name: string | null;
    kind_name: string | null;
    department_name: string;
    source_name: string;
    status_name: string;
    pause_subject_name: string | null;
    file_list: string | null;
    email_list: string | null;
    pole_total: number;
    attachment_list: string;
    reference_jumber_job: string | null;
    file_prep_list: string | null;
    employee_get_list: string | null;
    cs_employee_get_list: string | null;
    cs_is_all_assigned: BooleanNumber | null;
};

export type CSEmployeeGet = {
    employee_id: string,
    employee_name: string,
    seq_end: string,
    seq_start: string;
}

export type GetMREListRequest = {
    skip?: string | number;
    take?: string | number;
    id?: string;
};

export type InputAssignMRE = {
    range_start: number;
    range_end: number;
    employee_id: string;
    employee_name: string;
    mre_type: string;
}

export type AssignUserCS = {
    employee_id: string;
    range_start: number;
    range_end: number;
}

export type MREPoleList = {
    pole_id: string;
    type: MRETypeEnum;
}

export type AssignUserMRE = {
    employee_id: string;
    pole_list: MREPoleList[];
}

export type AssignMRERequest = {
    id: string;
    date_due: string;
    data: AssignUserMRE[];
}

export type UserAssignMRE = {
    employee_id: string,
    employee_name: string,
    range_end: number,
    range_start: number;
}

export type MainCalcSpecType = {
    id: string;
    project_name: string;
    team_name: string;
    job_kind: string;
    req_date: string;
    id_job: string;
    number_job: string;
    status: string;
    due_date: string;
    request_pole: string;
}

export type InputStatusModel = {
    pause_subject_id?: string;
    notes?: string;
}

export type MREEmployee = {
    employee_id: string;
    employee_name: string;
    pole_ids?: string;
    pole_sequences: string;
    status?: PoleMREEnum;
    type?: MRETypeEnum;

    pole_id_list?: Array<string>;
    isStatic: boolean;
}

export type MREEmployeeGet = {
    employee_id: string;
    employee_name: string;
    pole_sequences: string;
    pole_ids?: string;

    pole_id_list: Array<string>;
    isStatic: boolean;
}

export type MREWaitingReq = {
    job_id: string;
    waiting_notes: string;
    waiting_subject_id: null | string;
}

export type MRECancelReq = {
    job_id: string;
    cancel_notes: string;
}

export type MRESummary = {
    employee_id: string;
    employee_name: string;
    total: number;
    date_due: string;
}

export enum MREColumnSort {
    project_name,
    department_name,
    kind_job,
    request_date,
    number_id,
    number_job,
    other_number,
    status,
    due_date,
    date_assign,
    req_pole,
    date_assign_qc_mre
}

export enum MainMREColumnSort
{
    project_name,
    department_name,
    kind_job,
    request_date,
    number_id,
    number_job,
    status,
    due_date,
    date_assign,
    req_pole,
    date_assign_qc_mre
}


export enum SortingEnum {
    Ascending,
    Descending
}

export type GetMREWithFilterRequest = {
    project_name?: string;
    department_name?: string;
    kind_job?: string;
    startRequestDate?: string;
    endRequestDate?: string;
    number_id?: string;
    number_job?: string;
    other_number?: string;
    po_number?: string;
    status?: string;
    startDueDate?: string;
    endDueDate?: string;
    startDateAssign?: string;
    endDateAssign?: string;
    startDateQCMREAssign?: string;
    endDateQCMREAssign?: string;
    req_pole?: number;
    column?: MREColumnSort;
    sort?: SortingEnum;
    skip: number;
    take: number;
}

export type GetMREMainWithFilterRequest = {
    project_name?: string;
    department_name?: string;
    kind_job?: string;
    startRequestDate?: string;
    endRequestDate?: string;
    number_id?: string;
    number_job?: string;
    status?: string;
    startDueDate?: string;
    endDueDate?: string;
    startDateAssign?: string;
    endDateAssign?: string;
    startDateQCMREAssign?: string;
    endDateQCMREAssign?: string;
    req_pole?: number;
    column?: MainMREColumnSort;
    sort?: SortingEnum;
    employee_id?: string;
    skip: number;
    take: number;
}