import { BaseServiceClass } from 'base-service-class';
import httpClient, {ApiResponseWrapper} from 'http-client';
import {AxiosRequestConfig} from "axios";
import {NewUploadFileResponse, UploadResponse} from "./models";

export default class FileAttachmentApi extends BaseServiceClass {
    // static uploadFile(form: FormData, config: AxiosRequestConfig) {
    //     return httpClient
    //         .post<UploadResponse>(`${this.BASE_URL}${this.PATH_UPLOAD.upload_file}`, form, config)
    //         .then((res) => res.data);
    // }
    static uploadFile(form: FormData, config: AxiosRequestConfig) {
        return httpClient
            .post<UploadResponse>(`${this.BASE_UPLOAD_URL}${this.PATH_UPLOAD.new_upload_file}`, form, config)
            .then((res) => res.data);
    }

    // static uploadFile(form: FormData, config: AxiosRequestConfig) {
    //     return httpClient
    //         .post<UploadResponse>(`${this.BASE_URL}/api/Upload/UploadFileNew`, form, config)
    //         .then((res) => res.data);
    // }

    static getMediaEndpoint(url: string) {
        if (url.includes('data:image') || url.includes(this.MEDIA_URL) || url.includes(this.NEW_MEDIA_URL))
            return url;

        return `${this.MEDIA_URL}${url}`;
    }
}