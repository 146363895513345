import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {RootState} from '../../../../../app/store';
import {getAttendanceList, reset, updateAttendance} from "../../../../profile/profile.reducer";
import InputText from "../../../../../components/TextInput/InputText";
import Divider from "../../../../../components/Layout/Divider";
import Button from "../../../../../components/Button/Button";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import {useForm} from "react-hook-form";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {InputAttendanceFilter} from "../../../../approval/models";
import {UserCredential} from "../../../../login/models";
import {customWarnOption, savedStatusOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {AttendanceModel} from "../../../../profile/models";
import {GlobalApprovalEnum} from "../../../../../types";
import {MdSettingsBackupRestore} from "react-icons/md";
import {HttpClientError, RequestStatus} from "../../../../../http-client";

const AttendanceTable = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);

    const {
        control,
        register,
        handleSubmit,
        watch,
        reset: resetForm
    } = useForm<InputAttendanceFilter>({defaultValues: {startDate: undefined, endDate: undefined}});

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const attendanceList = useAppSelector(
        (state) => state.profile.attendanceList ?? []
    );
    const rows = useAppSelector(
        (state: RootState) => state.profile.attendanceRows ?? 0
    );
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector((state: RootState) => state.profile.attendanceStatus);
    const error = useAppSelector(
        (state: RootState) => state.profile.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                refreshCache(undefined);
                gridRef.current.api.setFilterModel(null);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onSubmit = (data: InputAttendanceFilter) => {
        let localStart = data.startDate !== undefined ? new Date(data.startDate).toISOString() : undefined;
        let localEnd = data.endDate !== undefined ? new Date(data.endDate).toISOString() : undefined;
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            filter: {
                filterType: "text",
                type: "contains",
                filter: data.filter
            },
            dateStartFilter: {
                filterType: "text",
                type: "contains",
                filter: localStart,
            },
            dateEndFilter: {
                filterType: "text",
                type: "contains",
                filter: localEnd
            }
        })
    }

    const onRequestClicked = (data: AttendanceModel) => {
        MySwal.fire(
            customWarnOption(
                'Revert Attendance',
                'Are you sure to revert this attendance to requested?',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(updateAttendance({
                    ...data,
                    status: GlobalApprovalEnum.Requested,
                }));
            }
        });
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    user?.roleName.some(item => item === "Superadmin") &&
                    <button onClick={() => onRequestClicked(props.data)}>
                        <MdSettingsBackupRestore className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'ts_created', headerName: 'Date Created', cellDataType: 'date_time'},
        {field: 'absentDate', headerName: 'Absent Date', cellDataType: 'date'},
        {
            field: 'nip', headerName: 'NIP'
        },
        {field: 'teamName', headerName: 'Team'},
        {field: 'name', headerName: 'Name'},
        {field: 'reqMinute', headerName: 'Req Minute'},
        {field: 'leaveDuration', headerName: 'Leave Duration'},
        {field: 'notes', headerName: 'Notes'},
        {field: 'statusName', headerName: 'status'},
        {field: 'approveMinute', headerName: 'Approved Minutes'},
        {field: 'approvalDate', headerName: 'Approval Date', cellDataType: 'date'},
        {field: 'rejectionNotes', headerName: 'Rejection Notes'},
        {field: 'isDeducted', headerName: 'Deducted', cellRenderer: (props: any) => <>{props.data?.isDeducted ? "Yes" : "No"}</>},
        {field: 'isRefNote', headerName: 'Ref Notes', cellRenderer: (props: any) => <>{props.data?.isRefNote ? "Yes" : "No"}</>},
        {
            headerName: 'Filter',
            field: 'filter',
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Date Start Filter',
            field: 'dateStartFilter',
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Date End Filter',
            field: 'dateEndFilter',
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const onResetClicked = () => {
        resetForm();
        refreshCache(undefined);
        gridRef.current.api.setFilterModel(null);
    }

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                let _filter = params.request.filterModel?.filter?.filter ?? undefined;
                    dispatch(
                    getAttendanceList({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        filter: _filter,
                        startDate: params.request.filterModel?.dateStartFilter?.filter ?? undefined,
                        endDate: params.request.filterModel?.dateEndFilter?.filter ?? undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="mt-10">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Attendance Report</h1>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Divider>
                            <Divider.LeftContent>
                                <div>
                                    <RangeDatePicker label={"Filter by Date"} startName='startDate' endName='endDate'
                                                     startValue={new Date(startDate)} endValue={new Date(endDate)}
                                                     control={control}/>
                                </div>
                            </Divider.LeftContent>
                            <Divider.RightContent>
                                <div>
                                    <InputText
                                        {...register('filter')}
                                        placeholder="Search by name | nip | team | status | notes"
                                        label="Filter by Name | NIP | Team | Status | Notes"
                                    />
                                    <div className="flex justify-end">
                                        <div className={"mx-2"}>
                                            <Button
                                                color="success"
                                                type="button"
                                                title="Reset"
                                                onClick={onResetClicked}
                                            />
                                        </div>
                                        <Button
                                            color="success"
                                            type="submit"
                                            title="Filter"
                                        />
                                    </div>
                                </div>
                            </Divider.RightContent>
                        </Divider>
                    </form>
                </div>
                <div className="main-data-grid fielding mt-5">
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} rowData={attendanceList}
                                            totalRows={rows} getServerSideDataSource={getServerSideDataSource}
                                            supportSaveTableState
                                            tableStateName={'report-attendance'} defaultPageSize={20} />
                </div>
            </div>
        </div>
    );
};

export default AttendanceTable;