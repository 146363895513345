import {BaseServiceClass} from "../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../http-client";
import {BilledGDevRequest, GDevApproveRequest, GDevDataModel, GetGDevRequest} from "../models";
import {InputFilter} from "../../report/jobReport/models";

export default class GDevApi extends BaseServiceClass {
    static async get(args: GetGDevRequest, token: string) {
        const res = await httpClient.post<ApiResponseWrapper<{
            list: GDevDataModel[];
            total: number;
        }>>(this.PATH_WORKSHEET.get_gdev_data, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });
        return res.data;
    }

    static async billedGDev(args: BilledGDevRequest, token: string) {
        const res = await httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.billed_gdev_data, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });
        return res.data;
    }

    static async approveGDev(args: GDevApproveRequest, token: string) {
        const res = await httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.approve_gdev, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });
        return res.data;
    }

    static exportGDev(args: GetGDevRequest, token: string) {
        const path = "/api/Job/ExportGdevData"
        return httpClient
            .post<any>(`${this.BASE_URL}${path}`, args, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }
}