import React, {useEffect, useState} from 'react';
import {MdOutlineCancel, MdOutlineDeleteForever} from 'react-icons/md';
import {BiCheckCircle, BiEditAlt} from 'react-icons/bi';
import {customWarnOption, deletedWarnOption, savedStatusOption,} from 'helpers/alertLabel';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import EditableCell from 'components/Table/EditableCell';
import Button from 'components/Button/Button';
import {SingleValue} from 'react-select';
import {Option} from 'components/SelectInput/BasicSelectInput';
import {getList} from 'features/master/poleType/poleType.reducer';
import {RootState} from 'app/store';
import SelectInput from 'components/SelectInput/SelectInput';

import {getList as getPoleStatusList} from 'features/master/poleStatus/poleStatus.reducer';
import {isArrayEmpty} from 'helpers/checkEmptiness';
import {HttpClientError, RequestStatus} from 'http-client';
import {Checkbox, Dropdown} from 'flowbite-react';
import {FaMapMarkerAlt} from 'react-icons/fa';
import DefaultModal from 'components/Modal/DefaultModal';
import Places from './Places';
import {AiOutlineMenu} from 'react-icons/ai';
import {
    changeStatusMultiplePole,
    createPole,
    deleteMultiplePole,
    deletePole,
    getPoleByJob,
    reset,
    updatePole,
} from '../../../../basic/pole/pole.reducer';
import {LocationCoordinate, Pole} from "../../../../basic/pole/models";
import TableWithServerPaging from "../../../../../components/Table/TableWithServerPaging";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import {useSelected} from "../../../../../hooks/checkbox/useSelected";
import {useShiftSelected} from "../../../../../hooks/checkbox/useShiftSelected";
import {useParams} from "react-router-dom";
import {SortingEnum} from "../../../../approval/models";
import {Job} from "../../../process/job/models";

const JobPrepTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const { id } = useParams();

    const [rowData, setRowData] = useState<Pole[]>([]);
    const [pageCount, setPageCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selection, setSelection] = useState<Array<string>>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [isAddNewActive, setIsAddNewActive] = useState<boolean>(false);
    const [isMapEditable, setIsMapEditable] = useState<boolean>(false);
    // shift purpose only
    const [initialPoleIds, setInitialPoleIds] = useState<Array<string>>([]);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const poleTypeOptions = useAppSelector(
        (state: RootState) => state.poleType.options ?? []
    );
    const pole = useAppSelector(
        (state: RootState) => state.pole.poleByJob ?? []
    );
    const rows = useAppSelector((state: RootState) => state.pole.poleByJobRows ?? 0);
    const status = useAppSelector((state: RootState) => state.pole.status);
    const error = useAppSelector(
        (state: RootState) => state.pole.error ?? ({} as HttpClientError)
    );
    const poleStatusList = useAppSelector(
        (state: RootState) => state.poleStatus.list ?? []
    );
    const singleJobState = useAppSelector(
        (state: RootState) => state.job.single ?? ({} as Job)
    );

    const {selected, change} = useSelected([] as Array<string>);
    const onChange = useShiftSelected(initialPoleIds, change);

    useEffect(() => {
        dispatch(getList({skip: 0, take: 500}));
        dispatch(getPoleStatusList({skip: 0, take: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id && id !== "") {
            dispatch(getPoleByJob({job_id: id as string, skip: 0, take: 10, column: 5, sort: SortingEnum.Ascending}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (!isArrayEmpty(pole)) {
            let dataForSort = [...pole];
            let ownPole = pole.filter(item => item.employee_prep_id === user.user_id);
            setInitialPoleIds(ownPole.map(item => item.id ?? ''));
            // let sortedPole = dataForSort.sort((a, b) => b.no?.localeCompare(a.no ?? '') ?? 0);
            if (isAddNewActive) {
                let data = dataForSort;
                data.unshift({
                    id: '',
                    job_id: id as string,
                    seq: '',
                    no: '',
                    address: '',
                    is_pip: false,
                    latitude: 0,
                    longitude: 0,
                    type_id: '',
                    is_fielding: true,
                    is_mre: false,
                    is_katapult: false,
                    notes: '',
                    job_number_job: '',
                    pole_type_name: '',
                    status_id: '',
                    pole_status_name: '',
                    index: 0
                });
                setRowData(
                    data
                );
            } else {
                setRowData(dataForSort);
            }
        } else {
            setRowData([]);
        }

        if (isAddNewActive && isArrayEmpty(pole)) {
            let data = [];
            data.unshift({
                id: '',
                job_id: id as string,
                seq: '',
                no: '',
                address: '',
                is_pip: false,
                latitude: 0,
                longitude: 0,
                type_id: '',
                is_fielding: true,
                is_mre: false,
                is_katapult: false,
                notes: '',
                job_number_job: '',
                pole_type_name: '',
                status_id: '',
                pole_status_name: '',
                index: 0
            });
            setRowData(
                data
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pole, isAddNewActive]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                setIsAddNewActive(false);
                setIsRowCreate(false);
                dispatch(getPoleByJob({job_id: id as string, skip: 0, take: rowsPerPage, column: 5, sort: SortingEnum.Ascending}));
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletePole(id));
            }
        });
    };

    const [isRowEdit, setIsRowEdit] = useState('');
    const [isRowCreate, setIsRowCreate] = useState<boolean>(false);
    const [currentPosition, setCurrentPosition] = useState({
        rowIndex: 0,
        columnId: 0,
    });
    const [defaultCoordinate, setDefaultCoordinate] =
        useState<LocationCoordinate>({lat: 43.45, lng: -80.49});

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const onPageCountChange = (pageCount: number) => {
        setPageCount(pageCount);
    }

    const openMapModal = (rowIndex: number, columnId: number, data: Pole, isEditable: boolean) => {
        console.log(isEditable);
        const filteredPole = pole.filter(item => item.latitude !== 0 && item.longitude !== 0);
        const currentPole = pole.find(item => item.id === data.id) ?? {} as Pole;
        if (currentPole.latitude === 0 && currentPole.longitude === 0 && !isArrayEmpty(filteredPole)) {
            setDefaultCoordinate({
                lat: filteredPole[filteredPole.length - 1].latitude ?? 43.45,
                lng: filteredPole[filteredPole.length - 1].longitude ?? -80.49,
            })
        } else {
            setDefaultCoordinate({
                lat: data.latitude ?? 43.45,
                lng: data.longitude ?? -80.49,
            });
        }
        if (isEditable) {
            setIsRowEdit(rowIndex.toString());
        }
        setShowModal(true);
        setCurrentPosition({rowIndex, columnId});
        setIsMapEditable(isEditable);
    };

    const onAddRowClick = () => {
        setIsAddNewActive(true);
        setIsRowEdit('0');
        setIsRowCreate(true);
        setPageCount(1);
        dispatch(getPoleByJob({
            job_id: id as string, skip: 0,
            take: rowsPerPage,
            column: 5,
            sort: SortingEnum.Ascending
        }));
    };
    const onEditClick = (index: number) => {
        setIsRowEdit(index.toString());
        setIsRowCreate(false);
    };
    const onSaveClick = (data: Pole) => {
        let _input = {
            job_id: data.job_id,
            seq: data.seq ?? '',
            no: data.no,
            address: data.address,
            is_pip: data.is_pip ?? 0,
            latitude: data.latitude,
            longitude: data.longitude,
            type_id: data.type_id,
            is_fielding: data.is_fielding,
            is_mre: data.is_mre,
            is_katapult: data.is_katapult,
            notes: data.notes,
            index: data.index ?? 0,
        };
        if (isRowCreate) {
            dispatch(createPole(_input));
        } else {
            let sendData = {
                ..._input, id: data.id,
                employee_prep_id: data.employee_prep_id ?? "",
                employee_calcspec_id: data.employee_calcspec_id ?? "",
                employee_qc_id: data.employee_qc_id ?? "",
                employee_katapult_id: data.employee_katapult_id ?? "",
            };
            dispatch(updatePole(sendData));
        }
        setIsAddNewActive(false);
        setIsRowEdit('');
    };
    const onCancelClick = () => {
        setIsRowEdit('');
        setIsAddNewActive(false);
        setIsRowCreate(false);
        dispatch(getPoleByJob({job_id: id as string, skip: 0, take: rowsPerPage, column: 5, sort: SortingEnum.Ascending}));
    };

    // const toggleSelection = (key: string, employeeId: string) => {
    //     if (employeeId !== user.user_id) return null;
    //     // start off with the existing state
    //     let _selection = [...selection];
    //     const keyIndex = selection.indexOf(key);
    //     // check to see if the key exists
    //     if (keyIndex >= 0) {
    //         // it does exist so we will remove it using destructing
    //         _selection = [
    //             ...selection.slice(0, keyIndex),
    //             ...selection.slice(keyIndex + 1),
    //         ];
    //     } else {
    //         // it does not exist so add it
    //         _selection.push(key);
    //     }
    //     // update the state
    //     setSelection(_selection);
    // };

    const toggleAll = () => {
        const _selectAll = !selectAll;
        const _selection: string[] = [];
        if (_selectAll) {
            pole.forEach((item) => {
                if (item.employee_prep_id === user.user_id) _selection.push(item.id);
            });
        }
        setSelection(_selection);
        setSelectAll(_selectAll);
    };

    // const isSelected = (key: string) => {
    //     return selection.includes(key);
    // };

    const columns = [
        {
            Header: (
                <>
                    <Checkbox
                        id="select_all"
                        checked={selectAll}
                        onChange={toggleAll}
                    />
                </>
            ),
            accessor: 'id',
            Cell: ({row}: any) => {
                return (
                    <div>
                        <Checkbox
                            id={row.id}
                            // checked={isSelected(row.original.id)}
                            // onChange={() => toggleSelection(row.original.id, row?.original?.employee_prep_id)}
                            onChange={(event) => onChange(event, row.original.id)
                        }
                            checked={selection.includes(row.original.id) || selected.includes(row.original.id)}
                            disabled={row?.original?.employee_prep_id !== user.user_id}
                        />
                    </div>
                );
            },
        },
        {
            Header: 'NO.',
            accessor: 'index',
            Cell: (props: any) =>
                EditableCell({...props, textType: 'number'}),
            // Cell: ({row: {index}}: any) => {
            //     return <div>{index + 1}</div>;
            // },
        },
        {
            Header: 'Pole Seq',
            minWidth: 150,
            accessor: 'seq',
            Cell: EditableCell,
        },
        {
            Header: 'Pole No',
            minWidth: 150,
            accessor: 'no',
            Cell: EditableCell,
        },
        {
            Header: 'Pole Address',
            minWidth: 150,
            accessor: 'address',
            Cell: EditableCell,
        },
        {
            Header: 'PIP',
            minWidth: 150,
            accessor: 'is_pip',
            Cell: ({
                       value: initialValue,
                       row: {index},
                       column: {id},
                   }: any) => {
                console.log(initialValue);
                return (
                    <>
                        {isRowEdit === index.toString() ? (
                            <Checkbox
                                id="is_pip"
                                checked={initialValue
                            }
                                onChange={(e) => onCheckboxChange(e, index, id)}
                            />
                        ) : (
                            <>{initialValue ? 'True' : 'False'}</>
                        )}
                    </>
                );
            },
        },
        {
            Header: 'Latitude',
            minWidth: 150,
            accessor: 'latitude',
            Cell: EditableCell,
        },
        {
            Header: 'Longitude',
            minWidth: 150,
            accessor: 'longitude',
            Cell: EditableCell,
        },
        {
            Header: 'Pole Type',
            minWidth: 150,
            accessor: 'type_name',
            Cell: ({
                       value: initialValue,
                       row: {index},
                       column: {id},
                   }: any) => {
                return (
                    <>
                        {isRowEdit === index.toString() ? (
                            <SelectInput
                                key={`my_unique_select_key__${initialValue}`}
                                options={poleTypeOptions}
                                // isLoading={isProjectLoading}
                                // label="Project"
                                onChange={(val: SingleValue<Option>) =>
                                    onPoleTypeChange(val, index, id)
                                }
                                // message={errors.project_id?.message}
                                defaultInputValue={initialValue}
                                menuPosition="fixed"
                                menuPlacement="auto"
                            />
                        ) : (
                            <span className="text-sm font-normal">
                                {initialValue}
                            </span>
                        )}
                    </>
                );
            },
        },
        {
            Header: 'Fielding',
            minWidth: 150,
            accessor: 'is_fielding',
            Cell: ({
                       value: initialValue,
                       row: {index},
                       column: {id},
                   }: any) => {
                return (
                    <>
                        {isRowEdit === index.toString() ? (
                            <Checkbox
                                id="is_fielding"
                                checked={initialValue}
                                onChange={(e) => onCheckboxChange(e, index, id)}
                            />
                        ) : (
                            <>{initialValue ? 'True' : 'False'}</>
                        )}
                    </>
                );
            },
        },
        {
            Header: 'MRE',
            minWidth: 150,
            accessor: 'is_mre',
            Cell: ({
                       value: initialValue,
                       row: {index},
                   }: any) => {
                return (
                    <>
                        {isRowEdit === index.toString() ? (
                            <>
                            </>
                            // <Checkbox
                            //     id="is_mre"
                            //     checked={initialValue}
                            //     onChange={(e) => onCheckboxChange(e, index, id)}
                            // />
                        ) : (
                            <>{initialValue ? 'True' : 'False'}</>
                        )}
                    </>
                );
            },
        },
        {
            Header: 'Katapult',
            minWidth: 150,
            accessor: 'is_katapult',
            Cell: ({
                       value: initialValue,
                       row: {index},
                   }: any) => {
                return (
                    <>
                        {isRowEdit === index.toString() ? (
                            <>
                                </>
                            // <Checkbox
                            //     id="is_katapult"
                            //     checked={initialValue}
                            //     onChange={(e) => onCheckboxChange(e, index, id)}
                            // />
                        ) : (
                            <>{initialValue ? 'True' : 'False'}</>
                        )}
                    </>
                );
            },
        },
        {
            Header: 'Notes Fielding',
            minWidth: 150,
            accessor: 'notes',
            Cell: EditableCell,
        },
        {
            Header: 'Pole Status',
            minWidth: 150,
            accessor: 'status_name',
        },
        {
            Header: 'Job Prep',
            minWidth: 150,
            accessor: 'employee_prep_name',
        },
        {
            Header: 'Action',
            minWidth: 150,
            sticky: 'right',
            Cell: ({row, column: {id}}: any) => {
                return (
                    <>
                        {
                            !isRowEdit && (
                                <button
                                    onClick={() =>
                                        openMapModal(row?.index, id, row.original, (user.user_id === row?.original?.employee_prep_id && checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.PinMapPole)))
                                    }
                                >
                                    <FaMapMarkerAlt className="mr-2 text-2xl text-primary-color"/>
                                </button>
                            )
                        }
                        {
                            (row?.original?.id === "" || user.user_id === row?.original?.employee_prep_id) && (
                                <>
                                    {isRowEdit === row?.index.toString() ? (
                                        <>
                                            {
                                                checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.PinMapPole) &&
                                                <button
                                                    onClick={() =>
                                                        openMapModal(row?.index, id, row.original, true)
                                                    }
                                                >
                                                    <FaMapMarkerAlt className="mr-2 text-2xl text-primary-color"/>
                                                </button>
                                            }
                                            <button
                                                onClick={() => onSaveClick(row.original)}
                                            >
                                                <BiCheckCircle className="mr-2 text-2xl text-primary-color"/>
                                            </button>
                                            <button onClick={() => onCancelClick()}>
                                                <MdOutlineCancel className="mr-2 text-2xl text-secondary-color"/>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.Edit) &&
                                                <button onClick={() => onEditClick(row?.index)}>
                                                    <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                                                </button>
                                            }
                                        </>
                                    )}
                                </>
                            )
                        }
                        {
                            ((row?.original?.id === "" || user.user_id === row?.original?.employee_prep_id) || user?.roleName.some(item => item === "Job Prep")) &&
                            <button
                                onClick={() =>
                                    onDeleteClicked(row.original.id)
                                }
                            >
                                <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                            </button>
                        }
                    </>
                );
            },
        },
    ];

    const onCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        rowIndex: any,
        columnId: any
    ) => {
        setRowData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: e.target.checked,
                    };
                }
                return row;
            })
        );
    };

    const updateMyData = (rowIndex: number, columnId: any, value: any) => {
        // We also turn on the flag to not reset the page
        setRowData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    // FIXME: simplify this into one function
    const onPoleTypeChange = (
        val: SingleValue<Option>,
        rowIndex: any,
        columnId: any
    ) => {
        setRowData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        type_id: val?.value ?? '',
                        [columnId]: val?.text ?? '',
                    };
                }
                return row;
            })
        );
    };

    const onCoordinateChange = (coordinate: LocationCoordinate | undefined, address: string) => {
        // We also turn on the flag to not reset the page
        setRowData((old) =>
            old.map((row, index) => {
                if (index === currentPosition.rowIndex) {
                    return {
                        ...old[currentPosition.rowIndex],
                        latitude: coordinate !== undefined ? coordinate.lat : 0,
                        longitude: coordinate !== undefined ? coordinate.lng : 0,
                        address: address,
                    };
                }
                return row;
            })
        );
        setShowModal(false);
    };

    // FIXME: end of simplify this into one function

    const onMultipleDelete = () => {
        if (isArrayEmpty(selected)) return null;
        let poleIds = selected.toString();
        MySwal.fire(
            customWarnOption(
                'Delete Multiple Data',
                'Are you sure you want to delete the selected items?'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteMultiplePole(poleIds));
            }
        });
    };

    const onMultipleChangeStatus = (status: string) => {
        if (isArrayEmpty(selected)) return null;
        let poleIds = selected.toString();
        MySwal.fire(
            customWarnOption(
                'Change Status Multiple Data',
                'Are you sure you want to change status the selected items?'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    changeStatusMultiplePole({
                        pole_ids: poleIds,
                        status_id: status,
                    })
                );
            }
        });
    };

    const handlePageChange = (page: number) => {
        setIsRowEdit('');
        setIsAddNewActive(false);
        dispatch(getPoleByJob({
            job_id: id as string, skip: rowsPerPage * (page - 1),
            take: rowsPerPage,
            column: 5,
            sort: SortingEnum.Ascending
        }));
    };

    const handleRowChange = (totalRow: number) => {
        setRowsPerPage(totalRow);
        dispatch(getPoleByJob({
            job_id: id as string, skip: 0,
            take: totalRow,
            column: 5,
            sort: SortingEnum.Ascending
        }));
    };

    return (
        <div>
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                    Job Prep ({`${singleJobState.number_id} / ${singleJobState.number_job}` })
                </h1>
                <div className="mr-1">
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.Input) &&
                        <Button title="Add New Pole" onClick={onAddRowClick} disabled={isAddNewActive}/>
                    }
                </div>
                <div>
                    <Dropdown
                        color="light"
                        label={<AiOutlineMenu className="text-xl text-light"/>}
                        arrowIcon={false}
                    >
                        <Dropdown.Header>
                            <span className="block text-sm font-medium truncate">
                                Multiple Action
                            </span>
                        </Dropdown.Header>
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.ChangePoleStatus) &&
                            <>
                                {poleStatusList.map((status) => (
                                    <Dropdown.Item
                                        key={status.id}
                                        onClick={() =>
                                            onMultipleChangeStatus(status.id)
                                        }
                                    >
                                <span
                                    className={`${
                                        isArrayEmpty(selection) &&
                                        'text-gray-400'
                                    }`}
                                >
                                    {status.name}
                                </span>
                                    </Dropdown.Item>
                                ))}
                            </>
                        }
                        {
                            checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.Delete) &&
                            <>
                                <Dropdown.Divider/>
                                <Dropdown.Item onClick={onMultipleDelete}>
                                    <span className={`${isArrayEmpty(selection) && 'text-gray-400'}`}>Delete</span>
                                </Dropdown.Item>
                            </>
                        }

                    </Dropdown>
                </div>
            </div>
            <div className="main-data-grid mt-5">
                <TableWithServerPaging
                    columns={columns}
                    data={rowData}
                    serverRows={rows}
                    handlePageChange={handlePageChange}
                    handleRowChange={handleRowChange}
                    updateMyData={updateMyData}
                    rowEdit={isRowEdit}
                    pageCount={pageCount}
                    changePageCount={onPageCountChange}
                />

                <DefaultModal
                    title="Maps"
                    show={showModal}
                    onClose={toggleModal}
                    size="7xl"
                    body={
                        <div style={{height: '80vh', width: '100%'}}>
                            <Places
                                onCoordinateChange={onCoordinateChange}
                                defaultCoordinate={defaultCoordinate}
                                isMapEditable={isMapEditable}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default JobPrepTable;
