import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {
    customWarnOption,
    savedStatusOption,
} from '../../../../helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {HttpClientError, RequestStatus} from '../../../../http-client';
import {RootState} from '../../../../app/store';
import {BiEditAlt} from 'react-icons/bi';
import {UserCredential} from '../../../login/models';
import {changeAttendanceStatus, getAttendanceWithFilter, reset} from '../../approval.reducer';
import {AttendanceModel} from "../../../profile/models";
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import AttendanceApprovalForm from "./AttendanceApprovalForm";
import {
    approvalAttendanceStatusFilterOptions,
    attendanceSortOption,
    getSortModel,
    getSortType,
} from "../../constants";
import InputCheckbox from "../../../../components/Checkbox/InputCheckbox";
import {GlobalApprovalEnum} from "../../../../types";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {dateSetTimeToNow} from "../../../../helpers/convert";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";
import {FaRegCheckCircle} from "react-icons/fa";

const AttendanceApprovalTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [singleAttendance, setSingleAttendance] = useState<AttendanceModel>({} as AttendanceModel);
    const [selection, setSelection] = useState<Array<AttendanceModel>>([]);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector(
        (state: RootState) => state.approval.attendanceApprovalStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.approval.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (status === RequestStatus.success) {
                    closeModal();
                }
                setSelection([]);
                refreshCache(undefined);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const openModal = (attendance: AttendanceModel) => {
        setSingleAttendance(attendance);
        setShowModal(true);
    };

    const closeModal = () => {
        setSingleAttendance({} as AttendanceModel);
        setShowModal(false);
    };

    const IsDeductedCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <InputCheckbox
                    checked={props.data?.isDeducted}
                    readOnly
                />
            </div>
        )
    }

    const IsRefNoteCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                <InputCheckbox
                    checked={props.data?.isRefNote}
                    readOnly
                />
            </div>
        )
    }

    const NoteCellRender = (props: any) => {
        const [isExpand, setIsExpand] = useState<boolean>(false);

        if (props.data === undefined) {
            return null;
        }

        const toggleExpand = () => {
            setIsExpand(!isExpand);
        }

        return <div>
            {props.data.notes?.length > 10 ? isExpand ? props.data.notes : props.data.notes?.substring(0, 10) : props.data.notes}{!isExpand && props.data.notes?.length > 10 &&
            <span className="text-cyan-600 hover:underline dark:text-cyan-500" onClick={toggleExpand}>...</span>}
        </div>
    }

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    props.data?.statusName === "Requested" &&
                    <button onClick={() => openModal(props.data)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
        },
        {
            field: 'ts_created', headerName: 'Date Created', cellDataType: 'date_time',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'absentDate', headerName: 'Absent Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'nip', headerName: 'NIP',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'teamName', headerName: 'Team',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'name', headerName: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'reqMinute', headerName: 'Req Minute',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'leaveDuration', headerName: 'Leave Duration',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'notes', headerName: 'Notes', cellRenderer: NoteCellRender,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'statusName', headerName: 'status', filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: approvalAttendanceStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {
            field: 'approveMinute', headerName: 'Approved Minutes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'approvalDate', headerName: 'Approval Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'rejectionNotes', headerName: 'Rejection Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'isDeducted', headerName: 'Deducted', cellRenderer: IsDeductedCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
        {
            field: 'isRefNote', headerName: 'Ref Notes', cellRenderer: IsRefNoteCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const onApproveClicked = () => {
        MySwal.fire(
            customWarnOption(
                'Approve Selected Attendance?',
                'Are you sure to approve selected attendance? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                let _input = selection.map((item) => {
                    return {
                        id: item.id ?? "",
                        approveMinute: item.reqMinute,
                        leaveDuration: item.leaveDuration,
                        rejectionNote: item.rejectionNotes,
                        isDeducted: item.isDeducted,
                        isRefNote: item.isRefNote,
                        userId: user.user_id,
                        status: GlobalApprovalEnum.Approved,
                    }

                })
                dispatch(changeAttendanceStatus(_input));
            }
        });
    }

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(attendanceSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {
                    dateFrom: startAbsentDate,
                    dateTo: endAbsentDate
                } = params.request.filterModel?.absentDate || {};
                const {
                    dateFrom: startApprovalDate,
                    dateTo: endApprovalDate
                } = params.request.filterModel?.approvalDate || {};

                dispatch(
                    getAttendanceWithFilter({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        startDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        startAbsentDate: startAbsentDate !== undefined ? dateSetTimeToNow(startAbsentDate) : undefined,
                        endAbsentDate: endAbsentDate !== undefined ? dateSetTimeToNow(endAbsentDate) : undefined,
                        nip: params.request.filterModel?.nip?.filter,
                        team: params.request.filterModel?.teamName?.filter,
                        name: params.request.filterModel?.name?.filter,
                        req_minute: params.request.filterModel?.reqMinute?.filter,
                        leave_duration: params.request.filterModel?.leaveDuration?.filter,
                        note: params.request.filterModel?.notes?.filter,
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        approve_minute: params.request.filterModel?.approveMinute?.filter,
                        startApprovalDate: startApprovalDate !== undefined ? dateSetTimeToNow(startApprovalDate) : undefined,
                        endApprovalDate: endApprovalDate !== undefined ? dateSetTimeToNow(endApprovalDate) : undefined,
                        rejection_note: params.request.filterModel?.rejectionNotes?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const onSelectionChanged = useCallback(() => {
        let selectedRows = gridRef.current.api.getSelectedNodes();
        let _selectedRows = selectedRows.map((item: any) => item.data);
        setSelection(_selectedRows);
    }, []);

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                            getServerSideDataSource={getServerSideDataSource}
                                            isRowSelectable={() => true} onSelectionChanged={onSelectionChanged}
                                            supportSaveTableState tableStateName={'approval-attendance'}
                                            isCustomHeader
                                            customChildHeaderComponent={
                                                <>
                                                    <FlowbiteButton
                                                        onClick={onApproveClicked}
                                                        size="xs"
                                                        color="success"
                                                        disabled={isArrayEmpty(selection)}
                                                    >
                                                        <FaRegCheckCircle className="mr-2 h-5 w-5"/>
                                                        Approve Selected
                                                    </FlowbiteButton>
                                                </>
                                            }
                                            defaultPageSize={20}
                    />

                    <DefaultModal
                        title="Attendance Approval"
                        show={showModal}
                        onClose={closeModal}
                        body={
                            <AttendanceApprovalForm singleAttendance={singleAttendance} userId={user.user_id}/>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default AttendanceApprovalTable;