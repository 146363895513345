import React, {
    useCallback,
    useEffect, useRef, useState,
} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {deletedWarnOption, savedStatusOption} from 'helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {HttpClientError, RequestStatus} from 'http-client';
import {RootState} from 'app/store';
import {BiEditAlt} from "react-icons/bi";
import {MdOutlineDeleteForever} from "react-icons/md";
import {UserCredential} from "../../../login/models";
import {HolidayModel, HolidayOvertimeApprovalEnum} from "../../models";
import {
    deleteHoliday,
    getEmployeeHolidayByUser,
    getHoliday,
    reset,
} from "../../profile.reducer";
import GridList from "../../../../components/List/GridList";
import {isArrayEmpty, isObjectEmpty} from "../../../../helpers/checkEmptiness";
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import AddHolidayForm from "./AddHolidayForm";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";
import {IoMdAdd} from "react-icons/io";
import {approvalStatusFilterOptions, getSortModel, getSortType, holidaySortOption} from "../../../approval/constants";
import {userHolidaySortOption} from "../../constants";
import {dateSetTimeToNow} from "../../../../helpers/convert";

const AddHolidayTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const employeeHolidayList = useAppSelector(
        (state) => state.profile.employeeHolidayList ?? []
    );
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const status = useAppSelector(
        (state: RootState) => state.profile.holidayStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.profile.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [singleHoliday, setSingleHoliday] = useState<HolidayModel>({} as HolidayModel);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    useEffect(() => {
        if (!isObjectEmpty(user)) {
            dispatch(
                getEmployeeHolidayByUser(user.user_id)
            );
        }
    }, [user]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (status === RequestStatus.success) {
                    closeModal();
                }
                refreshCache(undefined);
                gridRef.current.api.setFilterModel(null);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteHoliday(id));
            }
        });
    };

    const openModal = (holiday: HolidayModel, isEdit: boolean) => {
        setSingleHoliday(holiday);
        setIsEdit(isEdit);
        setShowModal(true);
    };

    const closeModal = () => {
        setSingleHoliday({} as HolidayModel);
        setShowModal(false);
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    props.data.status === HolidayOvertimeApprovalEnum.Requested &&
                    <>
                        <button onClick={() => openModal(props.data, true)}>
                            <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                        </button>
                        <button
                            onClick={() =>
                                onDeleteClicked(props.data?.id)
                            }
                        >
                            <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"/>
                        </button>
                    </>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'ts_created', headerName: 'Date Created', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {field: 'reqDate', headerName: 'Request Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {field: 'notes', headerName: 'Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'statusName', headerName: 'Status',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: approvalStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {field: 'rejectionNotes', headerName: 'Rejection Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'approvalByName', headerName: 'Approved By',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'managerApprovedName', headerName: 'Manager Approved',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            filter: false,
            sortable: false,
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(userHolidaySortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {dateFrom: startReqDate, dateTo: endReqDate} = params.request.filterModel?.reqDate || {};
                dispatch(
                    getHoliday({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        userId: user.user_id,
                        startDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        startReqDate: startReqDate !== undefined ? dateSetTimeToNow(startReqDate) : undefined,
                        endReqDate: endReqDate !== undefined ? dateSetTimeToNow(endReqDate) : undefined,
                        note: params.request.filterModel?.notes?.filter,
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        rejection_note: params.request.filterModel?.rejectionNotes?.filter,
                        approve_by: params.request.filterModel?.approvalByName?.filter,
                        manager_approval: params.request.filterModel?.managerApprovedName?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <div className="flex justify-items-center">
                        <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Holiday</h1>
                        {/*<div className="flex">*/}
                        {/*    <div className="mr-2">*/}
                        {/*        <Button title="Add Holiday" onClick={() => openModal({} as HolidayModel, false)}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className={"w-1/3"}>
                        <GridList data={employeeHolidayList} noLabel={true}/>
                    </div>
                    <div className="mt-3">
                        <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs} getServerSideDataSource={getServerSideDataSource} supportSaveTableState tableStateName={'profile-holiday'} isCustomHeader={true} customChildHeaderComponent={
                                                    <>
                                                        <FlowbiteButton
                                                            onClick={() => openModal({} as HolidayModel, false)}
                                                            size="xs"
                                                            color="success"
                                                        >
                                                            <IoMdAdd className="mr-2 h-5 w-5"/>
                                                            Add Holiday
                                                        </FlowbiteButton>
                                                    </>
                                                 }
                                                defaultPageSize={20}
                        />
                        <DefaultModal
                            title={`${isEdit ? "Edit" : "Add" } Holiday`}
                            show={showModal}
                            onClose={closeModal}
                            body={
                                <AddHolidayForm singleHoliday={singleHoliday} userId={user.user_id} isEdit={isEdit} />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddHolidayTable;
