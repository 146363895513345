import React, {useEffect} from 'react';
import {TableColumn} from "react-data-table-component";
import DataTableServerPaging from "../../../../../components/Table/DataTableServerPaging";
import {EmailModel} from "../../../../basic/email/models";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {getEmailByJob} from "../../../../basic/email/email.reducer";

const JobEmailHistory = ({jobId = undefined}: JobEmailHistoryProps) => {
    const dispatch = useAppDispatch();

    const emailList = useAppSelector(
        (state: RootState) => state.email.list ?? []
    );
    const rows = useAppSelector(
        (state: RootState) => state.email.rows ?? 0
    );

    useEffect(() => {
        if (jobId !== undefined) {
            dispatch(getEmailByJob({
                job_id: jobId,
                skip: 0,
                take: 10,
            }));
        }
    }, [jobId]);


    const columns: TableColumn<EmailModel>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Job Number',
            selector: (row) => row.job_number_job,
            sortable: true,
        },
        {
            name: 'ID Number',
            selector: (row) => row.job_number_id,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => row.ts_created,
            sortable: true,
            format: (row) => row.ts_created !== null ? new Date(row.ts_created).toLocaleDateString('en-US') : "-",
        },
        {
            name: 'Email Seq',
            selector: (row) => row.seq,
            sortable: true,
        },
        {
            name: 'To',
            selector: (row) => row.receiver,
            sortable: true,
        },
        {
            name: 'Email By',
            selector: (row) => row.sender,
            sortable: true,
        },
        {
            name: 'Notes',
            selector: (row) => row.note,
            sortable: true,
        },
    ]

    const handlePagingChange = (rowsPerPage: number, page: number) => {
        dispatch(getEmailByJob({
            job_id: jobId ?? "",
            skip: rowsPerPage * (page - 1),
            take: rowsPerPage
        }));
    }

    return (
        <div className="p-5">
            <DataTableServerPaging columns={columns} data={emailList} rows={rows}
                                   handlePagingChange={handlePagingChange}
                // conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );
};

export interface JobEmailHistoryProps {
    jobId?: string;
}

export default JobEmailHistory;
