import { HttpClientError, RequestStatus } from 'http-client';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
    UploadProgress,
    UploadStatus,
} from '../../../basic/fileAttachment/models';
import {JobFilePrep, ReadyToAssign, UpdateDetailJobPrep} from './models';
import JobPrepApi from './jobPrep.api';
import {CreateJobFile, JobFileNew} from "../../process/job/models";
import FileAttachmentApi from "../../../basic/fileAttachment/fileAttachment.api";

export interface JobPrepSlice {
    jobPrepFile?: Array<JobFileNew>;
    uploadProgress?: Array<UploadProgress>;
    uploadStatus?: Array<UploadStatus>;
    deleteFileStatus?: RequestStatus;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const uploadJobPrepFile = createAsyncThunk(
    'processDetail/jobPrep/uploadJobPrepFile',
    async (args: CreateJobFile, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((file, index) => {
                        return {
                            index: index,
                            filename: file.name,
                            status: RequestStatus.pending,
                            createStatus: RequestStatus.pending,
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (file, index) => {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('job_id', args.job_id);
                const config = {
                    headers: {
                        Authorization: `Bearer ${userAuth?.id as string}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        location: 'job-prep',
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            })
                        );
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.success,
                        })
                    );
                    return {
                        name: file.name,
                        path: data.result,
                        job_id: args.job_id
                    }
                }).catch(() => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.failed,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                        job_id: args.job_id
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                dispatch(createJobPrepFile(attachment as Array<JobFileNew>));
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createJobPrepFile = createAsyncThunk(
    'processDetail/jobPrep/createJobPrepFile',
    async (
        args: Array<JobFilePrep>,
        { getState, rejectWithValue, dispatch }
    ) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            const createResponse = args?.map(async (arg, index) => {
                if (arg.path !== "") {
                    return await JobPrepApi.createJobPrepFile(arg, userAuth?.id as string).then((data) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.success,
                            })
                        );
                        return data;
                    }).catch((e) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.failed,
                            })
                        );
                        return e;
                    });
                } else {
                    return {};
                }
            });

            Promise.all(createResponse).then(async (attachment) => {
                console.log('saved successfully', attachment);
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getJobPrepFile = createAsyncThunk(
    'processDetail/jobPrep/getJobPrepFile',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobPrepApi.getJobPrepFile(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteJobPrepFile = createAsyncThunk(
    'processDetail/jobPrep/deleteJobPrepFile',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobPrepApi.deleteJobPrepFile(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const readyToAssign = createAsyncThunk(
    'processDetail/jobPrep/readyToAssign',
    async (args: ReadyToAssign, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobPrepApi.readyToAssign(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const jobPrepSlice = createSlice({
    name: 'jobPrepState',
    initialState: {} as JobPrepSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.uploadStatus = [];
            state.uploadProgress = [];
            state.deleteFileStatus = RequestStatus.pending;
            state.uploadStatus = [];
            state.uploadProgress = [];
        },
        setUploadProgress: (state, payload: PayloadAction<Array<UploadProgress>>) => {
            state.uploadProgress = payload.payload;
        },
        setUploadStatus: (state, payload: PayloadAction<Array<UploadStatus>>) => {
            state.uploadStatus = payload.payload;
        },
        updateUploadProgress: (state, payload: PayloadAction<UploadProgress>) => {
            let progress = state.uploadProgress ?? [];
            let objIndex = state.uploadProgress?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            progress[objIndex as number].progress = payload.payload.progress;
            state.uploadProgress = progress;
        },
        updateUploadStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].status = payload.payload.status;
            state.uploadStatus = status;
        },
        updateCreateStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].createStatus = payload.payload.createStatus;
            state.uploadStatus = status;
        },
    },
    extraReducers: (builder) => {
        // create job prep file
        builder.addCase(createJobPrepFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createJobPrepFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createJobPrepFile.rejected, (state) => {
            state.isLoading = false;
        });
        // get job prep file
        builder.addCase(getJobPrepFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getJobPrepFile.fulfilled, (state, { payload }) => {
            state.jobPrepFile = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getJobPrepFile.rejected, (state) => {
            state.isLoading = false;
        });
        // delete job prep file
        builder.addCase(deleteJobPrepFile.pending, (state) => {
            state.deleteFileStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteJobPrepFile.fulfilled, (state) => {
            state.deleteFileStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteJobPrepFile.rejected, (state, { payload }) => {
            state.deleteFileStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        builder.addCase(readyToAssign.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(readyToAssign.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(readyToAssign.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});
export const {
    reset,
    setUploadProgress,
    setUploadStatus,
    updateUploadProgress,
    updateUploadStatus,
    updateCreateStatus,
} = jobPrepSlice.actions;
export default jobPrepSlice.reducer;
