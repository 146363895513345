import React, {useEffect, useState} from 'react';
import TextArea from "components/TextArea/TextArea";
import DataTable, {TableColumn} from "react-data-table-component";
import {tableStyles} from "components/Table/table";
import {InputCalcSpecJob, JobFileCSList} from "../jobCalcSpec.models";
import Button from "components/Button/Button";
import FileDropzone from "components/FileInput/Dropzone";
import {useForm} from "react-hook-form";
import {Job} from "features/worksheet/process/job/models";
import {isObjectEmpty} from "helpers/checkEmptiness";
import {
    reset as resetJob,
    setUploadProgress,
} from "features/worksheet/process/job/job.reducer";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import {useNavigate} from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal, {SweetAlertIcon} from "sweetalert2";
import DefaultModal from "components/Modal/DefaultModal";
import {
    deleteJobCSFile,
    getJobCSFile,
    readyToQC,
    reset, updateCSNote, uploadJobCSFile
} from "../jobCalcSpec.reducer";
import {HttpClientError, RequestStatus} from "http-client";
import {deletedStatusOption, savedStatusOption} from "helpers/alertLabel";
import {MdDownload, MdClose} from "react-icons/md";
import {darkGreen, darkRed} from "../../../../../constants";
import FileProgress from "../../../../basic/fileAttachment/components/FileProgress";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import FileAttachmentApi from "../../../../basic/fileAttachment/fileAttachment.api";

const JobCalcSpecForm = ({jobId = undefined}:JobCalcSpecFormProps) => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const { register, handleSubmit, reset: resetForm, formState: { errors } } =
        useForm<InputCalcSpecJob>();

    const [showAttachmentModal, setShowAttachmentModal] =
        useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [localFiles, setLocalFiles] = useState<Array<File>>([]);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const singleJobState = useAppSelector(
        (state: RootState) => state.job.single ?? ({} as Job)
    );
    const statusJob = useAppSelector((state: RootState) => state.job.status);
    const errorJob = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );
    const uploadProgresses = useAppSelector(
        (state: RootState) => state.jobCalcSpec.uploadProgress ?? []
    );
    const uploadStatus = useAppSelector((state: RootState) => state.jobCalcSpec.uploadStatus ?? []);
    const deleteFileStatus = useAppSelector(
        (state: RootState) => state.jobCalcSpec.deleteFileStatus
    );
    const jobCSFile = useAppSelector(
        (state: RootState) => state.jobCalcSpec.jobCSFile ?? []
    );
    const userId = useAppSelector(
        (state: RootState) => state.user.userAuth?.user_id
    );
    const error = useAppSelector(
        (state: RootState) => state.jobCalcSpec.error ?? ({} as HttpClientError)
    );
    const status = useAppSelector((state: RootState) => state.jobCalcSpec.status);

    useEffect(() => {
        if (jobId && jobId !== "" && userId !== undefined) {
            dispatch(getJobCSFile({job_id: jobId as string, employee_id: userId as string}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId, userId]);

    useEffect(() => {
        if (!isObjectEmpty(singleJobState)) {
            resetForm({
                calcspec_notes: singleJobState.calcspec_notes,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleJobState]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(setUploadProgress([]));
                return navigate('/worksheet/process/calc-spec', { replace: true });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    useEffect(() => {
        if (deleteFileStatus !== undefined && deleteFileStatus !== RequestStatus.pending) {
            MySwal.fire(
                deletedStatusOption(
                    deleteFileStatus === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getJobCSFile({job_id: jobId as string, employee_id: userId as string}));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteFileStatus, error]);

    useEffect(() => {
        if (statusJob !== undefined && statusJob !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    statusJob === RequestStatus.success,
                    errorJob.data?.message
                )
            ).then(() => {
                dispatch(resetJob());
                dispatch(setUploadProgress([]));
                onModalAttachmentClose();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusJob, errorJob]);

    const columnsAttachment: TableColumn<JobFileCSList>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Date',
            selector: (row) => row.ts_created,
            format: (row) => row.ts_created !== null ? new Date(row.ts_created).toLocaleDateString('en-US') : "-",
            sortable: true,
        },
        {
            name: 'CS',
            selector: (row) => row.employee_name ?? '-',
            sortable: true,
        },
        {
            name: 'File Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Action',
            width: '150px',
            cell: (row) => (
                <>
                    <a
                        href={
                            FileAttachmentApi.getMediaEndpoint(row.path ?? "")
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MdDownload className="mr-2 text-2xl text-primary-color" />
                    </a>
                    <button type={'button'} onClick={() => onFileDelete(row.id)}>
                        <MdClose className="mr-2 text-2xl text-secondary-color" />
                    </button>
                </>
            ),
        },
    ];

    const onFileChange = (files: Array<File>) => {
        setLocalFiles(files);
    };

    const onModalAttachmentClose = () => {
        setLocalFiles([]);
        dispatch(reset());
        dispatch(getJobCSFile({job_id: jobId as string, employee_id: userId as string}));
        setShowAttachmentModal(false);
    };

    const onFileDelete = (fileId: string) => {
        MySwal.fire({
            text: `Are you sure you want to delete this file?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: darkRed,
            cancelButtonColor: darkGreen,
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJobCSFile(fileId));
            }
        });
    };

    const onQCClicked = () => {
        MySwal.fire(
            {
                title: <span>Ready to QC!</span>,
                html: <div><span>Are you sure? You will not be able to recover this data!</span></div>,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: darkGreen,
                confirmButtonText: 'Yes',
            }
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(readyToQC({job_id: jobId as string}));
            }
        });
    }

    const onUploadModalClose = () => {
        setShowUploadModal(false);
        onModalAttachmentClose();
    }

    const toggleUploadModal = (args: boolean) => {
        setShowUploadModal(args);
        onModalAttachmentClose();
    };

    const CSAttachment = () => {
        const onUpload = () => {
            if (localFiles.length === 0) {
                MySwal.fire(
                    {
                        title: "File Empty!",
                        text: "You haven't selected any file",
                        icon: 'warning' as SweetAlertIcon,
                    }
                )
            } else {
                setShowUploadModal(true);
                dispatch(uploadJobCSFile({
                    job_id: jobId as string ?? "",
                    local_files: localFiles,
                }))
            }
        };

        return (
            <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <FileDropzone
                    label="Attachment"
                    onChange={onFileChange}
                    uploadProgress={uploadProgresses}
                    files={localFiles}
                />

                <div className="flex justify-center my-2">
                    <div className="mx-1">
                        <Button
                            title="Cancel"
                            type="button"
                            onClick={onModalAttachmentClose}
                        />
                    </div>
                    <div className="mx-1">
                        <Button title="Save" type="button" onClick={onUpload} />
                    </div>
                </div>
            </div>
        );
    };

    const onSubmit = (data: InputCalcSpecJob) => {
        dispatch(updateCSNote({
            job_id: jobId as string,
            cs_notes: data.calcspec_notes,
        }));
    };

    return (
        <form>
            <div>
                <TextArea
                    {...register('calcspec_notes')}
                    label="CS Notes"
                    placeholder="Input CS Notes"
                    message={errors.calcspec_notes?.message}
                />
            </div>
            <div className="mb-2 mt-3 flex">
                <div className="flex items-center grow">
                    <label
                        className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
                    >
                        Calc Spec
                    </label>
                </div>

                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCalcSpec, ActionEnum.Attachment) &&
                    <Button
                        title="Add/Remove Attachment"
                        type="button"
                        onClick={() => setShowAttachmentModal(true)}
                    />
                }
            </div>
            <div>
                <DataTable
                    columns={columnsAttachment}
                    data={jobCSFile}
                    pagination
                    noHeader
                    highlightOnHover
                    pointerOnHover
                    customStyles={tableStyles}
                    theme={'solarized'}
                    striped
                    persistTableHead
                />
            </div>
            <div className="mt-3 grid md:grid-cols-1 gap-4">
                <div className="flex flex-grow justify-end">
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCalcSpec, ActionEnum.Edit) &&
                        <div className="mr-2">
                            <Button
                                title="Save"
                                type="submit"
                                onClick={handleSubmit((d) => onSubmit(d))}
                            />
                        </div>
                    }
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCalcSpec, ActionEnum.ReadyToQC) &&
                        <div className="inline">
                            <Button
                                title="READY TO QC"
                                type="button"
                                onClick={onQCClicked}
                            />
                        </div>
                    }

                </div>
            </div>
            <DefaultModal
                title="Calc Spec Attachment"
                show={showAttachmentModal}
                onClose={onModalAttachmentClose}
                body={<CSAttachment />}
            />
            <DefaultModal
                title="Uploading File"
                show={showUploadModal}
                onClose={()=>onUploadModalClose()}
                body={
                    <FileProgress uploadStatus={uploadStatus} uploadProgress={uploadProgresses}  setShowModal={toggleUploadModal} />
                }
            />
        </form>
    );
};

export interface JobCalcSpecFormProps {
    jobId?: string;
}

export default JobCalcSpecForm;
