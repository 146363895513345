import React from 'react';

export type ParentDivider = {
    children: React.ReactNode;
}

type RightComponent = React.FunctionComponent<ParentDivider> ;
type CenterComponent = React.FunctionComponent ;
type LeftComponent = React.FunctionComponent<ParentDivider> ;
type DividerComponent = React.FunctionComponent<ParentDivider> & { RightContent: RightComponent, CenterLine: CenterComponent, LeftContent: LeftComponent };

const Divider: DividerComponent = ({ children }): JSX.Element => <div className="flex flex-col w-full lg:flex-row">{children}</div>;
const LeftContent: RightComponent =  ({ children }): JSX.Element =><div className="grid flex-grow w-1/2 p-5">{children}</div>;
const CenterLine: CenterComponent =  () => <><div className="divider lg:divider-horizontal py-5"></div></>;
const RightContent: RightComponent =  ({ children }): JSX.Element => <div className="grid flex-grow w-1/2 p-5">{children}</div>;


Divider.RightContent = RightContent;
Divider.CenterLine = CenterLine;
Divider.LeftContent = LeftContent;

export default Divider;
