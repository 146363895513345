import { BaseServiceClass } from 'base-service-class';
import httpClient, {ApiResponseWrapper} from 'http-client';
import {
    ChangeMultipleStatus,
    DetailPoleMRE,
    GetByRadius,
    GetPoleRequest,
    InputPole,
    Pole,
    PoleKatapult,
    PoleMRE, RemovePoleAssignReq
} from './models';

export default class PoleApi extends BaseServiceClass {
    static getPoleByJob(args: GetPoleRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<Pole[]>>(`${this.BASE_URL}${this.PATH_POLE.pole_get_by_job}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args,
            })
            .then((res) => res.data);
    }

    static getPoleKatapultByJob(args: GetPoleRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<PoleKatapult[]>>(this.PATH_POLE.pole_katapult_by_job, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args,
            })
            .then((res) => res.data);
    }

    static getPoleMREByJob(args: GetPoleRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<PoleMRE[]>>(this.PATH_POLE.pole_mre_by_job, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args,
            })
            .then((res) => res.data);
    }

    static getDetailPoleMRE(args: GetPoleRequest, token: string) {
        return httpClient
            .get<ApiResponseWrapper<DetailPoleMRE[]>>(this.PATH_POLE.pole_get_mre_detail, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args,
            })
            .then((res) => res.data);
    }

    static getMapPoleByJob(jobId: string, token: string) {
        const path = '/pole/byjobformap';
        return httpClient
            .get<any>(`${this.BASE_URL}${path}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    job_id: jobId,
                },
            })
            .then((res) => res.data);
    }

    static getPoleByRadius(args: GetByRadius, token: string) {
        return httpClient
            .get<any>(`${this.BASE_URL}${this.PATH_POLE.pole_by_radius}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    latitude: args.latitude,
                    longitude: args.longitude,
                    radius: args.radius,
                },
            })
            .then((res) => res.data);
    }

    static createPole(args: InputPole, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_POLE.pole}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }

    static updatePole(args: InputPole, token: string) {
        return httpClient
            .put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_POLE.pole}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }

    static deletePole(id: string, token: string) {
        return httpClient
            .delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_POLE.pole}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    id: id,
                },
            })
            .then((res) => res.data);
    }

    static deleteMultiplePole(pole_ids: string, token: string) {
        return httpClient
            .delete<any>(`${this.BASE_URL}${this.PATH_POLE.pole_multiple_delete}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    pole_ids: pole_ids,
                },
            })
            .then((res) => res.data);
    }

    static changeStatusMultiplePole(args: ChangeMultipleStatus, token: string) {
        return httpClient
            .put<any>(`${this.BASE_URL}${this.PATH_POLE.pole_multiple_edit_status}`, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }

    static exportPoleToCsv(jobId: string, token: string) {
        const path = '/pole/csv/byjob';
        return httpClient
            .get<any>(`${this.BASE_URL}${path}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    job_id: jobId,
                },
            })
            .then((res) => res.data);
    }

    static removePoleAssign(args: RemovePoleAssignReq, token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(this.PATH_POLE.pole_remove_assign, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }

    static removeMultiplePoleAssign(args: RemovePoleAssignReq[], token: string) {
        return httpClient
            .post<ApiResponseWrapper<null>>(this.PATH_POLE.pole_remove_multiple_assign, args, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data);
    }
}
