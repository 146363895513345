import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { JobCategory } from '../models';
import { HttpClientError, RequestStatus } from 'http-client';
import {BiEditAlt} from "react-icons/bi";
import {MdOutlineDeleteForever} from "react-icons/md";
import {deleteJobCategory, getList, reset, setSingle} from "../jobCategory.reducer";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertLabel";
import AgDataGridStatic from "../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";

const JobCategoryTable = ({ ...props}: JobCategoryTableProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const jobCategoryList = useAppSelector(
        (state: RootState) => state.jobCategory.list ?? []
    );
    const status = useAppSelector((state: RootState) => state.jobCategory.status);
    const error = useAppSelector(
        (state: RootState) => state.jobCategory.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        dispatch(getList({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(deletedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                dispatch(getList({}));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const openFormModal = (single: JobCategory) => {
        props.openModal(true);
        dispatch(setSingle(single));
    }

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJobCategory(id));
            }
        });
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex align-middle h-[100%]">
                <>
                    <button
                        onClick={() => openFormModal(props.data)}
                    >
                        <BiEditAlt className="mr-2 text-2xl text-primary-color" />
                    </button>
                    <button onClick={() => onDeleteClicked(props.data?.id)}>
                        <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color"  />
                    </button>
                </>
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'name', headerName: 'Name', filter: 'agTextColumnFilter'},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    return (
        <div className="main-data-grid mt-5">
            {/*<DataTableServerPaging columns={columns} data={jobCategoryList} rows={rows} handlePagingChange={handlePagingChange} />*/}
            <AgDataGridStatic columnDefs={columnDefs} rowData={jobCategoryList}/>
        </div>
    );
};

export interface JobCategoryTableProps {
    openModal: (isEdit: boolean) => void;
}

export default JobCategoryTable;
