import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {InputImportCSV} from "../models";
import SingleFileDropzone from "components/FileInput/SingleDropzone";
import Button from "components/Button/Button";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import {getListNew, importCSV, reset} from "../job.reducer";
import {HttpClientError, RequestStatus} from "http-client";
import {customStatusOption} from "helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {importSchema} from "../constants";

const JobImport = ({...props}: JobImportProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const {
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        clearErrors,
    } = useForm<InputImportCSV>({
        resolver: yupResolver(importSchema),
    });

    const fileWatcher = watch('local_files');

    const importProgress = useAppSelector(
        (state: RootState) => state.job.importProgress ?? 0
    );
    const isLoading = useAppSelector(
        (state: RootState) => state.job.isLoading ?? false
    );
    const status = useAppSelector(
        (state: RootState) => state.job.status
    );
    const error = useAppSelector((state: RootState) => state.job.error ?? ({} as HttpClientError));

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(customStatusOption(status === RequestStatus.success, error.data?.message, `import of '${fileWatcher.name}' completed successfully.`, 'failed to import file: ')).then(() => {
                dispatch(getListNew({skip: 0, take: 10}));
                dispatch(reset());
                props.toggleModal();
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onFileChange = (files: File) => {
        setValue('local_files', files);
        clearErrors('local_files');
    };

    const onSubmit = (data: InputImportCSV) => {
       dispatch(importCSV(data));
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <SingleFileDropzone
                    label="Input CSV File"
                    onChange={onFileChange}
                    uploadProgress={importProgress}
                    file={fileWatcher}
                    message={errors.local_files?.message}
                />
                <div className="pt-2 pb-2 flex justify-end">
                    <Button
                        color="success"
                        type="submit"
                        title="Submit"
                        isLoading={isLoading}
                    />
                </div>
            </form>
        </div>
    );
};

export interface JobImportProps {
    toggleModal: () => void;
}

export default JobImport;
