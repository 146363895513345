import React, {
    useCallback,
    useEffect, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import {deleteStaffReport,
    getStaffReport,
    reset,
} from '../../staffReport.reducer';
import {deletedWarnOption, savedStatusOption} from '../../../../../helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { HttpClientError, RequestStatus } from '../../../../../http-client';
import { RootState } from '../../../../../app/store';
import {BiEditAlt} from "react-icons/bi";
import {MdOutlineDeleteForever} from "react-icons/md";
import {StaffReportModel} from "../../models";
import {getList as getJobList} from "../../../../worksheet/process/job/job.reducer";
import {GetListRequest} from "../../../../worksheet/process/job/models";
import {UserCredential} from "../../../../login/models";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import AddStaffReportForm from "./AddStaffReportForm";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {
    staffReportSortOption, staffReportStatusFilterOptions
} from "../../constants";
import {IoMdAdd} from "react-icons/io";
import {Button as FlowbiteButton} from "flowbite-react";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {dateSetTimeToNow} from "../../../../../helpers/convert";

const AddStaffReportTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = React.useRef<any>();

    // const {
    //     control,
    //     register,
    //     handleSubmit,
    //     watch,
    //     getValues,
    //     reset: resetForm
    // } = useForm<InputStaffReportFilter>({defaultValues: {startDate: undefined, endDate: undefined}});
    //
    // const startDate = watch('startDate');
    // const endDate = watch('endDate');

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const status = useAppSelector(
        (state: RootState) => state.staffReport.status
    );
    const error = useAppSelector(
        (state: RootState) => state.staffReport.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [singleEmployeeWork, setSingleEmployeeWork] = useState<StaffReportModel>({} as StaffReportModel);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getJobList({skip: 0, take: 10000} as GetListRequest));
    }, []);

    useEffect(() => {
        if (user.user_id !== undefined) {
            dispatch(
                getStaffReport({
                    skip: 0,
                    take: 10,
                    userId: user.user_id,
                    isReport: true,
                })
            );
        }
    }, [user]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (status === RequestStatus.success) {
                    closeModal();
                }
                refreshCache(undefined);
                gridRef.current.api.setFilterModel(null);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onDeleteClicked = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteStaffReport(id));
            }
        });
    };

    const openModal = (staff: StaffReportModel, isEdit: boolean) => {
        setSingleEmployeeWork(staff);
        setIsEdit(isEdit);
        setShowModal(true);
    };

    const closeModal = () => {
        setSingleEmployeeWork({} as StaffReportModel);
        setShowModal(false);
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    props.data?.statusName === "Requested" && (
                        <>
                            <button onClick={() => openModal(props.data, true)}>
                                <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                            </button>
                            <button
                                onClick={() =>
                                    onDeleteClicked(props.data?.id)
                                }
                            >
                                <MdOutlineDeleteForever className="mr-2 text-2xl text-secondary-color" />
                            </button>
                        </>
                    )
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'requestDate', headerName: 'Request Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'typeName', headerName: 'Type Add Poles', filter: false,
        },
        {field: 'number_job', headerName: 'Job Number',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'role_name', headerName: 'Position',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'note', headerName: 'Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }, sortable: false,
        },
        {field: 'reqMinute', headerName: 'Req Minutes', sortable: false,
            filter: false,
        },
        {field: 'reqPole', headerName: 'Req Poles', sortable: false,
            filter: false,
        },
        {field: 'rejectionNote', headerName: 'Rejection Notes', sortable: false,
            filter: false,
        },
        {field: 'approvalPole', headerName: 'Approval Poles', sortable: false, filter: false,
        },
        {field: 'statusName', headerName: 'Status',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: staffReportStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            filter: false,
            sortable: false,
        },
    ]);

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(staffReportSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom, dateTo} = params.request.filterModel?.requestDate || {};

                dispatch(
                    getStaffReport({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        userId: user.user_id,
                        isReport: true,
                        startDate: dateFrom !== undefined ? dateSetTimeToNow(dateFrom) : undefined,
                        endDate: dateTo !== undefined ? dateSetTimeToNow(dateTo) : undefined,
                        // filterByName: params.request.filterModel?.name?.filter,
                        // filterByNIP: params.request.filterModel?.nip?.filter,
                        // filterByTeam: params.request.filterModel?.teamName?.filter,
                        filterByNotes: params.request.filterModel?.note?.filter,
                        filterByJobNumber: params.request.filterModel?.number_job?.filter,
                        filterByPosition: params.request.filterModel?.role_name?.filter,
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    return (
        <div>
            <div className="mt-3">
                <div>
                    {/*<form onSubmit={handleSubmit(onFilterSubmit)}>*/}
                    {/*    <Divider>*/}
                    {/*        <Divider.LeftContent>*/}
                    {/*            <div>*/}
                    {/*                <RangeDatePicker label={"Filter by Date"} startName='startDate' endName='endDate'*/}
                    {/*                                 startValue={new Date(startDate)} endValue={new Date(endDate)}*/}
                    {/*                                 control={control}/>*/}
                    {/*                <BasicSelectInput*/}
                    {/*                    label="Status"*/}
                    {/*                    options={staffReportFilterStatusOption}*/}
                    {/*                    {...register('status')}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </Divider.LeftContent>*/}
                    {/*        <Divider.RightContent>*/}
                    {/*            <div>*/}
                    {/*                <BasicSelectInput*/}
                    {/*                    label="Type"*/}
                    {/*                    options={staffReportFilterTypeOption}*/}
                    {/*                    {...register('type')}*/}
                    {/*                />*/}
                    {/*                <InputText*/}
                    {/*                    {...register('filter')}*/}
                    {/*                    placeholder="Search by job number"*/}
                    {/*                    label="Filter by Job Number"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div className="flex items-center justify-end">*/}
                    {/*                <div className="flex items-center justify-end">*/}
                    {/*                    <div className={"mx-1"}>*/}
                    {/*                        <Button*/}
                    {/*                            color="success"*/}
                    {/*                            type="button"*/}
                    {/*                            title="Reset"*/}
                    {/*                            onClick={onResetClicked}*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                    <div className={"mx-1"}>*/}
                    {/*                        <Button*/}
                    {/*                            color="success"*/}
                    {/*                            type="submit"*/}
                    {/*                            title="Filter"*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </Divider.RightContent>*/}
                    {/*    </Divider>*/}
                    {/*</form>*/}
                </div>
                <div className="main-data-grid fielding mt-5">
                    {/*<div className="mb-2">*/}
                    {/*    <Button title="Add New Record" onClick={() => openModal({} as StaffReportModel, false)}/>*/}
                    {/*</div>*/}
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                            getServerSideDataSource={getServerSideDataSource}
                                            supportSaveTableState
                                            tableStateName={'report-staff-report'}
                                            isCustomHeader={true}
                                            customChildHeaderComponent={
                                                <>
                                                    <FlowbiteButton
                                                        onClick={() => {
                                                            openModal({} as StaffReportModel, false)
                                                        }}
                                                        size="xs"
                                                        color="success"
                                                    >
                                                        <IoMdAdd className="mr-2 h-5 w-5"/>
                                                        Add New Record
                                                    </FlowbiteButton>
                                                </>
                                            }
                                            defaultPageSize={20}
                    />
                </div>
                <DefaultModal
                    title={`${isEdit ? "Edit" : "Add"} Employee Work`}
                    show={showModal}
                    onClose={closeModal}
                    body={<AddStaffReportForm singleEmployeeWork={singleEmployeeWork} userId={user.user_id}
                                              positionId={user.position_id as string} isEdit={isEdit}/>}
                />
            </div>
        </div>
    );
};

export default AddStaffReportTable;
