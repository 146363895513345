import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {Fielding, GetListRequest, InputFilter} from "./models";

export default class FieldingApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<Fielding[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_attachment_get_fielding_na}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static exportToCsv(args: InputFilter, token: string) {
        const path = "/api/JobAttachment/ExportJobFieldingToExcel"
        return httpClient
            .post<any>(`${this.BASE_URL}${path}`, {}, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    startDate: args.startDate,
                    endDate: args.endDate,
                }
            })
            .then((res) => res.data);
    }
}