import React, {useCallback, useEffect, useState} from 'react';
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {useAppDispatch, useAppSelector} from "../../../../helpers/redux";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import {exportBillJob, getBillJobWithFilter, getWeekendDate} from "../../billJob/billJob.reducer";
import {BiEditAlt} from "react-icons/bi";
import {Link, useNavigate} from "react-router-dom";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import ReportBillingOverview from "./ReportBillingOverview";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {getSortModel, getSortType} from "../../../approval/constants";
import {billJobReportSortOptions, billStatusFilterOptions, typeFilterOptions} from "../../constants";
import {dateSetTimeToNow} from "../../../../helpers/convert";
import MonthYearPickerState from "../../../../components/DatePicker/MonthYearPickerState";
import BasicSelectInput from "../../../../components/SelectInput/BasicSelectInput";
import {RootState} from "../../../../app/store";
import Button from "../../../../components/Button/Button";
import {Button as FlowbiteButton} from "flowbite-react";
import {PiMicrosoftExcelLogoDuotone} from "react-icons/pi";

const ReportBillingTable = () => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const gridRef = React.useRef<any>();

    const [showModal, setShowModal] = useState<boolean>(false);

    const [selectedNumberJobId, setSelectedNumberJobId] = useState<string | undefined>(undefined);
    const [selectedNumberJob, setSelectedNumberJob] = useState<string | undefined>(undefined);

    const [monthSelected, setMonthSelected] = useState<Date>(new Date());
    const [weekendSelected, setWeekendSelected] = useState<string>("all");

    const weekendDateOptions = useAppSelector(
        (state: RootState) => state.billJob.weekendDateOptions ?? []
    );

    useEffect(() => {
        if (monthSelected !== undefined) {
            dispatch(getWeekendDate({
                month: monthSelected.getMonth() + 1,
                year: monthSelected.getFullYear()
            }))
        }

    }, [monthSelected]);


    useEffect(() => {
        if (gridRef.current && gridRef.current.api !== undefined) {
            gridRef.current.api.setFilterModel({
                monthYearFilter: {
                    filterType: "text",
                    type: "contains",
                    filter: monthSelected.toISOString()
                },
                weekendDateFilter: {
                    filterType: "text",
                    type: "contains",
                    filter: weekendSelected
                },
            })
        }
    }, [gridRef.current]);

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        const onEditClicked = (id: number) => {
            return navigate(`/bill-job/bill-job/${id}`, {replace: true});
        }

        return (
            <div className="flex items-center h-[100%]">
                <Link to={`/bill-job/bill-job/${props.data.id}`}>
                    <button>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                </Link>
            </div>
        )
    }

    const JobNumberLinkCellRender = (props: any) => {

        const onDetailClicked = () => {
            setShowModal(true);
            setSelectedNumberJobId(props.data.numberJobId);
            setSelectedNumberJob(props.data.numberJob);
        }

        return (
            <Link
                to={`#`}
                className="underline text-blue-700"
                onClick={onDetailClicked}
            >
                {props.data?.numberJob}
            </Link>
        )
    };

    const NotesCellRender = (props: any) => {
        const [isExpand, setIsExpand] = useState<boolean>(false);

        if (props.data === undefined) {
            return null;
        }

        const toggleExpand = () => {
            setIsExpand(!isExpand);
        }

        return <div>
            {props.data.notes?.length > 10 ? isExpand ? props.data.notes : props.data.notes?.substring(0, 10) : props.data.notes}{props.data.notes?.length > 10 &&
            <span className="text-cyan-600 hover:underline dark:text-cyan-500" onClick={toggleExpand}>...</span>}
        </div>
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {
            field: 'ts_created', headerName: 'Entry Date', cellDataType: 'date', filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'upload_date', headerName: 'Upload Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'reqNumber', headerName: 'Req Number', filter: false, sortable: false
        },
        {
            field: 'project', headerName: 'Project',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'koj', headerName: 'KoJ',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'number', headerName: 'ID Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'numberJob',
            headerName: 'Job Number',
            sortable: false,
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'otherNumberJob', headerName: 'Other Number',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'district', headerName: 'District',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'poNumberJob', headerName: 'PO Number', filter: false, sortable: false},
        {field: 'typeJob', headerName: 'Type Job',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'salesOrderJob', headerName: 'Sales Order',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'qc_employee', headerName: 'User',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'typeName', headerName: 'Type',
            filter: 'agNumberColumnFilter',
            filterParams: {
                filterOptions: typeFilterOptions,
                maxNumConditions: 1,
            }
        },
        {
            field: 'statusName', headerName: 'Status',
            filter: 'agNumberColumnFilter',
            filterParams: {
                filterOptions: billStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {
            field: 'totalPole', headerName: 'Total Pole',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'job_complete_date', headerName: 'Complete Date', cellDataType: 'date', filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'department', headerName: 'Department',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {field: 'departmentOwner', headerName: 'Department Owner', filter: false, sortable: false},
        {
            field: 'notes', headerName: 'Notes',
            filter: 'agTextColumnFilter', filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            },
            cellRenderer: NotesCellRender
        },
        {
            headerName: 'Month Year Filter',
            field: 'monthYearFilter',
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Weekend Date Filter',
            field: 'weekendDateFilter',
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(billJobReportSortOptions, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
                const {dateFrom: startEntryDate, dateTo: endEntryDate} = params.request.filterModel?.ts_created || {};
                const {
                    dateFrom: startUploadDate,
                    dateTo: endUploadDate
                } = params.request.filterModel?.upload_date || {};
                const {
                    dateFrom: startCompleteDate,
                    dateTo: endCompleteDate
                } = params.request.filterModel?.job_complete_date || {};
                const _monthYearSelected = params.request.filterModel?.monthYearFilter?.filter !== undefined ? new Date(params.request.filterModel?.monthYearFilter?.filter) : new Date();

                dispatch(getBillJobWithFilter(
                    {
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        startEntryDate: startEntryDate !== undefined ? dateSetTimeToNow(startEntryDate) : undefined,
                        endEntryDate: endEntryDate !== undefined ? dateSetTimeToNow(endEntryDate) : undefined,
                        startUploadDate: startUploadDate !== undefined ? dateSetTimeToNow(startUploadDate) : undefined,
                        endUploadDate: endUploadDate !== undefined ? dateSetTimeToNow(endUploadDate) : undefined,
                        project: params.request.filterModel?.project?.filter,
                        koj: params.request.filterModel?.koj?.filter,
                        numberJobId: params.request.filterModel?.numberJob?.filter,
                        numberId: params.request.filterModel?.number?.filter,
                        type: params.request.filterModel?.typeName?.type === "all" || params.request.filterModel?.typeName?.type === undefined ? undefined : parseInt(params.request.filterModel?.typeName?.type ?? "0"),
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        totalPole: params.request.filterModel?.totalPole?.filter,
                        startCompleteDate: startCompleteDate !== undefined ? dateSetTimeToNow(startCompleteDate) : undefined,
                        endCompleteDate: endCompleteDate !== undefined ? dateSetTimeToNow(endCompleteDate) : undefined,
                        department: params.request.filterModel?.department?.filter,
                        notes: params.request.filterModel?.notes?.filter,
                        district: params.request.filterModel?.district?.filter,
                        typeJob: params.request.filterModel?.typeJob?.filter,
                        user: params.request.filterModel?.qc_employee?.filter,
                        other_number: params.request.filterModel?.otherNumberJob?.filter,
                        sales_order: params.request.filterModel?.salesOrderJob?.filter,
                        weekendDate: params.request.filterModel?.weekendDateFilter?.filter === 'all' ? undefined : params.request.filterModel?.weekendDateFilter?.filter,
                        month: params.request.filterModel?.weekendDateFilter?.filter === 'all' ? _monthYearSelected.getMonth() + 1 : undefined,
                        year: params.request.filterModel?.weekendDateFilter?.filter === 'all' ? _monthYearSelected.getFullYear() : undefined,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sort: sortModel !== undefined ? sortType : undefined
                    }
                )).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            }
        }
    }

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setSelectedNumberJobId(undefined);
        setSelectedNumberJob(undefined);
    };

    const onSearchClicked = () => {
        refreshCache(undefined);
        gridRef.current.api.setFilterModel({
            monthYearFilter: {
                filterType: "text",
                type: "contains",
                filter: monthSelected.toISOString()
            },
            weekendDateFilter: {
                filterType: "text",
                type: "contains",
                filter: weekendSelected
            },
        })
    }

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    const onExportHandler = () => {
        const filterModel = gridRef.current.api.getFilterModel();
        // const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(billJobReportSortOptions, params.request.sortModel[0].colId) ?? undefined;
        // const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;
        const {dateFrom: startEntryDate, dateTo: endEntryDate} = filterModel?.ts_created || {};
        const {
            dateFrom: startUploadDate,
            dateTo: endUploadDate
        } = filterModel?.upload_date || {};
        const {
            dateFrom: startCompleteDate,
            dateTo: endCompleteDate
        } = filterModel?.job_complete_date || {};
        const _monthYearSelected = filterModel?.monthYearFilter?.filter !== undefined ? new Date(filterModel?.monthYearFilter?.filter) : new Date();

        dispatch(exportBillJob({
            skip: gridRef.current.api.paginationGetCurrentPage() * gridRef.current.api.paginationGetPageSize(),
            take: gridRef.current.api.paginationGetPageSize(),
            startEntryDate: startEntryDate !== undefined ? dateSetTimeToNow(startEntryDate) : undefined,
            endEntryDate: endEntryDate !== undefined ? dateSetTimeToNow(endEntryDate) : undefined,
            startUploadDate: startUploadDate !== undefined ? dateSetTimeToNow(startUploadDate) : undefined,
            endUploadDate: endUploadDate !== undefined ? dateSetTimeToNow(endUploadDate) : undefined,
            project: filterModel?.project?.filter,
            koj: filterModel?.koj?.filter,
            numberJobId: filterModel?.numberJob?.filter,
            numberId: filterModel?.number?.filter,
            type: filterModel?.typeName?.type === "all" || filterModel?.typeName?.type === undefined ? undefined : parseInt(filterModel?.typeName?.type ?? "0"),
            status: filterModel?.statusName?.type === "all" || filterModel?.statusName?.type === undefined ? undefined : parseInt(filterModel?.statusName?.type ?? "0"),
            totalPole: filterModel?.totalPole?.filter,
            startCompleteDate: startCompleteDate !== undefined ? dateSetTimeToNow(startCompleteDate) : undefined,
            endCompleteDate: endCompleteDate !== undefined ? dateSetTimeToNow(endCompleteDate) : undefined,
            department: filterModel?.department?.filter,
            notes: filterModel?.notes?.filter,
            district: filterModel?.district?.filter,
            typeJob: filterModel?.typeJob?.filter,
            user: filterModel?.qc_employee?.filter,
            other_number: filterModel?.otherNumberJob?.filter,
            sales_order: filterModel?.salesOrderJob?.filter,
            weekendDate: filterModel?.weekendDateFilter?.filter === 'all' ? undefined : filterModel.weekendDateFilter?.filter,
            month: filterModel?.weekendDateFilter?.filter === 'all' ? _monthYearSelected.getMonth() + 1 : undefined,
            year: filterModel?.weekendDateFilter?.filter === 'all' ? _monthYearSelected.getFullYear() : undefined,
        }));
    }

    return (
        <div>
            <div className="mt-3">
                <div>
                    <div className={"flex"}>
                        <div className="w-1/4 mr-1">
                            <MonthYearPickerState
                                name="monthFilter"
                                label="Month / Year"
                                placeholderText="Select month & year"
                                selected={monthSelected}
                                onChange={(date) => setMonthSelected(date ?? new Date())}
                            />
                        </div>
                        <div className="w-1/4 mx-1">
                            <BasicSelectInput
                                label="Week Ending"
                                options={weekendDateOptions}
                                name={"weekEndingFilter"}
                                onChange={(e) => setWeekendSelected(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center ml-1">
                            <div className="mt-5">
                                <Button
                                    color="success"
                                    type="button"
                                    title="Search"
                                    onClick={onSearchClicked}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-data-grid fielding mt-5">
                <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                        getServerSideDataSource={getServerSideDataSource} supportSaveTableState
                                        tableStateName={'report-billing'}
                                        defaultFilter={{
                                            monthYearFilter: {
                                                filterType: "text",
                                                type: "contains",
                                                filter: monthSelected.toISOString()
                                            },
                                            weekendDateFilter: {
                                                filterType: "text",
                                                type: "contains",
                                                filter: weekendSelected
                                            },
                                        }}
                                        isCustomHeader={true}
                                        customChildHeaderComponent={
                                            <>
                                                <FlowbiteButton
                                                    onClick={onExportHandler}
                                                    size="xs"
                                                    color="success"
                                                >
                                                    <PiMicrosoftExcelLogoDuotone className="mr-2 h-5 w-5"/>
                                                    Export to Excel
                                                </FlowbiteButton>
                                            </>
                                        }
                                        defaultPageSize={20}
                />
            </div>
            <DefaultModal
                title="Compare"
                show={showModal}
                onClose={toggleModal}
                size={'full'}
                body={<ReportBillingOverview numberJobId={selectedNumberJobId} numberJob={selectedNumberJob}/>}
            />
        </div>
    );
};

export default ReportBillingTable;
