import { HttpClientError, RequestStatus } from '../../../http-client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import {
    ChangeMultipleStatus, DetailPoleMRE,
    GetByRadius, GetPoleRequest,
    InputPole,
    Pole, PoleKatapult, PoleMRE, RemovePoleAssignReq,
} from './models';
import PoleApi from './pole.api';
import FileSaver from 'file-saver';

export interface PoleSlice {
    poleByJob?: Array<Pole>;
    poleByJobListId?: Array<string>;
    poleMapByJob?: Array<Pole>;
    poleByRadius?: Array<Pole>;
    poleKatapultByJob?: Array<PoleKatapult>;
    poleMREByJob?: Array<PoleMRE>;
    poleMREDetail?: Array<DetailPoleMRE>;
    poleKatapultByJobListId?: Array<string>;
    poleByJobRows?: number;
    poleMapByJobRows?: number;
    poleByRadiusRows?: number;
    poleKatapultByJobRows?: number;
    poleMREByJobRows?: number;
    poleMREDetailRows?: number;
    isLoading?: boolean;
    isPoleRadiusLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getPoleByJob = createAsyncThunk(
    'pole/getPoleByJob',
    async (args: GetPoleRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.getPoleByJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getPoleKatapultByJob = createAsyncThunk(
    'pole/getPoleKatapultByJob',
    async (args: GetPoleRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.getPoleKatapultByJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getPoleMREByJob = createAsyncThunk(
    'pole/getPoleMREByJob',
    async (args: GetPoleRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.getPoleMREByJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getDetailPoleMRE = createAsyncThunk(
    'pole/getDetailPoleMRE',
    async (args: GetPoleRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.getDetailPoleMRE(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);


export const getMapPoleByJob = createAsyncThunk(
    'pole/getMapPoleByJob',
    async (args: GetPoleRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.getPoleByJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getPoleByRadius = createAsyncThunk(
    'pole/getPoleByRadius',
    async (args: GetByRadius, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.getPoleByRadius(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createPole = createAsyncThunk(
    'pole/createPole',
    async (args: InputPole, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.createPole(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updatePole = createAsyncThunk(
    'pole/updatePole',
    async (args: InputPole, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.updatePole(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deletePole = createAsyncThunk(
    'pole/deletePole',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.deletePole(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteMultiplePole = createAsyncThunk(
    'pole/deleteMultiplePole',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.deleteMultiplePole(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const changeStatusMultiplePole = createAsyncThunk(
    'pole/changeStatusMultiplePole',
    async (args: ChangeMultipleStatus, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await PoleApi.changeStatusMultiplePole(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const exportPoleToCsv = createAsyncThunk(
    'pole/exportPoleToCsv',
    async (jobId: string, { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await PoleApi.exportPoleToCsv(jobId, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const removePoleAssign = createAsyncThunk(
    'pole/removePoleAssign',
    async (args: RemovePoleAssignReq, { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await PoleApi.removePoleAssign(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const removeMultiplePoleAssign = createAsyncThunk(
    'pole/removeMultiplePoleAssign',
    async (args: RemovePoleAssignReq[], { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await PoleApi.removeMultiplePoleAssign(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const poleSlice = createSlice({
    name: 'poleState',
    initialState: {} as PoleSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
        },
        resetPoleByRadius: (state) => {
            state.poleByRadius = [];
            state.poleByRadiusRows = 0;
        }
    },
    extraReducers: (builder) => {
        // get pole by job
        builder.addCase(getPoleByJob.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPoleByJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.poleByJob = payload.data;
            state.poleByJobListId = payload.id_list ?? [];
            state.poleByJobRows = payload.rows;
        });
        builder.addCase(getPoleByJob.rejected, (state) => {
            state.isLoading = false;
        });
        // get pole mre by job
        builder.addCase(getPoleMREByJob.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPoleMREByJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.poleMREByJob = payload.data;
            state.poleMREByJobRows = payload.rows;
        });
        builder.addCase(getPoleMREByJob.rejected, (state) => {
            state.isLoading = false;
        });
        // get pole mre detail by job
        builder.addCase(getDetailPoleMRE.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDetailPoleMRE.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.poleMREDetail = payload.data;
            state.poleMREDetailRows = payload.rows;
        });
        builder.addCase(getDetailPoleMRE.rejected, (state) => {
            state.isLoading = false;
        });
        // get pole katapult by job
        builder.addCase(getPoleKatapultByJob.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPoleKatapultByJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.poleKatapultByJob = payload.data;
            state.poleKatapultByJobListId = payload.id_list ?? [];
            state.poleKatapultByJobRows = payload.rows;
        });
        builder.addCase(getPoleKatapultByJob.rejected, (state) => {
            state.isLoading = false;
        });
        // get map pole by job
        builder.addCase(getMapPoleByJob.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMapPoleByJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.poleMapByJob = payload.data;
            state.poleMapByJobRows = payload.rows;
        });
        builder.addCase(getMapPoleByJob.rejected, (state) => {
            state.isLoading = false;
        });
        // get pole by radius
        builder.addCase(getPoleByRadius.pending, (state) => {
            state.isPoleRadiusLoading = true;
        });
        builder.addCase(getPoleByRadius.fulfilled, (state, { payload }) => {
            state.isPoleRadiusLoading = false;
            state.poleByRadius = payload.data;
            state.poleByRadiusRows = payload.rows;
        });
        builder.addCase(getPoleByRadius.rejected, (state) => {
            state.isPoleRadiusLoading = false;
        });
        // create data reducer
        builder.addCase(createPole.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createPole.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createPole.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // update data reducer
        builder.addCase(updatePole.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updatePole.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updatePole.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // delete data reducer
        builder.addCase(deletePole.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deletePole.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deletePole.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // delete multiple data reducer
        builder.addCase(deleteMultiplePole.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteMultiplePole.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteMultiplePole.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // change status multiple data reducer
        builder.addCase(changeStatusMultiplePole.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(changeStatusMultiplePole.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(
            changeStatusMultiplePole.rejected,
            (state, { payload }) => {
                state.status = RequestStatus.failed;
                state.error = payload as HttpClientError;
                state.isLoading = false;
            }
        );
        // export to csv download reducer
        builder.addCase(exportPoleToCsv.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(exportPoleToCsv.fulfilled, (state, { payload }) => {
            FileSaver.saveAs(
                payload,
                'pole' + new Date().toISOString() + '.csv'
            );
            state.isLoading = false;
        });
        builder.addCase(exportPoleToCsv.rejected, (state, { payload }) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // remove pole assign reducer
        builder.addCase(removePoleAssign.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(removePoleAssign.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(removePoleAssign.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // remove multiple pole assign reducer
        builder.addCase(removeMultiplePoleAssign.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(removeMultiplePoleAssign.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(removeMultiplePoleAssign.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});
export const { reset, resetPoleByRadius } = poleSlice.actions;
export default poleSlice.reducer;
