import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {
    EmployeeHoliday,
    EmployeeHolidayReq,
    GetListRequest,
    HolidayCalendar,
    HolidayCalendarReq,
} from "./models";

export default class HolidayApi extends BaseServiceClass {
    static getHolidayCalendarList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<HolidayCalendar[]>>(`${this.BASE_URL}${this.PATH_MASTER.holiday_calendar}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getEmployeeHolidayList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<EmployeeHoliday[]>>(`${this.BASE_URL}${this.PATH_MASTER.employee_holiday}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static createHolidayCalendar(args: HolidayCalendarReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.holiday_calendar}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static createEmployeeHoliday(args: EmployeeHolidayReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.employee_holiday}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateHolidayCalendar(args: HolidayCalendarReq, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.holiday_calendar}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateEmployeeHoliday(args: EmployeeHolidayReq, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.employee_holiday}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deleteHolidayCalendar(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.holiday_calendar}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }

    static deleteEmployeeHoliday(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.employee_holiday}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
}