import { HttpClientError, RequestStatus } from '../../../http-client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import AttachmentApi from './attachment.api';
import {
    FullJobAttachment,
    GetJobAttachmentRequest, GetJobLinkAttachmentRequest,
    JobAttachment,
    JobAttachmentRequest,
    JobAttachmentType, JobLinkAttachment
} from "./models";

export interface AttachmentSlice {
    list?: JobAttachment[];
    singleList?: JobAttachment[];
    typeList?: JobAttachmentType[];
    linkList?: JobLinkAttachment[];
    rows?: number;
    linkRows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getAttachment = createAsyncThunk(
    'attachment/getAttachment',
    async (args: GetJobAttachmentRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await AttachmentApi.getAttachment(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getAttachmentSingleList = createAsyncThunk(
    'attachment/getAttachmentSingleList',
    async (jobId: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await AttachmentApi.getAttachmentSingleList(jobId, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getAttachmentType = createAsyncThunk(
    'attachment/getAttachmentType',
    async (args: GetJobAttachmentRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await AttachmentApi.getAttachmentType(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getJobLinkAttachment = createAsyncThunk(
    'attachment/getJobLinkAttachment',
    async (args: GetJobLinkAttachmentRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await AttachmentApi.getJobLinkAttachment(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createAttachment = createAsyncThunk(
    'attachment/createAttachment',
    async (args: JobAttachmentRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await AttachmentApi.createAttachment(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateAttachment = createAsyncThunk(
    'attachment/updateAttachment',
    async (args: JobAttachmentRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await AttachmentApi.updateAttachment(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateFullAttachment = createAsyncThunk(
    'attachment/updateFullAttachment',
    async (args: FullJobAttachment, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await AttachmentApi.updateFullAttachment(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const attachmentSlice = createSlice({
    name: 'attachmentState',
    initialState: {} as AttachmentSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
        },
    },
    extraReducers: (builder) => {
        // get attachment list
        builder.addCase(getAttachment.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAttachment.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.list = payload.data;
            state.rows = payload.rows;
        });
        builder.addCase(getAttachment.rejected, (state) => {
            state.isLoading = false;
        });
        // get attachment single list
        builder.addCase(getAttachmentSingleList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAttachmentSingleList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.singleList = payload.data;
        });
        builder.addCase(getAttachmentSingleList.rejected, (state) => {
            state.isLoading = false;
        });
        // get attachment type list
        builder.addCase(getAttachmentType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAttachmentType.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.typeList = payload.data;
            state.rows = payload.rows;
        });
        builder.addCase(getAttachmentType.rejected, (state) => {
            state.isLoading = false;
        });
        // get job link attachment attachment list
        builder.addCase(getJobLinkAttachment.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getJobLinkAttachment.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.linkList = payload.data;
            state.linkRows = payload.rows;
        });
        builder.addCase(getJobLinkAttachment.rejected, (state) => {
            state.isLoading = false;
        });
        // create data reducer
        builder.addCase(createAttachment.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createAttachment.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createAttachment.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // update data reducer
        builder.addCase(updateAttachment.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateAttachment.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateAttachment.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // update full data reducer
        builder.addCase(updateFullAttachment.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateFullAttachment.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateFullAttachment.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});
export const { reset } = attachmentSlice.actions;
export default attachmentSlice.reducer;
