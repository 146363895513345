import React, {useEffect, useState} from 'react';
import { Employee, InputModel } from '../models';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {educationOptions, relationOptions, schema} from '../constants';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {isArrayEmpty, isObjectEmpty} from '../../../../helpers/checkEmptiness';
import { useAppSelector } from '../../../../helpers/redux';
import { RootState } from '../../../../app/store';
import InputPassword from '../../../../components/TextInput/InputPassword';
import BasicSelectInput, {Option} from '../../../../components/SelectInput/BasicSelectInput';
import TextArea from '../../../../components/TextArea/TextArea';
import BasicDatePicker from "../../../../components/DatePicker/BasicDatePicker";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import {MultiValue} from "react-select";

const EmployeeForm = ({
    isLoading = false,
    isEdit = false,
    isMyProfile = false,
    ...props
}: EmployeeFormProps) => {
    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
        watch
    } = useForm<InputModel>({
        resolver: yupResolver(schema),
        defaultValues: {
            date_entry: new Date().toISOString(),
            total_children: "0",
        }
    });

    const watchPositionName = watch('position');
    const watchTeamId = watch('team_id');

    const singleState = useAppSelector(
        (state: RootState) => state.employee.single ?? ({} as Employee)
    );

    const positionOptions = useAppSelector(
        (state: RootState) => state.position.options ?? []
    );
    const optionByName = useAppSelector(
        (state: RootState) => state.position.optionByName ?? []
    );

    const teamOptions = useAppSelector(
        (state: RootState) => state.team.options ?? []
    );

    const [optionValues, setOptionValues] = useState<Option[]>([]);

    useEffect(() => {
        if (!isObjectEmpty(singleState)) {
            // destructuring an object
            let {position_name, team_name, ...rest } = singleState;
            reset({...rest, total_children: rest.total_children.toString()});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleState]);

    useEffect(() => {
        if (!isArrayEmpty(positionOptions)) {
            if (isEdit && !isObjectEmpty(singleState)) {
                let _position = singleState.position_id?.split(',');
                setOptionValues(positionOptions.filter((item) => _position?.includes(item.value)));
                setValue('position', singleState.position ?? "");
                setValue('team_id', singleState.team_id ?? "");
            }
        }
    }, [isEdit, singleState, positionOptions]);

    useEffect(() => {
        if (!isEdit && !isArrayEmpty(positionOptions) && !isArrayEmpty(teamOptions)) {
            setValue('position', positionOptions[0]?.text);
            setValue('team_id', teamOptions[0]?.value);
        }
    }, [isEdit, positionOptions, teamOptions]);
    // useEffect(() => {
    //     if (!isEdit) {
    //         reset({} as InputModel);
    //         setValue('position_id', positionOptions[0]?.value);
    //         setValue('team_id', teamOptions[0]?.value);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isEdit, positionOptions, teamOptions]);

    const onSubmit = (data: InputModel) => props.onSubmit(data);

    const onRolesChange = (val: MultiValue<Option>) => {
        let _position = val.map((item) => item.value);
        setValue('position_id', _position.toString());
        setOptionValues(val as Option[]);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-3 grid md:grid-cols-2 gap-4">
                <div>
                    <InputText
                        {...register('nip')}
                        label="NIP"
                        placeholder="Input NIP"
                        message={errors.nip?.message}
                    />
                    <InputText
                        {...register('name')}
                        label="Employee Name"
                        placeholder="Input employee name"
                        message={errors.name?.message}
                    />
                    <InputText
                        {...register('username')}
                        label="Employee Username"
                        placeholder="Input employee username"
                        message={errors.username?.message}
                    />
                    <InputPassword
                        {...register('password')}
                        label="Employee Password"
                        message={errors.password?.message}
                        placeholder="Input password"
                    />
                    <InputText
                        {...register('phone')}
                        label="Employee Phone"
                        placeholder="Input employee phone"
                        message={errors.phone?.message}
                    />
                    <InputText
                        {...register('number_employee')}
                        label="Employee Number"
                        placeholder="Input employee number"
                        message={errors.number_employee?.message}
                    />
                    <InputText
                        {...register('number_ktp')}
                        label="NIK"
                        placeholder="Input NIK"
                        message={errors.number_ktp?.message}
                    />
                    <BasicDatePicker label="Date of Birth" name="date_birth" placeholderText="Select Date of Birth" control={control}  />
                    <InputText
                        {...register('place_of_birth')}
                        label="Place of Birth"
                        placeholder="Input Place of Birth"
                        message={errors.place_of_birth?.message}
                    />
                    <TextArea
                        {...register('address')}
                        label="Address"
                        placeholder="Input address"
                        message={errors.address?.message}
                    />
                    <InputText
                        {...register('number_npwp')}
                        label="NPWP"
                        placeholder="Input NPWP"
                        message={errors.number_npwp?.message}
                    />
                    <InputText
                        {...register('bank_account_number')}
                        label="Bank Account Number"
                        placeholder="Input employee bank account number"
                        message={errors.bank_account_number?.message}
                    />
                    <InputText
                        {...register('bank_account_name')}
                        label="Bank Account Name"
                        placeholder="Input bank account name"
                        message={errors.bank_account_name?.message}
                    />
                    <InputText
                        {...register('bank_name')}
                        label="Bank Name"
                        placeholder="Input bank name"
                        message={errors.bank_name?.message}
                    />
                    <TextArea
                        {...register('bank_account_address')}
                        label="Bank Account Address"
                        placeholder="Input bank account address"
                        message={errors.bank_account_address?.message}
                    />
                </div>
                <div>
                    <InputText
                        {...register('dob')}
                        label="DOB"
                        placeholder="Input DOB"
                        message={errors.dob?.message}
                    />
                    <InputText
                        {...register('religion')}
                        label="Religion"
                        placeholder="Input Religion"
                        message={errors.religion?.message}
                    />
                    <BasicSelectInput
                        label="Highest Education"
                        message={errors.education?.message}
                        options={educationOptions}
                        {...register('education')}
                    />
                    <InputText
                        {...register('contact')}
                        label="Name of Relation"
                        placeholder="Input Name of Relation"
                        message={errors.contact?.message}
                    />
                    <InputText
                        {...register('contact_relation')}
                        label="Contact Relation"
                        placeholder="Input contact relation"
                        message={errors.contact_relation?.message}
                    />
                    <BasicSelectInput
                        label="Relationship Status"
                        message={errors.relation_status?.message}
                        options={relationOptions}
                        {...register('relation_status')}
                    />
                    <BasicSelectInput
                        label="Position"
                        message={errors.position?.message}
                        options={optionByName}
                        {...register('position')}
                        disabled={isMyProfile}
                        value={watchPositionName}
                    />
                    <SelectInput
                        options={positionOptions}
                        isMulti
                        label="Roles"
                        onChange={onRolesChange}
                        value={optionValues}
                        isDisabled={isMyProfile}
                    />
                    <BasicSelectInput
                        label="Team"
                        message={errors.team_id?.message}
                        options={teamOptions}
                        {...register('team_id')}
                        disabled={isMyProfile}
                        value={watchTeamId}
                    />
                    <InputText
                        {...register('email')}
                        label="E-mail"
                        placeholder="Input email"
                        message={errors.email?.message}
                    />
                    <BasicDatePicker label="Entry Date" name="date_entry" placeholderText="Select Date Entry" control={control}  />
                    <TextArea
                        {...register('bpjS_ketenagakerjaan')}
                        label="BPJS Ketenagakerjaan"
                        placeholder="Input BPJS Ketenagakerjaan"
                        message={errors.bpjS_ketenagakerjaan?.message}
                    />
                    <TextArea
                        {...register('bpjS_kesehatan')}
                        label="BPJS Kesehatan"
                        placeholder="Input BPJS Kesehatan"
                        message={errors.bpjS_kesehatan?.message}
                    />
                    <InputText
                        {...register('status_marital')}
                        label="Marital Status"
                        placeholder="Input marital status"
                        message={errors.status_marital?.message}
                    />
                    <InputText
                        {...register('total_children')}
                        label="Total Number of Children"
                        placeholder="Input total number of children"
                        message={errors.total_children?.message}
                    />
                </div>
            </div>

            <div className="pt-2 pb-2">
                <Button
                    color="success"
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                />
            </div>
        </form>
    );
};

export interface EmployeeFormProps {
    isLoading: boolean;
    isEdit: boolean;
    onSubmit: (formInput: InputModel) => void;
    isMyProfile?: boolean;
}

export default EmployeeForm;
