import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {
    GetStaffReq,
    StaffReportModel,
    SendStaffReq,
    GetEmployeeWorkReq,
    EmployeeWorkModel,
    GetStaffMainReq, GetStaffApprovalReq, EmployeeWorkFilterModel, GetStaffReportWithFilterReq, GetExportEmployeeWorkReq
} from "./models";
import {BillJobRequest} from "../../billJob/models";


export default class StaffReportApi extends BaseServiceClass {
    static getStaffReport(args: GetStaffReportWithFilterReq, token: string) {
        return httpClient.post<ApiResponseWrapper<StaffReportModel[]>>(this.PATH_REPORT.get_staff_report_with_filter, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
    static getStaffReportApproval(args: GetStaffApprovalReq, token: string) {
        return httpClient.post<ApiResponseWrapper<StaffReportModel[]>>(this.PATH_REPORT.get_staff_report_approval, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getStaffReportMain(args: GetStaffMainReq, token: string) {
        return httpClient.get<ApiResponseWrapper<StaffReportModel[]>>(this.PATH_REPORT.staff_report_main, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args,
        }).then((res) => res.data);
    }
    static createStaffReport(args: SendStaffReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_REPORT.staff_report, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static updateStaffReport(args: SendStaffReq, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(this.PATH_REPORT.staff_report, args,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static deleteStaffReport(arg: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(this.PATH_REPORT.staff_report, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: arg
            }
        }).then((res) => res.data);
    }
    static getEmployeeWork(args: GetEmployeeWorkReq, token: string) {
        return httpClient.get<ApiResponseWrapper<EmployeeWorkModel[]>>(this.PATH_REPORT.get_employee_work, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getEmployeeWorkWithFilter(args: GetEmployeeWorkReq, token: string) {
        return httpClient.get<ApiResponseWrapper<EmployeeWorkFilterModel[]>>(this.PATH_REPORT.get_employee_work_with_filter, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static exportEmployeeWork(args: GetExportEmployeeWorkReq, token: string) {
        const path = "/api/Job/ExportEmployeeWorkToExcel"
        return httpClient
            .get<any>(`${this.BASE_URL}${path}`,{
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args
            })
            .then((res) => res.data);
    }
    static getEmployeeWorkWithFilterNew(args: GetEmployeeWorkReq, token: string) {
        return httpClient.get<ApiResponseWrapper<EmployeeWorkFilterModel[]>>(this.PATH_REPORT.get_employee_work_with_filter_new, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static exportEmployeeWorkNew(args: GetExportEmployeeWorkReq, token: string) {
        const path = "/api/Job/ExportEmployeeWorkToExcelNew"
        return httpClient
            .get<any>(`${this.BASE_URL}${path}`,{
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: args
            })
            .then((res) => res.data);
    }
}