import {GlobalApprovalEnum} from "../../types";
import {HolidayApprovalEnum} from "../profile/models";
import {
    AttendanceApprovalWithFilterColumnSort,
    EmployeeWorkSortEnum, HolidayApprovalWithFilterColumnSort,
    OvertimeApprovalColumnSort,
    SortingEnum
} from "./models";
import {Option} from "../../components/SelectInput/BasicSelectInput";

export const approvalStatusOption = [
    {
        label: "Approved",
        text: "Approved",
        value: GlobalApprovalEnum.Approved.toString()
    },
    {
        label: "Rejected",
        text: "Rejected",
        value: GlobalApprovalEnum.Rejected.toString()
    }
]

export const employeeWorkSortOption = [
    {
        label: "requestDate",
        text: "requestDate",
        value: EmployeeWorkSortEnum.requestDate.toString()
    },
    {
        label: "typeName",
        text: "typeName",
        value: EmployeeWorkSortEnum.typeName.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: EmployeeWorkSortEnum.number_job.toString()
    },
    {
        label: "name",
        text: "name",
        value: EmployeeWorkSortEnum.name.toString()
    },
    {
        label: "nip",
        text: "nip",
        value: EmployeeWorkSortEnum.nip.toString()
    },
    {
        label: "teamName",
        text: "teamName",
        value: EmployeeWorkSortEnum.teamName.toString()
    },
    {
        label: "role_name",
        text: "role_name",
        value: EmployeeWorkSortEnum.role_name.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: EmployeeWorkSortEnum.statusName.toString()
    }
]

export const overtimeSortOption = [
    {
        label: "ts_created",
        text: "ts_created",
        value: OvertimeApprovalColumnSort.ts_created.toString()
    },
    {
        label: "nip",
        text: "nip",
        value: OvertimeApprovalColumnSort.nip.toString()
    },
    {
        label: "teamName",
        text: "teamName",
        value: OvertimeApprovalColumnSort.team.toString()
    },
    {
        label: "name",
        text: "name",
        value: OvertimeApprovalColumnSort.name.toString()
    },
    {
        label: "roleName",
        text: "roleName",
        value: OvertimeApprovalColumnSort.role.toString()
    },
    {
        label: "reqMinute",
        text: "reqMinute",
        value: OvertimeApprovalColumnSort.req_minute.toString()
    },
    {
        label: "notes",
        text: "notes",
        value: OvertimeApprovalColumnSort.notes.toString()
    },
    {
        label: "rejectionNotes",
        text: "rejectionNotes",
        value: OvertimeApprovalColumnSort.rejection_note.toString()
    },
    {
        label: "approveMinute",
        text: "approveMinute",
        value: OvertimeApprovalColumnSort.approved_time.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: OvertimeApprovalColumnSort.status.toString()
    },
    {
        label: "approveByName",
        text: "approveByName",
        value: OvertimeApprovalColumnSort.approve_by.toString()
    },
    {
        label: "managerApproveByName",
        text: "managerApproveByName",
        value: OvertimeApprovalColumnSort.manager_approval.toString()
    },
]

export const attendanceSortOption = [
    {
        label: "ts_created",
        text: "ts_created",
        value: AttendanceApprovalWithFilterColumnSort.ts_created.toString()
    },
    {
        label: "absentDate",
        text: "absentDate",
        value: AttendanceApprovalWithFilterColumnSort.absent_date.toString()
    },
    {
        label: "nip",
        text: "nip",
        value: AttendanceApprovalWithFilterColumnSort.nip.toString()
    },
    {
        label: "teamName",
        text: "teamName",
        value: AttendanceApprovalWithFilterColumnSort.team.toString()
    },
    {
        label: "name",
        text: "name",
        value: AttendanceApprovalWithFilterColumnSort.name.toString()
    },
    {
        label: "reqMinute",
        text: "reqMinute",
        value: AttendanceApprovalWithFilterColumnSort.req_minute.toString()
    },
    {
        label: "leaveDuration",
        text: "leaveDuration",
        value: AttendanceApprovalWithFilterColumnSort.leave_duration.toString()
    },
    {
        label: "notes",
        text: "notes",
        value: AttendanceApprovalWithFilterColumnSort.notes.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: AttendanceApprovalWithFilterColumnSort.status.toString()
    },
    {
        label: "approveMinute",
        text: "approveMinute",
        value: AttendanceApprovalWithFilterColumnSort.approval_minute.toString()
    },
    {
        label: "approvalDate",
        text: "approvalDate",
        value: AttendanceApprovalWithFilterColumnSort.approval_date.toString()
    },
    {
        label: "rejectionNotes",
        text: "rejectionNotes",
        value: AttendanceApprovalWithFilterColumnSort.rejection_note.toString()
    }
]

export const holidaySortOption = [
    {
        label: "ts_created",
        text: "ts_created",
        value: HolidayApprovalWithFilterColumnSort.ts_created.toString()
    },
    {
        label: "reqDate",
        text: "reqDate",
        value: HolidayApprovalWithFilterColumnSort.req_date.toString()
    },
    {
        label: "nip",
        text: "nip",
        value: HolidayApprovalWithFilterColumnSort.nip.toString()
    },
    {
        label: "teamName",
        text: "teamName",
        value: HolidayApprovalWithFilterColumnSort.team.toString()
    },
    {
        label: "name",
        text: "name",
        value: HolidayApprovalWithFilterColumnSort.name.toString()
    },
    {
        label: "notes",
        text: "notes",
        value: HolidayApprovalWithFilterColumnSort.note.toString()
    },
    {
        label: "statusName",
        text: "statusName",
        value: HolidayApprovalWithFilterColumnSort.status.toString()
    },
    {
        label: "rejectionNotes",
        text: "rejectionNotes",
        value: HolidayApprovalWithFilterColumnSort.rejection_note.toString()
    },
    {
        label: "approvalByName",
        text: "approvalByName",
        value: HolidayApprovalWithFilterColumnSort.approve_by.toString()
    },
    {
        label: "managerApprovedName",
        text: "managerApprovedName",
        value: HolidayApprovalWithFilterColumnSort.manager_approval.toString()
    }
]

export const getSortType = (sortType: string) => {
    return sortType === 'asc' ? SortingEnum.Ascending : sortType === 'desc' ? SortingEnum.Descending : undefined
}

export const getSortModel = (sortList: Array<Option>, fieldName: string) => {
    return sortList.find((item) => item.text === fieldName)?.value
}

export const approvalStatusFilterOptions = [
    {
        displayKey: 'all',
        displayName: 'All',
        predicate: 'all',
        numberOfInputs: 0,
    },
    {
        displayKey: HolidayApprovalEnum.Requested.toString(),
        displayName: 'Requested',
        predicate: HolidayApprovalEnum.Requested.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: HolidayApprovalEnum.ApprovedByHead.toString(),
        displayName: 'Approved by Head',
        predicate: HolidayApprovalEnum.ApprovedByHead.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: HolidayApprovalEnum.Rejected.toString(),
        displayName: 'Rejected',
        predicate: HolidayApprovalEnum.Rejected.toString(),
        numberOfInputs: 0,
    },
]

export const approvalAttendanceStatusFilterOptions = [
    {
        displayKey: 'all',
        displayName: 'All',
        predicate: 'all',
        numberOfInputs: 0,
    },
    {
        displayKey: GlobalApprovalEnum.Requested.toString(),
        displayName: 'Requested',
        predicate: GlobalApprovalEnum.Requested.toString(),
        numberOfInputs: 0,
    },
    {
        displayKey: GlobalApprovalEnum.Rejected.toString(),
        displayName: 'Rejected',
        predicate: GlobalApprovalEnum.Rejected.toString(),
        numberOfInputs: 0,
    },
]