import React from 'react';
import FieldingTable from "../components/FieldingTable";
// import RangeDatePicker from "components/DatePicker/RangeDatePicker";
// import {useForm} from "react-hook-form";
// import {InputFilter} from "../models";
// import {useAppDispatch} from "helpers/redux";
// import {exportToCsv, getList} from "../fielding.reducer";
// import Button from "components/Button/Button";

const FieldingPage = () => {
    // const {
    //     control,
    //     handleSubmit,
    //     watch,
    //     reset,
    // } = useForm<InputFilter>({defaultValues: {startDate: undefined, endDate: undefined}});
    // const dispatch = useAppDispatch();
    //
    // const startDate = watch('startDate');
    // const endDate = watch('endDate');
    //
    // const onSubmit = (data: InputFilter) => {
    //     let localStart = data.startDate !== undefined ? new Date(data.startDate).toISOString() : undefined;
    //     let localEnd = data.endDate !== undefined ? new Date(data.endDate).toISOString() : undefined;
    //     dispatch(getList({skip: 0, take: 10, startDate: localStart, endDate: localEnd}));
    // }
    //
    // const onExportClicked = () => {
    //     dispatch(exportToCsv({
    //         startDate: startDate !== undefined ? new Date(startDate).toISOString() : undefined,
    //         endDate: endDate !== undefined ? new Date(endDate).toISOString() : undefined
    //     }));
    // }
    //
    // const onResetClicked = () => {
    //     reset();
    //     dispatch(getList({skip: 0, take: 10}));
    // }

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Fielding</h1>
            </div>

            {/*<div className="flex justify-center">*/}
            {/*    <form onSubmit={handleSubmit(onSubmit)}>*/}
            {/*        <div className={"flex"}>*/}
            {/*            <RangeDatePicker startName='startDate' endName='endDate'*/}
            {/*                             startValue={startDate !== undefined ? new Date(startDate) : undefined}*/}
            {/*                             endValue={endDate !== undefined ? new Date(endDate) : undefined}*/}
            {/*                             control={control} centerStyle/>*/}
            {/*            <div className="flex justify-end items-center mx-1">*/}

            {/*                <div className={"mx-1"}>*/}
            {/*                    <Button*/}
            {/*                        color="success"*/}
            {/*                        type="button"*/}
            {/*                        title="Reset"*/}
            {/*                        onClick={onResetClicked}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className={"ml-1"}>*/}
            {/*                    <Button*/}
            {/*                        color="success"*/}
            {/*                        type="submit"*/}
            {/*                        title="Filter"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </form>*/}
            {/*</div>*/}

            <div className="mt-3">
                <FieldingTable/>
            </div>
        </div>
    );
};

export default FieldingPage;
