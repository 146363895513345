import {JobID} from "./models";
import {HttpClientError} from "../../../../http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../../app/store";
import JobDetailApi from "./jobDetail.api";

export interface JobDetailSlice {
    jobIdList?: JobID;
    isLoading?: boolean;
    error?: HttpClientError;
}

export const getByJobNumber = createAsyncThunk(
    'job-detail/getJobID',
    async (jobNumber: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobDetailApi.getByJobNumber(jobNumber, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const jobDetailSlice = createSlice({
    name: 'jobDetailState',
    initialState: {} as JobDetailSlice,
    reducers: {
        reset: (state) => {
            state.error = {} as HttpClientError;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getByJobNumber.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getByJobNumber.fulfilled, (state, action) => {
            state.isLoading = false;
            state.jobIdList = action.payload.data;
        });
        builder.addCase(getByJobNumber.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
    }
});

export const { reset } = jobDetailSlice.actions;
export default jobDetailSlice.reducer;