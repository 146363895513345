import React from 'react';
import TeamTable from "../components/TeamTable";
import BasicHeaderLayout from "components/Layout/BasicHeaderLayout";

const TeamPage = () => {

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <BasicHeaderLayout path={"/team/detail"} pageTitle="Team" buttonTitle="New Team" />
            <div className="mt-3">
                <TeamTable />
            </div>

        </div>
    );
};

export default TeamPage;
