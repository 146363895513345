import * as yup from "yup";
import {MainMREColumnSort, MREColumnSort, MRETypeEnum} from "./models";
export const schema = yup.object({
    range_start: yup.number().required(),
    range_end: yup.number().required(),
}).required();

export const mreTypeOptions = [
    {
        text: 'MRE-P',
        label: 'MRE-P',
        value: MRETypeEnum.MRE_P.toString(),
    },
    {
        text: 'MRE-E',
        label: 'MRE-E',
        value: MRETypeEnum.MRE_E.toString(),
    },
]

export const MRESortOption = [
    {
        label: "project_name",
        text: "project_name",
        value: MREColumnSort.project_name.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: MREColumnSort.department_name.toString()
    },
    {
        label: "kind_name",
        text: "kind_name",
        value: MREColumnSort.kind_job.toString()
    },
    {
        label: "ts_created",
        text: "ts_created",
        value: MREColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: MREColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: MREColumnSort.number_job.toString()
    },
    {
        label: "other_number",
        text: "other_number",
        value: MREColumnSort.other_number.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: MREColumnSort.status.toString()
    },
    {
        label: "date_due",
        text: "date_due",
        value: MREColumnSort.due_date.toString()
    },
    {
        label: "date_assigned_mre",
        text: "date_assigned_mre",
        value: MREColumnSort.date_assign.toString()
    },
    {
        label: "pole_total",
        text: "pole_total",
        value: MREColumnSort.req_pole.toString()
    },
    {
        label: "date_assigned_qc_mre",
        text: "date_assigned_qc_mre",
        value: MREColumnSort.date_assign_qc_mre.toString()
    },
]

export const MainMRESortOption = [
    {
        label: "project_name",
        text: "project_name",
        value: MainMREColumnSort.project_name.toString()
    },
    {
        label: "department_name",
        text: "department_name",
        value: MainMREColumnSort.department_name.toString()
    },
    {
        label: "kind_name",
        text: "kind_name",
        value: MainMREColumnSort.kind_job.toString()
    },
    {
        label: "ts_created",
        text: "ts_created",
        value: MainMREColumnSort.request_date.toString()
    },
    {
        label: "number_id",
        text: "number_id",
        value: MainMREColumnSort.number_id.toString()
    },
    {
        label: "number_job",
        text: "number_job",
        value: MainMREColumnSort.number_job.toString()
    },
    {
        label: "status_name",
        text: "status_name",
        value: MainMREColumnSort.status.toString()
    },
    {
        label: "date_due",
        text: "date_due",
        value: MainMREColumnSort.due_date.toString()
    },
    {
        label: "date_assigned_mre",
        text: "date_assigned_mre",
        value: MainMREColumnSort.date_assign.toString()
    },
    {
        label: "pole_total",
        text: "pole_total",
        value: MainMREColumnSort.req_pole.toString()
    },
    {
        label: "date_assigned_qc_mre",
        text: "date_assigned_qc_mre",
        value: MainMREColumnSort.date_assign_qc_mre.toString()
    },
]