import {BaseServiceClass} from "../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../http-client";
import {GetHistoryWithFilterRequest, HistoryModel} from "./models";


export default class HistoryApi extends BaseServiceClass {
    static getHistory(args: GetHistoryWithFilterRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<HistoryModel[]>>(this.PATH_REPORT.history, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }
}