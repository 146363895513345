import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../helpers/redux";
import {setDynamicNavId} from "../../sidebarPanel/sidebar.reducer";
import DynamicNavLink from "../../../components/Tabs/DynamicNavLink";
import {useParams} from "react-router-dom";
import {RootState} from "../../../app/store";
import {Job} from "../process/job/models";
import {JobID} from "./jobDetail/models";
import {getByJobNumber} from "./jobDetail/jobDetail.reducer";

const ProcessDetailDynamicNav = () => {
    const dispatch = useAppDispatch();

    let { id, koj } = useParams();

    console.log(koj);

    const links = [
        { id: 'job-detail', label: "Job Details", url: `/worksheet/ocalc/job-detail/${id}`, isRestricted: koj !== "ocalc" },
        { id: 'job-prep', label: "Job Prep", url: `/worksheet/ocalc/job-prep/${id}`, isRestricted: koj !== "ocalc"},
        { id: 'job-ocalc', label: "OCALC", url: `/worksheet/ocalc/job-ocalc/${id}`, isRestricted: koj !== "ocalc"},
        { id: 'request-document', label: "Request Document", url: "#", isRestricted: koj !== "ocalc"},

        { id: 'mre-detail', label: "Job Details", url: `/worksheet/mre/mre-detail/${id}`, isRestricted: koj !== "mre"},
        { id: 'mre-poles', label: "MRE", url: `/worksheet/mre/mre-poles/${id}`, isRestricted: koj !== "mre" },

        { id: 'katapult-detail', label: "Job Details", url: `/worksheet/katapult/katapult-detail/${id}`, isRestricted: koj !== "katapult"},
        { id: 'katapult-poles', label: "Katapult", url: `/worksheet/katapult/katapult-poles/${id}`, isRestricted: koj !== "katapult"},
    ];

    useEffect(() => {
        dispatch(setDynamicNavId('job-input'));
    }, []);

    // useEffect(() => {
    //     if (id) {
    //         dispatch(getByJobNumber(id));
    //     }
    // }, [id]);

    return (
        <DynamicNavLink links={links}/>
    );
};

export default ProcessDetailDynamicNav;
