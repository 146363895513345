import React from 'react';
import SeparateTabs from "../../../components/Tabs/SeparateTabs";
import {useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";
import OvertimeReportPage from "./overtime/containers/OvertimeReportPage";
import HolidayReportPage from "./holidayReport/containers/HolidayReportPage";
import AbsentLogPage from "./absentLogs/containers/AbsentLogPage";

const AttendanceReportMainPage = () => {
    //this id must match with job report dynamic nav id
    const tabs = [
        { id: 'overtime-report', name: "Overtime", link: `#`, content: <OvertimeReportPage /> },
        { id: 'holiday-report', name: "Holiday Report", link: `#`, content: <HolidayReportPage /> },
        { id: 'absent-log', name: "Absent Log", link: '#', content: <AbsentLogPage /> }
    ];

   const dynamicNavId = useAppSelector((state: RootState) => state.sidebar.dynamicNavId ?? "");

    return (
        <div>
            <SeparateTabs tabs={tabs} openTab={dynamicNavId} />
        </div>
    );
};

export default AttendanceReportMainPage;
