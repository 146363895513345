import {CreateJobFile, Job, JobFileNew} from 'features/worksheet/process/job/models';
import { HttpClientError, RequestStatus } from 'http-client';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import JobCalcSpecApi from './jobCalcSpec.api';
import {GetCSFileRequest, JobFileCS, JobFileCSList, ReadyToQC, UpdateCSNote} from './jobCalcSpec.models';
import {UploadProgress, UploadStatus} from "../../../basic/fileAttachment/models";
import FileAttachmentApi from "../../../basic/fileAttachment/fileAttachment.api";

export interface JobCalcSpecSlice {
    singleCalcSpec: Job;
    jobCSFile?: Array<JobFileCSList>;
    uploadProgress?: Array<UploadProgress>;
    uploadStatus?: Array<UploadStatus>;
    deleteFileStatus?: RequestStatus;
    isLoading?: boolean;
    rowsAffected?: number;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const uploadJobCSFile = createAsyncThunk(
    'processDetail/calcSpec/uploadJobCSFile',
    async (args: CreateJobFile, {getState, rejectWithValue, dispatch}) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((file, index) => {
                        return {
                            index: index,
                            filename: file.name,
                            status: RequestStatus.pending,
                            createStatus: RequestStatus.pending,
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (file, index) => {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('job_id', args.job_id);
                const config = {
                    headers: {
                        Authorization: `Bearer ${userAuth?.id as string}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        location: 'job-cs',
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            })
                        );
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.success,
                        })
                    );
                    return {
                        name: file.name,
                        path: data.result,
                        job_id: args.job_id
                    }
                }).catch(() => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: RequestStatus.failed,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                        job_id: args.job_id
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                dispatch(createJobCSFile(attachment as Array<JobFileNew>));
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createJobCSFile = createAsyncThunk(
    'processDetail/calcSpec/createJobCSFile',
    async (
        args: Array<JobFileCS>,
        { getState, rejectWithValue, dispatch }
    ) => {
        try {
            const {userAuth} = (getState() as RootState).user;

            const createResponse = args?.map(async (arg, index) => {
                if (arg.path !== "") {
                    return await JobCalcSpecApi.createJobCSFile({
                        ...arg, employee_id: userAuth?.user_id as string
                    }, userAuth?.id as string).then((data) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.success,
                            })
                        );
                        return data;
                    }).catch((e) => {
                        dispatch(
                            updateCreateStatus({
                                index: index,
                                createStatus: RequestStatus.failed,
                            })
                        );
                        return e;
                    });
                } else {
                    return {};
                }
            });

            Promise.all(createResponse).then(async (attachment) => {
                console.log('saved successfully', attachment);
            });
        } catch (e) {
            console.log('caught error', e);
            return rejectWithValue(e as HttpClientError);
        }
    }
);

// export const createJobCSFile = createAsyncThunk(
//     'processDetail/calcSpec/createJobCSFile',
//     async (args: CreateJobCSFile, { getState, rejectWithValue, dispatch }) => {
//         try {
//             const { userAuth } = (getState() as RootState).user;
//
//             dispatch(
//                 setUploadProgress(
//                     args.local_files.map((file, index) => {
//                         return { index: index, progress: 0 };
//                     })
//                 )
//             );
//             dispatch(
//                 setUploadStatus(
//                     args.local_files.map((file, index) => {
//                         return {
//                             index: index,
//                             filename: file.name,
//                             status: RequestStatus.pending,
//                         };
//                     })
//                 )
//             );
//
//             args.local_files.map(async (file, index) => {
//                 let formData = new FormData();
//                 formData.append('file', file);
//                 formData.append('job_id', args.job_id);
//                 formData.append('employee_id', args.employee_id);
//                 const config = {
//                     headers: {
//                         Authorization: `Bearer ${userAuth?.id as string}`,
//                         'Content-Type': 'multipart/form-data',
//                     },
//                     onUploadProgress: (progressEvent: ProgressEvent) => {
//                         const percentCompleted = Math.round(
//                             (progressEvent.loaded * 100) / progressEvent.total
//                         );
//                         dispatch(
//                             updateUploadProgress({
//                                 index: index,
//                                 progress: percentCompleted,
//                             })
//                         );
//                     },
//                 };
//                 let response = await JobCalcSpecApi.createJobCSFile(
//                     formData,
//                     config
//                 );
//                 if (response.status) {
//                     dispatch(
//                         updateUploadStatus({
//                             index: index,
//                             status: RequestStatus.success,
//                         })
//                     );
//                     return response;
//                 } else {
//                     dispatch(
//                         updateUploadStatus({
//                             index: index,
//                             status: RequestStatus.failed,
//                         })
//                     );
//                     return response;
//                 }
//             });
//         } catch (e) {
//             console.log('caught error', e);
//             return rejectWithValue(e as HttpClientError);
//         }
//     }
// );

export const getJobCSFile = createAsyncThunk(
    'processDetail/calcSpec/getJobCSFile',
    async (args: GetCSFileRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobCalcSpecApi.getJobCSFile(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteJobCSFile = createAsyncThunk(
    'processDetail/calcSpec/deleteJobCSFile',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobCalcSpecApi.deleteJobCSFile(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateCSNote = createAsyncThunk(
    'processDetail/calcSpec/updateCSNote',
    async (args: UpdateCSNote, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobCalcSpecApi.updateCSNote(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const readyToQC = createAsyncThunk(
    'processDetail/calcSpec/readyToQC',
    async (args: ReadyToQC, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await JobCalcSpecApi.readyToQC(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const jobCalcSpecSlice = createSlice({
    name: 'jobCalcSpecState',
    initialState: {} as JobCalcSpecSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.singleCalcSpec = {} as Job;
            state.rowsAffected = 0;
            state.uploadStatus = [];
            state.uploadProgress = [];
            state.deleteFileStatus = RequestStatus.pending;
            state.uploadStatus = [];
            state.uploadProgress = [];
        },
        setUploadProgress: (state, payload: PayloadAction<UploadProgress[]>) => {
            state.uploadProgress = payload.payload;
        },
        setUploadStatus: (state, payload: PayloadAction<UploadStatus[]>) => {
            state.uploadStatus = payload.payload;
        },
        updateUploadProgress: (state, payload: PayloadAction<UploadProgress>) => {
            let progress = state.uploadProgress ?? [];
            let objIndex = state.uploadProgress?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            progress[objIndex as number].progress = payload.payload.progress;
            state.uploadProgress = progress;
        },
        updateUploadStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].status = payload.payload.status;
            state.uploadStatus = status;
        },
        updateCreateStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].createStatus = payload.payload.createStatus;
            state.uploadStatus = status;
        },
    },
    extraReducers: (builder) => {
        // create job cs file
        builder.addCase(createJobCSFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createJobCSFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createJobCSFile.rejected, (state) => {
            state.isLoading = false;
        });
        // get job cs file
        builder.addCase(getJobCSFile.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getJobCSFile.fulfilled, (state, { payload }) => {
            state.jobCSFile = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getJobCSFile.rejected, (state) => {
            state.isLoading = false;
        });
        // delete job cs file
        builder.addCase(deleteJobCSFile.pending, (state) => {
            state.deleteFileStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteJobCSFile.fulfilled, (state) => {
            state.deleteFileStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteJobCSFile.rejected, (state, { payload }) => {
            state.deleteFileStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        builder.addCase(updateCSNote.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateCSNote.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateCSNote.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        builder.addCase(readyToQC.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(readyToQC.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(readyToQC.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const {
    reset,
    setUploadProgress,
    setUploadStatus,
    updateUploadProgress,
    updateUploadStatus,
    updateCreateStatus
} = jobCalcSpecSlice.actions;
export default jobCalcSpecSlice.reducer;