import React, {useMemo, useEffect} from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import '@reach/combobox/styles.css';
import '../styles.css';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import {isArrayEmpty} from "helpers/checkEmptiness";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import {getMapPoleByJob} from "../../../../basic/pole/pole.reducer";
import {Pole} from "../../../../basic/pole/models";
import {useParams} from "react-router-dom";

export default function ViewMaps() {
    const libraries: Libraries = ['places'];
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const poleMapByJob = useAppSelector(
        (state: RootState) => state.pole.poleMapByJob ?? []
    );

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs',
        libraries: libraries,
    });

    useEffect(() => {
        if (id && id !== "") {
            dispatch(getMapPoleByJob({
                job_id: id,
                skip: 0,
                take: 500,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    if (!isLoaded) return <div>Loading...</div>;
    return <Map coordinatList={poleMapByJob} />;
}

function Map({coordinatList = []}: MapModel) {
    const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);

    return (
        <>
            <GoogleMap
                zoom={10}
                center={!isArrayEmpty(coordinatList) ? { lat: coordinatList[0].latitude, lng: coordinatList[0].longitude } : center}
                mapContainerClassName="map-container"
            >
                {coordinatList.map((item) => (
                    <Marker
                        key={item.id}
                        position={{ lat: item.latitude, lng: item.longitude }}
                        label={{
                            text: `Pole - ${item.no}`,
                            className: 'marker-label',
                        }}

                    />
                ))}
            </GoogleMap>
        </>
    );
}

interface MapModel {
    coordinatList: Array<Pole>;
}


