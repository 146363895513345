import React from 'react';
import {AiOutlineBarChart} from "react-icons/ai";

const SidebarBrand = ({open = false}: SidebarBrandProps) => {
    return (
        <>
            <div className={`fixed ${
                open ? 'w-[245px]' : 'w-18'
            } bg-primary-color h-[60px] p-2 border border-green-600`}>
                <AiOutlineBarChart
                    className={`text-white text-[46px] text-center rounded cursor-pointer block float-left duration-500 ${open && "rotate-[360deg] mr-2"}`}/>
                {open && (
                    <h1 className={` text-white origin-left leading-5 font-medium ${!open && "scale-0"} duration-300`}>Quality <br />
                        Management <small className="text-xs">v2</small></h1>
                )}
            </div>
        </>
    );
};

export interface SidebarBrandProps {
    open: boolean;
    toggleOpen: () => void;
}

export default SidebarBrand;