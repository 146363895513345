import {EmployeePrep} from "../jobPrep/models";

export type CalcSpecType = {
    id: string;
    number_id: string;
    number_job: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string;
    category_name: string;
    type_id: string;
    type_name: string;
    district_id: string;
    district_name: string;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    kind_name: string;
    department_id: string;
    department_name: string;
    source_id: string;
    source_name: string;
    status_id: string;
    status_name: string;
    date_due_fielding: string;
    project_id: string;
    project_name: string;
    waiting_subject_id: string | null;
    waiting_notes: string | null;
    waiting_ts: string;
    ts_created: string;
    cancel_notes: string | null;
    prep_notes: string | null;
    prep_reference_job_id: string;
    prep_reference_job_number_job: string;
    employee_prep_list: Array<CSEmployee>;
    percent_prep: number;
    qc_notes: string | null;
    calcspec_notes: string | null;
    pole_total: number;
    percent_cs: number;
    department_owner_name: string;
    date_assigned_cs: string;
};

export type MainCalcSpecType = {
    id: string;
    number_id: string;
    number_job: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string;
    category_name: string;
    type_id: string;
    type_name: string;
    district_id: string;
    district_name: string;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    kind_name: string;
    department_id: string;
    department_name: string;
    source_id: string;
    source_name: string;
    status_id: string;
    status_name: string;
    date_due_fielding: string;
    project_id: string;
    project_name: string;
    waiting_subject_id: string | null;
    waiting_notes: string | null;
    waiting_ts: string;
    ts_created: string;
    cancel_notes: string | null;
    prep_notes: string | null;
    prep_reference_job_id: string;
    prep_reference_job_number_job: string;
    employee_prep_list: Array<CSEmployee>;
    percent_prep: number;
    qc_notes: string | null;
    calcspec_notes: string | null;
    pole_total: number;
    department_owner_name: string;
};

export type CSEmployeeGet = {
    employee_id: string;
    employee_name: string;
    pole_sequences: string;

    pole_id_list: Array<string>;
    pole_ids?: string;
    isStatic: boolean;
}

export type CSEmployee = {
    employee_id: string | undefined;
    employee_name: string | undefined;
    pole_ids?: string;
    pole_sequences: string | undefined;

    pole_id_list?: Array<string>;
    isStatic: boolean;
}

export type GetCalcSpecRequest = {
    skip?: string | number;
    take?: string | number;
    employee_id?: string;
    id?: string;
};

export type InputAssignCS = {
    range_start: number;
    range_end: number;
    employee_id: string;
    employee_name: string;
}

export type AssignUserCS = {
    employee_id: string;
    pole_id_list: Array<string>;
}

export type AssignCalcSpecRequest = {
    id: string;
    date_due: string;
    data: AssignUserCS[];
}

export type UserAssignCalcSpec = {
    employee_id: string;
    employee_name: string;
    pole_id_list: Array<string>;
}

export type CalcSpecSummary = {
    employee_id: string;
    employee_name: string;
    total: number;
    date_due: string;
}

export enum SortingEnum {
    Ascending,
    Descending
}

export enum JobCSColumnSort
{
    project_name,
    department_name,
    kind_job,
    request_date,
    number_id,
    number_job,
    other_number,
    status,
    due_date,
    date_assign,
    req_pole,
}

export enum JobCSMainColumnSort
{
    project_name,
    department_name,
    kind_job,
    request_date,
    number_id,
    number_job,
    status,
    due_date,
    date_assign,
    req_pole,
}

export type GetCalcSpecFilterRequest = {
    project_name?: string;
    department_name?: string;
    kind_job?: string;
    startRequestDate?: string;
    endRequestDate?: string;
    number_id?: string;
    number_job?: string;
    other_number?: string;
    po_number?: string;
    status?: string;
    startDueDate?: string;
    endDueDate?: string;
    startDateAssign?: string;
    endDateAssign?: string;
    req_pole?: number;
    column?: JobCSColumnSort;
    sort?: SortingEnum;
    skip: number;
    take: number;
};

export type GetMainCalcSpecFilterRequest = {
    project_name?: string;
    department_name?: string;
    kind_job?: string;
    startRequestDate?: string;
    endRequestDate?: string;
    number_id?: string;
    number_job?: string;
    status?: string;
    startDueDate?: string;
    endDueDate?: string;
    startDateAssign?: string;
    endDateAssign?: string;
    req_pole?: number;
    column?: JobCSMainColumnSort;
    sort?: SortingEnum;
    employee_id: string;
    skip: number;
    take: number;
}

