import {HttpClientError, RequestStatus} from "../../../../http-client";
import {
    AssignKatapultRequest,
    KatapultModel,
    GetKatapultListRequest,
    KatapultMainModel,
    KatapultWaitingReq,
    KatapultCancelReq,
    KatapultSummary,
    GetKatapultWithFilterRequest,
    GetKatapultMainWithFilterRequest
} from "./models";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../../app/store";
import ProcessKatapultApi from "./processKatapult.api";

export interface ProcessKatapultSlice {
    katapultList: Array<KatapultModel>;
    singleKatapult: KatapultModel;
    rows?: number;
    katapultSummaryList: Array<KatapultSummary>;
    katapultMainList: Array<KatapultMainModel>;
    mainRows?: number;
    isLoading?: boolean;
    isAssignLoading?: boolean;
    isSingleLoading?: boolean;
    status?: RequestStatus;
    actionStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getKatapult = createAsyncThunk(
    'katapult/getKatapult',
    async (args: GetKatapultListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.getKatapult(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getKatapultWithFilter = createAsyncThunk(
    'katapult/getKatapultWithFilter',
    async (args: GetKatapultWithFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.getKatapultWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingleKatapult = createAsyncThunk(
    'katapult/getSingleKatapult',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.getSingleKatapult(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getKatapultSummary = createAsyncThunk(
    'katapult/getKatapultSummary',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.getKatapultSummary(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainKatapult = createAsyncThunk(
    'katapult/getMainKatapult',
    async (args: GetKatapultListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.getMainKatapult(args, userAuth?.id as string, userAuth?.user_id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainKatapultWithFilter = createAsyncThunk(
    'katapult/getMainKatapultWithFilter',
    async (args: GetKatapultMainWithFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.getMainKatapultWithFilter(args, userAuth?.id as string, userAuth?.user_id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const assignKatapult = createAsyncThunk(
    'katapult/assignKatapult',
    async (args: AssignKatapultRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.assignKatapult(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const waitingKatapult = createAsyncThunk(
    'katapult/waitingKatapult',
    async (args: KatapultWaitingReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.waitingKatapult(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const cancelKatapult = createAsyncThunk(
    'katapult/cancelKatapult',
    async (args: KatapultCancelReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProcessKatapultApi.cancelKatapult(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);



const processKatapultSlice = createSlice({
    name: 'processKatapultState',
    initialState: {} as ProcessKatapultSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.actionStatus = RequestStatus.pending;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getKatapult.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getKatapult.fulfilled, (state, action) => {
            state.isLoading = false;
            state.katapultList = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getKatapult.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getKatapultWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getKatapultWithFilter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.katapultList = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getKatapultWithFilter.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getSingleKatapult.pending, (state) => {
            state.isSingleLoading = true;
        });
        builder.addCase(getSingleKatapult.fulfilled, (state, action) => {
            state.isSingleLoading = false;
            state.singleKatapult = action.payload.data;
        });
        builder.addCase(getSingleKatapult.rejected, (state, action) => {
            state.isSingleLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getKatapultSummary.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getKatapultSummary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.katapultSummaryList = action.payload.data;
        });
        builder.addCase(getKatapultSummary.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getMainKatapult.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainKatapult.fulfilled, (state, action) => {
            state.isLoading = false;
            state.katapultMainList = action.payload.data?.map((item: KatapultMainModel, index) => {
                return {...item, table_id: `${item.id}-${index}`};
            });
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainKatapult.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getMainKatapultWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainKatapultWithFilter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.katapultMainList = action.payload.data?.map((item: KatapultMainModel, index) => {
                return {...item, table_id: `${item.id}-${index}`};
            });
            state.mainRows = action.payload.rows;
        });
        builder.addCase(getMainKatapultWithFilter.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // assign katapult reducer
        builder.addCase(assignKatapult.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isAssignLoading = true;
        });
        builder.addCase(assignKatapult.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isAssignLoading = false;
        });
        builder.addCase(assignKatapult.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isAssignLoading = false;
        });
        // waiting katapult reducer
        builder.addCase(waitingKatapult.pending, (state) => {
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(waitingKatapult.fulfilled, (state) => {
            state.actionStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(waitingKatapult.rejected, (state, { payload }) => {
            state.actionStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // cancel katapult reducer
        builder.addCase(cancelKatapult.pending, (state) => {
            state.actionStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(cancelKatapult.fulfilled, (state) => {
            state.actionStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(cancelKatapult.rejected, (state, { payload }) => {
            state.actionStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});

export const { reset } = processKatapultSlice.actions;
export default processKatapultSlice.reducer;