import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../../components/RadioButton/RadioButton';
import { SupportingDocumentEnum } from '../../../process/job/models';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/redux';
import { RootState } from '../../../../../app/store';
import {
    createAttachment,getAttachmentSingleList,
    reset as resetAttachment,
    updateAttachment,
} from '../../../../basic/attachment/attachment.reducer';
import { JobAttachmentRequest } from '../../../../basic/attachment/models';
import DefaultModal from '../../../../../components/Modal/DefaultModal';
import { toast, ToastContainer } from 'react-toastify';
import { IoMdClose } from 'react-icons/io';
import { RequestStatus } from '../../../../../http-client';
import SupportingDocumentType from './SupportingDocumentType';
import {UserCredential} from "../../../../login/models";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";

const JobPrepAttachment = ({ jobId = '' }: JobPrepAttachmentProps) => {
    const dispatch = useAppDispatch();

    const [selectedAttachment, setSelectedAttachment] =
        useState<JobAttachmentRequest>({} as JobAttachmentRequest);
    const [showModal, setShowModal] = useState<boolean>(false);

    const userState = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const supportingDocumentState = useAppSelector(
        (state: RootState) => state.attachment.typeList ?? []
    );
    const attachment = useAppSelector(
        (state: RootState) => state.attachment.singleList ?? []
    );
    const statusAttachment = useAppSelector(
        (state: RootState) => state.attachment.status
    );

    useEffect(() => {
        if (statusAttachment === RequestStatus.success) {
            dispatch(getAttachmentSingleList(jobId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusAttachment, jobId]);

    const checkAttachment = (typeId: string) => {
        const currentAttachment = attachment?.find(
            (item) => item.type_id === typeId
        );
        return {
            ...currentAttachment,
            isAvailable: currentAttachment !== undefined,
        };
    };

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };
    const onModalClose = () => {
        displayToast();
        setShowModal(false);
    };

    const displayToast = () => {
        toast.info(<ShowAttachmentToast />);
    };

    const CloseButton = ({ closeToast }: any) => (
        <IoMdClose
            size={20}
            onClick={() => {
                dispatch(resetAttachment());
                closeToast();
            }}
        />
    );

    const changeAttachment = ({
        typeId,
        value,
    }: {
        typeId: string;
        value: SupportingDocumentEnum;
    }) => {
        if (checkAttachment(typeId).isAvailable) {
            displayToast();
            dispatch(
                updateAttachment({
                    id: checkAttachment(typeId).id,
                    job_id: jobId,
                    type_id: typeId,
                    value: value,
                    option: null,
                    date_request: new Date().toISOString(),
                    link: "",
                    note: "",
                    employee_id: userState.user_id,
                })
            );
        } else {
            displayToast();
            dispatch(
                createAttachment({
                    job_id: jobId,
                    type_id: typeId,
                    value: value,
                    option: null,
                    date_request: new Date().toISOString(),
                    link: "",
                    note: "",
                    employee_id: userState.user_id,
                })
            );
        }
    };

    const onSupportingDocumentChange = (
        typeId: string,
        value: SupportingDocumentEnum,
        options: string | null
    ) => {
        if (!checkRoleActionAccess(userState?.roleName, MenuEnum.WorksheetJobPrep, ActionEnum.SupportingDocument)) {
            return null;
        }

        if (options === null || options ===  "") {
            changeAttachment({ typeId, value });
        } else {
            if (value === SupportingDocumentEnum.NOT_AVAILABLE) {
                setSelectedAttachment({
                    id: checkAttachment(typeId).id,
                    job_id: jobId,
                    type_id: typeId,
                    value,
                    option: options,
                    date_request: new Date().toISOString(),
                    link: "",
                    note: "",
                    employee_id: userState.user_id,
                });
                toggleModal();
            } else {
                changeAttachment({ typeId, value });
            }
        }
    };

    const ShowAttachmentToast = () => {
        const [localAttachmentStatus, setLocalAttachmentStatus] = useState<RequestStatus>(RequestStatus.pending);

        const isAttachmentLoading = useAppSelector(
            (state: RootState) => state.attachment.isLoading
        );
        const statusAttachment = useAppSelector(
            (state: RootState) => state.attachment.status
        );
        // const errorAttachment = useAppSelector(
        //     (state: RootState) =>
        //         state.attachment.error ?? ({} as HttpClientError)
        // );

        useEffect(() => {

            if (statusAttachment !== RequestStatus.pending) {
                setLocalAttachmentStatus(statusAttachment ?? RequestStatus.pending);
            }

            return () => {
                dispatch(resetAttachment());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [statusAttachment, jobId]);

        return (
            <div>
                {isAttachmentLoading &&
                    localAttachmentStatus === RequestStatus.pending && (
                        <small>Saving Supporting Document...</small>
                    )}
                {!isAttachmentLoading &&
                    localAttachmentStatus === RequestStatus.success && (
                        <small>Supporting Document Saved Successfully...</small>
                    )}
                {!isAttachmentLoading &&
                    localAttachmentStatus === RequestStatus.failed && (
                        <small>
                            Failed when saving supporting document
                            {/*{localErrorAttachment.message}*/}
                        </small>
                    )}
            </div>
        );
    };

    return (
        <div>
            <div className="my-3">
                <label className="text-sm font-medium text-gray-900 dark:text-gray-300">
                    Data:
                </label>

                {supportingDocumentState.map((item) => (
                    <div className="my-3" key={item.id}>
                        <ul>
                            <li>
                                <label className="text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {item.name}
                                </label>
                                <div>
                                    <fieldset id="aramis-req">
                                        <div className="mx-3 grid grid-cols-3 gap-4">
                                            <div>
                                                <RadioButton
                                                    name={`${item.id}`}
                                                    label="AVAILABLE"
                                                    id={`${item.id}-available`}
                                                    checked={
                                                        checkAttachment(item.id)
                                                            .isAvailable &&
                                                        checkAttachment(item.id)
                                                            .value ===
                                                            SupportingDocumentEnum.AVAILABLE
                                                    }
                                                    onChange={() =>
                                                        onSupportingDocumentChange(
                                                            item.id,
                                                            SupportingDocumentEnum.AVAILABLE,
                                                            item.options
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <RadioButton
                                                    name={`${item.id}`}
                                                    label="N/A"
                                                    checked={
                                                        checkAttachment(item.id)
                                                            .isAvailable &&
                                                        checkAttachment(item.id)
                                                            .value ===
                                                            SupportingDocumentEnum.NOT_AVAILABLE
                                                    }
                                                    id={`${item.id}-not-available`}
                                                    onChange={() =>
                                                        onSupportingDocumentChange(
                                                            item.id,
                                                            SupportingDocumentEnum.NOT_AVAILABLE,
                                                            item.options
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <RadioButton
                                                    name={`${item.id}`}
                                                    label="NOT REQUIRED"
                                                    id={`${item.id}-not-required`}
                                                    checked={
                                                        checkAttachment(item.id)
                                                            .isAvailable &&
                                                        checkAttachment(item.id)
                                                            .value ===
                                                            SupportingDocumentEnum.NOT_REQUIRED
                                                    }
                                                    onChange={() =>
                                                        onSupportingDocumentChange(
                                                            item.id,
                                                            SupportingDocumentEnum.NOT_REQUIRED,
                                                            item.options
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </li>
                        </ul>
                    </div>
                ))}
            </div>
            <ToastContainer
                autoClose={5000}
                draggable={false}
                closeButton={CloseButton}
            />
            <DefaultModal
                title="Required - N/A"
                show={showModal}
                onClose={onModalClose}
                body={
                    <SupportingDocumentType
                        selectedAttachment={selectedAttachment}
                        toggleModal={toggleModal}
                        onModalClose={onModalClose}
                    />
                }
            />
        </div>
    );
};

export interface JobPrepAttachmentProps {
    jobId: string;
}

export default JobPrepAttachment;
