import React, { useState } from 'react';
import DepartmentOwnerTable from '../components/DepartmentOwnerTable';
import DepartmentOwnerDetailPage from './DepartmentOwnerDetailPage';
import DefaultModal from 'components/Modal/DefaultModal';
import Button from 'components/Button/Button';

const DepartmentOwnerPage = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const openModal = (isEdit: boolean) => {
        setIsEdit(isEdit);
        setShowModal(true);
    };

    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                    Department Owner
                </h1>
                <div className="">
                    <Button
                        title="New Department Owner"
                        onClick={() => openModal(false)}
                    />
                </div>
            </div>
            <div className="mt-3">
                <DepartmentOwnerTable openModal={openModal} />

                <DefaultModal
                    title="Department Owner"
                    show={showModal}
                    onClose={toggleModal}
                    body={
                        <DepartmentOwnerDetailPage
                            isEdit={isEdit}
                            toggleModal={toggleModal}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default DepartmentOwnerPage;
