import React, {Fragment} from 'react';
import {useAppDispatch, useAppSelector} from "../../helpers/redux";
import {RootState} from "../../app/store";
import {setDynamicNavId} from "../../features/sidebarPanel/sidebar.reducer";
import {CustomFlowbiteTheme, Navbar} from "flowbite-react";
import {DynamicNavLinkModel} from "../../types";
import {useLinkClickHandler, useLocation, useNavigate} from "react-router-dom";
import * as url from "url";

const DynamicNavLink = ({links = []}: DynamicNavLinkProps) => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();

    const dynamicNavId = useAppSelector((state: RootState) => state.sidebar.dynamicNavId ?? "");

    const onNavClicked = (navId: string, url: string | undefined) => {
        dispatch(setDynamicNavId(navId));
        if (url !== undefined && url !== "#") {
            return navigate(url, {replace: true});
        }
    }

    return (
        <Navbar.Collapse>
            {
                links?.map((item) => (
                    <Fragment key={item.id}>
                        {!item.isRestricted && (
                            <Navbar.Link
                                active={dynamicNavId === item.id}
                                href={"#"}
                                onClick={() => onNavClicked(item.id, item.url)}
                            >
                                <p>
                                    {item.label}
                                </p>
                            </Navbar.Link>
                        )}
                    </Fragment>
                ))
            }
        </Navbar.Collapse>
    );
};

export interface DynamicNavLinkProps {
    links: DynamicNavLinkModel[];
}

export default DynamicNavLink;