import {bool} from "yup";

export enum BooleanNumber {
    False = 0,
    True = 1,
}

export enum SupportingDocumentEnum {
    NOT_REQUIRED, NOT_AVAILABLE, AVAILABLE
}

export type UploadProgress = {
    index: number;
    progress: number;
}

export type FileAttachment = {
    id: string;
    name: string;
    path?: string;
}

export type DynamicNavLinkModel = {
    id: string;
    label: string;
    url?: string;
    isRestricted?: boolean;
}

export enum GlobalApprovalEnum
{
    Requested, Approved, Rejected
}