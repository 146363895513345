import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpClientError, RequestStatus } from 'http-client';
import { RootState } from 'app/store';
import DistrictApi from './district.api';
import {Option} from "components/SelectInput/BasicSelectInput";
import {CreateDistrict, GetListRequest, District, UpdateDistrict} from "./models";

export interface DistrictSlice {
    list?: Array<District>;
    options?: Array<Option>;
    single?: District;
    rows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getList = createAsyncThunk(
    'district/getList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DistrictApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingle = createAsyncThunk(
    'district/getSingle',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DistrictApi.getSingle(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createDistrict = createAsyncThunk(
    'district/create',
    async (args: CreateDistrict, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DistrictApi.createDistrict(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateDistrict = createAsyncThunk(
    'district/update',
    async (args: UpdateDistrict, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DistrictApi.updateDistrict(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteDistrict = createAsyncThunk(
    'district/delete',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DistrictApi.deleteDistrict(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const districtSlice = createSlice({
    name: 'districtState',
    initialState: {} as DistrictSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.single = {} as District;
        },
        setSingle: (state, action) => {
            state.single = action.payload;
        }
    },
    extraReducers: (builder) => {
        // get list data reducer
        builder.addCase(getList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, { payload }) => {
            state.rows = payload.rows;
            state.list = payload.data;
            state.options = payload.data.map((district: District) => {
                return {
                    text: district.name,
                    label: district.name,
                    value: district.id
                }
            })
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state) => {
            state.isLoading = false;
        });

        // get single data reducer
        builder.addCase(getSingle.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingle.fulfilled, (state, { payload }) => {
            state.single = payload?.data;
            state.isLoading = false;
        });
        builder.addCase(getSingle.rejected, (state) => {
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createDistrict.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createDistrict.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createDistrict.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updateDistrict.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateDistrict.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateDistrict.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deleteDistrict.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteDistrict.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteDistrict.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const { reset, setSingle } = districtSlice.actions;
export default districtSlice.reducer;
