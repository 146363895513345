import {GetSupportingDocumentReportReq, SupportingDocumentReportModel} from "./models";
import {HttpClientError, RequestStatus} from "../../../http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import SupportingDocumentReportApi from "./supportingDocumentReport.api";

export interface SupportingDocumentReportSlice {
    supportingDocumentReportList?: Array<SupportingDocumentReportModel>;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getSupportingDocumentReport = createAsyncThunk(
    'supportingDocumentReport/getSupportingDocumentReport',
    async (args: GetSupportingDocumentReportReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await SupportingDocumentReportApi.getSupportingDocumentReport(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

const supportingDocumentReportSlice = createSlice({
    name: 'supportingDocumentReportState',
    initialState: {} as SupportingDocumentReportSlice,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSupportingDocumentReport.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSupportingDocumentReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.supportingDocumentReportList = action.payload?.data;
        });
        builder.addCase(getSupportingDocumentReport.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
    }});

export default supportingDocumentReportSlice.reducer;