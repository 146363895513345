import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {CreateDistrict, District, GetListRequest, UpdateDistrict} from "./models";

export default class DistrictApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<District[]>>(`${this.BASE_URL}${this.PATH_MASTER.district}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<District>>(`${this.BASE_URL}${this.PATH_MASTER.district}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
            }
        }).then((res) => res.data);
    }

    static createDistrict(args: CreateDistrict, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.district}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateDistrict(args: UpdateDistrict, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.district}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deleteDistrict(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.district}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
}