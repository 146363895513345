import React from 'react';
import PositionTable from '../components/PositionTable';
import BasicHeaderLayout from 'components/Layout/BasicHeaderLayout';

const PositionPage = () => {
    return (
        <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <BasicHeaderLayout
                path={'/position/detail'}
                pageTitle="Position"
                buttonTitle="New Position"
            />
            <div className="mt-3">
                <PositionTable />
            </div>
        </div>
    );
};

export default PositionPage;
