import React from 'react';
import {Link} from "react-router-dom";

export type Tab = {
    id: string;
    name: string;
    link: string;
    content: React.ReactElement | string;
}

const SeparateTabs = ({tabs = [], openTab = ''}:SeparateTabProps) => {
    return (
        <div>
            <div className="flex flex-col">
                <div className="p-3">
                    {tabs.map((tab) => {
                        if (tab.id === openTab) {
                            return <div key={tab.id}>{tab.content}</div>;
                        } else return null;
                    })}
                </div>
            </div>
        </div>
    );
};

export interface SeparateTabProps {
    tabs: Array<Tab>;
    openTab: string;
}
export default SeparateTabs;
