import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {InputFilter} from "../../../staffReport/models";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import HolidayReportTable from "../components/HolidayReportTable";
import {getHolidayReport, reset} from "../../attendanceReport.reducer";
import Button from "../../../../../components/Button/Button";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {customStatusOption} from "../../../../../helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const HolidayReportPage = () => {
    const {
        control,
        handleSubmit,
        watch,
        reset: resetForm
    } = useForm<InputFilter>({defaultValues: {startDate: new Date().toISOString(), endDate: new Date().toISOString()}});
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const actionStatus = useAppSelector(
        (state) => state.attendanceReport.changeStatus
    );

    const error = useAppSelector(
        (state) => state.attendanceReport.error ?? {} as HttpClientError
    );

    useEffect(() => {
        dispatch(
            getHolidayReport({})
        );
    }, []);

    useEffect(() => {
        if (
            actionStatus !== undefined &&
            actionStatus !== RequestStatus.pending
        ) {
            MySwal.fire(
                customStatusOption(
                    actionStatus === RequestStatus.success,
                    error.data?.message,
                    'status has been changed!',
                    'failed to change status'
                )
            ).then(() => {
                dispatch(reset());
                dispatch(
                    getHolidayReport({})
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatus, error]);

    const onSubmit = (data: InputFilter) => {
        let localStart = new Date(data.startDate).toISOString();
        let localEnd = new Date(data.endDate).toISOString();
        dispatch(
            getHolidayReport({
                startDate: localStart,
                endDate: localEnd,
            })
        );
    }

    const onResetClicked = () => {
        resetForm();
        dispatch(
            getHolidayReport({})
        );
    }

    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Holiday</h1>
                </div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <RangeDatePicker startName='startDate' endName='endDate' startValue={new Date(startDate)} endValue={new Date(endDate)} control={control} centerStyle/>
                        <div className="flex justify-end items-center">
                            <div className={"mx-1"}>
                                <Button
                                    color="success"
                                    type="button"
                                    title="Reset"
                                    onClick={onResetClicked}
                                />
                            </div>
                            <div className={"ml-1"}>
                                <Button
                                    color="success"
                                    type="submit"
                                    title="Filter"
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="mt-3">
                    <HolidayReportTable />
                </div>
            </div>
        </div>
    );
};

export default HolidayReportPage;
