import * as yup from "yup";
import {RelationTypeEnum} from "../employee/models";
import {HolidayCalendarTypeEnum} from "./models";

export const holidayCalendarSchema = yup.object({
    name: yup.string().required(),
}).required();

export const employeeHolidaySchema = yup.object({
    userId: yup.string().required(),
}).required();

export const holidayCalendarTypeOptions = [
    {value: HolidayCalendarTypeEnum.Indonesia.toString(), label: 'Indonesia', text: 'Indonesia'},
    {value: HolidayCalendarTypeEnum.US.toString(), label: 'US', text: 'US'},
]