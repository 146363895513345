import {HttpClientError} from "../../http-client";
import {LSK_USER_SLICE, SECRET_KEY} from "../../constants";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {LoginCredential, UserCredential} from "./models";
import UserApi from "./login.api";
import CryptoJS from 'crypto-js';

export interface UserSlice {
    userAuth?: UserCredential;
    isAttendanceToday?: boolean;
    isLoading?: boolean;
    error?: HttpClientError;
}

const getInitialSlice = () => {
    let result = {} as UserSlice;
    try {
      result = JSON.parse( CryptoJS.AES.decrypt(localStorage.getItem(LSK_USER_SLICE) as string, SECRET_KEY).toString(CryptoJS.enc.Utf8) ) as UserSlice;
      return result;
    } catch (e) {
        console.log(e);
        return {};
    }
}
// - IF EXISTS ON LOCAL STORAGE -
// getting initial slice from local storage
const initialSlice = localStorage.getItem(LSK_USER_SLICE)
    ? getInitialSlice()
    : {};

export const login = createAsyncThunk('user/login', async (loginCredential: LoginCredential, {rejectWithValue}) => {
    try {
        return await UserApi.login(loginCredential);
    } catch (e) {
        return rejectWithValue(e as HttpClientError);
    }
});
//
const userSlice = createSlice({
    name: 'userState',
    initialState: initialSlice,
    reducers: {
        clearSession: (state) => {
            state.userAuth = {} as UserCredential;
        },
        resetError: (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });

        builder.addCase(login.fulfilled, (state, {payload}) => {
            state.userAuth = payload.data;
            state.isAttendanceToday = payload.isAttendanceToday;
            state.isLoading = false;
        });

        builder.addCase(login.rejected, (state, {payload}) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});

export const {clearSession, resetError} = userSlice.actions;
export default userSlice.reducer;