import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {
    savedStatusOption,
} from '../../../../helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {HttpClientError, RequestStatus} from '../../../../http-client';
import {RootState} from '../../../../app/store';
import {BiEditAlt} from 'react-icons/bi';
import {UserCredential} from '../../../login/models';
import {getHolidayWithFilter, reset} from '../../approval.reducer';
import {HolidayModel} from "../../../profile/models";
import DefaultModal from "../../../../components/Modal/DefaultModal";
import AgDataGridServerPaging from "../../../../components/DataGrid/AgDataGridServerPaging";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import HolidayApprovalForm from "./HolidayApprovalForm";
import {
    approvalStatusFilterOptions,
    getSortModel, getSortType, holidaySortOption,
} from "../../constants";
import {isArrayEmpty} from "../../../../helpers/checkEmptiness";
import {dateSetTimeToNow} from "../../../../helpers/convert";

const HolidayApprovalTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    const gridRef = useRef<any>(null);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [singleHoliday, setSingleHoliday] = useState<HolidayModel>({} as HolidayModel);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const status = useAppSelector(
        (state: RootState) => state.approval.holidayApprovalStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.approval.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                if (status === RequestStatus.success) {
                    closeModal();
                }
                refreshCache(undefined);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(holidaySortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.ts_created || {};
                const {dateFrom: startReqDate, dateTo: endReqDate} = params.request.filterModel?.reqDate || {};

                dispatch(
                    getHolidayWithFilter({
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        startDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
                        endDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
                        startReqDate: startReqDate !== undefined ? dateSetTimeToNow(startReqDate) : undefined,
                        endReqDate: endReqDate !== undefined ? dateSetTimeToNow(endReqDate) : undefined,
                        nip: params.request.filterModel?.nip?.filter,
                        team: params.request.filterModel?.teamName?.filter,
                        name: params.request.filterModel?.name?.filter,
                        note: params.request.filterModel?.notes?.filter,
                        status: params.request.filterModel?.statusName?.type === "all" || params.request.filterModel?.statusName?.type === undefined ? undefined : parseInt(params.request.filterModel?.statusName?.type ?? "0"),
                        rejection_note: params.request.filterModel?.rejectionNotes?.filter,
                        approve_by: params.request.filterModel?.approvalByName?.filter,
                        manager_approval: params.request.filterModel?.managerApprovedName?.filter,
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : undefined,
                        sortType: sortModel !== undefined ? sortType : undefined
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const openModal = (holiday: HolidayModel) => {
        setSingleHoliday(holiday);
        setShowModal(true);
    };

    const closeModal = () => {
        setSingleHoliday({} as HolidayModel);
        setShowModal(false);
    };

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        return (
            <div className="flex items-center h-[100%]">
                {
                    (user.roleName.some((item) => item === "Head") && props.data?.statusName === "Requested") &&
                    <button onClick={() => openModal(props.data)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
                {
                    (user.roleName.some((item) => item === "Administrator" || item === "Superadmin" || item === "HRD") && props.data?.statusName !== "ManagerApproval" && props.data?.statusName !== "Rejected") &&
                    <button onClick={() => openModal(props.data)}>
                        <BiEditAlt className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {
            field: 'ts_created', headerName: 'Created Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'reqDate', headerName: 'Req Date', cellDataType: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'nip', headerName: 'NIP',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'teamName', headerName: 'Team',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'name', headerName: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'notes', headerName: 'Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'statusName', headerName: 'status',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: approvalStatusFilterOptions,
                maxNumConditions: 1,
            }
        },
        {
            field: 'rejectionNotes', headerName: 'Rejection Notes',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'approvalByName', headerName: 'Approved By',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'managerApprovedName', headerName: 'Manager Approval',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                            getServerSideDataSource={getServerSideDataSource} defaultPageSize={50}
                                            supportSaveTableState tableStateName={'approval-holiday'}/>

                    <DefaultModal
                        title="Holiday Approval"
                        show={showModal}
                        onClose={closeModal}
                        body={
                            <HolidayApprovalForm singleHoliday={singleHoliday} userId={user.user_id}/>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default HolidayApprovalTable;
