import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import {exportEmployeeWork, getEmployeeWorkWithFilter} from "../../staffReport.reducer";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import Button from "../../../../../components/Button/Button";
import {useForm} from "react-hook-form";
import {EmployeeWorkFilterModel, InputFilter} from "../../models";
import DetailEmployeeWork from "./DetailEmployeeWork";
import {FaChevronRight} from "react-icons/fa";
import InputText from "../../../../../components/TextInput/InputText";
import FormLoader from "../../../../../components/Loader/FormLoader";
import {
    MdOutlineArrowBackIos,
    MdOutlineArrowForwardIos
} from "react-icons/md";

const StaffReportTable = () => {
    // const gridRef = useRef<any>(null);
    const dispatch = useAppDispatch();

    const {
        control,
        handleSubmit,
        watch,
        reset,
    } = useForm<InputFilter>({defaultValues: {startDate: new Date().toISOString(), endDate: new Date().toISOString()}});

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const [selectedDetail, setSelectedDetail] = useState<EmployeeWorkFilterModel>({} as EmployeeWorkFilterModel);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));
    const [searchName, setSearchName] = useState<string>("");
    const [searchTeam, setSearchTeam] = useState<string>("");
    const [currentPage, setCurrentPage] = useState(1);

    const employeeWorkWithFilterList = useAppSelector(state => state.staffReport.employeeWorkWithFilterList ?? []);
    const employeeWorkWithFilterRows = useAppSelector(state => state.staffReport.employeeWorkWithFilterRows ?? 0);
    const isEmployeeLoading = useAppSelector(state => state.staffReport.isEmployeeLoading);

    useEffect(() => {
        dispatch(getEmployeeWorkWithFilter({
            skip: 0,
            take: 10,
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
        }));
        setCurrentPage(1);
    }, []);

    // Each Column Definition results in one Column.
    // const [columnDefs, _setColumnDefs] = useState([
    //     {field: 'name', headerName: 'Name', filter: true, cellRenderer: 'agGroupCellRenderer'},
    //     {field: 'teamName', headerName: 'Team', filter: true},
    //     {
    //         field: 'date_filter',
    //         headerName: 'Request Date',
    //         cellDataType: 'date',
    //         filter: 'agDateColumnFilter',
    //         filterParams: {
    //             filterOptions: ['inRange'],
    //             maxNumConditions: 1,
    //         },
    //         hide: true,
    //     },
    // ]);

    // const getServerSideDataSource = () => {
    //     return {
    //         getRows: (params: any) => {
    //             const {dateFrom: startDate, dateTo: endDate} = params.request.filterModel?.date_filter || {};
    //             console.log(params.request.filterModel);
    //             dispatch(getEmployeeWorkWithFilter(
    //                 {
    //                     skip: params.request.startRow,
    //                     take: params.api.paginationGetPageSize(),
    //                     startDate: startDate !== undefined ? dateSetTimeToNow(startDate) : undefined,
    //                     endDate: endDate !== undefined ? dateSetTimeToNow(endDate) : undefined,
    //                 }
    //             )).then((res: any) => {
    //                 params.success({
    //                     rowData: res.payload?.data,
    //                     rowCount: res.payload.rows,
    //                 });
    //             }).catch((_err: any) => {
    //                 params.fail();
    //             })
    //         }
    //     }
    // }

    // const refreshCache = useCallback((route: any) => {
    //     const purge = !!document?.querySelector("#purge" as any)?.checked;
    //     gridRef.current.api.refreshServerSide({route: route, purge: purge});
    // }, []);

    const onNextPageChange = () => {
        if (Math.ceil(employeeWorkWithFilterRows / 10) > currentPage && !isEmployeeLoading) {
            setCurrentPage(prevState => prevState + 1);
            dispatch(getEmployeeWorkWithFilter({
                skip: (currentPage) * 10,
                take: 10,
                team: searchTeam === "" ? undefined : searchTeam,
                name: searchName === "" ? undefined : searchName,
                startDate: startDate,
                endDate: endDate,
            }));
        }
    }

    const onPrevPageChange = () => {
        if (currentPage > 1 && !isEmployeeLoading) {
            setCurrentPage(prevState => prevState - 1);
            dispatch(getEmployeeWorkWithFilter({
                skip: (currentPage - 2) * 10,
                take: 10,
                team: searchTeam === "" ? undefined : searchTeam,
                name: searchName === "" ? undefined : searchName,
                startDate: startDate,
                endDate: endDate,
            }));
        }
    }

    const onResetClicked = () => {
        reset();
        // refreshCache(undefined);
        dispatch(getEmployeeWorkWithFilter({
            skip: 0,
            take: 10,
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
        }));
        setSearchName("");
        setSearchTeam("");
        setSelectedDetail({} as EmployeeWorkFilterModel);
        setCurrentPage(1);
    }

    const onSubmit = (data: InputFilter) => {
        let localStart = new Date(data.startDate).toISOString();
        let localEnd = new Date(data.endDate).toISOString();
        // refreshCache(undefined);
        // gridRef.current.api.setFilterModel({
        //     date_filter: {
        //         filterType: "date",
        //         type: "inRange",
        //         dateFrom: localStart,
        //         dateTo: localEnd,
        //     },
        // })
        dispatch(getEmployeeWorkWithFilter({
            skip: 0,
            take: 10,
            startDate: localStart,
            endDate: localEnd,
        }));
        setCurrentPage(1);
    }

    const onExportHandler = () => {
        let localStart = new Date(startDate).toISOString();
        let localEnd = new Date(endDate).toISOString();

        dispatch(exportEmployeeWork({
            skip: 0,
            take: 10,
            team: searchTeam === "" ? undefined : searchTeam,
            name: searchName === "" ? undefined : searchName,
            startDate: localStart,
            endDate: localEnd,
        }))
    }

    const onDetailClicked = (item: EmployeeWorkFilterModel) => {
        setSelectedDetail(item);
    }

    const onSearchTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                setSearchTeam(e.target.value);
                dispatch(getEmployeeWorkWithFilter({
                    skip: 0,
                    take: 10,
                    team: e.target.value === "" ? undefined : e.target.value,
                    name: searchName === "" ? undefined : searchName,
                    startDate: startDate,
                    endDate: endDate,
                }));
                setCurrentPage(1);
            }, 1000)
        );
    }

    const onSearchNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                setSearchName(e.target.value);
                dispatch(getEmployeeWorkWithFilter({
                    skip: 0,
                    take: 10,
                    team: searchTeam === "" ? undefined : searchTeam,
                    name: e.target.value === "" ? undefined : e.target.value,
                    startDate: startDate,
                    endDate: endDate,
                }));
                setCurrentPage(1);
            }, 1000)
        );
    }

    return (
        <div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <RangeDatePicker startName='startDate' endName='endDate' startValue={new Date(startDate)}
                                     endValue={new Date(endDate)} control={control} centerStyle/>
                    <div className="flex justify-end items-center">
                        <div className={"mx-1"}>
                            <Button
                                color="success"
                                type="button"
                                title="Export to Excel"
                                onClick={onExportHandler}
                            />
                        </div>
                        <div className={"mx-1"}>
                            <Button
                                color="success"
                                type="button"
                                title="Reset"
                                onClick={onResetClicked}
                            />
                        </div>
                        <div className={"ml-1"}>
                            <Button
                                color="success"
                                type="submit"
                                title="Filter"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="mt-3">
                <div className="flex border border-[#dfdfdf]">
                    <div className="w-1/4">
                        <div className="p-4 border border-[#dfdfdf]">
                            <div>
                                <b>Filter</b>
                            </div>
                            <div className="flex">
                                <div className="w-1/2 pr-1">
                                    <InputText
                                        placeholder="Team"
                                        onChange={onSearchTeamChange}
                                    />
                                </div>
                                <div className="w-1/2 pl-1">
                                    <InputText
                                        placeholder="Name"
                                        onChange={onSearchNameChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="h-[calc(75vh-90px)] overflow-auto">
                            <FormLoader isLoading={isEmployeeLoading} label={"Fetching Data"}>
                                <>
                                    {
                                        employeeWorkWithFilterList.map((item) => (
                                            <div
                                                className={`flex p-4 items-center text-xs border border-[#dfdfdf] ${(item.name + item.teamName) === (selectedDetail.name + selectedDetail.teamName) && "bg-[#fcebe0]"} hover:bg-[#fcebe0] cursor-pointer`}
                                                onClick={() => onDetailClicked(item)}
                                            >
                                                <div className="flex-grow">
                                                    <div>
                                                        <b>Name</b>
                                                        <span className="text-[#919191] px-2">{item.name}</span>
                                                    </div>
                                                    <div>
                                                        <b>Team</b>
                                                        <span className="text-[#919191] px-2">{item.teamName}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <FaChevronRight
                                                        className={`${(item.name + item.teamName) === (selectedDetail.name + selectedDetail.teamName) ? "text-secondary-color" : "text-[#919191]"}`}/>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            </FormLoader>
                        </div>
                        <div className="h-[50px] flex overflow-x-auto sm:justify-center items-center">
                            <div className="flex justify-center items-center text-sm mr-3">
                                <span className={`px-5 cursor-pointer ${currentPage === 1 && "text-t-secondary-color"}`} onClick={onPrevPageChange}><MdOutlineArrowBackIos /></span>
                                <span>Page <b>{currentPage}</b> of <b>{Math.ceil(employeeWorkWithFilterRows / 10)}</b></span>
                                <span className={`px-5 cursor-pointer ${currentPage === Math.ceil(employeeWorkWithFilterRows / 10) && "text-t-secondary-color"}`} onClick={onNextPageChange}><MdOutlineArrowForwardIos /></span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <DetailEmployeeWork data={selectedDetail.detailRole ?? []}/>
                    </div>
                </div>
                {/*<div className="main-data-grid fielding mt-5">*/}
                {/*    <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}*/}
                {/*                            getServerSideDataSource={getServerSideDataSource} supportSaveTableState*/}
                {/*                            tableStateName={'employee-work-report'}*/}
                {/*                            detailCellRenderer={(params: any) => <DetailEmployeeWork data={params.data?.detailRole ?? []} />}*/}
                {/*                            tableDimension={{width: "100%", height: "80vh"}}*/}
                {/*                            defaultFilter={{*/}
                {/*                                date_filter: {*/}
                {/*                                    filterType: "date",*/}
                {/*                                    type: "inRange",*/}
                {/*                                    dateFrom: new Date().toISOString(),*/}
                {/*                                    dateTo: new Date().toISOString(),*/}
                {/*                                },*/}
                {/*                            }}*/}
                {/*                            isCustomHeader={true}*/}
                {/*                            customChildHeaderComponent={*/}
                {/*                                <>*/}
                {/*                                    <FlowbiteButton*/}
                {/*                                        onClick={onExportHandler}*/}
                {/*                                        size="xs"*/}
                {/*                                        color="success"*/}
                {/*                                    >*/}
                {/*                                        <PiMicrosoftExcelLogoDuotone className="mr-2 h-5 w-5"/>*/}
                {/*                                        Export to Excel*/}
                {/*                                    </FlowbiteButton>*/}
                {/*                                </>*/}
                {/*                            }*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default StaffReportTable;
