import React, { useState } from 'react';
import AgDataGrid from 'components/DataGrid/AgDataGrid';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import {filterParams} from "../../../../../helpers/agTable";
import {Button} from "flowbite-react";
import {PiMicrosoftExcelLogoDuotone} from "react-icons/pi";
import {exportJobInToCsv} from "../../jobReport.reducer";
import FormLoader from "../../../../../components/Loader/FormLoader";

const JobInTable = ({
                        startDate = new Date().toISOString(),
                        endDate = new Date().toISOString(),
                    }: JobInTableProps) => {
    const dispatch = useAppDispatch();

    const jobInList = useAppSelector(
        (state) => state.jobReport.jobInList ?? []
    );
    const isGetJobInLoading = useAppSelector(
        (state) => state.jobReport.isGetJobInLoading
    );
    const isExportInLoading = useAppSelector(
        (state) => state.jobReport.isExportInLoading
    );

    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([
        {field: 'ts_created', headerName: 'Date', filter: 'agDateColumnFilter', filterParams: filterParams, cellDataType: 'date'},
        { field: 'projectName', headerName: 'Project', filter: true },
        { field: 'jobNumber', headerName: 'Job Number', filter: true },
        { field: 'koj', headerName: 'KoJ', filter: true },
        { field: 'numberId', headerName: 'Id Number', filter: true },
        { field: 'otherNumber', headerName: 'Other Number', filter: true },
        { field: 'po_number', headerName: 'PO Number', filter: true },
        { field: 'district', headerName: 'District', filter: true },
        { field: 'jobType', headerName: 'Type', filter: true },
        { field: 'reqPole', headerName: 'Req Poles', filter: true, aggFunc: 'sum' },
    ]);

    const onExportHandler = () => {
        dispatch(exportJobInToCsv({
            startDate: startDate,
            endDate: endDate,
        }));
    }

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <FormLoader isLoading={isGetJobInLoading} label={"Fetching Data"}>
                    <AgDataGrid
                        rowData={jobInList}
                        columnDefs={columnDefs}
                        groupIncludeFooter={true}
                        groupIncludeTotalFooter={true}
                        supportSaveTableState
                        tableStateName={'report-job-in'}
                        defaultPageSize={20}
                        isCustomHeader={true}
                        customChildHeaderComponent={
                            <>
                                <Button
                                    onClick={onExportHandler}
                                    size="xs"
                                    color="success"
                                    isProcessing={isExportInLoading}
                                    disabled={isExportInLoading}
                                >
                                    <PiMicrosoftExcelLogoDuotone className="mr-2 h-5 w-5"/>
                                    {isExportInLoading ? "Exporting" : " Export to Excel"}
                                </Button>
                            </>
                        }
                    />
                    </FormLoader>
                </div>
            </div>
        </div>
    );
};

export interface JobInTableProps {
    startDate?: string;
    endDate?: string;
}

export default JobInTable;
