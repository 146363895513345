import {BaseServiceClass} from "../../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../../http-client";
import {
    AssignKatapultRequest,
    GetKatapultListRequest, GetKatapultMainWithFilterRequest, GetKatapultWithFilterRequest, KatapultCancelReq,
    KatapultMainModel,
    KatapultModel, KatapultSummary,
    KatapultWaitingReq
} from "./models";

export default class ProcessKatapultApi extends BaseServiceClass {
    static getKatapult(args: GetKatapultListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<KatapultModel[]>>(this.PATH_WORKSHEET.job_get_katapult, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getKatapultWithFilter(args: GetKatapultWithFilterRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<KatapultModel[]>>(this.PATH_WORKSHEET.job_get_katapult_with_filter, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static getSingleKatapult(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<KatapultModel>>(this.PATH_WORKSHEET.job_get_katapult, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: 0,
                take: 1,
                id: id
            }
        }).then((res) => res.data);
    }
    static getMainKatapult(args: GetKatapultListRequest, token: string, userId: string) {
        return httpClient.get<ApiResponseWrapper<KatapultMainModel[]>>(this.PATH_WORKSHEET.job_get_katapult_main, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getMainKatapultWithFilter(args: GetKatapultMainWithFilterRequest, token: string, userId: string) {
        return httpClient.post<ApiResponseWrapper<KatapultMainModel[]>>(this.PATH_WORKSHEET.job_get_katapult_main_with_filter, {...args, employee_id: userId}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static assignKatapult(args: AssignKatapultRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_katapult_assign, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data.data);
    }
    static waitingKatapult(args: KatapultWaitingReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_katapult_waiting, null, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data.data);
    }
    static getKatapultSummary(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<KatapultSummary[]>>(this.PATH_WORKSHEET.job_get_katapult_summary, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
    static cancelKatapult(args: KatapultCancelReq, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(this.PATH_WORKSHEET.job_katapult_cancel, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data.data);
    }
}