import React, { useEffect, useState } from 'react';
import BasicSelectInput, {
    Option,
} from '../../../../../components/SelectInput/BasicSelectInput';
import Button from '../../../../../components/Button/Button';
import {JobAttachmentRequest} from "../../../../basic/attachment/models";
import {createAttachment, updateAttachment} from "../../../../basic/attachment/attachment.reducer";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {UserCredential} from "../../../../login/models";

const SupportingDocumentType = ({selectedAttachment = {} as JobAttachmentRequest, ...props}:SupportingDocumentTypeProps) => {
    const dispatch = useAppDispatch();

    const [errMessage, setErrMessage] = useState<string>('');
    const [options, setOptions] = useState<Array<Option>>([]);
    const [selected, setSelected] = useState<string>('');

    const userState = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );

    useEffect(() => {
        let _arrOptions = selectedAttachment.option?.split(',');
        let _optionList: Array<Option> =
            _arrOptions?.map((item) => {
                return {
                    text: item,
                    label: item,
                    value: item,
                };
            }) ?? [];
        setOptions([
            {
                text: 'Select Item',
                label: 'Select Item',
                value: '',
            },
            ..._optionList,
        ]);
    }, []);

    const onModalSubmit = () => {
        if (selected === '') {
            setErrMessage('Required');
        } else {
            if (selectedAttachment?.id) {
                dispatch(
                    updateAttachment({
                        id: selectedAttachment.id,
                        job_id: selectedAttachment.job_id,
                        type_id: selectedAttachment.type_id,
                        value: selectedAttachment.value,
                        option: selected,
                        date_request: new Date().toISOString(),
                        link: "",
                        note: "",
                        employee_id: userState.user_id,
                    })
                );
            } else {
                dispatch(
                    createAttachment({
                        job_id: selectedAttachment.job_id,
                        type_id: selectedAttachment.type_id,
                        value: selectedAttachment.value,
                        option: selected,
                        date_request: new Date().toISOString(),
                        link: "",
                        note: "",
                        employee_id: userState.user_id,
                    })
                );
            }
            props.onModalClose();
        }
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <BasicSelectInput
                label="Supporting Document Type"
                options={options}
                message={errMessage}
                onChange={(e) => {
                    setSelected(e.target.value);
                }}
            />
            <div className="flex justify-center my-2">
                <div className="mx-1">
                    <Button title="No" type="button" onClick={props.toggleModal} />
                </div>
                <div className="mx-1">
                    <Button title="Yes" type="button" onClick={onModalSubmit} />
                </div>
            </div>
        </div>
    );
};

export interface SupportingDocumentTypeProps {
    selectedAttachment: JobAttachmentRequest;
    toggleModal: () => void;
    onModalClose: () => void;
}

export default SupportingDocumentType;
