import React, {useEffect, useState} from 'react';
import MyCalendar, {CalendarEvent} from "../../../../../components/Calendar/MyCalendar";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import Text from "../../../../../components/Text/Text";
import {AbsentLogDetailModel} from "../../models";
import Divider from "../../../../../components/Layout/Divider";
import {isObjectEmpty} from "../../../../../helpers/checkEmptiness";
import {RootState} from "../../../../../app/store";
import {getHolidayCalendarEventList} from "../../../../master/holiday/holiday.reducer";

const localizer = momentLocalizer(moment);
const AbsenLogDetail = () => {
    const dispatch = useAppDispatch();

    const absentDetail = useAppSelector(
        (state) => state.attendanceReport.absentReportDetail ?? {} as AbsentLogDetailModel
    );
    const holidayEventList = useAppSelector(
        (state: RootState) => state.holiday.holidayCalendarEventList ?? []
    );

    // const eventList = useAppSelector(
    //     (state) => state.attendanceReport.absentReportEventDetail ?? []
    // );

    const [eventList, setEventList] = useState<CalendarEvent[]>([]);

    useEffect(() => {
        dispatch(getHolidayCalendarEventList({ skip: 0, take: 200 }));
    }, []);

    useEffect(() => {
        if (!isObjectEmpty(absentDetail)) {
            let detail = absentDetail.details;
            let _eventAbsent: CalendarEvent[] = detail.filter((item) => item.time !== 0).map((item) => {
                return {
                    title: item.time.toString(),
                    start: moment(item.date).toDate(),
                    end: moment(item.date).toDate(),
                    allDay: true,
                }
            });

            let _eventLeave = detail.filter((item) => item.leaveDuration !== 0).map((item) => {
                return {
                    title: `I = ${item.leaveDuration?.toString()}`,
                    start: moment(item.date).toDate(),
                    end: moment(item.date).toDate(),
                    allDay: true,
                }
            });

            let _eventAlpha = detail.filter((item) => {
                return !item.isDeducted && !item.isRefNote && item.leaveDuration === 0 && item.time === 0 && !item.isHoliday
            }).map((item) => {
                return {
                    title: `A`,
                    start: moment(item.date).toDate(),
                    end: moment(item.date).toDate(),
                    allDay: true,
                }
            });

            let _eventSick = detail.filter((item) => item.isRefNote).map((item) => {
                return {
                    title: `SS`,
                    start: moment(item.date).toDate(),
                    end: moment(item.date).toDate(),
                    allDay: true,
                }
            });

            let _eventHoliday = detail.filter((item) => item.isHoliday).map((item) => {
                return {
                    title: `C`,
                    start: moment(item.date).toDate(),
                    end: moment(item.date).toDate(),
                    allDay: true,
                }
            });

            setEventList([..._eventAbsent, ..._eventLeave, ..._eventAlpha, ..._eventSick, ..._eventHoliday, ...holidayEventList])
        }
    }, [absentDetail, holidayEventList]);

    const customDayPropGetter = (date: {
        getDate: () => number;
        getDay: () => number;
        getMonth: () => number;
        getFullYear: () => number;
    }) => {
        let obj = holidayEventList.find((element, index) => {
            if (
                new Date(element?.start).getDate() === date.getDate() &&
                new Date(element?.start).getMonth() === date.getMonth() &&
                new Date(element?.start).getFullYear() === date.getFullYear()
            ) {
                return holidayEventList[index];
            }
        });
        if (date.getDay() === 0) {
            return { className: "date_weekend", style: { backgroundColor: "#fde0e0" } };
        } else if (obj !== undefined) {
            return { className: "date_weekend", style: { backgroundColor: "#fde0e0" } };
        } else {
            return { style: { backgroundColor: "#fff" } };
        }
    };

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">

            <Divider>
                <Divider.LeftContent>
                    <Text label="NIP" value={absentDetail.nip ?? "No Data"}/>
                    <Text label="Name" value={absentDetail.name}/>
                    <Text label="Team" value={absentDetail.team}/>
                    <Text label="Role" value={absentDetail.role}/>
                </Divider.LeftContent>
                <Divider.CenterLine />
                <Divider.RightContent>
                    <Text label="OT" value={absentDetail.ot?.toString()}/>
                    <Text label="Work Day" value={absentDetail.workDay?.toString()}/>
                    <Text label="Izin" value={absentDetail.izin?.toString()}/>
                    <Text label="Total" value={absentDetail.total?.toString()}/>
                </Divider.RightContent>
            </Divider>
            <MyCalendar style={{ height: 1000}} localizer={localizer} events={eventList} views={['month']} dayPropGetter={customDayPropGetter}/>
        </div>
    );
};
export default AbsenLogDetail;
