import {
    CreateDepartmentOwner,
    GetListRequest, DepartmentOwner, UpdateDepartmentOwner,
} from './models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpClientError, RequestStatus } from 'http-client';
import { RootState } from 'app/store';
import DepartmentOwnerApi from './departmentOwner.api';
import {Option} from "components/SelectInput/BasicSelectInput";

export interface DepartmentOwnerSlice {
    list?: Array<DepartmentOwner>;
    options?: Array<Option>;
    single?: DepartmentOwner;
    rows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    deleteStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getList = createAsyncThunk(
    'departmentOwner/getList',
    async (args: GetListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DepartmentOwnerApi.getList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingle = createAsyncThunk(
    'departmentOwner/getSingle',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DepartmentOwnerApi.getSingle(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const createDepartmentOwner = createAsyncThunk(
    'departmentOwner/create',
    async (args: CreateDepartmentOwner, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DepartmentOwnerApi.createDepartmentOwner(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const updateDepartmentOwner = createAsyncThunk(
    'departmentOwner/update',
    async (args: UpdateDepartmentOwner, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DepartmentOwnerApi.updateDepartmentOwner(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const deleteDepartmentOwner = createAsyncThunk(
    'departmentOwner/delete',
    async (args: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DepartmentOwnerApi.deleteDepartmentOwner(
                args,
                userAuth?.id as string
            );
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const departmentOwnerSlice = createSlice({
    name: 'departmentOwnerState',
    initialState: {} as DepartmentOwnerSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.single = {} as DepartmentOwner;
        },
        setSingle: (state, action) => {
            state.single = action.payload;
        }
    },
    extraReducers: (builder) => {
        // get list data reducer
        builder.addCase(getList.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, { payload }) => {
            state.rows = payload.rows;
            state.list = payload.data;
            state.options = payload.data.map((departmentOwner: DepartmentOwner) => {
                return {
                    text: departmentOwner.name,
                    value: departmentOwner.id
                }
            })
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state) => {
            state.isLoading = false;
        });

        // get single data reducer
        builder.addCase(getSingle.pending, (state) => {
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(getSingle.fulfilled, (state, { payload }) => {
            state.single = payload?.data;
            state.isLoading = false;
        });
        builder.addCase(getSingle.rejected, (state) => {
            state.isLoading = false;
        });

        // create data reducer
        builder.addCase(createDepartmentOwner.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createDepartmentOwner.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createDepartmentOwner.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // update data reducer
        builder.addCase(updateDepartmentOwner.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(updateDepartmentOwner.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(updateDepartmentOwner.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });

        // delete data reducer
        builder.addCase(deleteDepartmentOwner.pending, (state) => {
            state.deleteStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(deleteDepartmentOwner.fulfilled, (state) => {
            state.deleteStatus = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(deleteDepartmentOwner.rejected, (state, { payload }) => {
            state.deleteStatus = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    },
});

export const { reset, setSingle } = departmentOwnerSlice.actions;
export default departmentOwnerSlice.reducer;
