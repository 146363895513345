import React, {useEffect, useState} from 'react';
import {InputStatusModel} from "features/worksheet/process/job/models";
import {Link} from "react-router-dom";
import {
    MdOutlineCheckCircleOutline, MdPauseCircleOutline,
} from "react-icons/md";
import DefaultModal from "components/Modal/DefaultModal";
import JobChangeStatus from "features/worksheet/process/job/components/JobChangeStatus";
import {useAppDispatch, useAppSelector} from "helpers/redux";
import {RootState} from "app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {getCancelList, getList} from "../jobCheck.reducer";
import {customStatusOption, customWarnOption} from "helpers/alertLabel";
import {
    changeStatusJob,
    reset, waitingJob
} from "features/worksheet/process/job/job.reducer";
import {HttpClientError, RequestStatus} from "http-client";
import {DETAIL_TYPE_JOB_INPUT} from "../../../process/job/constants";
import AgDataGrid from "../../../../../components/DataGrid/AgDataGrid";
import {filterParams} from "../../../../../helpers/agTable";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {JOB_STATUS_PREP} from "../../../../../constants";
import {UserCredential} from "../../../../login/models";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";

const JobCancelTable = () => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const jobCancelList = useAppSelector((state: RootState) => state.jobCheck.cancelList ?? []);
    const actionStatus = useAppSelector(
        (state: RootState) => state.job.actionStatus
    );
    const error = useAppSelector(
        (state: RootState) => state.job.error ?? ({} as HttpClientError)
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const [jobId, setJobId] = useState<string>('');
    const [tableName, setTableName] = useState('');

    useEffect(() => {
        dispatch(getCancelList({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (
            tableName === 'cancel' &&
            actionStatus !== undefined &&
            actionStatus !== RequestStatus.pending
        ) {
            MySwal.fire(
                customStatusOption(
                    actionStatus === RequestStatus.success,
                    error.data?.message,
                    'status has been changed!',
                    'failed to change status'
                )
            ).then(() => {
                dispatch(reset());
                dispatch(getCancelList({}));
                dispatch(getList({}));
                setShowModal(false);
                setTableName('');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableName, actionStatus, error]);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
        setAction('');
        setJobId('');
    };

    const openModal = (action: string, id: string) => {
        setAction(action);
        setShowModal(true);
        setJobId(id);
    };

    const onStatusClicked = (id: string, action: string, data?: InputStatusModel) => {
        MySwal.fire(
            customWarnOption(
                'Waiting Job',
                'Are you sure you want to waiting this job? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                setTableName('cancel');
                dispatch(
                    waitingJob({
                        job_id: id,
                        waiting_notes: data?.notes ?? '',
                        waiting_subject_id:
                            data?.pause_subject_id ?? '',
                        waiting_by_user: user.user_id
                    })
                );
            }
        });
    };

    const onActionClicked = (job_id: string) => {
        MySwal.fire(
            customWarnOption(
                'Active Job',
                'Are you sure to active this job? You will not be able to recover this later!',
            )
        ).then((result) => {
            if (result.isConfirmed) {
                setTableName('cancel');
                dispatch(changeStatusJob({
                    job_id: job_id,
                    status: JOB_STATUS_PREP,
                    userId: user.user_id
                }))
            }
        });
    };

    const ActionCellRender = (props: any) => {
        return (
            <div className="flex align-middle h-[100%]">
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCancelJob, ActionEnum.ActionCheck) &&
                    <button onClick={() => onActionClicked(props.data?.id)}>
                        <MdOutlineCheckCircleOutline
                            className="mr-2 text-2xl text-primary-color"
                        />
                    </button>
                }
                {
                    checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCancelJob, ActionEnum.ActionWaiting) &&
                    <button onClick={() => openModal('waiting', props.data?.id)}>
                        <MdPauseCircleOutline className="mr-2 text-2xl text-primary-color"/>
                    </button>
                }
            </div>
        )
    }

    const JobNumberLinkCellRender = (props: any) => (
        <Link
            to={`/worksheet/ocalc/job-detail/${props.data?.id}`}
            className="underline text-blue-700"
        >
            {props.data?.number_job}
        </Link>
    );

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            maxWidth: 50,
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'sales_order', headerName: 'Sales Order', filter: true},
        {field: 'number_id', headerName: 'ID Number', filter: true},
        {
            headerName: 'Job Number',
            field: 'number_job',
            filter: 'agTextColumnFilter',
            suppressCellFlash: true,
            cellRenderer: JobNumberLinkCellRender,
        },
        {field: 'project_name', headerName: 'Project', filter: 'agTextColumnFilter'},
        {field: 'department_owner_name', headerName: 'Department Owner', filter: 'agTextColumnFilter'},
        {field: 'cancel_by_user_name', headerName: 'Cancel By', filter: 'agTextColumnFilter'},
        {
            field: 'cancel_ts',
            headerName: 'Cancel Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date_time'
        },
        {field: 'kind_name', headerName: 'KoJ', filter: 'agTextColumnFilter'},
        {field: 'waiting_subject_name', headerName: 'Waiting Subject', filter: 'agTextColumnFilter'},
        {
            field: 'ts_created',
            headerName: 'Request Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date'
        },
        {field: 'status_name', headerName: 'Status', filter: true},
        {field: 'type_name', headerName: 'Type', filter: true},
        {field: 'district_name', headerName: 'District'},
        {field: 'po_number', headerName: 'PO Number', filter: false, sortable: false},
        {
            field: 'date_due',
            headerName: 'Due Date',
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellDataType: 'date'
        },
        {field: 'pole_total', headerName: 'Req Pole'},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
        },
    ]);

    return (
        <div>
            <div className="main-data-grid mt-5">
                <AgDataGrid rowData={jobCancelList} columnDefs={columnDefs} supportSaveTableState tableStateName={'job-data-cancel-job'} defaultPageSize={20}/>
                <DefaultModal
                    title="Change Status"
                    show={showModal}
                    onClose={toggleModal}
                    body={<JobChangeStatus action={action} jobId={jobId} onSubmit={onStatusClicked}/>}
                />
            </div>
        </div>
    );
};

export default JobCancelTable;
