import {ComponentPropsWithRef, FC, forwardRef} from "react";

type InputTextProps = {
    label?: string;
    message?: string;
    customMessageClass?: string;
}

type InputTextType = FC<InputTextProps & ComponentPropsWithRef<'input'>>;

const InputText: InputTextType = forwardRef((props, ref) => {
    const {
        label = "",
        message = "",
        customMessageClass= "text-gray-500 dark:text-gray-400",
        ...rest} = props;

    return <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
        {
            label && <label htmlFor={label} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{label}</label>
        }
        <input
            type="text"
            id={label}
            ref={ref}
            {...rest}
            className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-color focus:ring-primary-color dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-color dark:focus:ring-primary-color rounded-lg p-2.5 text-sm"
        />

        {message !== '' && (
            <p className={`text-sm ${customMessageClass}`}>
                {message}
            </p>
        )}
    </div>
});

export default InputText;