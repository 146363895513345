import React, {useState} from 'react';
import BasicTabs from "../../../components/Tabs/BasicTabs";
import JobStatisticPage from "./jobStatistic/containers/JobStatisticPage";
import JobInPage from "./jobIn/containers/JobInPage";
import SeparateTabs from "../../../components/Tabs/SeparateTabs";
import {useAppSelector} from "../../../helpers/redux";
import {RootState} from "../../../app/store";

const JobReportMainPage = () => {
    //this id must match with job report dynamic nav id
    const tabs = [
        { id: 'job-statistic', name: "Job Statistic", link: `#`, content: <JobStatisticPage /> },
        { id: 'job-in', name: "Job In", link: `#`, content: <JobInPage /> },
    ];

   const dynamicNavId = useAppSelector((state: RootState) => state.sidebar.dynamicNavId ?? "");

    return (
        <div>
            <SeparateTabs tabs={tabs} openTab={dynamicNavId} />
        </div>
    );
};

export default JobReportMainPage;
