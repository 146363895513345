import React, {useRef, useState} from 'react';
import FormLoader from "../../../../components/Loader/FormLoader";
import AgDataGridStatic from "../../../../components/DataGrid/AgDataGridStatic";
import {useAppSelector} from "../../../../helpers/redux";
import {rowNumberRenderer} from "../../../../components/DataGrid/agGridCustomProps";
import {RootState} from "../../../../app/store";
import {BiCloudDownload} from "react-icons/bi";
import FileAttachmentApi from "../../../basic/fileAttachment/fileAttachment.api";

const ReportBillingOverviewBillTable = () => {
    const gridRef = useRef<any>(null);

    const billJob = useAppSelector((state: RootState) => state.billJob.billByJobList ?? []);

    const VDriveCellRender = (props: any) => {
        const [isExpand, setIsExpand] = useState<boolean>(false);

        if (props.data === undefined) {
            return null;
        }

        const toggleExpand = () => {
            setIsExpand(!isExpand);
        }

        return <div>
            {props.data.vDriveLocation?.length > 10 ? isExpand ? props.data.vDriveLocation : props.data.vDriveLocation?.substring(0, 10) : props.data.vDriveLocation}{props.data.vDriveLocation?.length > 10 &&
            <span className="text-cyan-600 hover:underline dark:text-cyan-500" onClick={toggleExpand}>...</span>}
        </div>
    }

    const NotesCellRender = (props: any) => {
        const [isExpand, setIsExpand] = useState<boolean>(false);

        if (props.data === undefined) {
            return null;
        }

        const toggleExpand = () => {
            setIsExpand(!isExpand);
        }

        return <div>
            {props.data.notes?.length > 10 ? isExpand ? props.data.notes : props.data.notes?.substring(0, 10) : props.data.notes}{props.data.notes?.length > 10 &&
            <span className="text-cyan-600 hover:underline dark:text-cyan-500" onClick={toggleExpand}>...</span>}
        </div>
    }

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
            suppressSizeToFit: true,
        },
        {field: 'ts_created', headerName: 'Entry Date', cellDataType: 'date'},
        {field: 'reqNumber', headerName: 'Req Number', filter: 'agTextColumnFilter'},
        {field: 'typeName', headerName: 'Bill Type', filter: 'agTextColumnFilter'},
        {field: 'statusName', headerName: 'Entry Type', filter: 'agTextColumnFilter'},
        {field: 'totalPole', headerName: 'Pole', filter: 'agTextColumnFilter'},
        {field: 'qc_employee', headerName: 'QC', filter: 'agTextColumnFilter'},
        {field: 'notes', headerName: 'Notes', filter: 'agTextColumnFilter', cellRenderer: NotesCellRender},
        {field: 'qc_note', headerName: 'QC Notes', filter: 'agTextColumnFilter'},
        {field: 'salesOrderJob', headerName: 'Sales Order', filter: 'agTextColumnFilter'},
        {field: 'otherNumberJob', headerName: 'Other Number', filter: 'agTextColumnFilter'},
        {field: 'poNumberJob', headerName: 'PO Number', filter: 'agTextColumnFilter'},
        {field: 'number', headerName: 'Number ID', filter: 'agTextColumnFilter'},
        {field: 'numberJob', headerName: 'Project Number', filter: 'agTextColumnFilter'},
        {
            field: 'vDriveLocation',
            headerName: 'vDrive Location',
            filter: 'agTextColumnFilter',
            cellRenderer: VDriveCellRender
        },
        {
            field: 'attachment', headerName: 'Attachment', filter: 'agTextColumnFilter', cellRenderer: (props: any) => (
                <>
                    {props.data?.attachment &&
                        <div><a
                            href={FileAttachmentApi.getMediaEndpoint(props.data?.attachment ?? "")}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BiCloudDownload color="#00bdfe" size={20}/>
                        </a>
                        </div>
                    }
                </>
            ), cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    return (
        <div className="main-data-grid mt-5">
            <div>
                <FormLoader isLoading={false} label={"Fetching Data"}>
                    <AgDataGridStatic ref={gridRef} columnDefs={columnDefs} rowData={billJob} supportSaveTableState
                                      tableStateName={'bill-job-bill'} tableDimension={{
                        width: "100%",
                        height: "30vh",
                    }} defaultColDef={{
                        sortable: true,
                        resizable: true,
                        minWidth: 10,
                        wrapHeaderText: true,
                        wrapText: true,
                        autoHeight: true,
                        flex: 1,
                    }}/>
                </FormLoader>
            </div>
        </div>
    );
};

export default ReportBillingOverviewBillTable;
