import React, {useCallback, useEffect, useRef, useState} from 'react';
import {customWarnOption, savedStatusOption} from "helpers/alertLabel";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Dropdown} from "flowbite-react";
import {AiOutlineMenu} from "react-icons/ai";
import {isArrayEmpty} from "../../../../../helpers/checkEmptiness";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {getList as getPoleStatusList} from "../../../../master/poleStatus/poleStatus.reducer";
import {changeStatusMultiplePole, getPoleByJob, reset} from "../../../../basic/pole/pole.reducer";
import {HttpClientError, RequestStatus} from "../../../../../http-client";
import {Job} from "../../../process/job/models";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import AgDataGridServerPaging from "../../../../../components/DataGrid/AgDataGridServerPaging";
import {Pole, PoleGetByJobColumnSort} from "../../../../basic/pole/models";
import {getSortModel, getSortType} from "../../../../approval/constants";
import {poleOCalcSortOption} from "../constants";
import {SortingEnum} from "../../../../approval/models";
import moment from "moment";
import {getCalcSpecSummary} from "../../../process/calcSpec/calcSpec.reducer";
import {getJobQCSummary} from "../../../process/qc/qc.reducer";

const CalcSpecTable = ({jobId = undefined}: CalcSpecTableProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const gridRef = useRef<any>(null);

    const [selection, setSelection] = useState<Array<Pole>>([]);
    const [pageCount, setPageCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const singleJobState = useAppSelector(
        (state: RootState) => state.job.single ?? ({} as Job)
    );
    const rows = useAppSelector((state: RootState) => state.pole.poleByJobRows ?? 0);
    const pole = useAppSelector(
        (state: RootState) => state.pole.poleByJob ?? []
    );
    const poleIdList = useAppSelector(
        (state: RootState) => state.pole.poleByJobListId ?? []
    );
    const poleStatusList = useAppSelector(
        (state: RootState) => state.poleStatus.list ?? []
    );
    const status = useAppSelector((state: RootState) => state.pole.status);
    const error = useAppSelector(
        (state: RootState) => state.pole.error ?? ({} as HttpClientError)
    );
    const calcSpecSummaryList = useAppSelector((state: RootState) => state.calcSpec.calcSpecSummaryList ?? []);
    const qcSummaryList = useAppSelector((state: RootState) => state.qc.qcSummaryList ?? []);

    useEffect(() => {
        dispatch(getPoleStatusList({skip: 0, take: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (jobId !== undefined) {
            dispatch(getCalcSpecSummary(jobId as string));
            dispatch(getJobQCSummary(jobId as string));
        }
    }, [jobId]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                // dispatch(getPoleByJob({job_id: jobId as string, skip: 0, take: rowsPerPage}));
                setSelection([]);
                refreshCache(undefined);
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    // const toggleAll = () => {
    //     const _selectAll = !selectAll;
    //     setSelection(_selectAll ? poleIdList : []);
    //     setSelectAll(_selectAll);
    // };

    // const isSelected = (key: string) => {
    //     return selection.includes(key);
    // };

    // const toggleSelection = (key: string) => {
    //     // start off with the existing state
    //     let _selection = [...selection];
    //     const keyIndex = selection.indexOf(key);
    //     // check to see if the key exists
    //     if (keyIndex >= 0) {
    //         // it does exist so we will remove it using destructing
    //         _selection = [
    //             ...selection.slice(0, keyIndex),
    //             ...selection.slice(keyIndex + 1),
    //         ];
    //     } else {
    //         // it does not exist so add it
    //         _selection.push(key);
    //     }
    //     // update the state
    //     setSelection(_selection);
    // };

    const columns = [
        // {
        //     Header: (
        //         <>
        //             <Checkbox
        //                 id="select_all"
        //                 checked={selectAll}
        //                 onChange={toggleAll}
        //             />
        //         </>
        //     ),
        //     accessor: 'id',
        //     Cell: ({row}: any) => {
        //         return (
        //             <div>
        //                 <Checkbox
        //                     id={row.id}
        //                     checked={isSelected(row.original.id)}
        //                     onChange={() => toggleSelection(row.original.id)}
        //                 />
        //             </div>
        //         );
        //     },
        // },
        {
            Header: 'NO.',
            accessor: 'index',
            // Cell: ({row: {index}}: any) => {
            //     return <div>{index + 1}</div>;
            // },
        },
        {
            Header: 'Pole Seq',
            minWidth: 150,
            accessor: 'seq',
        },
        {
            Header: 'Pole No',
            minWidth: 150,
            accessor: 'no',
        },
        {
            Header: 'CS',
            minWidth: 150,
            accessor: 'employee_calcspec_name',
        },
        {
            Header: 'QC',
            minWidth: 150,
            accessor: 'employee_qc_name',
        },
        {
            Header: 'Status',
            minWidth: 150,
            accessor: 'status_name',
        },

    ];

    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'index',
            suppressSizeToFit: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            filter: false,
        },
        {
            field: 'seq', headerName: 'Pole Seq',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'no', headerName: 'Pole No',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'employee_calcspec_name', headerName: 'CS',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'employee_qc_name', headerName: 'QC',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            field: 'status_name', headerName: 'Status',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
    ]);

    const handlePageChange = (page: number) => {
        dispatch(getPoleByJob({
            job_id: jobId as string, skip: rowsPerPage * (page - 1),
            take: rowsPerPage
        }));
    };

    const handleRowChange = (totalRow: number) => {
        setRowsPerPage(totalRow);
        dispatch(getPoleByJob({
            job_id: jobId as string, skip: 0,
            take: totalRow
        }));
    };

    const onPageCountChange = (pageCount: number) => {
        setPageCount(pageCount);
    }

    const onMultipleChangeStatus = (status: string) => {
        if (isArrayEmpty(selection)) return null;
        let poleIds = selection.map((item) => item.id);
        MySwal.fire(
            customWarnOption(
                'Change Status Multiple Data',
                'Are you sure you want to change status the selected items?'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    changeStatusMultiplePole({
                        pole_ids: poleIds.toString(),
                        status_id: status,
                    })
                );
            }
        });
    };

    const getServerSideDataSource = () => {
        return {
            getRows: (params: any) => {
                const sortModel = isArrayEmpty(params.request.sortModel) ? undefined : getSortModel(poleOCalcSortOption, params.request.sortModel[0].colId) ?? undefined;
                const sortType = isArrayEmpty(params.request.sortModel) ? undefined : getSortType(params.request.sortModel[0].sort) ?? undefined;

                dispatch(
                    getPoleByJob({
                        job_id: jobId as string,
                        skip: params.request.startRow,
                        take: params.api.paginationGetPageSize(),
                        column: sortModel !== undefined ? parseInt(sortModel ?? "0") : PoleGetByJobColumnSort.index,
                        sort: sortModel !== undefined ? sortType : SortingEnum.Ascending,
                        seq: params.request.filterModel?.seq?.filter,
                        no: params.request.filterModel?.no?.filter,
                        cs: params.request.filterModel?.employee_calcspec_name?.filter,
                        qc: params.request.filterModel?.employee_qc_name?.filter,
                        status: params.request.filterModel?.status_name?.filter,
                    })
                ).then((res: any) => {
                    params.success({
                        rowData: res.payload?.data,
                        rowCount: res.payload.rows,
                    });
                }).catch((_err: any) => {
                    params.fail();
                })
            },
        };
    }

    const onSelectionChanged = useCallback(() => {
        let selectedRows = gridRef.current.api.getSelectedNodes();
        let _selectedRows = selectedRows.map((item: any) => item.data);
        setSelection(_selectedRows);
    }, []);

    const refreshCache = useCallback((route: any) => {
        const purge = !!document?.querySelector("#purge" as any)?.checked;
        gridRef.current.api.refreshServerSide({route: route, purge: purge});
    }, []);

    return (
        <div>
            <div className="main-data-grid mt-2">
                <div className="flex justify-items-center mb-3">
                    <div className="flex items-center grow">
                        <div>
                            <h1 className="font-medium text-gray-700 dark:text-gray-300">
                                OCALC - {singleJobState.number_job}
                            </h1>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
                <div>

                </div>
                <div className="flex">
                    <div className="w-2/3">
                        <AgDataGridServerPaging ref={gridRef} columnDefs={columnDefs}
                                                getServerSideDataSource={getServerSideDataSource}
                                                isRowSelectable={() => true} onSelectionChanged={onSelectionChanged}
                                                supportSaveTableState tableStateName={'approval-attendance'}
                                                isCustomHeader
                                                customChildHeaderComponent={
                                                    <div>
                                                        <Dropdown
                                                            color="light"
                                                            label={<AiOutlineMenu className="text-xl text-light"/>}
                                                            arrowIcon={false}
                                                        >
                                                            <Dropdown.Header><span
                                                                className="block text-sm font-medium truncate">Multiple Action</span></Dropdown.Header>
                                                            {
                                                                checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetCalcSpec, ActionEnum.ChangePoleStatus) &&
                                                                <>
                                                                    {poleStatusList.map((status) => (
                                                                        <Dropdown.Item
                                                                            key={status.id}
                                                                            onClick={() =>
                                                                                onMultipleChangeStatus(status.id)
                                                                            }
                                                                        ><span
                                                                            className={`${isArrayEmpty(selection) && 'text-gray-400'}`}>{status.name}</span></Dropdown.Item>
                                                                    ))}
                                                                </>
                                                            }
                                                        </Dropdown>
                                                    </div>
                                                }
                                                defaultPageSize={20}
                        />
                    </div>
                    <div className="w-1/3 mx-3">

                        <div className="h-[36vh] mb-5 border border-[#dfdfdf] rounded-lg w-[100%]">
                            <div className="h-[40px] bg-[#e7ebe6] rounded-t-lg flex items-center">
                                <label className="block text-sm font-bold text-gray-700 dark:text-gray-300 px-3">
                                    Calc Spec Summary
                                </label>
                            </div>
                            <div className="h-[calc(36vh-40px)] overflow-auto">
                                    <div className="overflow-auto h-[calc(36vh-40px)]">
                                        {
                                            calcSpecSummaryList.map((item) => (
                                                <div
                                                    className="flex h-[40px] p-4 items-center text-xs border border-[#dfdfdf] hover:bg-[#fcebe0]">
                                                    <div className="w-1/3 truncate">
                                                        <b>{item.employee_name}</b>
                                                    </div>
                                                    <div className="w-1/3">
                                                        <span className="text-[#919191]">Total Poles </span>
                                                        <b>{item.total}</b>
                                                    </div>
                                                    <div className="w-1/3">
                                                    <span
                                                        className="text-[#919191]">Date </span><b>{moment(item.date_due).format("D MMM YYYY")}</b>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                            </div>
                        </div>
                        <div className="h-[36vh] mb-5 border border-[#dfdfdf] rounded-lg w-[100%]">
                            <div className="h-[40px] bg-[#e7ebe6] rounded-t-lg flex items-center">
                                <label className="block text-sm font-bold text-gray-700 dark:text-gray-300 px-3">
                                    QC Summary
                                </label>
                            </div>
                            <div className="h-[calc(36vh-40px)] overflow-auto">
                                    <div className="overflow-auto h-[calc(36vh-40px)]">
                                        {
                                            qcSummaryList.map((item) => (
                                                <div
                                                    className="flex h-[40px] p-4 items-center text-xs border border-[#dfdfdf] hover:bg-[#fcebe0]">
                                                    <div className="w-1/3 truncate">
                                                        <b>{item.employee_name}</b>
                                                    </div>
                                                    <div className="w-1/3">
                                                        <span className="text-[#919191]">Total Poles </span>
                                                        <b>{item.total}</b>
                                                    </div>
                                                    <div className="w-1/3">
                                                    <span
                                                        className="text-[#919191]">Date </span><b>{moment(item.date_due).format("D MMM YYYY")}</b>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<TableWithServerPaging*/}
                {/*    columns={columns}*/}
                {/*    data={pole}*/}
                {/*    serverRows={rows}*/}
                {/*    handlePageChange={handlePageChange}*/}
                {/*    handleRowChange={handleRowChange}*/}
                {/*    pageCount={pageCount}*/}
                {/*    changePageCount={onPageCountChange}*/}
                {/*/>*/}

            </div>
        </div>
    );
};

export interface CalcSpecTableProps {
    jobId?: string;
}

export default CalcSpecTable;

