import {BaseServiceClass} from "../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../http-client";
import {Employee, GetListByPositionRequest, GetListRequest, InputEmployee} from "./models";

export default class EmployeeApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<Employee[]>>(`${this.BASE_URL}${this.PATH_MASTER.employee}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getListByPosition(args: GetListByPositionRequest, token: string) {
        return httpClient.get<any>(`${this.BASE_URL}${this.PATH_MASTER.employee_get_by_position}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<Employee>>(`${this.BASE_URL}${this.PATH_MASTER.employee}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
                skip: 0,
                take: 1
            }
        }).then((res) => res.data);
    }

    static createEmployee(args: InputEmployee, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.employee}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateEmployee(args: InputEmployee, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.employee}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deleteEmployee(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.employee}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
}