import {GlobalApprovalEnum} from "../../types";
import {HolidayCalendarTypeEnum} from "../master/holiday/models";
import {OvertimeSortEnum} from "../approval/models";

export type InputAttendance = {
    reqMinute: string;
    absentDate: string;
    leaveDuration: string;
    notes: string;
}

export type InputHoliday = {
    reqDate: string;
    notes: string;
}

export type SendAttendanceReq = {
    id?: string;
    reqMinute: number;
    leaveDuration: number;
    notes: string;
    rejectionNotes: string;
    status: GlobalApprovalEnum;
    approveMinute: number;
    isDeducted: boolean;
    isRefNote: boolean;
    userId: string;
    absentDate: string;
}

export type AttendanceModel = {
    id?: string;
    reqMinute: number;
    leaveDuration: number;
    notes: string;
    rejectionNotes: string;
    status: GlobalApprovalEnum;
    approveMinute: number;
    isDeducted: boolean;
    isRefNote: boolean;
    userId: string;
    ts_created?: string;
    approvalDate?: string;
    absentDate: string;
    name?: string;
}

export type GetAttendanceReq = {
    skip: number;
    take: number;
    id?: string;
    userId?: string;
    startDate?: string;
    endDate?: string;
    filter?: string;
    status?: GlobalApprovalEnum;
    column?: OvertimeSortEnum;
    sortType?: SortingEnum;
}

export type SendHolidayReq = {
    id?: string;
    reqDate: string;
    notes: string;
    status: HolidayOvertimeApprovalEnum;
    rejectionNotes: string;
    managerApproved: string;
    approvedBy: string;
    userId: string;
}

export type HolidayModel = {
    id?: string;
    reqDate: string;
    notes: string;
    status: HolidayOvertimeApprovalEnum;
    rejectionNotes: string;
    managerApproved: string;
    approvedBy: string;
    userId: string;
    ts_created?: string;
    name?: string;
}


export type GetHolidayReq = {
    skip: number;
    take: number;
    id?: string;
    userId?: string;
    startDate?: string;
    endDate?: string;
    startCreatedDate?: string;
    endCreatedDate?: string;
    filter?: string;
    status?: HolidayOvertimeApprovalEnum;
    column?: OvertimeSortEnum;
    sortType?: SortingEnum;
}

export type InputOvertime = {
    roleId: string;
    roleName?: string;
    reqMinute: number;
    notes: string;
    status: HolidayOvertimeApprovalEnum;
    rejectionNote: string;
    approveMinute: number;
    multiplier: string;
    managerApproveById: string;
    approveById: string;
    userId: string;
    detail: DetailOvertime[];
}

export type GetOvertimeReq = {
    skip: number;
    take: number;
    id?: string;
    userId?: string;
    startDate?: string;
    endDate?: string;
    filter?: string;
    status?: HolidayOvertimeApprovalEnum;
    column?: OvertimeSortEnum;
    sortType?: SortingEnum;
}

export type SendOvertimeReq = {
    id?: string;
    roleId: string;
    reqMinute: number;
    notes: string;
    status: HolidayOvertimeApprovalEnum;
    rejectionNote: string;
    approveMinute: number;
    multiplier: number;
    managerApproveById: string | null;
    approveById: string | null;
    userId: string;
    detail: DetailOvertime[];
}

export type OvertimeModel = {
    id?: string;
    roleId: string;
    roleName?: string;
    reqMinute: number;
    notes: string;
    status: HolidayOvertimeApprovalEnum;
    rejectionNote: string;
    approveMinute: number;
    multiplier: number;
    managerApproveById: string;
    approveById: string;
    userId: string;
    details: DetailOvertime[];
    name?: string;
}

export type DetailOvertime = {
    pole: number;
    work: string;
    note: string;
    overtimeId?: string;
}

export enum HolidayOvertimeApprovalEnum
{
    Requested,ManagerApproved, Approved, Rejected
}

export enum HolidayApprovalEnum
{
    Requested, ApprovedByHead, ManagerApproval, Rejected
}

export type CalendarModel = {
    id?: string;
    date: string;
    name: string;
    type: HolidayCalendarTypeEnum;
}

export type EmployeeHolidayUserModel = {
    id: string;
    userId: string;
    name: string | null;
    applicationUser: string | null;
    holidayAvailable: number;
    totalHolidaysMax: number;
    totalHolidaysTaken: number;
    totalHolidaysSoon: number;
    totalHolidaysRemaining: number;
}


export type GetNotificationUserReq = {
    skip: number;
    take: number;
    userId?: string;
}

export type NotificationModel = {
    id: string;
    title: string;
    description: string;
    userId: string;
}

export enum SortingEnum {
    Ascending,
    Descending
}

export enum AttendanceProfileWithFilterColumnSort
{
    ts_created,
    absent_date,
    req_minute,
    leave_duration,
    notes,
    status,
    approval_minute,
    rejection_note
}

export enum HolidayProfileWithFilterColumnSort
{
    ts_created,
    req_date,
    note,
    status,
    rejection_note,
    approve_by,
    manager_approval
}
export enum OvertimeProfileColumnSort
{
    ts_created,
    role,
    req_minute,
    notes,
    rejection_note,
    approved_time,
    status,
    approve_by,
    manager_approval
}

export type GetAttendanceWithFilterReq = {
    skip: number;
    take: number;
    startDate?: string;
    endDate?: string;
    startAbsentDate?: string;
    endAbsentDate?: string;
    req_minute?: number;
    leave_duration?: number;
    note?: string;
    userId?: string;
    status?: number;
    approve_minute?: number;
    rejection_note?: string;
    column?: AttendanceProfileWithFilterColumnSort;
    sortType?: SortingEnum;
}

export type GetHolidayWithFilterReq = {
    skip: number;
    take: number;
    startDate?: string;
    endDate?: string;
    startReqDate?: string;
    endReqDate?: string;
    note?: string;
    status?: number;
    userId?: string;
    rejection_note?: string;
    approve_by?: string;
    manager_approval?: string;
    column?: HolidayProfileWithFilterColumnSort;
    sortType?: SortingEnum;
}

export type GetOvertimeWithFilterReq = {
    skip: number;
    take: number;
    startDate?: string;
    endDate?: string;
    userId?: string;
    role?: string;
    req_minute?: number;
    note?: string;
    status?: number;
    rejection_note?: string;
    approve_by?: string;
    manager_approval?: string;
    approve_minute?: number;
    column?: OvertimeProfileColumnSort;
    sortType?: SortingEnum;
}