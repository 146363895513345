import {GetHistoryWithFilterRequest, HistoryModel} from "./models";
import {HttpClientError, RequestStatus} from "../../../http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import HistoryApi from "./history.api";
import {RootState} from "../../../app/store";


export interface HistorySlice {
    historyList?: Array<HistoryModel>;
    historyRows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getHistory = createAsyncThunk(
    'history/getHistory',
    async (args: GetHistoryWithFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await HistoryApi.getHistory(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

const historySlice = createSlice({
    name: 'historyState',
    initialState: {} as HistorySlice,
    reducers: {},
    extraReducers: builder => {
        // get history
        builder.addCase(getHistory.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getHistory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.historyList = action.payload.data;
            state.historyRows = action.payload.rows;
        });
        builder.addCase(getHistory.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
    }
});

export default historySlice.reducer;