import {
    AttendanceModel,
    GetAttendanceReq, GetAttendanceWithFilterReq,
    GetHolidayReq, GetHolidayWithFilterReq, GetNotificationUserReq, GetOvertimeReq, GetOvertimeWithFilterReq,
    HolidayModel, NotificationModel, OvertimeModel,
    SendAttendanceReq,
    SendHolidayReq, SendOvertimeReq
} from "./models";
import {HttpClientError, RequestStatus} from "../../http-client";
import ProfileApi from "./profile.api";
import {RootState} from "../../app/store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GridData} from "../../components/List/GridList";

export interface ProfileSlice {
    attendanceList?: Array<AttendanceModel>;
    holidayList?: Array<HolidayModel>;
    overtimeList?: Array<OvertimeModel>;
    employeeHolidayList?: Array<GridData>;
    notificationUserList?: Array<NotificationModel>;
    attendanceRows?: number;
    holidayRows?: number;
    overtimeRows?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    attendanceStatus?: RequestStatus;
    holidayStatus?: RequestStatus;
    overtimeStatus?: RequestStatus;
    overtimeDeleteStatus?: RequestStatus;
    error?: HttpClientError;
}

export const getAttendanceList = createAsyncThunk(
    'profile/getAttendanceList',
    async (args: GetAttendanceReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getAttendanceList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getAttendanceApprovalList = createAsyncThunk(
    'profile/getAttendanceApprovalList',
    async (args: GetAttendanceReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getAttendanceApprovalList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getAttendance = createAsyncThunk(
    'profile/getAttendance',
    async (args: GetAttendanceWithFilterReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getAttendance(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const createAttendance = createAsyncThunk(
    'profile/createAttendance',
    async (args: SendAttendanceReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.createAttendance(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const updateAttendance = createAsyncThunk(
    'profile/updateAttendance',
    async (args: SendAttendanceReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.updateAttendance(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const deleteAttendance = createAsyncThunk(
    'profile/deleteAttendance',
    async (arg: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.deleteAttendance(arg, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getHolidayList = createAsyncThunk(
    'profile/getHolidayList',
    async (args: GetHolidayReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getHolidayList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getHolidayApprovalList = createAsyncThunk(
    'profile/getHolidayApprovalList',
    async (args: GetHolidayReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getHolidayApprovalList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getHoliday = createAsyncThunk(
    'profile/getHoliday',
    async (args: GetHolidayWithFilterReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getHoliday(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getEmployeeHolidayByUser = createAsyncThunk(
    'profile/getEmployeeHolidayByUser',
    async (userId: string, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getEmployeeHolidayByUser(userId, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const createHoliday = createAsyncThunk(
    'profile/createHoliday',
    async (args: SendHolidayReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.createHoliday(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const updateHoliday = createAsyncThunk(
    'profile/updateHoliday',
    async (args: SendHolidayReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.updateHoliday(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const deleteHoliday = createAsyncThunk(
    'profile/deleteHoliday',
    async (arg: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.deleteHoliday(arg, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getOvertimeList = createAsyncThunk(
    'profile/getOvertimeList',
    async (args: GetOvertimeReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getOvertimeList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getOvertimeApprovalList = createAsyncThunk(
    'profile/getOvertimeApprovalList',
    async (args: GetOvertimeReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getOvertimeApprovalList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getOvertime = createAsyncThunk(
    'profile/getOvertime',
    async (args: GetOvertimeWithFilterReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getOvertime(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const createOvertime = createAsyncThunk(
    'profile/createOvertime',
    async (args: SendOvertimeReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.createOvertime(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const updateOvertime = createAsyncThunk(
    'profile/updateOvertime',
    async (args: SendOvertimeReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.updateOvertime(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const deleteOvertime = createAsyncThunk(
    'profile/deleteOvertime',
    async (arg: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await ProfileApi.deleteOvertime(arg, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getNotificationUser = createAsyncThunk(
    'profile/getNotificationUser',
    async (args: GetNotificationUserReq, {getState, rejectWithValue}) => {
        try {
            const {userAuth} = (getState() as RootState).user;
            return await ProfileApi.getNotificationUser(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

const profileSlice = createSlice({
    name: 'profileState',
    initialState: {} as ProfileSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.attendanceStatus = RequestStatus.pending;
            state.holidayStatus = RequestStatus.pending;
            state.overtimeStatus = RequestStatus.pending;
            state.overtimeDeleteStatus = RequestStatus.pending;
            state.error = {} as HttpClientError;
        }
    },
    extraReducers: (builder) => {
        // get attendance
        builder.addCase(getAttendanceList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAttendanceList.fulfilled, (state, {payload}) => {
            state.attendanceList = payload.data;
            state.attendanceRows = payload.rows;
            state.isLoading = false;
        });
        builder.addCase(getAttendanceList.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // get attendance by user
        builder.addCase(getAttendance.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAttendance.fulfilled, (state, {payload}) => {
            state.attendanceList = payload.data;
            state.attendanceRows = payload.rows;
            state.isLoading = false;
        });
        builder.addCase(getAttendance.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // create attendance
        builder.addCase(createAttendance.pending, (state) => {
            state.isLoading = true;
            state.attendanceStatus = RequestStatus.pending;
        });
        builder.addCase(createAttendance.fulfilled, (state) => {
            state.isLoading = false;
            state.attendanceStatus = RequestStatus.success;
        });
        builder.addCase(createAttendance.rejected, (state, action) => {
            state.isLoading = false;
            state.attendanceStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // update attendance
        builder.addCase(updateAttendance.pending, (state) => {
            state.isLoading = true;
            state.attendanceStatus = RequestStatus.pending;
        });
        builder.addCase(updateAttendance.fulfilled, (state) => {
            state.isLoading = false;
            state.attendanceStatus = RequestStatus.success;
        });
        builder.addCase(updateAttendance.rejected, (state, action) => {
            state.isLoading = false;
            state.attendanceStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // delete attendance
        builder.addCase(deleteAttendance.pending, (state) => {
            state.isLoading = true;
            state.attendanceStatus = RequestStatus.pending;
        });
        builder.addCase(deleteAttendance.fulfilled, (state) => {
            state.isLoading = false;
            state.attendanceStatus = RequestStatus.success;
        });
        builder.addCase(deleteAttendance.rejected, (state, action) => {
            state.isLoading = false;
            state.attendanceStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // get holiday
        builder.addCase(getHolidayList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getHolidayList.fulfilled, (state, {payload}) => {
            state.holidayList = payload.data;
            state.holidayRows = payload.rows;
            state.isLoading = false;
        });
        builder.addCase(getHolidayList.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // get holiday by user
        builder.addCase(getHoliday.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getHoliday.fulfilled, (state, {payload}) => {
            state.holidayList = payload.data;
            state.holidayRows = payload.rows;
            state.isLoading = false;
        });
        builder.addCase(getHoliday.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // get total holiday by user
        builder.addCase(getEmployeeHolidayByUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEmployeeHolidayByUser.fulfilled, (state, {payload}) => {
            state.employeeHolidayList = [
                {
                    data: [
                        {
                            text: 'Total Holiday',
                            value: `${payload.data?.holidayAvailable ?? 0}/${payload.data?.totalHolidaysMax ?? 0}`
                        },
                        {
                            text: 'Holiday Taken',
                            value: `${payload.data?.totalHolidaysTaken ?? 0}`
                        },
                        // {
                        //     text: 'Holiday to be taken',
                        //     value: `${payload.data?.totalHolidaysSoon ?? 0}`
                        // },
                        // {
                        //     text: 'Remaining Holidays',
                        //     value: `${payload.data?.totalHolidaysRemaining ?? 0}`
                        // }
                    ]
                }
            ]
            state.isLoading = false;
        });
        builder.addCase(getEmployeeHolidayByUser.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // create holiday
        builder.addCase(createHoliday.pending, (state) => {
            state.isLoading = true;
            state.holidayStatus = RequestStatus.pending;
        });
        builder.addCase(createHoliday.fulfilled, (state) => {
            state.isLoading = false;
            state.holidayStatus = RequestStatus.success;
        });
        builder.addCase(createHoliday.rejected, (state, action) => {
            state.isLoading = false;
            state.holidayStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // update holiday
        builder.addCase(updateHoliday.pending, (state) => {
            state.isLoading = true;
            state.holidayStatus = RequestStatus.pending;
        });
        builder.addCase(updateHoliday.fulfilled, (state) => {
            state.isLoading = false;
            state.holidayStatus = RequestStatus.success;
        });
        builder.addCase(updateHoliday.rejected, (state, action) => {
            state.isLoading = false;
            state.holidayStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // delete holiday
        builder.addCase(deleteHoliday.pending, (state) => {
            state.isLoading = true;
            state.holidayStatus = RequestStatus.pending;
        });
        builder.addCase(deleteHoliday.fulfilled, (state) => {
            state.isLoading = false;
            state.holidayStatus = RequestStatus.success;
        });
        builder.addCase(deleteHoliday.rejected, (state, action) => {
            state.isLoading = false;
            state.holidayStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // get overtime
        builder.addCase(getOvertimeList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOvertimeList.fulfilled, (state, {payload}) => {
            state.overtimeList = payload.data;
            state.overtimeRows = payload.rows;
            state.isLoading = false;
        });
        builder.addCase(getOvertimeList.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // get overtime by user
        builder.addCase(getOvertime.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOvertime.fulfilled, (state, {payload}) => {
            state.overtimeList = payload.data;
            state.overtimeRows = payload.rows;
            state.isLoading = false;
        });
        builder.addCase(getOvertime.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
        // create overtime
        builder.addCase(createOvertime.pending, (state) => {
            state.isLoading = true;
            state.overtimeStatus = RequestStatus.pending;
        });
        builder.addCase(createOvertime.fulfilled, (state) => {
            state.isLoading = false;
            state.overtimeStatus = RequestStatus.success;
        });
        builder.addCase(createOvertime.rejected, (state, action) => {
            state.isLoading = false;
            state.overtimeStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // update overtime
        builder.addCase(updateOvertime.pending, (state) => {
            state.isLoading = true;
            state.overtimeStatus = RequestStatus.pending;
        });
        builder.addCase(updateOvertime.fulfilled, (state) => {
            state.isLoading = false;
            state.overtimeStatus = RequestStatus.success;
        });
        builder.addCase(updateOvertime.rejected, (state, action) => {
            state.isLoading = false;
            state.overtimeStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // delete overtime
        builder.addCase(deleteOvertime.pending, (state) => {
            state.isLoading = true;
            state.overtimeDeleteStatus = RequestStatus.pending;
        });
        builder.addCase(deleteOvertime.fulfilled, (state) => {
            state.isLoading = false;
            state.overtimeDeleteStatus = RequestStatus.success;
        });
        builder.addCase(deleteOvertime.rejected, (state, action) => {
            state.isLoading = false;
            state.overtimeDeleteStatus = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // get notification user
        builder.addCase(getNotificationUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getNotificationUser.fulfilled, (state, {payload}) => {
            state.notificationUserList = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getNotificationUser.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as HttpClientError;
        });
    }
});

export const { reset } = profileSlice.actions;
export default profileSlice.reducer;