import React, {KeyboardEventHandler, useState} from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
    DropdownIndicator: null,
};

const CreatableSelectInput = ({value = [], label = '', placeholder = '', ...props}: CreatableSelectInputProps) => {
    const [inputValue, setInputValue] = useState<string>("");

    const handleChange = (
        val: any
    ) => {
        const _value = val.map((item: { value: string }) =>
            item.value
        );
        props.getValue(_value);
    };

    const handleInputChange = (inputValue: string) => {
        setInputValue(inputValue);
    };

    const handleKeyDown: KeyboardEventHandler<any> = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Go':
            case 'Tab':
                setInputValue('');
                let _value = value.map((item) => item.value);
                props.getValue([..._value, inputValue]);
                event.preventDefault();
        }
    };

    const handleOnBlur = () => {
        if (!inputValue) return;
        setInputValue('');
        let _value = value.map((item) => item.value);
        props.getValue([..._value, inputValue]);
    };

    return (
        <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
            {
                label && <label htmlFor={label} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{label}</label>
            }
            <CreatableSelect
                id={label}
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleChange}
                onBlur={handleOnBlur}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                value={value}
            />
        </div>
    )
}

export type ValueOption = {
    label: string;
    value: string;
}

export interface CreatableSelectInputProps {
    label?: string;
    placeholder: string;
    value: ValueOption[];
    getValue: (value: Array<string>) => void;
}

export default CreatableSelectInput;