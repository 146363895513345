import {Option} from "../../../components/SelectInput/BasicSelectInput";
import {GlobalApprovalEnum} from "../../../types";
import {MultiValue} from "react-select";
import {EmployeeWorkSortEnum, SortingEnum} from "../../approval/models";

export type StaffReportModel = {
    id: string | null;
    number_job: string;
    role_id: string;
    note: string;
    reqMinute: number;
    reqPole: number;
    approvalMinute: number;
    approvalPole: number;
    rejectionNote: string;
    status: number;
    type: number;
    requestDate: string;
    userId?: string;
    name?: string;
    statusName?: string;
};

export type StaffReportMainModel = {
    id: string | null;
    number_job: string;
    role_id: string;
    note: string;
    reqMinute: number;
    reqPole: number;
    approvalMinute: number;
    approvalPole: number;
    rejectionNote: string;
    status: number;
    type: number;
    requestDate: string;
};

export type GetStaffReq = {
    skip: number;
    take: number;
    userId?: string;
    isReport?: boolean;
    startDate?: string;
    endDate?: string;
    filter?: string;
    type?: StaffReportTypeEnum;
    status?: StaffReportFilterStatusEnum;
};

export type GetStaffApprovalReq = {
    skip: number;
    take: number;
    userId?: string;
    isReport?: boolean;
    startDate?: string;
    endDate?: string;
    filterByName?: string;
    filterByNIP?: string;
    filterByTeam?: string;
    filterByNotes?: string;
    filterByJobNumber?: string;
    filterByPosition?: string;
    status?: GlobalApprovalEnum;
    column?: EmployeeWorkSortEnum;
    sortType?: SortingEnum;
};

export type GetStaffMainReq = {
    userId?: string;
    startDate?: string;
    endDate?: string;
}

export type SendStaffReq = {
    id?: string | null;
    number_job: string;
    role_id: string;
    note: string;
    reqMinute: number;
    reqPole: number;
    approvalMinute: number;
    approvalPole: number | string;
    rejectionNote: string;
    status: number;
    type: number;
    requestDate: string;
    userId?: string;
    selectedNumberJob?: Option[];
};

export type EmployeeWorkInput = {
    requestDate: string;
    type: string;
    job_number: MultiValue<Option>;
    note: string;
    reqMinute: string;
    reqPole: string;
}

export type GetEmployeeWorkReq = {
    skip?: number;
    take?: number;
    team?: string;
    name?: string;
    startDate?: string;
    endDate?: string;
};

export type EmployeeWorkModel = {
    projectName: string;
    teamName: string;
    positionName: string;
    name: string;
    totalJobComplete: number;
    processJob: number;
    totalPole: number;
    addPole: number;
    revisionPole: number;
    sumPole: number;
    mre: number;
    katapult: number;
};

export type InputFilter = {
    startDate: string;
    endDate: string;
}

export enum StaffReportFilterStatusEnum
{
    Requested, ApprovedByHead, ManagerApproval, Rejected
}
export enum StaffReportTypeEnum
{
    Add, Update, Revision, Remove
}

export type EmployeeWorkFilterModel = {
    name: string;
    teamName: string;
    detailRole: DetailRole[];
}

export type DetailRole = {
    projectName: string | null;
    teamName: string;
    positionName: string;
    name: string;
    totalJobComplete: number;
    processJob: number;
    totalPole: number;
    addPole: number;
    revisionPole: number;
    sumPole: number;
    mre: number;
    katapult: number;
    details: Detail[];
}

export type Detail = {
    project: string;
    koj: string;
    number_id: string;
    number_job: string;
    job_type_name: string;
    district: string;
    poles: number;
    complete_date: string;
}

export enum StaffReportApprovalColumnSort
{
    requestDate,
    typeName,
    number_job,
    name,
    nip,
    teamName,
    role_name,
    statusName
}

export type GetStaffReportWithFilterReq = {
    skip: number;
    take: number;
    userId: string;
    isReport?: boolean;
    startDate?: string;
    endDate?: string;
    filterByName?: string;
    filterByNIP?: string;
    filterByTeam?: string;
    filterByNotes?: string;
    filterByJobNumber?: string;
    filterByPosition?: string;
    status?: StaffReportFilterStatusEnum;
    column?: StaffReportApprovalColumnSort;
    sortType?: SortingEnum;
}

export type GetExportEmployeeWorkReq = {
    startDate?: string;
    endDate?: string;
    team?: string;
    name?: string;
    skip?: number;
    take?: number;
}