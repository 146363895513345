import React from 'react';
import SupportingDocumentReportTable from "../components/SupportingDocumentReportTable";

const SupportingDocumentReportPage = () => {
    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Supporting
                        Document Report</h1>
                </div>
                <div>
                </div>
                <div className="mt-3">
                    <SupportingDocumentReportTable/>
                </div>
            </div>
        </div>
    );
};

export default SupportingDocumentReportPage;
