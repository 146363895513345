import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';

import DistrictForm from '../components/DistrictForm';
import { InputModel, District } from '../models';
import { HttpClientError, RequestStatus } from 'http-client';
import {
    createDistrict, getList,
    reset,
    updateDistrict,
} from '../district.reducer';
import {getList as getProjectList} from "../../project/project.reducer";
import {savedStatusOption} from "../../../../helpers/alertLabel";

const DistrictDetailPage = ({
    isEdit = false, ...props
}: DistrictDetailPageProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const isLoading = useAppSelector(
        (state: RootState) => state.district.isLoading ?? false
    );
    const singleState = useAppSelector(
        (state: RootState) => state.district.single ?? ({} as District)
    );
    const status = useAppSelector((state: RootState) => state.district.status);
    const error = useAppSelector(
        (state: RootState) => state.district.error ?? ({} as HttpClientError)
    );

    const onSubmit = (formInput: InputModel) => {
        if (isEdit) {
            let _formInput = { ...formInput, id: singleState.id };
            dispatch(updateDistrict(_formInput));
        } else {
            dispatch(createDistrict(formInput));
        }
        dispatch(reset());
    };

    useEffect(() => {
        dispatch(getProjectList({skip: 0, take: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(savedStatusOption(status === RequestStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                dispatch(getList({ skip: 0, take: 1000 }));
                props.toggleModal();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    return (
        <div className="p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <DistrictForm
                isLoading={isLoading}
                isEdit={isEdit}
                onSubmit={onSubmit}
            />
        </div>
    );
};

export interface DistrictDetailPageProps {
    isEdit: boolean;
    toggleModal: () => void;
}

export default DistrictDetailPage;
