import React from 'react';
import UnauthorizedImage from 'assets/images/unauthorized.svg';

const UnauthorizedPage = () => {
    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="mt-3 flex justify-center items-center">
                    <img src={UnauthorizedImage} alt={'unauthorized'} width={500}/>
                </div>
            </div>
        </div>
    );
};

export default UnauthorizedPage;
