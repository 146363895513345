import React, {useEffect} from 'react';
import JobInTable from "../components/JobInTable";
import JobInChart from "../components/JobInChart";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import {useForm} from "react-hook-form";
import {InputFilter} from "../../../staffReport/models";
import {useAppDispatch} from "../../../../../helpers/redux";
import {getJobIn, reset} from "../../jobReport.reducer";
import Button from "../../../../../components/Button/Button";
const JobInPage = () => {
    const {
        control,
        handleSubmit,
        watch,
    } = useForm<InputFilter>({defaultValues: {startDate: new Date().toISOString(), endDate: new Date().toISOString()}});
    const dispatch = useAppDispatch();

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    useEffect(() => {
        dispatch(
            getJobIn({
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
            })
        );
    }, []);

    const onSubmit = (data: InputFilter) => {
        let localStart = new Date(data.startDate).toISOString();
        let localEnd = new Date(data.endDate).toISOString();
        dispatch(
            getJobIn({
                startDate: localStart,
                endDate: localEnd,
            })
        );
    }

    const onResetClicked = () => {
        reset();
        dispatch(
            getJobIn({
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
            })
        );
    }


    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Job In</h1>
                </div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <RangeDatePicker startName='startDate' endName='endDate' startValue={new Date(startDate)}
                                         endValue={new Date(endDate)} control={control}/>
                        <div className="flex items-center justify-end">
                            <div className={"mx-2"}>
                                <Button
                                    color="success"
                                    type="button"
                                    title="Reset"
                                    onClick={onResetClicked}
                                />
                            </div>
                            <Button
                                color="success"
                                type="submit"
                                title="Filter"
                            />
                        </div>
                    </form>
                </div>
                <div className="mt-3">
                    <JobInChart/>
                    <JobInTable startDate={startDate} endDate={endDate}/>
                </div>
            </div>
        </div>
    );
};

export default JobInPage;
