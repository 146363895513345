import {BaseServiceClass} from "../../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../../http-client";
import {
    GetMainPrepListRequest,
    GetPrepListRequest, GetPrepMainRequest, GetPrepRequest,
    JobPrep,
    JobPrepGetRequest,
    JobPrepSummary,
    MainJobPrep
} from "./models";
import {CalcSpecSummary} from "../calcSpec/models";

export default class ProcessJobPrepApi extends BaseServiceClass {
    static getJobPrep(args: GetPrepListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<JobPrep[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_prep}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getJobPrepWithFilter(args: GetPrepRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<JobPrep[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_prep_with_filter}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static getSingleJobPrep(args: GetPrepListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<JobPrep>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_prep}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: args.id,
                skip: 0,
                take: 1
            }
        }).then((res) => res.data);
    }
    static getMainJobPrep(args: GetMainPrepListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<MainJobPrep[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_prep_main}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }
    static getMainJobPrepWithFilter(args: GetPrepMainRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<MainJobPrep[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_prep_main_with_filter}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
    static getJobPrepSummary(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<JobPrepSummary[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_prep_summary}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
    static jobPrepGetAction(args: JobPrepGetRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_prep_task}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data.data);
    }
}