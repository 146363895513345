export const isObjectEmpty = (object: Object) => {
    return object && Object.keys(object).length === 0 && object.constructor === Object;
};

export const isArrayEmpty = (array: Array<any>) => {
    return array.length === 0;
};

export function isBlank(str: string) {
    return !str || /^\s*$/.test(str);
}

export const compareDates = (d1: string, d2: string) => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (date1 < date2) {
        return false
    } else if (date1 > date2) {
        return false
    } else {
        return true
    }
};

export function isMalformedUTF8(str: string) {
    try {
        new TextDecoder("utf-8").decode(new TextEncoder().encode(str));
        return false;
    } catch (error) {
        return true;
    }
}
