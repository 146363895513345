import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import InputText from 'components/TextInput/InputText';
import Button from 'components/Button/Button';
import {employeeHolidaySchema} from '../constants';
import {useAppSelector} from 'helpers/redux';
import {RootState} from 'app/store';
import {isObjectEmpty} from 'helpers/checkEmptiness';
import {EmployeeHoliday, EmployeeHolidayReq} from "../models";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import {Option} from "../../../../components/SelectInput/BasicSelectInput";
import {SingleValue} from "react-select";
import {UserCredential} from "../../../login/models";

const EmployeeHolidayForm = ({
                                 isLoading = false,
                                 isEdit = false,
                                 ...props
                             }: EmployeeHolidayFormProps) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
        watch,
        setValue
    } = useForm<EmployeeHolidayReq>({
        resolver: yupResolver(employeeHolidaySchema),
    });

    const userIdWatcher = watch('userId');
    const userNameWatcher = watch('name');

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const singleState = useAppSelector(
        (state: RootState) => state.holiday.singleEmployeeHoliday ?? ({} as EmployeeHoliday)
    );
    const userListOptions = useAppSelector((state: RootState) => state.employee.options ?? []);

    console.log(user.roleName.some((item) => item === "Administrator"));

    useEffect(() => {
        if (isEdit && !isObjectEmpty(singleState)) {
            reset(singleState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleState]);

    const onSubmit = (data: EmployeeHolidayReq) => props.onSubmit(data);

    const onUserChange = (val: SingleValue<Option>) => {
        setValue('userId', val?.value ?? '');
        setValue('name', val?.text ?? '');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SelectInput
                key={`my_unique_select_key__user`}
                options={userListOptions}
                isLoading={isLoading}
                value={{text: userNameWatcher, label: userNameWatcher, value: userIdWatcher} as Option}
                label="Employee"
                onChange={onUserChange}
                message={errors.userId?.message}
            />
            <InputText
                {...register('holidayAvailable')}
                label="Holiday Available"
                placeholder="Input Holiday Available"
                message={errors.holidayAvailable?.message}
                type={'number'}
                min={0}
            />
            <InputText
                {...register('totalHolidaysMax')}
                label="Holiday Max"
                placeholder="Input Holiday Max"
                message={errors.totalHolidaysMax?.message}
                type={'number'}
                min={0}
            />

            {
                (user.roleName.some((item) => item === "Administrator" || item === "Superadmin") && (
                    <>
                        <InputText
                            {...register('totalHolidaysTaken')}
                            label="Holiday Taken"
                            placeholder="Input Holiday Taken"
                            message={errors.totalHolidaysTaken?.message}
                            type={'number'}
                            min={0}
                        />

                        <InputText
                            {...register('totalHolidaysSoon')}
                            label="Holiday to be taken"
                            placeholder="Input Holiday to be taken"
                            message={errors.totalHolidaysSoon?.message}
                            type={'number'}
                            min={0}
                        />

                        <InputText
                            {...register('totalHolidaysRemaining')}
                            label="Remaining Holidays"
                            placeholder="Input Remaining Holidays"
                            message={errors.totalHolidaysRemaining?.message}
                            type={'number'}
                            min={0}
                        />
                    </>
                ))
            }

            <div className="pt-2 pb-2">
                <Button
                    color="success"
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                />
            </div>
        </form>
    );
};

export interface EmployeeHolidayFormProps {
    isLoading: boolean;
    isEdit: boolean;
    onSubmit: (formInput: EmployeeHolidayReq) => void;
}

export default EmployeeHolidayForm;
