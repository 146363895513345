import {BaseServiceClass} from "base-service-class";
import httpClient, {ApiResponseWrapper} from "http-client";
import {CreateJobCategory, GetListRequest, JobCategory, UpdateJobCategory} from "./models";

export default class JobCategoryApi extends BaseServiceClass {
    static getList(args: GetListRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<JobCategory[]>>(`${this.BASE_URL}${this.PATH_MASTER.jobCategory}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                skip: args.skip,
                take: args.take
            }
        }).then((res) => res.data);
    }

    static getSingle(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<JobCategory>>(`${this.BASE_URL}${this.PATH_MASTER.jobCategory}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id,
            }
        }).then((res) => res.data);
    }

    static createJobCategory(args: CreateJobCategory, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobCategory}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static updateJobCategory(args: UpdateJobCategory, token: string) {
        return httpClient.put<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobCategory}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static deleteJobCategory(id: string, token: string) {
        return httpClient.delete<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_MASTER.jobCategory}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }
}