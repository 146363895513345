import {Navigate, useLocation} from "react-router-dom";
import {useAppDispatch} from "../../../helpers/redux";
import {clearSession} from "../../login/login.reducer";
import {LAST_ATTENDANCE} from "../../../constants";

const Logout = () => {
    let location = useLocation();
    localStorage.removeItem('__qmuser');
    localStorage.removeItem(LAST_ATTENDANCE);
    const dispatch = useAppDispatch();
    dispatch(clearSession());
    return <Navigate to="/login" state={{from: location}} replace/>;
};

export default Logout;