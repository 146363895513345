export class BaseServiceClass {
    // production
    static BASE_URL = "https://api.kpaindonesia.com";

    // development
    // static BASE_URL = "https://api-qms-dev.ultimosolution.com";

    static BASE_UPLOAD_URL = "https://api-kpa.ultimosolution.com";
    static MEDIA_URL = "https://media.kpaindonesia.com";
    static NEW_MEDIA_URL = "https://media-demo.1tugas.com";
    static PATH_MASTER = {
        department: "/api/Department",
        district: "/api/District",
        jobCategory: "/api/JobCategory",
        jobStatus: "/api/JobStatus",
        jobSubjectNote: "/api/JobSubjectNote",
        jobType: "/api/JobType",
        job_source: "/api/Source",
        kindOfJob: "/api/KindOfJob",
        position: "/api/Position",
        project: "/api/Project",
        pole_status: "/api/PoleStatus",
        pole_type: "/api/PoleType",
        team: "/api/Team",
        employee: "/api/User",
        employee_get_by_position: "/api/User/GetByPosition",
        supporting_document: "/api/SupportingDocument",
        department_owner: "/api/DepartmentOwner",
        holiday_calendar: "/api/HolidayCalendar",
        employee_holiday: "/api/EmployeeHoliday",
    };
    static PATH_WORKSHEET = {
        job: "/api/Job",
        job_import: `${this.BASE_URL}/api/Job/Import`,
        job_get: "/api/Job/Get",
        job_get_all_job: "/api/Job/ListAllHistoryNonCompleteData",
        job_get_new: "/api/Job/GetNew",
        job_get_dropdown: `${this.BASE_URL}/api/Job/GetByJobNumberDropdown`,
        job_get_waiting: `${this.BASE_URL}/api/Job/GetWaiting`,
        job_get_hold: `${this.BASE_URL}/api/Job/GetJobHold`,
        job_get_cancel: `${this.BASE_URL}/api/Job/GetJobCancel`,
        job_get_prep: "/api/Job/GetPrep",
        job_get_prep_with_filter: "/api/Job/GetPrepWithFilter",
        job_get_prep_summary: "/api/Job/GetPrepSummary",
        job_get_prep_main: "/api/Job/GetPrepMain",
        job_get_prep_main_with_filter: "/api/Job/GetPrepMainWithFilter",
        job_get_calc_spec: "/api/Job/GetCalcSpec",
        job_get_calc_spec_with_filter: "/api/Job/GetCalcSpecWithFilter",
        job_get_calc_spec_summary: "/api/Job/GetCalcSpecSummary",
        job_get_calc_spec_main: "/api/Job/GetCalcSpecMain",
        job_get_calc_spec_main_with_filter: "/api/Job/GetCalcSpecMainWithFilter",
        job_get_qc: `${this.BASE_URL}/api/Job/GetQC`,
        job_get_qc_with_filter: `${this.BASE_URL}/api/Job/GetQCWithFilter`,
        job_get_qc_summary: `${this.BASE_URL}/api/Job/GetQCSummary`,
        job_get_qc_main: `${this.BASE_URL}/api/Job/GetQCMain`,
        job_get_qc_main_with_filter: `${this.BASE_URL}/api/Job/GetQCMainWithFilter`,
        job_new_check: "/api/Job/JobNewTaskCheck",
        job_new_cancel: "/api/Job/JobNewTaskCancel",
        job_prep_task: "/api/Job/JobPrepTask",
        job_calc_spec_assign: "/api/Job/JobCalcSpecAssign",
        job_qc_task: `${this.BASE_URL}/api/Job/JobQCTask`,
        job_waiting: "/api/Job/TaskWaiting",
        job_change_status: `${this.BASE_URL}/api/Job/ChangeStatus`,
        job_change_complete_date: `${this.BASE_URL}/api/Job/JobCompleteDate`,
        job_pending: `${this.BASE_URL}/api/Job/JobPending`,
        job_file_new: "/api/JobFileNew",
        job_file_prep: "/api/JobFilePrep",
        job_file_calc_spec: `${this.BASE_URL}/api/JobFileCalcSpec`,
        job_file_qc: `${this.BASE_URL}/api/JobFileQC`,
        job_update_cs_notes: `${this.BASE_URL}/api/Job/JobUpdateCSNotes`,
        job_update_qc_notes: `${this.BASE_URL}/api/Job/JobUpdateQCNotes`,
        job_attachment: "/api/Job/Attachment",
        job_attachment_single_list: "/api/Job/GetAttachmentSingle",
        job_attachment_get_type: "/api/JobAttachment/GetType",
        job_attachment_get_datasheet_na: "/api/JobAttachment/GetNotAvailableDatasheet",
        job_attachment_get_datasheet_na_with_filter: "/api/JobAttachment/GetNotAvailableDatasheetNew",
        job_attachment_get_working_na: "/api/JobAttachment/GetNotAvailableWorking",
        job_attachment_get_working_na_with_filter: "/api/JobAttachment/GetNotAvailableWorkingNew",
        job_attachment_get_power_map_na: "/api/JobAttachment/GetNotAvailablePowerMap",
        job_attachment_get_power_map_na_with_filter: "/api/JobAttachment/GetNotAvailablePowerMapNew",
        job_attachment_get_fielding_na: "/api/JobAttachment/GetNotAvailableFielding",
        job_attachment_get_aramis_na: "/api/JobAttachment/GetNotAvailableAramis",
        job_attachment_get_job_link: "/api/JobAttachment/GetByJob",
        job_attachment_get_main_map_req: "/api/Job/AttachmentGetMain",
        job_attachment_map_req_get: `${this.BASE_URL}/api/Job/AttachmentGet`,
        job_attachment_full: "/api/Job/AttachmentFull",
        job_ready_to_assign: "/api/Job/PrepReadyToAssign",
        job_qc_ready_to_assign: `${this.BASE_URL}/api/Job/QCReadyToAssign`,
        job_complete: `${this.BASE_URL}/api/Job/CompleteJob`,
        job_get_mre: `${this.BASE_URL}/api/Job/GetMRE`,
        job_get_mre_with_filter: `${this.BASE_URL}/api/Job/GetMREWithFilter`,
        job_get_mre_summary: `${this.BASE_URL}/api/Job/GetMRESummary`,
        job_get_qc_mre_summary: `${this.BASE_URL}/api/Job/GetMREQCSummary`,
        job_get_mre_main: `${this.BASE_URL}/api/Job/GetMREMain`,
        job_get_mre_main_with_filter: `${this.BASE_URL}/api/Job/GetMREMainWithFilter`,
        job_mre_assign: `${this.BASE_URL}/api/Job/JobMREAssign`,
        job_qc_mre_assign: `${this.BASE_URL}/api/Job/JobMREQCAssign`,
        job_file_mre: `${this.BASE_URL}/api/JobFileMRE`,
        job_update_mre_notes: `${this.BASE_URL}/api/Job/JobUpdateMRENotes`,
        job_update_qc_mre_notes: `${this.BASE_URL}/api/Job/JobUpdateMREQCNotes`,
        job_file_qc_mre_get: `${this.BASE_URL}/api/JobFileMRE/GetQC`,
        job_file_qc_mre_post: `${this.BASE_URL}/api/JobFileMRE/PostQC`,
        job_file_qc_mre_delete: `${this.BASE_URL}/api/JobFileMRE/DeleteQC`,
        job_mre_ready_to_qc_mre: `${this.BASE_URL}/api/Job/ChangeStatusReadyToQCMRE`,
        job_mre_waiting: `${this.BASE_URL}/api/Job/TaskWaitingMRE`,
        job_mre_cancel: `${this.BASE_URL}/api/Job/JobMRECancel`,
        job_mre_complete: `${this.BASE_URL}/api/Job/CompleteMREJob`,
        job_get_katapult: `${this.BASE_URL}/api/Job/GetKatapult`,
        job_get_katapult_with_filter: `${this.BASE_URL}/api/Job/GetKatapultWithFilter`,
        job_get_katapult_summary: `${this.BASE_URL}/api/Job/GetKatapultSummary`,
        job_get_katapult_main: `${this.BASE_URL}/api/Job/GetKatapultMain`,
        job_get_katapult_main_with_filter: `${this.BASE_URL}/api/Job/GetKatapultMainWithFilter`,
        job_katapult_assign: `${this.BASE_URL}/api/Job/JobKatapultAssign`,
        job_katapult_waiting: `${this.BASE_URL}/api/Job/TaskWaitingKatapult`,
        job_katapult_cancel: `${this.BASE_URL}/api/Job/JobKatapultCancel`,
        job_katapult_complete: `${this.BASE_URL}/api/Job/CompleteKatapultJob`,
        job_file_katapult: `${this.BASE_URL}/api/JobFileKatapult`,
        job_update_katapult_notes: `${this.BASE_URL}/api/Job/JobUpdateKatapultNotes`,
        job_get_by_job_number: `${this.BASE_URL}/api/Job/GetByJobNumber`,
        get_gdev_data: `${this.BASE_URL}/api/Job/GetGDevData`,
        billed_gdev_data: `${this.BASE_URL}/api/Job/BilledJobGDev`,
        approve_gdev: `${this.BASE_URL}/api/Job/ApproveGDevJob`,
    };
    static PATH_POLE = {
        pole: "/api/Pole",
        pole_get_by_job: "/api/Pole/GetByJob",
        pole_katapult_by_job: `${this.BASE_URL}/api/Pole/KatapultGetByJob`,
        pole_mre_by_job: `${this.BASE_URL}/api/Pole/MREGetByJob`,
        pole_get_mre_detail: `${this.BASE_URL}/api/Pole/MREDetailGetByJob`,
        pole_multiple_edit_status: "/api/Pole/MultipleEdit",
        pole_multiple_delete: "/api/Pole/MultipleDelete",
        pole_by_radius: "/api/Pole/GetByRadius",
        pole_remove_assign: `${this.BASE_URL}/api/Pole/RemoveAssignment`,
        pole_remove_multiple_assign: `${this.BASE_URL}/api/Pole/MultiRemoveAssignment`,
    };
    static PATH_UPLOAD = {
        upload_file: "/api/Upload/UploadFile",
        new_upload_file: "/api/Upload/UploadFile",
    }
    static PATH_EMAIL = {
        email: "/api/JobEmail",
        email_get_last_seq: "/api/JobEmail/GetLastSequence",
        email_get_by_job: "/api/JobEmail/GetByJob",
    };
    static PATH_DASHBOARD = {
        ocalc_header: `${this.BASE_URL}/api/Job/GetDashboardHeaderOCalcData`,
        mre_header: `${this.BASE_URL}/api/Job/GetDashboardHeaderMREData`,
        katapult_header: `${this.BASE_URL}/api/Job/GetDashboardHeaderKatapultData`,
        ocalc_chart: `${this.BASE_URL}/api/Pole/GetDashboardOCalcChart`,
        mre_chart: `${this.BASE_URL}/api/Pole/GetDashboardMREChart`,
        katapult_chart: `${this.BASE_URL}/api/Pole/GetDashboardKatapultChart`,
        ocalc_dashboard: `${this.BASE_URL}/api/Job/GetDashboardOCalcData`,
    };
    static PATH_REPORT = {
        history: `${this.BASE_URL}/api/Job/GetJobHistoryData`,
        staff_report: `${this.BASE_URL}/api/StaffReport`,
        get_staff_report_with_filter: `${this.BASE_URL}/api/StaffReport/GetStaffReport`,
        get_staff_report_approval: `${this.BASE_URL}/api/StaffReport/GetStaffReportApproval`,
        staff_report_main: `${this.BASE_URL}/api/StaffReport/GetStaffReportMain`,
        get_employee_work: `${this.BASE_URL}/api/Job/GetEmployeeWork`,
        get_employee_work_with_filter: `${this.BASE_URL}/api/Job/GetEmployeeWorkWithFilter`,
        get_employee_work_with_filter_new: `${this.BASE_URL}/api/Job/GetEmployeeWorkWithFilterNew`,
        get_job_statistic: `${this.BASE_URL}/api/Job/GetJobStatistic`,
        get_job_in: `${this.BASE_URL}/api/Job/GetJobIn`,
        get_overtime_report: `${this.BASE_URL}/api/Overtime/GetReport`,
        get_holiday_report: `${this.BASE_URL}/api/Holiday/GetReport`,
        get_supporting_document_report: `${this.BASE_URL}/api/JobAttachment/GetReportSupportingDocument`,
        get_supporting_document_log: `${this.BASE_URL}/api/JobAttachment/GetReportSupportingDocumentLog`,
        get_attendance_report: `${this.BASE_URL}/api/Attendance/GetAttendanceReportLog`,
        change_holiday_request: `${this.BASE_URL}/api/Holiday/ChangeToRequest`,
        get_attendance_detail_report: `${this.BASE_URL}/api/Attendance/GetAttendanceReportLogDetail`,
        attendance_export: `${this.BASE_URL}/api/Attendance/ExportAttendanceLogDataToExcel`,
    }
    static PATH_APPROVAL = {
        change_staff_report_status: `${this.BASE_URL}/api/StaffReport/ChangeStatus`,
        change_overtime_status: `${this.BASE_URL}/api/Overtime/ChangeStatus`,
        change_attendance_status: `${this.BASE_URL}/api/Attendance/ChangeStatus`,
        change_holiday_status: `${this.BASE_URL}/api/Holiday/ChangeStatus`,
        get_overtime_with_filter: `${this.BASE_URL}/api/Overtime/GetListApproval`,
        get_attendance_with_filter: `${this.BASE_URL}/api/Attendance/GetListApproval`,
        get_holiday_with_filter: `${this.BASE_URL}/api/Holiday/GetListApproval`,
    }
    static PATH_PROFILE = {
        attendance: `${this.BASE_URL}/api/Attendance`,
        get_attendance_approval: `${this.BASE_URL}/api/Attendance/GetListApproval`,
        get_attendance_user: `${this.BASE_URL}/api/Attendance/GetByUser`,
        holiday: `${this.BASE_URL}/api/Holiday`,
        get_holiday_approval: `${this.BASE_URL}/api/Holiday/GetListApproval`,
        overtime: `${this.BASE_URL}/api/Overtime`,
        get_overtime_approval: `${this.BASE_URL}/api/Overtime/GetListApproval`,
        get_holiday_user: `${this.BASE_URL}/api/Holiday/GetByUser`,
        get_employee_holiday_by_user: `${this.BASE_URL}/api/EmployeeHoliday/GetByUser`,
        get_overtime_user: `${this.BASE_URL}/api/Overtime/GetByUser`,
        get_notification_user: `${this.BASE_URL}/api/Notification/GetByUser`,
    }
    static PATH_BILL_JOB = {
        get_job_data: `${this.BASE_URL}/api/BillJob/GetJobData`,
        get_bill_job_detail: `${this.BASE_URL}/api/Job/GetBillJobDetail`,
        get_status_by_type: `${this.BASE_URL}/api/BillJob/GetStatusByType`,
        get_bill_by_job: `${this.BASE_URL}/api/BillJob/GetBillByJob`,
        get_bill_by_job_number: `${this.BASE_URL}/api/BillJob/GetBillByJobNumber`,
        bill_job: `${this.BASE_URL}/api/BillJob`,
        bill_job_with_filter: `${this.BASE_URL}/api/BillJob/GetWithFilter`,
        get_weekend_date: `${this.BASE_URL}/api/BillJob/GetWeekendDate`,
    };
}
