import React from 'react';
import BillJobForm from "../components/BillJobForm";
import BillJobTable from "../components/BillJobTable";

const BillJobPage = ({isEdit}: BillJobPageProps) => {
    return (
        <div>
            <div className="p-2 md:p-10 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
                <div className="flex justify-items-center">
                    <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">Bill Job</h1>
                </div>
                <div className="mt-3">
                    <BillJobForm isEdit={isEdit} />
                    <BillJobTable />
                </div>
            </div>
        </div>

    );
};

export interface BillJobPageProps {
    isEdit: boolean;
}

export default BillJobPage;
