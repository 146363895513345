import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import InputText from 'components/TextInput/InputText';
import {HolidayCalendar, HolidayCalendarReq} from '../models';
import Button from 'components/Button/Button';
import {holidayCalendarTypeOptions, holidayCalendarSchema} from '../constants';
import { useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { isObjectEmpty } from 'helpers/checkEmptiness';
import BasicDatePicker from "../../../../components/DatePicker/BasicDatePicker";
import BasicSelectInput from "../../../../components/SelectInput/BasicSelectInput";

const HolidayCalendarForm = ({
    isLoading = false,
    isEdit = false,
    ...props
}: HolidayCalendarFormProps) => {
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<HolidayCalendarReq>({
        resolver: yupResolver(holidayCalendarSchema),
        defaultValues: {
            date: new Date().toISOString(),
        }
    });

    const singleState = useAppSelector(
        (state: RootState) => state.holiday.singleHolidayCalendar ?? ({} as HolidayCalendar)
    );

    useEffect(() => {
        if (isEdit && !isObjectEmpty(singleState)) {
            reset(singleState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, singleState]);

    const onSubmit = (data: HolidayCalendarReq) => props.onSubmit(data);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputText
                {...register('name')}
                label="Holiday Name"
                placeholder="Input Holiday name"
                message={errors.name?.message}
            />
            <BasicDatePicker label="Date" name="date" placeholderText="Select Date Entry" control={control} asISOString />
            <BasicSelectInput
                label="Holiday Calendar Type"
                message={errors.type?.message}
                options={holidayCalendarTypeOptions}
                {...register('type')}
            />
            <div className="pt-2 pb-2">
                <Button
                    color="success"
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                />
            </div>
        </form>
    );
};

export interface HolidayCalendarFormProps {
    isLoading: boolean;
    isEdit: boolean;
    onSubmit: (formInput: HolidayCalendarReq) => void;
}

export default HolidayCalendarForm;
