import React from 'react';
import {Link} from "react-router-dom";
import Button from "../Button/Button";

const BasicHeaderLayout = ({pageTitle = '', path = '', buttonTitle = ''}: BasicHeaderLayoutProps) => {
    return (
        <div className="flex justify-items-center">
            <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">{pageTitle}</h1>
            <div className="">
                <Link
                    to={{
                        pathname: path,
                    }}
                >
                    <Button title={buttonTitle} />
                </Link>
            </div>
        </div>
    );
};

export interface BasicHeaderLayoutProps {
    pageTitle?: string;
    path: string;
    buttonTitle?: string;
}

export default BasicHeaderLayout;
