import {ComponentPropsWithRef, FC, forwardRef} from "react";

type TextProps = {
    label?: string;
    value?: string;
    suppressBorder?: boolean;
}

type TextType = FC<TextProps & ComponentPropsWithRef<'p'>>;

const Text: TextType = forwardRef((props, ref) => {
    const {
        label = "",
        value = "",
        suppressBorder = false,
        ...rest} = props;

    return <div className="col-span-6 sm:col-span-3 pt-2 pb-2">
        {
            label && <span className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{label}</span>
        }
        <p
            ref={ref}
            {...rest}
            className={`block ${!suppressBorder && "border-b-[1px]"} pb-2 border-gray-300 w-full mt-2 text-gray-900 dark:text-white font-normal`}
        >{value}</p>
    </div>
});

export default Text;