import React from 'react';
import DataTableServerPaging from "../../../../../components/Table/DataTableServerPaging";
import {TableColumn} from "react-data-table-component";
import {DetailPoleMRE, PoleMRE} from "../../../../basic/pole/models";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {getDetailPoleMRE, getPoleMREByJob} from "../../../../basic/pole/pole.reducer";
import {JobID} from "../../jobDetail/models";
import {MREModel} from "../../../process/mre/models";

const DetailMREPoleTable = ({jobId = undefined}: DetailKatapultTableProps) => {
    const dispatch = useAppDispatch();

    const singleMRE = useAppSelector(
        (state: RootState) => state.processMRE.singleMRE ?? {} as MREModel
    );
    const pole = useAppSelector(
        (state: RootState) => state.pole.poleMREDetail ?? []
    );
    const poleRows = useAppSelector(
        (state: RootState) => state.pole.poleMREDetailRows ?? 0
    );

    const columns: TableColumn<DetailPoleMRE>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Pole Seq',
            selector: (row) => row.seq,
            sortable: true,
        },
        {
            name: 'Pole No',
            selector: (row) => row.no ?? "-",
            sortable: true,
        },
        {
            name: 'Pole Status',
            selector: (row) => row.status_name,
            sortable: true,
        },
        {
            name: 'MRE',
            selector: (row) => row.employee_mre_name,
            sortable: true,
        },
        {
            name: 'QC MRE',
            selector: (row) => row.employee_mre_qc_name,
            sortable: true,
        },
        {
            name: 'MRE Type',
            selector: (row) => row.type,
            sortable: true,
        },
    ];

    const handlePagingChange = (rowsPerPage: number, page: number) => {
        dispatch(getDetailPoleMRE({
            job_id: jobId as string, skip: rowsPerPage * (page - 1),
            take: rowsPerPage,
        }));
    }

    return (
        <div>
            <div className="flex justify-items-center">
                <h1 className="text-lg leading-10 grow font-medium truncate mr-5 dark:text-gray-300">
                    MRE - {singleMRE.number_job}
                </h1>
            </div>
            <div className="main-data-grid mt-5">
                <DataTableServerPaging columns={columns} data={pole} rows={poleRows}
                                       handlePagingChange={handlePagingChange}
                />
            </div>
        </div>
    );
};

export interface DetailKatapultTableProps {
    jobId?: string;
}

export default DetailMREPoleTable;
