import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import InputText from 'components/TextInput/InputText';
import { InputModel, DepartmentOwner } from '../models';
import Button from 'components/Button/Button';
import { schema } from '../constants';
import { useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { isArrayEmpty, isObjectEmpty } from 'helpers/checkEmptiness';
import SelectInput from '../../../../components/SelectInput/SelectInput';
import BasicSelectInput, {
    Option,
} from '../../../../components/SelectInput/BasicSelectInput';
import { MultiValue } from 'react-select';

const DepartmentOwnerForm = ({
    isLoading = false,
    isEdit = false,
    ...props
}: DepartmentOwnerFormProps) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<InputModel>({
        resolver: yupResolver(schema), defaultValues: {
            district_ids: '', type_ids: ''
        }
    });

    const singleState = useAppSelector(
        (state: RootState) =>
            state.departmentOwner.single ?? ({} as DepartmentOwner)
    );
    const departmentOptions = useAppSelector(
        (state: RootState) => state.department.options ?? []
    );
    const districtState = useAppSelector(
        (state: RootState) => state.district.options ?? []
    );
    const typeState = useAppSelector(
        (state: RootState) => state.jobType.options ?? []
    );

    const [selectedDistrict, setSelectedDistrict] = useState<Option[]>([]);
    const [selectedType, setSelectedType] = useState<Option[]>([]);

    useEffect(() => {
        if (!isObjectEmpty(singleState)) {
            let type_ids = singleState.jobtypes.map((item) => item.id);
            let district_ids = singleState.districts.map((item) => item.id);
            let _district = singleState.districts?.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            let _types = singleState.jobtypes?.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            setSelectedDistrict(_district as Option[]);
            setSelectedType(_types as Option[]);
            reset({name: singleState.name, department_id: singleState.department_id, type_ids: type_ids.toString() ?? '', district_ids: district_ids.toString() ?? ''} as InputModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleState]);

    useEffect(() => {
        if (!isEdit) {
            setSelectedType([]);
            setSelectedDistrict([]);
            reset({department_id: departmentOptions[0]?.value, type_ids: '', district_ids: ''} as InputModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, departmentOptions]);

    const onSubmit = (data: InputModel) => {
        let district_ids = selectedDistrict.map((item) => item.value);
        let type_ids = selectedType.map((item) => item.value);
        let _data = {...data, district_ids: district_ids.toString(), type_ids: type_ids.toString()};
        props.onSubmit(_data);
    };

    const onDistrictChange = (val: MultiValue<Option>) => {
        // let _district = val.map((item) => item.value);
        // setValue('district_ids', _district.toString());
        setSelectedDistrict(val as Option[]);
    };

    const onTypeChange = (val: MultiValue<Option>) => {
        // let _type = val.map((item) => item.value);
        // setValue('type_ids', _type.toString());
        setSelectedType(val as Option[]);
    };

    const defaultDistrict = () => {
        if (isEdit) {
            if (isArrayEmpty(districtState)) return [];
            let _district = singleState.districts?.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            return _district as Option[];
        }
    }

    const defaultType = () => {
        if (isEdit) {
            if (isArrayEmpty(typeState)) return [];
            let _type = singleState.jobtypes?.map((item) => item.id);
            return typeState.filter((item) => _type?.includes(item.value));
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputText
                {...register('name')}
                label="Departement Owner Name"
                placeholder="Input Departement Owner name"
                message={errors.name?.message}
            />
            <BasicSelectInput
                label="Department"
                message={errors.department_id?.message}
                options={departmentOptions}
                {...register('department_id')}
            />
            <SelectInput
                options={districtState}
                isMulti
                label="District"
                onChange={onDistrictChange}
                value={selectedDistrict}
                // defaultValue={defaultDistrict()}
            />
            <SelectInput
                options={typeState}
                isMulti
                label="Job Type"
                onChange={onTypeChange}
                value={selectedType}
                // defaultValue={defaultType()}
            />
            <div className="pt-2 pb-2">
                <Button
                    color="success"
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                />
            </div>
        </form>
    );
};

export interface DepartmentOwnerFormProps {
    isLoading: boolean;
    isEdit: boolean;
    onSubmit: (formInput: InputModel) => void;
}

export default DepartmentOwnerForm;
