import * as yup from 'yup';
import {
    JOB_STATUS_CALC_SPEC, JOB_STATUS_CANCEL, JOB_STATUS_CANCEL_KATAPULT, JOB_STATUS_CANCEL_MRE, JOB_STATUS_HOLD,
    JOB_STATUS_KATAPULT,
    JOB_STATUS_MRE,
    JOB_STATUS_NEW,
    JOB_STATUS_NEW_KATAPULT,
    JOB_STATUS_NEW_MRE,
    JOB_STATUS_PREP,
    JOB_STATUS_QC,
    JOB_STATUS_QC_MRE,
    JOB_STATUS_READY_TO_ASSIGN,
    JOB_STATUS_READY_TO_QC,
    JOB_STATUS_READY_TO_QC_MRE, JOB_STATUS_WAITING,
    JOB_STATUS_WAITING_KATAPULT,
    JOB_STATUS_WAITING_MRE
} from "../../../../constants";

export const schema = yup
    .object({
        number_id: yup.string().required('Number ID is required'),
        number_job: yup.string().required('Job Number is required'),
        sales_order: yup.string().required('Sales Order is required'),
        date_due: yup.date().required().default(() => new Date()),
        // fielder: yup.string().required(),
        // category_id: yup.string().required('Category is required'),
        type_id: yup.string().required('Type is required'),
        // district_id: yup.string().required('District is required'),
        // selectedProject: yup.object().required('Project is required'),
        // requestor: yup.string().required(),
        // is_priority: yup.number().required(),
        // is_mre_req: yup.number().required(),
        // is_katapult: yup.number().required(),
        pole_total: yup.string().required('Req Pole is required'),
        kind_id: yup.string().required('Kind is required'),
        // department_id: yup.string().required('Department is required'),
        // source_id: yup.string().required(),
    })
    .required();

export const importSchema = yup
    .object({
        local_files: yup.mixed().required('File is required'),
    })
    .required();

export const DETAIL_TYPE_JOB_INPUT = 'job-input';
export const DETAIL_TYPE_JOB_PREP = 'job-prep';
export const DETAIL_TYPE_JOB_OCALC = 'job-ocalc';
export const DETAIL_TYPE_MRE = 'mre';
export const DETAIL_TYPE_KATAPULT = 'katapult';

export const showHistoryActionList = [JOB_STATUS_NEW, JOB_STATUS_PREP, JOB_STATUS_READY_TO_ASSIGN, JOB_STATUS_CALC_SPEC, JOB_STATUS_READY_TO_QC, JOB_STATUS_QC];


export const showHistoryMreActionList = [JOB_STATUS_NEW_MRE, JOB_STATUS_MRE, JOB_STATUS_WAITING_MRE, JOB_STATUS_READY_TO_QC_MRE, JOB_STATUS_QC_MRE];

export const showHistoryKatapultActionList = [ JOB_STATUS_NEW_KATAPULT, JOB_STATUS_KATAPULT, JOB_STATUS_WAITING_KATAPULT];

export const showNotesJobList = [ JOB_STATUS_WAITING, JOB_STATUS_HOLD, JOB_STATUS_CANCEL, JOB_STATUS_WAITING_MRE, JOB_STATUS_CANCEL_MRE, JOB_STATUS_WAITING_KATAPULT, JOB_STATUS_CANCEL_KATAPULT ];

export const checkHoldStatus = (status: string) => {
    return showHistoryActionList.includes(status);
}

export const checkMreStatus = (status: string) => {
    return showHistoryMreActionList.includes(status);
}

export const checkKatapultStatus = (status: string) => {
    return showHistoryKatapultActionList.includes(status);
}

export const checkNotesJobStatus = (status: string) => {
    return showNotesJobList.includes(status);
}