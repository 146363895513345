import {BaseServiceClass} from "../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../http-client";
import {DashboardChartModel, DashboardJobModel, DashboardOverviewModel} from "./models";

export default class DashboardApi extends BaseServiceClass {
    static getDashboardHeaderOcalc(token: string) {
        return httpClient.get<ApiResponseWrapper<DashboardOverviewModel[]>>(this.PATH_DASHBOARD.ocalc_header, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static getDashboardHeaderMRE(token: string) {
        return httpClient.get<ApiResponseWrapper<DashboardOverviewModel[]>>(this.PATH_DASHBOARD.mre_header, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static getDashboardHeaderKatapult(token: string) {
        return httpClient.get<ApiResponseWrapper<DashboardOverviewModel[]>>(this.PATH_DASHBOARD.katapult_header, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static getOcalcChart(token: string) {
        return httpClient.get<ApiResponseWrapper<DashboardChartModel[]>>(this.PATH_DASHBOARD.ocalc_chart, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static getMREChart(token: string) {
        return httpClient.get<ApiResponseWrapper<DashboardChartModel[]>>(this.PATH_DASHBOARD.mre_chart, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static getKatapultChart(token: string) {
        return httpClient.get<ApiResponseWrapper<DashboardChartModel[]>>(this.PATH_DASHBOARD.katapult_chart, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }

    static getDashboardOcalc(token: string) {
        return httpClient.get<ApiResponseWrapper<DashboardJobModel[]>>(this.PATH_DASHBOARD.ocalc_dashboard, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => res.data);
    }
}