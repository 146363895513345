import { HttpClientError, RequestStatus } from '../../../http-client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import EmailApi from './email.api';
import {EmailModel, EmailRequest, GetEmailByJob} from "./models";
export interface EmailSlice {
    list?: Array<EmailModel>;
    rows?: number;
    lastSeq?: number;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}
export const createEmail = createAsyncThunk(
    'email/createEmail',
    async (args: EmailRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmailApi.createEmail(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getLastSeq = createAsyncThunk(
    'email/getLastSeq',
    async (jobId: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmailApi.getLastSeq(jobId, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getEmailByJob = createAsyncThunk(
    'email/getEmailByJob',
    async (args: GetEmailByJob, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await EmailApi.getEmailByJob(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const emailSlice = createSlice({
    name: 'emailState',
    initialState: {} as EmailSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
        },
    },
    extraReducers: (builder) => {
        // create data reducer
        builder.addCase(createEmail.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isLoading = true;
        });
        builder.addCase(createEmail.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isLoading = false;
        });
        builder.addCase(createEmail.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // get last seq data reducer
        builder.addCase(getLastSeq.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getLastSeq.fulfilled, (state, {payload}) => {
            state.lastSeq = payload.data;
            state.isLoading = false;
        });
        builder.addCase(getLastSeq.rejected, (state) => {
            state.isLoading = false;
        });
        // get email data reducer
        builder.addCase(getEmailByJob.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEmailByJob.fulfilled, (state, {payload}) => {
            state.list = payload.data;
            state.rows = payload.rows;
            state.isLoading = false;
        });
        builder.addCase(getEmailByJob.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { reset } = emailSlice.actions;
export default emailSlice.reducer;
