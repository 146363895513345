import React, {useEffect} from 'react';
import Button from "../../../components/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../helpers/redux";
import {GlobalApprovalEnum} from "../../../types";
import {HttpClientError, RequestStatus} from "../../../http-client";
import {savedStatusOption} from "../../../helpers/alertLabel";
import {createAttendance, reset} from "../../profile/profile.reducer";
import {RootState} from "../../../app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {setDynamicNavId} from "../sidebar.reducer";
import {IS_WARNING_SHOWN, LAST_ATTENDANCE} from "../../../constants";
import {dateSetTimeToNow} from "../../../helpers/convert";

const AttendanceAlert = ({userId = "", ...props}: AttendanceAlertProps) => {
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);
    let navigate = useNavigate();

    const status = useAppSelector((state: RootState) => state.profile.attendanceStatus);
    const error = useAppSelector(
        (state: RootState) => state.profile.error ?? ({} as HttpClientError)
    );

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                if (status === RequestStatus.success) {
                    localStorage.setItem(LAST_ATTENDANCE, new Date().toISOString());
                    dispatch(setDynamicNavId("absent"));
                    props.onClose();
                    return navigate("/profile", {replace: true});
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onFullDayClicked = () => {
        dispatch(createAttendance({
            reqMinute: 480,
            leaveDuration: 0,
            notes: 'WFO 08.00-17.00',
            rejectionNotes: '',
            status: GlobalApprovalEnum.Requested,
            approveMinute: 0,
            isDeducted: false,
            isRefNote: false,
            userId: userId,
            absentDate: dateSetTimeToNow(new Date().toISOString()) ?? new Date().toISOString(),
        }));
    }

    const onCustomAbsentClicked = () => {
        dispatch(setDynamicNavId("absent"));
        props.onClose()
        return navigate("/profile", {replace: true});
    }

    return (
        <div className="flex justify-center p-2 md:p-6 pt-2 md:pt-2 bg-white dark:border-gray-700 dark:bg-gray-800 rounded-2xl">
            <div>
                <div className="my-5">
                    <h1 className="text-2xl">Don't Forget Attendance!!</h1>
                </div>
                <div className="text-center">
                    <div className="mb-1 text-center">
                        <Button
                            color="success"
                            type="submit"
                            title="Full Day"
                            onClick={onFullDayClicked}
                        />
                    </div>
                    <div className="mt-1">
                        <Button
                            color="success"
                            type="submit"
                            title="Custom Absent"
                            onClick={onCustomAbsentClicked}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export interface AttendanceAlertProps {
    userId: string;
    onClose: () => void;
}

export default AttendanceAlert;
