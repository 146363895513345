import {HttpClientError, RequestStatus} from "../../../../http-client";
import {
    QCType,
    GetQCListRequest,
    MainQCType,
    GetQCRequest,
    JobQCSummary,
    GetQCWithFilterRequest,
    GetQCMainWithFilterRequest
} from "./models";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../../app/store";
import QcApi from "./qc.api";

export interface QCSlice {
    qcList: Array<QCType>;
    singleQC: QCType;
    mainQCList: Array<MainQCType>;
    qcSummaryList: Array<JobQCSummary>;
    rows?: number;
    isLoading?: boolean;
    isAssignLoading?: boolean;
    isSingleLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getQCList = createAsyncThunk(
    'qualityControl/getQCList',
    async (args: GetQCListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await QcApi.getQCList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getQCWithFilterList = createAsyncThunk(
    'qualityControl/getQCWithFilterList',
    async (args: GetQCWithFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await QcApi.getQCWithFilterList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getSingleQC = createAsyncThunk(
    'qualityControl/getSingleQC',
    async (args: GetQCListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await QcApi.getSingleQC(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getQCMainList = createAsyncThunk(
    'qualityControl/getQCMainList',
    async (args: GetQCListRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await QcApi.getQCMainList(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getMainQCWithFilterList = createAsyncThunk(
    'qualityControl/getMainQCWithFilterList',
    async (args: GetQCMainWithFilterRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await QcApi.getMainQCWithFilterList(args, userAuth?.id as string, userAuth?.user_id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const getJobQCSummary = createAsyncThunk(
    'qualityControl/getJobQCSummary',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await QcApi.getJobQCSummary(id, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);


export const getAssignQC = createAsyncThunk(
    'qualityControl/getAssignQC',
    async (args: GetQCRequest, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await QcApi.getAssignQC(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const qcSlice = createSlice({
    name: 'qcState',
    initialState: {} as QCSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getQCList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQCList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.qcList = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getQCList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getQCWithFilterList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQCWithFilterList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.qcList = action.payload.data;
            state.rows = action.payload.rows;
        });
        builder.addCase(getQCWithFilterList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getSingleQC.pending, (state) => {
            state.isSingleLoading = true;
        });
        builder.addCase(getSingleQC.fulfilled, (state, action) => {
            state.isSingleLoading = false;
            state.singleQC = action.payload.data;
        });
        builder.addCase(getSingleQC.rejected, (state, action) => {
            state.isSingleLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getJobQCSummary.pending, (state) => {
            state.isSingleLoading = true;
        });
        builder.addCase(getJobQCSummary.fulfilled, (state, action) => {
            state.isSingleLoading = false;
            state.qcSummaryList = action.payload.data;
        });
        builder.addCase(getJobQCSummary.rejected, (state, action) => {
            state.isSingleLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getQCMainList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getQCMainList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mainQCList = action.payload.data?.map((item: MainQCType, index) => {
                return {...item, table_id: `${item.id}-${index}`};
            });
            state.rows = action.payload.rows;
        });
        builder.addCase(getQCMainList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        builder.addCase(getMainQCWithFilterList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMainQCWithFilterList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mainQCList = action.payload.data?.map((item: MainQCType, index) => {
                return {...item, table_id: `${item.id}-${index}`};
            });
            state.rows = action.payload.rows;
        });
        builder.addCase(getMainQCWithFilterList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // create data reducer
        builder.addCase(getAssignQC.pending, (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
            state.isAssignLoading = true;
        });
        builder.addCase(getAssignQC.fulfilled, (state) => {
            state.status = RequestStatus.success;
            state.isAssignLoading = false;
        });
        builder.addCase(getAssignQC.rejected, (state, { payload }) => {
            state.status = RequestStatus.failed;
            state.error = payload as HttpClientError;
            state.isAssignLoading = false;
        });
    }
});

export const { reset } = qcSlice.actions;
export default qcSlice.reducer;