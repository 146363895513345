import {DashboardChartModel, DashboardJobModel, DashboardOverviewModel, PieChartModel} from "./models";
import {HttpClientError, RequestStatus} from "../../http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import DashboardApi from "./dashboard.api";
import {RootState} from "../../app/store";
import {groupBy} from "../../helpers/convert";


export interface DashboardSlice {
    ocalcHeaderList?: Array<DashboardOverviewModel>;
    mreHeaderList?: Array<DashboardOverviewModel>;
    katapultHeaderList?: Array<DashboardOverviewModel>;
    ocalcChartList?: PieChartModel;
    mreChartList?: PieChartModel;
    katapultChartList?: PieChartModel;
    dashboardJobList?: Array<DashboardJobModel>;
    isLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getDashboardHeaderOcalc = createAsyncThunk(
    'dashboard/getDashboardHeaderOcalc',
    async (arg: undefined, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DashboardApi.getDashboardHeaderOcalc(userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getDashboardHeaderMRE = createAsyncThunk(
    'dashboard/getDashboardHeaderMRE',
    async (arg: undefined, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DashboardApi.getDashboardHeaderMRE(userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getDashboardHeaderKatapult = createAsyncThunk(
    'dashboard/getDashboardHeaderKatapult',
    async (arg: undefined, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DashboardApi.getDashboardHeaderKatapult(userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getOcalcChart = createAsyncThunk(
    'dashboard/getOcalcChart',
    async (arg: undefined, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DashboardApi.getOcalcChart(userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getMREChart = createAsyncThunk(
    'dashboard/getMREChart',
    async (arg: undefined, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DashboardApi.getMREChart(userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getKatapultChart = createAsyncThunk(
    'dashboard/getKatapultChart',
    async (arg: undefined, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DashboardApi.getKatapultChart(userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });


export const getDashboardOcalc = createAsyncThunk(
    'dashboard/getDashboardOcalc',
    async (arg: undefined, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await DashboardApi.getDashboardOcalc(userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

const dashboardSlice = createSlice({
    name: 'dashboardState',
    initialState: {} as DashboardSlice,
    reducers: {},
    extraReducers: (builder) => {
        // get dashboard header ocalc
        builder.addCase(getDashboardHeaderOcalc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDashboardHeaderOcalc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ocalcHeaderList = action.payload.data;
        });
        builder.addCase(getDashboardHeaderOcalc.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get dashboard header mre
        builder.addCase(getDashboardHeaderMRE.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDashboardHeaderMRE.fulfilled, (state, action) => {
            state.isLoading = false;
            state.mreHeaderList = action.payload.data;
        });
        builder.addCase(getDashboardHeaderMRE.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get dashboard header katapult
        builder.addCase(getDashboardHeaderKatapult.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDashboardHeaderKatapult.fulfilled, (state, action) => {
            state.isLoading = false;
            state.katapultHeaderList = action.payload.data;
        });
        builder.addCase(getDashboardHeaderKatapult.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get dashboard chart ocalc
        builder.addCase(getOcalcChart.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOcalcChart.fulfilled, (state, action) => {
            const _label = action.payload?.data?.map((item) => item.project);
            const _total = action.payload?.data?.map((item) => item.total);
            state.ocalcChartList = {
                labels: _label,
                datasets: [
                    {
                        label: '# of Available Poles',
                        data: _total,
                        borderWidth: 1,
                    }
                ]
            };
            state.isLoading = false;
        });
        builder.addCase(getOcalcChart.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get dashboard chart mre
        builder.addCase(getMREChart.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMREChart.fulfilled, (state, action) => {
            const _label = action.payload.data?.map((item) => item.project);
            const _total = action.payload.data?.map((item) => item.total);
            state.mreChartList = {
                labels: _label,
                datasets: [
                    {
                        label: '# of Available Poles',
                        data: _total,
                        borderWidth: 1,
                    }
                ]
            };
            state.isLoading = false;
        });
        builder.addCase(getMREChart.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get dashboard chart katapult
        builder.addCase(getKatapultChart.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getKatapultChart.fulfilled, (state, action) => {
            const _label = action.payload.data?.map((item) => item.project);
            const _total = action.payload.data?.map((item) => item.total);
            state.katapultChartList = {
                labels: _label,
                datasets: [
                    {
                        label: '# of Available Poles',
                        data: _total,
                        borderWidth: 1,
                    }
                ]
            };
            state.isLoading = false;
        });
        builder.addCase(getKatapultChart.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get dashboard job
        builder.addCase(getDashboardOcalc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDashboardOcalc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.dashboardJobList = action.payload.data;
        });
        builder.addCase(getDashboardOcalc.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
    }
});

export default dashboardSlice.reducer;