import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BillJob, BillJobInputModel } from '../../models';
import SelectInput from '../../../../components/SelectInput/SelectInput';
import { getJobDropdown } from '../../../worksheet/process/job/job.reducer';
import { useAppDispatch, useAppSelector } from '../../../../helpers/redux';
import { RootState } from '../../../../app/store';
import { SingleValue } from 'react-select';
import {
    Option,
    Options,
} from '../../../../components/SelectInput/BasicSelectInput';
import Button from '../../../../components/Button/Button';
import {
    createBillJob,
    getBillByJobNumber,
    getJobData,
    getSingleBillJob,
    getStatusByType,
    getWeekendDate,
    reset,
    submitBillJobWithFile,
    updateBillJob,
} from '../billJob.reducer';
import { BillTypeOptions, schema } from '../../constants';
import InputText from '../../../../components/TextInput/InputText';
import TextArea from '../../../../components/TextArea/TextArea';
import FileDropzone from '../../../../components/FileInput/Dropzone';
import { HttpClientError, RequestStatus } from '../../../../http-client';
import {
    customWarnOption,
    savedStatusOption,
} from '../../../../helpers/alertLabel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { isObjectEmpty } from '../../../../helpers/checkEmptiness';
import { FileAttachment } from '../../../../types';
import BillJobFile from './BillJobFile';
import { UserCredential } from '../../../login/models';
import AsyncSelect from 'react-select/async';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { checkCurrentWeekendRange, checkWeekendRange, getDatesHMinus7 } from 'helpers/convert';

const BillJobForm = ({ isEdit }: BillJobFormProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();

    const [hide, setHide] = useState<string | undefined>(undefined);
    let { id } = useParams();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset: resetForm,
        formState: { errors },
    } = useForm<BillJobInputModel>({
        resolver: yupResolver(schema),
    });

    const selectedJobNumber = watch('numberJobId');
    const selectedIdNumber = watch('numberId');
    const selectedOtherNumber = watch('otherNumberJobId');
    const selectedSalesOrder = watch('salesOrderJobId');
    const selectedPONumber = watch('poNumberJobId');
    const selectedType = watch('type');
    const selectedStatus = watch('status');
    const fileWatcher = watch('local_files');

    const [isMoreThan24Hours, setIsMoreThan24Hours] = useState<boolean>(false);

    const [currentFiles, setCurrentFiles] = useState<Array<FileAttachment>>([]);
    const [timer, setTimer] = useState(setTimeout(() => {}, 1000));

    const [isCurrentWeek, setIsCurrentWeek] = useState<boolean>(true);

    // const jobOptions = useAppSelector(
    //     (state: RootState) => state.job.options ?? []
    // );
    // const isJobLoading = useAppSelector(
    //     (state: RootState) => state.job.isLoading
    // );

    const singleBillJob = useAppSelector(
        (state: RootState) => state.billJob.single ?? ({} as BillJob)
    );

    const numberIdOptions = useAppSelector(
        (state: RootState) => state.billJob.numberIdOptions ?? []
    );
    const otherNumberOptions = useAppSelector(
        (state: RootState) => state.billJob.otherNumberOptions ?? []
    );
    const salesOrderOptions = useAppSelector(
        (state: RootState) => state.billJob.salesOrderOptions ?? []
    );
    const poNumberOptions = useAppSelector(
        (state: RootState) => state.billJob.poNumberOptions ?? []
    );
    const isSelectedJobLoading = useAppSelector(
        (state: RootState) => state.billJob.isLoading
    );

    const billStatusOptions = useAppSelector(
        (state: RootState) => state.billJob.billStatusOptions ?? []
    );
    const isBillStatusLoading = useAppSelector(
        (state: RootState) => state.billJob.isBillStatusLoading
    );
    const uploadProgresses = useAppSelector(
        (state: RootState) => state.billJob.uploadProgress ?? []
    );
    const status = useAppSelector((state: RootState) => state.billJob.status);
    const error = useAppSelector(
        (state: RootState) => state.billJob.error ?? ({} as HttpClientError)
    );
    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? ({} as UserCredential)
    );
    const weekendDate = useAppSelector(
        (state: RootState) => state.billJob.weekendDate ?? []
    );

    // useEffect(() => {
    //     console.log(isEdit);
    //     if (!isEdit) {
    //         resetForm();
    //         dispatch(reset());
    //         dispatch(resetSingle());
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isEdit]);

    useEffect(() => {
        if (isEdit && id) {
            dispatch(
                getSingleBillJob({
                    skip: 0,
                    take: 1,
                    id: id,
                })
            );
            dispatch(
                getWeekendDate({
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear(),
                })
            );
        }
    }, [id, isEdit]);

    useEffect(() => {
        if (isEdit && !isObjectEmpty(singleBillJob)) {
            resetForm({
                numberJobId: {
                    value: singleBillJob.numberJobId.toString(),
                    label: singleBillJob.numberJob,
                },
                numberId: {
                    value: singleBillJob.numberId.toString(),
                    label: singleBillJob.number,
                },
                otherNumberJobId: {
                    value: singleBillJob.otherNumberJobId.toString(),
                    label: singleBillJob.otherNumberJob,
                },
                salesOrderJobId: {
                    value: singleBillJob.salesOrderJobId.toString(),
                    label: singleBillJob.salesOrderJob,
                },
                poNumberJobId: {
                    value: singleBillJob.poNumberJobId?.toString(),
                    label: singleBillJob.poNumberJob,
                },
                type: {
                    value: singleBillJob.type.toString(),
                    label: singleBillJob.typeName,
                },
                status: {
                    value: singleBillJob.status.toString(),
                    label: singleBillJob.statusName,
                },
                totalPole: singleBillJob.totalPole.toString(),
                vDriveLocation: singleBillJob.vDriveLocation,
                notes: singleBillJob.notes,
            });

            setIsMoreThan24Hours(singleBillJob.isMoreThan24Hours);

            if (singleBillJob.attachment !== null) {
                setCurrentFiles([
                    {
                        id: singleBillJob.id,
                        name: singleBillJob.attachment,
                    },
                ]);
            }
        }
    }, [isEdit, singleBillJob]);

    useEffect(() => {
        if (selectedJobNumber && selectedJobNumber.value !== undefined) {
            dispatch(getJobData(selectedJobNumber.value));
            dispatch(getBillByJobNumber(selectedJobNumber.value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedJobNumber]);

    useEffect(() => {
        if (selectedType && selectedType.value !== undefined) {
            dispatch(getStatusByType(parseInt(selectedType.value)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType]);

    useEffect(() => {
        if (status !== undefined && status !== RequestStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === RequestStatus.success,
                    error.data?.message,
                    true
                )
            ).then(() => {
                if (isEdit) {
                    dispatch(
                        getSingleBillJob({
                            skip: 0,
                            take: 1,
                            id: id,
                        })
                    );
                }
                dispatch(getBillByJobNumber(selectedJobNumber.value));
                dispatch(reset());
                resetForm();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    useEffect(() => {
        if (weekendDate.length > 0 && !isObjectEmpty(singleBillJob)) {
            let weekending = weekendDate.map((item) => item.split('T')[0]);
            const datesHMinus7 = getDatesHMinus7(weekending);
            // Memformat tanggal kembali ke string jika diperlukan
            const formattedStartDates = datesHMinus7.map(
                (date) => date.toISOString().split('T')[0]
            );
            const formattedEndDates = weekending.map(
                (date) => date.split('T')[0]
            );

            const result = checkWeekendRange(
                formattedStartDates,
                formattedEndDates
            );

            if (result.length > 0) {
                setIsCurrentWeek(
                    checkCurrentWeekendRange(
                        result[0],
                        result[1],
                        singleBillJob.ts_created?.split('T')[0]
                    )
                );
            } else {
                setIsCurrentWeek(true);
            }
        }
    }, [weekendDate, singleBillJob]);

    const onSubmit = (data: BillJobInputModel) => {
        MySwal.fire(
            customWarnOption(
                'Update Bill Job',
                'Are you sure you want to update this bill job?'
            )
        ).then((result) => {
            if (result.isConfirmed) {
                const _submit = {
                    ...data,
                    numberJobId: data.numberJobId?.value,
                    numberId: data.numberId?.value,
                    otherNumberJobId: data.otherNumberJobId?.value,
                    salesOrderJobId: data.salesOrderJobId?.value,
                    poNumberJobId: data.poNumberJobId?.value,
                    type: parseInt(data.type?.value),
                    status: parseInt(data.status?.value),
                    totalPole: parseInt(data.totalPole),
                    user_qc: user.user_id,
                };
                if (data.local_files) {
                    const _submitBill = { ..._submit, isEdit: isEdit, id: id };
                    dispatch(submitBillJobWithFile(_submitBill));
                } else {
                    if (isEdit) {
                        const _submitUpdate = {
                            ..._submit,
                            id: id,
                            attachment: singleBillJob.attachment,
                        };
                        dispatch(updateBillJob(_submitUpdate));
                    } else {
                        dispatch(createBillJob(_submit));
                    }
                }
            }
        });
    };

    const onOptionChange = (val: SingleValue<Option>, valName: any) => {
        setValue(valName, val ?? ({} as Option));

        if (valName === 'numberJobId') {
            setValue('numberId', {} as Option);
            setValue('otherNumberJobId', {} as Option);
            setValue('salesOrderJobId', {} as Option);
        }
    };

    const onFileChange = (files: Array<File>) => {
        setValue('local_files', files);
    };

    const getDropdownJob = async (inputValue: string) => {
        const result = await dispatch(
            getJobDropdown({
                skip: 0,
                take: 100,
                job_number:
                    inputValue.toLowerCase() === ''
                        ? 'a'
                        : inputValue.toLowerCase(),
            })
        );

        const originalPromiseResult = unwrapResult(result);

        let _option = originalPromiseResult?.data?.map((item: any) => {
            return {
                label: `${item.number_job} | ${item.koj}`,
                text: `${item.number_job} | ${item.koj}`,
                value: item.id,
            };
        });
        return _option as Options[];
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<Options[]>((resolve) => {
            clearTimeout(timer);
            setTimer(
                setTimeout(() => {
                    resolve(getDropdownJob(inputValue));
                }, 1000)
            );
        });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className="flex mt-5 mb-10">
                    <div className="w-1/6 flex items-center">
                        <span>Job Number</span>
                    </div>
                    <div className="w-1/2">
                        {/*<SelectInput*/}
                        {/*    key={`numberJobId`}*/}
                        {/*    options={jobOptions}*/}
                        {/*    isLoading={isJobLoading}*/}
                        {/*    onChange={(val) => onOptionChange(val, 'numberJobId')}*/}
                        {/*    value={selectedJobNumber}*/}
                        {/*/>*/}
                        <div
                            style={{
                                display: hide && 'none',
                                position: 'absolute',
                                marginLeft: '10px',
                                marginTop: '10px',
                                zIndex: 10,
                            }}
                        >
                            {selectedJobNumber && selectedJobNumber?.label}
                        </div>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseOptions}
                            isDisabled={isMoreThan24Hours}
                            value={
                                selectedJobNumber !== null
                                    ? {
                                          value: selectedJobNumber?.value,
                                          label: selectedJobNumber?.label,
                                      }
                                    : null
                            }
                            onChange={(data: any) =>
                                onOptionChange(
                                    {
                                        value: data.value,
                                        label: data.label,
                                        text: data.label,
                                    },
                                    'numberJobId'
                                )
                            }
                            onInputChange={setHide}
                            components={{
                                SingleValue: () => {
                                    return null;
                                },
                            }}
                            styles={{
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                }),
                            }}
                        />
                        {errors.numberJobId?.value?.message !== '' && (
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                {errors.numberJobId?.value?.message}
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>ID Number</span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`numberId`}
                            options={numberIdOptions}
                            isLoading={isSelectedJobLoading}
                            isDisabled={isMoreThan24Hours}
                            onChange={(val) => onOptionChange(val, 'numberId')}
                            value={
                                selectedIdNumber?.value !== undefined
                                    ? selectedIdNumber
                                    : null
                            }
                            supportCopyInputVal
                            inputValue={selectedIdNumber?.label}
                            message={errors.numberId?.value?.message}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>Other Number</span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`otherNumberJobId`}
                            options={otherNumberOptions}
                            isLoading={isSelectedJobLoading}
                            isDisabled={isMoreThan24Hours}
                            onChange={(val) =>
                                onOptionChange(val, 'otherNumberJobId')
                            }
                            value={
                                selectedOtherNumber?.value !== undefined
                                    ? selectedOtherNumber
                                    : null
                            }
                            supportCopyInputVal
                            inputValue={selectedOtherNumber?.label}
                            message={errors.otherNumberJobId?.value?.message}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>Sales Order</span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`salesOrderJobId`}
                            options={salesOrderOptions}
                            isLoading={isSelectedJobLoading}
                            isDisabled={isMoreThan24Hours}
                            onChange={(val) =>
                                onOptionChange(val, 'salesOrderJobId')
                            }
                            value={
                                selectedSalesOrder?.value !== undefined
                                    ? selectedSalesOrder
                                    : null
                            }
                            supportCopyInputVal
                            inputValue={selectedSalesOrder?.label}
                            message={errors.salesOrderJobId?.value?.message}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>PO Number</span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`poNumberJobId`}
                            options={poNumberOptions}
                            isLoading={isSelectedJobLoading}
                            isDisabled={isMoreThan24Hours}
                            onChange={(val) =>
                                onOptionChange(val, 'poNumberJobId')
                            }
                            value={
                                selectedPONumber?.value !== undefined
                                    ? selectedPONumber
                                    : null
                            }
                            supportCopyInputVal
                            inputValue={selectedPONumber?.label}
                            message={errors.poNumberJobId?.value?.message}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>Type</span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`BillType`}
                            options={BillTypeOptions}
                            onChange={(val) => onOptionChange(val, 'type')}
                            isDisabled={isMoreThan24Hours}
                            value={
                                selectedType?.value !== undefined
                                    ? selectedType
                                    : null
                            }
                            supportCopyInputVal
                            inputValue={selectedType?.label}
                        />
                    </div>
                </div>
                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>Status</span>
                    </div>
                    <div className="w-1/2">
                        <SelectInput
                            key={`BillStatus`}
                            options={billStatusOptions}
                            isLoading={isBillStatusLoading}
                            isDisabled={isMoreThan24Hours}
                            onChange={(val) => onOptionChange(val, 'status')}
                            value={
                                selectedStatus?.value !== undefined
                                    ? selectedStatus
                                    : null
                            }
                            supportCopyInputVal
                            inputValue={selectedStatus?.label}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>Total Poles</span>
                    </div>
                    <div className="w-1/2">
                        <InputText
                            {...register('totalPole')}
                            placeholder="Input total poles"
                            type={'number'}
                            message={errors.totalPole?.message}
                            onWheel={(e) => (e.target as HTMLElement).blur()}
                            disabled={!isCurrentWeek || isMoreThan24Hours}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>VDrive Location</span>
                    </div>
                    <div className="w-1/2">
                        <InputText
                            {...register('vDriveLocation')}
                            placeholder="Input VDrive Location"
                            message={errors.vDriveLocation?.message}
                            disabled={isMoreThan24Hours}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>Attachment</span>
                    </div>
                    <div className="w-1/2">
                        <FileDropzone
                            onChange={onFileChange}
                            uploadProgress={uploadProgresses}
                            files={fileWatcher}
                            disabled={isMoreThan24Hours}
                        />
                        {isEdit && (
                            <BillJobFile files={currentFiles} isReadOnly />
                        )}
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6 flex items-center">
                        <span>Notes</span>
                    </div>
                    <div className="w-1/2">
                        <TextArea
                            {...register('notes')}
                            placeholder="Input notes"
                            message={errors.notes?.message}
                        />
                    </div>
                </div>

                <div className="flex my-5">
                    <div className="w-1/6"></div>
                    <div className="w-1/2 flex justify-end">
                        <Button title="Save" type="submit" />
                    </div>
                </div>
            </div>
        </form>
    );
};

export interface BillJobFormProps {
    isEdit: boolean;
}

export default BillJobForm;
