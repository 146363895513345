import {BooleanNumber} from "types";
import {Option} from "../../../../components/SelectInput/BasicSelectInput";

export type Job = {
    id: string;
    ts_created: string;
    number_id: string;
    number_job: string;
    other_number: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string;
    category_name: string;
    type_id: string;
    type_name: string;
    district_id: string;
    district_name: string;
    requestor: string;
    is_priority: boolean;
    is_mre:  boolean;
    is_katapult:  boolean;
    is_have_mre: boolean;
    is_have_katapult: boolean;
    kind_id: string;
    kind_name: string;
    department_id: string;
    department_name: string;
    source_id: string;
    source_name: string;
    status_id: string;
    status_name: string;
    date_due_fielding: string;
    project_id: string;
    project_name: string;
    waiting_subject_id: string;
    waiting_notes: string;
    waiting_ts: string;
    cancel_notes: string;
    prep_notes: string;
    prep_reference_job_id:string;
    qc_notes: string;
    calspec_notes: string;
    prep_reference_job_number_job: string;
    prep_reference_list: PrepReference[];
    calcspec_notes: string;
    pole_total: number;
    department_owner_name: string;
    employee_prep_user?: string;
    employee_qc_user?: string;
    employee_cs_user?: string;
    employee_katapult_user?: string;
    employee_mre_user?: string;
    employee_qc_mre_user?: string;
    complete_date?: string;
    po_number?: string;
};

export type JobAllModel = {
    id: string;
    project: string;
    team: string | null;
    koj: string;
    request_date: string;
    number_id: string;
    number_job: string;
    status: string;
    type: string;
    district: string;
    sales_order: string;
    other_number: string | null;
    due_date: string;
    request_pole: number;
    employee_id_list: string | null;
    team_list: string | null;
    department_owner_name: string;
    employee_cs: null | string;
    employee_cs_name: null | string;
    employee_katapult: null | string;
    employee_katapult_name: null | string;
    employee_mre: null | string;
    employee_mre_name: null | string;
    employee_prep: null | string;
    employee_prep_name: null | string;
    employee_qc: null | string;
    employee_qc_mre: null | string;
    employee_qc_mre_name: null | string;
    employee_qc_name: null | string;
    complete_date: string | null;
    mre_e: number;
    mre_p: number;
};

export type PrepReference = {
    prep_reference_job_id: string;
    prep_reference_job_number_job: string;
    prep_reference_job_number_id: string;
    koj: string;
}

export type GetListRequest = {
    skip?: string | number;
    take?: string | number;
};

export type GetDropdownListRequest = {
    skip?: string | number;
    take?: string | number;
    job_number?: string;
};

export type InputModel = {
    number_id: string;
    number_job: string;
    sales_order: string;
    date_due: Date;
    date_due_fielding: Date;
    fielder: string;
    category_id: string;
    type_id: string;
    district_id: string;
    requestor: string;
    is_priority: BooleanNumber;
    is_mre_req: BooleanNumber;
    is_katapult: BooleanNumber;
    req_pole: string;
    kind_id: string;
    department_id: string;
    source_id: string;
    local_files: Array<File>;
    project_id: string;
    project_name: string;
    notes?: string;
    pause_note?: string;
    pause_subject_id?: string | null;
    pause_ts?: string | null;
    pending_ts?: string | null;
    notes_prep?: string | null;
    email_ids?: string;
    pole_total?: number;
    attachment_list: Array<SupportingDocumentType>;
};

export type InputJob = {
    id?: string;
    number_id: string;
    number_job: string;
    other_number: string;
    po_number: string;
    sales_order: string;
    date_due: string;
    date_due_fielding: string;
    fielder: string;
    category_id: string | null;
    type_id: string;
    district_id: string | null;
    project_id?: string | null;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    department_id: string | null;
    source_id: string;
    pole_total?: number;
    local_files: Array<File>;
    isEdit: boolean;
    status_id?: string;
    selectedProject?: Option;
};

export type CreateJob = {
    number_id: string;
    number_job: string;
    other_number: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string | null;
    type_id: string;
    district_id: string | null;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    department_id: string | null;
    source_id: string;
    status_id: string;
    date_due_fielding: string;
    project_id: string | null;
    waiting_subject_id: string;
    waiting_notes: string;
    waiting_ts: string;
    cancel_notes: string;
    prep_notes: string;
    prep_reference_job_id: string;
    qc_notes: string;
    calspec_notes: string;
    pole_total: number;
};

export type UpdateJob = {
    id?: string;
    number_id: string;
    number_job: string;
    other_number: string;
    sales_order: string;
    date_due: string;
    fielder: string;
    category_id: string | null;
    type_id: string;
    district_id: string | null;
    requestor: string;
    is_priority: boolean;
    is_mre: boolean;
    is_katapult: boolean;
    kind_id: string;
    department_id: string | null;
    source_id: string;
    status_id?: string;
    date_due_fielding: string;
    project_id: string | null;
    waiting_subject_id?: string;
    waiting_notes?: string;
    waiting_ts?: string;
    cancel_notes?: string;
    prep_notes?: string;
    prep_reference_job_id?: string;
    qc_notes?: string;
    calspec_notes?: string;
    calcspec_notes?: string;
};

export type CreateJobFile = {
    job_id: string;
    local_files: Array<File>;
}

export type SupportingDocumentType = {
    type_id: string;
    value: SupportingDocumentEnum;
    option: string | null;
}

export enum SupportingDocumentEnum {
    NOT_REQUIRED, NOT_AVAILABLE, AVAILABLE
}

export type JobFile = {
    id: string;
    ts_created: string;
    job_id: string;
    name: string;
    path: string;
}

export type JobCreateRequest = {
    job_id: string;
    file_list: Array<JobFileNew>;
}

export type JobFileNew = {
    id: string;
    name: string;
    path: string;
    job_id: string;
}

export type CheckRequest = {
    job_id: string;
    user_id: string;
}

export type WaitingRequest = {
    job_id: string;
    waiting_subject_id?: string;
    waiting_notes?: string;
    waiting_ts?: string;
    waiting_by_user?: string;
}

export type ChangeStatusRequest = {
    job_id: string;
    status: string;
    userId: string;
}

export type ChangeCompleteDateRequest = {
    id: string;
    completeDate: string;
}

export type CancelRequest = {
    job_id: string;
    cancel_notes: string;
    waiting_by_user: string;
}

export type InputStatusModel = {
    pause_subject_id?: string;
    notes?: string;
}

export type InputCompleteDateModel = {
    completeDate?: string;
}

export type InputImportCSV = {
    local_files: File;
}

export type DropdownJob = {
    id: string;
    number_id: string;
    number_job: string;
    other_number: string;
    sales_order: string;
}