import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from "react-data-table-component";
import {tableStyles} from "components/Table/table";
import Button from "components/Button/Button";
import DefaultModal from "components/Modal/DefaultModal";
import RequestDocumentModal from "./RequestDocumentModal";
import {getAttachment} from "../../../../basic/attachment/attachment.reducer";
import {useAppDispatch, useAppSelector} from "../../../../../helpers/redux";
import {RootState} from "../../../../../app/store";
import {JobAttachment} from "../../../../basic/attachment/models";
import {SupportingDocumentEnum} from "../../../process/job/models";
import {getLinkUrl} from "../../../../../helpers/urlManipulation";
import {BiEditAlt} from "react-icons/bi";
import {checkRoleActionAccess} from "../../../../../routes/checkRoleAccess";
import {ActionEnum, MenuEnum} from "../../../../../routes/models";
import {UserCredential} from "../../../../login/models";
import {dateWithTime} from "../../../../../helpers/convert";
import {useParams} from "react-router-dom";

const RequestDocumentTable = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [singleRequestDocument, setSingleRequestDocument] = useState<JobAttachment>({} as JobAttachment);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const user = useAppSelector(
        (state: RootState) => state.user.userAuth ?? {} as UserCredential
    );
    const attachment = useAppSelector(
        (state: RootState) => state.attachment.list ?? []
    );

    useEffect(() => {
        if (id && id !== "") {
            dispatch(getAttachment({job_id: id as string, skip: 0, take: 10}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onEditClicked = (row: JobAttachment) => {
        setShowAttachmentModal(true);
        setSingleRequestDocument(row);
        setIsEdit(true);
    }

    const columns: TableColumn<JobAttachment>[] = [
        {
            name: 'NO.',
            width: '60px',
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: 'Req Date',
            width: '220px',
            selector: (row) => row.date_request,
            format: (row) => row.date_request !== null ? dateWithTime(row.date_request) : "-",
            sortable: true,

        },
        {
            name: 'Member',
            selector: (row) => row.employee_name,
            sortable: true,
        },
        {
            name: 'Position',
            selector: (row) => row.position,
            sortable: true,
        },
        {
            name: 'Request Data',
            selector: (row) => row.type_name,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <>
                    {row.value === SupportingDocumentEnum.AVAILABLE && "Available"}
                    {row.value === SupportingDocumentEnum.NOT_AVAILABLE && "Not Available"}
                    {row.value === SupportingDocumentEnum.NOT_REQUIRED && "Not Required"}
                </>
            )
        },
        {
            name: 'Note',
            selector: (row) => row.note ?? "-",
            sortable: true,
        },
        {
            name: 'Link',
            cell: (row) => (
                <>
                    {row.link !== null ? (
                            <a
                                href={getLinkUrl(row.link) ?? ""}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {row.link}
                            </a>
                    ) : "-"}
                </>
            ),
        },
        {
            name: 'Action',
            width: '150px',
            cell: (row) => (
                <>
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetRequestDocument, ActionEnum.Edit) &&
                        <button onClick={() => onEditClicked(row)}>
                            <BiEditAlt className="mr-2 text-2xl text-primary-color" />
                        </button>
                    }
                </>
            ),
        },
    ];

    const onModalAttachmentClose = () => {
        setShowAttachmentModal(false);
        setIsEdit(false);
        setSingleRequestDocument({} as JobAttachment);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        dispatch(
            getAttachment({
                job_id: id as string,
                skip: newPerPage * (page - 1),
                take: newPerPage,
            })
        );
    };

    const handlePageChange = (page: number) => {
        dispatch(
            getAttachment({
                job_id: id as string,
                skip: rowsPerPage * (page - 1),
                take: rowsPerPage,
            })
        );
    };

    return (
        <div>
            <div className="main-data-grid mt-2">
                <div className="mb-2 mt-3">
                    {
                        checkRoleActionAccess(user?.roleName, MenuEnum.WorksheetRequestDocument, ActionEnum.Input) &&
                        <Button
                            title="Request Supporting Doc"
                            type="button"
                            onClick={() => setShowAttachmentModal(true)}
                        />
                    }
                </div>
                <DataTable
                    columns={columns}
                    data={attachment}
                    pagination
                    paginationServer
                    paginationTotalRows={1}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noHeader
                    highlightOnHover
                    pointerOnHover
                    customStyles={tableStyles}
                    theme={'solarized'}
                    striped
                />
            </div>
            <DefaultModal
                title="Request Supporting Doc"
                show={showAttachmentModal}
                onClose={onModalAttachmentClose}
                body={<RequestDocumentModal onClose={onModalAttachmentClose} isEdit={isEdit} singleRequestDocument={singleRequestDocument} jobId={id} />}
            />
        </div>
    );
};

export default RequestDocumentTable;

