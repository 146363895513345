import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import InputText from 'components/TextInput/InputText';
import { InputModel, SupportingDocument } from '../models';
import Button from 'components/Button/Button';
import { schema } from '../constants';
import { useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import { isBlank, isObjectEmpty } from 'helpers/checkEmptiness';
import CreatableSelectInput, {
    ValueOption,
} from 'components/SelectInput/CreatableSelectInput';

const MasterSupportingDocumentForm = ({
    isLoading = false,
    isEdit = false,
    ...props
}: MasterSupportingDocumentFormProps) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm<InputModel>({
        resolver: yupResolver(schema),
    });

    const optionWatcher = watch('options');
    const [valueOptions, setValueOptions] = useState<Array<ValueOption>>([]);

    const singleState = useAppSelector(
        (state: RootState) =>
            state.supportingDocument.single ?? ({} as SupportingDocument)
    );

    useEffect(() => {
        if (!isObjectEmpty(singleState)) {
            reset(singleState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleState]);

    useEffect(() => {
        if (optionWatcher !== undefined) {
            let _optionArr = isBlank(optionWatcher)
                ? []
                : optionWatcher?.split(',');
            let options = _optionArr?.map((item) => {
                return {
                    label: item,
                    value: item,
                };
            });
            setValueOptions(options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionWatcher]);

    useEffect(() => {
        if (!isEdit) {
            reset({} as InputModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);

    const onSubmit = (data: InputModel) => {
        props.onSubmit(data);
    };

    const getValue = (value: Array<string>) => {
        setValue('options', value.toString());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputText
                {...register('name')}
                label="Supporting Document Name"
                placeholder="Input supporting document name"
                message={errors.name?.message}
            />
            <CreatableSelectInput
                value={valueOptions}
                getValue={getValue}
                label="Supporting Document Type"
                placeholder={'Input supporting document type'}
            />
            <div className="pt-2 pb-2">
                <Button
                    color="success"
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                />
            </div>
        </form>
    );
};

export interface MasterSupportingDocumentFormProps {
    isLoading: boolean;
    isEdit: boolean;
    onSubmit: (formInput: InputModel) => void;
}

export default MasterSupportingDocumentForm;
