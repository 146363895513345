import React, {useEffect} from 'react';
import {useAppDispatch} from "helpers/redux";
import {setDynamicNavId} from "../../sidebarPanel/sidebar.reducer";
import DynamicNavLink from "components/Tabs/DynamicNavLink";

const ProfileDynamicNav = () => {
    const dispatch = useAppDispatch();

    const links = [
        {
            id: "staff-report",
            label: "Staff Report",
        },
        {
            id: "employee-work",
            label: "Employee Work"
        },
        {
            id: "employee-work-process",
            label: "Employee Work Process"
        }
    ];

    useEffect(() => {
        dispatch(setDynamicNavId('staff-report'));
    }, []);

    return (
        <DynamicNavLink links={links}/>
    );
};

export default ProfileDynamicNav;