import { createContext, useState, useEffect } from 'react'
import {createTheme} from "react-data-table-component";

type ThemeName = 'light' | 'dark' | string
type ThemeContextType = {
    theme: ThemeName
    setTheme: (name: ThemeName) => void
}

const getInitialTheme = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const storedPrefs = window.localStorage.getItem('color-theme')
        if (typeof storedPrefs === 'string') {
            return storedPrefs
        }

        const userMedia = window.matchMedia('(prefers-color-scheme:dark)')
        if (userMedia.matches) {
            // return 'dark'
            return 'light'
        }
    }
    // returning default theme here
    return 'light'
}

export const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType)

// @ts-ignore
export const ThemeProvider = ({ initialTheme, children }) => {
    const [theme, setTheme] = useState(getInitialTheme);

    createTheme('solarized',
         {
            text: {
                primary: theme === 'dark' ?  'rgb(209, 213, 219)' : '#374151',
                secondary: theme === 'dark' ?  'rgb(209, 213, 219)' : '#374151'
            },
            background: {
                default:  theme === 'dark' ? '#374151' : '#fff',
                hover: theme === 'dark' ? '#424b5b' : '#ccc',
            },
             striped: {
               default: theme === 'dark' ? '#424b5b' : '#ccc',
             },
            divider: {
                default:  theme === 'dark' ?  '#1f2937' : '#E5E7EB',
            },
            button: {
                default: theme === 'dark' ?  'rgb(209, 213, 219)' : '#374151',
                hover: 'rgba(55,65,81,.08)',
                focus: 'rgba(255,255,255,.12)',
                disabled:  theme === 'dark' ? 'rgba(255, 255, 255, .34)' :'gray',
            },
            sortFocus: {
                default: 'rgb(209, 213, 219)',
            },

    });

    const rawSetTheme = (theme: string) => {
        //Updated rawSetTheme to theme above//
        const root = window.document.documentElement;
        const isDark = theme === 'dark';

        root.classList.remove(isDark ? 'light' : 'dark');
        root.classList.add(theme);

        // localStorage.setItem('color-theme', theme);
        localStorage.setItem('color-theme', 'light');
    };

    if (initialTheme) {
        rawSetTheme(initialTheme);
        createTheme('solarized',
            {
                text: {
                    primary: theme === 'dark' ?  'rgb(209, 213, 219)' : '#374151',
                    secondary: theme === 'dark' ?  'rgb(209, 213, 219)' : '#374151'
                },
                background: {
                    default:  theme === 'dark' ? '#374151' : '#fff',
                    hover: theme === 'dark' ? '#424b5b' : '#ccc',
                },
                striped: {
                    default: theme === 'dark' ? '#424b5b' : '#ccc',
                },
                divider: {
                    default:  theme === 'dark' ?  '#1f2937' : '#E5E7EB',
                },
                button: {
                    default: theme === 'dark' ?  'rgb(209, 213, 219)' : '#374151',
                    hover: 'rgba(55,65,81,.08)',
                    focus: 'rgba(255,255,255,.12)',
                    disabled:  theme === 'dark' ? 'rgba(255, 255, 255, .34)' :'gray',
                },
                sortFocus: {
                    default: 'rgb(209, 213, 219)',
                },
            }, 'dark');
    }

    useEffect(() => {
        rawSetTheme(theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
}
