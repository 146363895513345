import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'helpers/redux';
import AgDataGridStatic from "../../../../../components/DataGrid/AgDataGridStatic";
import {rowNumberRenderer} from "../../../../../components/DataGrid/agGridCustomProps";
import {filterParams} from "../../../../../helpers/agTable";
import {getAttendanceLog} from "../../attendanceReport.reducer";
import {MdOutlineSupervisedUserCircle} from "react-icons/md";
import DefaultModal from "../../../../../components/Modal/DefaultModal";
import AssignCalcSpec from "../../../../worksheet/process/calcSpec/components/AssignCalcSpec";
import {CalcSpecType} from "../../../../worksheet/process/calcSpec/models";
import AbsenLogDetail from "./AbsenLogDetail";
import {BsCalendar3} from "react-icons/bs";
import {Button as FlowbiteButton} from "flowbite-react/lib/esm/components/Button/Button";
import {StaffReportModel} from "../../../staffReport/models";
import {IoMdAdd} from "react-icons/io";
import {PiMicrosoftExcelLogoDuotone} from "react-icons/pi";

const AbsentLogTable = ({...componentProps}: AbsenLogTableProps) => {
    const dispatch = useAppDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);

    const attendanceLogList = useAppSelector(
        (state) => state.attendanceReport.absentReportList ?? []
    );

    const ActionCellRender = (props: any) => {

        if (props.data === undefined) {
            return null;
        }

        const onDetailClicked = () => {
            componentProps.onDetailClicked(props.data?.userId);
            toggleModal();
        }

        return (
            <div className="flex items-center h-[100%]">
                <button onClick={onDetailClicked}>
                    <BsCalendar3
                        className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"/>
                </button>
            </div>
        )
    }

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    // Each Column Definition results in one Column.
    const [columnDefs, _setColumnDefs] = useState([
        {
            headerName: '#',
            field: 'rowNumber',
            minWidth: 50,
            cellRenderer: rowNumberRenderer, // Use the custom cell renderer
        },
        {field: 'nip', headerName: 'NIP', filter: true},
        {field: 'name', headerName: 'Name', filter: true},
        {field: 'team', headerName: 'Team', filter: true},
        {field: 'role', headerName: 'Role', filter: true},
        {field: 'ot', headerName: 'OT'},
        {field: 'workDay', headerName: 'Work Day'},
        {field: 'izin', headerName: 'Izin'},
        {field: 'total', headerName: 'Total'},
        {
            headerName: 'Actions',
            field: 'actions',
            pinned: 'right',
            suppressCellFlash: true,
            minWidth: 220,
            cellRenderer: ActionCellRender,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            })
        },
    ]);

    return (
        <div>
            <div className="mt-3">
                <div className="main-data-grid fielding mt-5">
                    <div>
                        <AgDataGridStatic columnDefs={columnDefs} rowData={attendanceLogList} supportSaveTableState
                                          tableStateName={'report-absent-log'}
                                          isCustomHeader={true}
                                          customChildHeaderComponent={
                                              <>
                                                  <FlowbiteButton
                                                      onClick={() => componentProps.onExportHandler?.()}
                                                      size="xs"
                                                      color="success"
                                                  >
                                                      <PiMicrosoftExcelLogoDuotone className="mr-2 h-5 w-5"/>
                                                      Export
                                                  </FlowbiteButton>
                                              </>
                                          }
                                          defaultPageSize={20}
                        />
                    </div>
                    <DefaultModal
                        title="Absent Log Detail"
                        show={showModal}
                        onClose={toggleModal}
                        size="7xl"
                        body={<AbsenLogDetail/>}
                    />
                </div>
            </div>
        </div>
    );
};

export interface AbsenLogTableProps {
    onDetailClicked: (userId: string) => void;
    onExportHandler?: () => void;
}

export default AbsentLogTable;
