import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import InputText from 'components/TextInput/InputText';
import { InputModel, District } from '../models';
import Button from 'components/Button/Button';
import { schema } from '../constants';
import { useAppSelector } from 'helpers/redux';
import { RootState } from 'app/store';
import {isArrayEmpty, isObjectEmpty} from 'helpers/checkEmptiness';
import {Option} from "../../../../components/SelectInput/BasicSelectInput";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import {MultiValue} from "react-select";

const DistrictForm = ({
    isLoading = false,
    isEdit = false,
    ...props
}: DistrictFormProps) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<InputModel>({
        resolver: yupResolver(schema),
    });

    const singleState = useAppSelector(
        (state: RootState) => state.district.single ?? ({} as District)
    );

    const projectOptions = useAppSelector(
        (state: RootState) => state.project.options ?? []
    );

    useEffect(() => {
        if (!isObjectEmpty(singleState)) {
            let {project_names, ...rest } = singleState;
            reset(rest);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleState]);

    useEffect(() => {
        if (!isEdit) {
            reset({} as InputModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, projectOptions]);

    const onSubmit = (data: InputModel) => props.onSubmit(data);

    const onProjectChange = (val: MultiValue<Option>) => {
        let _project = val.map((item) => item.value);
        setValue('project_ids', _project.toString());
    };

    const defaultProject = () => {
        if (isEdit) {
            if (isArrayEmpty(projectOptions)) return [];
            let _district = singleState.project_ids?.split(',');
            return projectOptions.filter((item) => _district?.includes(item.value));
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputText
                {...register('name')}
                label="District Name"
                placeholder="Input district name"
                message={errors.name?.message}
            />
            <SelectInput
                options={projectOptions}
                isMulti
                label="Project"
                onChange={onProjectChange}
                defaultValue={defaultProject()}
            />
            <div className="pt-2 pb-2">
                <Button
                    color="success"
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                />
            </div>
        </form>
    );
};

export interface DistrictFormProps {
    isLoading: boolean;
    isEdit: boolean;
    onSubmit: (formInput: InputModel) => void;
}

export default DistrictForm;
