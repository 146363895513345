import {
    EmployeeWorkFilterModel,
    EmployeeWorkModel,
    GetEmployeeWorkReq, GetExportEmployeeWorkReq, GetStaffApprovalReq, GetStaffMainReq, GetStaffReportWithFilterReq,
    SendStaffReq,
    StaffReportMainModel,
    StaffReportModel
} from "./models";
import {HttpClientError, RequestStatus} from "http-client";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import StaffReportApi from "./staffReport.api";
import {RootState} from "app/store";
import FileSaver from "file-saver";


export interface StaffReportSlice {
    staffReportList?: Array<StaffReportModel>;
    staffReportMainList?: Array<StaffReportMainModel>;
    employeeWorkList?: Array<EmployeeWorkModel>;
    employeeWorkWithFilterList?: Array<EmployeeWorkFilterModel>;
    employeeWorkWithFilterRows?: number;
    employeeWorkWithFilterNewList?: Array<EmployeeWorkFilterModel>;
    employeeWorkWithFilterNewRows?: number;
    staffReportRows?: number;
    isLoading?: boolean;
    isEmployeeLoading?: boolean;
    isMainLoading?: boolean;
    status?: RequestStatus;
    error?: HttpClientError;
}

export const getStaffReport = createAsyncThunk(
    'staffReport/getStaffReport',
    async (args: GetStaffReportWithFilterReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.getStaffReport(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getStaffReportApproval = createAsyncThunk(
    'staffReport/getStaffReportApproval',
    async (args: GetStaffApprovalReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.getStaffReportApproval(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getStaffReportMain = createAsyncThunk(
    'staffReport/getStaffReportMain',
    async (arg: GetStaffMainReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.getStaffReportMain(arg, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getEmployeeWork = createAsyncThunk(
    'staffReport/getEmployeeWork',
    async (args: GetEmployeeWorkReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.getEmployeeWork(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getEmployeeWorkWithFilter = createAsyncThunk(
    'staffReport/getEmployeeWorkWithFilter',
    async (args: GetEmployeeWorkReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.getEmployeeWorkWithFilter(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const getEmployeeWorkWithFilterNew = createAsyncThunk(
    'staffReport/getEmployeeWorkWithFilterNew',
    async (args: GetEmployeeWorkReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.getEmployeeWorkWithFilterNew(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const createStaffReport = createAsyncThunk(
    'staffReport/createStaffReport',
    async (args: SendStaffReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.createStaffReport(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const updateStaffReport = createAsyncThunk(
    'staffReport/updateStaffReport',
    async (args: SendStaffReq, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.updateStaffReport(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const deleteStaffReport = createAsyncThunk(
    'staffReport/deleteStaffReport',
    async (arg: string, { getState, rejectWithValue }) => {
        try {
            const { userAuth } = (getState() as RootState).user;
            return await StaffReportApi.deleteStaffReport(arg, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    });

export const exportEmployeeWork = createAsyncThunk(
    'staffReport/exportEmployeeWork',
    async (args: GetExportEmployeeWorkReq, { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await StaffReportApi.exportEmployeeWork(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

export const exportEmployeeWorkNew = createAsyncThunk(
    'staffReport/exportEmployeeWorkNew',
    async (args: GetExportEmployeeWorkReq, { getState, rejectWithValue }) => {
        const { userAuth } = (getState() as RootState).user;
        try {
            return await StaffReportApi.exportEmployeeWorkNew(args, userAuth?.id as string);
        } catch (e) {
            return rejectWithValue(e as HttpClientError);
        }
    }
);

const staffReportSlice = createSlice({
    name: 'staffReportState',
    initialState: {} as StaffReportSlice,
    reducers: {
        reset: (state) => {
            state.status = RequestStatus.pending;
            state.error = {} as HttpClientError;
        }
    },
    extraReducers: builder => {
        // get staff report
        builder.addCase(getStaffReport.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStaffReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.staffReportList = action.payload.data;
            state.staffReportRows = action.payload.rows;
        });
        builder.addCase(getStaffReport.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get staff report
        builder.addCase(getStaffReportApproval.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStaffReportApproval.fulfilled, (state, action) => {
            state.isLoading = false;
            state.staffReportList = action.payload.data;
            state.staffReportRows = action.payload.rows;
        });
        builder.addCase(getStaffReportApproval.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get staff report main
        builder.addCase(getStaffReportMain.pending, (state) => {
            state.isMainLoading = true;
        });
        builder.addCase(getStaffReportMain.fulfilled, (state, action) => {
            state.isMainLoading = false;
            state.staffReportMainList = action.payload.data;
        });
        builder.addCase(getStaffReportMain.rejected, (state, action) => {
            state.isMainLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get employee work list
        builder.addCase(getEmployeeWork.pending, (state) => {
            state.isEmployeeLoading = true;
        });
        builder.addCase(getEmployeeWork.fulfilled, (state, action) => {
            state.isEmployeeLoading = false;
            state.employeeWorkList = action.payload.data;
        });
        builder.addCase(getEmployeeWork.rejected, (state, action) => {
            state.isEmployeeLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get employee work list
        builder.addCase(getEmployeeWorkWithFilter.pending, (state) => {
            state.isEmployeeLoading = true;
        });
        builder.addCase(getEmployeeWorkWithFilter.fulfilled, (state, action) => {
            state.isEmployeeLoading = false;
            state.employeeWorkWithFilterList = action.payload.data;
            state.employeeWorkWithFilterRows = action.payload.rows;
        });
        builder.addCase(getEmployeeWorkWithFilter.rejected, (state, action) => {
            state.isEmployeeLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // get employee work list
        builder.addCase(getEmployeeWorkWithFilterNew.pending, (state) => {
            state.isEmployeeLoading = true;
        });
        builder.addCase(getEmployeeWorkWithFilterNew.fulfilled, (state, action) => {
            state.isEmployeeLoading = false;
            state.employeeWorkWithFilterNewList = action.payload.data;
            state.employeeWorkWithFilterNewRows = action.payload.rows;
        });
        builder.addCase(getEmployeeWorkWithFilterNew.rejected, (state, action) => {
            state.isEmployeeLoading = false;
            state.error = action.payload as HttpClientError;
        });
        // create staff report
        builder.addCase(createStaffReport.pending, (state) => {
            state.isLoading = true;
            state.status = RequestStatus.pending;
        });
        builder.addCase(createStaffReport.fulfilled, (state) => {
            state.isLoading = false;
            state.status = RequestStatus.success;
        });
        builder.addCase(createStaffReport.rejected, (state, action) => {
            state.isLoading = false;
            state.status = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // update staff report
        builder.addCase(updateStaffReport.pending, (state) => {
            state.isLoading = true;
            state.status = RequestStatus.pending;
        });
        builder.addCase(updateStaffReport.fulfilled, (state) => {
            state.isLoading = false;
            state.status = RequestStatus.success;
        });
        builder.addCase(updateStaffReport.rejected, (state, action) => {
            state.isLoading = false;
            state.status = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // delete staff report
        builder.addCase(deleteStaffReport.pending, (state) => {
            state.isLoading = true;
            state.status = RequestStatus.pending;
        });
        builder.addCase(deleteStaffReport.fulfilled, (state) => {
            state.isLoading = false;
            state.status = RequestStatus.success;
        });
        builder.addCase(deleteStaffReport.rejected, (state, action) => {
            state.isLoading = false;
            state.status = RequestStatus.failed;
            state.error = action.payload as HttpClientError;
        });
        // export to csv download reducer
        builder.addCase(exportEmployeeWork.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(exportEmployeeWork.fulfilled, (state, { payload }) => {
            FileSaver.saveAs(
                payload,
                'employee_work' + new Date().toISOString() + '.xlsx'
            );
            state.isLoading = false;
        })
        builder.addCase(exportEmployeeWork.rejected, (state, { payload }) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
        // export to csv download reducer
        builder.addCase(exportEmployeeWorkNew.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(exportEmployeeWorkNew.fulfilled, (state, { payload }) => {
            FileSaver.saveAs(
                payload,
                'employee_work_process' + new Date().toISOString() + '.xlsx'
            );
            state.isLoading = false;
        })
        builder.addCase(exportEmployeeWorkNew.rejected, (state, { payload }) => {
            state.error = payload as HttpClientError;
            state.isLoading = false;
        });
    }
});
export const { reset } = staffReportSlice.actions;
export default staffReportSlice.reducer;