import {BaseServiceClass} from "../../../../base-service-class";
import httpClient, {ApiResponseWrapper} from "../../../../http-client";
import {
    AssignCalcSpecRequest,
    CalcSpecSummary,
    CalcSpecType,
    GetCalcSpecFilterRequest,
    GetCalcSpecRequest, GetMainCalcSpecFilterRequest,
    MainCalcSpecType
} from "./models";

export default class CalcSpecApi extends BaseServiceClass {
    static getCalcSpec(args: GetCalcSpecRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<CalcSpecType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_calc_spec}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getCalcSpecWithFilter(args: GetCalcSpecFilterRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<CalcSpecType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_calc_spec_with_filter}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static getSingleCalcSpec(args: GetCalcSpecRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<CalcSpecType>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_calc_spec}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getCalcSpecSummary(id: string, token: string) {
        return httpClient.get<ApiResponseWrapper<CalcSpecSummary[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_calc_spec_summary}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                id: id
            }
        }).then((res) => res.data);
    }

    static getMainCalcSpec(args: GetCalcSpecRequest, token: string) {
        return httpClient.get<ApiResponseWrapper<MainCalcSpecType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_calc_spec_main}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: args
        }).then((res) => res.data);
    }

    static getMainCalcSpecWithFilter(args: GetMainCalcSpecFilterRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<MainCalcSpecType[]>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_get_calc_spec_main_with_filter}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data);
    }

    static assignCalcSpec(args: AssignCalcSpecRequest, token: string) {
        return httpClient.post<ApiResponseWrapper<null>>(`${this.BASE_URL}${this.PATH_WORKSHEET.job_calc_spec_assign}`, args, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then((res) => res.data.data);
    }
}